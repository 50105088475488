import clsx from 'clsx';
import moment from 'moment';
import Papa from 'papaparse';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AlertTriangle, Trash, Upload, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { Entity } from '../enums/entity';
import { OrganizationType } from '../enums/organization-type';
import { CsvColumnDto } from '../interfaces/csv-column.dto';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { DetailIgnoredRowDto, ImportCsvResultDto } from '../interfaces/import-csv-result.dto';
import { ImportCsvColumnDto, ImportCsvDto } from '../interfaces/import-csv.dto';
import { ImportDeliveryNoteDto } from '../interfaces/import-delivery-note.dto';
import { Organization } from '../interfaces/organization';
import { Supplier } from '../interfaces/supplier';
import { api } from '../services/api';
import { replaceDiacriticAccents } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { storeSelector } from '../store/store-slice';

enum ImportPhase {
  UploadCsv = 1,
  MapFields = 2,
  CheckMappings = 3,
  ConfirmMapping = 4,
  ImportResult = 5,
}

interface Props {
  entity: Entity;
  show: boolean;
  closeModal: (imported: boolean) => void;
}

const ImportCsvModal = ({ entity, show, closeModal }: Props) => {
  const { store } = useSelector(storeSelector);
  const organization: Organization = useAppSelector((state) => state.auth.organization)!;
  const organizationType: OrganizationType = useAppSelector((state) => state.auth.organizationType)!;
  const [importPhase, setImportPhase] = useState<ImportPhase>(ImportPhase.UploadCsv);
  const [csvColumnsDtos, setCsvColumnsDtos] = useState<CsvColumnDto[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [parseResult, setParseResult] = useState<Papa.ParseResult<any> | null>(null);
  const csvInputFileRef = useRef<any>(null);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [importCsvResultDto, setImportCsvResultDto] = useState<ImportCsvResultDto | null>(null);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<any>({
    mode: 'onSubmit',
    defaultValues: {},
  });
  const title: string = useMemo(() => {
    switch (entity) {
      case Entity.Customer:
        return 'Clientes';
      case Entity.DeliveryNote:
        return 'Compra';
      case Entity.Product:
        return 'Productos';
      case Entity.Repair:
        return 'Reparaciones';
      case Entity.Sale:
        return 'Ventas';
      case Entity.Stock:
        return 'Productos';
      default:
        return '';
    }
  }, [entity]);
  const availableColumns: string[] = useMemo(() => {
    if (!parseResult) {
      return [];
    }
    if (!parseResult.meta.fields) {
      return [];
    }
    return parseResult.meta.fields.sort();
  }, [parseResult]);
  const existErrors: boolean = useMemo(() => {
    return Object.keys(errors).length > 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, watch()]);

  useEffect(() => {
    if (show) {
      const getCsvColumnsGivenEntity = async () => {
        let columns: CsvColumnDto[] = [];
        try {
          switch (entity) {
            case Entity.Customer:
              columns = await api.getImportCsvColumnsCustomers();
              break;
            case Entity.DeliveryNote:
              columns = await api.getImportCsvColumnsDeliveryNotes(store!.id);
              break;
            case Entity.Product:
              columns = await api.getImportCsvColumnsProduct();
              break;
            case Entity.Repair:
              columns = await api.getImportCsvColumnsRepairs();
              break;
            case Entity.Sale:
              columns = await api.getImportCsvColumnsSales();
              break;
            case Entity.Stock:
              columns = await api.getImportCsvColumnsStock(store!.id);
              break;
          }
        } catch (e: any) {
          const httpExceptionDto: HttpExceptionDto = e.response.data;
          myToastr.error(httpExceptionDto.message);
        }
        setCsvColumnsDtos(columns);
      };
      getCsvColumnsGivenEntity();
    } else {
      setImportPhase(ImportPhase.UploadCsv);
      reset();
      setCsvColumnsDtos([]);
      setFile(null);
      setParseResult(null);
      setRequesting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, reset, show]);

  useEffect(() => {
    if (!show) {
      return;
    }
    switch (entity) {
      case Entity.DeliveryNote:
        setValue('extDataDate', moment().add(5, 'minutes').format('YYYY-MM-DD'));
        getSuppliers();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, entity]);

  const removeFile = () => {
    setFile(null);
    setParseResult(null);
  };

  const onSelectedFile = (event: any) => {
    if (event.target.files.length === 0) {
      return;
    }
    const file: File = event.target.files[0];
    if (file.type !== 'text/csv') {
      myToastr.error('El fichero seleccionado no es un CSV');
      return;
    }
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv: Papa.ParseResult<any> = Papa.parse((target as any).result, { header: true });
      if (csv.meta.fields && csv.meta.fields.length > 0) {
        csv.meta.fields.forEach((field: string) => {
          const csvColumnDto: CsvColumnDto | undefined = csvColumnsDtos.find((c: CsvColumnDto) => {
            const fieldLowerCase: string = field.toLowerCase();
            return replaceDiacriticAccents(c.propertyName).toLowerCase() === fieldLowerCase || replaceDiacriticAccents(c.suggestedColumnName).toLowerCase() === fieldLowerCase;
          });
          if (csvColumnDto) {
            setValue(csvColumnDto.propertyName, field);
          }
        });
      }
      setParseResult(csv);
    };
    reader.readAsText(file);
    setFile(file);
  };

  const close = () => {
    closeModal(importPhase === ImportPhase.ImportResult);
  };

  const downloadFile = (filePath: string) => {
    const link = document.createElement('a');
    link.href = filePath;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadCsvExample = () => {
    switch (entity) {
      case Entity.Customer:
        downloadFile(`${process.env.PUBLIC_URL}/files/sample/clientes_sample.csv`);
        break;
      case Entity.DeliveryNote:
        if (organizationType === OrganizationType.B2B) {
          downloadFile(`${process.env.PUBLIC_URL}/files/sample/compras_mayorista_sample.csv`);
        } else {
          downloadFile(`${process.env.PUBLIC_URL}/files/sample/compras_sample.csv`);
        }
        break;
      case Entity.Product:
        downloadFile(`${process.env.PUBLIC_URL}/files/sample/productos_sample.csv`);
        break;
      case Entity.Repair:
        downloadFile(`${process.env.PUBLIC_URL}/files/sample/composturas_sample.csv`);
        break;
      case Entity.Sale:
        downloadFile(`${process.env.PUBLIC_URL}/files/sample/ventas_sample.csv`);
        break;
      case Entity.Stock:
        downloadFile(`${process.env.PUBLIC_URL}/files/sample/stock_sample.csv`);
        break;
    }
  };

  const onSubmit = async () => {
    setImportPhase(ImportPhase.CheckMappings);
  };

  const importCsv = async () => {
    setRequesting(true);
    try {
      const importCsvColumnDto: ImportCsvColumnDto[] = [];
      const values: { [key: string]: any } = getValues();
      for (const key in values) {
        if (key.startsWith('extData')) {
          continue;
        }
        if (values[key]) {
          importCsvColumnDto.push({ propertyName: key, csvColumnName: values[key] });
        }
      }
      const importCsvDto: ImportCsvDto = {
        file: file!,
        columns: importCsvColumnDto,
        storeId: store ? store!.id : null,
      };
      let importResult: ImportCsvResultDto | null = null;
      switch (entity) {
        case Entity.Customer:
          myToastr.info('Importando CSV. El proceso puede llevar unos minutos. Espere por favor...');
          importResult = await api.importCsvCustomers(importCsvDto);
          break;
        case Entity.DeliveryNote:
          myToastr.info('Importando CSV. El proceso puede llevar unos minutos. Espere por favor...');
          const importDeliveryNoteDto: ImportDeliveryNoteDto = {
            date: values.extDataDate,
            externalNumber: values.extDataExternalNumber,
            storeId: store!.id,
            supplierId: values.extDataSupplierId,
            hasEquivalenceSurcharge: values.extDataEquivalenceSurcharge,
            invoiceDate: moment(values.extDataInvoiceDate).isValid() ? moment(values.extDataInvoiceDate).toDate() : null,
            invoiceNumber: values.extDataInvoiceNumber,
            file: file!,
            columns: importCsvColumnDto,
          };
          importResult = await api.importCsvDeliveryNotes(importDeliveryNoteDto);
          break;
        case Entity.Product:
          myToastr.info(`El proceso se ejecutará en segundo plano. Recibirá un correo electrónico con el resultado de la importación.`);
          await api.importCsvProducts(importCsvDto);
          setRequesting(false);
          closeModal(false);
          return;
        case Entity.Repair:
          myToastr.info('Importando CSV. El proceso puede llevar unos minutos. Espere por favor...');
          importResult = await api.importCsvRepairs(importCsvDto);
          break;
        case Entity.Sale:
          myToastr.info('Importando CSV. El proceso puede llevar unos minutos. Espere por favor...');
          importResult = await api.importCsvSales(importCsvDto);
          break;
        case Entity.Stock:
          myToastr.info('Importando CSV. El proceso puede llevar unos minutos. Espere por favor...');
          importResult = await api.importCsvStock(importCsvDto);
          break;
      }
      setImportCsvResultDto(importResult);
      myToastr.clear();
      setImportPhase(ImportPhase.ImportResult);
    } catch (e: any) {
      const data: any = e.response.data;
      if (data.hasOwnProperty('fileRows')) {
        setImportCsvResultDto(data);
        myToastr.clear();
        setImportPhase(ImportPhase.ImportResult);
      } else {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
    setRequesting(false);
  };

  const getSuppliers = async () => {
    const sups: Supplier[] = await api.getSuppliers(organization.id);
    setSuppliers(sups);
  };

  return (
    <Modal className="vercomi-modal my-form import-csv-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex mb-3">
            <div className="title text-center flex-grow-1">Mapeador Importador {title}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          {importPhase === ImportPhase.UploadCsv && (
            <div className="noselect">
              <p>
                Sube un CSV con todos los datos, revisa que coincide con nuestra estructura y, de no ser así, los podrás seleccionar manualmente.
                {entity !== Entity.DeliveryNote && (
                  <span className="d-block blockquote-footer mt-0 mb-0">
                    Si el producto existe se actualizará la descripción, nombre, ean, marca y colección (CUIDADO: si el valor del campo está vacío lo dejará vacío)
                  </span>
                )}
                <span className="d-block blockquote-footer mt-0 mb-0">Del stock de la tienda se actualitzará la cantidad, precio de coste, pvp, descuento, activo y peso</span>
                <span className="d-block blockquote-footer mt-0 mb-0">El caracter usado como separador de decimales debe ser ' . ' (punto)</span>
                <span className="d-block blockquote-footer mt-0 mb-0">Las categorías se separarán con el caracter "|". De esta forma: CATEGORÍA1|CATEGORÍA2</span>
                <span className="d-block blockquote-footer mt-0 mb-0">Si una categoría contiene subcategorías se indicará con el caracter "&gt;". De esta forma: CATEGORÍA&gt;SUCATEGORÍA</span>
                <span className="d-block blockquote-footer mt-0 mb-0">El pvp tiene que llevar el IVA (o el impuesto correspondiente) incluido</span>
                <span className="d-block blockquote-footer mt-0">El precio de coste no debe llevar incluido el IVA (o impuesto correspondiente)</span>
              </p>
              <p>
                Descarga nuestro CSV de ejemplo{' '}
                <span className="download-example-csv" onClick={downloadCsvExample}>
                  aquí
                </span>
                .<span></span>
              </p>
              <p>Las columnas no deben contener el carácter ',' (coma) o '/' (barra). Si la columna lo contiene deberá escaparla completamente con comillas dobles.</p>
              <p>Importante: se realizará la importación siempre y cuando no se encuentren errores en ninguna de las filas del CSV.</p>
              <div className="container-import-csv-file">
                {file === null ? (
                  <div title="Seleccionar fichero" className="d-flex align-items-center" onClick={() => csvInputFileRef.current.click()}>
                    <Upload className="me-2" color="#A0AEC1" size={18} />
                    <span>Importa tu CSV</span>
                  </div>
                ) : (
                  <div title="Elimina fichero" className="d-flex align-items-center" onClick={removeFile}>
                    <Trash className="me-2" color="#A0AEC1" size={18} />
                    <span>{file.name}</span>
                  </div>
                )}
                <input
                  ref={csvInputFileRef}
                  type="file"
                  accept=".csv"
                  onClick={(event: any) => {
                    event.target.value = null;
                  }}
                  onChange={onSelectedFile}
                />
              </div>
              {parseResult !== null &&
                (availableColumns.length === 0 ? (
                  <p className="d-flex align-items-center error">
                    <AlertTriangle size={16} className="me-2" />
                    No se han encontrado columnas en el fichero.
                  </p>
                ) : (
                  <p>
                    El fichero contiene {parseResult.data.length} {parseResult.data.length === 1 ? 'fila' : 'filas'}.
                  </p>
                ))}
              {entity === Entity.DeliveryNote && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-6">
                      <div className={clsx('input-name', { error: errors?.extDataSupplierId })}>Proveedor *</div>
                      <select {...register('extDataSupplierId', { required: true, min: 1, valueAsNumber: true })} className={clsx({ error: errors?.extDataSupplierId })}>
                        <option value={-1}>Selecciona un proveedor</option>
                        {suppliers.map((supplier: Supplier) => (
                          <option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                          </option>
                        ))}
                      </select>
                      {errors.extDataSupplierId && <div className="error-message">{errors.extDataSupplierId.message}</div>}
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col">
                      <div className={clsx('input-name', { error: errors?.extDataDate })}>Fecha entrada prevista *</div>
                      <input type="date" {...register('extDataDate', { required: true })} className={clsx({ error: errors?.extDataDate })} />
                      {errors.extDataDate && <div className="error-message">{errors.extDataDate.message}</div>}
                    </div>
                    <div className="col">
                      <div className={clsx('input-name', { error: errors?.extDataExternalNumber })}>Nº documento (Ref. albarán o factura) *</div>
                      <input type="text" {...register('extDataExternalNumber', { required: true })} className={clsx({ error: errors?.extDataExternalNumber })} placeholder="Introduce el dato..." />
                      {errors.extDataExternalNumber && <div className="error-message">{errors.extDataExternalNumber.message}</div>}
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col">
                      <div className={clsx('input-name', { error: errors?.extDataInvoiceNumber })}>Número factura</div>
                      <input type="text" {...register('extDataInvoiceNumber')} className={clsx({ error: errors?.extDataInvoiceNumber })} placeholder="Nº factura" />
                      {errors.extDataInvoiceNumber && <div className="error-message">{errors.extDataInvoiceNumber.message}</div>}
                    </div>
                    <div className="col">
                      <div className={clsx('input-name', { error: errors?.extDataInvoiceDate })}>Fecha factura</div>
                      <input type="date" {...register('extDataInvoiceDate')} className={clsx({ error: errors?.extDataInvoiceDate })} />
                      {errors.extDataInvoiceDate && <div className="error-message">{errors.extDataInvoiceDate.message}</div>}
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-6">
                      <input type="checkbox" {...register('extDataEquivalenceSurcharge')} />
                      <label className={clsx('input-name ms-2', { error: errors?.extDataEquivalenceSurcharge })}>Recargo equivalencia</label>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
          {importPhase === ImportPhase.MapFields && (
            <div>
              <div className="text-center w-75 mx-auto mb-4">
                <p>Selecciona qué columnas de tu CSV se corresponden con los campos en Sierra.</p>
                <p>Solo se importarán los datos de las columnas de tu csv que asocies a continuación. </p>
                <p>
                  <strong>¡Muy importante!</strong>
                  <br />
                  Todas las columnas que asocies se importarán sustituirás el valor existente por un valor vacío.
                </p>
                <p>
                  Si necesitas ayuda:{' '}
                  <a href="https://sierraretail.es/ayuda" target="_blank" rel="noreferrer">
                    https://sierraretail.es/ayuda
                  </a>
                </p>
              </div>
              <div className="container-selects">
                {csvColumnsDtos.map((csvColumnDto: CsvColumnDto) => {
                  return (
                    <div key={csvColumnDto.propertyName} className="d-flex flex-row align-items-center container-select-column">
                      <div className="d-flex flex-column container-key">
                        <label>
                          {csvColumnDto.suggestedColumnName}
                          {csvColumnDto.mandatory && <span className="mandatory">*</span>}
                        </label>
                        <span className="description">{csvColumnDto.values.join(', ')}</span>
                      </div>
                      <select {...register(csvColumnDto.propertyName, { required: csvColumnDto.mandatory })} className={clsx({ error: errors[csvColumnDto.propertyName] })}>
                        <option value="">Selecciona una opción</option>
                        {availableColumns.map((field: string) => {
                          return (
                            <option key={field} value={field}>
                              {field}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
                })}
              </div>
              {existErrors && (
                <p className="display-block w-100 align-items-center error text-center">
                  <AlertTriangle size={16} className="me-2" />
                  Faltan por seleccionar campos obligatorios.
                </p>
              )}
            </div>
          )}
          {importPhase === ImportPhase.CheckMappings && (
            <div>
              <p>Por favor, comprueba que coinciden los campos. Una vez aprobado no se podrá modificar.</p>
              <div className="container-mappings">
                {csvColumnsDtos.map((csvColumnDto: CsvColumnDto) => {
                  return (
                    <div key={csvColumnDto.propertyName} className="row container-key-value">
                      <div className="col-6 key text-end">{csvColumnDto.suggestedColumnName}:</div>
                      <div className="col-6 value">{getValues()[csvColumnDto.propertyName] || '-'}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {importPhase === ImportPhase.ConfirmMapping && (
            <div className="container-confirm">
              <p className="title">AVISO</p>
              <p className="title">¡Muy importante!</p>
              <p className="description">
                Muy importante haber revisado los datos que se van a subir.
                <br />
                Una vez realizada la importación no se podrá deshacer y si los productos que estás importando ya existían se reemplazará toda la información que existiera por los nuevos datos
                importados en tu CSV.
              </p>
              <p className="description">¿Estás seguro de que deseas continuar con esta acción?</p>
            </div>
          )}
          {importPhase === ImportPhase.ImportResult && (
            <div className="container-import-result">
              <div className="d-flex justify-content-around container-results">
                <div className="d-flex flex-row">
                  <label>Total:</label>
                  <span>{importCsvResultDto!.fileRows}</span>
                </div>
                <div className="d-flex flex-row">
                  <label>Nuevos:</label>
                  <span>{importCsvResultDto!.insertedRows}</span>
                </div>
                <div className="d-flex flex-row">
                  <label>Actualizados:</label>
                  <span>{importCsvResultDto!.updatedRows}</span>
                </div>
                <div className="d-flex flex-row">
                  <label>Ignorados:</label>
                  <span>{importCsvResultDto!.ignoredRows}</span>
                </div>
              </div>
              <div className="container-table">
                <table>
                  <thead>
                    <tr>
                      <th>Fila</th>
                      <th>Error</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importCsvResultDto!.detailIgnoredRows.length === 0 ? (
                      <tr>
                        <td colSpan={2}>No hay errores</td>
                      </tr>
                    ) : (
                      importCsvResultDto!.detailIgnoredRows.map((detailIgnoredRowDto: DetailIgnoredRowDto, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{detailIgnoredRowDto.row}</td>
                            <td>{detailIgnoredRowDto.reason}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row">
          {importPhase === ImportPhase.UploadCsv && (
            <button
              disabled={availableColumns.length === 0}
              className="modal-button"
              type="button"
              onClick={async () => {
                switch (entity) {
                  case Entity.DeliveryNote:
                    if (!(await trigger())) {
                      return;
                    }
                    break;
                }
                setImportPhase(ImportPhase.MapFields);
              }}
            >
              Siguiente
            </button>
          )}
          {importPhase === ImportPhase.MapFields && (
            <React.Fragment>
              <button
                className="modal-button back-button"
                type="button"
                onClick={() => {
                  reset();
                  setImportPhase(ImportPhase.UploadCsv);
                }}
              >
                Volver
              </button>
              <button disabled={existErrors} className="modal-button next-button" type="submit">
                Siguiente
              </button>
            </React.Fragment>
          )}
          {importPhase === ImportPhase.CheckMappings && (
            <React.Fragment>
              <button className="modal-button back-button" type="button" onClick={() => setImportPhase(ImportPhase.MapFields)}>
                Volver
              </button>
              <button disabled={existErrors} className="modal-button next-button" type="button" onClick={() => setImportPhase(ImportPhase.ConfirmMapping)}>
                Confirmar importación
              </button>
            </React.Fragment>
          )}
          {importPhase === ImportPhase.ConfirmMapping && (
            <React.Fragment>
              <button disabled={requesting} className="modal-button back-button-red" type="button" onClick={() => setImportPhase(ImportPhase.CheckMappings)}>
                Volver
              </button>
              <button disabled={requesting} className="modal-button next-button" type="button" onClick={importCsv}>
                Aceptar
              </button>
            </React.Fragment>
          )}
          {importPhase === ImportPhase.ImportResult && (
            <button disabled={requesting} className="modal-button" type="button" onClick={() => closeModal(true)}>
              Finalizar
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default ImportCsvModal;
