import { useSelector } from 'react-redux';
import { formatNumber } from '../services/helpers';
import { saleSelector } from '../store/sale-slice';

const SaleSubtotalsReturns = () => {
  const { total, totalIva, totalDiscount } = useSelector(saleSelector);

  return (
    <div className="pos-subtotals noselect">
      <div className="d-flex flex-column mb-3 totals mt-2">
        <div className="d-flex flex-row justify-content-end mb-1 taxes">
          <div className="">Total IVA ({formatNumber(parseFloat(process.env.REACT_APP_IVA))}%):</div>
          <div className="ms-1">{formatNumber(totalIva)}€</div>
        </div>
        <div className="d-flex flex-row justify-content-end mb-1 taxes">
          <div className="">Descuento:</div>
          <div className="ms-1">{formatNumber(totalDiscount)}€</div>
        </div>
        <div className="d-flex flex-row justify-content-end mb-1 total">
          <div className="">Total:</div>
          <div className="ms-1">{formatNumber(total)}€</div>
        </div>
      </div>
    </div>
  );
};

export default SaleSubtotalsReturns;
