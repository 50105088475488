import { UilAngleRight } from '@iconscout/react-unicons';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { ChevronDown, Download, Edit, Maximize2, Minimize2, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Row } from 'react-table';
import CategorySubcategoryModal from '../components/category-subcategory-modal';
import ConfirmModal from '../components/confirm-modal';
import Table from '../components/table';
import { CategoryProductsCountDto, SubCategoryProductsCountDto } from '../interfaces/category-products-count.dto';
import { Organization } from '../interfaces/organization';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const CategoriesView = () => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [categories, setCategories] = useState<CategoryProductsCountDto[]>([]);
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<CategoryProductsCountDto | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategoryProductsCountDto | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);

  const getCategories = async () => {
    setRequesting(true);
    const categories: CategoryProductsCountDto[] = await api.getCategoriesSubcategoriesProductsCount(organization!.id);
    setCategories(categories);
    setRequesting(false);
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data: any[] = useMemo(() => {
    return categories.map((category: CategoryProductsCountDto) => {
      return {
        id: category.id,
        name: category.name,
        numProducts: category.numProducts,
        subRows: category.subCategories,
      };
    });
  }, [categories]);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        accessor: 'expander',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, toggleAllRowsExpanded }: any) => (
          <span
            className={clsx('expander', { active: isAllRowsExpanded })}
            {...getToggleAllRowsExpandedProps({
              onClick: () => {
                toggleAllRowsExpanded(!isAllRowsExpanded);
              },
            })}
          >
            {isAllRowsExpanded ? <Minimize2 size={14} color="#808a95" /> : <Maximize2 size={14} color="#fff" />}
          </span>
        ),
        maxWidth: 10,
        minWidth: 5,
        width: 5,
        Cell: ({ row }: any) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property and paddingLeft to indicate the depth of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
                onClick: () => {
                  row.toggleRowExpanded();
                },
              })}
            >
              {row.isExpanded ? <ChevronDown size={14} /> : <UilAngleRight size={14} />}
            </span>
          ) : null,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Nombre',
        accessor: 'name',
        filter: 'fuzzyText',
        Cell: ({ row, value }: any) => {
          if (row.depth === 0) {
            return (
              <strong
                style={{
                  color: '#222E3D',
                }}
              >
                {value}
              </strong>
            );
          } else {
            return (
              <strong
                style={{
                  color: '#808a95',
                  paddingLeft: `${row.depth * 1}rem`,
                }}
              >
                - {value}
              </strong>
            );
          }
        },
      },
      {
        Header: 'Productos',
        accessor: 'numProducts',
        disableFilters: true,
        Cell: ({ row, value }: any) => {
          if (row.depth === 0) {
            return (
              <strong
                style={{
                  color: '#222E3D',
                }}
              >
                {value}
              </strong>
            );
          } else {
            return (
              <strong
                style={{
                  color: '#808a95',
                }}
              >
                {value}
              </strong>
            );
          }
        },
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                size={16}
                color="#222E3D"
                onClick={() => {
                  if (row.depth === 0) {
                    setSelectedCategory(row.original);
                    setSelectedSubCategory(null);
                  } else {
                    setSelectedCategory(null);
                    setSelectedSubCategory(row.original);
                  }
                  setShowCategoryModal(true);
                }}
                type="button"
                className="mx-2"
              />
              <Trash2
                type="button"
                className="mx-2"
                onClick={() => {
                  if (row.depth === 0) {
                    setSelectedCategory(row.original);
                    setSelectedSubCategory(null);
                  } else {
                    setSelectedCategory(null);
                    setSelectedSubCategory(row.original);
                  }
                  setShowConfirmModal(true);
                }}
                size={14}
              />
            </React.Fragment>
          );
        },
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]) as any;

  const newCategory = () => {
    setSelectedCategory(null);
    setShowCategoryModal(true);
  };

  const onCloseCategoryModal = (refresh: boolean) => {
    if (refresh) {
      getCategories();
    }
    setShowCategoryModal(false);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
  };

  const deleteCategory = async (c: CategoryProductsCountDto) => {
    try {
      await api.deleteCategory(c.id);
      const cats: CategoryProductsCountDto[] = [...categories];
      const index: number = cats.findIndex((cat: CategoryProductsCountDto) => cat.id === c.id);
      cats.splice(index, 1);
      setCategories(cats);
      myToastr.success('Categoría eliminada correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const deleteSubCategory = async (sc: SubCategoryProductsCountDto) => {
    try {
      await api.deleteSubCategory(sc.id);
      myToastr.success('Subcategoría eliminada correctamente');
      getCategories();
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const category: any = fr.original;
      data.push({
        Categoria: category.name,
        Subcategoria: '',
        Productos: category.numProducts,
      });
      category.subRows.forEach((sc: SubCategoryProductsCountDto) => {
        data.push({
          Categoria: category.name,
          Subcategoria: sc.name,
          Productos: sc.numProducts,
        });
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="categories p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Categorías</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="categorias.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <button className="d-flex align-items-center create-button" onClick={newCategory}>
          <Plus className="me-1" size={14} /> Nueva Categoría o Subcat.
        </button>
      </div>
      <Table
        data={data}
        columns={columns}
        noDataMessage="No hay categorías"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <CategorySubcategoryModal show={showCategoryModal} closeModal={onCloseCategoryModal} categoryId={selectedCategory?.id || null} subCategoryId={selectedSubCategory?.id || null} />
      {selectedCategory && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Categoría"
          content={`¿Estás seguro que quieres eliminar la categoría ${selectedCategory.name}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteCategory(selectedCategory);
            }
            setSelectedCategory(null);
          }}
        ></ConfirmModal>
      )}
      {selectedSubCategory && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Subcategoría"
          content={`¿Estás seguro que quieres eliminar la subcategoría ${selectedSubCategory.name}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteSubCategory(selectedSubCategory);
            }
            setSelectedSubCategory(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default CategoriesView;
