import { useRef, useState } from 'react';
import { X } from 'react-feather';
import Modal from 'react-modal';

interface Props {
  show: boolean;
  onCloseModal: (pin: string | null) => void;
}

const PinModal = (props: Props) => {
  const containerButtonsRef = useRef<any>(null);
  const [pin, setPin] = useState<number[]>([]);

  const onClick = (value: number) => {
    if (pin.length < 4) {
      setPin([...pin, value]);
    }
  };

  const close = () => {
    props.onCloseModal(null);
    clear();
  };

  const save = () => {
    props.onCloseModal(pin.join(''));
    clear();
  };

  const clear = () => {
    setPin([]);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Backspace') {
      const newPin: number[] = pin.slice(0, -1);
      setPin(newPin);
    } else if (e.key === 'Backspace' || e.key === 'c') {
      clear();
    } else if (e.key === 'Enter' && pin.length === 4) {
      save();
    } else if (e.key >= 0 && e.key <= 9 && pin.length < 4) {
      const digit: number = parseInt(e.key, 10);
      onClick(digit);
    }
  };

  return (
    <Modal
      className="vercomi-modal pin-modal"
      isOpen={props.show}
      onRequestClose={close}
      contentLabel="Pin Modal"
      shouldCloseOnOverlayClick={false}
      onAfterOpen={() => {
        if (containerButtonsRef.current) {
          containerButtonsRef.current.focus();
        }
      }}
    >
      <div
        ref={containerButtonsRef}
        tabIndex={-1} // Enables key handlers on div
        onKeyDown={onKeyDown}
        style={{ outline: 'none' }}
      >
        <h1>Código PIN</h1>
        <button className="close-button" onClick={close}>
          <X size={16} />
        </button>
        <div className="container-buttons">
          <div className="row">
            <div className="col">
              <button onClick={() => onClick(1)}>1</button>
            </div>
            <div className="col">
              <button onClick={() => onClick(2)}>2</button>
            </div>
            <div className="col">
              <button onClick={() => onClick(3)}>3</button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button onClick={() => onClick(4)}>4</button>
            </div>
            <div className="col">
              <button onClick={() => onClick(5)}>5</button>
            </div>
            <div className="col">
              <button onClick={() => onClick(6)}>6</button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button onClick={() => onClick(7)}>7</button>
            </div>
            <div className="col">
              <button onClick={() => onClick(8)}>8</button>
            </div>
            <div className="col">
              <button onClick={() => onClick(9)}>9</button>
            </div>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="col">
              <button onClick={() => onClick(0)}>0</button>
            </div>
            <div className="col">
              <button onClick={() => clear()}>C</button>
            </div>
          </div>
        </div>
        <div className="container-dots">
          {pin.map((_: number, index: number) => (
            <span key={index} className="dot"></span>
          ))}
          <span></span>
        </div>
        <button className={`save-button ${pin.length < 4 ? 'disabled' : ''}`} onClick={save} disabled={pin.length < 4}>
          Aceptar
        </button>
      </div>
    </Modal>
  );
};

export default PinModal;
