import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Check, Download, Edit, Plus, Trash2, XCircle } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Row } from 'react-table';
import BrandModal from '../components/brand-modal';
import ConfirmModal from '../components/confirm-modal';
import Table from '../components/table';
import { Role } from '../enums/role';
import { Brand } from '../interfaces/brand';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { ChangeEditableDto } from '../interfaces/toggle-editable-products.dto';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const BrandsView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [showBrandModal, setShowBrandModal] = useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [showEnableEditableProductsModal, setShowEnableEditableProductsModal] = useState<boolean>(false);
  const [showDisblableEditableProductsModal, setShowDisableEditableProductsModal] = useState<boolean>(false);

  const getBrands = async () => {
    setRequesting(true);
    const brands: Brand[] = await api.getBrands();
    setBrands(brands);
    setRequesting(false);
  };

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = React.useMemo(() => {
    const columnsData: any[] = [{ Header: 'Nombre', accessor: 'name', filter: 'fuzzyText' }];
    if (user.role === Role.SuperAdmin) {
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                size={16}
                color="#222E3D"
                onClick={() => {
                  setSelectedBrand(row.original);
                  setShowBrandModal(true);
                }}
                type="button"
                className="mx-2"
              />
              <div style={{ display: 'inline' }} title="Habilitar edición de productos">
                <Check
                  size={16}
                  color="#222E3D"
                  onClick={() => {
                    setSelectedBrand(row.original);
                    setShowEnableEditableProductsModal(true);
                  }}
                  type="button"
                  className="mx-2"
                />
              </div>
              <div style={{ display: 'inline' }} title="Deshabilitar edición de productos">
                <XCircle
                  size={16}
                  color="#222E3D"
                  onClick={() => {
                    setSelectedBrand(row.original);
                    setShowDisableEditableProductsModal(true);
                  }}
                  type="button"
                  className="mx-2"
                />
              </div>
              <Trash2
                type="button"
                className="mx-2"
                onClick={() => {
                  setSelectedBrand(row.original);
                  setShowConfirmModal(true);
                }}
                size={14}
              />
            </React.Fragment>
          );
        },
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, brands]) as any;

  const newBrand = () => {
    setSelectedBrand(null);
    setShowBrandModal(true);
  };

  const onBrandModalClose = (brand: Brand | null) => {
    if (brand) {
      getBrands();
    }
    setShowBrandModal(false);
    setSelectedBrand(null);
  };

  const deleteBrand = async (b: Brand) => {
    try {
      await api.deleteBrand(b.id);
      const brs: Brand[] = [...brands];
      const index: number = brs.findIndex((br: Brand) => br.id === b.id);
      brs.splice(index, 1);
      setBrands(brs);
      myToastr.success('Marca eliminada correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const brand: Brand = fr.original;
      data.push({
        Nombre: brand.name,
      });
    });
    setCsvData(data);
  };

  const updateEditableProducts = async (editable: boolean) => {
    try {
      const changeEditableDto: ChangeEditableDto = { editable };
      await api.updateEditableFieldInProductsGivenBrandId(selectedBrand!.id, changeEditableDto);
      myToastr.success('Actualización realizada con éxito');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
    setShowEnableEditableProductsModal(false);
    setShowDisableEditableProductsModal(false);
    setSelectedBrand(null);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="brands p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Marcas</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="marcas.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {user.role === Role.SuperAdmin && (
          <button className="d-flex align-items-center create-button" onClick={newBrand}>
            <Plus className="me-1" size={14} /> Nueva marca
          </button>
        )}
      </div>
      <Table
        data={brands}
        columns={columns}
        noDataMessage="No hay marcas"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <BrandModal show={showBrandModal} closeModal={onBrandModalClose} brand={selectedBrand}></BrandModal>
      {selectedBrand && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Marca"
          content={`¿Estás seguro que quieres eliminar la marca ${selectedBrand.name}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteBrand(selectedBrand);
            }
            setSelectedBrand(null);
          }}
        ></ConfirmModal>
      )}
      {selectedBrand && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showEnableEditableProductsModal}
          title={selectedBrand.name}
          content={`¿Estás seguro que quieres habilitar la edición de todos los productos de la marca ${selectedBrand.name}?`}
          closeModal={(result: boolean) => {
            if (!result) {
              setShowEnableEditableProductsModal(false);
              return;
            }
            updateEditableProducts(true);
          }}
        />
      )}
      {selectedBrand && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showDisblableEditableProductsModal}
          title={selectedBrand.name}
          content={`¿Estás seguro que quieres deshabilitar la edición de todos los productos de la marca ${selectedBrand.name}?`}
          closeModal={(result: boolean) => {
            if (!result) {
              setShowDisableEditableProductsModal(false);
              return;
            }
            updateEditableProducts(false);
          }}
        />
      )}
    </div>
  );
};

export default BrandsView;
