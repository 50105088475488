import { forwardRef } from 'react';

const CustomMenuDropdown = forwardRef((props: any, ref: any) => {
  return (
    <div ref={ref} style={props.style} className={`${props.className} py-0`} aria-labelledby={props.labeledBy}>
      {props.children}
    </div>
  );
});

export default CustomMenuDropdown;
