import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { OrganizationType } from '../enums/organization-type';
import { useStores } from '../hooks/use-stores.hook';
import { AcceptOrganizationRelationDto } from '../interfaces/accept-organization-relation.dto';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { OrganizationClient } from '../interfaces/organization-client';
import { Store } from '../interfaces/store';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  organizationClient: OrganizationClient;
  show: boolean;
  closeModal: (organizationClient: OrganizationClient | null) => void;
}

const initialValues: AcceptOrganizationRelationDto = {
  storeId: -1,
};

const AcceptOrganizationRelationModal = ({ organizationClient, show, closeModal }: Props) => {
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const stores: Store[] = useStores();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AcceptOrganizationRelationDto>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (show) {
      return;
    }
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!organizationClient || !organizationClient.relatedStoreId || stores.length === 0) {
      return;
    }
    reset({
      storeId: organizationClient.relatedStoreId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationClient, stores]);

  const close = () => {
    closeModal(null);
  };

  const onSubmit = async (data: AcceptOrganizationRelationDto) => {
    try {
      const updatedOrganizationClient: OrganizationClient = await api.updateOrganizationClient(organizationClient.id, data);
      closeModal(updatedOrganizationClient);
      myToastr.success('La relación se ha actualizado correctamente');
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form brand-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title"></div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.storeId })}>
                {organizationType === OrganizationType.B2B ? 'Almacén para recibir las órdenes de compra' : 'Tienda para recibir las órdenes de compra'} *
              </div>
              <select {...register('storeId', { required: true, valueAsNumber: true, min: 1 })} className={clsx({ error: errors?.storeId })}>
                <option value={-1}>Selecciona una opción</option>
                {stores.map((store: Store) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
              </select>
              {errors.storeId && <div className="error-message">{errors.storeId.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default AcceptOrganizationRelationModal;
