import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { Image, Search } from 'react-feather';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Order } from '../enums/order';
import { Brand } from '../interfaces/brand';
import { Category } from '../interfaces/category';
import { GetPaginatedStock } from '../interfaces/get-paginated-stock';
import { Organization } from '../interfaces/organization';
import { PaginatedDto } from '../interfaces/paginated.dto';
import { Stock } from '../interfaces/stock';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { formatNumber } from '../services/helpers';
import { useAppSelector } from '../store/hooks';
import { addItem } from '../store/pos-slice';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

const getStock = async (getPaginatedStock: GetPaginatedStock, cb: (result: PaginatedDto<Stock>) => void) => {
  const paginatedDto: PaginatedDto<Stock> = await api.getPaginatedPosStock(getPaginatedStock);
  cb(paginatedDto);
};

const debouncedGetStock = debounce((getPaginatedStock: GetPaginatedStock, cb: (result: PaginatedDto<Stock>) => void) => {
  getStock(getPaginatedStock, cb);
}, 500);

const PosProductsFinder = () => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const inputRef = useRef<any>(null);
  const dispatch = useDispatch();
  const [stock, setStock] = useState<Stock[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const { selectedStoreId } = useSelector(storeSelector);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [paginatedDto, setPaginatedDto] = useState<PaginatedDto<Stock> | null>(null);
  const [getPaginatedStock, setGetPaginatedStock] = useState<GetPaginatedStock>({
    organizationId: organization.id,
    orderField: 'product.name',
    order: Order.Asc,
    limit: Constants.LIMIT_RESULTS,
    page: 1,
    query: '',
    brandId: -1,
    categoryId: -1,
    storeId: selectedStoreId,
  });

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.focus();
  }, [inputRef]);

  useEffect(() => {
    const getCategories = async () => {
      const categories: Category[] = await api.getCategories();
      setCategories(categories);
    };
    const getBrands = async () => {
      const brands: Brand[] = await api.getBrands(organization!.id);
      setBrands(brands);
    };
    getCategories();
    getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedStoreId >= 1) {
      setGetPaginatedStock({ ...getPaginatedStock, storeId: selectedStoreId });
      setPaginatedDto(null);
      setStock([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId]);

  useEffect(() => {
    setRequesting(true);
    debouncedGetStock(getPaginatedStock!, (pdto: PaginatedDto<Stock>) => {
      setPaginatedDto(pdto);
      setStock([...stock, ...pdto.results]);
      setRequesting(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPaginatedStock]);

  const fetchMoreData = () => {
    setGetPaginatedStock({ ...getPaginatedStock, page: getPaginatedStock.page + 1 });
  };

  return (
    <div className="product-finder d-flex flex-column p-4">
      <div className="flex flex-column">
        <div className="d-flex justify-content-center align-items-center mb-2">
          <h1 className="mb-0">Productos</h1>
        </div>
        <div className="position-relative mb-2">
          <Search className="position-absolute end-0 top-50 translate-middle-y pe-2" />
          <input
            ref={inputRef}
            type="text"
            placeholder="Buscar productos"
            value={getPaginatedStock.query}
            onChange={(e: any) => {
              setPaginatedDto(null);
              setStock([]);
              setGetPaginatedStock({ ...getPaginatedStock, page: 1, query: e.target.value });
            }}
            disabled={selectedStoreId <= 0}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <select
            name="category"
            onChange={(e: any) => {
              setPaginatedDto(null);
              setStock([]);
              setGetPaginatedStock({ ...getPaginatedStock, page: 1, brandId: parseInt(e.target.value, 10) });
            }}
            disabled={selectedStoreId <= 0}
          >
            <option value={-1}>Todas las marcas</option>
            {brands.map((brand: Brand) => (
              <option key={brand.id} value={brand.id}>
                {brand.id} - {brand.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col">
          <select
            name="category"
            onChange={(e: any) => {
              setPaginatedDto(null);
              setStock([]);
              setGetPaginatedStock({ ...getPaginatedStock, page: 1, categoryId: parseInt(e.target.value, 10) });
            }}
            disabled={selectedStoreId <= 0 || getPaginatedStock.brandId <= 0}
          >
            <option value={-1}>Todas las categorías</option>
            {categories.map((category: Category) => (
              <option key={category.id} value={category.id}>
                {category.id} - {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <InfiniteScroll
        dataLength={stock.length}
        next={fetchMoreData}
        hasMore={paginatedDto != null ? paginatedDto.currentPage < paginatedDto.totalPages : false}
        loader={
          <div className="text-center mt-2">
            <Loader type="TailSpin" color="#252E3C" height={30} width={30} />
          </div>
        }
        endMessage={
          requesting ? (
            <div className="text-center mt-2">
              <Loader type="TailSpin" color="#252E3C" height={30} width={30} />
            </div>
          ) : null
        }
        height={'calc(100vh - 240px)'}
      >
        <div className="row">
          {stock.map((stock: Stock) => {
            return (
              <div
                key={stock.id}
                className="product-card col-3 col-md-3 mb-4"
                onClick={() => {
                  dispatch(addItem(stock));
                  if (getPaginatedStock.query) {
                    setPaginatedDto(null);
                    setStock([]);
                    setGetPaginatedStock({ ...getPaginatedStock, page: 1, query: '' });
                  }
                  inputRef.current.focus();
                }}
              >
                <div className="d-flex flex-row flex-md-column">
                  {stock.product.images.length > 0 ? (
                    <LazyLoadImage className="img-thumbnail mb-1" src={process.env.REACT_APP_PUBLIC_URL + stock.product.images[0].path} alt={stock.product.name} width="100px" />
                  ) : (
                    <Image className="img-thumbnail mb-1" size={100} color="#808A95" />
                  )}
                  <div className="d-flex flex-column justify-content-start align-items-start product-card-info">
                    <div className="name ellipsis-text-multiline-2">{stock.product.name}</div>
                    <div className="sku">
                      {stock.product.sku} ({stock.quantity})
                    </div>
                    <div className="price mt-1">{formatNumber(stock.pvp)}€</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default PosProductsFinder;
