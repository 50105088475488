import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Role } from '../enums/role';
import { CashRegisterCheck } from '../interfaces/cash-register-check';
import { PaymentOnAccount } from '../interfaces/payment-on-account';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { formatNumber } from '../services/helpers';
import { useAppSelector } from '../store/hooks';
import { repairSelector, updatePaymentOnAccount } from '../store/repair-slice';
import { RootState } from '../store/store';
import SelectPaymentMethodModal from './select-payment-method-modal';

const TablePaymentsOnAccount = () => {
  const user: User | null = useAppSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const { repair } = useSelector(repairSelector);
  const [currentCashRegisterCheck, setCurrentCashRegisterCheck] = useState<CashRegisterCheck | null>(null);
  const [selectedPaymentOnAccount, setSelectedPaymentOnAccount] = useState<PaymentOnAccount | null>(null);

  const onCloseSelectPaymentMethod = (newPaymentMethodId: number) => {
    dispatch(
      updatePaymentOnAccount({
        paymentOnAccountId: selectedPaymentOnAccount!.id,
        newPaymentMethodId,
      }),
    );
    setSelectedPaymentOnAccount(null);
  };

  const getCurrentCashRegisterCheck = useCallback(async () => {
    try {
      const crc: CashRegisterCheck = await api.getCurrentCashRegisterCheck(repair!.storeId);
      setCurrentCashRegisterCheck(crc);
    } catch (e) {}
  }, [repair]);

  useEffect(() => {
    if (!repair || !user) {
      return;
    }
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      getCurrentCashRegisterCheck();
    }
  }, [getCurrentCashRegisterCheck, repair, user]);

  if (!repair) {
    return null;
  }

  const numPaymentsOnAccount: number = repair.paymentsOnAccount?.length || 0;

  return (
    <div className="table-booking-payments">
      <table className="my-table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Método de pago</th>
            <th>Cantidad</th>
            {currentCashRegisterCheck !== null && <th>Acciones</th>}
          </tr>
        </thead>
        <tbody>
          {numPaymentsOnAccount === 0 ? (
            <tr className="text-center">
              <td colSpan={currentCashRegisterCheck !== null ? 4 : 3}>No se han añadido pagos a cuenta</td>
            </tr>
          ) : (
            repair.paymentsOnAccount.map((paymentOnAccount: PaymentOnAccount) => (
              <tr key={paymentOnAccount.id}>
                <td>{moment(paymentOnAccount.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                <td>{paymentOnAccount.paymentMethod.name}</td>
                <td>{formatNumber(paymentOnAccount.amount)}€</td>
                <td>
                  {currentCashRegisterCheck !== null && currentCashRegisterCheck.createdAt <= paymentOnAccount.createdAt && (
                    <div onClick={() => setSelectedPaymentOnAccount(paymentOnAccount)} className="cursor-pointer" title="Cambiar método de pago">
                      <RefreshCw size={14} />
                    </div>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <SelectPaymentMethodModal
        amount={selectedPaymentOnAccount?.amount || 0}
        customerId={repair.customerId}
        onCancel={() => setSelectedPaymentOnAccount(null)}
        onSave={onCloseSelectPaymentMethod}
        show={selectedPaymentOnAccount !== null}
        storeId={repair.storeId}
        paymentMethodId={selectedPaymentOnAccount?.paymentMethod.id || -1}
      />
    </div>
  );
};

export default TablePaymentsOnAccount;
