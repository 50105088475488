import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { ShoppingByBrand, ShoppingStats } from '../interfaces/shopping-stats';
import { api } from '../services/api';
import { formatNumber, slugify } from '../services/helpers';
import myToastr from '../services/toastr';
import { storeSelector } from '../store/store-slice';
import Table from './table';

interface Props {
  organizationId: number;
  storeId: number;
  startDate: Date | null;
  endDate: Date | null;
}

const DashboardShoppingStats = ({ organizationId, storeId, startDate, endDate }: Props) => {
  const { store } = useSelector(storeSelector);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [shoppingStats, setShoppingStats] = useState<ShoppingStats | null>(null);
  const [csvData, setCsvData] = useState<any[]>([]);
  const columnsShoppingByBrand: any[] = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Marca',
        accessor: 'brandName',
        disableFilters: true,
      },
      {
        Header: 'Unidades',
        accessor: 'units',
        Cell: ({ value }: any) => {
          return formatNumber(value, true);
        },
        disableFilters: true,
      },
      {
        Header: 'Precio Coste',
        accessor: 'costPrice',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
      {
        Header: 'PVP',
        accessor: 'pvp',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
      {
        Header: 'Margen',
        accessor: 'margin',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}%`;
        },
        disableFilters: true,
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingStats?.shoppingByBrand]) as any;

  useEffect(() => {
    if (startDate !== null && endDate !== null && (!moment(startDate).isValid() || !moment(endDate).isValid())) {
      setShoppingStats(null);
      return;
    }
    const getSaleStats = async () => {
      try {
        setRequesting(true);
        const ss: ShoppingStats = await api.getShoppingStats(organizationId, storeId, startDate, endDate);
        setShoppingStats(ss);
        setRequesting(false);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    };
    getSaleStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, storeId, startDate, endDate]);

  const generateCsv = () => {
    const data: any[] = [];
    shoppingStats!.shoppingByBrand.forEach((shoppingByBrand: ShoppingByBrand) => {
      data.push({
        Marca: shoppingByBrand.brandName,
        Unidades: shoppingByBrand.units,
        'Precio Coste': shoppingByBrand.costPrice,
        PVP: shoppingByBrand.pvp,
        Margen: shoppingByBrand.margin,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  if (!shoppingStats) {
    return null;
  }

  return (
    <div className="dashboard-purchases-stats">
      <div className="row">
        <div className="col">
          <div className="billing-summary mb-3">
            <h4>Compras</h4>
            <div>
              <span className="amount">{formatNumber(shoppingStats.total)}€</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="billing-summary mb-3">
            <h4>Coste Neto</h4>
            <div className="d-flex flex-column">
              <span className="amount">{formatNumber(shoppingStats.cost.total)}€</span>
              <span className="percentage">{formatNumber(shoppingStats.cost.percentage)}%</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="billing-summary mb-3">
            <h4>Impuestos</h4>
            <div className="d-flex flex-column">
              <span className="amount">{formatNumber(shoppingStats.taxes.total)}€</span>
              <span className="percentage">{formatNumber(shoppingStats.taxes.percentage)}%</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="billing-summary mb-3">
            <h4>Gastos</h4>
            <div className="d-flex flex-column">
              <span className="amount">{formatNumber(shoppingStats.withdrawals)}€</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col px-2">
          <div className="dashboard-tab active">
            <div className="d-flex flex-row align-items-center mb-2">
              <h4 className="mb-0 flex-grow-1">Compras</h4>
              <CSVLink filename={`compras_marcas_${slugify(store?.name)}.csv`} className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
                <Download className="me-1" size={14} /> Exportar
              </CSVLink>
            </div>
            {shoppingStats?.shoppingByBrand && <Table columns={columnsShoppingByBrand} data={shoppingStats.shoppingByBrand} noDataMessage={'No hay ventas en el período seleccionado.'} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardShoppingStats;
