import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import OnlineStoreModal from '../components/online-store-modal';
import Table from '../components/table';
import { Role } from '../enums/role';
import { OnlineStore } from '../interfaces/online-store';
import { Organization } from '../interfaces/organization';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { ucFirst } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

function OrganizationColumnFilter(a: any, b: any, c: any, d: any) {
  const map: Map<number, Organization> = new Map<number, Organization>();
  a.data.forEach((onlineStore: OnlineStore) => {
    map.set(onlineStore.organization.id, onlineStore.organization);
  });
  return (
    <select
      value={a.column.filterValue}
      onChange={(e) => {
        a.column.setFilter(parseInt(e.target.value, 10));
      }}
    >
      <option value={-1}>Todos</option>
      {Array.from(map.values()).map((org: Organization) => (
        <option key={org.id} value={org.id}>
          {org.name}
        </option>
      ))}
    </select>
  );
}

function OrganizationSelectFilter(rows: any, columnIds: any, filterValue: any) {
  return filterValue === -1 || isNaN(filterValue) ? rows : rows.filter((row: any) => row.original.organization.id === filterValue);
}

function OnlineStoreStatusColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      <option value={true as any}>Activo</option>
      <option value={false as any}>Inactivo</option>
    </select>
  );
}

const OnlineStoresView = () => {
  const navigate = useNavigate();
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [onlineStores, setOnlineStores] = useState<OnlineStore[]>([]);
  const [showOnlineStoreModal, setShowOnlineStoreModal] = useState<boolean>(false);
  const [selectedOnlineStore, setSelectedOnlineStore] = useState<OnlineStore | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [requesting, setRequesting] = useState<boolean>(false);

  const getOnlineStores = async () => {
    setRequesting(true);
    const onlineStores: OnlineStore[] = await api.getOnlineStores(organization.id);
    setOnlineStores(onlineStores);
    setRequesting(false);
  };

  useEffect(() => {
    if (user.role === Role.SuperAdmin) {
      getOnlineStores();
    } else if (user.role === Role.Admin || user.role === Role.Manager) {
      if (organization.enabledOnlineStores) {
        getOnlineStores();
      } else {
        navigate('/pos');
      }
    } else {
      navigate('/pos');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Nombre',
        accessor: 'name',
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const onlineStore: OnlineStore = row.original as OnlineStore;
          return (
            <strong
              onClick={() => {
                setSelectedOnlineStore(onlineStore);
                setShowOnlineStoreModal(true);
              }}
              className="clickable"
            >
              {onlineStore.name}
            </strong>
          );
        },
      },
      {
        Header: 'Organización',
        accessor: 'organization.name',
        Filter: OrganizationColumnFilter,
        filter: OrganizationSelectFilter,
      },
      { Header: 'URL', accessor: 'url', filter: 'fuzzyText' },
      {
        Header: 'Tipo',
        accessor: 'type',
        Cell: ({ value }: any) => {
          return ucFirst(value);
        },
        Filter: OnlineStoreStatusColumnFilter,
      },
      {
        Header: 'Estado',
        accessor: 'isActive',
        Cell: ({ value }: any) => {
          return <span className={`badge ${value ? 'my-success' : 'my-danger'}`}>{value ? 'Activo' : 'Inactivo'}</span>;
        },
        Filter: OnlineStoreStatusColumnFilter,
      },
    ];
    if (user.role === Role.SuperAdmin) {
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <div title="Eliminar tienda online">
              <Trash2
                type="button"
                className="mx-2"
                onClick={() => {
                  setSelectedOnlineStore(row.original);
                  setShowConfirmModal(true);
                }}
                size={14}
              />
            </div>
          );
        },
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineStores, user]) as any;

  const newOnlineStore = () => {
    setSelectedOnlineStore(null);
    setShowOnlineStoreModal(true);
  };

  const onCloseOnlineStoreModal = (onlineStore: OnlineStore | null) => {
    if (onlineStore) {
      getOnlineStores();
    }
    setShowOnlineStoreModal(false);
    setSelectedOnlineStore(null);
  };

  const deleteOnlineStore = async () => {
    try {
      await api.deleteOnlineStore(selectedOnlineStore!.id);
      const strs: OnlineStore[] = [...onlineStores];
      const index: number = strs.findIndex((str: OnlineStore) => str.id === selectedOnlineStore!.id);
      strs.splice(index, 1);
      setOnlineStores(strs);
      myToastr.success('Tienda online eliminada correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const os: OnlineStore = fr.original;
      data.push({
        Nombre: os.name,
        Organización: os.organization.name,
        URL: os.url,
        Tipo: ucFirst(os.type),
        Estado: os.isActive ? 'Activo' : 'Inactivo',
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Tiendas online</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="tiendas_online.csv" className="d-flex align-items-center export-csv" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {user.role === Role.SuperAdmin && (
          <React.Fragment>
            <div
              className="export-csv cursor-pointer me-2"
              onClick={() =>
                window.open(
                  `https://auth.miravia.com/apps/oauth/authorize?response_type=code&redirect_uri=${process.env.REACT_APP_API_URL}/miravia/webhook&client_id=${process.env.REACT_APP_MIRAVIA_CLIENT_ID}`,
                  '_blank',
                )
              }
              style={{ height: 'auto' }}
            >
              Iniciar sesión Miravia
            </div>
            <button className="d-flex align-items-center create-button" onClick={newOnlineStore}>
              <Plus className="me-1" size={14} /> Nueva tienda online
            </button>
          </React.Fragment>
        )}
      </div>
      <Table
        data={onlineStores}
        columns={columns}
        noDataMessage="No hay tiendas online"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      {showOnlineStoreModal && <OnlineStoreModal show={showOnlineStoreModal} closeModal={onCloseOnlineStoreModal} onlineStoreId={selectedOnlineStore?.id || null} />}
      {selectedOnlineStore && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar tienda online"
          content={`¿Estás seguro que quieres eliminar la tienda online ${selectedOnlineStore.name}? Todas las ventas online relacionadas con esta tienda perderán su referencia. Esta acción no puede deshacerse.`}
          closeModal={async (result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              await deleteOnlineStore();
            }
            setSelectedOnlineStore(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default OnlineStoresView;
