import { UilRobot, UilShop } from '@iconscout/react-unicons';
import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Bell, Bookmark, Box, Briefcase, Calendar, Clock, DollarSign, LogOut, Menu, RefreshCw, Settings, ShoppingBag, ShoppingCart, Star, Truck, User as UserIcon, Users } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import retailLogo from '../assets/images/logo.svg';
import wholesalerLogo from '../assets/images/logo_mayoristas.svg';
import mobileLogo from '../assets/images/logo_mobile.svg';
import { EventType } from '../enums/event-type';
import { LocalEvents } from '../enums/local-events';
import { NotificationEntity } from '../enums/notification-entity';
import { OrganizationType } from '../enums/organization-type';
import { PosStatus } from '../enums/pos-status';
import { Role } from '../enums/role';
import { StoreType } from '../enums/store-type';
import { useBasePath } from '../hooks/use-base-path';
import { useStores } from '../hooks/use-stores.hook';
import { Alert } from '../interfaces/alert';
import { GetUserTimeSheet } from '../interfaces/get-user-time-sheets-query';
import { GetUserWorkClockingsQuery } from '../interfaces/get-user-work-clockings-query';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Notification } from '../interfaces/notification';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { UserInOrganization } from '../interfaces/user-in-organization';
import { UserTimeSheet } from '../interfaces/user-time-sheet';
import { UserWorkClocking } from '../interfaces/user-work-clocking';
import { UserWorkClockingDto } from '../interfaces/user-work-clocking.dto';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import eventEmitter from '../services/events';
import { organizationTypeToString } from '../services/helpers';
import myToastr from '../services/toastr';
import { websocket } from '../services/websocket';
import { alertsSelector } from '../store/alerts-slice';
import { authSelector, setOrganizationType } from '../store/auth-slice';
import { useAppSelector } from '../store/hooks';
import { newNotification, notificationsSelector } from '../store/notifications-slice';
import { clearShoppingCart } from '../store/pos-slice';
import { purchaseOrderSelector } from '../store/purchase-order-slice';
import { RootState } from '../store/store';
import { setShowCashRegiserModal, setStoreId, storeSelector } from '../store/store-slice';
import { toggleAside, uiSelector } from '../store/ui-slice';
import CustomMenuDropdown from '../views/custom-menu-dropdown';
import { CashRegisterModal } from './cash-register-modal';
import CustomToggleDropdown from './custom-toggle-dropdown';
import LogoutModal from './logout-modal';
import PinModal from './pin-modal';
import { PinDto } from './pin.dto';
import ScanEanSaleModal from './scan-ean-sale';
import ShoppingCartPurchaseOrder from './shopping-cart-purchase-order';
import UnreadNotifications from './unread-notifications';
import UserWorkClockingModal from './user-work-clocking-modal';
import WithdrawalModal from './withdrawal-modal';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const basePath = useBasePath();
  const { connectedToWs } = useSelector(authSelector);
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const { alerts } = useSelector(alertsSelector);
  const { collapsed } = useSelector(uiSelector);
  const { selectedStoreId, store, showCashRegiserModal } = useSelector(storeSelector);
  const { products: productsInShoppingCart } = useAppSelector(purchaseOrderSelector);
  const { totalUnreadNotifications } = useSelector(notificationsSelector);
  const [showWithdrawalModal, setShowWithdrawalModal] = useState<boolean>(false);
  const stores: Store[] = useStores();
  const [showPurchaseOrderShoppingCartDropdown, setShowPurchaseOrderShoppingCartDropdown] = useState<boolean>(false);
  const [showNotificationsDropdown, setShowNotificationsDropdown] = useState<boolean>(false);
  const [showSettingsDropdown, setShowSettingsDropdown] = useState<boolean>(false);
  const [showUserDropdown, setShowUserDropdown] = useState<boolean>(false);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [showScanEanSaleModal, setShowScanEanSaleModal] = useState<boolean>(false);
  const [userTimeSheet, setUserTimeSheet] = useState<UserTimeSheet | null>(null);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);
  const [userPin, setUserPin] = useState<User | null>(null);
  const [showUserWorkClockingModal, setShowUserWorkClockingModal] = useState<boolean>(false);
  const showOnlineStores: boolean = useMemo(() => {
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      return organizationType === OrganizationType.B2C && organization.enabledOnlineStores;
    } else {
      return false;
    }
  }, [user, organization, organizationType]);
  const showStoreSelector: boolean = useMemo(() => organizationType === OrganizationType.B2C, [organizationType]);
  const activeColor: string = useMemo(() => (organizationType === OrganizationType.B2C ? Constants.ACTIVE_COLOR_RETAIL : Constants.ACTIVE_COLOR_WHOLESALER), [organizationType]);
  const showCashRegisterModal: boolean = useMemo(
    () =>
      organizationType === OrganizationType.B2C &&
      store !== null &&
      (location.pathname === '/pos' ||
        location.pathname === '/ventas' ||
        location.pathname.startsWith('/venta/') ||
        location.pathname === '/reparaciones' ||
        location.pathname.startsWith('/reparacion') ||
        location.pathname === '/gastos'),
    [organizationType, store, location.pathname],
  );
  const showShoppingCartPurchaseOrder = useMemo(
    () => location.pathname === '/orden-compra' || basePath === '/orden-compra/actualizar' || location.pathname === '/pedido' || basePath === `/pedido/actualizar`,
    [location.pathname, basePath],
  );
  const showNewWithdrawal: boolean = useMemo(() => {
    if (!store) {
      return false;
    }
    if (organizationType !== OrganizationType.B2C) {
      return false;
    }
    if (user.role === Role.WholesaleCustomer) {
      return false;
    }
    return true;
  }, [organizationType, store, user]);
  const showNotifications: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showSettings: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showUserWorkClocking: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showWorkShifts: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showPayrolls: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showWholesalers: boolean = useMemo(
    () => organizationType === OrganizationType.B2C && (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.WholesaleCustomer),
    [organizationType, user],
  );
  useEffect(() => {
    getUserTimeSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.stores.length === 0) {
      return;
    }
    if (organizationType === OrganizationType.B2C) {
      return;
    }
    if (selectedStoreId > 0) {
      return;
    }
    const index: number = user.stores.findIndex((s: Store) => s.organizationId === organization.id && s.type === StoreType.B2B);
    if (index === -1) {
      dispatch(setStoreId(-1));
      return;
    }
    // Las organizaciones de tipo mayorista sólo tienen un almacén
    dispatch(setStoreId(user.stores[index].id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, user.stores, organizationType, selectedStoreId]);

  useEffect(() => {
    if (!connectedToWs) {
      return;
    }
    websocket.on(EventType.Notification, (notification: Notification) => {
      dispatch(newNotification(notification));
    });
    return () => {
      websocket.off(EventType.Notification);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedToWs]);

  useEffect(() => {
    if (!connectedToWs) {
      return;
    }
    myToastr.clear();
    const refs: JQuery<HTMLElement>[] = [];
    for (const alert of alerts) {
      const ref = myToastr[alert.type](alert.description, alert.title, {
        closeButton: true,
        timeOut: 0,
        extendedTimeOut: 0,
        tapToDismiss: false,
        onCloseClick: () => onCloseClick(alert),
      });
      ref.attr('alert-id', alert.id.toString());
      refs.push(ref);
    }
    websocket.on(EventType.AlertRead, (alert: Alert) => {
      const index: number = refs.findIndex((ref) => ref.attr('alert-id') === alert.id.toString());
      if (index >= 0) {
        refs[index].remove();
        refs.splice(index, 1);
      }
    });
    websocket.on(EventType.AlertNew, (alert: Alert) => {
      const ref = myToastr[alert.type](alert.description, alert.title, {
        closeButton: true,
        timeOut: 0,
        extendedTimeOut: 0,
        tapToDismiss: false,
        onCloseClick: () => onCloseClick(alert),
      });
      ref.attr('alert-id', alert.id.toString());
      refs.push(ref);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedToWs, alerts]);

  useEffect(() => {
    if (!connectedToWs) {
      return;
    }
    websocket.emit(EventType.UserInStore, selectedStoreId > 0 ? selectedStoreId : null);
  }, [selectedStoreId, connectedToWs]);

  const getUserTimeSheet = async () => {
    try {
      const getUserTimeSheet: GetUserTimeSheet = {
        organizationId: organization!.id,
        year: moment().year(),
        month: moment().month() + 1,
      };
      const userTimeSheet: UserTimeSheet = await api.getUserTimeSheet(getUserTimeSheet);
      setUserTimeSheet(userTimeSheet);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getUserWorkClockings = async (userId: number) => {
    try {
      const getUserWorkClockingsQuery: GetUserWorkClockingsQuery = {
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        userTimeSheetId: userTimeSheet!.id,
        userId,
      };
      return api.getUserWorkClockings(getUserWorkClockingsQuery);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      return null;
    }
  };

  const onCloseClick = async (alert: Alert) => {
    try {
      await api.markAlertAsRead(alert.id);
      switch (alert.entity) {
        case NotificationEntity.OnlineSynchronization:
          // if (alert.entityId) {
          //   navigate(`/sincronizacion/${alert.entityId}`);
          // }
          break;
        case NotificationEntity.Sale:
          navigate(`/venta/${alert.entityId}`);
          break;
      }
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const createAutomaticUserWorkClocking = () => {
    setShowPinModal(true);
  };

  const onClosePinModal = async (pin: string | null) => {
    if (!pin) {
      setShowPinModal(false);
      return;
    }
    try {
      const pinDto: PinDto = {
        organizationId: organization!.id,
        pin,
      };
      const u: User = await api.getUserByPin(pinDto);
      const index: number = u.userInOrganizations.findIndex((userInOrganization: UserInOrganization) => userInOrganization.organizationId === organization.id);
      if (index === -1) {
        myToastr.error('El usuario no pertenece a esta organización');
        navigate(-1);
        return;
      }
      setUserPin(u);
      if (u.role === Role.Seller || u.role === Role.Business) {
        if (!store) {
          myToastr.error('Selecciona una tienda para poder fichar');
          setShowPinModal(false);
          return;
        }
        await createUpdateUserWorkClocking(u.id, store!.id);
        setShowPinModal(false);
      } else {
        setShowPinModal(false);
        setShowUserWorkClockingModal(true);
      }
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const onCloseUserWorkClockingModal = async (storeId: number | null) => {
    if (storeId === null) {
      setShowUserWorkClockingModal(false);
      return;
    }
    await createUpdateUserWorkClocking(userPin!.id, storeId);
    setShowUserWorkClockingModal(false);
  };

  const createUpdateUserWorkClocking = async (userId: number, storeId: number) => {
    const userWorkClockings: UserWorkClocking[] | null = await getUserWorkClockings(userId);
    if (!userWorkClockings) {
      return;
    }
    try {
      const numToday: number = userWorkClockings.filter((uwc: UserWorkClocking) => !uwc.isDeleted).length;
      const numOpened: number = userWorkClockings.filter((uwc: UserWorkClocking) => !uwc.isDeleted && !uwc.clockOut).length;
      if (numToday === 0 || numOpened === 0) {
        // No hay un fichaje creado para hoy o todos los fichajes tienen hora de salida
        const userWorkClockingDto: UserWorkClockingDto = {
          storeId: storeId > 0 ? storeId : null,
          userId,
          userTimeSheetId: userTimeSheet!.id,
          date: moment().format('YYYY-MM-DD'),
          clockIn: moment().format('HH:mm:ss'),
          clockOut: null,
          notes: null,
        };
        const newUserWorkClocking: UserWorkClocking = await api.createUserWorkClocking(userWorkClockingDto);
        myToastr.success('Fichaje creado correctamente');
        eventEmitter.emit(LocalEvents.NewUserWorkClocking, newUserWorkClocking);
        return;
      }
      // Obtener el primer fichaje sin hora de salida
      const currentUserWorkClocking: UserWorkClocking = userWorkClockings.find((uwc: UserWorkClocking) => !uwc.isDeleted && !uwc.clockOut)!;
      const userWorkClockingDto: UserWorkClockingDto = {
        ...currentUserWorkClocking,
        storeId: storeId > 0 ? storeId : null,
        clockIn: currentUserWorkClocking.clockIn,
        clockOut: moment().format('HH:mm:ss'),
      };
      const updatedUserWorkClocking: UserWorkClocking = await api.updateUserWorkClocking(currentUserWorkClocking.id, userWorkClockingDto);
      eventEmitter.emit(LocalEvents.UpdatedUserWorkClocking, updatedUserWorkClocking);
      myToastr.success('Fichaje actualizado correctamente');
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  return (
    <div className="container-header d-flex py-2">
      <div title={collapsed ? 'Mostrar barra lateral' : 'Ocultar barra lateral'} className="mx-2">
        <Menu className="clickable" onClick={() => dispatch(toggleAside())} size={25} color={organizationType === OrganizationType.B2C ? 'white' : 'gray'} />
      </div>
      <div className="d-flex align-items-center flex-grow-1">
        <div className="logo">
          {organizationType === OrganizationType.B2C && (
            <React.Fragment>
              <img src={retailLogo} alt="SIERRA Retail" className="hide-desktop" />
              <img src={mobileLogo} alt="SIERRA Retail" className="hide-mobile" />
            </React.Fragment>
          )}
          {organizationType === OrganizationType.B2B && (
            <React.Fragment>
              <img src={wholesalerLogo} alt="SIERRA Mayoristas" className="hide-desktop" />
              <img src={mobileLogo} alt="SIERRA Mayoristas" className="hide-mobile" />
              {organization.type === OrganizationType.B2B && (
                <span
                  style={{
                    marginLeft: '15px',
                    color: activeColor,
                    textTransform: 'uppercase',
                    fontSize: 12,
                  }}
                >
                  B2B
                </span>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="d-flex flex-row flex-grow-1 company-selector">
          {organization.type === OrganizationType.ALL && (user.role === Role.SuperAdmin || user.role === Role.Admin) && (
            <select
              className="me-4"
              value={organizationType}
              onChange={(e: any) => {
                const newOrganizationType: OrganizationType = e.target.value;
                dispatch(setOrganizationType(newOrganizationType));
                if (newOrganizationType === OrganizationType.B2B) {
                  const index: number = user.stores.findIndex((store: Store) => (store.type as string) === (newOrganizationType as string));
                  if (index !== -1) {
                    dispatch(setStoreId(user.stores[index].id));
                  } else {
                    dispatch(setStoreId(-1));
                  }
                } else {
                  dispatch(setStoreId(-1));
                }
                navigate('/stock');
              }}
            >
              <option value={OrganizationType.B2B}>{organizationTypeToString(OrganizationType.B2B)}</option>
              <option value={OrganizationType.B2C}>{organizationTypeToString(OrganizationType.B2C)}</option>
            </select>
          )}
          {location.pathname !== '/traspaso' && stores.length > 0 && showStoreSelector && (
            <select
              value={selectedStoreId}
              onChange={(e: any) => {
                const storeId: number = parseInt(e.target.value, 10);
                dispatch(setStoreId(storeId));
                dispatch(clearShoppingCart());
              }}
            >
              <option value={-1}>{organizationType === OrganizationType.B2C ? 'Todas las tiendas' : 'Todos los almacenes'}</option>
              {stores.map((store: Store) => (
                <option key={store.id} value={store.id}>
                  {store.name}
                </option>
              ))}
            </select>
          )}
          {!showStoreSelector && <div className={clsx('d-flex align-items-center', { 'store-wholesaler': organizationType === OrganizationType.B2B })}>{store?.name}</div>}
        </div>
        {showNewWithdrawal && (
          <button
            onClick={() => {
              if (store!.posStatus === PosStatus.Closed) {
                myToastr.error('Abre la caja para poder realizar retiradas');
                return;
              }
              setShowWithdrawalModal(true);
            }}
            className="withdrawals-button me-4"
            title="Nuevo gasto"
          >
            + Nuevo gasto
          </button>
        )}
        {store && location.pathname === '/pos' && (
          <React.Fragment>
            <button onClick={() => setShowScanEanSaleModal(true)} className="repairs-button me-4" title="Escanear ticket">
              Escanear ticket
            </button>
            <button
              onClick={() => {
                navigate('/reparacion');
                if (store.posStatus === PosStatus.Closed) {
                  myToastr.error('Abre la caja para poder realizar composturas');
                  return;
                }
              }}
              className="repairs-button me-4"
              title="Nueva compostura"
            >
              + Nueva compostura
            </button>
          </React.Fragment>
        )}
        {store && location.pathname === '/reparacion' && (
          <button
            onClick={() => {
              navigate('/pos');
              if (store.posStatus === PosStatus.Closed) {
                myToastr.error('Abre la caja para poder realizar ventas');
                return;
              }
            }}
            className="repairs-button me-4"
            title="Nueva venta"
          >
            + Nueva venta
          </button>
        )}
        {showCashRegisterModal && (
          <button onClick={() => dispatch(setShowCashRegiserModal(true))} className="close-pos me-4" title={store!.posStatus === PosStatus.Opened ? 'Cerrar caja' : 'Abrir caja'}>
            {store!.posStatus === PosStatus.Opened ? 'Cerrar caja' : 'Abrir caja'}
          </button>
        )}
        {showShoppingCartPurchaseOrder && (
          <Dropdown autoClose={'outside'} show={showPurchaseOrderShoppingCartDropdown} onToggle={() => setShowPurchaseOrderShoppingCartDropdown(!showPurchaseOrderShoppingCartDropdown)}>
            <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components">
              <div className="me-4">
                <div className="position-relative clickable" title="Productos en el carrito de la compra">
                  <ShoppingCart color={organizationType === OrganizationType.B2C ? 'white' : '#808A95'} />
                  {productsInShoppingCart.length > 0 && (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger notification-badge">
                      {productsInShoppingCart.length}
                      <span className="visually-hidden">productos</span>
                    </span>
                  )}
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenuDropdown} show={showPurchaseOrderShoppingCartDropdown}>
              <ShoppingCartPurchaseOrder onClose={() => setShowPurchaseOrderShoppingCartDropdown(!showPurchaseOrderShoppingCartDropdown)} />
            </Dropdown.Menu>
          </Dropdown>
        )}
        {showNotifications && (
          <Dropdown autoClose={'outside'} show={showNotificationsDropdown} onToggle={(e) => setShowNotificationsDropdown(!showNotificationsDropdown)}>
            <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components">
              <div className="me-4">
                <div className="position-relative" title="Notificaciones">
                  <Bell className="clickable" color={organizationType === OrganizationType.B2C ? 'white' : '#808A95'} />
                  {totalUnreadNotifications !== null && totalUnreadNotifications.total > 0 && (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger notification-badge">
                      {totalUnreadNotifications.total}
                      <span className="visually-hidden">notificaciones no leídas</span>
                    </span>
                  )}
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenuDropdown} show={showNotificationsDropdown}>
              <UnreadNotifications onClose={() => setShowNotificationsDropdown(!showNotificationsDropdown)} />
            </Dropdown.Menu>
          </Dropdown>
        )}
        {showSettings && (
          <Dropdown autoClose={'outside'} show={showSettingsDropdown} onToggle={(e) => setShowSettingsDropdown(!showSettingsDropdown)}>
            <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components">
              <div className="me-4">
                <div className="position-relative clickable" title="Ajustes">
                  <Settings color={organizationType === OrganizationType.B2C ? 'white' : '#808A95'} />
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenuDropdown} show={showSettingsDropdown}>
              <div className="container-settings">
                <ul>
                  {user.role === Role.SuperAdmin && (
                    <li className={location.pathname === '/familias' ? 'active' : ''} title="Familias">
                      <Link to="/familias" className="d-flex flex-row align-items-center w-100" onClick={() => setShowSettingsDropdown(false)}>
                        <Bookmark color={location.pathname === '/familias' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">Familias</span>
                      </Link>
                    </li>
                  )}
                  {user.role === Role.SuperAdmin && (
                    <li className={location.pathname === '/subfamilias' ? 'active' : ''} title="Subfamilias">
                      <Link to="/subfamilias" className="d-flex flex-row align-items-center w-100" onClick={() => setShowSettingsDropdown(false)}>
                        <Bookmark color={location.pathname === '/subfamilias' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">Subfamilias</span>
                      </Link>
                    </li>
                  )}
                  {user.role === Role.SuperAdmin && (
                    <li className={location.pathname === '/marcas' ? 'active' : ''} title="Marcas">
                      <Link to="/marcas" className="d-flex flex-row align-items-center w-100" onClick={() => setShowSettingsDropdown(false)}>
                        <Star color={location.pathname === '/marcas' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">Marcas</span>
                      </Link>
                    </li>
                  )}
                  {user.role === Role.SuperAdmin && (
                    <li className={location.pathname === '/colecciones' ? 'active' : ''} title="Colecciones">
                      <Link to="/colecciones" className="d-flex flex-row align-items-center w-100" onClick={() => setShowSettingsDropdown(false)}>
                        <Star color={location.pathname === '/colecciones' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">Colecciones</span>
                      </Link>
                    </li>
                  )}
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                    <li className={location.pathname === '/categorias' ? 'active' : ''} title="Categorías">
                      <Link to="/categorias" className="d-flex flex-row align-items-center w-100" onClick={() => setShowSettingsDropdown(false)}>
                        <Bookmark color={location.pathname === '/categorias' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">Categorías</span>
                      </Link>
                    </li>
                  )}
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                    <li className={clsx(' w-100', location.pathname === '/organizaciones' ? 'active' : '')} title="Organizaciones">
                      <Link to="/organizaciones" onClick={() => setShowSettingsDropdown(false)}>
                        <Box color={location.pathname === '/organizaciones' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">{user.role === Role.SuperAdmin ? 'Organizaciones' : 'Organización'}</span>
                      </Link>
                    </li>
                  )}
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                    <li className={location.pathname === '/proveedores' ? 'active' : ''} title="Proveedores">
                      <Link to="/proveedores" className="d-flex flex-row align-items-center  w-100" onClick={() => setShowSettingsDropdown(false)}>
                        <Briefcase color={location.pathname === '/proveedores' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">Proveedores</span>
                      </Link>
                    </li>
                  )}
                  {showWholesalers && (
                    <li className={location.pathname === '/mayoristas' ? 'active' : ''} title="Mayoristas">
                      <Link to="/mayoristas" className="d-flex flex-row align-items-center w-100" onClick={() => setShowSettingsDropdown(false)}>
                        <Truck color={location.pathname === '/mayoristas' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">Mayoristas</span>
                      </Link>
                    </li>
                  )}
                  {organizationType === OrganizationType.B2C && (
                    <li className={location.pathname === '/servicios-tecnicos' ? 'active' : ''} title="Servicios técnicos">
                      <Link to="/servicios-tecnicos" onClick={() => setShowSettingsDropdown(false)} className="w-100">
                        <UilRobot color={location.pathname === '/servicios-tecnicos' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">Servicios técnicos</span>
                      </Link>
                    </li>
                  )}
                  {showOnlineStores && (
                    <li className={location.pathname === '/sincronizaciones' ? 'active' : ''} title="Sincronizaciones">
                      <Link to="/sincronizaciones" onClick={() => setShowSettingsDropdown(false)} className="w-100">
                        <RefreshCw color={location.pathname === '/sincronizaciones' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">Sincronizaciones</span>
                      </Link>
                    </li>
                  )}
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                    <li
                      className={clsx('w-100', location.pathname === '/tiendas' || location.pathname === '/almacenes' ? 'active' : '')}
                      title={organizationType === OrganizationType.B2C ? 'Tiendas' : 'Almacenes'}
                    >
                      <Link to={organizationType === OrganizationType.B2C ? '/tiendas' : '/almacenes'} onClick={() => setShowSettingsDropdown(false)}>
                        <ShoppingBag color={location.pathname === '/tiendas' || location.pathname === '/almacenes' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">{organizationType === OrganizationType.B2C ? 'Tiendas' : 'Almacenes'}</span>
                      </Link>
                    </li>
                  )}
                  {showOnlineStores && (
                    <li className={location.pathname === '/tiendas-online' ? 'active' : ''} title="Tiendas online">
                      <Link to="/tiendas-online" onClick={() => setShowSettingsDropdown(false)} className="w-100">
                        <UilShop color={location.pathname === '/tiendas-online' ? activeColor : Constants.DISABLED_COLOR} />
                        <span className="ms-2">Tiendas online</span>
                      </Link>
                    </li>
                  )}
                  <li className={location.pathname === '/usuarios' ? 'active' : ''} title="Usuarios">
                    <Link to="/usuarios" onClick={() => setShowSettingsDropdown(false)} className="w-100">
                      <Users color={location.pathname === '/usuarios' ? activeColor : Constants.DISABLED_COLOR} />
                      <span className="ms-2">Usuarios</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Dropdown autoClose={'outside'} show={showUserDropdown} onToggle={(e) => setShowUserDropdown(!showUserDropdown)}>
          <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components">
            <div className="me-4">
              <div className="position-relative" title="Usuario">
                <UserIcon className="clickable" color={organizationType === OrganizationType.B2C ? 'white' : '#808A95'} />
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenuDropdown} show={showUserDropdown}>
            <div className="container-settings">
              <ul>
                {user.role !== Role.WholesaleCustomer && (
                  <li>
                    <Link to={`/usuario/${user.id}`} onClick={() => setShowUserDropdown(false)}>
                      <UserIcon color={Constants.DISABLED_COLOR} />
                      <span className="ms-2">
                        {user.name} {user.surnames}
                      </span>
                    </Link>
                  </li>
                )}
                {showUserWorkClocking && (
                  <React.Fragment>
                    <li onClick={() => setShowUserDropdown(false)}>
                      <div className="w-100 clickable" onClick={createAutomaticUserWorkClocking}>
                        <Calendar color={Constants.DISABLED_COLOR} />
                        <span className="ms-2">Fichar</span>
                      </div>
                    </li>
                    <li onClick={() => setShowUserDropdown(false)}>
                      <Link to="/fichaje" className="w-100">
                        <Calendar color={Constants.DISABLED_COLOR} />
                        <span className="ms-2">Fichajes</span>
                      </Link>
                    </li>
                  </React.Fragment>
                )}
                {showWorkShifts && (
                  <li onClick={() => setShowUserDropdown(false)}>
                    <Link to="/turnos" className="w-100">
                      <Clock color={Constants.DISABLED_COLOR} />
                      <span className="ms-2">Turnos</span>
                    </Link>
                  </li>
                )}
                {showPayrolls && (
                  <li onClick={() => setShowUserDropdown(false)}>
                    <Link to="/nominas" className="w-100">
                      <DollarSign color={Constants.DISABLED_COLOR} />
                      <span className="ms-2">Nóminas</span>
                    </Link>
                  </li>
                )}
                <li
                  onClick={() => {
                    setShowUserDropdown(false);
                    setShowLogoutModal(true);
                  }}
                >
                  <LogOut color={Constants.DISABLED_COLOR} />
                  <span className="ms-2">Salir</span>
                </li>
              </ul>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <WithdrawalModal show={showWithdrawalModal} closeModal={() => setShowWithdrawalModal(false)}></WithdrawalModal>
      <CashRegisterModal show={showCashRegiserModal} closeModal={() => dispatch(setShowCashRegiserModal(false))} />
      <LogoutModal show={showLogoutModal} closeModal={() => setShowLogoutModal(false)} />
      <ScanEanSaleModal show={showScanEanSaleModal} onCloseModal={() => setShowScanEanSaleModal(false)} />
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
      {userPin !== null && userTimeSheet !== null && <UserWorkClockingModal title="Fichar" user={userPin} show={showUserWorkClockingModal} closeModal={onCloseUserWorkClockingModal} />}
    </div>
  );
};

export default Header;
