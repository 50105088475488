import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Row, TableState } from 'react-table';
import ellipse from '../assets/images/ellipse.svg';
import ConfirmModal from '../components/confirm-modal';
import DateRangeSelector from '../components/date-range-selector';
import InventoryModal from '../components/inventory-modal';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import { DateRange } from '../enums/date-range';
import { InventoryStatus } from '../enums/inventory-status';
import { Role } from '../enums/role';
import { useStores } from '../hooks/use-stores.hook';
import { DateRangeData } from '../interfaces/date-range-data';
import { Inventory } from '../interfaces/inventory';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { getDatesGivenDateRange } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

interface FiltersSessionStorage {
  dateRange: DateRange;
  startDateStr: string | null;
  endDateStr: string | null;
  // Filtros de la tabla
  internalId: string | null;
  storeName: number | null;
  brand: string | null;
  date: string | null;
  user: string | null;
  status: InventoryStatus | null;
}

function InventoryStatusColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      <option value={InventoryStatus.Completed}>Completado</option>
      <option value={InventoryStatus.Draft}>Borrador</option>
      <option value={InventoryStatus.Resolved}>Resuelto</option>
      <option value={InventoryStatus.Incidence}>Incidencia</option>
    </select>
  );
}

const dateRange: DateRange = DateRange.ThisYear;

const InventoriesView = () => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const tableRef: any = useRef<any>(null);
  const navigate = useNavigate();
  const { selectedStoreId, store } = useSelector(storeSelector);
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [selectedInventory, setSelectedInventory] = useState<Inventory | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData | null>(null);
  const [tableFilters, setTableFilters] = useState<{ id: string; value: any }[]>([]);
  const stores: Store[] = useStores();
  const [showInventoryModal, setShowInventoryModal] = useState<boolean>(false);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Número',
        id: 'internalId',
        accessor: 'internalId',
        Cell: ({ row }: any) => {
          const inventory: Inventory = row.original;
          if (user.role === Role.Seller && inventory.status === InventoryStatus.Incidence) {
            return <span className="fw-bold">{inventory.internalId}</span>;
          }
          return (
            <Link to={`/inventario/${inventory.id}`} className="link-sale">
              <span className="fw-bold">{inventory.internalId}</span>
              {inventory.notes && inventory.notes.length > 0 && <img src={ellipse} alt="ellipse" className="sale-with-note" />}
            </Link>
          );
        },
      },
      {
        Header: 'Tienda',
        id: 'store',
        accessor: 'store.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Marca',
        id: 'brand',
        accessor: 'brand.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Colección',
        id: 'colection',
        accessor: 'collection.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Categoría',
        id: 'category',
        accessor: 'category.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Subcategoría',
        id: 'subCategory',
        accessor: 'subCategory.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Fecha',
        id: 'createdAt',
        accessor: (inventory: Inventory) => moment(inventory.createdAt).format('DD/MM/YYYY HH:mm'),
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const inventory: Inventory = row.original;
          return moment(inventory.createdAt).format('DD/MM/YYYY HH:mm');
        },
        sortType: (a: any, b: any) => {
          const momentA = moment(a.original.createdAt);
          const momentB = moment(b.original.createdAt);
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      },
      {
        Header: 'Responsable',
        id: 'user',
        accessor: (inventory: Inventory) => {
          const user: User = inventory.user;
          let value = '';
          if (user.name) {
            value += user.name;
          }
          if (user.surnames) {
            value += ` ${user.surnames}`;
          }
          return value;
        },
        Filter: SelectColumnFilter,
        Cell: ({ row }: any) => {
          const responsable: User = row.original.user;
          return `${responsable.name} ${responsable.surnames}`;
        },
      },
      {
        Header: 'Estado',
        id: 'status',
        accessor: 'status',
        Filter: InventoryStatusColumnFilter,
        Cell: ({ value }: any) => {
          let className = '';
          let textOption = '';
          switch (value) {
            case InventoryStatus.Completed:
              textOption = 'Completado';
              className = 'inventory-closed';
              break;
            case InventoryStatus.Draft:
              textOption = 'Borrador';
              className = 'inventory-draft';
              break;
            case InventoryStatus.Resolved:
              textOption = 'Resuelto';
              className = 'inventory-resolved';
              break;
            case InventoryStatus.Incidence:
              textOption = 'Incidencia';
              className = 'inventory-incidence';
              break;
          }
          return <span className={`badge ${className}`}>{textOption}</span>;
        },
      },
    ];
    if (user.role === Role.SuperAdmin) {
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          const inventory: Inventory = row.original;
          if (inventory.status === InventoryStatus.Completed || inventory.status === InventoryStatus.Resolved) {
            return null;
          }
          return (
            <Trash2
              type="button"
              className="mx-2"
              onClick={() => {
                setSelectedInventory(row.original);
                setShowConfirmModal(true);
              }}
              size={14}
            />
          );
        },
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventories, selectedStoreId]);

  useEffect(() => {
    const value: string | null = sessionStorage.getItem('search-inventories');
    let drd: DateRangeData = {
      dateRange,
      dates: getDatesGivenDateRange(dateRange),
    };
    const tf: { id: string; value: any }[] = [];
    if (value) {
      try {
        const filtersSessionStorage: FiltersSessionStorage = JSON.parse(value);
        drd = {
          dateRange: filtersSessionStorage.dateRange,
          dates: [
            moment(filtersSessionStorage.startDateStr).isValid() ? moment(filtersSessionStorage.startDateStr).toDate() : null,
            moment(filtersSessionStorage.endDateStr).isValid() ? moment(filtersSessionStorage.endDateStr).toDate() : null,
          ],
        };
        if (filtersSessionStorage.internalId) {
          tf.push({ id: 'internalId', value: filtersSessionStorage.internalId });
        }
        if (filtersSessionStorage.storeName) {
          tf.push({ id: 'store', value: filtersSessionStorage.storeName });
        }
        if (filtersSessionStorage.brand) {
          tf.push({ id: 'brand', value: filtersSessionStorage.brand });
        }
        if (filtersSessionStorage.date) {
          tf.push({ id: 'createdAt', value: filtersSessionStorage.date });
        }
        if (filtersSessionStorage.user) {
          tf.push({ id: 'user', value: filtersSessionStorage.user });
        }
        if (filtersSessionStorage.status) {
          tf.push({ id: 'status', value: filtersSessionStorage.status });
        }
      } catch (e) {
        sessionStorage.removeItem('search-inventories');
      }
    }
    setTableFilters(tf);
    setDateRangeData(drd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    const getInventories = async () => {
      setRequesting(true);
      const is: Inventory[] = await api.getInventories(organization!.id, selectedStoreId, dateRangeData.dates[0], dateRangeData.dates[1]);
      setInventories(is);
      setRequesting(false);
    };
    getInventories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId, dateRangeData]);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    const filtersSessionStorage: FiltersSessionStorage = {
      dateRange: dateRangeData!.dateRange,
      startDateStr: moment(dateRangeData!.dates[0]).format('YYYY-MM-DD'),
      endDateStr: moment(dateRangeData!.dates[1]).format('YYYY-MM-DD'),
      internalId: tableFilters.find((filter: any) => filter.id === 'internalId')?.value || '',
      storeName: tableFilters.find((filter: any) => filter.id === 'store')?.value || '',
      brand: tableFilters.find((filter: any) => filter.id === 'brand')?.value || '',
      date: tableFilters.find((filter: any) => filter.id === 'createdAt')?.value || '',
      user: tableFilters.find((filter: any) => filter.id === 'user')?.value || '',
      status: tableFilters.find((filter: any) => filter.id === 'status')?.value || '',
    };
    sessionStorage.setItem('search-inventories', JSON.stringify(filtersSessionStorage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, tableFilters]);

  const deleteInventory = async (i: Inventory) => {
    try {
      await api.deleteInventory(i.id);
      const invs: Inventory[] = [...inventories];
      const index: number = invs.findIndex((inv: Inventory) => inv.id === i.id);
      invs.splice(index, 1);
      setInventories(invs);
      myToastr.success('Inventario eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const i: Inventory = fr.original;
      let status = '';
      switch (i.status) {
        case InventoryStatus.Completed:
          status = 'Completado';
          break;
        case InventoryStatus.Resolved:
          status = 'Resuelto';
          break;
        case InventoryStatus.Incidence:
          status = 'Incidencia';
          break;
      }
      data.push({
        Numero: i.internalId,
        Tienda: i.store.name,
        Marca: i.brand.name,
        Fecha: moment(i.createdAt).format('DD/MM/YYYY HH:mm'),
        Responsable: `${i.user.name} ${i.user.surnames}`,
        Estado: status,
      });
    });
    setCsvData(data);
  };

  const onTableStateChange = (state: TableState<any>) => {
    setTableFilters(state.filters);
  };

  const onCloseInventoryModal = (inventory: Inventory | null) => {
    if (inventory) {
      const invs: Inventory[] = [...inventories];
      invs.push(inventory);
      setInventories(invs);
      navigate(`/inventario/${inventory.id}`);
    }
    setShowInventoryModal(false);
  };

  if (!dateRangeData) {
    return null;
  }

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="inventories p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Inventarios</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename={store ? `inventarios_${store.name}.csv` : 'inventarios.csv'} className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <button className="d-flex align-items-center create-button mx-2" onClick={() => setShowInventoryModal(true)}>
          <Plus className="me-1" size={14} /> Nuevo Inventario
        </button>
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={false}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
        <button
          className="ms-2 clear-filters"
          disabled={requesting}
          onClick={() => {
            tableRef.current?.clearFilters();
            setDateRangeData({
              dateRange,
              dates: getDatesGivenDateRange(dateRange),
            });
          }}
        >
          Limpiar filtros
        </button>
      </div>
      <Table
        ref={tableRef}
        data={inventories}
        columns={columns}
        noDataMessage="No hay inventarios"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          filters: tableFilters,
          sortBy: [{ id: 'createdAt', desc: true }],
          pageSize: Constants.LIMIT_RESULTS,
        }}
        onStateChange={onTableStateChange}
      />
      <InventoryModal show={showInventoryModal} closeModal={onCloseInventoryModal} />
      {selectedInventory && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Inventario"
          content={`¿Estás seguro que quieres eliminar el inventario ${selectedInventory.id}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteInventory(selectedInventory);
            }
            setSelectedInventory(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default InventoriesView;
