import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Edit, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Row } from 'react-table';
import CollectionModal from '../components/collection-modal';
import ConfirmModal from '../components/confirm-modal';
import Table from '../components/table';
import { Role } from '../enums/role';
import { Collection } from '../interfaces/collection';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const CollectionsView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [showCollectionModal, setShowCollectionModal] = useState<boolean>(false);
  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);

  const getCollections = async () => {
    setRequesting(true);
    const collections: Collection[] = await api.getCollections();
    setCollections(collections);
    setRequesting(false);
  };

  useEffect(() => {
    getCollections();
  }, []);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      { Header: 'Nombre', accessor: 'name', filter: 'fuzzyText' },
      { Header: 'Marca', accessor: 'brand.name', filter: 'fuzzyText' },
    ];
    if (user.role === Role.SuperAdmin) {
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                size={16}
                color="#222E3D"
                onClick={() => {
                  setSelectedCollection(row.original);
                  setShowCollectionModal(true);
                }}
                type="button"
                className="mx-2"
              />
              <Trash2
                type="button"
                className="mx-2"
                onClick={() => {
                  setSelectedCollection(row.original);
                  setShowConfirmModal(true);
                }}
                size={14}
              />
            </React.Fragment>
          );
        },
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections]) as any;

  const newCollection = () => {
    setSelectedCollection(null);
    setShowCollectionModal(true);
  };

  const onCollectionModalClose = (collection: Collection | null) => {
    if (collection) {
      getCollections();
    }
    setShowCollectionModal(false);
    setSelectedCollection(null);
  };

  const deleteCollection = async (c: Collection) => {
    try {
      await api.deleteCollection(c.id);
      const cs: Collection[] = [...collections];
      const index: number = cs.findIndex((e: Collection) => e.id === c.id);
      cs.splice(index, 1);
      setCollections(cs);
      myToastr.success('Colección eliminada correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const collection: Collection = fr.original;
      data.push({
        Nombre: collection.name,
        Marca: collection.brand.name,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="collections p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Colecciones</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="colecciones.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {user.role === Role.SuperAdmin && (
          <button className="d-flex align-items-center create-button" onClick={newCollection}>
            <Plus className="me-1" size={14} /> Nueva colección
          </button>
        )}
      </div>
      <Table
        data={collections}
        columns={columns}
        noDataMessage="No hay colecciones"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <CollectionModal show={showCollectionModal} closeModal={onCollectionModalClose} collection={selectedCollection} />
      {selectedCollection && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar colección"
          content={`¿Estás seguro que quieres eliminar la colección ${selectedCollection.name}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteCollection(selectedCollection);
            }
            setSelectedCollection(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default CollectionsView;
