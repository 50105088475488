import moment from 'moment';
import 'moment/locale/es';
import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Calendar, ChevronLeft, ChevronRight, Download } from 'react-feather';
import { Link } from 'react-router-dom';
import { GetOrganizationUserWorkClockingsQuery } from '../interfaces/get-user-work-clockings-organization-query';
import { GetWorkShiftsQuery } from '../interfaces/get-work-shifts-query';
import { Organization } from '../interfaces/organization';
import { User } from '../interfaces/user';
import { UserWorkClocking } from '../interfaces/user-work-clocking';
import { WorkShift } from '../interfaces/work-shift';
import { api } from '../services/api';
import { minutesToHourAndMinutesStr, ucFirst } from '../services/helpers';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

moment.locale('es');

const TimeControlView = () => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [users, setUsers] = useState<User[]>([]);
  const [workShifts, setWorkShifts] = useState<WorkShift[]>([]);
  const [userWorkClockings, setUserWorkClockings] = useState<UserWorkClocking[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const days: string[] = useMemo(() => {
    const firstDayOfWeek: moment.Moment = moment(selectedDate).startOf('week');
    const data: string[] = [];
    for (let i = 0; i < 7; i++) {
      data.push(firstDayOfWeek.clone().add(i, 'day').format('YYYY-MM-DD'));
    }
    return data;
  }, [selectedDate]);

  useEffect(() => {
    getOrganizationUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (days.length === 0) {
      return;
    }
    getWorkShifts();
    getUserWorkClockings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  const getOrganizationUsers = async () => {
    try {
      const us: User[] = await api.getOrganizationUsers(organization!.id);
      setUsers(us);
    } catch (e) {}
  };

  const getWorkShifts = async () => {
    try {
      const getWorkShiftsQuery: GetWorkShiftsQuery = {
        organizationId: organization!.id,
        startDate: moment(days[0]).toDate(),
        endDate: moment(days[days.length - 1]).toDate(),
      };
      const workShifts: WorkShift[] = await api.getWorkShifts(getWorkShiftsQuery);
      setWorkShifts(workShifts);
    } catch (e) {}
  };

  const getUserWorkClockings = async () => {
    try {
      const getOrganizationUserWorkClockingsQuery: GetOrganizationUserWorkClockingsQuery = {
        organizationId: organization!.id,
        startDate: days[0],
        endDate: days[days.length - 1],
      };
      const userWorkClockings: UserWorkClocking[] = await api.getOrganizationUserWorkClockings(getOrganizationUserWorkClockingsQuery);
      setUserWorkClockings(userWorkClockings);
    } catch (e) {}
  };

  const generateCsv = () => {
    const data: any[] = [];
    users.forEach((user: User) => {
      const wssUser: WorkShift[] = workShifts.filter((ws: WorkShift) => ws.userId === user.id);
      const estimatedMinutes: number = wssUser.reduce((acc: number, ws: WorkShift) => {
        const clockInMoment: moment.Moment = moment(ws.clockIn, 'HH:mm');
        const clockOutMoment: moment.Moment = moment(ws.clockOut, 'HH:mm');
        if (clockInMoment.isValid() && clockOutMoment.isValid()) {
          const diff: number = clockOutMoment.diff(clockInMoment, 'minutes');
          return acc + diff;
        }
        return acc;
      }, 0);
      const uwcsUser: UserWorkClocking[] = userWorkClockings.filter((uwc: UserWorkClocking) => uwc.userId === user.id);
      const workedMinutes: number = uwcsUser.reduce((acc: number, uwc: UserWorkClocking) => {
        const clockInMoment: moment.Moment = moment(uwc.clockIn, 'HH:mm');
        const clockOutMoment: moment.Moment = moment(uwc.clockOut, 'HH:mm');
        if (clockInMoment.isValid() && clockOutMoment.isValid()) {
          const diff: number = clockOutMoment.diff(clockInMoment, 'minutes');
          return acc + diff;
        }
        return acc;
      }, 0);
      const diffMinutes: number = workedMinutes - estimatedMinutes;
      const mapStores: Map<number, string> = new Map<number, string>();
      uwcsUser.forEach((uwc: UserWorkClocking) => {
        if (uwc.storeId && !mapStores.has(uwc.storeId)) {
          mapStores.set(uwc.storeId, uwc.store.name);
        }
      });
      data.push({
        Trabajador: `${user.name} ${user.surnames}`,
        'Horas estimadas': minutesToHourAndMinutesStr(estimatedMinutes),
        'Horas trabajadas': minutesToHourAndMinutesStr(workedMinutes),
        Tiempo: diffMinutes !== 0 ? minutesToHourAndMinutesStr(diffMinutes) : '',
        Tiendas: Array.from(mapStores)
          .map((value: [number, string]) => value[1])
          .join(', '),
      });
    });
    setCsvData(data);
  };

  return (
    <div className="user-work-clockings-view d-flex flex-column mx-4 my-4">
      <div className="container-header d-flex flex-row align-items-center mb-4">
        <div className="d-flex flex-row align-items-center flex-grow-1">
          <h1 className="mb-0">Control horario</h1>
        </div>
        <CSVLink filename={`control_horario.csv`} className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv} title="Exportar fichaje">
          <Download className="me-1" size={14} /> Exportar
        </CSVLink>
        <div className="d-flex flex-row align-items-center container-year-month no-select">
          <ChevronLeft
            className="cursor-pointer me-1"
            onClick={() => {
              const dateMoment: moment.Moment = moment(selectedDate).subtract(1, 'week');
              setSelectedDate(dateMoment.format('YYYY-MM-DD'));
            }}
            color="gray"
            size={18}
          />
          <Calendar className="mx-2" color="black" size={18} />
          <span className="year-week cursor-default">
            Semana {moment(selectedDate).week()}: {ucFirst(moment(selectedDate).format('MMMM YYYY'))}
          </span>
          <ChevronRight
            className="cursor-pointer ms-2"
            onClick={() => {
              const dateMoment: moment.Moment = moment(selectedDate).add(1, 'week');
              setSelectedDate(dateMoment.format('YYYY-MM-DD'));
            }}
            color="gray"
            size={18}
          />
        </div>
      </div>
      <div className="container-workshifts">
        <table>
          <thead>
            <tr>
              <th style={{ verticalAlign: 'middle' }} className="text-left">
                Trabajador
              </th>
              <th style={{ verticalAlign: 'middle' }} className="text-center">
                Horas estimadas
              </th>
              <th style={{ verticalAlign: 'middle' }} className="text-center">
                Horas trabajadas
              </th>
              <th style={{ verticalAlign: 'middle' }} className="text-center">
                Tiempo
              </th>
              <th style={{ verticalAlign: 'middle' }} className="text-left">
                Tiendas
              </th>
              <th style={{ verticalAlign: 'middle' }} className="text-center">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user: User) => {
              const surnames: string = user.surnames ? user.surnames.split(' ')[0] : '';
              const wssUser: WorkShift[] = workShifts.filter((ws: WorkShift) => ws.userId === user.id);
              const estimatedMinutes: number = wssUser.reduce((acc: number, ws: WorkShift) => {
                const clockInMoment: moment.Moment = moment(ws.clockIn, 'HH:mm');
                const clockOutMoment: moment.Moment = moment(ws.clockOut, 'HH:mm');
                if (clockInMoment.isValid() && clockOutMoment.isValid()) {
                  const diff: number = clockOutMoment.diff(clockInMoment, 'minutes');
                  return acc + diff;
                }
                return acc;
              }, 0);
              const uwcsUser: UserWorkClocking[] = userWorkClockings.filter((uwc: UserWorkClocking) => uwc.userId === user.id);
              const workedMinutes: number = uwcsUser.reduce((acc: number, uwc: UserWorkClocking) => {
                const clockInMoment: moment.Moment = moment(uwc.clockIn, 'HH:mm');
                const clockOutMoment: moment.Moment = moment(uwc.clockOut, 'HH:mm');
                if (clockInMoment.isValid() && clockOutMoment.isValid()) {
                  const diff: number = clockOutMoment.diff(clockInMoment, 'minutes');
                  return acc + diff;
                }
                return acc;
              }, 0);
              const diffMinutes: number = workedMinutes - estimatedMinutes;
              const mapStores: Map<number, string> = new Map<number, string>();
              uwcsUser.forEach((uwc: UserWorkClocking) => {
                if (uwc.storeId && !mapStores.has(uwc.storeId)) {
                  mapStores.set(uwc.storeId, uwc.store.name);
                }
              });
              return (
                <tr key={user.id}>
                  <td style={{ verticalAlign: 'middle' }} className="text-left worker">
                    <div className="d-flex flex-row align-items-center">
                      {user.color && <span className="user-color" style={{ backgroundColor: user.color }}></span>}
                      <span className="name">
                        {user.name} {surnames}
                      </span>
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'middle' }} className="text-center">
                    <span className="hours">{minutesToHourAndMinutesStr(estimatedMinutes)}</span>
                  </td>
                  <td style={{ verticalAlign: 'middle' }} className="text-center">
                    <span className="hours">{minutesToHourAndMinutesStr(workedMinutes)}</span>
                  </td>
                  <td style={{ verticalAlign: 'middle' }} className="text-center">
                    {diffMinutes !== 0 && <span className="missing-time">{minutesToHourAndMinutesStr(diffMinutes)}</span>}
                  </td>
                  <td style={{ verticalAlign: 'middle' }} className="text-left">
                    <div className="d-flex flex-column">
                      {Array.from(mapStores).map((value: [number, string]) => {
                        return (
                          <span key={value[0]} className="store">
                            {value[1]}
                          </span>
                        );
                      })}
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'middle' }} className="text-center">
                    <Link to={`/fichaje/${user.id}`} className="see-user-work-clockings">
                      Ver fichajes
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TimeControlView;
