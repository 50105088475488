import { useEffect, useState } from 'react';
import { OrganizationType } from '../enums/organization-type';
import { StoreType } from '../enums/store-type';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { storesByName } from '../services/helpers';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

export const useStores = () => {
  const user: User | null = useAppSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useAppSelector((state: RootState) => state.auth.organization);
  const organizationType: OrganizationType | null = useAppSelector((state: RootState) => state.auth.organizationType);
  const [userStores, setUserStores] = useState<Store[]>([]);

  useEffect(() => {
    if (!user || !organization || !organizationType) {
      return;
    }
    const storeType: StoreType = organizationType === OrganizationType.B2B ? StoreType.B2B : StoreType.B2C;
    const organizationStores: Store[] = user.stores.filter((store: Store) => store.organizationId === organization.id && store.type === storeType);
    const ss: Store[] = storesByName(organizationStores);
    setUserStores(ss);
  }, [user, organization, organizationType]);

  return userStores;
};
