import { AxiosError } from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { Organization } from '../interfaces/organization';
import { Payroll } from '../interfaces/payroll';
import { PayrollDto } from '../interfaces/payroll.dto';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { monthToString } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  payroll: Payroll | null;
  show: boolean;
  closeModal: (p: Payroll | null) => void;
}

interface CustomPayrollDto {
  name: string;
  year: number;
  month: number;
  userId: number;
  file?: FileList | null;
}

const defaultValues: CustomPayrollDto = {
  name: '',
  year: moment().year(),
  month: moment().month() + 1,
  userId: -1,
  file: null,
};

const PayrollModal = ({ payroll, show, closeModal }: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [users, setUsers] = useState<{ label: string; value: number }[]>([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  });
  const years: number[] = useMemo(() => {
    const data: number[] = [];
    for (let year = moment().year(); year >= moment().year() - 3; year--) {
      data.push(year);
    }
    return data;
  }, []);
  const months: { label: string; value: number }[] = useMemo(() => {
    const data: { label: string; value: number }[] = [];
    for (let month = 1; month <= 12; month++) {
      data.push({ label: monthToString(month), value: month });
    }
    return data;
  }, []);

  useEffect(() => {
    if (!show) {
      return;
    }
    const getUsers = async () => {
      const us: User[] = await api.getOrganizationUsers(organization.id);
      const data: { label: string; value: number }[] = [];
      us.forEach((u: User) => {
        data.push({ label: `${u.name} ${u.surnames}`, value: u.id });
      });
      data.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
      setUsers(data);
    };
    getUsers();
  }, [organization.id, show]);

  useEffect(() => {
    if (!show || !payroll) {
      return;
    }
    setValue('name', payroll.name);
    setValue('year', payroll.year);
    setValue('month', payroll.month);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, payroll]);

  useEffect(() => {
    if (users.length === 0 || !payroll) {
      return;
    }
    setValue('userId', payroll.user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payroll, users]);

  const close = () => {
    reset();
    closeModal(null);
  };

  const onSubmit = async (customPayrollDto: CustomPayrollDto) => {
    try {
      if (payroll) {
        const payrollDto: PayrollDto = {
          name: customPayrollDto.name,
          year: customPayrollDto.year,
          month: customPayrollDto.month,
          userId: payroll.userId,
          file: customPayrollDto.file ? customPayrollDto.file!.item(0)! : null,
        };
        const p: Payroll = await api.updatePayroll(payroll.id, payrollDto);
        closeModal(p);
        myToastr.success('Nómina actualizada');
      } else {
        const payrollDto: PayrollDto = {
          name: customPayrollDto.name,
          year: customPayrollDto.year,
          month: customPayrollDto.month,
          userId: customPayrollDto.userId,
          file: customPayrollDto.file!.item(0)!,
        };
        const p: Payroll = await api.createPayroll(payrollDto);
        closeModal(p);
        myToastr.success('Nómina creada');
      }
      reset(defaultValues);
    } catch (e: any) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError?.response) {
        if (Array.isArray(axiosError.response.data.message)) {
          myToastr.error(axiosError.response.data.message.join('</br>'));
        } else {
          myToastr.error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form technical-service-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">{!payroll ? 'Crear nómina' : 'Editar nómina'}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.name ? 'error' : '')}>Nombre*</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.userId })}>Usuario *</div>
              <select
                {...register('userId', {
                  required: true,
                  min: 1,
                  valueAsNumber: true,
                })}
                disabled={payroll !== null ? true : false}
                className={clsx({ error: errors?.userId })}
              >
                <option value={-1}>Seleccione una opción</option>
                {users.map((e: { label: string; value: number }) => (
                  <option key={e.value} value={e.value}>
                    {e.label}
                  </option>
                ))}
              </select>
              {errors.userId && <div className="error-message">{errors.userId.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.year })}>Año</div>
              <select {...register('year', { valueAsNumber: true })} placeholder="Año" className={clsx({ error: errors?.year })}>
                {years.map((year: number) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              {errors.year && <div className="error-message">{errors.year.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.month })}>Mes</div>
              <select {...register('month', { valueAsNumber: true })} placeholder="Año" className={clsx({ error: errors?.month })}>
                {months.map((e: { label: string; value: number }) => (
                  <option key={e.value} value={e.value}>
                    {e.label}
                  </option>
                ))}
              </select>
              {errors.month && <div className="error-message">{errors.month.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.file })}>Nómina {payroll === null ? '*' : ''}</div>
              {payroll !== null && <div className="input-name">Subir un fichero reemplazará el anterior</div>}
              <input type="file" {...register('file', { required: payroll === null })} className={clsx({ error: errors?.file })} placeholder="Fichero" />
              {errors.file && <div className="error-message">{errors.file.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default PayrollModal;
