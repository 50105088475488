import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { Image, Plus, X } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { ManualSaleProductDto } from '../interfaces/manual-sale-product.dto';
import { PosItem } from '../interfaces/pos-item';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import {
  addManualSaleProduct,
  posSelector,
  removeItem,
  removeItemDiscount,
  removeManualSaleProduct,
  removeManualSaleProductDiscount,
  setItemDiscount,
  setItemTotal,
  setItemUnits,
  setManualProductPendingToAdd,
  setManualSaleProductDiscount,
  setManualSaleProductTotal,
  setManualSaleProductUnits,
} from '../store/pos-slice';
import DiscountModal from './discount-modal';
import PosProductTotalModal from './pos-product-total-modal';
import ProductUnitsModal from './product-units-modal';

interface Props {
  enableActions: boolean;
}

export const PosProductsTable = (props: Props) => {
  const { items, manualSaleProducts } = useSelector(posSelector);
  const dispatch = useDispatch();
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const [selectedManualProductId, setSelectedManualProductId] = useState<number | null>(null);
  const [productDiscount, setProductDiscount] = useState<number>(0);
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [showProductUnitsModal, setShowProductUnitsModal] = useState<boolean>(false);
  const [selectedPosItem, setSelectedPosItem] = useState<PosItem | null>(null);
  const [showProductTotalModal, setShowProductTotalModal] = useState<boolean>(false);
  const [manualProductDto, setManualProductDto] = useState<ManualSaleProductDto>({
    name: '',
    pvp: 0,
    quantity: 0,
    discountPercentage: 0,
    total: 0,
  });
  const [errorsManualProductDto, setErrorsManualProductDto] = useState<{ [key: string]: boolean }>({
    name: false,
    pvp: false,
    quantity: false,
    total: false,
  });
  const manualProductIsValid: boolean = useMemo(() => {
    return manualProductDto.name.length > 0 && manualProductDto.pvp !== null && manualProductDto.quantity !== null && manualProductDto.quantity > 0 && manualProductDto.total !== null;
  }, [manualProductDto]);

  useEffect(() => {
    dispatch(setManualProductPendingToAdd(manualProductIsValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualProductIsValid]);

  const onCloseProductDiscountModal = () => {
    setShowDiscountModal(false);
    setSelectedProductId(null);
    setSelectedManualProductId(null);
  };

  const onCloseProductUnitsModal = () => {
    setShowProductUnitsModal(false);
    setSelectedProductId(null);
    setSelectedManualProductId(null);
  };

  const onSaveProductDiscountModal = (discount: number) => {
    if (discount) {
      if (selectedProductId) {
        dispatch(setItemDiscount({ productId: selectedProductId!, discount }));
      } else {
        dispatch(setManualSaleProductDiscount({ index: selectedManualProductId!, discount }));
      }
    } else {
      if (selectedProductId) {
        dispatch(removeItemDiscount(selectedProductId!));
      } else {
        dispatch(removeManualSaleProductDiscount(selectedManualProductId!));
      }
    }
    setShowDiscountModal(false);
    setSelectedProductId(null);
  };

  const onSaveProductUnitsModal = (units: number) => {
    if (selectedPosItem) {
      dispatch(setItemUnits({ productId: selectedPosItem!.stock.productId, units }));
    } else {
      dispatch(setManualSaleProductUnits({ index: selectedManualProductId!, units }));
    }
    setShowProductUnitsModal(false);
    setSelectedPosItem(null);
    setSelectedManualProductId(null);
  };

  const onDeleteProductDiscountModal = () => {
    if (selectedProductId) {
      dispatch(removeItemDiscount(selectedProductId!));
    } else {
      dispatch(removeManualSaleProductDiscount(selectedManualProductId!));
    }
    setShowDiscountModal(false);
    setSelectedProductId(null);
  };

  const onClosePosProductTotalModal = () => {
    setShowProductTotalModal(false);
    setSelectedPosItem(null);
    setSelectedManualProductId(null);
  };

  const onSavePosProductTotalModal = (total: number) => {
    if (selectedPosItem) {
      dispatch(setItemTotal({ productId: selectedPosItem!.stock.productId, total }));
    } else {
      dispatch(setManualSaleProductTotal({ index: selectedManualProductId!, total }));
    }
    setShowProductTotalModal(false);
    setSelectedPosItem(null);
    setSelectedManualProductId(null);
  };

  const onDeletePosProductTotalModal = () => {
    if (selectedPosItem) {
      dispatch(removeItemDiscount(selectedPosItem!.stock.productId));
    } else {
      dispatch(removeManualSaleProductDiscount(selectedManualProductId!));
    }
    setShowProductTotalModal(false);
    setSelectedPosItem(null);
    setSelectedManualProductId(null);
  };

  return (
    <React.Fragment>
      <div className="pos-products-table-container">
        <table className="my-table noselect pos-products-table" style={{ maxWidth: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th className="text-start" style={{ width: '200px' }}>
                Producto
              </th>
              <th className="text-center" style={{ width: '65px' }}>
                Precio
              </th>
              <th className="text-center" style={{ width: '35px' }}>
                Uds.
              </th>
              <th className="" style={{ width: '65px' }}>
                Dto.
              </th>
              <th className="text-center" style={{ width: '65px' }}>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((posItem: PosItem) => (
              <tr key={posItem.stock.productId}>
                <td className="text-start first-column-name" style={{ width: '200px' }}>
                  <div className="d-flex align-items-center">
                    {props.enableActions && (
                      <span className="remove-icon">
                        <X onClick={() => dispatch(removeItem(posItem.stock.productId))} size={16} className="remove-item" />
                      </span>
                    )}
                    {posItem.stock.product.images.length > 0 ? (
                      <span className="me-1">
                        <LazyLoadImage className="img-thumbnail" src={process.env.REACT_APP_PUBLIC_URL + posItem.stock.product.images[0].path} alt={posItem.stock.product.name} width="50px" />
                      </span>
                    ) : (
                      <span className="me-1">
                        <Image className="img-thumbnail" size={50} color="#808A95" />
                      </span>
                    )}
                    <div className="d-flex flex-column flex-grow-1">
                      <div className="d-flex flex-row">
                        <div className="name me-2 ellipsis-text-multiline-2">{posItem.stock.product.name || 'Sin nombre'}</div>
                      </div>
                      <div className="sku">{posItem.stock.product.sku}</div>
                    </div>
                  </div>
                </td>
                <td className="text-center" style={{ width: '65px' }}>
                  <div className="d-flex flex-column align-items-center noselect">
                    <div className="price">{formatNumber(posItem.unitPrice)}€</div>
                  </div>
                </td>
                <td className="text-center product-qty" style={{ width: '35px' }}>
                  <div className="d-flex align-items-center justify-content-center">
                    <span
                      className={clsx('units', { 'cursor-default': !props.enableActions })}
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setSelectedPosItem(posItem);
                        setShowProductUnitsModal(true);
                      }}
                    >
                      {posItem.units}
                    </span>
                  </div>
                </td>
                <td className="" style={{ width: '65px' }}>
                  {posItem.discountValue > 0 ? (
                    <span
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setProductDiscount(posItem.discountValue);
                        setSelectedProductId(posItem.stock.productId);
                        setShowDiscountModal(true);
                      }}
                      className={clsx('discount text-center with-value noselect', props.enableActions ? 'clickable' : 'cursor-default')}
                      title="Modificar descuento"
                    >
                      -{formatNumber(posItem.discountValue)}%
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setProductDiscount(0);
                        setSelectedProductId(posItem.stock.productId);
                        setShowDiscountModal(true);
                      }}
                      className={clsx('text-center discount noselect', props.enableActions ? 'clickable' : 'cursor-default')}
                      title="Añadir descuento"
                    >
                      {props.enableActions ? 'Dto.' : '-'}
                    </span>
                  )}
                </td>
                <td className="text-center" style={{ width: '65px' }}>
                  <span
                    onClick={() => {
                      if (!props.enableActions) {
                        return;
                      }
                      setSelectedPosItem(posItem);
                      setShowProductTotalModal(true);
                    }}
                    className={clsx('total discount noselect', props.enableActions ? 'clickable' : 'cursor-default')}
                    title={props.enableActions ? 'Modificar total' : ''}
                  >
                    {formatNumber(posItem.total)}€
                  </span>
                </td>
              </tr>
            ))}
            {manualSaleProducts.map((manualSaleProduct: ManualSaleProductDto, index: number) => (
              <tr key={manualSaleProduct.name}>
                <td className="text-start" style={{ width: '200px' }}>
                  <div className="d-flex align-items-center">
                    {props.enableActions && (
                      <span className="remove-icon">
                        <X onClick={() => dispatch(removeManualSaleProduct(index))} size={16} className="remove-item" />
                      </span>
                    )}
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row">
                        <div className="name manual me-2">{manualSaleProduct.name}</div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-center" style={{ width: '65px' }}>
                  <div className="d-flex flex-column align-items-center noselect">
                    <div className="price">{formatNumber(manualSaleProduct.pvp)}€</div>
                  </div>
                </td>
                <td className="text-center product-qty" style={{ width: '35px' }}>
                  <div className="d-flex align-items-center justify-content-center">
                    <span
                      className={clsx('units', { 'cursor-default': !props.enableActions })}
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setSelectedManualProductId(index);
                        setShowProductUnitsModal(true);
                      }}
                    >
                      {manualSaleProduct.quantity!}
                    </span>
                  </div>
                </td>
                <td className="" style={{ width: '65px' }}>
                  {manualSaleProduct.discountPercentage !== null && manualSaleProduct.discountPercentage! > 0 ? (
                    <span
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setProductDiscount(manualSaleProduct.discountPercentage!);
                        setSelectedManualProductId(index);
                        setShowDiscountModal(true);
                      }}
                      className={clsx('discount text-center with-value noselect', props.enableActions ? 'clickable' : 'cursor-default')}
                      title="Modificar descuento"
                    >
                      -{formatNumber(manualSaleProduct.discountPercentage)}%
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setProductDiscount(0);
                        setSelectedManualProductId(index);
                        setShowDiscountModal(true);
                      }}
                      className={clsx('text-center discount noselect', props.enableActions ? 'clickable' : 'cursor-default')}
                      title="Añadir descuento"
                    >
                      {props.enableActions ? 'Desc.' : '-'}
                    </span>
                  )}
                </td>
                <td className="text-center" style={{ width: '65px' }}>
                  <span
                    onClick={() => {
                      if (!props.enableActions) {
                        return;
                      }
                      setSelectedManualProductId(index);
                      setShowProductTotalModal(true);
                    }}
                    className={clsx('total discount noselect', props.enableActions ? 'clickable' : 'cursor-default')}
                    title={props.enableActions ? 'Modificar total' : ''}
                  >
                    {formatNumber(manualSaleProduct.total)}€
                  </span>
                </td>
              </tr>
            ))}
            {props.enableActions && (
              <tr>
                <td style={{ width: '200px' }}>
                  <input
                    type="text"
                    value={manualProductDto.name}
                    onChange={(e: any) => {
                      setManualProductDto({ ...manualProductDto, name: e.target.value });
                      setErrorsManualProductDto({ ...errorsManualProductDto, name: false });
                    }}
                    className={clsx('form-control manual-product', { 'is-invalid': errorsManualProductDto.name })}
                    placeholder="Nombre del producto"
                  />
                </td>
                <td style={{ width: '65px' }}>
                  <input
                    type="number"
                    min="0"
                    value={manualProductDto.pvp || ''}
                    onChange={(e: any) => {
                      let pvp: number | null = null;
                      if (e.target.value !== '') {
                        pvp = parseFloat(e.target.value);
                        if (isNaN(pvp)) {
                          pvp = null;
                        }
                      }
                      const discount: number = manualProductDto.discountPercentage || 0;
                      const quantity: number = manualProductDto.quantity || 0;
                      let total: number | null = null;
                      if (pvp !== null) {
                        total = quantity * pvp * (1 - discount / 100);
                      }
                      setManualProductDto({ ...manualProductDto, pvp, total });
                      setErrorsManualProductDto({ ...errorsManualProductDto, pvp: false });
                    }}
                    className={clsx('form-control manual-product', { 'is-invalid': errorsManualProductDto.pvp })}
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0.00€"
                  />
                </td>
                <td style={{ width: '35px' }}>
                  <input
                    type="number"
                    min="0"
                    value={manualProductDto.quantity || ''}
                    onChange={(e: any) => {
                      let quantity: number | null = null;
                      if (e.target.value !== '') {
                        quantity = parseInt(e.target.value);
                        if (isNaN(quantity)) {
                          quantity = null;
                        }
                      }
                      const discount: number = manualProductDto.discountPercentage || 0;
                      const pvp: number = manualProductDto.pvp || 0;
                      let total: number | null = null;
                      if (quantity !== null) {
                        total = quantity * pvp * (1 - discount / 100);
                      }
                      setManualProductDto({ ...manualProductDto, quantity, total });
                      setErrorsManualProductDto({ ...errorsManualProductDto, quantity: false });
                    }}
                    className={clsx('form-control manual-product', { 'is-invalid': errorsManualProductDto.quantity })}
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                  />
                </td>
                <td style={{ width: '65px' }}>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    value={manualProductDto.discountPercentage || ''}
                    onChange={(e: any) => {
                      let discountPercentage: number | null = null;
                      if (e.target.value !== '') {
                        discountPercentage = parseFloat(e.target.value);
                        if (isNaN(discountPercentage)) {
                          discountPercentage = null;
                        } else {
                          discountPercentage = Math.min(100, Math.max(0, discountPercentage));
                        }
                      }
                      const pvp: number = manualProductDto.pvp || 0;
                      const quantity: number = manualProductDto.quantity || 0;
                      let total: number | null = null;
                      if (discountPercentage !== null) {
                        total = quantity * pvp * (1 - discountPercentage / 100);
                      } else {
                        total = quantity * pvp;
                      }
                      setManualProductDto({ ...manualProductDto, discountPercentage, total });
                    }}
                    className="form-control manual-product"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0.00%"
                  />
                </td>
                <td style={{ width: '65px' }}>
                  <div className="text-center">
                    {/* <input
                      type="number"
                      min="0"
                      value={manualProductDto.total !== null ? manualProductDto.total : ''}
                      onChange={(e: any) => {
                        let total: number | null = null;
                        if (e.target.value !== '') {
                          total = parseFloat(e.target.value);
                          if (isNaN(total)) {
                            total = null;
                          }
                        }
                        const pvp: number = manualProductDto.pvp || 0;
                        const quantity: number = manualProductDto.quantity || 0;
                        const maxTotal: number = quantity * pvp;
                        let discountPercentage: number | null = null;
                        if (total !== null) {
                          discountPercentage = roundTwoDecimals(100 - (total * 100) / (quantity * pvp));
                          const totalDiscounted: number = quantity * pvp * (1 - discountPercentage / 100);
                          if (totalDiscounted > maxTotal) {
                            total = maxTotal;
                            discountPercentage = null;
                          }
                        }
                        setManualProductDto({ ...manualProductDto, discountPercentage, total });
                        setErrorsManualProductDto({ ...errorsManualProductDto, total: false });
                      }}
                      className={clsx('form-control manual-product', { 'is-invalid': errorsManualProductDto.total })}
                      pattern="[0-9]+([\.,][0-9]+)?"
                      placeholder="0.00€"
                    /> */}
                    <span
                      className="add-manual"
                      onClick={() => {
                        if (manualProductDto.name === null || manualProductDto.name.length === 0) {
                          setErrorsManualProductDto({ ...errorsManualProductDto, name: true });
                          myToastr.error('El nombre del producto es obligatorio');
                          return;
                        }
                        if (manualProductDto.pvp === null || manualProductDto.pvp === 0) {
                          setErrorsManualProductDto({ ...errorsManualProductDto, pvp: true });
                          myToastr.error('El precio del producto es obligatorio');
                          return;
                        }
                        if (manualProductDto.quantity === null || manualProductDto.quantity === 0) {
                          setErrorsManualProductDto({ ...errorsManualProductDto, quantity: true });
                          myToastr.error('La cantidad del producto es obligatoria');
                          return;
                        }
                        dispatch(addManualSaleProduct(manualProductDto));
                        setManualProductDto({
                          name: '',
                          pvp: 0,
                          quantity: 0,
                          discountPercentage: 0,
                          total: 0,
                        });
                      }}
                    >
                      <Plus color={manualProductIsValid ? '#26c44b' : 'gray'} className="" size={15} />
                    </span>
                  </div>
                </td>
              </tr>
            )}
            {items.length === 0 && manualSaleProducts.length === 0 && (
              <tr>
                <td className="text-center" colSpan={5}>
                  No hay productos en el carrito
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <DiscountModal
        show={showDiscountModal}
        discountValue={productDiscount}
        closeModal={onCloseProductDiscountModal}
        saveDiscount={onSaveProductDiscountModal}
        deleteDiscount={onDeleteProductDiscountModal}
      />
      <ProductUnitsModal show={showProductUnitsModal} closeModal={onCloseProductUnitsModal} saveUnits={onSaveProductUnitsModal} />
      {selectedPosItem && (
        <PosProductTotalModal
          show={showProductTotalModal}
          maxTotal={selectedPosItem.unitPrice * selectedPosItem.units}
          discountValue={selectedPosItem.discountValue}
          closeModal={onClosePosProductTotalModal}
          saveTotal={onSavePosProductTotalModal}
          deleteTotal={onDeletePosProductTotalModal}
        />
      )}
      {selectedManualProductId !== null && (
        <PosProductTotalModal
          show={showProductTotalModal}
          maxTotal={manualSaleProducts[selectedManualProductId].pvp! * manualSaleProducts[selectedManualProductId].quantity!}
          discountValue={manualSaleProducts[selectedManualProductId].discountPercentage || 0}
          closeModal={onClosePosProductTotalModal}
          saveTotal={onSavePosProductTotalModal}
          deleteTotal={onDeletePosProductTotalModal}
        />
      )}
    </React.Fragment>
  );
};
