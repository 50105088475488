import { AxiosError } from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Loader, Plus, Trash2, XCircle } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import DateRangeSelector from '../components/date-range-selector';
import Table from '../components/table';
import { DateRange } from '../enums/date-range';
import { OrganizationType } from '../enums/organization-type';
import { PurchaseOrderStatus } from '../enums/purchase-order-status';
import { DateRangeData } from '../interfaces/date-range-data';
import { GetPurchaseOrdersQuery } from '../interfaces/get-purchase-orders-query';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { PurchaseOrder } from '../interfaces/purchase-order';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { formatNumber, getDatesGivenDateRange, purchaseOrderStatusStr } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

const PurchaseOrdersView = () => {
  const navigate = useNavigate();
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const { selectedStoreId } = useSelector(storeSelector);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData>({
    dateRange: DateRange.ThisYear,
    dates: getDatesGivenDateRange(DateRange.ThisYear),
  });
  const [requesting, setRequesting] = useState<boolean>(false);
  const [filteredRows, setFilteredRows] = useState<Row<PurchaseOrder>[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<PurchaseOrder | null>(null);
  const [showDeletePurchaseOrderModal, setShowDeletePurchaseOrderModal] = useState<boolean>(false);
  const [showCancelPurchaseOrderModal, setShowCancelPurchaseOrderModal] = useState<boolean>(false);

  const columns = useMemo(() => {
    if (organizationType === OrganizationType.B2B) {
      return [
        {
          Header: 'Número',
          accessor: 'wholesalerInternalId',
          Cell: ({ row }: any) => {
            const purchaseOrder: PurchaseOrder = row.original;
            return (
              <Link to={`/pedido/${purchaseOrder.id}`} className="link-sale">
                <span className="fw-bold">{purchaseOrder.originOrganizationId === organization.id ? purchaseOrder.originInternalId : purchaseOrder.destinationInternalId}</span>
              </Link>
            );
          },
        },
        {
          Header: 'Fecha',
          id: 'createdAt',
          accessor: (purchaseOrder: PurchaseOrder) => moment(purchaseOrder.createdAt).format('DD/MM/YYYY HH:mm'),
          filter: 'fuzzyText',
          Cell: ({ row }: any) => {
            const purchaseOrder: PurchaseOrder = row.original;
            return moment(purchaseOrder.createdAt).format('DD/MM/YYYY HH:mm');
          },
          sortType: (a: any, b: any) => {
            const momentA = moment(a.original.date);
            const momentB = moment(b.original.date);
            return momentA.isBefore(momentB) ? -1 : 1;
          },
        },
        {
          Header: 'Cliente',
          accessor: (purchaseOrder: PurchaseOrder) => (purchaseOrder.originOrganizationId === organization.id ? purchaseOrder.destinationOrganization.name : purchaseOrder.originOrganization.name),
          filter: 'fuzzyText',
        },
        {
          Header: 'Uds. solicitadas',
          accessor: 'requestedUnits',
          filter: 'fuzzyText',
        },
        {
          Header: 'Uds. finales',
          accessor: (purchaseOrder: PurchaseOrder) => (purchaseOrder.status === PurchaseOrderStatus.Draft || purchaseOrder.status === PurchaseOrderStatus.Generated ? '-' : purchaseOrder.finalUnits),
          filter: 'fuzzyText',
        },
        {
          Header: 'Total',
          accessor: 'total',
          Cell: ({ row }: any) => {
            const purchaseOrder: PurchaseOrder = row.original;
            return `${formatNumber(purchaseOrder.total)}€`;
          },
        },
        {
          Header: 'Estado',
          accessor: (purchaseOrder: PurchaseOrder) => purchaseOrderStatusStr(purchaseOrder.status),
          Cell: ({ row }: any) => {
            const purchaseOrder: PurchaseOrder = row.original;
            return <span className={clsx('purchase-order-status', purchaseOrder.status)}>{purchaseOrderStatusStr(purchaseOrder.status)}</span>;
          },
        },
        {
          Header: 'Acciones',
          Cell: ({ row }: any) => {
            const purchaseOrder: PurchaseOrder = row.original;
            const showDeleteButton: boolean = purchaseOrder.destinationOrganizationId === organization.id;
            return (
              <div className="d-flex flex-row">
                {purchaseOrder.destinationOrganizationId === organization.id && (purchaseOrder.status === PurchaseOrderStatus.Draft || purchaseOrder.status === PurchaseOrderStatus.Generated) && (
                  <XCircle
                    type="button"
                    className="mx-2"
                    onClick={() => {
                      setSelectedPurchaseOrder(purchaseOrder);
                      setShowCancelPurchaseOrderModal(true);
                    }}
                    size={14}
                  />
                )}
                {showDeleteButton && purchaseOrder.status === PurchaseOrderStatus.Draft && (
                  <Trash2
                    type="button"
                    className="mx-2"
                    onClick={() => {
                      setSelectedPurchaseOrder(purchaseOrder);
                      setShowDeletePurchaseOrderModal(true);
                    }}
                    size={14}
                  />
                )}
              </div>
            );
          },
        },
      ];
    } else if (organizationType === OrganizationType.B2C) {
      return [
        {
          Header: 'Número',
          accessor: 'retailInternalId',
          Cell: ({ row }: any) => {
            const purchaseOrder: PurchaseOrder = row.original;
            return (
              <Link to={`/orden-compra/${purchaseOrder.id}`} className="link-sale">
                <span className="fw-bold">{purchaseOrder.destinationInternalId}</span>
              </Link>
            );
          },
        },
        {
          Header: 'Fecha',
          id: 'createdAt',
          accessor: (purchaseOrder: PurchaseOrder) => moment(purchaseOrder.createdAt).format('DD/MM/YYYY HH:mm'),
          filter: 'fuzzyText',
          Cell: ({ row }: any) => {
            const purchaseOrder: PurchaseOrder = row.original;
            return moment(purchaseOrder.createdAt).format('DD/MM/YYYY HH:mm');
          },
          sortType: (a: any, b: any) => {
            const momentA = moment(a.original.date);
            const momentB = moment(b.original.date);
            return momentA.isBefore(momentB) ? -1 : 1;
          },
        },
        {
          Header: 'Mayorista',
          accessor: 'originOrganization.name',
          filter: 'fuzzyText',
        },
        {
          Header: 'Uds. solicitadas',
          accessor: 'requestedUnits',
          filter: 'fuzzyText',
        },
        {
          Header: 'Uds. finales',
          accessor: (purchaseOrder: PurchaseOrder) => (purchaseOrder.status === PurchaseOrderStatus.Draft || purchaseOrder.status === PurchaseOrderStatus.Generated ? '-' : purchaseOrder.finalUnits),
          filter: 'fuzzyText',
        },
        {
          Header: 'Total',
          accessor: 'total',
          Cell: ({ row }: any) => {
            const purchaseOrder: PurchaseOrder = row.original;
            return `${formatNumber(purchaseOrder.total)}€`;
          },
        },
        {
          Header: 'Estado',
          accessor: (purchaseOrder: PurchaseOrder) => purchaseOrderStatusStr(purchaseOrder.status),
          Cell: ({ row }: any) => {
            const purchaseOrder: PurchaseOrder = row.original;
            return <span className={clsx('purchase-order-status', purchaseOrder.status)}>{purchaseOrderStatusStr(purchaseOrder.status)}</span>;
          },
        },
        {
          Header: 'Acciones',
          Cell: ({ row }: any) => {
            const purchaseOrder: PurchaseOrder = row.original;
            return (
              <div className="d-flex flex-row">
                {purchaseOrder.status === PurchaseOrderStatus.Draft && (
                  <Trash2
                    type="button"
                    className="mx-2"
                    onClick={() => {
                      setSelectedPurchaseOrder(purchaseOrder);
                      setShowDeletePurchaseOrderModal(true);
                    }}
                    size={14}
                  />
                )}
              </div>
            );
          },
        },
      ];
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationType]);

  useEffect(() => {
    getPurchaseOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, selectedStoreId, dateRangeData]);

  const getPurchaseOrders = async () => {
    try {
      setRequesting(true);
      const getPurchaseOrdersQuery: GetPurchaseOrdersQuery = {
        organizationId: organization.id,
        relatedStoreId: selectedStoreId > 0 ? selectedStoreId : null,
        startDate: moment(dateRangeData!.dates[0]).format('YYYY-MM-DD'),
        endDate: moment(dateRangeData!.dates[1]).format('YYYY-MM-DD'),
      };
      const pos: PurchaseOrder[] = await api.getPurchaseOrders(getPurchaseOrdersQuery);
      setPurchaseOrders(pos);
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    } finally {
      setRequesting(false);
    }
  };

  const onCloseDeletePurchaseModal = async (result: boolean) => {
    if (!result) {
      setShowDeletePurchaseOrderModal(false);
      setSelectedPurchaseOrder(null);
      return;
    }
    try {
      await api.deletePurchaseOrder(selectedPurchaseOrder!.id);
      const pos: PurchaseOrder[] = [...purchaseOrders];
      const index: number = pos.findIndex((po: PurchaseOrder) => po.id === selectedPurchaseOrder!.id);
      pos.splice(index, 1);
      setPurchaseOrders(pos);
      myToastr.success(organizationType === OrganizationType.B2C ? 'Órden de compra eliminada' : 'Pedido eliminado');
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    } finally {
      setShowDeletePurchaseOrderModal(false);
      setSelectedPurchaseOrder(null);
    }
  };

  const onCloseCancelPurchaseModal = async (result: boolean) => {
    if (!result) {
      setShowCancelPurchaseOrderModal(false);
      setSelectedPurchaseOrder(null);
      return;
    }
    try {
      const updatedPurchaseOrder: PurchaseOrder = await api.cancelPurchaseOrder(selectedPurchaseOrder!.id);
      const pos: PurchaseOrder[] = [...purchaseOrders];
      const index: number = pos.findIndex((po: PurchaseOrder) => po.id === updatedPurchaseOrder.id);
      pos[index] = updatedPurchaseOrder;
      setPurchaseOrders(pos);
      myToastr.success(organizationType === OrganizationType.B2C ? 'Órden de compra cancelada correctamente' : 'Pedido cancelado correctamente');
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    } finally {
      setShowCancelPurchaseOrderModal(false);
      setSelectedPurchaseOrder(null);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((row: Row<PurchaseOrder>) => {
      const purchaseOrder: PurchaseOrder = row.original;
      const csvRow: any = {
        Número:
          organizationType === OrganizationType.B2C
            ? purchaseOrder.destinationInternalId
            : purchaseOrder.originOrganizationId === organization.id
            ? purchaseOrder.originInternalId
            : purchaseOrder.destinationInternalId,
        Fecha: moment(purchaseOrder.createdAt).format('DD/MM/YYYY HH:mm'),
        [organizationType === OrganizationType.B2C ? 'Mayorista' : 'Cliente']:
          organizationType === OrganizationType.B2C
            ? purchaseOrder.originOrganization.name
            : purchaseOrder.originOrganizationId === organization.id
            ? purchaseOrder.destinationOrganization.name
            : purchaseOrder.originOrganization.name,
        'Uds. solicitadas': purchaseOrder.requestedUnits,
        'Uds. finales': purchaseOrder.finalUnits,
        Total: `${formatNumber(purchaseOrder.total)}€`,
        Estado: purchaseOrderStatusStr(purchaseOrder.status),
      };
      data.push(csvRow);
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">{organizationType === OrganizationType.B2C ? 'Órdenes de compra' : 'Pedidos'}</h1>
        {filteredRows.length > 0 && (
          <CSVLink
            filename={organizationType === OrganizationType.B2C ? 'ordenes_compra.csv' : 'pedidos.csv'}
            className="d-flex align-items-center export-csv"
            data={csvData}
            onClick={generateCsv}
            title={organizationType === OrganizationType.B2C ? 'Exportar compras' : 'Exportar pedidos'}
          >
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <button className="d-flex align-items-center create-button mx-3" onClick={() => navigate(organizationType === OrganizationType.B2C ? '/orden-compra' : '/pedido')}>
          <Plus className="me-1" size={14} /> {organizationType === OrganizationType.B2C ? 'Nueva órden de compra' : 'Nuevo pedido'}
        </button>
        <DateRangeSelector
          dateRangeData={dateRangeData}
          showTimePicker={false}
          availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
          onChange={(drd: DateRangeData) => setDateRangeData(drd)}
        />
      </div>
      <Table
        data={purchaseOrders}
        columns={columns}
        noDataMessage={organizationType === OrganizationType.B2C ? 'No hay órdenes compras' : 'No hay pedidos'}
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <ConfirmModal
        acceptButtonClass="accept-button"
        show={showDeletePurchaseOrderModal}
        title={organizationType === OrganizationType.B2C ? 'Eliminar órden de compra' : 'Eliminar pedido'}
        content={organizationType === OrganizationType.B2C ? `¿Estás seguro que quieres eliminar la órden de compra?` : `¿Estás seguro que quieres eliminar el pedido?`}
        closeModal={onCloseDeletePurchaseModal}
      />
      <ConfirmModal
        acceptButtonClass="accept-button"
        show={showCancelPurchaseOrderModal}
        title={organizationType === OrganizationType.B2C ? 'Cancelar órden de compra' : 'Cancelar pedido'}
        content={organizationType === OrganizationType.B2C ? `¿Estás seguro que quieres cancelar la órden de compra?` : `¿Estás seguro que quieres cancelar el pedido?`}
        closeModal={onCloseCancelPurchaseModal}
      />
    </div>
  );
};

export default PurchaseOrdersView;
