import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Sale } from '../interfaces/sale';
import { api } from '../services/api';
import myToastr from '../services/toastr';

interface Props {
  show: boolean;
  onCloseModal: () => void;
}

interface FormData {
  saleId: string;
}

const initialValues: FormData = {
  saleId: '',
};

const ScanEanSaleModal = ({ show, onCloseModal }: Props) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setFocus,
    trigger,
    setValue,
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (show) {
      return;
    }
    reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = async (formData: FormData) => {
    try {
      if (isNaN(formData.saleId as any)) {
        myToastr.error('El código de la venta debe ser numérico');
        return;
      }
      const saleId: number = parseInt(formData.saleId, 10);
      const sale: Sale = await api.getSale(saleId);
      navigate(`/venta/${sale.id}`);
      onCloseModal();
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      setValue('saleId', '');
      setFocus('saleId');
    }
  };

  return (
    <Modal className="vercomi-modal my-form brand-modal" isOpen={show} onRequestClose={onCloseModal} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Escanear ticket</div>
            <button type="button" className="close-button-modal" onClick={onCloseModal} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.saleId })}>Identificador de la venta</div>
              <input
                type="text"
                {...register('saleId', { required: true })}
                className={clsx({ error: errors?.saleId })}
                placeholder="Identificador de la venta"
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    trigger();
                  }
                }}
                onBlur={() => setFocus('saleId')}
                autoFocus
              />
              {errors.saleId?.type === 'required' && <div className="error-message">El campo es obligatorio</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Buscar venta
        </button>
      </form>
    </Modal>
  );
};

export default ScanEanSaleModal;
