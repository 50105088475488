import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { CancelSaleDto } from '../interfaces/cancel-sale.dto';
import { Organization } from '../interfaces/organization';
import { PaymentMethod } from '../interfaces/payment-method';
import { SalePaymentMethodDto } from '../interfaces/sale-payment-method.dto';
import { roundTwoDecimals } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { cancelSale, saleSelector } from '../store/sale-slice';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';
import PinModal from './pin-modal';

interface Props {
  show: boolean;
  closeModal: (result: boolean) => void;
}

const CancelReservationModal = (props: Props) => {
  const dispatch = useDispatch();
  const { sale } = useSelector(saleSelector);
  const { store } = useSelector(storeSelector);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);
  const [values, setValues] = useState<{ [key: number]: number | null }>({});
  const storePaymentMethods: PaymentMethod[] = useMemo(() => {
    if (!store) {
      return [];
    }
    return store.paymentMethods.filter((paymentMethod: PaymentMethod) => {
      if (paymentMethod.deleted) {
        return false;
      }
      if (paymentMethod.online) {
        return false;
      }
      if (!paymentMethod.availableInPos) {
        return false;
      }
      return true;
    });
  }, [store]);
  const total: number = useMemo(() => {
    if (!sale) {
      return 0;
    }
    return sale.bookingPayments.reduce((acc: number, bookingPayment: SalePaymentMethodDto) => acc + (bookingPayment.amount || 0), 0);
  }, [sale]);
  const pending: number = useMemo(() => {
    if (!sale) {
      return 0;
    }
    let pending = total;
    for (const paymentMethodId in values) {
      const value: number | null = values[paymentMethodId];
      if (value) {
        pending -= value;
      }
    }
    return roundTwoDecimals(pending);
  }, [sale, total, values]);

  useEffect(() => {
    if (!props.show) {
      setValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const close = () => {
    props.closeModal(false);
  };

  const onSubmit = async () => {
    if (pending !== 0) {
      myToastr.error('El importe a devolver no coincide con el total');
      return;
    }
    setShowPinModal(true);
  };

  const onClosePinModal = async (pin: string | null) => {
    if (!pin) {
      setShowPinModal(false);
      return;
    }
    try {
      const cancelPaymentMethods: SalePaymentMethodDto[] = [];
      for (const paymentMethodId in values) {
        const value: number | null = values[paymentMethodId];
        if (value) {
          cancelPaymentMethods.push({
            paymentMethodId: parseInt(paymentMethodId),
            amount: value,
          });
        }
      }
      const cancelSaleDto: CancelSaleDto = {
        organizationId: organization.id,
        pin,
        cancelPaymentMethods,
      };
      dispatch(cancelSale(cancelSaleDto));
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
    setShowPinModal(false);
    props.closeModal(true);
  };

  return (
    <Modal className="vercomi-modal my-form cancel-reservation-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <div className="content">
        <div className="d-flex justify-content-between mb-3">
          <div className="title">Devolución reserva {sale?.internalReservationId}</div>
          <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
            <X size={16} />
          </button>
        </div>
        <div className="row my-3">
          <div className="col">
            <div className="input-name">Total:</div>
            <input type="text" value={`${total}€`} disabled />
          </div>
          <div className="col">
            <div className="input-name">Pendiente:</div>
            <input type="text" value={`${pending}€`} disabled />
          </div>
        </div>
        <div className="row">
          {storePaymentMethods.map((paymentMethod: PaymentMethod) => {
            return (
              <div key={paymentMethod.id} className="col-6 mb-3">
                <div className={clsx('input-name')}>{paymentMethod.name}:</div>
                <input
                  type="number"
                  step="0.01"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0.00€"
                  onChange={(e: any) => {
                    let value: number | null = null;
                    if (e.target.value) {
                      try {
                        value = parseFloat(e.target.value);
                        if (isNaN(value)) {
                          value = null;
                        }
                      } catch (e) {}
                    }
                    setValues({ ...values, [paymentMethod.id]: value });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <button className={`save-button`} type="button" onClick={onSubmit}>
        Guardar
      </button>
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
    </Modal>
  );
};

export default CancelReservationModal;
