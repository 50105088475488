import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Role } from '../enums/role';
import { MarkAllNotificationsAsReadDto } from '../interfaces/mark-all-notifications-as-read.dto';
import { Notification } from '../interfaces/notification';
import { User } from '../interfaces/user';
import { useAppSelector } from '../store/hooks';
import { markAllNotificationsAsRead, notificationsSelector } from '../store/notifications-slice';
import { RootState } from '../store/store';
import NotificationView from './notification';
import PinModal from './pin-modal';

interface Props {
  onClose: () => void;
}

const UnreadNotifications = (props: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const organization = useAppSelector((state: RootState) => state.auth.organization!);
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const { totalUnreadNotifications } = useSelector(notificationsSelector);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);

  const onClosePinModal = async (pin: string | null) => {
    setShowPinModal(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    const markAllNotificationsAsReadDto: MarkAllNotificationsAsReadDto = {
      organizationId: organization.id,
      pin,
    };
    dispatch(markAllNotificationsAsRead(markAllNotificationsAsReadDto));
  };

  if (!totalUnreadNotifications) {
    return null;
  }

  return (
    <div className="notifications">
      <div className="header">Notificaciones</div>
      <div className="body">
        {totalUnreadNotifications.total === 0 ? (
          <div className="no-notifications">No hay notificaciones pendientes</div>
        ) : (
          totalUnreadNotifications.notifications.map((notification: Notification) => <NotificationView key={notification.id} notification={notification} onClose={props.onClose} />)
        )}
      </div>
      {(totalUnreadNotifications.total > 0 || user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
        <div className="footer">
          {totalUnreadNotifications.total > 0 && (
            <div className="mb-2 clickable" onClick={() => setShowPinModal(true)}>
              Marcar todas las notifications como leídas
            </div>
          )}
          {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
            <Link to="/notificaciones" onClick={props.onClose} className={location.pathname === '/notificaciones' ? 'active' : ''}>
              Histórico de notificaciones
            </Link>
          )}
        </div>
      )}
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
    </div>
  );
};

export default UnreadNotifications;
