import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { Plus, Trash2 } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { ManualSaleProduct } from '../interfaces/manual-sale-product';
import { ManualSaleProductDto } from '../interfaces/manual-sale-product.dto';
import { PosItem } from '../interfaces/pos-item';
import { SaleProduct } from '../interfaces/sale-product';
import { formatNumber, roundTwoDecimals } from '../services/helpers';
import myToastr from '../services/toastr';
import {
  addManualNewProduct,
  removeItemDiscount,
  removeManualItemDiscount,
  removeManualNewProduct,
  removeManualProductToReturn,
  removeNewProduct,
  removeProductToReturn,
  saleSelector,
  setItemDiscount,
  setItemTotal,
  setManualItemDiscount,
  setManualItemTotal,
  setManualProductPendingToAdd,
  setQuantityManualNewProduct,
  setQuantityManualProductToReturn,
  setQuantityNewProduct,
  setQuantityProductToReturn,
} from '../store/sale-slice';
import DiscountModal from './discount-modal';
import PosProductTotalModal from './pos-product-total-modal';
import ProductUnitsInOrganization from './product-units-in-stores';
import ProductUnitsModal from './product-units-modal';

interface Props {
  enableActions: boolean;
}

export const ReturnsAndExchangesTable = (props: Props) => {
  const { productsToReturn, manualProductsToReturn, newProducts, newManualSaleProducts, availableUnits, manualAvailableUnits, sale } = useSelector(saleSelector);
  const dispatch = useDispatch();
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const [selectedManualProductId, setSelectedManualProductId] = useState<number | null>(null);
  const [productDiscount, setProductDiscount] = useState<number>(0);
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [selectedPosItem, setSelectedPosItem] = useState<PosItem | null>(null);
  const [showProductTotalModal, setShowProductTotalModal] = useState<boolean>(false);
  const [showProductUnitsModal, setShowProductUnitsModal] = useState<boolean>(false);
  const [showProductUnitsToReturnModal, setShowProductUnitsToReturnModal] = useState<boolean>(false);
  const organizationId: number = useMemo(() => {
    if (!sale) {
      return -1;
    }
    return sale.store.organizationId;
  }, [sale]);
  const [manualProductDto, setManualProductDto] = useState<ManualSaleProductDto>({
    name: '',
    pvp: 0,
    quantity: 0,
    discountPercentage: 0,
    total: 0,
  });
  const [errorsManualProductDto, setErrorsManualProductDto] = useState<{ [key: string]: boolean }>({
    name: false,
    pvp: false,
    quantity: false,
    total: false,
  });
  const manualProductIsValid: boolean = useMemo(() => {
    return manualProductDto.name.length > 0 && manualProductDto.pvp !== null && manualProductDto.quantity !== null && manualProductDto.quantity > 0 && manualProductDto.total !== null;
  }, [manualProductDto]);
  const isOnlineSale: boolean = useMemo(() => {
    if (!sale) {
      return false;
    }
    if (!sale.onlineSaleId) {
      return false;
    }
    return true;
  }, [sale]);

  useEffect(() => {
    dispatch(setManualProductPendingToAdd(manualProductIsValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualProductIsValid]);

  const onCloseProductDiscountModal = () => {
    setShowDiscountModal(false);
    setSelectedProductId(null);
    setSelectedManualProductId(null);
  };

  const onCloseProductUnitsModal = () => {
    setShowProductUnitsModal(false);
    setSelectedProductId(null);
    setSelectedManualProductId(null);
  };

  const onCloseProductUnitsToReturnModal = () => {
    setShowProductUnitsToReturnModal(false);
    setSelectedProductId(null);
    setSelectedManualProductId(null);
  };

  const onSaveProductDiscountModal = (discount: number) => {
    if (discount) {
      if (selectedProductId) {
        dispatch(setItemDiscount({ productId: selectedProductId!, discount }));
      } else {
        dispatch(setManualItemDiscount({ index: selectedManualProductId!, discount }));
      }
    } else {
      if (selectedProductId) {
        dispatch(removeItemDiscount(selectedProductId!));
      } else {
        dispatch(removeManualItemDiscount(selectedManualProductId!));
      }
    }
    setShowDiscountModal(false);
    setSelectedProductId(null);
  };

  const onSaveProductUnitsModal = (units: number) => {
    if (selectedProductId) {
      dispatch(setQuantityNewProduct({ productId: selectedProductId, units }));
    } else {
      dispatch(setQuantityManualNewProduct({ index: selectedManualProductId!, units: units }));
    }
    setShowProductUnitsModal(false);
    setSelectedProductId(null);
    setSelectedManualProductId(null);
  };

  const onSaveProductUnitsToReturnModal = (units: number) => {
    if (selectedProductId) {
      const saleProduct: SaleProduct = productsToReturn.find((sp: SaleProduct) => sp.productId === selectedProductId!)!;
      if (units >= 0 && units <= availableUnits[saleProduct.productId]) {
        dispatch(setQuantityProductToReturn({ productId: selectedProductId!, quantity: units }));
      } else {
        myToastr.info(`No se puede devolver más de ${availableUnits[saleProduct.productId]} ${availableUnits[saleProduct.productId] === 1 ? 'unidad' : 'unidades'} del producto`);
      }
    } else {
      const manualSaleProduct: ManualSaleProduct = manualProductsToReturn.find((msp: ManualSaleProduct) => msp.id === selectedManualProductId)!;
      if (units >= 0 && units <= manualAvailableUnits[manualSaleProduct.name.toLowerCase()]) {
        dispatch(setQuantityManualProductToReturn({ manualSaleProductId: selectedManualProductId!, quantity: units }));
      } else {
        myToastr.info(
          `No se puede devolver más de ${manualAvailableUnits[manualSaleProduct.name.toLowerCase()]} ${
            manualAvailableUnits[manualSaleProduct.name.toLowerCase()] === 1 ? 'unidad' : 'unidades'
          } del producto`,
        );
      }
    }
    setShowProductUnitsToReturnModal(false);
    setSelectedProductId(null);
    setSelectedManualProductId(null);
  };

  const onDeleteProductDiscountModal = () => {
    if (selectedProductId) {
      dispatch(removeItemDiscount(selectedProductId!));
    } else {
      dispatch(removeManualItemDiscount(selectedManualProductId!));
    }
    setShowDiscountModal(false);
    setSelectedProductId(null);
  };

  const onClosePosProductTotalModal = () => {
    setShowProductTotalModal(false);
    setSelectedPosItem(null);
    setSelectedManualProductId(null);
  };

  const onSavePosProductTotalModal = (total: number) => {
    if (selectedPosItem) {
      dispatch(setItemTotal({ productId: selectedPosItem!.stock.productId, total }));
    } else {
      dispatch(setManualItemTotal({ index: selectedManualProductId!, total }));
    }
    setShowProductTotalModal(false);
    setSelectedPosItem(null);
    setSelectedManualProductId(null);
  };

  const onDeletePosProductTotalModal = () => {
    if (selectedPosItem) {
      dispatch(removeItemDiscount(selectedPosItem!.stock.productId));
    } else {
      dispatch(removeManualItemDiscount(selectedManualProductId!));
    }
    setShowProductTotalModal(false);
    setSelectedPosItem(null);
    setSelectedManualProductId(null);
  };

  return (
    <React.Fragment>
      <div className="pos-products-table-container">
        <table className="my-table pos-products-table" style={{ maxWidth: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th className="text-start" style={{ width: '200px' }}>
                Producto
              </th>
              <th className="text-center" style={{ width: '65px' }}>
                Precio
              </th>
              <th className="text-center" style={{ width: '35px' }}>
                Uds.
              </th>
              <th className="" style={{ width: '65px' }}>
                Dto.
              </th>
              <th className="text-center" style={{ width: '65px' }}>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {productsToReturn.map((saleProduct: SaleProduct) => (
              <tr key={saleProduct.productId}>
                <td className="text-start">
                  <div className="d-flex align-items-center">
                    {props.enableActions && (
                      <span className="remove-icon">
                        <Trash2 onClick={() => dispatch(removeProductToReturn(saleProduct.productId))} size={16} className="remove-item" />
                      </span>
                    )}
                    {saleProduct.product.images.length > 0 && (
                      <LazyLoadImage className="img-thumbnail" src={process.env.REACT_APP_PUBLIC_URL + saleProduct.product.images[0].path} alt={saleProduct.product.name} width="50px" />
                    )}
                    <div className="">
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row">
                          <div className="name me-2">{saleProduct.product.name}</div>
                          <ProductUnitsInOrganization organizationId={organizationId} productId={saleProduct.productId} />
                        </div>
                        <div className="sku">{saleProduct.product.sku}</div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-column align-items-center noselect">
                    <div className="price">{formatNumber(saleProduct.pvp)}€</div>
                  </div>
                </td>
                <td className="text-center product-qty">
                  <div className="d-flex align-items-center justify-content-center">
                    <span
                      className={clsx('units', { 'cursor-default': !props.enableActions })}
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setSelectedProductId(saleProduct.productId);
                        setShowProductUnitsToReturnModal(true);
                      }}
                    >
                      {saleProduct.quantity}
                    </span>
                  </div>
                </td>
                <td className="">
                  {saleProduct.discountPercentage > 0 ? (
                    <span className={clsx('discount text-center with-value noselect cursor-default')} title="Descuento">
                      -{formatNumber(saleProduct.discountPercentage)}%
                    </span>
                  ) : (
                    <span className={clsx('discount noselect cursor-default text-center')} title="Producto sin descuento">
                      -
                    </span>
                  )}
                </td>
                <td className="text-center">
                  <span className={clsx('total discount noselect cursor-default return')}>-{formatNumber(saleProduct.total)}€</span>
                </td>
              </tr>
            ))}
            {manualProductsToReturn.map((manualSaleProduct: ManualSaleProduct) => (
              <tr key={manualSaleProduct.id}>
                <td className="text-start">
                  <div className="d-flex align-items-center">
                    {props.enableActions && (
                      <span className="remove-icon">
                        <Trash2 onClick={() => dispatch(removeManualProductToReturn(manualSaleProduct.id))} size={16} className="remove-item" />
                      </span>
                    )}
                    <div className="name manual me-2">{manualSaleProduct.name}</div>
                  </div>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-column align-items-center noselect">
                    <div className="price">{formatNumber(manualSaleProduct.pvp)}€</div>
                  </div>
                </td>
                <td className="text-center product-qty">
                  <div className="d-flex align-items-center justify-content-center">
                    <span
                      className={clsx('units', { 'cursor-default': !props.enableActions })}
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setSelectedManualProductId(manualSaleProduct.id);
                        setShowProductUnitsToReturnModal(true);
                      }}
                    >
                      {manualSaleProduct.quantity}
                    </span>
                  </div>
                </td>
                <td className="">
                  {manualSaleProduct.discountPercentage > 0 ? (
                    <span className={clsx('discount text-center with-value noselect cursor-default')} title="Descuento">
                      -{formatNumber(manualSaleProduct.discountPercentage)}%
                    </span>
                  ) : (
                    <span className={clsx('discount noselect cursor-default text-center')} title="Producto sin descuento">
                      -
                    </span>
                  )}
                </td>
                <td className="text-center">
                  <span className={clsx('total discount noselect cursor-default return')}>-{formatNumber(manualSaleProduct.total)}€</span>
                </td>
              </tr>
            ))}
            {/* CAMBIOS / NUEVOS PRODUCTOS */}
            {newProducts.map((posItem: PosItem) => (
              <tr key={posItem.stock.productId}>
                <td className="text-start">
                  <div className="d-flex align-items-center">
                    {props.enableActions && (
                      <span className="remove-icon">
                        <Trash2 onClick={() => dispatch(removeNewProduct(posItem.stock.productId))} size={16} className="remove-item" />
                      </span>
                    )}
                    {posItem.stock.product.images.length > 0 && (
                      <LazyLoadImage className="img-thumbnail" src={process.env.REACT_APP_PUBLIC_URL + posItem.stock.product.images[0].path} alt={posItem.stock.product.name} width="50px" />
                    )}
                    <div className="">
                      <div className="d-flex flex-column">
                        <div className="name">{posItem.stock.product.name}</div>
                        <div className="sku">{posItem.stock.product.sku}</div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-column align-items-center noselect">
                    <div className="price">{formatNumber(posItem.unitPrice)}€</div>
                  </div>
                </td>
                <td className="text-center product-qty">
                  <div className="d-flex align-items-center justify-content-center">
                    <span
                      className={clsx('units', { 'cursor-default': !props.enableActions })}
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setSelectedProductId(posItem.stock.productId);
                        setShowProductUnitsModal(true);
                      }}
                    >
                      {posItem.units}
                    </span>
                  </div>
                </td>
                <td className="">
                  {posItem.discountValue > 0 ? (
                    <span
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setProductDiscount(posItem.discountValue);
                        setSelectedProductId(posItem.stock.productId);
                        setShowDiscountModal(true);
                      }}
                      className={clsx('discount text-center with-value noselect mx-3', props.enableActions ? 'clickable' : 'cursor-default')}
                      title="Modificar descuento"
                    >
                      -{formatNumber(posItem.discountValue)}%
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setProductDiscount(0);
                        setSelectedProductId(posItem.stock.productId);
                        setShowDiscountModal(true);
                      }}
                      className={clsx('text-center discount noselect', props.enableActions ? 'clickable' : 'cursor-default')}
                      title="Añadir descuento"
                    >
                      Desc
                    </span>
                  )}
                </td>
                <td className="text-center">
                  <span
                    onClick={() => {
                      if (!props.enableActions) {
                        return;
                      }
                      setSelectedPosItem(posItem);
                      setShowProductTotalModal(true);
                    }}
                    className={clsx('total discount noselect change', props.enableActions ? 'clickable' : 'cursor-default')}
                    title="Modificar total"
                  >
                    {formatNumber(posItem.total)}€
                  </span>
                </td>
              </tr>
            ))}
            {/* CAMBIOS / NUEVOS PRODUCTOS MANUALES */}
            {newManualSaleProducts.map((manualSaleProduct: ManualSaleProductDto, index: number) => (
              <tr key={manualSaleProduct.name}>
                <td className="text-start">
                  <div className="d-flex align-items-center">
                    <span className="remove-icon">
                      <Trash2 onClick={() => dispatch(removeManualNewProduct(index))} size={16} className="remove-item" />
                    </span>
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row">
                        <div className="name manual me-2">{manualSaleProduct.name}</div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-column align-items-center noselect">
                    <div className="price">{formatNumber(manualSaleProduct.pvp)}€</div>
                  </div>
                </td>
                <td className="text-center product-qty">
                  <div className="d-flex align-items-center justify-content-center">
                    <span
                      className={clsx('units', { 'cursor-default': !props.enableActions })}
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setSelectedManualProductId(index);
                        setShowProductUnitsModal(true);
                      }}
                    >
                      {manualSaleProduct.quantity!}
                    </span>
                  </div>
                </td>
                <td className="">
                  {manualSaleProduct.discountPercentage !== null && manualSaleProduct.discountPercentage! > 0 ? (
                    <span
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setProductDiscount(manualSaleProduct.discountPercentage!);
                        setSelectedManualProductId(index);
                        setShowDiscountModal(true);
                      }}
                      className={clsx('discount text-center with-value noselect', props.enableActions ? 'clickable' : 'cursor-default')}
                      title="Modificar descuento"
                    >
                      -{formatNumber(manualSaleProduct.discountPercentage)}%
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        if (!props.enableActions) {
                          return;
                        }
                        setProductDiscount(0);
                        setSelectedManualProductId(index);
                        setShowDiscountModal(true);
                      }}
                      className={clsx('text-center discount noselect', props.enableActions ? 'clickable' : 'cursor-default')}
                      title="Añadir descuento"
                    >
                      {props.enableActions ? 'Desc.' : '-'}
                    </span>
                  )}
                </td>
                <td className="text-center">
                  <span
                    onClick={() => {
                      if (!props.enableActions) {
                        return;
                      }
                      setSelectedManualProductId(index);
                      setShowProductTotalModal(true);
                    }}
                    className={clsx('total discount noselect', props.enableActions ? 'clickable' : 'cursor-default')}
                    title={props.enableActions ? 'Modificar total' : ''}
                  >
                    {formatNumber(manualSaleProduct.total)}€
                  </span>
                </td>
              </tr>
            ))}
            {/* DEVOLUCIONES */}
            {productsToReturn.length === 0 && manualProductsToReturn.length === 0 ? (
              <tr>
                <td colSpan={5} className="text-center">
                  No se han seleccionado productos para devolver
                </td>
              </tr>
            ) : !isOnlineSale ? (
              /* CAMBIOS / NUEVO PRODUCTO MANUAL */
              <tr className="manual-product">
                <td>
                  <div className="d-flex flex-row align-items-center">
                    <Plus
                      type="button"
                      color={manualProductIsValid ? '#26c44b' : 'gray'}
                      className="me-2"
                      onClick={() => {
                        if (manualProductDto.name === null || manualProductDto.name.length === 0) {
                          setErrorsManualProductDto({ ...errorsManualProductDto, name: true });
                          myToastr.error('El nombre del producto es obligatorio');
                          return;
                        }
                        if (manualProductDto.pvp === null || manualProductDto.pvp === 0) {
                          setErrorsManualProductDto({ ...errorsManualProductDto, pvp: true });
                          myToastr.error('El precio del producto es obligatorio');
                          return;
                        }
                        if (manualProductDto.quantity === null || manualProductDto.quantity === 0) {
                          setErrorsManualProductDto({ ...errorsManualProductDto, quantity: true });
                          myToastr.error('La cantidad del producto es obligatoria');
                          return;
                        }
                        dispatch(addManualNewProduct(manualProductDto));
                        setManualProductDto({
                          name: '',
                          pvp: 0,
                          quantity: 0,
                          discountPercentage: 0,
                          total: 0,
                        });
                      }}
                      size={20}
                    />
                    <input
                      type="text"
                      value={manualProductDto.name}
                      onChange={(e: any) => {
                        setManualProductDto({ ...manualProductDto, name: e.target.value });
                        setErrorsManualProductDto({ ...errorsManualProductDto, name: false });
                      }}
                      className={clsx('form-control', { 'is-invalid': errorsManualProductDto.name })}
                      placeholder="Nombre del producto"
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="number"
                    min="0"
                    value={manualProductDto.pvp || ''}
                    onChange={(e: any) => {
                      let pvp: number | null = null;
                      if (e.target.value !== '') {
                        pvp = parseFloat(e.target.value);
                        if (isNaN(pvp)) {
                          pvp = null;
                        }
                      }
                      const discount: number = manualProductDto.discountPercentage || 0;
                      const quantity: number = manualProductDto.quantity || 0;
                      let total: number | null = null;
                      if (pvp !== null) {
                        total = quantity * pvp * (1 - discount / 100);
                      }
                      setManualProductDto({ ...manualProductDto, pvp, total });
                      setErrorsManualProductDto({ ...errorsManualProductDto, pvp: false });
                    }}
                    className={clsx('form-control', { 'is-invalid': errorsManualProductDto.pvp })}
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0.00€"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    min="0"
                    value={manualProductDto.quantity || ''}
                    onChange={(e: any) => {
                      let quantity: number | null = null;
                      if (e.target.value !== '') {
                        quantity = parseInt(e.target.value);
                        if (isNaN(quantity)) {
                          quantity = null;
                        }
                      }
                      const discount: number = manualProductDto.discountPercentage || 0;
                      const pvp: number = manualProductDto.pvp || 0;
                      let total: number | null = null;
                      if (quantity !== null) {
                        total = quantity * pvp * (1 - discount / 100);
                      }
                      setManualProductDto({ ...manualProductDto, quantity, total });
                      setErrorsManualProductDto({ ...errorsManualProductDto, quantity: false });
                    }}
                    className={clsx('form-control', { 'is-invalid': errorsManualProductDto.quantity })}
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    value={manualProductDto.discountPercentage || ''}
                    onChange={(e: any) => {
                      let discountPercentage: number | null = null;
                      if (e.target.value !== '') {
                        discountPercentage = parseFloat(e.target.value);
                        if (isNaN(discountPercentage)) {
                          discountPercentage = null;
                        } else {
                          discountPercentage = Math.min(100, Math.max(0, discountPercentage));
                        }
                      }
                      const pvp: number = manualProductDto.pvp || 0;
                      const quantity: number = manualProductDto.quantity || 0;
                      let total: number | null = null;
                      if (discountPercentage !== null) {
                        total = quantity * pvp * (1 - discountPercentage / 100);
                      } else {
                        total = quantity * pvp;
                      }
                      setManualProductDto({ ...manualProductDto, discountPercentage, total });
                    }}
                    className="form-control"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0.00%"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    min="0"
                    value={manualProductDto.total !== null ? manualProductDto.total : ''}
                    onChange={(e: any) => {
                      let total: number | null = null;
                      if (e.target.value !== '') {
                        total = parseFloat(e.target.value);
                        if (isNaN(total)) {
                          total = null;
                        }
                      }
                      const pvp: number = manualProductDto.pvp || 0;
                      const quantity: number = manualProductDto.quantity || 0;
                      const maxTotal: number = quantity * pvp;
                      let discountPercentage: number | null = null;
                      if (total !== null) {
                        discountPercentage = roundTwoDecimals(100 - (total * 100) / (quantity * pvp));
                        const totalDiscounted: number = quantity * pvp * (1 - discountPercentage / 100);
                        if (totalDiscounted > maxTotal) {
                          total = maxTotal;
                          discountPercentage = null;
                        }
                      }
                      setManualProductDto({ ...manualProductDto, discountPercentage, total });
                      setErrorsManualProductDto({ ...errorsManualProductDto, total: false });
                    }}
                    className={clsx('form-control', { 'is-invalid': errorsManualProductDto.total })}
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0.00€"
                  />
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <DiscountModal
        show={showDiscountModal}
        discountValue={productDiscount}
        closeModal={onCloseProductDiscountModal}
        saveDiscount={onSaveProductDiscountModal}
        deleteDiscount={onDeleteProductDiscountModal}
      />
      <ProductUnitsModal show={showProductUnitsToReturnModal} closeModal={onCloseProductUnitsToReturnModal} saveUnits={onSaveProductUnitsToReturnModal} />
      <ProductUnitsModal show={showProductUnitsModal} closeModal={onCloseProductUnitsModal} saveUnits={onSaveProductUnitsModal} />
      {selectedPosItem && (
        <PosProductTotalModal
          show={showProductTotalModal}
          maxTotal={selectedPosItem.discountedUnitPrice * selectedPosItem.units}
          discountValue={selectedPosItem.discountValue}
          closeModal={onClosePosProductTotalModal}
          saveTotal={onSavePosProductTotalModal}
          deleteTotal={onDeletePosProductTotalModal}
        />
      )}
      {selectedManualProductId !== null && showProductTotalModal && (
        <PosProductTotalModal
          show={showProductTotalModal}
          maxTotal={newManualSaleProducts[selectedManualProductId].pvp! * newManualSaleProducts[selectedManualProductId].quantity!}
          discountValue={newManualSaleProducts[selectedManualProductId].discountPercentage || 0}
          closeModal={onClosePosProductTotalModal}
          saveTotal={onSavePosProductTotalModal}
          deleteTotal={onDeletePosProductTotalModal}
        />
      )}
    </React.Fragment>
  );
};
