import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { ChevronLeft } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ellipse from '../assets/images/ellipse.svg';
import CustomerModal from '../components/customer-modal';
import Table from '../components/table';
import { PaymentMethodType } from '../enums/payment-method-type';
import { BookingPayment } from '../interfaces/booking-payment';
import { Customer } from '../interfaces/customer';
import { HistoricalBalance } from '../interfaces/historical-balance';
import { Sale } from '../interfaces/sale';
import { SalePaymentMethod } from '../interfaces/sale-payment-method';
import { SaleProduct } from '../interfaces/sale-product';
import { api } from '../services/api';
import { formatNumber } from '../services/helpers';

interface CustomerMovement {
  key: string;
  id: number;
  internalId: string;
  date: Date;
  total: number;
  hasNotes: boolean;
  paymentMethodType: PaymentMethodType;
}

const CustomerView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const [sales, setSales] = useState<Sale[]>([]);
  const { totalSales, customerMovements } = useMemo(() => {
    let totalSales = 0;
    const customerMovements: CustomerMovement[] = [];
    sales.forEach((sale: Sale) => {
      sale.bookingPayments.forEach((bookingPayment: BookingPayment) => {
        totalSales += bookingPayment.amount;
        customerMovements.push({
          key: `bookingPayment-${bookingPayment.id}`,
          id: sale.id,
          internalId: sale.internalId || sale.internalReservationId,
          date: moment(bookingPayment.createdAt).toDate(),
          total: bookingPayment.amount,
          hasNotes: sale.notes !== null && sale.notes.length > 0,
          paymentMethodType: bookingPayment.paymentMethod.type,
        });
      });
      sale.salePaymentMethods.forEach((salePaymentMethod: SalePaymentMethod) => {
        totalSales += salePaymentMethod.amount;
        customerMovements.push({
          key: `salePaymentMethod-${salePaymentMethod.id}`,
          id: sale.id,
          internalId: sale.internalId || sale.internalReservationId,
          date: moment(salePaymentMethod.createdAt).toDate(),
          total: salePaymentMethod.amount,
          hasNotes: sale.notes !== null && sale.notes.length > 0,
          paymentMethodType: salePaymentMethod.paymentMethod.type,
        });
      });
    });
    return { totalSales, customerMovements: customerMovements.sort((a: CustomerMovement, b: CustomerMovement) => b.date.getTime() - a.date.getTime()) };
  }, [sales]);

  const columns = useMemo(
    () => [
      {
        Header: 'Fecha',
        accessor: 'createdAt',
        disableFilters: true,
        Cell: (props: any) => moment(props.value).format('DD/MM/YYYY HH:mm'),
      },
      {
        Header: 'Cantidad',
        accessor: 'money',
        disableFilters: true,
        Cell: (props: any) => `${formatNumber(props.value)}€`,
      },
      {
        Header: 'Usuario',
        accessor: (hb: HistoricalBalance) => {
          let name = '';
          if (hb.user?.name) {
            name += hb.user.name;
          }
          if (hb.user?.surnames) {
            name += ` ${hb.user.surnames}`;
          }
          return name.trim();
        },
        disableFilters: true,
      },
    ],
    [],
  );

  useEffect(() => {
    if (params?.id) {
      const getSales = async () => {
        const s: Sale[] = await api.getCustomerSales(parseInt(params.id!, 10));
        setSales(s);
      };
      getCustomer();
      getSales();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getCustomer = async () => {
    try {
      const c: Customer = await api.getCustomer(parseInt(params.id!, 10));
      setCustomer(c);
    } catch (e) {
      navigate('/clientes');
    } finally {
    }
  };

  const onEditCustomer = () => {
    setShowCustomerModal(true);
  };

  const onCloseCustomerModal = (customer: Customer | null) => {
    if (customer) {
      getCustomer();
    }
    setShowCustomerModal(false);
  };

  if (!customer) {
    return null;
  }

  let name = 'Sin nombre';
  if (customer.name) {
    name = customer.name;
  }
  if (customer.surnames) {
    name += ` ${customer.surnames}`;
  }

  let labelSales = '';
  if (sales.length === 0) {
    labelSales = 'Sin pedidos';
  } else if (sales.length === 1) {
    labelSales = '1 pedido';
  } else if (sales.length > 1) {
    labelSales = `${sales.length} pedidos`;
  }

  const lastSale: Sale | null = sales.length > 0 ? sales[0] : null;

  return (
    <>
      <div className="customer-view d-flex flex-column p-4">
        <div className="d-flex justify-content-start align-items-center">
          <div onClick={() => navigate('/clientes')} className="come-back me-4">
            <ChevronLeft size={14} className="me-1" />
            Volver
          </div>
          <div className="customer-name">{name}</div>
        </div>
        <div className="row mt-4">
          <div className="col-8 px-2">
            <div className="d-flex flex-column container-info">
              <div className="d-flex flex-row justify-content-between">
                <div className="information mb-3">Información de contacto</div>
                <div onClick={onEditCustomer} className="edit" title="Editar información del cliente">
                  Editar
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Nombre</div>
                    <input value={customer.name || ''} placeholder="Nombre" disabled />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Apellidos</div>
                    <input value={customer.surnames || ''} placeholder="Apellidos" disabled />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Teléfono</div>
                    <input value={customer.phone || ''} placeholder="Teléfono" disabled />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Teléfono secundario</div>
                    <input value={customer.secondaryPhone || ''} placeholder="Teléfono secundario" disabled />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Dirección</div>
                    <input value={customer.address || ''} placeholder="Dirección" disabled />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Ciudad</div>
                    <input value={customer.city || ''} placeholder="Ciudad" disabled />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Provincia</div>
                    <input value={customer.province || ''} placeholder="Provincia" disabled />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Código postal</div>
                    <input value={customer.postalCode || ''} placeholder="Código postal" disabled />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Email</div>
                    <input value={customer.email || ''} placeholder="Email" disabled />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Notas</div>
                    <textarea value={customer.notes || ''} placeholder="Notas" disabled />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div className="information mb-3">Datos de facturación</div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Razón social</div>
                    <input value={customer.billingBusinessName || ''} placeholder="Razón social" disabled />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">CIF / NIF</div>
                    <input value={customer.cif || ''} placeholder="CIF / NIF" disabled />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Dirección (línea 1)</div>
                    <input value={customer.billingAddress1 || ''} placeholder="Dirección (línea 1)" disabled />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Dirección (línea 2)</div>
                    <input value={customer.billingAddress2 || ''} placeholder="Dirección (línea 2)" disabled />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Código postal</div>
                    <input value={customer.billingPostalCode || ''} placeholder="Código postal" disabled />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Ciudad</div>
                    <input value={customer.billingCity || ''} placeholder="Ciudad" disabled />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Provincia</div>
                    <input value={customer.billingProvince || ''} placeholder="Provincia" disabled />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Email</div>
                    <input value={customer.billingEmail || ''} placeholder="Email" disabled />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Teléfono</div>
                    <input value={customer.billingPhone || ''} placeholder="Teléfono" disabled />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-info my-4">
              <h6>Histórico de saldos</h6>
              <Table data={customer.historicalBalances} columns={columns} noDataMessage="No hay histórico de saldos"></Table>
            </div>
          </div>
          <div className="col-4 px-2">
            <div className="container-info">
              <div className="d-flex flex-column">
                <div className="title-input mb-2">Avisos</div>
                <div className={clsx('marketing', { 'not-accepted': !customer.marketing })}>{customer.marketing ? 'Acepta avisos' : 'No acepta avisos'}</div>
              </div>
              <div className="d-flex flex-column mt-3">
                <div className="title-input mb-2">Saldo</div>
                <div className="fw-bold">{formatNumber(customer.balance?.money || 0)}€</div>
              </div>
            </div>
            {lastSale && (
              <div className="container-info my-4">
                <div className="d-flex flex-column">
                  <div className="title-input mb-2">Último pedido</div>
                  <div className="d-flex justify-content-between container-sale mb-3">
                    <Link to={`/venta/${lastSale.id}`} className="link-sale">
                      <span className="fw-bold">{lastSale.internalId}</span>
                      {lastSale.notes && lastSale.notes.length > 0 && <img src={ellipse} alt="ellipse" className="sale-with-note" />}
                    </Link>
                    <div className="">{moment(lastSale.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                    <div className="total-sale">{formatNumber(lastSale.total)}€</div>
                  </div>
                  {lastSale.saleProducts.map((saleProduct: SaleProduct) => {
                    return (
                      <div key={saleProduct.id} className="d-flex flex-row justify-content-start align-items-center container-product-sale">
                        <div className="me-2">
                          {saleProduct.product.images.length > 0 && (
                            <LazyLoadImage className="img-thumbnail" src={process.env.REACT_APP_PUBLIC_URL + saleProduct.product.images[0].path} alt={saleProduct.product.name} width="50px" />
                          )}
                        </div>
                        <div>
                          <div className="d-flex flex-column mb-3">
                            <div className="name">{saleProduct.product.name}</div>
                            <div className="sku">{saleProduct.product.sku}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="container-info my-4">
              <div className="d-flex flex-column">
                <div className="title-input mb-2">Total gastado hasta la fecha</div>
                <div className="total-sales">{formatNumber(totalSales)}€</div>
                <div className="num-sales my-2">{labelSales}</div>
                {sales.length > 0 && (
                  <div className="d-flex justify-content-between header-sales">
                    <span className="">Venta</span>
                    <span className="">Fecha</span>
                    <span className="">Total</span>
                  </div>
                )}
                {customerMovements.map((customerMovement: CustomerMovement) => (
                  <div key={customerMovement.key} className="d-flex justify-content-between align-items-center container-sale">
                    <Link to={`/venta/${customerMovement.id}`} className="link-sale">
                      <span className="fw-bold">{customerMovement.internalId}</span>
                      {customerMovement.hasNotes && <img src={ellipse} alt="ellipse" className="sale-with-note" />}
                    </Link>
                    <div className="">{moment(customerMovement.date).format('DD/MM/YYYY HH:mm')}</div>
                    <div className="d-flex flex-column">
                      <span className="total-sale">{formatNumber(customerMovement.total)}€</span>
                      {customerMovement.paymentMethodType === PaymentMethodType.Balance && (
                        <span className="text-success" style={{ fontSize: 10 }}>
                          (Saldo)
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCustomerModal && <CustomerModal show={showCustomerModal} closeModal={onCloseCustomerModal} customer={customer} billingDataRequired={false} />}
    </>
  );
};

export default CustomerView;
