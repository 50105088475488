import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Role } from '../enums/role';
import { BookingPayment } from '../interfaces/booking-payment';
import { CashRegisterCheck } from '../interfaces/cash-register-check';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { formatNumber } from '../services/helpers';
import { useAppSelector } from '../store/hooks';
import { saleSelector, updateBookingPayment } from '../store/sale-slice';
import { RootState } from '../store/store';
import SelectPaymentMethodModal from './select-payment-method-modal';

const TableBookingPayments = () => {
  const user: User | null = useAppSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const { sale } = useSelector(saleSelector);
  const [currentCashRegisterCheck, setCurrentCashRegisterCheck] = useState<CashRegisterCheck | null>(null);
  const [selectedBookingPayment, setSelectedBookingPayment] = useState<BookingPayment | null>(null);

  const onCloseSelectPaymentMethod = (newPaymentMethodId: number) => {
    dispatch(
      updateBookingPayment({
        bookingPaymentId: selectedBookingPayment!.id,
        newPaymentMethodId,
      }),
    );
    setSelectedBookingPayment(null);
  };

  const getCurrentCashRegisterCheck = useCallback(async () => {
    try {
      const crc: CashRegisterCheck = await api.getCurrentCashRegisterCheck(sale!.storeId);
      setCurrentCashRegisterCheck(crc);
    } catch (e) {}
  }, [sale]);

  useEffect(() => {
    if (!sale || !user) {
      return;
    }
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      getCurrentCashRegisterCheck();
    }
  }, [getCurrentCashRegisterCheck, sale, user]);

  if (!sale) {
    return null;
  }

  const numBookingPayments: number = sale.bookingPayments.length || 0;

  return (
    <div className="table-booking-payments">
      <table className="my-table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Método de pago</th>
            <th>Cantidad</th>
            {currentCashRegisterCheck !== null && <th>Acciones</th>}
          </tr>
        </thead>
        <tbody>
          {numBookingPayments === 0 ? (
            <tr className="text-center">
              <td colSpan={currentCashRegisterCheck !== null ? 4 : 3}>No se han añadido pagos a cuenta</td>
            </tr>
          ) : (
            sale.bookingPayments.map((bookingPayment: BookingPayment) => (
              <tr key={bookingPayment.id}>
                <td>{moment(bookingPayment.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                <td>{bookingPayment.paymentMethod.name}</td>
                <td>{formatNumber(bookingPayment.amount)}€</td>
                <td>
                  {currentCashRegisterCheck !== null && currentCashRegisterCheck.createdAt <= bookingPayment.createdAt && (
                    <div onClick={() => setSelectedBookingPayment(bookingPayment)} className="cursor-pointer" title="Cambiar método de pago">
                      <RefreshCw size={14} />
                    </div>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <SelectPaymentMethodModal
        amount={selectedBookingPayment?.amount || 0}
        customerId={sale.customerId}
        onCancel={() => setSelectedBookingPayment(null)}
        onSave={onCloseSelectPaymentMethod}
        show={selectedBookingPayment !== null}
        storeId={sale.storeId}
        paymentMethodId={selectedBookingPayment?.paymentMethod.id || -1}
      />
    </div>
  );
};

export default TableBookingPayments;
