import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/sierra_logo_home.svg';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { RecoverPasswordDto } from '../interfaces/recover-password.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';

const RecoverPasswordView = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RecoverPasswordDto>({
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    const token: string | null = localStorage.getItem('token');
    if (!token) {
      return;
    }
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (recoverPasswordDto: RecoverPasswordDto) => {
    try {
      await api.recoverPassword(recoverPasswordDto);
      myToastr.success('Se ha enviado un correo electrónico con las instrucciones para recuperar la contraseña');
      navigate('/login');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  return (
    <div className="login my-form">
      <LazyLoadImage src={logo} alt="" className="home-logo" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Recuperar contraseña</div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.email ? 'error' : '')}>Correo electrónico *</div>
              <input type="email" {...register('email', { required: true })} className={clsx({ error: errors?.email })} placeholder="Introduce tu correo electrónico" />
              {errors.email && <div className="error-message">{errors.email.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Aceptar
        </button>
      </form>
    </div>
  );
};

export default RecoverPasswordView;
