import clsx from 'clsx';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { ChevronLeft, Plus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SaleStatus } from '../enums/sale-status';
import { SaleStep } from '../enums/sale-step';
import { Customer } from '../interfaces/customer';
import { saleSelector, setStep, updateSaleCustomer, updateSaleNotes } from '../store/sale-slice';
import CustomerInfo from './customer-info';
import NoteModal from './note-modal';
import { ReturnsProductsTable } from './returns-products-table';
import SaleProductsFinder from './sale-products-finder';
import { SaleProductsTable } from './sale-products-table';
import SaleSubtotals from './sale-subtotals';

const SaleSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { balance, customer, sale, notes, step, productsToReturn, manualProductsToReturn } = useSelector(saleSelector);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const title: string = useMemo(() => {
    let title = '';
    if (sale?.status === SaleStatus.Finalized || sale?.status === SaleStatus.Pending) {
      title = `Venta ${sale.internalId}`;
    } else if (sale?.status === SaleStatus.Cancelled) {
      if (sale.internalId) {
        title = `Venta ${sale.internalId}`;
      } else {
        title = `Reserva ${sale.internalReservationId}`;
      }
    } else if (sale?.status === SaleStatus.Return) {
      title = `Devolución ${sale.internalId}`;
    } else if (sale?.status === SaleStatus.Reservation) {
      title = `Reserva ${sale.internalReservationId}`;
    }
    return title;
  }, [sale]);

  const onCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  const onSaveNoteModal = (notes: string | null) => {
    dispatch(updateSaleNotes({ id: sale!.id, notes }));
    setShowNoteModal(false);
  };

  const onDeleteNoteModal = () => {
    dispatch(updateSaleNotes({ id: sale!.id, notes: '' }));
    setShowNoteModal(false);
  };

  const createdAtStr = sale?.createdAt && moment(sale!.createdAt).isValid() ? moment(sale!.createdAt).format('YYYY-MM-DD HH:mm') : '';
  const updatedAtStr = sale?.updatedAt && moment(sale!.updatedAt).isValid() ? moment(sale!.updatedAt).format('YYYY-MM-DD HH:mm') : '';

  return (
    <React.Fragment>
      <div className="sale-summary sale d-flex flex-column p-4">
        <div className="d-flex justify-content-between align-items-start mb-3 noselect">
          <div
            onClick={() => {
              switch (step) {
                case SaleStep.ReturnsAndExchanges:
                  dispatch(setStep(SaleStep.Viewing));
                  break;
                default:
                  navigate('/ventas');
                  break;
              }
            }}
            className="d-flex flex-row align-items-center come-back"
          >
            <ChevronLeft className="me-1" size={14} />
            <span>Volver</span>
          </div>
          <div className="d-flex flex-column text-center">
            <h1 className="mb-0">{title}</h1>
            {sale?.onlineSaleId && (
              <span className="date">
                Venta online {sale.onlineSaleId} - {sale.onlineStore.name}
              </span>
            )}
            <span className="date">Fecha creación: {createdAtStr}</span>
            {createdAtStr !== updatedAtStr && <span className="date">Fecha última modificación: {updatedAtStr}</span>}
          </div>
          {step === SaleStep.Viewing ? (
            <div onClick={() => setShowNoteModal(true)} className={clsx('d-flex align-items-center button-header', { 'has-notes': notes != null && notes !== '' })}>
              {notes != null && notes !== '' ? (
                '1 Nota'
              ) : (
                <React.Fragment>
                  <Plus className="me-1" size={14} />
                  <span>Nota</span>
                </React.Fragment>
              )}
            </div>
          ) : (
            <span></span>
          )}
        </div>
        <SaleProductsTable />
        <SaleSubtotals />
        {step === SaleStep.Viewing && (
          <React.Fragment>
            <CustomerInfo balance={balance} customer={customer!} onAssignCustomer={(newCustomer: Customer) => dispatch(updateSaleCustomer(newCustomer.id))} />
            <ReturnsProductsTable />
          </React.Fragment>
        )}
        {step === SaleStep.ReturnsAndExchanges && (productsToReturn.length > 0 || manualProductsToReturn.length > 0) && sale?.onlineSaleId === null && <SaleProductsFinder />}
      </div>
      <NoteModal editable={true} show={showNoteModal} notes={notes} closeModal={onCloseNoteModal} saveNotes={onSaveNoteModal} deleteNotes={onDeleteNoteModal} />
    </React.Fragment>
  );
};

export default SaleSummary;
