import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async';
import { Product } from '../interfaces/product';
import { api } from '../services/api';
import { styleMultiSelectInModal } from '../styless/multiselect-style';
import ProductOption from './product-option';

const promiseProductOptions = async (inputValue: string) => {
  if (inputValue == null || inputValue === '') {
    return [];
  }
  const products: Product[] = await api.getProducts({ query: inputValue });
  const options: any[] = products.map((product: Product) => {
    return {
      value: product.id,
      label: product.sku,
      product,
    };
  });
  options.push({
    custom: true,
  });
  return options;
};

interface Props {
  show: boolean;
  onClose: (product: Product | null) => void;
}

const ProductsFinderModal = (props: Props) => {
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    setProduct(null);
  }, [props.show]);

  const onChange = (e: any) => {
    setProduct(e ? e.product : null);
  };

  const accept = () => {
    props.onClose(product);
  };

  const close = () => {
    props.onClose(null);
  };

  return (
    <Modal className="vercomi-modal my-form products-finder-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <div className="content">
        <div className="d-flex justify-content-between mb-3">
          <div className="title">Buscar producto</div>
          <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
            <X size={16} />
          </button>
        </div>
        <div className="row my-3">
          <div className="col">
            <AsyncSelect
              autoFocus={true}
              cacheOptions
              placeholder="Buscar producto"
              defaultOptions
              noOptionsMessage={({ inputValue }) => (!inputValue ? null : <div className="enter-text my-2">No se han encontrado productos</div>)}
              onChange={onChange}
              isClearable
              loadingMessage={() => 'Buscando productos...'}
              loadOptions={(inputValue: string) => promiseProductOptions(inputValue)}
              components={{
                DropdownIndicator: null,
                Option: (props: any) => <ProductOption {...props} onCreate={() => {}} />,
              }}
              styles={styleMultiSelectInModal(false)}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
      </div>
      <button className={`save-button`} type="button" onClick={accept}>
        Aceptar
      </button>
    </Modal>
  );
};

export default ProductsFinderModal;
