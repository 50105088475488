import { ChevronLeft } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { repairSelector, reset } from '../store/repair-slice';

interface Props {
  isNew: boolean;
}

const RepairHeader = ({ isNew }: Props) => {
  const dispatch = useDispatch();
  const { repair, warranty } = useSelector(repairSelector);

  return (
    <div className="new-repair-header">
      <div className="d-flex align-items-center">
        <div className="col">
          {!isNew && (
            <Link to="/reparaciones" className="d-flex flex-row align-items-center come-back">
              <ChevronLeft className="me-1" size={14} />
              <span>Volver</span>
            </Link>
          )}
          {isNew && warranty !== null && (
            <div onClick={() => dispatch(reset())} className="d-flex flex-row align-items-center come-back">
              <ChevronLeft className="me-1" size={14} />
              <span>Volver</span>
            </div>
          )}
        </div>
        <div className="col text-center">
          <h1 className="title mb-0">Composturas</h1>
        </div>
        <div className="col">{(warranty || repair?.warranty) && <div className="in-warranty">En garantía</div>}</div>
      </div>
    </div>
  );
};

export default RepairHeader;
