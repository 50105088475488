import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { Category } from '../interfaces/category';
import { CategoryDto } from '../interfaces/category.dto';
import { Organization } from '../interfaces/organization';
import { SubCategory } from '../interfaces/subcategory';
import { SubCategoryDto } from '../interfaces/subcategory.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  categoryId: number | null;
  subCategoryId: number | null;
  show: boolean;
  closeModal: (result: boolean) => void;
}

interface CustomCategoryDto {
  name: string;
  categoryId: number;
  subCategoryId: number;
}

const initialValues: CustomCategoryDto = {
  name: '',
  categoryId: -1,
  subCategoryId: -1,
};

const CategorySubcategoryModal = ({ categoryId, subCategoryId, show, closeModal }: Props) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [category, setCategory] = useState<Category | null>(null);
  const [subCategory, setSubCategory] = useState<SubCategory | null>(null);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CustomCategoryDto>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  const title: string = useMemo(() => {
    if ((categoryId === null || categoryId === -1) && subCategory === null) {
      return 'Añadir Categorías o Subcategorías ';
    } else {
      if (subCategoryId === null || subCategoryId === -1) {
        return 'Editar categoría';
      } else {
        return 'Editar subcategoría';
      }
    }
  }, [categoryId, subCategory, subCategoryId]);

  useEffect(() => {
    if (!show) {
      return;
    }
    const getCategories = async () => {
      try {
        const cs: Category[] = await api.getCategories();
        setCategories(cs.filter((c: Category) => c.organizationId === organization!.id));
      } catch (e) {}
    };
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!show) {
      return;
    }
    if (subCategoryId) {
      const getSubCategory = async () => {
        try {
          const subCategory: SubCategory = await api.getSubCategory(subCategoryId);
          setSubCategory(subCategory);
          reset({
            name: subCategory.name,
            categoryId: subCategory.categoryId,
            subCategoryId: subCategory.id,
          });
        } catch (e) {}
      };
      getSubCategory();
    } else if (categoryId) {
      const getCategory = async () => {
        try {
          const category: Category = await api.getCategory(categoryId!);
          setCategory(category);
          reset({
            name: category.name,
            categoryId: category.id,
            subCategoryId: -1,
          });
        } catch (e) {}
      };
      getCategory();
    }
  }, [show, categoryId, subCategoryId, reset]);

  const close = () => {
    reset(initialValues);
    setCategory(null);
    setSubCategory(null);
    closeModal(false);
  };

  const onSubmit = async (data: CustomCategoryDto) => {
    try {
      if ((categoryId === null || categoryId === -1) && subCategory === null) {
        if (data.categoryId === null || data.categoryId === -1) {
          const categoryDto: CategoryDto = {
            name: data.name,
            organizationId: organization!.id,
          };
          await api.createCategory(categoryDto);
          myToastr.success('Categoría creada correctamente');
        } else {
          const subCategoryDto: SubCategoryDto = {
            name: data.name,
            categoryId: data.categoryId!,
          };
          await api.createSubCategory(subCategoryDto);
          myToastr.success('Subcategoría creada correctamente');
        }
        closeModal(true);
      } else {
        if (subCategoryId === null || subCategoryId === -1) {
          const categoryDto: CategoryDto = {
            name: data.name,
            organizationId: organization!.id,
          };
          await api.updateCategory(categoryId!, categoryDto);
          myToastr.success('Categoría actualizada correctamente');
        } else {
          const subCategoryDto: SubCategoryDto = {
            name: data.name,
            categoryId: data.categoryId!,
          };
          await api.updateSubCategory(subCategoryId!, subCategoryDto);
          myToastr.success('Subcategoría actualizada correctamente');
        }
        closeModal(true);
      }
      reset(initialValues);
      setCategory(null);
      setSubCategory(null);
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  return (
    <Modal className="vercomi-modal my-form category-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">{title}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.name })}>Nombre</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre de categoría o subcategoría" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
          </div>
          {category === null && (
            <div className="row my-3">
              <div className="col">
                <div className={clsx('input-name', { error: errors?.categoryId })}>Categoría padre (para subcategorías)</div>
                <select
                  {...register('categoryId', { required: subCategoryId !== null && subCategoryId !== -1, valueAsNumber: true })}
                  className={clsx({ error: errors?.categoryId })}
                  disabled={category !== null || subCategory !== null}
                >
                  <option value={-1}>Selecciona una opción</option>
                  {categories.map((category: Category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default CategorySubcategoryModal;
