import axios, { AxiosError } from 'axios';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { ChevronLeft, Key } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate, useParams } from 'react-router-dom';
import userShadow from '../assets/images/user.svg';
import PinModal from '../components/pin-modal';
import { PinDto } from '../components/pin.dto';
import { Role } from '../enums/role';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { RecoverPinDto } from '../interfaces/recover-pin.dto';
import { User } from '../interfaces/user';
import { UserInOrganization } from '../interfaces/user-in-organization';
import { api } from '../services/api';
import { roleToString } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const UserView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [user, setUser] = useState<User | null>(null);
  const [showPinModal, setShowPinModal] = useState<boolean>(true);
  const role: Role | null = useMemo(() => {
    if (!user) {
      return null;
    }
    const userInOrganization: UserInOrganization | undefined = user.userInOrganizations.find((uio: UserInOrganization) => uio.organizationId === organization.id);
    if (!userInOrganization) {
      return null;
    }
    return userInOrganization.role;
  }, [user, organization]);

  const onPinModalClose = async (pin: string | null) => {
    if (!pin) {
      navigate(-1);
      return;
    }
    try {
      const pinDto: PinDto = {
        organizationId: organization.id,
        pin,
      };
      await api.checkPin(pinDto);
      const userId: number = parseInt(params.id!, 10);
      const user: User = await api.getUser(userId);
      setUser(user);
      setShowPinModal(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const recoverPin = async () => {
    if (!user!.email) {
      myToastr.error(`No tienes configurado un email en tu cuenta`);
      return;
    }
    try {
      const recoverPinDto: RecoverPinDto = {
        organizationId: organization.id,
        userId: user!.id,
      };
      await api.recoverPin(recoverPinDto);
      myToastr.success('Se ha enviado un email con el pin');
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
  };

  return (
    <React.Fragment>
      <PinModal show={showPinModal} onCloseModal={onPinModalClose} />
      {user && (
        <div className="customer-view d-flex flex-column p-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              <div onClick={() => navigate('/usuarios')} className="come-back me-4">
                <ChevronLeft size={14} className="me-1" />
                Volver
              </div>
              <div className="customer-name">{user.username}</div>
            </div>
            <div className="d-flex flex-row">
              {/* <button className="d-flex align-items-center create-button" onClick={() => {}}>
                <Edit2 className="me-2" size={14} />
                Editar
              </button> */}
              <button className="d-flex align-items-center create-button" onClick={recoverPin}>
                <Key className="me-2" size={14} />
                Recuperar pin
              </button>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-2 px-2">
              <div className="container-info text-center">
                <LazyLoadImage src={user.imagePath ? process.env.REACT_APP_PUBLIC_URL + user.imagePath : userShadow} alt="user" className="img-thumbnail mb-2" />
                <div className="title-input mb-2">Usuario: {user.username}</div>
                <div className="title-input mb-2">Rol: {roleToString(role)}</div>
                <div className="title-input mb-2">Organización: {organization.name}</div>
              </div>
            </div>
            <div className="col-10 px-2">
              <div className="d-flex flex-column container-info">
                <div className="d-flex flex-row justify-content-between">
                  <div className="information mb-3">Información general</div>
                </div>
                <div className="title-input mb-2">
                  Nombre completo: {user.name || ''} {user.surnames || ''}
                </div>
                <div className="title-input mb-2">Fecha de nacimiento: {moment(user.birthDate).isValid() ? moment(user.birthDate).format('DD/MM/YYYY') : ''}</div>
                <div className="title-input mb-2">Correo electrónico: {user.email || ''}</div>
                <div className="title-input mb-2">Teléfono: {user.phone || ''}</div>
                <div className="title-input mb-2">DNI: {user.nid || ''}</div>
                <div className="title-input mb-2">Nº Seguridad Social: {user.socialSecurityNumber || ''}</div>
                <div className="title-input mb-2">
                  Dirección: {user.address || ''} {user.secondaryAddress || ''}
                </div>
                <div className="title-input mb-2">Provincia: {user.province || ''}</div>
                <div className="title-input mb-2">Código Postal: {user.postalCode || ''}</div>
              </div>
              <div className="d-flex flex-column container-info my-4">
                <div className="d-flex flex-row justify-content-between">
                  <div className="information mb-3">Información bancaria</div>
                </div>
                <div className="title-input mb-2">Cuenta bancaria: {user.bankAccount || ''}</div>
                <div className="title-input mb-2">SWIFT/BIC: {user.swiftBic || ''}</div>
              </div>
              <div className="d-flex flex-column container-info my-4">
                <div className="d-flex flex-row justify-content-between">
                  <div className="information mb-3">Contacto de emergencia</div>
                </div>
                <div className="title-input mb-2">Nombre contacto emergencia: {user.emergencyContactName || ''}</div>
                <div className="title-input mb-2">Teléfono contacto emergencia: {user.emergencyContactPhone || ''}</div>
              </div>
              <div className="d-flex flex-column container-info my-4">
                <div className="d-flex flex-row justify-content-between">
                  <div className="information mb-3">Horario</div>
                </div>
                <div className="title-input mb-2">Días libres: {user.daysOff || ''}</div>
                <div className="title-input mb-2">Vacaciones: {user.holidays || ''}</div>
                <div className="title-input mb-2">Tipo de contrato: {user.typeOfContract || ''}</div>
                <div className="title-input mb-2">Fecha de inicio de prueba: {moment(user.trialPeriodStartDate).format('DD/MM/YYYY') || ''}</div>
                <div className="title-input mb-2">Fecha de fin de prueba: {moment(user.trialPeriodEndDate).format('DD/MM/YYYY') || ''}</div>
                <div className="title-input mb-2">Fecha de fin de contrato: {moment(user.contractEndDate).format('DD/MM/YYYY') || ''}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserView;
