import clsx from 'clsx';
import moment from 'moment';
import printJS from 'print-js';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Plus, Printer, Trash2 } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row } from 'react-table';
import ellipse from '../assets/images/ellipse.svg';
import ConfirmModal from '../components/confirm-modal';
import DateRangeSelector from '../components/date-range-selector';
import Table from '../components/table';
import { DateRange } from '../enums/date-range';
import { InvoiceStatus } from '../enums/invoice-status';
import { InvoiceType } from '../enums/invoice-type';
import { Role } from '../enums/role';
import { Customer } from '../interfaces/customer';
import { DateRangeData } from '../interfaces/date-range-data';
import { Invoice } from '../interfaces/invoice';
import { Organization } from '../interfaces/organization';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { formatNumber, getDatesGivenDateRange } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

const dateRange: DateRange = DateRange.ThisYear;

const InvoicesView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { selectedStoreId, store } = useSelector(storeSelector);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData>({
    dateRange,
    dates: getDatesGivenDateRange(dateRange),
  });
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [requestingPdf, setRequestingPdf] = useState<boolean>(false);
  const [requestingXml, setRequestingXml] = useState<boolean>(false);
  const [showTotals, setShowTotals] = useState<boolean>(false);
  const { taxBase, vat, total } = useMemo(() => {
    const result: {
      taxBase: number;
      vat: number;
      total: number;
    } = {
      taxBase: 0,
      vat: 0,
      total: 0,
    };
    if (!showTotals) {
      return result;
    }
    filteredRows.forEach((row: Row) => {
      const invoice: Invoice = row.original as any;
      result.taxBase += invoice.taxBase;
      result.vat += invoice.vat;
      result.total += invoice.total;
    });
    return result;
  }, [showTotals, filteredRows]);
  const showNewCorrectiveInvoice: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager, [user]);
  const showNewInvoice: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager, [user]);

  useEffect(() => {
    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId, dateRangeData]);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Número',
        accessor: 'id',
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const invoice: Invoice = row.original;
          return (
            <Link to={`/factura/${invoice.id}`} className="link-sale">
              <span className="fw-bold">#{invoice.internalId}</span>
              {invoice.notes && invoice.notes.length > 0 && <img src={ellipse} alt="ellipse" style={{ position: 'absolute' }} />}
            </Link>
          );
        },
      },
      {
        Header: 'Tipo',
        accessor: 'invoiceType',
        Cell: ({ value }: any) => {
          let className = '';
          let textOption = '';
          switch (value) {
            case InvoiceType.Normal:
              textOption = 'Normal';
              className = 'my-success';
              break;
            case InvoiceType.Rectification:
              textOption = 'Rectificativa';
              className = 'my-danger';
              break;
          }
          return <span className={`badge ${className}`}>{textOption}</span>;
        },
      },
      {
        Header: 'Fecha',
        accessor: 'date',
        filter: 'fuzzyDate',
        Cell: ({ value }: any) => moment(value).format('DD/MM/YYYY'),
      },
      {
        Header: 'Cliente',
        accessor: (invoice: Invoice) => {
          return `${invoice.customer.billingBusinessName} - ${invoice.customer.cif}`;
        },
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const invoice: Invoice = row.original;
          return `${invoice.customer.billingBusinessName} - ${invoice.customer.cif}`;
        },
      },
      {
        Header: 'Base imponible',
        accessor: 'taxBase',
        Cell: ({ row }: any) => {
          const invoice: Invoice = row.original;
          return `${formatNumber(invoice.taxBase)}€`;
        },
      },
      {
        Header: 'IVA',
        accessor: 'vat',
        Cell: ({ row }: any) => {
          const invoice: Invoice = row.original;
          return `${formatNumber(invoice.vat)}€`;
        },
      },
      {
        Header: 'Precio total',
        accessor: 'total',
        Cell: ({ row }: any) => {
          const invoice: Invoice = row.original;
          return `${formatNumber(invoice.total)}€`;
        },
      },
      {
        Header: 'Estado',
        accessor: 'invoiceStatus',
        Cell: ({ value }: any) => {
          let className = '';
          let textOption = '';
          switch (value) {
            case InvoiceStatus.Posted:
              textOption = 'Contabilizada';
              className = 'my-success';
              break;
            case InvoiceStatus.Pending:
              textOption = 'Pendiente';
              className = 'my-warning';
              break;
          }
          return <span className={`badge ${className}`}>{textOption}</span>;
        },
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          const invoice: Invoice = row.original;
          return (
            <React.Fragment>
              {invoice.invoiceStatus === InvoiceStatus.Posted && (
                <div className="d-flex flex-row">
                  <div title="Descargar factura electrónica">
                    <Download
                      size={16}
                      color="#222E3D"
                      onClick={() => {
                        if (requestingXml) {
                          return;
                        }
                        exportElectronicInvoice(invoice);
                      }}
                      type="button"
                      className="mx-2"
                    />
                  </div>
                  <div title="Imprimir factura">
                    <Printer
                      size={16}
                      color="#222E3D"
                      onClick={() => {
                        if (requestingPdf) {
                          return;
                        }
                        printInvoice(invoice.id);
                      }}
                      type="button"
                      className="mx-2"
                    />
                  </div>
                </div>
              )}
              {invoice.invoiceStatus === InvoiceStatus.Pending && (
                <div title="Eliminar factura">
                  <Trash2
                    type="button"
                    className="mx-2"
                    onClick={() => {
                      setSelectedInvoice(invoice);
                      setShowConfirmModal(true);
                    }}
                    size={14}
                  />
                </div>
              )}
            </React.Fragment>
          );
        },
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestingPdf, requestingXml]);

  const getInvoices = async () => {
    const invoices: Invoice[] = await api.getInvoices(dateRangeData.dates[0], dateRangeData.dates[1], organization.id, selectedStoreId);
    setInvoices(invoices);
  };

  const deleteInvoice = async () => {
    try {
      await api.deleteInvoice(selectedInvoice!.id);
      await getInvoices();
      myToastr.success('Factura eliminada correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((row: any) => {
      const invoice: Invoice = row.original;
      const customer: Customer = invoice.customer;
      let customerValue = '';
      if (customer.name) {
        customerValue = customer.name;
        if (customer.surnames) {
          customerValue += ` ${customer.surnames}`;
        }
      } else {
        customerValue = customer.cif;
      }
      data.push({
        Número: invoice.internalId,
        Tipo: invoice.invoiceType === InvoiceType.Normal ? 'Normal' : 'Rectificativa',
        Fecha: moment(invoice.date).format('DD/MM/YYYY'),
        Cliente: customerValue,
        'Base imponible': `${formatNumber(invoice.taxBase)}€`,
        IVA: `${formatNumber(invoice.vat)}€`,
        'Precio total': `${formatNumber(invoice.total)}€`,
        Estado: invoice.invoiceStatus === InvoiceStatus.Posted ? 'Contabilizada' : 'Pendiente',
      });
    });
    setCsvData(data);
  };

  const exportElectronicInvoice = async (invoice: Invoice) => {
    setRequestingXml(true);
    try {
      myToastr.info('Obteniendo la factura electrónica. Espera por favor.');
      const result: ArrayBuffer = await api.getInvoiceXml(invoice.id);
      const url: string = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `factura-${invoice!.internalId}.xml`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      myToastr.error('Hubo un error obteniendo el XML de la factura');
    } finally {
      setRequestingXml(false);
    }
  };

  const printInvoice = async (id: number) => {
    setRequestingPdf(true);
    try {
      myToastr.info('Obteniendo la factura en formato PDF. Espera por favor.');
      const result: ArrayBuffer = await api.getInvoicePdf(id);
      const url: string = window.URL.createObjectURL(new Blob([result]));
      printJS(url);
    } catch (e) {
      myToastr.error('Hubo un error obteniendo la factura en formato PDF.');
    } finally {
      setRequestingPdf(false);
    }
  };

  return (
    <div className="invoices p-4">
      <div className="d-flex">
        <div className="d-flex flex-row flex-grow-1">
          <h1 className="me-4">Facturas</h1>
          <div className={clsx('check-switch', { switched: showTotals })}>
            <input type="checkbox" checked={showTotals} onChange={(e: any) => setShowTotals(e.target.checked)} />
            <label className="filter-notes">Totales</label>
          </div>
        </div>
        {filteredRows.length > 0 && (
          <CSVLink filename={store ? `facturas_${store.name}.csv` : 'facturas.csv'} className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {showNewCorrectiveInvoice && (
          <Link to="/factura" state={{ invoiceType: InvoiceType.Rectification }} className="d-flex align-items-center create-button" style={{ backgroundColor: '#e02760', textDecoration: 'none' }}>
            <Plus className="me-1" size={14} /> Nueva Rectificativa
          </Link>
        )}
        {showNewInvoice && (
          <Link to="/factura" state={{ invoiceType: InvoiceType.Normal }} className="d-flex align-items-center create-button mx-3" style={{ textDecoration: 'none' }}>
            <Plus className="me-1" size={14} /> Nueva factura
          </Link>
        )}
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={false}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
      </div>
      {showTotals && (
        <div className="totals-wrapper d-flex justify-content-around __totals mb-3">
          <span className="totals-info">Base imponible: {formatNumber(taxBase)}€</span>
          <span className="totals-info">IVA: {formatNumber(vat)}€</span>
          <span className="totals-info">Total: {formatNumber(total)}€</span>
        </div>
      )}
      <Table data={invoices} columns={columns} noDataMessage="No hay facturas" onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)} />
      {selectedInvoice && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Marca"
          content={`¿Estás seguro que quieres eliminar la factura #${selectedInvoice.internalId}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteInvoice();
            }
            setSelectedInvoice(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default InvoicesView;
