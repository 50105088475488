import moment from 'moment';
import 'moment/locale/es';
import { AlertCircle, AlertTriangle, CheckCircle, Info, Trash2 } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { NotificationEntity } from '../enums/notification-entity';
import { NotificationType } from '../enums/notification-type';
import { Notification } from '../interfaces/notification';
import { markNotificationAsRead } from '../store/notifications-slice';

moment.locale('es');

interface Props {
  notification: Notification;
  onClose: () => void;
}

const NotificationView = (props: Props) => {
  const { notification } = props;
  const dispatch = useDispatch();

  let color: string = '';
  switch (notification.type) {
    case NotificationType.Success:
      color = '#26C44B';
      break;
    case NotificationType.Warning:
      color = '#E09200';
      break;
    case NotificationType.Error:
      color = '#E02760';
      break;
    case NotificationType.Info:
      color = '#0092FD';
      break;
  }
  let linkType: string = '';
  let to: string = '';
  switch (notification.entity) {
    case NotificationEntity.CashRegister:
      linkType = 'arqueo';
      to = `/arqueo/${notification.entityId}`;
      break;
    case NotificationEntity.DeliveryNote:
      linkType = 'compra';
      to = `/compra/${notification.entityId}`;
      break;
    case NotificationEntity.Inventory:
      linkType = 'inventario';
      to = `/inventario/${notification.entityId}`;
      break;
    case NotificationEntity.Repair:
      linkType = 'reparación';
      to = `/reparacion/${notification.entityId}`;
      break;
    case NotificationEntity.Sale:
      linkType = 'venta';
      to = `/venta/${notification.entityId}`;
      break;
    case NotificationEntity.Stock:
      linkType = 'producto';
      to = `/producto/${notification.entityId}`;
      break;
    case NotificationEntity.System:
      linkType = 'novedades';
      break;
    case NotificationEntity.Transfer:
      linkType = 'traspaso';
      to = `/traspaso/${notification.entityId}`;
      break;
  }

  const markAsRead = () => {
    dispatch(markNotificationAsRead(notification.id));
  };

  return (
    <div className="notification h-100" style={{ backgroundColor: notification.type === NotificationType.Error ? '#E027601A' : '' }}>
      <div className="d-flex">
        <div className="me-2">
          {notification.type === NotificationType.Success && <CheckCircle color={color} size={18} />}
          {notification.type === NotificationType.Warning && <AlertCircle color={color} size={18} />}
          {notification.type === NotificationType.Error && <AlertTriangle color={color} size={18} />}
          {notification.type === NotificationType.Info && <Info color={color} size={18} />}
        </div>
        <div className="flex-grow-1 d-flex align-items-start flex-column content">
          <div className="title">{notification.title}</div>
          <div className="date">{moment(notification.createdAt).format('LL')}</div>
          <div className="description">{notification.description}</div>
          <div className="link mt-auto">
            <Link
              to={to}
              onClick={() => {
                markAsRead();
                props.onClose();
              }}
              style={{ color }}
            >
              Ver {linkType}
            </Link>
          </div>
        </div>
        <div className="clickable" title="Marcar notificación como leída" onClick={markAsRead}>
          <Trash2 color="#808A95" size={15} />
        </div>
      </div>
    </div>
  );
};

export default NotificationView;
