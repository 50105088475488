import { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Layers, Loader } from 'react-feather';
import { ProductUnitsInStore } from '../interfaces/product-units-in-stores';
import { api } from '../services/api';

interface Props {
  organizationId: number;
  productId: number;
}

interface Data {
  requesting: boolean;
  productUnitsInStores: ProductUnitsInStore[];
}

const ProductUnitsInOrganization = ({ organizationId, productId }: Props) => {
  const [data, setData] = useState<Data>({
    requesting: false,
    productUnitsInStores: [],
  });

  const getData = async () => {
    setData({
      requesting: true,
      productUnitsInStores: [],
    });
    try {
      const result: ProductUnitsInStore[] = await api.getProductInOrganization(organizationId, productId);
      setData({
        requesting: false,
        productUnitsInStores: result,
      });
    } catch (e) {
      setData({
        requesting: false,
        productUnitsInStores: [],
      });
    }
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      rootClose={true}
      overlay={
        <Popover>
          <Popover.Header as="h3">Unidades disponibles</Popover.Header>
          <Popover.Body>
            {data.requesting ? (
              <div className="loader">
                <Loader type="TailSpin" color="#252E3C" height={20} width={20} />
              </div>
            ) : (
              data.productUnitsInStores.map((productUnitsInStores: ProductUnitsInStore) => {
                return (
                  <div key={productUnitsInStores.storeId}>
                    <strong>{productUnitsInStores.storeName}:</strong> {productUnitsInStores.units}
                  </div>
                );
              })
            )}
          </Popover.Body>
        </Popover>
      }
    >
      <Layers
        onClick={() => {
          if (data.productUnitsInStores.length > 0) {
            return;
          }
          getData();
        }}
        size={16}
        color="#808A95"
        className="clickable"
      />
    </OverlayTrigger>
  );
};

export default ProductUnitsInOrganization;
