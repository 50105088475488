import { useDispatch } from 'react-redux';
import { setWarranty } from '../store/repair-slice';

const RepairProductWarranty = () => {
  const dispatch = useDispatch();

  return (
    <div className="repair-product-warranty h-100">
      <div className="d-flex flex-column justify-content-center h-100">
        <div onClick={() => dispatch(setWarranty(true))} className="container-option under-warranty">
          Producto en garantía
        </div>
        <div onClick={() => dispatch(setWarranty(false))} className="container-option no-warranty">
          Producto sin garantía
        </div>
      </div>
    </div>
  );
};

export default RepairProductWarranty;
