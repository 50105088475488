import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { Role } from '../enums/role';
import { Alert } from '../interfaces/alert';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { authSlice } from './auth-slice';
import { AppThunk, RootState } from './store';
import { setStoreId } from './store-slice';

export interface AlertsState {
  alerts: Alert[];
  requested: boolean;
}

const initialState: AlertsState = {
  alerts: [],
  requested: false,
};

function* getUnreadAlertsOnUserChange(): any {
  try {
    const state: RootState = yield select();
    const user: User | null = state.auth.user;
    if (!user) {
      return;
    }
    if (user.role === Role.SuperAdmin) {
      return;
    }
    if (!state.alerts.requested || user.role === Role.Admin || user.role === Role.Manager) {
      const alerts: Alert[] = yield api.getUnreadAlerts(-1);
      yield put(alertsSlice.actions.setAlerts(alerts));
    }
    yield put(alertsSlice.actions.setRequested(true));
  } catch (e) {
    yield put(alertsSlice.actions.setAlerts([]));
  }
}

function* getUnreadAlertsOnStoreChangeSeller(): any {
  try {
    const state: RootState = yield select();
    const user: User | null = state.auth.user;
    if (!user) {
      return;
    }
    if (user.role !== Role.Seller) {
      return;
    }
    const storeId: number = state.store.selectedStoreId;
    const alerts: Alert[] = yield api.getUnreadAlerts(storeId);
    yield put(alertsSlice.actions.setAlerts(alerts));
  } catch (e) {
    yield put(alertsSlice.actions.setAlerts([]));
  }
}

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlerts: (state: AlertsState, action: PayloadAction<Alert[]>) => {
      state.alerts = action.payload;
    },
    deleteAlert: (state: AlertsState, action: PayloadAction<Alert>) => {
      const alert: Alert = action.payload;
      const alerts: Alert[] = [...state.alerts];
      const index: number = alerts.findIndex((n: Alert) => n.id === alert.id);
      if (index > -1) {
        state.alerts.splice(index, 1);
      }
    },
    newAlert: (state: AlertsState, action: PayloadAction<Alert>) => {
      const alert: Alert = action.payload;
      const alerts: Alert[] = [...state.alerts];
      const index: number = alerts.findIndex((n: Alert) => n.id === alert.id);
      if (index === -1 && !alert.read) {
        alerts.unshift(alert);
      } else if (index > -1 && alert.read) {
        alerts.splice(index, 1);
      }
      state.alerts = alerts;
    },
    setRequested: (state: AlertsState, action: PayloadAction<boolean>) => {
      state.requested = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const markAlertAsRead =
  (id: number): AppThunk<AlertsState> =>
  async (dispatch): Promise<void> => {
    try {
      const alert: Alert = await api.markAlertAsRead(id);
      dispatch(alertsSlice.actions.deleteAlert(alert));
    } catch (e) {}
  };

export const { newAlert, deleteAlert } = alertsSlice.actions;

export default alertsSlice.reducer;

export const alertsSelector = (state: RootState): AlertsState => state.alerts;

export const alertsSaga = function* () {
  yield takeLatest(authSlice.actions.setUser.type, getUnreadAlertsOnUserChange);
  yield takeLatest(setStoreId.type, getUnreadAlertsOnStoreChangeSeller);
};
