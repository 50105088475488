import { useState } from 'react';
import { Mail, Phone, Smartphone } from 'react-feather';
import { useSelector } from 'react-redux';
import { TechnicalService } from '../interfaces/technical-service';
import { repairSelector } from '../store/repair-slice';

const RepairNotifyTechnicalService = () => {
  const { repair } = useSelector(repairSelector);
  const [onHoverPhone, setOnHoverPhone] = useState<boolean>(false);
  const [onHoverSms, setOnHoverSms] = useState<boolean>(false);
  const [onHoverEmail, setOnHoverEmail] = useState<boolean>(false);

  if (!repair || !repair.technicalService) {
    return null;
  }

  const technicalService: TechnicalService = repair.technicalService;

  let hasPhone: boolean = false;
  if (technicalService?.phone && technicalService.phone.length > 0) {
    hasPhone = true;
  }

  let hasEmail: boolean = false;
  if (technicalService?.email && technicalService.email.length > 0) {
    hasEmail = true;
  }

  return (
    <div className="repair-notify d-flex flex-column mt-3">
      <div className="title mb-3">Contactar al proveedor</div>
      {!hasPhone && !hasEmail ? (
        <p>No se dispone información de contacto</p>
      ) : (
        <div className="row">
          {hasPhone && (
            <div className="col-4">
              <div className="container-contact" onMouseEnter={() => setOnHoverPhone(true)} onMouseLeave={() => setOnHoverPhone(false)}>
                <div className="d-flex align-self-center"></div>
                <div className="d-flex flex-column align-items-center">
                  <Phone color={onHoverPhone ? 'white' : '#555555'} size={25} />
                  <a href={`tel:${technicalService.phone}`} className="mt-1">
                    Teléfono
                  </a>
                </div>
              </div>
            </div>
          )}
          {hasPhone && (
            <div className="col-4">
              <div className="container-contact" onMouseEnter={() => setOnHoverSms(true)} onMouseLeave={() => setOnHoverSms(false)}>
                <div className="d-flex align-self-center"></div>
                <div className="d-flex flex-column align-items-center">
                  <Smartphone color={onHoverSms ? 'white' : '#555555'} size={25} />
                  <a href={`sms:${technicalService.phone}`} className="mt-1">
                    SMS
                  </a>
                </div>
              </div>
            </div>
          )}
          {hasEmail && (
            <div className="col-4">
              <div className="container-contact" onMouseEnter={() => setOnHoverEmail(true)} onMouseLeave={() => setOnHoverEmail(false)}>
                <div className="d-flex align-self-center"></div>
                <div className="d-flex flex-column align-items-center">
                  <Mail color={onHoverEmail ? 'white' : '#555555'} size={25} />
                  <a href={`mailto:${technicalService.email}`} className="mt-1">
                    Email
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RepairNotifyTechnicalService;
