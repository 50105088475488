import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { OrganizationClient } from '../interfaces/organization-client';
import { OrganizationClientDto } from '../interfaces/organization-client.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  show: boolean;
  closeModal: (organizationClient: OrganizationClient | null) => void;
}

const initialValues: OrganizationClientDto = {
  name: '',
  cif: '',
  businessName: '',
  address: '',
  phone: '',
  email: '',
  url: '',
  postalCode: '',
  city: '',
  province: '',
  country: '',
  organizationId: -1,
};

const OrganizationClientModal = ({ show, closeModal }: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (show) {
      return;
    }
    reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const close = () => {
    closeModal(null);
  };

  const onSubmit = async (organizationClientDto: OrganizationClientDto) => {
    try {
      organizationClientDto.organizationId = organization.id;
      const newOrganizationClient: OrganizationClient = await api.createOrganizationClient(organizationClientDto);
      closeModal(newOrganizationClient);
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form organization-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Crear cliente</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.name ? 'error' : '')}>Nombre *</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.businessName })}>Razón Social *</div>
              <input type="text" {...register('businessName', { required: true })} placeholder="Razón social" className={clsx({ error: errors?.businessName })} />
              {errors.businessName && <div className="error-message">{errors.businessName.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.phone })}>Teléfono *</div>
              <input type="number" {...register('phone', { required: true })} placeholder="Teléfono" className={clsx({ error: errors?.phone })} />
              {errors.phone && <div className="error-message">{errors.phone.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.cif })}>CIF *</div>
              <input type="text" {...register('cif', { required: true })} className={clsx({ error: errors?.cif })} placeholder="CIF" />
              {errors.cif && <div className="error-message">{errors.cif.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.address })}>Dirección *</div>
              <input type="text" {...register('address', { required: true })} className={clsx({ error: errors?.address })} placeholder="Dirección" />
              {errors.address && <div className="error-message">{errors.address.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.email })}>Email *</div>
              <input type="email" {...register('email', { required: true })} className={clsx({ error: errors?.email })} placeholder="Email" />
              {errors.email && <div className="error-message">{errors.email.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.postalCode })}>Código postal *</div>
              <input type="text" {...register('postalCode', { required: true })} className={clsx({ error: errors?.postalCode })} placeholder="Código postal" />
              {errors.postalCode && <div className="error-message">{errors.postalCode.message}</div>}
            </div>
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.city })}>Ciudad *</div>
              <input type="text" {...register('city', { required: true })} className={clsx({ error: errors?.city })} placeholder="Ciudad" />
              {errors.city && <div className="error-message">{errors.city.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.province })}>Provincia *</div>
              <input type="text" {...register('province', { required: true })} className={clsx({ error: errors?.province })} placeholder="Provincia" />
              {errors.province && <div className="error-message">{errors.province.message}</div>}
            </div>
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.country })}>País *</div>
              <input type="text" {...register('country', { required: true })} className={clsx({ error: errors?.country })} placeholder="País" />
              {errors.country && <div className="error-message">{errors.country.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.url })}>Url</div>
              <input type="text" {...register('url')} className={clsx({ error: errors?.url })} placeholder="Url" />
              {errors.url && <div className="error-message">{errors.url.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default OrganizationClientModal;
