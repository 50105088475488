import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import debounce from 'debounce-promise';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Role } from '../enums/role';
import { StoreType } from '../enums/store-type';
import { Brand } from '../interfaces/brand';
import { Category } from '../interfaces/category';
import { Collection } from '../interfaces/collection';
import { Family } from '../interfaces/family';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { Product } from '../interfaces/product';
import { ProductStoreStock } from '../interfaces/product-store-stock';
import { ProductDto } from '../interfaces/product.dto';
import { Stock } from '../interfaces/stock';
import { StockDto } from '../interfaces/stock.dto';
import { Store } from '../interfaces/store';
import { SubCategory } from '../interfaces/subcategory';
import { SubFamily } from '../interfaces/subfamily';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { sleep } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { styleMultiSelectInModal } from '../styless/multiselect-style';
import ProductOption from './product-option';

const promiseProductOptions = async (inputValue: string) => {
  if (inputValue == null || inputValue === '') {
    return [];
  }
  const products: Product[] = await api.getProducts({ query: inputValue });
  const options: any[] = [
    {
      custom: true,
      value: inputValue,
      label: inputValue,
    },
  ];
  products.forEach((product: Product) => {
    options.push({
      value: product.id,
      label: product.sku,
      product,
    });
  });
  return options;
};

const debouncedLoadProductOptions = debounce((inputValue: string) => promiseProductOptions(inputValue), 1000, {
  leading: false,
});

interface Props {
  show: boolean;
  closeModal: (stock: Stock | null) => void;
}

export interface CustomStockDto {
  units: number | null;
  retailPrice: number | null;
  costPrice: number | null;
  pvp: number | null;
  discount: number | null;
  productId: number | null;
  productDto: {
    name: string;
    description: string | null;
    sku: {
      value: string;
      label: string;
      custom?: boolean;
      __isNew__?: boolean;
    } | null;
    ean: string | null;
    enabled: boolean;
    brand: { label: string; value: number } | null;
    collectionId: number | null;
    categoryIds: { value: number; label: string }[];
    subCategoryIds: { value: number; label: string }[];
    familyIds: { value: number; label: string }[];
    subFamilyIds: { value: number; label: string }[];
  };
  weight: number | null;
}

const defaultValues: CustomStockDto = {
  units: null,
  retailPrice: null,
  costPrice: null,
  pvp: null,
  discount: null,
  productId: null,
  productDto: {
    name: '',
    description: null,
    sku: null,
    ean: null,
    enabled: true,
    brand: null,
    collectionId: -1,
    categoryIds: [],
    subCategoryIds: [],
    familyIds: [],
    subFamilyIds: [],
  },
  weight: null,
};

const CreateStockModal = ({ show, closeModal }: Props) => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const store: Store | null = useAppSelector((state: RootState) => state.store.store);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [families, setfamilies] = useState<Family[]>([]);
  const [subFamilies, setSubFamilies] = useState<SubFamily[]>([]);
  const skuSelectInputRef = useRef<any>(null);
  const skuRef = useRef<string | null>(null);
  const [productStoreStock, setProductStoreStock] = useState<ProductStoreStock | null>(null);
  const imageInputFileRef = useRef<any>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [creatingProduct, setCreatingProduct] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CustomStockDto>({
    mode: 'onSubmit',
    defaultValues,
  });
  const availableCollections: Collection[] = useMemo(() => {
    if (!collections || collections.length === 0 || watch('productDto.brand') === null) {
      return [];
    }
    return collections.filter((collection: Collection) => collection.brandId === watch('productDto.brand.value'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections, watch('productDto.brand')]);
  const filteredSubCategories: SubCategory[] = useMemo(() => {
    if (!subCategories || subCategories.length === 0 || watch('productDto.categoryIds').length === 0) {
      return [];
    }
    const categoryIds: number[] = watch('productDto.categoryIds').map((categoryId: { value: number; label: string }) => categoryId.value);
    return subCategories.filter((subCategory: SubCategory) => categoryIds.includes(subCategory.categoryId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategories, watch('productDto.categoryIds')]);
  const filteredSubFamilies: SubFamily[] = useMemo(() => {
    if (!subFamilies || subFamilies.length === 0 || watch('productDto.familyIds').length === 0) {
      return [];
    }
    const familyIds: number[] = watch('productDto.familyIds').map((familyId: { value: number; label: string }) => familyId.value);
    return subFamilies.filter((subFamily: SubFamily) => familyIds.includes(subFamily.familyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subFamilies, watch('productDto.familyIds')]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isNewProduct: boolean = useMemo(() => watch('productId') === null, [watch('productId')]);
  const productIsInStock: boolean = useMemo(() => productStoreStock !== null, [productStoreStock]);
  const skuIntroduced: boolean = useMemo(() => {
    const sku: { label: string; value: number | string } | null = watch('productDto.sku');
    if (sku?.value) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('productDto.sku')]);

  useEffect(() => {
    if (show) {
      getBrands();
      getCollections();
      getCategories();
      getSubCategories();
      if (user.role === Role.SuperAdmin) {
        getFamilies();
        getSubfamilies();
      }
      return;
    }
    reset();
    setFiles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, user]);

  useEffect(() => {
    if (!watch('productId')) {
      setProductStoreStock(null);
      return;
    }
    getProductStoreStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('productId')]);

  useEffect(() => {
    if (skuSelectInputRef.current) {
      skuSelectInputRef.current.focus();
    }
  }, [errors?.productDto?.sku]);

  const getBrands = async () => {
    try {
      const brands: Brand[] = await api.getBrands();
      setBrands(brands);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getCollections = async () => {
    try {
      const collections: Collection[] = await api.getCollections();
      setCollections(collections);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getCategories = async () => {
    try {
      const categories: Category[] = await api.getCategories(organization.id);
      setCategories(categories);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getSubCategories = async () => {
    try {
      const subCategories: SubCategory[] = await api.getSubCategories(organization.id);
      setSubCategories(subCategories);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getFamilies = async () => {
    try {
      const families: Family[] = await api.getFamilies();
      setfamilies(families);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getSubfamilies = async () => {
    try {
      const subFamilies: SubFamily[] = await api.getSubFamilies();
      setSubFamilies(subFamilies);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const close = () => {
    closeModal(null);
  };

  const getProductStoreStock = async () => {
    try {
      const productStoreStocks: ProductStoreStock[] = await api.getProductStoresStock(watch('productId')!, organization.id);
      const productStoreStock: ProductStoreStock | null = productStoreStocks.find((pss: ProductStoreStock) => pss.storeId === store!.id) || null;
      setProductStoreStock(productStoreStock);
      if (productStoreStock) {
        myToastr.error('El producto ya está en el stock de la tienda');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const onSubmit = async (customStockDto: CustomStockDto) => {
    if (creatingProduct) {
      return;
    }
    setCreatingProduct(true);
    try {
      const toastrId: JQuery<HTMLElement> = myToastr.info('Añadiendo producto...');
      const productDto: ProductDto = {
        name: customStockDto.productDto.name,
        description: customStockDto.productDto.description,
        sku: customStockDto.productDto?.sku ? customStockDto.productDto.sku.value : '',
        ean: customStockDto.productDto?.ean ? customStockDto.productDto.ean : null,
        enabled: true,
        brandId: customStockDto.productDto.brand!.value,
        collectionId: customStockDto.productDto?.collectionId && customStockDto.productDto.collectionId > 0 ? customStockDto.productDto.collectionId : null,
        supplierIds: [],
        categoryIds: customStockDto.productDto.categoryIds.map((categoryId: { value: number; label: string }) => categoryId.value),
        subCategoryIds: customStockDto.productDto.subCategoryIds.map((subCategoryId: { value: number; label: string }) => subCategoryId.value),
        familyIds: customStockDto.productDto.familyIds.map((familyId: { value: number; label: string }) => familyId.value),
        subFamilyIds: customStockDto.productDto.subFamilyIds.map((subFamilyId: { value: number; label: string }) => subFamilyId.value),
        organizationId: organization.id,
      };
      const stockDto: StockDto = {
        units: customStockDto.units!,
        retailPrice: customStockDto.retailPrice ? customStockDto.retailPrice : null,
        costPrice: customStockDto.costPrice!,
        pvp: customStockDto.pvp!,
        discount: customStockDto.discount ? customStockDto.discount : null,
        storeId: store!.id,
        productId: customStockDto.productId !== null && customStockDto.productId > 0 ? customStockDto.productId : null,
        productDto: customStockDto.productId !== null && customStockDto.productId > 0 ? null : productDto,
        weight: customStockDto.weight ? customStockDto.weight : null,
      };
      const newStock: Stock = await api.createStock(stockDto);
      if (files.length > 0) {
        await api.uploadProductImages(newStock.productId, files);
        setFiles([]);
      }
      myToastr.clear(toastrId);
      await sleep(1000);
      myToastr.success('Producto añadido correctamente');
      closeModal(newStock);
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    } finally {
      setCreatingProduct(false);
    }
  };

  return (
    <Modal className="vercomi-modal my-form create-stock-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Añadir producto a {store?.name}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: (errors?.productDto as any)?.sku })}>SKU</div>
              <Controller
                control={control}
                name="productDto.sku"
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <React.Fragment>
                      <AsyncCreatableSelect
                        ref={skuSelectInputRef}
                        placeholder="SKU"
                        defaultOptions
                        noOptionsMessage={({ inputValue }: any) => (!inputValue ? null : <div className="enter-text my-2">No se han encontrado productos</div>)}
                        onChange={(value) => {
                          setProductStoreStock(null);
                          if (!value) {
                            setValue('productId', null);
                            setValue('productDto.sku', null);
                            setValue('productDto.name', '');
                            setValue('productDto.ean', null);
                            setValue('productDto.brand', null);
                            setValue('productDto.collectionId', -1);
                          } else if (value?.custom || value?.__isNew__) {
                            setValue('productId', null);
                          } else if ((value as any).product?.id) {
                            setValue('productId', (value as any).product.id);
                            setValue('productDto.sku', { value: (value as any).product.sku, label: (value as any).product.sku, __isNew__: false });
                            setValue('productDto.name', (value as any).product.name);
                            setValue('productDto.ean', (value as any).product.ean);
                            setValue('productDto.brand', (value as any).product?.brand ? { label: (value as any).product.brand.name, value: (value as any).product.brand.id } : null);
                            setValue('productDto.collectionId', (value as any).product.collectionId || -1);
                          }
                          onChange(value);
                          if (!value) {
                            reset(defaultValues);
                          }
                        }}
                        value={value}
                        isClearable
                        menuPlacement="bottom"
                        loadingMessage={() => 'Buscando productos...'}
                        onInputChange={(inputValue: string) => (skuRef.current = inputValue)}
                        onKeyDown={async (e) => {
                          if (e.key === 'Enter' || e.key === 'Tab') {
                            e.stopPropagation();
                            const products: Product[] = await api.getProducts({ sku: skuRef.current! });
                            if (products.length === 1) {
                              const product: Product = products[0];
                              setValue('productId', product.id);
                              setValue('productDto.sku', { value: product.sku, label: product.sku, __isNew__: false });
                              setValue('productDto.name', product.name);
                              setValue('productDto.ean', product.ean);
                              setValue('productDto.brand', { label: product.brand.name, value: product.brand.id });
                              setValue('productDto.collectionId', product.collectionId || -1);
                            } else {
                              setValue('productDto.sku', { value: skuRef.current!, label: skuRef.current!, __isNew__: false });
                            }
                            skuRef.current = null;
                          }
                        }}
                        loadOptions={debouncedLoadProductOptions}
                        components={{
                          DropdownIndicator: null,
                          Option: (props: any) => (
                            <ProductOption
                              {...props}
                              onCreate={(sku: any) => {
                                setValue('productId', null);
                                setValue('productDto.sku', { value: sku, label: sku, __isNew__: true });
                              }}
                            />
                          ),
                        }}
                        styles={styleMultiSelectInModal(!!error)}
                        menuPortalTarget={document.body}
                        autoFocus={true}
                      />
                      {(errors?.productDto as any)?.sku && <div className="error-message">{(errors.productDto as any).sku.message || 'Campo obligatorio'}</div>}
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: (errors?.productDto as any)?.name })}>Nombre</div>
              <input
                disabled={!skuIntroduced || productIsInStock || !isNewProduct}
                type="text"
                {...register('productDto.name', { required: isNewProduct })}
                className={clsx({ error: (errors?.productDto as any)?.name })}
                placeholder="Nombre"
              />
              {(errors?.productDto as any)?.name && <div className="error-message">{(errors.productDto as any).name.message || 'Campo obligatorio'}</div>}
            </div>
            {skuIntroduced && isNewProduct && (
              <div className="col">
                <div className={clsx('input-name', { error: (errors?.productDto as any)?.name })}>Imágenes</div>
                {files.length > 0 ? (
                  <div className="d-flex flex-row">
                    {files.map((file: File, index: number) => (
                      <div key={index} className="position-relative container-image d-flex align-items-center justify-content-center me-2 mb-2">
                        <img className="product-image" src={URL.createObjectURL(file)} alt="" />
                        <span
                          className="delete-image"
                          onClick={() => {
                            const newFiles = [...files];
                            newFiles.splice(index, 1);
                            setFiles(newFiles);
                          }}
                        >
                          <X color="white" size="15" />
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <React.Fragment>
                    <button type="button" className="open-file" onClick={() => imageInputFileRef.current.click()}>
                      Seleccionar imágenes
                    </button>
                    <input
                      ref={imageInputFileRef}
                      multiple
                      type="file"
                      accept="image/*"
                      onClick={(event: any) => {
                        event.target.value = null;
                      }}
                      onChange={(e) => {
                        if (e.target.files === null || e.target.files.length === 0) {
                          return;
                        }
                        if (e.target.files.length > 4) {
                          myToastr.error('No puedes subir más de 4 imágenes');
                          return;
                        }
                        setFiles(Array.from(e.target.files));
                      }}
                      style={{ display: 'none' }}
                    />
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: (errors?.productDto as any)?.ean })}>EAN</div>
              <input
                disabled={!skuIntroduced || productIsInStock || !isNewProduct}
                type="text"
                {...register('productDto.ean')}
                className={clsx({ error: (errors?.productDto as any)?.ean })}
                placeholder="EAN"
              />
              {(errors?.productDto as any)?.ean && <div className="error-message">{(errors.productDto as any).ean.message || 'Campo obligatorio'}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.weight })}>Peso</div>
              <input
                disabled={!skuIntroduced || productIsInStock}
                type="number"
                {...register('weight', { min: 0, valueAsNumber: true })}
                className={clsx({ error: errors?.weight })}
                min={0}
                placeholder="Peso (gr)"
              />
              {errors?.weight && <div className="error-message">{errors.weight.message || 'Campo obligatorio'}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.units })}>Unidades</div>
              <input
                disabled={!skuIntroduced || productIsInStock}
                type="number"
                {...register('units', { required: true, min: 1, valueAsNumber: true })}
                className={clsx({ error: errors?.units })}
                min={0}
                placeholder="Unidades"
              />
              {errors?.units && <div className="error-message">{errors.units.message || 'Campo obligatorio'}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.costPrice })}>Coste Ud. €</div>
              <input
                disabled={!skuIntroduced || productIsInStock}
                type="number"
                {...register('costPrice', { required: true, min: 0.01, valueAsNumber: true })}
                className={clsx({ error: errors?.costPrice })}
                min={0}
                step={0.01}
                placeholder="Coste Ud. €"
              />
              {errors?.costPrice && <div className="error-message">{errors.costPrice.message || 'Campo obligatorio'}</div>}
            </div>
          </div>
          <div className="row my-3">
            {store?.type === StoreType.B2B && (
              <div className="col-6">
                <div className={clsx('input-name', { error: errors?.pvp })}>PVM Ud. €</div>
                <input
                  disabled={!skuIntroduced || productIsInStock}
                  type="number"
                  {...register('retailPrice', { required: true, min: 0.01, valueAsNumber: true })}
                  className={clsx({ error: errors?.pvp })}
                  min={0}
                  step={0.01}
                  placeholder="PVM Ud. €"
                />
                {errors?.pvp && <div className="error-message">{errors.pvp.message || 'Campo obligatorio'}</div>}
              </div>
            )}
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.pvp })}>PVP Ud. €</div>
              <input
                disabled={!skuIntroduced || productIsInStock}
                type="number"
                {...register('pvp', { required: true, min: 0.01, valueAsNumber: true })}
                className={clsx({ error: errors?.pvp })}
                min={0}
                step={0.01}
                placeholder="PVP Ud. €"
              />
              {errors?.pvp && <div className="error-message">{errors.pvp.message || 'Campo obligatorio'}</div>}
            </div>
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.discount, 'mt-3': store?.type === StoreType.B2B })}>Descuento %</div>
              <input
                type="number"
                disabled={!skuIntroduced || productIsInStock}
                {...register('discount', { required: false, min: 0, max: 100, valueAsNumber: true })}
                className={clsx({ error: errors?.discount })}
                min={0}
                max={100}
                placeholder="Descuento %"
              />
              {errors?.discount && <div className="error-message">{errors.discount.message || 'Campo obligatorio'}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: (errors?.productDto as any)?.brand })}>Marca</div>
              <Controller
                control={control}
                name="productDto.brand"
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    isDisabled={!skuIntroduced || !isNewProduct || productIsInStock}
                    options={brands.map((brand: Brand) => ({
                      value: brand.id,
                      label: brand.name,
                    }))}
                    placeholder="Selecciona una marca..."
                    value={value}
                    onChange={(newBrand: any) => {
                      onChange(newBrand);
                      setValue('productDto.collectionId', -1);
                    }}
                    styles={styleMultiSelectInModal(!!error)}
                    menuPortalTarget={document.body}
                    filterOption={(option, inputValue: string) => (!inputValue || inputValue.length < 2 ? false : option.label.toLowerCase().startsWith(inputValue.toLowerCase()))}
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue ? <div>Introduce la marca</div> : inputValue.length < 2 ? <div>Escribe al menos 2 caracteres</div> : <div>No hay coincidencias</div>
                    }
                  />
                )}
              />
              {(errors?.productDto as any)?.brand && <div className="error-message">{(errors.productDto as any).brand.message || 'Campo obligatorio'}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: (errors?.productDto as any)?.collectionId })}>Colección</div>
              <select
                {...register('productDto.collectionId', { required: false, valueAsNumber: true })}
                className={clsx({ error: (errors?.productDto as any)?.collectionId, 'cursor-not-allowed': watch('productDto.brand') === null })}
                disabled={!skuIntroduced || watch('productDto.brand') === null || !isNewProduct || productIsInStock}
              >
                {watch('productDto.brand') === null ? (
                  <option value={-1}>Selecciona una marca</option>
                ) : availableCollections.length === 0 ? (
                  <option value={-1}>No hay colecciones disponibles</option>
                ) : (
                  <React.Fragment>
                    <option value={-1}>Selecciona una colección</option>
                    {availableCollections.map((collection: Collection) => (
                      <option key={collection.id} value={collection.id}>
                        {collection.name}
                      </option>
                    ))}
                  </React.Fragment>
                )}
              </select>
              {(errors?.productDto as any)?.collectionId && <div className="error-message">{(errors.productDto as any).collectionId.message || 'Campo obligatorio'}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className="input-name">Categorías</div>
              <Controller
                control={control}
                name="productDto.categoryIds"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    isDisabled={!skuIntroduced || productIsInStock}
                    isMulti
                    options={categories.map((category: Category) => ({
                      value: category.id,
                      label: category.name,
                    }))}
                    value={value}
                    styles={styleMultiSelectInModal(!!error)}
                    menuPortalTarget={document.body}
                    placeholder="Selecciona categorías..."
                    onChange={(newCategories: any) => {
                      onChange(newCategories);
                      const newSubCategories: { value: number; label: string }[] = watch('productDto.subCategoryIds').filter((e: { value: number; label: string }) => {
                        const subCategory: SubCategory | undefined = subCategories.find((sc: SubCategory) => sc.id === e.value);
                        if (!subCategory) {
                          return false;
                        }
                        const index: number = newCategories.findIndex((category: { value: number; label: string }) => category.value === subCategory.categoryId);
                        if (index === -1) {
                          return false;
                        }
                        return true;
                      });
                      setValue('productDto.subCategoryIds', newSubCategories);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className="input-name">Subcategorías</div>
              <Controller
                control={control}
                name="productDto.subCategoryIds"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    isDisabled={!skuIntroduced || productIsInStock}
                    isMulti
                    options={filteredSubCategories.map((subCategory: SubCategory) => ({
                      value: subCategory.id,
                      label: subCategory.name,
                    }))}
                    value={value}
                    styles={styleMultiSelectInModal(!!error)}
                    menuPortalTarget={document.body}
                    placeholder="Selecciona subcategorías..."
                    noOptionsMessage={() => 'No hay subcategorías disponibles'}
                    onChange={(newSubcategories: any) => {
                      onChange(newSubcategories);
                    }}
                  />
                )}
              />
            </div>
          </div>
          {user.role === Role.SuperAdmin && (
            <React.Fragment>
              <div className="row my-3">
                <div className="col">
                  <div className="input-name">Familias</div>
                  <Controller
                    control={control}
                    name="productDto.familyIds"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Select
                        isDisabled={!skuIntroduced || productIsInStock}
                        isMulti
                        options={families.map((family: Family) => ({
                          value: family.id,
                          label: family.name,
                        }))}
                        value={value}
                        styles={styleMultiSelectInModal(!!error)}
                        menuPortalTarget={document.body}
                        placeholder="Selecciona familias..."
                        noOptionsMessage={() => 'No hay familias disponibles'}
                        onChange={(newFamilies: any) => {
                          onChange(newFamilies);
                          const newSubFamilies: { value: number; label: string }[] = watch('productDto.subFamilyIds').filter((e: { value: number; label: string }) => {
                            const subFamily: SubFamily | undefined = subFamilies.find((sf: SubFamily) => sf.id === e.value);
                            if (!subFamily) {
                              return false;
                            }
                            const index: number = newFamilies.findIndex((family: { value: number; label: string }) => family.value === subFamily.familyId);
                            if (index === -1) {
                              return false;
                            }
                            return true;
                          });
                          setValue('productDto.subFamilyIds', newSubFamilies);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col">
                  <div className="input-name">Subfamilias</div>
                  <Controller
                    control={control}
                    name="productDto.subFamilyIds"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Select
                        isDisabled={!skuIntroduced || productIsInStock}
                        isMulti
                        options={filteredSubFamilies.map((subFamily: SubFamily) => ({
                          value: subFamily.id,
                          label: subFamily.name,
                        }))}
                        value={value}
                        styles={styleMultiSelectInModal(!!error)}
                        menuPortalTarget={document.body}
                        noOptionsMessage={() => 'No hay subfamilias disponibles'}
                        onChange={(newSubFamilies: any) => {
                          onChange(newSubFamilies);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <button disabled={productIsInStock || creatingProduct} className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default CreateStockModal;
