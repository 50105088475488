import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, Download, Edit, Image, Upload } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import Loader from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import ConfirmModal from '../components/confirm-modal';
import ImportCsvModal from '../components/import-csv-modal';
import ImportStockModal from '../components/import-stock-modal';
import Paginator from '../components/paginator';
import UpdateProductsModal from '../components/update-products-modal';
import UploadProductImageModal from '../components/upload-product-image-modal';
import { Entity } from '../enums/entity';
import { Order } from '../enums/order';
import { Role } from '../enums/role';
import { Brand } from '../interfaces/brand';
import { Collection } from '../interfaces/collection';
import { Family } from '../interfaces/family';
import { GetPaginatedProducts } from '../interfaces/get-paginated-products';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { PaginatedDto } from '../interfaces/paginated.dto';
import { Product } from '../interfaces/product';
import { ProductImage } from '../interfaces/product-image';
import { RawImage } from '../interfaces/raw-image';
import { Stock } from '../interfaces/stock';
import { SubFamily } from '../interfaces/subfamily';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const debounceGetData = debounce(async (getPaginatedProducts: GetPaginatedProducts, cb: (result: PaginatedDto<Product>) => void) => {
  const result: PaginatedDto<Product> = await api.getPaginatedProducts(getPaginatedProducts);
  cb(result);
}, 500);

const customStyles = {
  container: (provided: any, state: any) => {
    return {
      ...provided,
      flex: 1,
      fontFamily: 'Inter-Regular',
      fontSize: '14px',
      borderColor: '#a0aec1',
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
  }),
  control: (provided: any, state: any) => {
    return {
      ...provided,
    };
  },
  singleValue: (provided: any, state: any) => {
    return { ...provided };
  },
};

const ProductsView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const navigate = useNavigate();
  const [paginatedDto, setPaginatedDto] = useState<PaginatedDto<Product> | null>(null);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [showUploadProductModal, setShowUploadProductModal] = useState<boolean>(false);
  const [showImportStockModal, setShowImportStockModal] = useState<boolean>(false);
  const [showUpdateProductsModal, setUpdateProductsModal] = useState<boolean>(false);
  const [getPaginatedProducts, setGetPaginatedProducts] = useState<GetPaginatedProducts | null>(null);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [families, setFamilies] = useState<Family[]>([]);
  const [subFamilies, setSubFamilies] = useState<SubFamily[]>([]);
  const [showImportProductsCsvModal, setShowImportProductsCsvModal] = useState<boolean>(false);
  const [showImportStockCsvModal, setShowImportStockCsvModal] = useState<boolean>(false);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [showExportProductsModal, setShowExportProductsModal] = useState<boolean>(false);
  const [exportingProducts, setExportingProducts] = useState<boolean>(false);
  const [enabledFilter, setEnabledFilter] = useState<{ label: string; value: boolean | null } | null>(null);
  const [editableFilter, setEditableFilter] = useState<{ label: string; value: boolean | null } | null>(null);

  const selectedBrands: { label: string; value: number }[] = useMemo(() => {
    if (!getPaginatedProducts?.brandIds) {
      return [];
    }
    return brands.filter((brand: Brand) => getPaginatedProducts.brandIds!.includes(brand.id)).map((brand: Brand) => ({ label: brand.name, value: brand.id }));
  }, [brands, getPaginatedProducts?.brandIds]);
  const selectedCollections: { label: string; value: number }[] = useMemo(() => {
    if (!getPaginatedProducts?.collectionIds) {
      return [];
    }
    return collections
      .filter((collection: Collection) => getPaginatedProducts.collectionIds!.includes(collection.id))
      .map((collection: Collection) => ({ label: collection.name, value: collection.id }));
  }, [collections, getPaginatedProducts?.collectionIds]);
  const selectedFamilies: { label: string; value: number }[] = useMemo(() => {
    if (!getPaginatedProducts?.familyIds) {
      return [];
    }
    return families.filter((family: Family) => getPaginatedProducts.familyIds!.includes(family.id)).map((family: Family) => ({ label: family.name, value: family.id }));
  }, [families, getPaginatedProducts?.familyIds]);
  const selectedSubFamilies: { label: string; value: number }[] = useMemo(() => {
    if (!getPaginatedProducts?.subFamilyIds) {
      return [];
    }
    return subFamilies.filter((subFamily: SubFamily) => getPaginatedProducts.subFamilyIds!.includes(subFamily.id)).map((subFamily: SubFamily) => ({ label: subFamily.name, value: subFamily.id }));
  }, [subFamilies, getPaginatedProducts?.subFamilyIds]);

  const refreshData = () => {
    setRequesting(true);
    debounceGetData(getPaginatedProducts!, (paginatedDto: PaginatedDto<Product>) => {
      for (const result of paginatedDto.results) {
        result.images.sort((a: ProductImage, b: ProductImage) => {
          if (a.isMain) {
            return -1;
          }
          if (b.isMain) {
            return 1;
          }
          return 0;
        });
      }
      setPaginatedDto(paginatedDto);
      setRequesting(false);
    });
  };

  useEffect(() => {
    let gpse: GetPaginatedProducts = {
      orderField: 'product.sku',
      order: Order.Asc,
      limit: Constants.LIMIT_RESULTS,
      page: 1,
      query: '',
      brandIds: [],
      collectionIds: [],
      familyIds: [],
      subFamilyIds: [],
      enabled: null,
      editable: null,
      images: null,
    };
    const value: string | null = sessionStorage.getItem('search-products');
    if (value) {
      try {
        gpse = JSON.parse(value);
        gpse.brandIds = gpse?.brandIds
          ? (gpse.brandIds as any)
              .split(',')
              .filter((e: string) => !isNaN(e as any))
              .map((e: string) => parseInt(e, 10))
          : [];
        gpse.collectionIds = gpse?.collectionIds
          ? (gpse.collectionIds as any)
              .split(',')
              .filter((e: string) => !isNaN(e as any))
              .map((e: string) => parseInt(e, 10))
          : [];
        gpse.familyIds = gpse?.familyIds
          ? (gpse.familyIds as any)
              .split(',')
              .filter((e: string) => !isNaN(e as any))
              .map((e: string) => parseInt(e, 10))
          : [];
        gpse.subFamilyIds = gpse?.subFamilyIds
          ? (gpse.subFamilyIds as any)
              .split(',')
              .filter((e: string) => !isNaN(e as any))
              .map((e: string) => parseInt(e, 10))
          : [];
      } catch (e) {
        sessionStorage.removeItem('search-products');
      }
    }
    setGetPaginatedProducts(gpse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const b: Brand[] = await api.getBrands();
        setBrands(b);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    };
    const getCollections = async () => {
      try {
        const c: Collection[] = await api.getCollections();
        setCollections(c);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    };
    const getFamilies = async () => {
      try {
        const f: Family[] = await api.getFamilies();
        setFamilies(f);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    };
    const getSubFamilies = async () => {
      try {
        const sf: SubFamily[] = await api.getSubFamilies();
        setSubFamilies(sf);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    };
    getBrands();
    getCollections();
    getFamilies();
    getSubFamilies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!getPaginatedProducts || getPaginatedProducts.enabled === null) {
      return;
    }
    setEnabledFilter({ label: getPaginatedProducts.enabled ? 'Sí' : 'No', value: getPaginatedProducts.enabled });
  }, [getPaginatedProducts]);

  useEffect(() => {
    if (!getPaginatedProducts || getPaginatedProducts.editable === null) {
      return;
    }
    setEditableFilter({ label: getPaginatedProducts.editable ? 'Sí' : 'No', value: getPaginatedProducts.editable });
  }, [getPaginatedProducts]);

  useEffect(() => {
    if (getPaginatedProducts === null) {
      return;
    }
    sessionStorage.setItem('search-products', JSON.stringify({ ...getPaginatedProducts, page: 1 }));
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPaginatedProducts]);

  const onCloseUploadProductImageModal = (productImage: ProductImage[] | RawImage | null) => {
    if (productImage !== null) {
      refreshData();
    }
    setSelectedProduct(null);
    setShowUploadProductModal(false);
  };

  const generateCsv = async () => {
    if (exportingProducts) {
      return;
    }
    setExportingProducts(true);
    try {
      const id: JQuery<HTMLElement> = myToastr.info('Exportando productos. Espere por favor.');
      const buffer: Buffer = await api.exportProducts(getPaginatedProducts!);
      const blob: Blob = new Blob([buffer], { type: 'text/csv' });
      const url: string = window.URL.createObjectURL(blob);
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.download = 'productos.csv';
      link.click();
      link.remove();
      myToastr.remove(id);
    } catch (e) {
      myToastr.error('Hubo un error exportando los productos');
    } finally {
      setExportingProducts(false);
    }
  };

  const onCloseImportStockModal = (s: Stock[] | null) => {
    if (s !== null && s.length > 0) {
      refreshData();
    }
    setShowImportStockModal(false);
  };

  const onCloseUpdateProductsModal = (products: Product[] | null) => {
    if (products !== null && products.length > 0) {
      refreshData();
    }
    setUpdateProductsModal(false);
  };

  const onCloseImportStockCsvModal = (result: boolean) => {
    if (result) {
      setGetPaginatedProducts({ ...getPaginatedProducts!, page: 1 });
    }
    setShowImportStockCsvModal(false);
  };

  const onCloseImportProductsCsvModal = (result: boolean) => {
    if (result) {
      navigate(0);
    }
    setShowImportProductsCsvModal(false);
  };

  const onCloseExportsProductsModal = async (result: boolean) => {
    if (!result) {
      setShowExportProductsModal(false);
      return;
    }
    await generateCsv();
    setShowExportProductsModal(false);
  };

  if (getPaginatedProducts === null) {
    return null;
  }

  return (
    <div className="products-view p-4">
      <div className="d-flex flex-row align-items-center mb-2">
        <div className="d-flex align-items-center flex-grow-1">
          <h1 className="me-4">Productos</h1>
        </div>
        {user.role === Role.SuperAdmin && (
          <div className="d-flex align-items-center export-csv cursor-pointer" onClick={() => setShowImportProductsCsvModal(true)} title="Importar catálogo de productos">
            <Upload className="me-1" size={14} /> Importar Catálogo
          </div>
        )}
        <div className="d-flex align-items-center export-csv clickable" onClick={() => setShowExportProductsModal(true)} title="Exportar productos">
          <Download className="me-1" size={14} />
          <span>Exportar</span>
        </div>
        {user.role === Role.SuperAdmin && (
          <div className="d-flex align-items-center export-csv cursor-pointer" onClick={() => setUpdateProductsModal(true)}>
            <Edit className="me-1" size={14} /> Actualizar productos
          </div>
        )}
      </div>
      <div className="filters-bar d-flex align-items-center my-3">
        <div className="me-1 sup-label" style={{ width: '180px' }}>
          <span className="">Buscar</span>
          <input
            className="input-filter"
            type="text"
            placeholder="Buscar producto..."
            value={getPaginatedProducts.query}
            onChange={(e: any) => setGetPaginatedProducts({ ...getPaginatedProducts, query: e.target.value || '', page: 1 })}
            style={{ width: '100%' }}
          />
        </div>
        <div className="ms-1 sup-label" style={{ width: '15%' }}>
          <span className="">Marcas</span>
          <Select
            isClearable
            placeholder="Marcas..."
            name="brandIds"
            options={brands.map((brand: Brand) => ({
              label: brand.name,
              value: brand.id,
            }))}
            value={selectedBrands}
            isMulti
            onChange={(e: any) => setGetPaginatedProducts({ ...getPaginatedProducts, brandIds: e.map((b: { label: string; value: number }) => b.value) })}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <div className="ms-1 sup-label" style={{ width: '15%' }}>
          <span className="">Colecciones</span>
          <Select
            isClearable
            placeholder="Colecciones..."
            name="collectionIds"
            options={collections.map((collection: Collection) => ({
              label: collection.name,
              value: collection.id,
            }))}
            value={selectedCollections}
            isMulti
            onChange={(e: any) => setGetPaginatedProducts({ ...getPaginatedProducts, collectionIds: e.map((b: { label: string; value: number }) => b.value) })}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <div className="ms-1 sup-label" style={{ width: '15%' }}>
          <span className="">Familias</span>
          <Select
            isClearable
            placeholder="Familias..."
            name="familyIds"
            options={families.map((family: Family) => ({
              label: family.name,
              value: family.id,
            }))}
            value={selectedFamilies}
            isMulti
            onChange={(e: any) => setGetPaginatedProducts({ ...getPaginatedProducts, familyIds: e.map((b: { label: string; value: number }) => b.value) })}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <div className="ms-1 sup-label" style={{ width: '15%' }}>
          <span className="">Subfamilias</span>
          <Select
            isClearable
            placeholder="Subfamilias..."
            name="subFamilyIds"
            options={subFamilies.map((subFamily: SubFamily) => ({
              label: subFamily.name,
              value: subFamily.id,
            }))}
            value={selectedSubFamilies}
            isMulti
            onChange={(e: any) => setGetPaginatedProducts({ ...getPaginatedProducts, subFamilyIds: e.map((b: { label: string; value: number }) => b.value) })}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <div className="mx-1 sup-label" style={{ width: '135px' }}>
          <span className="">Activo</span>
          <Select
            isClearable
            placeholder="Activo..."
            name="enabled"
            options={[
              {
                label: 'Todos',
                value: null,
              },
              {
                label: 'Sí',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            value={enabledFilter}
            onChange={(e: any) => {
              setEnabledFilter(e);
              setGetPaginatedProducts({ ...getPaginatedProducts, enabled: e !== null && e.hasOwnProperty('value') ? e.value : null });
            }}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <div className="mx-1 sup-label" style={{ width: '135px' }}>
          <span className="">Editable</span>
          <Select
            isClearable
            placeholder="Editable..."
            name="editable"
            options={[
              {
                label: 'Todos',
                value: null,
              },
              {
                label: 'Sí',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            value={editableFilter}
            onChange={(e: any) => {
              setEditableFilter(e);
              setGetPaginatedProducts({ ...getPaginatedProducts, editable: e !== null && e.hasOwnProperty('value') ? e.value : null });
            }}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <div className="mx-1 sup-label" style={{ width: '135px' }}>
          <span className="">Imágenes</span>
          <Select
            isClearable
            placeholder="Imágenes..."
            name="images"
            options={[
              {
                label: 'Todos',
                value: null,
              },
              {
                label: 'Sí',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            value={editableFilter}
            onChange={(e: any) => {
              setEditableFilter(e);
              setGetPaginatedProducts({ ...getPaginatedProducts, images: e !== null && e.hasOwnProperty('value') ? e.value : null });
            }}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <button
          className="clear-filters ms-1"
          disabled={requesting}
          onClick={() => {
            setGetPaginatedProducts({
              orderField: 'product.sku',
              order: Order.Asc,
              limit: Constants.LIMIT_RESULTS,
              page: 1,
              query: '',
              brandIds: [],
              collectionIds: [],
              familyIds: [],
              subFamilyIds: [],
              enabled: null,
              editable: null,
              images: null,
            });
            setEnabledFilter(null);
            setEditableFilter(null);
          }}
        >
          Limpiar filtros
        </button>
        <div className="ms-2">
          {requesting ? <Loader type="TailSpin" color="#252E3C" height={12} width={12} /> : <span className="num-products">{formatNumber(paginatedDto?.totalItems || 0, true)}</span>}
        </div>
      </div>
      <table className="my-table">
        <thead>
          <tr>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedProducts.orderField === 'product.sku') {
                    setGetPaginatedProducts({ ...getPaginatedProducts, order: getPaginatedProducts.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedProducts({ ...getPaginatedProducts, orderField: 'product.sku', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Sku</span>
                {getPaginatedProducts.orderField === 'product.sku' ? (
                  <span className="ms-2">{getPaginatedProducts.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedProducts.orderField === 'product.name') {
                    setGetPaginatedProducts({ ...getPaginatedProducts, order: getPaginatedProducts.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedProducts({ ...getPaginatedProducts, orderField: 'product.name', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Producto</span>
                {getPaginatedProducts.orderField === 'product.name' ? (
                  <span className="ms-2">{getPaginatedProducts.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedProducts.orderField === 'product.ean') {
                    setGetPaginatedProducts({ ...getPaginatedProducts, order: getPaginatedProducts.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedProducts({ ...getPaginatedProducts, orderField: 'product.ean', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>EAN</span>
                {getPaginatedProducts.orderField === 'product.ean' ? (
                  <span className="ms-2">{getPaginatedProducts.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedProducts.orderField === 'brand.name') {
                    setGetPaginatedProducts({ ...getPaginatedProducts, order: getPaginatedProducts.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedProducts({ ...getPaginatedProducts, orderField: 'brand.name', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Marca</span>
                {getPaginatedProducts.orderField === 'brand.name' ? (
                  <span className="ms-2">{getPaginatedProducts.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedProducts.orderField === 'collection.name') {
                    setGetPaginatedProducts({ ...getPaginatedProducts, order: getPaginatedProducts.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedProducts({ ...getPaginatedProducts, orderField: 'collection.name', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Colección</span>
                {getPaginatedProducts.orderField === 'collection.name' ? (
                  <span className="ms-2">{getPaginatedProducts.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedProducts.orderField === 'family.name') {
                    setGetPaginatedProducts({ ...getPaginatedProducts, order: getPaginatedProducts.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedProducts({ ...getPaginatedProducts, orderField: 'family.name', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Familias</span>
                {getPaginatedProducts.orderField === 'family.name' ? (
                  <span className="ms-2">{getPaginatedProducts.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedProducts.orderField === 'subFamily.name') {
                    setGetPaginatedProducts({ ...getPaginatedProducts, order: getPaginatedProducts.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedProducts({ ...getPaginatedProducts, orderField: 'subFamily.name', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Subfamilias</span>
                {getPaginatedProducts.orderField === 'subFamily.name' ? (
                  <span className="ms-2">{getPaginatedProducts.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            <th>Activo</th>
            <th>Editable</th>
          </tr>
        </thead>
        <tbody>
          {paginatedDto === null || requesting ? (
            <tr>
              <td colSpan={20} className="text-center">
                <Loader type="TailSpin" color="#252E3C" height={25} width={25} />
              </td>
            </tr>
          ) : paginatedDto?.results.length === 0 ? (
            <tr>
              <td colSpan={20} className="text-center">
                No hay productos
              </td>
            </tr>
          ) : (
            paginatedDto?.results.map((product: Product) => {
              let productName: string = product.name;
              if (productName.length > 50) {
                productName = product.name.substring(0, 50) + '...';
              }
              let ean: string = product.ean;
              if (ean && ean.length > 30) {
                ean = ean.substring(0, 30);
              }
              return (
                <tr key={product.id}>
                  <td>
                    <Link to={`/producto/${product.id}`} className="position-relative link-sale">
                      <span className="fw-bold">{product.sku}</span>
                    </Link>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="me-2">
                        {product.images.length > 0 ? (
                          <LightgalleryProvider>
                            {product.images.map((productImage: ProductImage, index: number) => {
                              return (
                                <LightgalleryItem key={productImage.id} group="group-product-images" src={process.env.REACT_APP_PUBLIC_URL + productImage.path}>
                                  {index === 0 && <LazyLoadImage src={process.env.REACT_APP_PUBLIC_URL + productImage.path} alt="" className="img-thumbnail clickable" />}
                                </LightgalleryItem>
                              );
                            })}
                          </LightgalleryProvider>
                        ) : (
                          <Image size={50} className="img-thumbnail my-1 me-2" color="#D3D3D3" />
                        )}
                      </div>
                      <span className="product-name">{productName}</span>
                    </div>
                  </td>
                  <td>{ean}</td>
                  <td>{product.brand.name}</td>
                  <td>{product.collection?.name}</td>
                  <td>{product.families.map((f: Family) => f.name).join(', ')}</td>
                  <td>{product.subFamilies.map((sf: SubFamily) => sf.name).join(', ')}</td>
                  <td>
                    <span className={`badge ${product.enabled ? 'my-success' : 'my-danger'}`}>{product.enabled ? 'Activo' : 'Inactivo'}</span>
                  </td>
                  <td>
                    <span className={`badge ${product.editable ? 'my-success' : 'my-danger'}`}>{product.editable ? 'Editable' : 'No editable'}</span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {paginatedDto !== null && paginatedDto.results.length > 0 && <Paginator paginatedDto={paginatedDto} goToPage={(page: number) => setGetPaginatedProducts({ ...getPaginatedProducts!, page })} />}
      {selectedProduct && <UploadProductImageModal returnRawData={false} product={selectedProduct} show={showUploadProductModal} closeModal={onCloseUploadProductImageModal} />}
      <ImportStockModal show={showImportStockModal} closeModal={onCloseImportStockModal} />
      <UpdateProductsModal show={showUpdateProductsModal} closeModal={onCloseUpdateProductsModal} />
      <ImportCsvModal show={showImportProductsCsvModal} closeModal={onCloseImportProductsCsvModal} entity={Entity.Product} />
      <ImportCsvModal show={showImportStockCsvModal} closeModal={onCloseImportStockCsvModal} entity={Entity.Stock} />
      <ConfirmModal
        title="Exportar productos"
        content={`Se van a exportar ${formatNumber(paginatedDto?.totalItems || 0, true)} productos.</br>¿Desea continuar?`}
        acceptButtonClass="accept-button"
        disabled={exportingProducts}
        show={showExportProductsModal}
        closeModal={onCloseExportsProductsModal}
      />
    </div>
  );
};

export default ProductsView;
