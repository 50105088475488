import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Withdrawal } from '../interfaces/withdrawal';
import { WithdrawalDto } from '../interfaces/withdrawal.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { storeSelector } from '../store/store-slice';

interface Props {
  show: boolean;
  closeModal: (withdrawal: Withdrawal | null) => void;
}

const defaultValues: WithdrawalDto = {
  amount: null,
  note: '',
  storeId: -1,
};

const WithdrawalModal = (props: Props) => {
  const { selectedStoreId } = useSelector(storeSelector);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<WithdrawalDto>({
    mode: 'onSubmit',
    defaultValues,
  });

  useEffect(() => {
    if (!props.show) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const close = () => {
    reset(defaultValues);
    props.closeModal(null);
  };

  const onSubmit = async (withdrawalDto: WithdrawalDto) => {
    try {
      withdrawalDto.storeId = selectedStoreId;
      const withdrawal: Withdrawal = await api.createWithdrawal(withdrawalDto);
      props.closeModal(withdrawal);
      myToastr.success('Gasto creado correctamente');
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form withdrawal-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Gasto</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.amount })}>Cantidad *</div>
              <input type="number" {...register('amount', { required: true, valueAsNumber: true, min: 0 })} className={clsx({ error: errors?.amount })} placeholder="Cantidad €" step={0.01} min={0} />
              {errors.amount && <div className="error-message">{errors.amount.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.note })}>Nota *</div>
              <textarea {...register('note', { required: true })} className={clsx({ error: errors?.note })} placeholder="Nota"></textarea>
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default WithdrawalModal;
