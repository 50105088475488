import { UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons';
import { useMemo } from 'react';
import { PaginatedDto } from '../interfaces/paginated.dto';

const MIN_NUM_PAGES: number = 7;

interface Props {
  paginatedDto: PaginatedDto<any>;
  goToPage: (page: number) => void;
}

const Paginator = ({ paginatedDto, goToPage }: Props) => {
  const pages: number[] = useMemo(() => {
    const newPages: number[] = [];
    if (paginatedDto.totalPages <= MIN_NUM_PAGES) {
      for (let i = 1; i <= paginatedDto.totalPages; i += 1) {
        newPages.push(i);
      }
    } else {
      for (let i = Math.max(1, paginatedDto.currentPage - 3); i <= Math.min(paginatedDto.currentPage + 3, paginatedDto.totalPages); i += 1) {
        newPages.push(i);
      }
      if (newPages.length < MIN_NUM_PAGES) {
        if (paginatedDto.currentPage - 3 > 0) {
          do {
            newPages.unshift(newPages[0]! - 1);
          } while (newPages.length < MIN_NUM_PAGES);
        }
        if (paginatedDto.currentPage + 3 < paginatedDto.totalPages) {
          do {
            newPages.push(newPages[newPages.length - 1]! + 1);
          } while (newPages.length < MIN_NUM_PAGES);
        }
      }
    }
    return newPages;
  }, [paginatedDto]);

  const canPreviousPage: boolean = useMemo(() => {
    return paginatedDto.currentPage - 1 >= 1;
  }, [paginatedDto]);

  const canNextPage: boolean = useMemo(() => {
    return paginatedDto.currentPage + 1 <= paginatedDto.totalPages;
  }, [paginatedDto]);

  return (
    <div className="my-pagination noselect d-flex justify-content-center align-items-center">
      <div
        className={`d-flex justify-content-center align-items-center previous-page ${canPreviousPage ? 'selectable' : 'disabled'}`}
        onClick={() => {
          if (canPreviousPage) {
            goToPage(paginatedDto.currentPage - 1);
          }
        }}
      >
        <UilAngleLeft color="#808a95" size={20} />
        <span>Anterior</span>
      </div>
      {pages.map((page: number) => (
        <span
          key={page}
          onClick={() => {
            if (page === paginatedDto.currentPage) {
              return;
            }
            goToPage(page);
          }}
          className={`page ${paginatedDto.currentPage === page ? 'active' : 'selectable'}`}
        >
          {page}
        </span>
      ))}
      <div
        className={`d-flex justify-content-center align-items-center next-page ${canNextPage ? 'selectable' : 'disabled'}`}
        onClick={() => {
          if (canNextPage) {
            goToPage(paginatedDto.currentPage + 1);
          }
        }}
      >
        <span>Siguiente</span>
        <UilAngleRight color="#808a95" size={20} />
      </div>
    </div>
  );
};

export default Paginator;
