import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getTextAndColorRepairStatus } from '../services/helpers';
import { repairSelector } from '../store/repair-slice';

const RepairStatusView = () => {
  const { repair } = useSelector(repairSelector);
  const { text, color } = useMemo(() => getTextAndColorRepairStatus(repair!), [repair]);
  return (
    <div className="repair-status-view" style={{ backgroundColor: color }}>
      {text}
    </div>
  );
};

export default RepairStatusView;
