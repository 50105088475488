import clsx from 'clsx';
import es from 'date-fns/locale/es';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Calendar, Check, ChevronDown, ChevronUp } from 'react-feather';
import { DateRange } from '../enums/date-range';
import { DateRangeData } from '../interfaces/date-range-data';
import { dateRangeToString, getDatesGivenDateRange } from '../services/helpers';
import myToastr from '../services/toastr';
import CustomMenuDropdown from '../views/custom-menu-dropdown';
import CustomToggleDropdown from './custom-toggle-dropdown';

registerLocale('es', es);

interface Props {
  showTimePicker: boolean;
  dateRangeData: DateRangeData;
  availableDateRanges: DateRange[];
  onChange: (dateRangeData: DateRangeData) => void;
}

const DateRangeSelector = ({ showTimePicker, dateRangeData, availableDateRanges, onChange }: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>('00:00');
  const [endTime, setEndTime] = useState<string>('23:59');
  const [localDateRangeData, setLocalDateRangeData] = useState<DateRangeData>(dateRangeData);

  useEffect(() => {
    setLocalDateRangeData(dateRangeData);
  }, [dateRangeData]);

  const applyTime = () => {
    if (!dateRangeData.dates[0] || !dateRangeData.dates[1]) {
      return;
    }
    if (!startTime || !moment(`${moment().format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm', true).isValid()) {
      myToastr.error('Formato de hora inicio incorrecto');
      return;
    }
    if (!endTime || !moment(`${moment().format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm', true).isValid()) {
      myToastr.error('Formato de hora fin incorrecto');
      return;
    }
    const partsStartTime = startTime.split(':');
    const startDate: moment.Moment = moment(dateRangeData.dates[0]).set('hour', parseInt(partsStartTime[0])).set('minute', parseInt(partsStartTime[1]));
    const partsEndTime = endTime.split(':');
    const endDate: moment.Moment = moment(dateRangeData.dates[1]).set('hour', parseInt(partsEndTime[0])).set('minute', parseInt(partsEndTime[1]));
    setLocalDateRangeData({
      dateRange: dateRangeData.dateRange,
      dates: [startDate.toDate(), endDate.toDate()],
    });
    onChange({
      dateRange: dateRangeData.dateRange,
      dates: [startDate.toDate(), endDate.toDate()],
    });
    setShow(false);
  };

  const changeDateRange = (dateRange: DateRange) => {
    const dates: [Date | null, Date | null] = getDatesGivenDateRange(dateRange);
    if (
      moment(dates[0]).isValid() &&
      startTime &&
      moment(`${moment().format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm', true).isValid() &&
      moment(dates[1]).isValid() &&
      endTime &&
      moment(`${moment().format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm', true).isValid()
    ) {
      const partsStartTime = startTime.split(':');
      dates[0] = moment(dates[0]).set('hour', parseInt(partsStartTime[0])).set('minute', parseInt(partsStartTime[1])).toDate();
      const partsEndTime = endTime.split(':');
      dates[1] = moment(dates[1]).set('hour', parseInt(partsEndTime[0])).set('minute', parseInt(partsEndTime[1])).toDate();
    }
    setLocalDateRangeData({ dateRange: dateRange, dates });
    onChange({ dateRange, dates });
    setShow(false);
  };

  const onChangeDatePicker = (dates: any) => {
    setLocalDateRangeData({ dateRange: DateRange.Custom, dates });
    if (moment(dates[0]).isValid() && moment(dates[1]).isValid()) {
      if (!availableDateRanges.includes(DateRange.All)) {
        const diffDays: number = moment(dates[1]).diff(moment(dates[0]), 'days');
        if (diffDays > 365) {
          myToastr.error('El rango de fechas no puede ser mayor a 1 año');
          return;
        }
      }
      if (
        startTime &&
        moment(`${moment().format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm', true).isValid() &&
        endTime &&
        moment(`${moment().format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm', true).isValid()
      ) {
        const partsStartTime = startTime.split(':');
        dates[0] = moment(dates[0]).set('hour', parseInt(partsStartTime[0])).set('minute', parseInt(partsStartTime[1])).toDate();
        const partsEndTime = endTime.split(':');
        dates[1] = moment(dates[1]).set('hour', parseInt(partsEndTime[0])).set('minute', parseInt(partsEndTime[1])).toDate();
      }
      onChange({ dateRange: DateRange.Custom, dates });
      setShow(false);
    }
  };

  return (
    <Dropdown autoClose={'outside'} show={show} onToggle={(e) => setShow(!show)}>
      <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components">
        <div className="d-flex align-items-center container-selected-date-range">
          <Calendar className="me-2" color="black" size={18} />
          <span className="selected-date-range flex-grow-1">{dateRangeToString(localDateRangeData.dateRange)}</span>
          {show ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenuDropdown} show={show}>
        <div className="date-ranges-options d-flex flex-column">
          {availableDateRanges.map((dr: DateRange) => (
            <span key={dr} onClick={() => changeDateRange(dr)} className={clsx('mt-2', 'date-option', { selected: localDateRangeData.dateRange === dr })}>
              {dateRangeToString(dr)}
            </span>
          ))}
          {showTimePicker && (
            <div className="d-flex align-items-stretch container-time mb-2 justify-content-between">
              <div>
                <div className="d-flex flex-row me-3 justify-content-between mb-1">
                  <div className="time-option">Hora Inicio</div>
                  <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                </div>
                <div className="d-flex flex-row me-3 justify-content-between">
                  <div className="time-option">Hora Fin</div>
                  <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                </div>
              </div>
              <button title="Aceptar filtro tiempo" onClick={applyTime}>
                <Check color="#26c44b" size={20} />
              </button>
            </div>
          )}
          <div className="">
            <DatePicker
              placeholderText="Selecciona un rango de fechas"
              className="input-date"
              dateFormat="dd/MM/yyyy"
              selected={localDateRangeData.dates[0]}
              onChange={onChangeDatePicker}
              startDate={localDateRangeData.dates[0]}
              endDate={localDateRangeData.dates[1]}
              selectsRange
              locale="es"
              inline
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DateRangeSelector;
