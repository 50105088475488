import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row } from 'react-table';
import Table from '../components/table';
import { NotificationEntity } from '../enums/notification-entity';
import { NotificationType } from '../enums/notification-type';
import { Notification } from '../interfaces/notification';
import { api } from '../services/api';
import { storeSelector } from '../store/store-slice';

const NotificationsView = () => {
  const { store, selectedStoreId } = useSelector(storeSelector);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);

  useEffect(() => {
    const getNotifications = async () => {
      const ns: Notification[] = await api.getNotifications();
      setNotifications(ns);
    };
    getNotifications();
  }, [selectedStoreId]);

  const getColorAndText = (notification: Notification) => {
    let text: string = '';
    let to: string = '';
    switch (notification.entity) {
      case NotificationEntity.CashRegister:
        text = 'Arqueo';
        to = `/arqueo/${notification.entityId}`;
        break;
      case NotificationEntity.DeliveryNote:
        text = 'Compra';
        to = `/compra/${notification.entityId}`;
        break;
      case NotificationEntity.Inventory:
        text = 'Inventario';
        to = `/inventario/${notification.entityId}`;
        break;
      case NotificationEntity.Repair:
        text = 'Reparación';
        to = `/reparacion/${notification.entityId}`;
        break;
      case NotificationEntity.Sale:
        text = 'Venta';
        to = `/venta/${notification.entityId}`;
        break;
      case NotificationEntity.Stock:
        text = 'Stock';
        to = `/producto/${notification.entityId}`;
        break;
      case NotificationEntity.System:
        text = 'Sistema';
        to = '#';
        break;
      case NotificationEntity.Transfer:
        text = 'Traspaso';
        to = `/traspaso/${notification.entityId}`;
        break;
    }
    return { text, to };
  };

  const columns = React.useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Fecha',
        accessor: 'createdAt',
        filter: 'fuzzyDate',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }: any) => {
          return moment(value).format('DD/MM/YYYY HH:mm');
        },
      },
      {
        Header: 'Tienda',
        accessor: 'store.name',
        filter: 'fuzzyText',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: 'Tipo',
        accessor: 'entity',
        filter: 'fuzzyText',
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ row }: any) => {
          const notification: Notification = row.original;
          let color = '';
          switch (notification.type) {
            case NotificationType.Info:
              color = '#0092FD';
              break;
            case NotificationType.Warning:
              color = '#E09200';
              break;
            case NotificationType.Error:
              color = '#E02760';
              break;
            case NotificationType.Success:
              color = '#26C44B';
              break;
          }
          const { text, to } = getColorAndText(notification);
          return (
            <Link to={to} className="fw-bold" style={{ color }}>
              {text}
            </Link>
          );
        },
      },
      {
        Header: 'Título',
        accessor: 'title',
        filter: 'fuzzyText',
      },
      {
        Header: 'Descripción',
        accessor: 'description',
        filter: 'fuzzyText',
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]) as any;

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const notification: Notification = fr.original;
      const { text } = getColorAndText(notification);
      data.push({
        Fecha: moment(notification.createdAt).format('DD/MM/YYYY HH:mm'),
        Tienda: notification.store.name,
        Tipo: text,
        Título: notification.title,
        Descripción: notification.description,
        Leída: `${notification.user.name} ${notification.user.surnames}`,
      });
    });
    setCsvData(data);
  };

  return (
    <div className="notificaciones p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Notificaciones</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename={store ? `notificaciones_${store.name}.csv` : 'notificaciones.csv'} className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
      </div>
      <Table data={notifications} columns={columns} noDataMessage="No hay notificaciones" onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}></Table>
    </div>
  );
};

export default NotificationsView;
