import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RepairStatus } from '../enums/repair-status';
import { Organization } from '../interfaces/organization';
import { useAppSelector } from '../store/hooks';
import { cancelRepair, repairSelector, updateNotes } from '../store/repair-slice';
import { RootState } from '../store/store';
import NoteModal from './note-modal';
import PinModal from './pin-modal';

const RepairUpdateHeader = () => {
  const dispatch = useDispatch();
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { notes, repair } = useSelector(repairSelector);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);

  const onCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  const onSaveNoteModal = (notes: string | null) => {
    dispatch(updateNotes({ notes }));
    setShowNoteModal(false);
  };

  const onDeleteNoteModal = () => {
    dispatch(updateNotes({ notes: null }));
    setShowNoteModal(false);
  };

  const onCancelRepair = (pin: string | null) => {
    if (pin) {
      dispatch(cancelRepair({ organizationId: organization.id, pin }));
    }
    setShowPinModal(false);
  };

  return (
    <div className="repair-update-header">
      <div className="d-flex align-items-center">
        <div className="col">
          {(repair?.repairStatus === RepairStatus.Budget ||
            repair?.repairStatus === RepairStatus.Finished ||
            repair?.repairStatus === RepairStatus.InProcess ||
            repair?.repairStatus === RepairStatus.Opened) && (
            <button className="cancel-repair" onClick={() => setShowPinModal(true)}>
              Cancelar
            </button>
          )}
        </div>
        <div className="col text-center">
          <h1 className="title mb-0">{repair!.internalId}</h1>
        </div>
        <div className="col">
          {notes != null && notes !== '' ? (
            <span onClick={() => setShowNoteModal(true)} className="has-notes">
              1 Nota
            </span>
          ) : (
            <span onClick={() => setShowNoteModal(true)} className="notes">
              + Notas
            </span>
          )}
        </div>
      </div>
      <NoteModal editable={true} show={showNoteModal} notes={notes} closeModal={onCloseNoteModal} saveNotes={onSaveNoteModal} deleteNotes={onDeleteNoteModal} />
      <PinModal show={showPinModal} onCloseModal={onCancelRepair} />
    </div>
  );
};

export default RepairUpdateHeader;
