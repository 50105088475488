export enum DateRange {
  Custom = 'custom',
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'this-week',
  ThisMonth = 'this-month',
  ThisYear = 'this-year',
  LastYear = 'last-year',
  All = 'all',
}
