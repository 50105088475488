import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { Brand } from '../interfaces/brand';
import { Collection } from '../interfaces/collection';
import { CollectionDto } from '../interfaces/collection.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';

interface Props {
  collection: Collection | null;
  show: boolean;
  closeModal: (collection: Collection | null) => void;
}

interface CustomCollectionDto {
  name: string;
  brandId: number | null;
}

const initialValues: CustomCollectionDto = {
  name: '',
  brandId: null,
};

const CollectionModal = ({ collection, show, closeModal }: Props) => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CustomCollectionDto>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    const getBrands = async () => {
      const brands: Brand[] = await api.getBrands();
      setBrands(brands);
    };
    getBrands();
  }, []);

  useEffect(() => {
    if (show && collection) {
      reset({
        name: collection.name,
        brandId: collection.brandId,
      });
    }
  }, [show, collection, reset]);

  const close = () => {
    reset(initialValues);
    closeModal(null);
  };

  const onSubmit = async (customCollectionDto: CustomCollectionDto) => {
    const collectionDto: CollectionDto = {
      name: customCollectionDto.name,
      brandId: customCollectionDto.brandId!,
    };
    try {
      if (collection) {
        const updatedCollection: Collection = await api.updateCollection(collection.id, collectionDto);
        closeModal(updatedCollection);
        myToastr.success('Colección actualizada correctamente');
      } else {
        const collection: Collection = await api.createCollection(collectionDto);
        closeModal(collection);
        myToastr.success('Colección creada correctamente');
      }
      reset();
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  return (
    <Modal className="vercomi-modal my-form collection-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Colección</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.name })}>Nombre</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.brandId })}>Marca*</div>
              <select {...register('brandId', { required: true, valueAsNumber: true })} className={clsx({ error: errors?.brandId })}>
                <option value="">Seleccionar</option>
                {brands.map((b: Brand) => (
                  <option key={b.id} value={b.id}>
                    {b.name}
                  </option>
                ))}
              </select>
              {errors.brandId && <div className="error-message">{errors.brandId.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default CollectionModal;
