import moment from 'moment';
import { useMemo } from 'react';
import { X } from 'react-feather';
import Modal from 'react-modal';
import { UserWorkClockingHistory } from '../interfaces/user-work-clocking-history';
import { userWorkClockingActionToString } from '../services/helpers';
import Table from './table';

interface Props {
  dayMonthYear: string;
  history: UserWorkClockingHistory[];
  show: boolean;
  closeModal: () => void;
}

const UserWorkClockingHistoryDayModal = ({ dayMonthYear, history, show, closeModal }: Props) => {
  const columns = useMemo(
    () => [
      { Header: 'Fecha acción', accessor: (uwch: UserWorkClockingHistory) => moment(uwch.createdAt).format('DD/MM/YYYY HH:mm') },
      {
        Header: 'Tienda anterior',
        accessor: 'previousStore.name',
      },
      {
        Header: 'Tienda actual',
        accessor: 'currentStore.name',
      },
      {
        Header: 'Hora entrada anterior',
        accessor: 'previousClockIn',
      },
      {
        Header: 'Hora entrada actual',
        accessor: 'currentClockIn',
      },
      {
        Header: 'Hora salida anterior',
        accessor: 'previousClockOut',
      },
      {
        Header: 'Hora salida actual',
        accessor: 'currentClockOut',
      },
      {
        Header: 'Acción',
        accessor: (uwch: UserWorkClockingHistory) => userWorkClockingActionToString(uwch.action),
      },
    ],
    [],
  );

  return (
    <Modal className="vercomi-modal user-work-clockings-history-modal" isOpen={show} onRequestClose={closeModal} shouldCloseOnOverlayClick={true}>
      <div className="main">
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Cambios en el fichaje del día {dayMonthYear}</div>
            <button type="button" className="close-button-modal" onClick={closeModal} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <Table
            data={history}
            columns={columns}
            noDataMessage="No hay histórico de fichajes"
            initialState={{
              pageSize: 10,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UserWorkClockingHistoryDayModal;
