import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReturnSummary from '../components/return-summary';
import SaleDetails from '../components/sale-details';
import SalePayment from '../components/sale-payment';
import SalePaymentMethods from '../components/sale-payment-methods';
import SaleSummary from '../components/sale-summary';
import { SaleStatus } from '../enums/sale-status';
import { SaleStep } from '../enums/sale-step';
import { PreviosNextSaleDto } from '../interfaces/previos-next-sale.dto';
import { Sale } from '../interfaces/sale';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { reset, saleSelector, setPreviousNextSale, setSale } from '../store/sale-slice';
import { storeSelector } from '../store/store-slice';

const SaleView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const { sale, step, productsToReturn } = useAppSelector(saleSelector);
  const { selectedStoreId } = useSelector(storeSelector);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedStoreId === -1 && step !== SaleStep.Viewing && productsToReturn.length === 0) {
      myToastr.info(`Selecciona una tienda`);
    }
  }, [selectedStoreId, step, productsToReturn]);

  useEffect(() => {
    if (params?.id) {
      const getSale = async () => {
        try {
          setLoading(false);
          const saleId: number = parseInt(params.id!, 10);
          const sale: Sale = await api.getSale(saleId);
          dispatch(setSale(sale));
          const previosNextSaleDto: PreviosNextSaleDto = await api.getPreviosNextSale(saleId);
          dispatch(setPreviousNextSale(previosNextSaleDto));
        } catch (e) {
          navigate('/ventas');
        } finally {
          setLoading(false);
        }
      };
      getSale();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!sale) {
    return null;
  }

  return (
    <div className="pos inner-layout">
      <div className="l-side overflow-scroll">
        {step === SaleStep.Viewing && <SaleSummary />}
        {step === SaleStep.ReturnsAndExchanges && <SaleSummary />}
        {step === SaleStep.Payment && <ReturnSummary />}
      </div>
      <div className="r-side">
        {step === SaleStep.Viewing && (sale.status === SaleStatus.Finalized || sale.status === SaleStatus.Cancelled || sale.status === SaleStatus.Return || sale.status === SaleStatus.Pending) && (
          <SalePaymentMethods />
        )}
        {step === SaleStep.Viewing && sale.status === SaleStatus.Reservation && <SalePayment />}
        {step === SaleStep.ReturnsAndExchanges && <SaleDetails />}
        {step === SaleStep.Payment && <SalePayment />}
      </div>
    </div>
  );
};

export default SaleView;
