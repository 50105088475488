import clsx from 'clsx';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Aside from '../components/aside';
import AsideB2B from '../components/aside-b2b';
import Header from '../components/header';
import { EventType } from '../enums/event-type';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import useCacheBuster from '../hooks/use-cache-buster';
import { AccessToken } from '../interfaces/access-token';
import { DecodedToken } from '../interfaces/decoded-token';
import { UserInOrganization } from '../interfaces/user-in-organization';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import myToastr from '../services/toastr';
import { websocket } from '../services/websocket';
import { authSelector, getOrganization, getUserInfo, logoutAction, setOrganizationType, setRole } from '../store/auth-slice';
import { setStoreId } from '../store/store-slice';

// Cada 5 minutos se comprueba el token
const MS_CHECK_TOKEN = 5 * 60 * 1000;
const OFFSET_MS_REFRESH_TOKEN = 2 * 60 * 60 * 1000; // 2 horas

const HomeView = () => {
  useCacheBuster();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, user, organization, organizationType } = useSelector(authSelector);

  useEffect(() => {
    const timer = setInterval(() => {
      checkToken();
    }, MS_CHECK_TOKEN);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) {
      dispatch(getUserInfo());
    }
    if (!organization) {
      const localStorageOrganizationId: number = localStorage.getItem('organizationId') ? parseInt(localStorage.getItem('organizationId')!, 10) : -1;
      if (localStorageOrganizationId > 0) {
        dispatch(getOrganization(localStorageOrganizationId));
      } else {
        logout();
        return;
      }
    }
    if (!organizationType) {
      const localStorageOrganizationType: OrganizationType = localStorage.getItem('organizationType') as OrganizationType;
      if (localStorageOrganizationType) {
        dispatch(setOrganizationType(localStorageOrganizationType));
      }
    }
    const storeId: number = localStorage.getItem('storeId') ? parseInt(localStorage.getItem('storeId')!, 10) : -1;
    if (storeId > 0) {
      dispatch(setStoreId(storeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user || !organization) {
      return;
    }
    dispatch(setRole());
  }, [user, organization, dispatch]);

  useEffect(() => {
    if (user) {
      const token: string = localStorage.getItem('token')!;
      websocket.connect(token);
    }
    return () => {
      websocket.disconnect();
    };
  }, [user]);

  useEffect(() => {
    if (!websocket.isConnected) {
      return;
    }
    websocket.on(EventType.NewSession, () => {
      myToastr.error('Se ha iniciado sesión en otro dispositivo', 'Atención', {
        closeButton: true,
        timeOut: 0,
        extendedTimeOut: 0,
        tapToDismiss: false,
      });
      logout();
    });
    return () => {
      websocket.off(EventType.NewSession);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocket.isConnected]);

  useEffect(() => {
    if (!user || !organization) {
      return;
    }
    const index: number = user.userInOrganizations.findIndex((userInOrganization: UserInOrganization) => userInOrganization.organizationId === organization.id);
    if (index === -1) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, organization]);

  useEffect(() => {
    if (!user?.role || !organization) {
      return;
    }
    if (organizationType) {
      if (!Constants.ORGANIZATION_TYPES.includes(organizationType)) {
        logout();
      } else {
        switch (organizationType) {
          case OrganizationType.B2B:
            if (organization.type === OrganizationType.B2C) {
              dispatch(setOrganizationType(OrganizationType.B2C));
            }
            break;
          case OrganizationType.B2C:
            if (organization.type === OrganizationType.B2B) {
              dispatch(setOrganizationType(OrganizationType.B2B));
            }
            break;
          default:
            logout();
            break;
        }
      }
    } else {
      // Setear organizationType organización a partir del rol del usuario y a partir del tipo de organización
      if (organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2B) {
        if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
          dispatch(setOrganizationType(OrganizationType.B2B));
        } else {
          dispatch(setOrganizationType(OrganizationType.B2C));
        }
      } else if (organization.type === OrganizationType.B2C) {
        dispatch(setOrganizationType(OrganizationType.B2C));
      } else {
        logout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, user, organizationType]);

  const logout = () => {
    dispatch(logoutAction());
    navigate('/login');
  };

  const checkToken = async () => {
    try {
      const token: string | null = localStorage.getItem('token');
      if (token) {
        const decodedToken: DecodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 <= new Date().getTime() + OFFSET_MS_REFRESH_TOKEN) {
          console.log(`Access token expired. Checking refresh token...`);
          const refreshToken: string | null = localStorage.getItem('refreshToken');
          if (refreshToken) {
            const decodedRefreshToken: DecodedToken = jwtDecode(refreshToken);
            if (decodedRefreshToken.exp * 1000 < new Date().getTime()) {
              console.log(`Refresh token expired. Logging out the user...`);
              logout();
              return;
            }
            console.log(`Refresh token is still valid. Requesting a new access token...`);
            // El token va a expirar en menos de 60 minutos, se solicita uno nuevo
            const accessToken: AccessToken = await api.refreshAccessToken({ refreshToken });
            localStorage.setItem('token', accessToken.token);
            localStorage.setItem('refreshToken', accessToken.refreshToken);
            console.log(`Access token refreshed!`);
          } else {
            console.log(`No refresh token found. Logging out the user...`);
            logout();
          }
        }
      } else {
        console.log(`No access token found. Logging out the user...`);
        logout();
      }
    } catch (e: any) {
      logout();
    }
  };

  if (loading || !user || !user.role || !organization || !organizationType) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="layout">
      <div className={clsx('header', { 'header-wholesaler': organizationType === OrganizationType.B2B })}>
        <Header />
      </div>
      {organizationType === OrganizationType.B2C ? <Aside /> : <AsideB2B />}
      <div className="main">
        <Outlet />
      </div>
    </div>
  );
};

export default HomeView;
