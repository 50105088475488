import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronLeft, ChevronUp, Image } from 'react-feather';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import Loader from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Paginator from '../components/paginator';
import { Order } from '../enums/order';
import { OrganizationType } from '../enums/organization-type';
import { useStores } from '../hooks/use-stores.hook';
import { Brand } from '../interfaces/brand';
import { Collection } from '../interfaces/collection';
import { GetPaginatedStockWholesaler } from '../interfaces/get-paginated-stock-wholesaler';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { OrganizationClient } from '../interfaces/organization-client';
import { PaginatedDto } from '../interfaces/paginated.dto';
import { ProductImage } from '../interfaces/product-image';
import { Stock } from '../interfaces/stock';
import { Store } from '../interfaces/store';
import { api } from '../services/api';
import { formatNumber, roundTwoDecimals } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  getPurchaseOrder as getPurchaseOrderStore,
  ProductInShoppingCart,
  purchaseOrderSelector,
  removeAllProducts,
  removeProduct,
  setDestinationOrganizationId,
  setDestinationStoreId,
  setHasEquivalenceSurcharge,
  setOrganizationClientId,
  setOriginOrganizationId,
  setOriginStoreId,
  setRetailStoreId,
  setUnits,
} from '../store/purchase-order-slice';
import { RootState } from '../store/store';

const customStyles = {
  container: (provided: any, state: any) => {
    return {
      ...provided,
      flex: 1,
      fontFamily: 'Inter-Regular',
      fontSize: '14px',
      borderColor: '#a0aec1',
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
  }),
  control: (provided: any, state: any) => {
    return {
      ...provided,
    };
  },
  singleValue: (provided: any, state: any) => {
    return { ...provided };
  },
};

interface Totals {
  requestedUnits: number;
  taxBase: number;
  totalVat: number;
  totalSurchargeEquivalence: number;
  total: number;
}

const NewPurchaseOrderView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const { purchaseOrder, organizationClientId, retailStoreId, productUnitsMap, originOrganizationId, hasEquivalenceSurcharge: equivalenceSurcharge, products } = useAppSelector(purchaseOrderSelector);
  const [organizationClients, setOrganizationClients] = useState<OrganizationClient[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [collections, setCollections] = useState<Collection[]>([]);
  const stores: Store[] = useStores();
  const [filters, setFilters] = useState<GetPaginatedStockWholesaler>({
    orderField: 'product.sku',
    order: Order.Asc,
    limit: 50,
    page: 1,
    query: '',
    organizationId: organization.id,
    stockOrganizationId: -1,
    brandIds: [],
    collectionIds: [],
  });
  const [paginatedDto, setPaginatedDto] = useState<PaginatedDto<Stock> | null>(null);
  const [requesting, setRequesting] = useState<boolean>(false);
  const selectedBrands: { label: string; value: number }[] = useMemo(
    () => brands.filter((brand: Brand) => filters.brandIds!.includes(brand.id)).map((brand: Brand) => ({ label: brand.name, value: brand.id })),
    [brands, filters.brandIds],
  );
  const selectedCollections: { label: string; value: number }[] = useMemo(
    () => collections.filter((collection: Collection) => filters.collectionIds!.includes(collection.id)).map((collection: Collection) => ({ label: collection.name, value: collection.id })),
    [collections, filters.collectionIds],
  );
  const showTable: boolean = useMemo(() => {
    if (organizationClientId <= 0) {
      return false;
    }
    if (organizationType === OrganizationType.B2B) {
      return true;
    } else {
      if (retailStoreId === null || retailStoreId <= 0) {
        return false;
      }
      return true;
    }
  }, [organizationType, organizationClientId, retailStoreId]);
  const totals: Totals = useMemo(() => {
    const result: Totals = {
      requestedUnits: 0,
      taxBase: 0,
      totalVat: 0,
      totalSurchargeEquivalence: 0,
      total: 0,
    };
    products.forEach((productInShoppingCart: ProductInShoppingCart) => {
      result.requestedUnits += productInShoppingCart.units;
      result.taxBase += productInShoppingCart.units * productInShoppingCart.retailPrice;
    });
    result.taxBase = roundTwoDecimals(result.taxBase);
    result.totalVat = roundTwoDecimals((result.taxBase * parseFloat(process.env.REACT_APP_IVA)) / 100);
    result.totalSurchargeEquivalence = equivalenceSurcharge ? roundTwoDecimals((result.taxBase * parseFloat(process.env.REACT_APP_EQUIVALENCE_SURCHARGE)) / 100) : 0;
    result.total = roundTwoDecimals(result.taxBase + result.totalVat + result.totalSurchargeEquivalence);
    return result;
  }, [products, equivalenceSurcharge]);

  const getPaginatedStockWholeSalerGivenFilters = async (filters: GetPaginatedStockWholesaler) => {
    try {
      setRequesting(true);
      const ps: PaginatedDto<Stock> = await api.getPaginatedStockWholeSaler(filters);
      setPaginatedDto(ps);
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    } finally {
      setRequesting(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(debounce(getPaginatedStockWholeSalerGivenFilters, 1000), []);

  useEffect(() => {
    getOrganizationClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!params.id) {
      return;
    }
    const id: number = parseInt(params.id);
    if (isNaN(id)) {
      navigate(organizationType === OrganizationType.B2C ? '/ordenes-compra' : '/pedidos');
      return;
    }
    getPurchaseOrder(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, organizationType]);

  const getPurchaseOrder = async (id: number) => {
    const result = await dispatch(getPurchaseOrderStore(id));
    if (!result.payload) {
      navigate(organizationType === OrganizationType.B2C ? '/ordenes-compra' : '/pedidos');
      return;
    }
    // TODO: seleccionar cliente org y tienda
    setRequesting(false);
  };

  useEffect(() => {
    if (!purchaseOrder || organizationClients.length === 0) {
      return;
    }
    const index: number = organizationClients.findIndex((oc: OrganizationClient) => {
      if (organizationType === OrganizationType.B2B) {
        return (
          (purchaseOrder.originOrganizationId === oc.organizationId && purchaseOrder.destinationOrganizationId === oc.relatedOrganizationId) ||
          (purchaseOrder.originOrganizationId === oc.relatedOrganizationId && purchaseOrder.destinationOrganizationId === oc.organizationId)
        );
      } else {
        return purchaseOrder.originOrganizationId === oc.organizationId && organization.id === oc.relatedOrganizationId;
      }
    });
    if (index === -1) {
      return;
    }
    const organizationClient: OrganizationClient = organizationClients[index];
    dispatch(setOrganizationClientId(organizationClient.id));
    dispatch(setRetailStoreId(organizationClient.relatedStoreId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, purchaseOrder, organizationClients]);

  useEffect(() => {
    if (organizationClientId <= 0) {
      setPaginatedDto(null);
      return;
    }
    const organizationClient: OrganizationClient = organizationClients.find((organizationClient: OrganizationClient) => organizationClient.id === organizationClientId)!;
    let stockOrganizationId: number = -1;
    if (organizationType === OrganizationType.B2B) {
      if (organizationClient.relatedOrganization) {
        if (organizationClient.relatedOrganization.type === OrganizationType.ALL || organizationClient.relatedOrganization.type === OrganizationType.B2B) {
          // El mayorista compra a un mayorista (mostrar el stock de la otra organización)
          stockOrganizationId = organization.id === organizationClient.relatedOrganizationId! ? organizationClient.organizationId : organizationClient.relatedOrganizationId!;
        } else {
          // El mayorista hace una órden de compra a un minorista (mostrar su propio stock)
          stockOrganizationId = organizationClient.organizationId;
        }
      } else {
        // El mayorista hace una órden de compra a un cliente manual, mostrar su propio stock
        stockOrganizationId = organizationClient.organizationId;
      }
    } else {
      // El minorista compra a un mayorista
      stockOrganizationId = organizationClient.organizationId;
    }
    getBrands(stockOrganizationId);
    getCollections(stockOrganizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationClientId]);

  useEffect(() => {
    if (organizationClientId <= 0) {
      return;
    }
    debounced(filters)!;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (organizationClientId <= 0) {
      setPaginatedDto(null);
      return;
    }
    if (organizationType === OrganizationType.B2C && (retailStoreId === null || retailStoreId <= 0)) {
      setPaginatedDto(null);
      return;
    }
    const organizationClient: OrganizationClient = organizationClients.find((organizationClient: OrganizationClient) => organizationClient.id === organizationClientId)!;
    let stockOrganizationId: number = -1;
    if (organizationType === OrganizationType.B2B) {
      if (organizationClient.relatedOrganization) {
        if (organizationClient.relatedOrganization.type === OrganizationType.ALL || organizationClient.relatedOrganization.type === OrganizationType.B2B) {
          // El mayorista compra a un mayorista (mostrar el stock de la otra organización)
          stockOrganizationId = organization.id === organizationClient.relatedOrganizationId! ? organizationClient.organizationId : organizationClient.relatedOrganizationId!;
        } else {
          // El mayorista hace una órden de compra a un minorista (mostrar su propio stock)
          stockOrganizationId = organizationClient.organizationId;
        }
      } else {
        // El mayorista hace una órden de compra a un cliente manual, mostrar su propio stock
        stockOrganizationId = organizationClient.organizationId;
      }
    } else {
      // El minorista compra a un mayorista
      stockOrganizationId = organizationClient.organizationId;
    }
    setFilters({
      ...filters,
      stockOrganizationId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, organizationType, organizationClientId, retailStoreId]);

  const getOrganizationClients = async () => {
    try {
      const ocs: OrganizationClient[] = await api.getOrganizationClients(organization.id);
      setOrganizationClients(ocs);
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
  };

  const getBrands = async (organizationId: number) => {
    try {
      const b: Brand[] = await api.getBrands(organizationId);
      setBrands(b);
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

  const getCollections = async (organizationId: number) => {
    try {
      const c: Collection[] = await api.getCollections(organizationId);
      setCollections(c);
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

  const title: string = useMemo(() => {
    if (purchaseOrder) {
      return organizationType === OrganizationType.B2B ? 'Editar pedido' : 'Editar órden de compra';
    } else {
      return organizationType === OrganizationType.B2B ? 'Nuevo pedido' : 'Nueva órden de compra';
    }
  }, [purchaseOrder, organizationType]);

  return (
    <div className="new-purchase-order-view d-flex flex-column p-4">
      <div className="d-flex justify-content-start align-items-center mb-3">
        <div onClick={() => navigate(organizationType === OrganizationType.B2C ? '/ordenes-compra' : '/pedidos')} className="d-flex align-items-center come-back me-4">
          <ChevronLeft size={14} className="me-2" />
          Volver
        </div>
        <span className="title flex-grow-1">{title}</span>
      </div>
      <div className="container-info mb-4">
        <div className="row">
          <div className="col-4 d-flex flex-column">
            <label className="title-input mb-2">{organizationType === OrganizationType.B2C ? 'Mayorista' : 'Cliente'}</label>
            {organizationClients.length === 0 ? (
              <span>{organizationType === OrganizationType.B2C ? 'No hay mayoristas disponibles' : 'No hay clientes disponibles'}</span>
            ) : (
              <select
                disabled={purchaseOrder !== null}
                value={organizationClientId}
                onChange={(e) => {
                  setPaginatedDto(null);
                  const id: number = parseInt(e.target.value, 10);
                  dispatch(setOrganizationClientId(id));
                  if (id !== -1) {
                    const organizationClient: OrganizationClient = organizationClients.find((oc: OrganizationClient) => oc.id === id)!;
                    const relatedOrganization: Organization = organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization : organizationClient.organization;
                    if (organizationType === OrganizationType.B2B) {
                      // El usuario es B2B
                      if (relatedOrganization.type === OrganizationType.ALL || relatedOrganization.type === OrganizationType.B2B) {
                        // El mayorista compra a un mayorista
                        if (organizationClient.organizationId === organization.id) {
                          dispatch(setOriginOrganizationId(organizationClient.relatedOrganizationId));
                          dispatch(setOriginStoreId(organizationClient.relatedStoreId));
                          dispatch(setDestinationOrganizationId(organizationClient.organizationId));
                          dispatch(setDestinationStoreId(organizationClient.storeId));
                        } else {
                          dispatch(setOriginOrganizationId(organizationClient.organizationId));
                          dispatch(setOriginStoreId(organizationClient.storeId));
                          dispatch(setDestinationOrganizationId(organizationClient.relatedOrganizationId));
                          dispatch(setDestinationStoreId(organizationClient.relatedStoreId));
                        }
                      } else {
                        // El mayorista hace una órden de compra a sí mismo en nombre de un minorista
                        dispatch(setOriginOrganizationId(organization.id));
                        dispatch(setOriginStoreId(organizationClient.storeId));
                        dispatch(setDestinationOrganizationId(organizationClient.relatedOrganizationId));
                        dispatch(setDestinationStoreId(organizationClient.relatedStoreId));
                      }
                    } else if (organizationType === OrganizationType.B2C) {
                      // El usuario es B2C
                      if (relatedOrganization.type === OrganizationType.B2C) {
                        dispatch(setOrganizationClientId(id));
                        myToastr.error('No puedes hacer una órden de compra a un cliente B2C');
                      } else {
                        if (organizationClient.organizationId === organization.id) {
                          dispatch(setOriginOrganizationId(organizationClient.relatedOrganizationId));
                          dispatch(setOriginStoreId(organizationClient.relatedStoreId));
                          dispatch(setDestinationOrganizationId(organization.id));
                          dispatch(setDestinationStoreId(organizationClient.storeId));
                        } else {
                          dispatch(setOriginOrganizationId(organizationClient.organizationId));
                          dispatch(setOriginStoreId(organizationClient.storeId));
                          dispatch(setDestinationOrganizationId(organizationClient.relatedOrganizationId));
                          dispatch(setDestinationStoreId(organizationClient.storeId));
                        }
                      }
                    }
                  } else {
                    dispatch(setOriginOrganizationId(-1));
                    dispatch(setOriginStoreId(-1));
                    dispatch(setDestinationOrganizationId(-1));
                    dispatch(setDestinationStoreId(-1));
                    dispatch(removeAllProducts());
                  }
                }}
              >
                <option value={-1}>Selecciona una opción</option>
                {organizationClients.map((oc: OrganizationClient) => {
                  let name = '';
                  if (organizationType === OrganizationType.B2C) {
                    name = oc.organization.name;
                  } else {
                    if (organization.id === oc.relatedOrganizationId) {
                      name = oc.organization.name;
                    } else {
                      name = oc.relatedOrganization.name;
                    }
                  }
                  return (
                    <option key={oc.id} value={oc.id} disabled={oc.relatedStoreId === null}>
                      {name}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
          {organizationType === OrganizationType.B2C && organizationClientId > 0 && (
            <div className="col-4 d-flex flex-column">
              <label className="title-input mb-2">Tienda</label>
              <select
                disabled={purchaseOrder !== null}
                value={retailStoreId}
                onChange={(e) => {
                  const storeId: number = parseInt(e.target.value, 10);
                  dispatch(setRetailStoreId(storeId));
                  dispatch(setDestinationOrganizationId(organization.id));
                  dispatch(setDestinationStoreId(storeId));
                }}
              >
                <option value={-1}>Selecciona una opción</option>
                {stores.map((s: Store) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="col-4 d-flex flex-column align-items-start">
            <label className="title-input mb-2">Recargo de equivalencia</label>
            <input type="checkbox" value={equivalenceSurcharge ? 1 : 0} onChange={(e) => dispatch(setHasEquivalenceSurcharge(e.target.checked))} />
          </div>
        </div>
      </div>
      {showTable && (
        <React.Fragment>
          <div className="filters-bar d-flex align-items-center my-3">
            <div className="me-1 sup-label" style={{ width: '15%' }}>
              <span className="">Buscar</span>
              <input
                className="input-filter"
                type="text"
                placeholder="Buscar producto..."
                value={filters.query}
                onChange={(e: any) => setFilters({ ...filters, query: e.target.value || '', page: 1 })}
                style={{ width: '100%' }}
              />
            </div>
            <div className="ms-1 sup-label" style={{ width: '15%' }}>
              <span className="">Marcas</span>
              <Select
                isClearable
                placeholder="Marcas..."
                name="brandIds"
                options={brands.map((brand: Brand) => ({
                  label: brand.name,
                  value: brand.id,
                }))}
                value={selectedBrands}
                isMulti
                onChange={(e: any) => setFilters({ ...filters, brandIds: e.map((b: { label: string; value: number }) => b.value) })}
                styles={customStyles}
                className="select-comp"
              />
            </div>
            <div className="ms-1 sup-label" style={{ width: '15%' }}>
              <span className="">Colecciones</span>
              <Select
                isClearable
                placeholder="Colecciones..."
                name="collectionIds"
                options={collections.map((collection: Collection) => ({
                  label: collection.name,
                  value: collection.id,
                }))}
                value={selectedCollections}
                isMulti
                onChange={(e: any) => setFilters({ ...filters, collectionIds: e.map((b: { label: string; value: number }) => b.value) })}
                styles={customStyles}
                className="select-comp"
              />
            </div>
          </div>
          <table className="my-table">
            <thead>
              <tr>
                <th>
                  <div
                    className="flex flex-row clickable"
                    onClick={() => {
                      if (filters.orderField === 'product.sku') {
                        setFilters({ ...filters, order: filters.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                      } else {
                        setFilters({ ...filters, orderField: 'product.sku', order: Order.Asc, page: 1 });
                      }
                    }}
                  >
                    <span>Sku</span>
                    {filters.orderField === 'product.sku' ? (
                      <span className="ms-2">{filters.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                    ) : null}
                  </div>
                </th>
                <th>
                  <div
                    className="flex flex-row clickable"
                    onClick={() => {
                      if (filters.orderField === 'product.name') {
                        setFilters({ ...filters, order: filters.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                      } else {
                        setFilters({ ...filters, orderField: 'product.name', order: Order.Asc, page: 1 });
                      }
                    }}
                  >
                    <span>Producto</span>
                    {filters.orderField === 'product.name' ? (
                      <span className="ms-2">{filters.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                    ) : null}
                  </div>
                </th>
                <th>
                  <div
                    className="flex flex-row clickable"
                    onClick={() => {
                      if (filters.orderField === 'brand.name') {
                        setFilters({ ...filters, order: filters.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                      } else {
                        setFilters({ ...filters, orderField: 'brand.name', order: Order.Asc, page: 1 });
                      }
                    }}
                  >
                    <span>Marca</span>
                    {filters.orderField === 'brand.name' ? (
                      <span className="ms-2">{filters.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                    ) : null}
                  </div>
                </th>
                <th>
                  <div
                    className="flex flex-row clickable"
                    onClick={() => {
                      if (filters.orderField === 'collection.name') {
                        setFilters({ ...filters, order: filters.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                      } else {
                        setFilters({ ...filters, orderField: 'collection.name', order: Order.Asc, page: 1 });
                      }
                    }}
                  >
                    <span>Colección</span>
                    {filters.orderField === 'collection.name' ? (
                      <span className="ms-2">{filters.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                    ) : null}
                  </div>
                </th>
                {organization.id === originOrganizationId && (
                  <th>
                    <div
                      className="flex flex-row clickable"
                      onClick={() => {
                        if (filters.orderField === 'stock.price') {
                          setFilters({ ...filters, order: filters.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                        } else {
                          setFilters({ ...filters, orderField: 'stock.price', order: Order.Asc, page: 1 });
                        }
                      }}
                    >
                      <span>Coste unitario</span>
                      {filters.orderField === 'stock.price' ? (
                        <span className="ms-2">{filters.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                      ) : null}
                    </div>
                  </th>
                )}
                <th>
                  <div
                    className="flex flex-row clickable"
                    onClick={() => {
                      if (filters.orderField === 'stock.retailPrice') {
                        setFilters({ ...filters, order: filters.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                      } else {
                        setFilters({ ...filters, orderField: 'stock.retailPrice', order: Order.Asc, page: 1 });
                      }
                    }}
                  >
                    <span>PVM</span>
                    {filters.orderField === 'stock.retailPrice' ? (
                      <span className="ms-2">{filters.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                    ) : null}
                  </div>
                </th>
                <th>Unidades</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {paginatedDto === null || requesting ? (
                <tr>
                  <td colSpan={20} className="text-center">
                    <Loader type="TailSpin" color="#252E3C" height={25} width={25} />
                  </td>
                </tr>
              ) : paginatedDto.results.length === 0 ? (
                <tr>
                  <td colSpan={20} className="text-center">
                    No hay productos
                  </td>
                </tr>
              ) : (
                paginatedDto.results.map((stock: Stock) => {
                  let productName: string = stock.product.name;
                  if (stock.product.name.length > 30) {
                    productName = stock.product.name.substring(0, 50) + '...';
                  }
                  return (
                    <tr key={stock.id}>
                      <td>
                        <span className="fw-bold">{stock.product.sku}</span>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="me-2">
                            {stock.product.images.length > 0 ? (
                              <LightgalleryProvider>
                                {stock.product.images.map((productImage: ProductImage, index: number) => {
                                  return (
                                    <LightgalleryItem key={productImage.id} group="group-product-images" src={process.env.REACT_APP_PUBLIC_URL + productImage.path}>
                                      {index === 0 && <img src={process.env.REACT_APP_PUBLIC_URL + productImage.path} alt="" className="img-thumbnail" />}
                                    </LightgalleryItem>
                                  );
                                })}
                              </LightgalleryProvider>
                            ) : (
                              <Image size={50} className="img-thumbnail my-1 me-2" color="#D3D3D3" />
                            )}
                          </div>
                          <span className="product-name">{productName}</span>
                        </div>
                      </td>
                      <td>{stock.product.brand.name}</td>
                      <td>{stock.product.collection?.name}</td>
                      {organization.id === originOrganizationId && <td>{formatNumber(stock.price)}€</td>}
                      <td>{formatNumber(stock.retailPrice)}€</td>
                      <td>
                        <input
                          type="number"
                          min="1"
                          step="1"
                          value={productUnitsMap[stock.productId] || 1}
                          onChange={(e) => {
                            const units: number = e.target.value && !isNaN(e.target.value as any) ? parseInt(e.target.value) : 0;
                            dispatch(
                              setUnits({
                                stock,
                                units,
                              }),
                            );
                          }}
                        />
                      </td>
                      <td>
                        {productUnitsMap.hasOwnProperty(stock.productId) ? (
                          <button className="remove" onClick={() => dispatch(removeProduct(stock.productId))}>
                            Eliminar
                          </button>
                        ) : (
                          <button
                            className="add"
                            onClick={() =>
                              dispatch(
                                setUnits({
                                  stock,
                                  units: 1,
                                }),
                              )
                            }
                          >
                            Añadir
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          {paginatedDto !== null && paginatedDto.results.length > 0 && <Paginator paginatedDto={paginatedDto} goToPage={(page: number) => setFilters({ ...filters!, page })} />}
          <div className="resume-totals mt-4">
            <table className="">
              <thead>
                <tr>
                  <th>Uds. Solicitadas</th>
                  <th>Base Imponible</th>
                  <th>IVA ({formatNumber(parseFloat(process.env.REACT_APP_IVA))}%)</th>
                  <th>Rec Equi. ({formatNumber(parseFloat(process.env.REACT_APP_EQUIVALENCE_SURCHARGE))}%)</th>
                  <th>Total €</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>{totals.requestedUnits}</div>
                  </td>
                  <td>
                    <div>{formatNumber(totals.taxBase)}€</div>
                  </td>
                  <td>
                    <div>{formatNumber(totals.totalVat)}€</div>
                  </td>
                  <td>
                    <div>{formatNumber(totals.totalSurchargeEquivalence)}€</div>
                  </td>
                  <td>
                    <div className="selected">{formatNumber(totals.total)}€</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default NewPurchaseOrderView;
