import clsx from 'clsx';
import React, { useState } from 'react';
import { Balance } from '../interfaces/balance';
import { Customer } from '../interfaces/customer';
import { Store } from '../interfaces/store';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import AssignCustomerModal from './assign-customer-modal';

interface Props {
  balance: Balance | null;
  customer: Customer;
  onAssignCustomer: (customer: Customer) => void;
  onRemoveCustomer?: () => void;
}

const CustomerInfo = ({ balance, customer, onAssignCustomer, onRemoveCustomer }: Props) => {
  const store: Store = useAppSelector((state: RootState) => state.store.store!);
  const [showAssignCustomerModal, setShowAssignCustomerModal] = useState<boolean>(false);

  let name = 'Sin nombre';
  if (customer.name && customer.name.length > 0) {
    name = customer.name;
    if (customer.surnames && customer.surnames.length > 0) {
      name = `${name} ${customer.surnames}`;
    }
  } else if (customer.email && customer.email.length > 0) {
    name = customer.email;
  }

  const onCloseAssignCustomerModal = async (newCustomer: Customer | null) => {
    if (newCustomer) {
      onAssignCustomer(newCustomer);
    }
    setShowAssignCustomerModal(false);
  };

  return (
    <React.Fragment>
      <div className="pos-customer d-flex flex-column">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-row name w-100">
            <div className="flex-grow-1">
              <span>{name}</span>
            </div>
            <button
              className="change-client"
              onClick={() => {
                if (!store) {
                  myToastr.error('Selecciona una tienda');
                  return;
                }
                setShowAssignCustomerModal(true);
              }}
            >
              Cambiar cliente
            </button>
            {onRemoveCustomer && (
              <button className="change-client ms-2" onClick={onRemoveCustomer}>
                Desasignar cliente
              </button>
            )}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between details mt-2">
          <div className="">
            <span>Cta. Cliente:</span>
            <span className={clsx('ms-2', { 'positive-balance': balance && balance.money > 0, 'negative-balance': balance && balance.money < 0, 'no-balance': !balance })}>
              {balance ? formatNumber(balance.money) : 0}€
            </span>
          </div>
          <div className="">
            <span>Avisos:</span>
            <span className={clsx('value-detail ms-2', customer && customer.marketing ? 'marketing' : 'no-marketing')}>{customer?.marketing ? 'Sí' : 'No'}</span>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between details mt-2">
          <div className="">
            <span>Teléfono:</span>
            {customer.phone && customer.phone.length > 0 ? (
              <a href={`tel:${customer.phone}`} className="personal-data ms-2">
                {customer.phone}
              </a>
            ) : (
              <span className="personal-data ms-2">-</span>
            )}
          </div>
          <div className="">
            <span>Email:</span>
            {customer.email && customer.email.length > 0 ? (
              <a href={`mailto:${customer.email}`} className="personal-data ms-2">
                {customer.email}
              </a>
            ) : (
              <span className="personal-data ms-2">-</span>
            )}
          </div>
        </div>
      </div>
      {showAssignCustomerModal && <AssignCustomerModal show={showAssignCustomerModal} closeModal={onCloseAssignCustomerModal} />}
    </React.Fragment>
  );
};

export default CustomerInfo;
