import io, { Socket } from 'socket.io-client';
import { EventType } from '../enums/event-type';
import { setConnectedToWs } from '../store/auth-slice';
import { store } from '../store/store';

class Websocket {
  private socket!: Socket;

  public connect(token: string): void {
    const organizationIdStr: string | null = localStorage.getItem('organizationId');
    let organizationId: number | null = null;
    if (organizationIdStr) {
      organizationId = parseInt(organizationIdStr, 10);
      if (isNaN(organizationId)) {
        organizationId = null;
      }
    }
    this.socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
            'x-sierra-organization-id': organizationId,
          },
        },
      },
    });
    this.socket.on('connect', () => {
      console.log('Connected to websocket');
    });
    this.socket.on('disconnect', () => {
      console.log('Disconnected from websocket');
      store.dispatch(setConnectedToWs(false));
    });
    this.socket.on('connect_error', (error: any) => {
      console.log('Error connecting to websocket', error);
    });
    this.socket.on(EventType.ConnectedUser, () => {
      console.log('Connected user');
      store.dispatch(setConnectedToWs(true));
    });
  }

  get isConnected(): boolean {
    return this.socket?.connected === true;
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.close();
    }
  }

  public on(event: EventType, callback: (data: any) => void): void {
    this.socket.on(event, callback);
  }

  public emit(event: EventType, data: any): void {
    this.socket.emit(event, data);
  }

  public once(event: EventType, callback: (data: any) => void): void {
    this.socket.once(event, callback);
  }

  public off(event: EventType, callback?: (data: any) => void): void {
    this.socket.off(event, callback);
  }

  public removeListener(event: string, callback: (data: any) => void): void {
    this.socket.removeListener(event, callback);
  }

  public removeAllListeners(event: string): void {
    this.socket.removeAllListeners(event);
  }

  public listeners(event: string): Function[] {
    return this.socket.listeners(event);
  }

  public hasListeners(event: string): boolean {
    return this.socket.hasListeners(event);
  }

  public connected(): boolean {
    return this.socket.connected;
  }

  public disconnected(): boolean {
    return this.socket.disconnected;
  }
}

export const websocket: Websocket = new Websocket();
