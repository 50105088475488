import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import { AddUserToOrganizationDto } from '../interfaces/add-user-to-organization.dto';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { roleToString } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  show: boolean;
  closeModal: (added: boolean) => void;
}

const initialValues: AddUserToOrganizationDto = {
  email: null,
  organizationId: -1,
  role: null,
};

const AddUserToOrganizationModal = ({ show, closeModal }: Props) => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddUserToOrganizationDto>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!show) {
      return;
    }
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const close = () => {
    closeModal(false);
  };

  const onSubmit = async (addUserToOrganizationDto: AddUserToOrganizationDto) => {
    try {
      addUserToOrganizationDto.organizationId = organization.id;
      await api.addUserToOrganization(addUserToOrganizationDto);
      closeModal(true);
      myToastr.success('Usuario añadido a la organización');
      reset();
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form brand-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Añadir usuario</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.email })}>Email *</div>
              <input
                type="email"
                {...register('email', {
                  required: true,
                  validate: (email: string | null) => {
                    if (!email) {
                      return 'Campo obligatorio';
                    }
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                      return 'Email inválido';
                    }
                    return true;
                  },
                })}
                className={clsx({ error: errors?.email })}
                placeholder="Email"
                autoComplete="off"
              />
              {errors?.email?.type === 'required' && <div className="error-message">Campo obligatorio</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.role })}>Rol *</div>
              <select
                {...register('role', {
                  required: true,
                })}
                className={clsx({ error: errors?.role })}
              >
                <option value={''}>Selecciona una opción</option>
                {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2B) && <option value={Role.Business}>{roleToString(Role.Business)}</option>}
                {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2C) && <option value={Role.Admin}>{roleToString(Role.Admin)}</option>}
                {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2C) && <option value={Role.Manager}>{roleToString(Role.Manager)}</option>}
                {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2C) && <option value={Role.Seller}>{roleToString(Role.Seller)}</option>}
                {user.role === Role.SuperAdmin && <option value={Role.SuperAdmin}>{roleToString(Role.SuperAdmin)}</option>}
              </select>
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default AddUserToOrganizationModal;
