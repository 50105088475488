import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { DollarSign, User } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethodType } from '../enums/payment-method-type';
import { SaleStatus } from '../enums/sale-status';
import { BookingPaymentDto } from '../interfaces/booking-payment.dto';
import { PaymentMethod } from '../interfaces/payment-method';
import { SalePaymentMethod } from '../interfaces/sale-payment-method';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { saleSelector, setBookingPaymentAmount, setPaymentMethodAmount, setReturnPaymentMethodAmount } from '../store/sale-slice';
import { setStoreId, storeSelector } from '../store/store-slice';

const ReturnPaymentMethods = () => {
  const dispatch = useDispatch();
  const { total, sale, balance, bookingPayments, pending, paymentMethods, returnPaymentMethods } = useSelector(saleSelector);
  const { store } = useSelector(storeSelector);

  const paymentMethodsReturn: PaymentMethod[] = useMemo(() => {
    if (!sale) {
      return [];
    }
    if (sale.onlineSaleId) {
      return sale.salePaymentMethods.map((salePaymentMethod: SalePaymentMethod) => salePaymentMethod.paymentMethod);
    } else {
      if (!store) {
        return [];
      }
      return store.paymentMethods.filter((paymentMethod: PaymentMethod) => paymentMethod.externalId === null && paymentMethod.availableInPos && !paymentMethod.deleted);
    }
  }, [store, sale]);

  useEffect(() => {
    if (!store && sale) {
      if (sale.status === SaleStatus.Reservation) {
        dispatch(setStoreId(sale.storeId));
      } else if (sale.status === SaleStatus.Finalized && !sale.onlineSaleId) {
        myToastr.error('Selecciona una tienda para realizar la devolución');
      }
    }
  }, [store, sale, dispatch]);

  const completeReturnPaymentMethod = (paymentMethod: PaymentMethod) => {
    dispatch(
      setReturnPaymentMethodAmount({
        paymentMethodId: paymentMethod.id,
        amount: pending,
      }),
    );
  };

  const completePaymentMethod = (paymentMethod: PaymentMethod) => {
    if (sale!.status === SaleStatus.Pending) {
      dispatch(
        setPaymentMethodAmount({
          paymentMethodId: paymentMethod.id,
          amount: pending,
        }),
      );
    } else if (sale!.status === SaleStatus.Reservation) {
      dispatch(
        setBookingPaymentAmount({
          paymentMethodId: paymentMethod.id,
          amount: pending,
        }),
      );
    }
  };

  if (!sale) {
    return null;
  }

  return (
    <React.Fragment>
      {total < 0 ? (
        paymentMethodsReturn.map((paymentMethod: PaymentMethod) => {
          return (
            <div
              key={paymentMethod.id}
              className={clsx('mb-3 payment-method', {
                paytpv: paymentMethod.isTpv,
                paybalance: paymentMethod.type === PaymentMethodType.Balance,
                paycash: paymentMethod.type === PaymentMethodType.Cash,
                paycustom: paymentMethod.type === PaymentMethodType.Custom,
                payimports: paymentMethod.type === PaymentMethodType.Imports,
              })}
            >
              <div className="name d-flex align-items-center">
                {paymentMethod.type === PaymentMethodType.Balance && <User className="me-2" size={14} />}
                <div className="d-flex flex-row position-relative flex-grow-1">
                  <span>Devolución: {paymentMethod.name}</span>
                </div>
                {pending > 0 && !returnPaymentMethods[paymentMethod.id] && (
                  <div onClick={() => completeReturnPaymentMethod(paymentMethod)} className="cursor-pointer me-2 mb-1" title="Completar pago">
                    <DollarSign size={14} />
                  </div>
                )}
              </div>
              <div className="container-amount">
                <input
                  type="number"
                  step="0.01"
                  placeholder="0.00€"
                  value={returnPaymentMethods[paymentMethod.id] || undefined}
                  min={0}
                  onChange={(e: any) => {
                    let amount: number | null = null;
                    if (e.target.value !== '') {
                      amount = parseFloat(e.target.value);
                      if (amount > -total) {
                        amount = -total;
                      }
                    }
                    dispatch(
                      setReturnPaymentMethodAmount({
                        paymentMethodId: paymentMethod.id,
                        amount,
                      }),
                    );
                  }}
                />
              </div>
            </div>
          );
        })
      ) : (
        <React.Fragment>
          {store !== null &&
            store.paymentMethods.map((paymentMethod: PaymentMethod) => {
              if (paymentMethod.deleted) {
                return false;
              }
              if (paymentMethod.online) {
                return null;
              }
              if (!paymentMethod.availableInPos) {
                return null;
              }
              if (paymentMethod.type === PaymentMethodType.Balance && !balance) {
                return null;
              }
              const bookingPaymentDto: BookingPaymentDto | undefined =
                sale.status === SaleStatus.Reservation ? bookingPayments.find((bookingPayment: BookingPaymentDto) => bookingPayment.paymentMethodId === paymentMethod.id) : undefined;
              return (
                <div
                  key={paymentMethod.id}
                  className={clsx('mb-3 payment-method', {
                    paytpv: paymentMethod.isTpv,
                    paybalance: paymentMethod.type === PaymentMethodType.Balance,
                    paycash: paymentMethod.type === PaymentMethodType.Cash,
                    paycustom: paymentMethod.type === PaymentMethodType.Custom,
                    payimports: paymentMethod.type === PaymentMethodType.Imports,
                  })}
                >
                  <div className="name d-flex align-items-center">
                    {paymentMethod.type === PaymentMethodType.Balance && <User className="me-2" size={14} />}
                    <div className="d-flex flex-row position-relative flex-grow-1">
                      <span>{paymentMethod.isTpv ? `TPV - ${paymentMethod.name}` : paymentMethod.name}</span>
                    </div>
                    {paymentMethod.type === PaymentMethodType.Balance && <span className="me-2">{balance ? formatNumber(balance.money) : 0}€</span>}
                    {pending > 0 &&
                      ((sale.status === SaleStatus.Pending && !paymentMethods[paymentMethod.id]) || (sale.status === SaleStatus.Reservation && !bookingPaymentDto)) &&
                      (paymentMethod.type !== PaymentMethodType.Balance || (balance !== null && balance.money >= pending)) && (
                        <div onClick={() => completePaymentMethod(paymentMethod)} className="cursor-pointer me-2 mb-1" title="Completar pago">
                          <DollarSign size={14} />
                        </div>
                      )}
                  </div>
                  <div className="container-amount">
                    <input
                      type="number"
                      step="0.01"
                      placeholder="0.00€"
                      value={paymentMethods[paymentMethod.id] || bookingPaymentDto?.amount || undefined}
                      min={0}
                      max={paymentMethod.type === PaymentMethodType.Balance ? balance!.money : ''}
                      onChange={(e: any) => {
                        let amount: number | null = null;
                        if (e.target.value !== '') {
                          amount = Math.max(0, parseFloat(e.target.value));
                          if (paymentMethod.type === PaymentMethodType.Balance && amount > balance!.money) {
                            amount = balance!.money;
                          } else if (paymentMethod.type === PaymentMethodType.Cash && amount > 1000) {
                            amount = 1000;
                          }
                        }
                        if (sale.status === SaleStatus.Finalized) {
                          dispatch(
                            setPaymentMethodAmount({
                              paymentMethodId: paymentMethod.id,
                              amount,
                            }),
                          );
                        } else if (sale.status === SaleStatus.Reservation) {
                          dispatch(
                            setBookingPaymentAmount({
                              paymentMethodId: paymentMethod.id,
                              amount,
                            }),
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ReturnPaymentMethods;
