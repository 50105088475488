import axios, { AxiosError } from 'axios';
import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { Download, Edit, Plus, Trash2, Upload } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfirmModal from '../components/confirm-modal';
import CustomerModal from '../components/customer-modal';
import ImportCsvModal from '../components/import-csv-modal';
import NoStoresAssigned from '../components/no-stores-assigned';
import Paginator from '../components/paginator';
import { Entity } from '../enums/entity';
import { Order } from '../enums/order';
import { Role } from '../enums/role';
import { useStores } from '../hooks/use-stores.hook';
import { Customer } from '../interfaces/customer';
import { ExportCustomersQuery } from '../interfaces/export-customers-query';
import { GetPaginatedCustomers } from '../interfaces/get-paginated-customers';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { PaginatedDto } from '../interfaces/paginated.dto';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { formatNumber } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

const getData = async (getPaginatedCustomers: GetPaginatedCustomers, cb: (result: PaginatedDto<Customer>) => void) => {
  const data: PaginatedDto<Customer> = await api.getPaginatedCustomers(getPaginatedCustomers);
  cb(data);
};

const debouncedGetData = debounce((getPaginatedCustomers: GetPaginatedCustomers, cb: (result: PaginatedDto<Customer>) => void) => {
  getData(getPaginatedCustomers, cb);
}, 750);

const CustomersView = () => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const { store } = useSelector(storeSelector);
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [requestingCsv, setRequestingCsv] = useState<boolean>(false);
  const [paginatedDto, setPaginatedDto] = useState<PaginatedDto<Customer> | null>(null);
  const [getPaginatedCustomers, setGetPaginatedCustomers] = useState<GetPaginatedCustomers | null>(null);
  const [showImportCsvModal, setShowImportCsvModal] = useState<boolean>(false);
  const selectedCustomerName: string = useMemo(() => {
    if (!selectedCustomer) {
      return '';
    }
    let value = '';
    if (selectedCustomer.name) {
      value += selectedCustomer.name;
    }
    if (selectedCustomer.surnames) {
      value += ` ${selectedCustomer.surnames}`;
    }
    if (!value) {
      value = `${selectedCustomer.id}`;
    }
    return value;
  }, [selectedCustomer]);
  const stores: Store[] = useStores();

  useEffect(() => {
    setGetPaginatedCustomers({
      organizationId: organization!.id,
      storeId: -1,
      page: 1,
      limit: Constants.LIMIT_RESULTS,
      orderField: 'name',
      order: Order.Asc,
      query: '',
      hasEmail: '',
    });
  }, [organization]);

  useEffect(() => {
    if (!getPaginatedCustomers) {
      return;
    }
    setRequesting(true);
    debouncedGetData(getPaginatedCustomers, (result: PaginatedDto<Customer>) => {
      setPaginatedDto(result);
      setRequesting(false);
    });
  }, [getPaginatedCustomers]);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Id',
        accessor: 'id',
        width: '60px',
        Cell: ({ row }: any) => {
          const customer: Customer = row.original;
          return (
            <Link to={`/cliente/${customer.id}`} className="link-sale">
              <span className="fw-bold">{customer.id}</span>
            </Link>
          );
        },
      },
      {
        Header: 'Nombre',
        id: 'customer',
        accessor: (customer: Customer) => {
          let value = '';
          if (customer.name) {
            value += customer.name;
          }
          if (customer.surnames) {
            value += ` ${customer.surnames}`;
          }
          return value;
        },
        Cell: ({ value, row }: any) => <span className="fw-bold">{value.length === 0 ? 'Sin nombre' : `${value}`}</span>,
        filter: 'fuzzyText',
      },
      {
        Header: 'Email',
        accessor: 'email',
        filter: 'fuzzyText',
      },
      {
        Header: 'Teléfono',
        accessor: 'phone',
        filter: 'fuzzyText',
      },
      {
        Header: 'Tel. Secundario',
        accessor: 'secondaryPhone',
        filter: 'fuzzyText',
      },
      {
        Header: 'Datos personales',
        accessor: 'acceptPrivacy',
        Cell: ({ value }: any) => {
          return <span className={`badge ${value ? 'my-success' : 'my-danger'}`}>{value ? 'Sí' : 'No'}</span>;
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Marketing',
        accessor: 'marketing',
        Cell: ({ value }: any) => {
          return <span className={`badge ${value ? 'my-success' : 'my-danger'}`}>{value ? 'Sí' : 'No'}</span>;
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Saldo',
        accessor: 'balance.money',
        Cell: ({ row }: any) => {
          const customer: Customer = row.original;
          return <span className="fw-bold">{formatNumber(customer.balance?.money || 0)}€</span>;
        },
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                className="mx-2"
                size={16}
                color="#222E3D"
                onClick={() => {
                  setSelectedCustomer(row.original);
                  setShowCustomerModal(true);
                }}
                type="button"
              />
              {user.role === Role.SuperAdmin && (
                <Trash2
                  type="button"
                  className="mx-2"
                  onClick={() => {
                    setSelectedCustomer(row.original);
                    setShowConfirmModal(true);
                  }}
                  size={14}
                />
              )}
            </React.Fragment>
          );
        },
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      columnsData.splice(5, 0, {
        Header: 'Tienda',
        accessor: 'store.name',
      });
      columnsData.splice(6, 0, {
        Header: 'Usuario',
        accessor: 'user.name',
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newCustomer = () => {
    setSelectedCustomer(null);
    setShowCustomerModal(true);
  };

  const refreshData = () => {
    setRequesting(true);
    debouncedGetData(getPaginatedCustomers!, (result: PaginatedDto<Customer>) => {
      setPaginatedDto(result);
      setRequesting(false);
    });
  };

  const onCloseCustomerModal = (customer: Customer | null) => {
    setShowCustomerModal(false);
    setSelectedCustomer(null);
    if (customer) {
      refreshData();
    }
  };

  const deleteCustomer = async (c: Customer) => {
    try {
      await api.deleteCustomer(c.id);
      refreshData();
      myToastr.success('Cliente eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.dta.message);
    }
  };

  const downloadCsv = async () => {
    if (requestingCsv) {
      myToastr.warning('Espere por favor, se están descargando los datos');
      return;
    }
    myToastr.success('Descargando los datos de los clientes');
    try {
      setRequestingCsv(true);
      const exportCustomersQuery: ExportCustomersQuery = {
        orderField: getPaginatedCustomers!.orderField,
        order: getPaginatedCustomers!.order,
        query: getPaginatedCustomers!.query,
        organizationId: getPaginatedCustomers!.organizationId,
        storeId: getPaginatedCustomers!.storeId,
        hasEmail: getPaginatedCustomers!.hasEmail,
      };
      const content: string = await api.exportCustomers(exportCustomersQuery);
      const blob: Blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
      const url: string = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', 'clientes.csv');
      a.click();
      URL.revokeObjectURL(url);
      a.remove();
      myToastr.success('Datos de clientes descargados');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    } finally {
      setRequestingCsv(false);
    }
  };

  const onCloseImportCsvModal = (result: boolean) => {
    setShowImportCsvModal(false);
    if (result) {
      setGetPaginatedCustomers({
        ...getPaginatedCustomers!,
        page: 1,
      });
    }
  };

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (!getPaginatedCustomers) {
    return null;
  }

  return (
    <div className="customers p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Clientes</h1>
        {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
          <div
            className="d-flex align-items-center export-csv cursor-pointer"
            onClick={() => {
              if (!store) {
                myToastr.error(`Selecciona una tienda`);
                return;
              }
              setShowImportCsvModal(true);
            }}
            title="Importar clientes"
          >
            <Upload className="me-1" size={14} /> Importar
          </div>
        )}
        {paginatedDto !== null && paginatedDto.results.length > 0 && (
          <div className="d-flex align-items-center export-csv cursor-pointer" onClick={downloadCsv} title="Exportar clientes">
            <Download className="me-1" size={14} /> Exportar
          </div>
        )}
        <button className="d-flex align-items-center create-button ms-2" onClick={newCustomer}>
          <Plus className="me-1" size={14} /> Nuevo cliente
        </button>
        <button
          className="ms-2 clear-filters"
          disabled={requesting}
          onClick={() => {
            setGetPaginatedCustomers({
              organizationId: organization!.id,
              storeId: -1,
              page: 1,
              limit: Constants.LIMIT_RESULTS,
              orderField: 'name',
              order: Order.Asc,
              query: '',
              hasEmail: '',
            });
          }}
        >
          Limpiar filtros
        </button>
      </div>
      <div className="d-flex flex-row mt-2 mb-4 gap-3">
        <input
          className="input-paginated-table"
          style={{ width: '50%' }}
          type="text"
          placeholder="Buscar cliente"
          value={getPaginatedCustomers.query || ''}
          onChange={(e) =>
            setGetPaginatedCustomers({
              ...getPaginatedCustomers,
              page: 1,
              query: e.target.value || '',
            })
          }
        />
        <select
          style={{ width: '220px' }}
          value={getPaginatedCustomers.storeId}
          onChange={(e) =>
            setGetPaginatedCustomers({
              ...getPaginatedCustomers,
              page: 1,
              storeId: parseInt(e.target.value, 10),
            })
          }
        >
          <option value={-1}>Todas las tiendas</option>
          {user.stores.map((s: Store) => (
            <option value={s.id} key={s.id}>
              {s.name}
            </option>
          ))}
        </select>
        <select
          style={{ width: '150px' }}
          value={getPaginatedCustomers.hasEmail.toString()}
          onChange={(e) =>
            setGetPaginatedCustomers({
              ...getPaginatedCustomers,
              page: 1,
              hasEmail: e.target.value === '' ? '' : e.target.value === 'true',
            })
          }
        >
          <option value="">Todos</option>
          <option value="true">Con email</option>
          <option value="false">Sin email</option>
        </select>
        {paginatedDto !== null && (
          <div className="ms-2">
            <span className="num-customers">Clientes: {formatNumber(paginatedDto.totalItems, true)}</span>
          </div>
        )}
      </div>
      {requesting ? (
        <div className="loader">
          <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
        </div>
      ) : (
        paginatedDto !== null && (
          <React.Fragment>
            <div className="table-container">
              <table className="my-table">
                <thead>
                  <tr>
                    {columns.map((column: any) => (
                      <th key={column.Header}>{column.Header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedDto.results.length === 0 ? (
                    <tr className="text-center">
                      <td colSpan={columns.length}>No hay clientes</td>
                    </tr>
                  ) : (
                    paginatedDto.results.map((customer: Customer) => {
                      let customerName = '';
                      if (customer.name) {
                        customerName += customer.name;
                      }
                      if (customer.surnames) {
                        customerName += ` ${customer.surnames}`;
                      }
                      return (
                        <tr key={customer.id}>
                          <td>
                            <Link to={`/cliente/${customer.id}`} className="link-sale">
                              <span className="fw-bold">{customer.id}</span>
                            </Link>
                          </td>
                          <td>{customerName}</td>
                          <td>{customer.email}</td>
                          <td>{customer.phone}</td>
                          <td>{customer.secondaryPhone}</td>
                          {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && <td>{customer?.store?.name}</td>}
                          {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                            <td>
                              {customer?.user?.name} {customer?.user?.surnames}
                            </td>
                          )}
                          <td>
                            <span className={`badge ${customer.acceptPrivacy ? 'my-success' : 'my-danger'}`}>{customer.acceptPrivacy ? 'Sí' : 'No'}</span>
                          </td>
                          <td>
                            <span className={`badge ${customer.marketing ? 'my-success' : 'my-danger'}`}>{customer.marketing ? 'Sí' : 'No'}</span>
                          </td>
                          <td>
                            <span className="fw-bold">{formatNumber(customer.balance?.money || 0)}€</span>
                          </td>
                          <td>
                            <Edit
                              className="mx-2"
                              size={16}
                              color="#222E3D"
                              onClick={() => {
                                setSelectedCustomer(customer);
                                setShowCustomerModal(true);
                              }}
                              type="button"
                            />
                            {user.role === Role.SuperAdmin && (
                              <Trash2
                                type="button"
                                className="mx-2"
                                onClick={() => {
                                  setSelectedCustomer(customer);
                                  setShowConfirmModal(true);
                                }}
                                size={14}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <Paginator
              paginatedDto={paginatedDto}
              goToPage={(page: number) =>
                setGetPaginatedCustomers({
                  ...getPaginatedCustomers,
                  page,
                })
              }
            />
          </React.Fragment>
        )
      )}
      <CustomerModal show={showCustomerModal} closeModal={onCloseCustomerModal} customer={selectedCustomer} billingDataRequired={false} />
      {selectedCustomer && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Cliente"
          content={`¿Estás seguro que quieres eliminar el cliente ${selectedCustomerName}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteCustomer(selectedCustomer);
            }
            setSelectedCustomer(null);
          }}
        ></ConfirmModal>
      )}
      <ImportCsvModal show={showImportCsvModal} closeModal={onCloseImportCsvModal} entity={Entity.Customer} />
    </div>
  );
};

export default CustomersView;
