import clsx from 'clsx';
import Fuse from 'fuse.js';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight, Image, Plus, Search } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import NoteModal from '../components/note-modal';
import { ProductTransferStatus } from '../enums/product-transfer-status';
import { Role } from '../enums/role';
import { TransferStatus } from '../enums/transfer-status';
import { TransferVerificationType } from '../enums/transfer-verification-type';
import { Category } from '../interfaces/category';
import { GetProductsTags } from '../interfaces/get-product-tags';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { ProductTransfer } from '../interfaces/product-transfer';
import { Transfer } from '../interfaces/transfer';
import { User } from '../interfaces/user';
import { VerifyProductTransferDto } from '../interfaces/verify-product-transfer.dto';
import { api } from '../services/api';
import { downloadPdf } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const DetailTransferView = () => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const navigate = useNavigate();
  const params = useParams();
  const tableProducts = useRef(null);
  const [transfer, setTransfer] = useState<Transfer | null>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedBrandId, setSelectedBrandId] = useState<number>(-1);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1);
  const [selectedStatus, setSelectedStatus] = useState<ProductTransferStatus | ''>('');
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [filteredProductTransfers, setFilteredProductTransfers] = useState<ProductTransfer[]>([]);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [adjustments, setAdjustments] = useState<{ [productId: number]: TransferVerificationType | '' }>({});
  const [numIncidences, setNumIncidences] = useState<number>(0);
  const [numIncidencesFixed, setNumIncidencesFixed] = useState<number>(0);
  const [generatingLabels, setGeneratingLabels] = useState<boolean>(false);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const brands: { id: number; name: string }[] = useMemo(() => {
    let brs: { id: number; name: string }[] = [];
    if (transfer) {
      transfer.transferredProducts.forEach((productTransfer: ProductTransfer) => {
        const index: number = brs.findIndex((b: { id: number; name: string }) => b.id === productTransfer.product.brand.id);
        if (index === -1) {
          brs.push({ id: productTransfer.product.brand.id, name: productTransfer.product.brand.name });
        }
      });
    }
    return brs;
  }, [transfer]);
  const categories: { id: number; name: string }[] = useMemo(() => {
    let crs: { id: number; name: string }[] = [];
    if (transfer) {
      transfer.transferredProducts.forEach((productTransfer: ProductTransfer) => {
        productTransfer.product.categories.forEach((category: Category) => {
          const index: number = crs.findIndex((c: { id: number; name: string }) => c.id === category.id);
          if (index === -1) {
            crs.push({ id: category.id, name: category.name });
          }
        });
      });
    }
    return crs;
  }, [transfer]);
  const disabledButton: boolean = useMemo(() => {
    if (transfer) {
      for (const transferredProduct of transfer.transferredProducts) {
        if (transferredProduct.status === ProductTransferStatus.Completed) {
          continue;
        }
        if (!adjustments.hasOwnProperty(transferredProduct.productId)) {
          return true;
        } else if (adjustments[transferredProduct.productId] === '') {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }, [adjustments, transfer]);

  useEffect(() => {
    if (params?.id) {
      const getTransfer = async () => {
        try {
          const t: Transfer = await api.getTransfer(parseInt(params.id!, 10));
          if (t.status === TransferStatus.Draft) {
            navigate(`/traspaso/${t.id}/borrador`, { replace: true });
            return;
          }
          if (t.status === TransferStatus.Pending) {
            if (moment(t.shippingDate).isAfter(moment())) {
              navigate(`/traspaso/${t.id}/pendiente`, { replace: true });
              return;
            } else {
              navigate(`/traspaso/${t.id}/verificar`, { replace: true });
              return;
            }
          }
          if (t.status === TransferStatus.Incidence && user.role === Role.Seller) {
            navigate(`/traspasos`, { replace: true });
            return;
          }
          const incidences: number = t.transferredProducts.filter((p: ProductTransfer) => p.unitsShipped !== p.unitsReceived).length;
          if (t.status === TransferStatus.Resolved) {
            const numIncFixed: number = t.transferredProducts.filter((p: ProductTransfer) => p.status === ProductTransferStatus.Resolved).length;
            setNumIncidencesFixed(numIncFixed);
          }
          setNumIncidences(incidences);
          setTransfer(t);
        } catch (e: any) {
          myToastr.error(e.response.data.message);
          navigate('/traspasos', { replace: true });
        }
      };
      getTransfer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!transfer) {
      return;
    }
    let filteredStock: ProductTransfer[] = [...transfer.transferredProducts];
    if (searchText.length > 0) {
      const fuse = new Fuse(filteredStock, {
        keys: ['product.name', 'product.sku', 'product.ean'],
      });
      const fuseResult: Fuse.FuseResult<ProductTransfer>[] = fuse.search(searchText);
      filteredStock = fuseResult.map((result: Fuse.FuseResult<ProductTransfer>) => result.item);
    }
    // Marca
    if (selectedBrandId > 0) {
      filteredStock = filteredStock.filter((s: ProductTransfer) => s.product.brandId === selectedBrandId);
    }
    // Categoría
    if (selectedCategoryId > 0) {
      filteredStock = filteredStock.filter((s: ProductTransfer) => {
        const category = s.product.categories.find((c: Category) => c.id === selectedCategoryId);
        return category !== undefined;
      });
    }
    // Estado
    if (selectedStatus !== '') {
      filteredStock = filteredStock.filter((s: ProductTransfer) => s.status === selectedStatus);
    }
    setFilteredProductTransfers(filteredStock);
  }, [searchText, selectedBrandId, selectedCategoryId, selectedStatus, transfer]);

  if (!transfer) {
    return null;
  }

  const updateProductAdjustment = (productId: number, type: TransferVerificationType | '') => {
    const newAdjustments: { [productId: number]: TransferVerificationType | '' } = { ...adjustments };
    newAdjustments[productId] = type;
    let numIncFixed = 0;
    for (const productId in newAdjustments) {
      if (newAdjustments.hasOwnProperty(productId) && newAdjustments[productId] !== '') {
        numIncFixed += 1;
      }
    }
    setAdjustments(newAdjustments);
    setNumIncidencesFixed(numIncFixed);
  };

  const fixTransfer = async () => {
    try {
      setRequesting(true);
      const verifyProductsTransferDtos: VerifyProductTransferDto[] = [];
      for (const productId in adjustments) {
        if (adjustments.hasOwnProperty(productId) && adjustments[productId] !== '') {
          verifyProductsTransferDtos.push({ productId: parseInt(productId, 10), type: adjustments[productId] as TransferVerificationType });
        }
      }
      await api.fixTransfer(transfer.id, verifyProductsTransferDtos);
      navigate('/traspasos', { replace: true });
    } catch (e: any) {
      myToastr.error(e.response.data.message);
      setRequesting(false);
    }
  };

  const verificationTypeToString = (type: TransferVerificationType): string => {
    switch (type) {
      case TransferVerificationType.ProductStoreOrigin:
        return 'Producto tienda origen';
      case TransferVerificationType.ProductStoreDestination:
        return 'Producto tienda destino';
      case TransferVerificationType.LostProduct:
        return 'Producto extraviado';
      default:
        return '';
    }
  };

  const getProductsLabels = async () => {
    if (generatingLabels) {
      return;
    }
    try {
      myToastr.info('Generando etiquetas. Este proceso puede llevar segundos. Espere por favor...');
      setGeneratingLabels(true);
      const getProductsTags: GetProductsTags = {
        organizationId: organization!.id,
        storeIds: [transfer.toStoreId],
        products: transfer.transferredProducts.map((productTransfer: ProductTransfer) => {
          return {
            productId: productTransfer.productId,
            quantity: productTransfer.verificationType === TransferVerificationType.ProductStoreDestination ? productTransfer.unitsShipped : productTransfer.unitsReceived,
          };
        }),
      };
      const result: ArrayBuffer = await api.getProductsLabels(getProductsTags);
      downloadPdf(result, `etiquetas-traspaso-${transfer.internalId}.pdf`);
    } catch (e) {
      myToastr.error('Hubo un error obteniendo la etiqueta del producto');
    } finally {
      setGeneratingLabels(false);
    }
  };

  const onCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  const onSaveNotes = async (notes: string | null) => {
    try {
      const t: Transfer = await api.updateTransferNotes(transfer.id, notes);
      setTransfer(t);
      setShowNoteModal(false);
      myToastr.info('Notas guardadas');
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

  return (
    <div className="verify-transfer-view position-relative p-4">
      <div className="d-flex flex-column h-100">
        <div className="d-flex flex-row align-items-center mb-2">
          <Link to="/traspasos" className="d-flex flex-row align-items-center come-back me-2">
            <ChevronLeft className="me-1" size={14} />
            <span>Volver</span>
          </Link>
          <h1 className="title mb-0 flex-grow-1">Traspaso {transfer.internalId}</h1>
          <div
            onClick={() => setShowNoteModal(true)}
            className={clsx('d-flex align-items-center button-header me-3', { 'has-notes': transfer.notes != null && transfer.notes !== '' })}
            style={{ fontSize: '14px', padding: '7px 10px' }}
          >
            {transfer.notes ? (
              '1 Nota'
            ) : (
              <React.Fragment>
                <Plus className="me-1" size={14} />
                <span>Nota</span>
              </React.Fragment>
            )}
          </div>
          <button className="create-button create-expiration me-2" disabled={generatingLabels} onClick={getProductsLabels}>
            Descargar etiquetas
          </button>
          <ReactToPrint
            trigger={() => (
              <div className="create-button create-expiration d-flex align-items-center cursor-pointer">
                <span>Imprimir</span>
              </div>
            )}
            content={() => tableProducts.current}
          />
        </div>
        <div className="d-flex flex-row align-items-center container-info my-2">
          <div className="date">{moment(transfer.createdAt).format('DD/MM/YYYY HH:mm')}</div>
          <div className="store">
            <span>{transfer.fromStore.name}</span>
            <ChevronRight size={14} className="mx-1" color="#26c44b" />
            <span>{transfer.toStore.name}</span>
          </div>
          <div className="user">
            <strong>Creado por:</strong>
            <span className="ms-1">
              {transfer.user.name} {transfer.user.surnames}
            </span>
          </div>
          <div className="user">
            <strong>Verificado por:</strong>
            <span className="ms-1">
              {transfer.verifyingUser.name} {transfer.verifyingUser.surnames}
            </span>
          </div>
          <div className="incidence">
            <strong>Incidencias:</strong>
            <span className="ms-1">{numIncidences}</span>
          </div>
          <div className="fixed-incidence">
            <strong>Incidencias corregidas:</strong>
            <span className="ms-1">{numIncidencesFixed}</span>
          </div>
        </div>
        <div className="container-filters d-flex flex-row my-2">
          <input type="text" placeholder="Buscar" className="me-1" onChange={(e: any) => setSearchText(e.target.value)} />
          <select name="brand" className="mx-1" onChange={(e: any) => setSelectedBrandId(parseInt(e.target.value, 10))}>
            <option value={-1}>Todas las Marca</option>
            {brands.map((b: { id: number; name: string }) => (
              <option key={b.id} value={b.id}>
                {b.name}
              </option>
            ))}
          </select>
          <select name="category" className="mx-1" onChange={(e: any) => setSelectedCategoryId(parseInt(e.target.value, 10))}>
            <option value={-1}>Todas las Categorías</option>
            {categories.map((c: { id: number; name: string }) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
          <select name="status" className="mx-1" onChange={(e: any) => setSelectedStatus(e.target.value)}>
            <option value="">Todos los estados</option>
            <option value={ProductTransferStatus.Completed}>Completado</option>
            <option value={ProductTransferStatus.Incidence}>Incidencia</option>
            <option value={ProductTransferStatus.Resolved}>Resuelto</option>
          </select>
        </div>
        <div className="container-table my-2">
          <table>
            <thead>
              <tr>
                <th className="text-start">Producto</th>
                <th className="text-center">SKU</th>
                <th className="text-center">Marca</th>
                <th className="text-center">Categorías</th>
                <th className="text-center">Uds. Env.</th>
                <th className="text-center">Uds. Rec.</th>
                <th className="text-center">Estado</th>
                {transfer.status === TransferStatus.Incidence && <th className="text-center">Incidencias</th>}
                {transfer.status === TransferStatus.Resolved && <th className="text-center">Tipo de verificación</th>}
              </tr>
            </thead>
            <tbody>
              {filteredProductTransfers.length === 0 ? (
                <tr className="text-center">
                  <td colSpan={transfer.status === TransferStatus.Incidence ? 8 : 7}>No hay productos disponibles con los filtros seleccionados</td>
                </tr>
              ) : (
                filteredProductTransfers.map((s: ProductTransfer) => {
                  let image: string | null = null;
                  if (s.product.images.length > 0) {
                    image = s.product.images[0].path;
                  }
                  let iconClass = 'incidence-icon';
                  let iconTitle = 'Incidencia';
                  if (s.status === ProductTransferStatus.Completed) {
                    iconClass = 'same-quantity-icon';
                    iconTitle = 'Completado';
                  } else if (
                    s.status === ProductTransferStatus.Resolved ||
                    (s.status === ProductTransferStatus.Incidence && adjustments.hasOwnProperty(s.productId) && adjustments[s.productId] !== '')
                  ) {
                    iconClass = 'fixed-icon';
                    iconTitle = 'Resuelto';
                  }
                  return (
                    <tr key={s.productId}>
                      <td className="text-start">
                        <div className="d-flex align-items-center container-product">
                          {s.product.images.length > 0 ? (
                            <>
                              <div
                                style={{ cursor: 'zoom-in' }}
                                onMouseEnter={() => {
                                  setImageUrl(image);
                                }}
                                onMouseLeave={() => {
                                  setImageUrl(null);
                                }}
                              >
                                <Search className="me-3" size={16} color="#808A95" />
                              </div>
                              <LazyLoadImage className="me-3" src={process.env.REACT_APP_PUBLIC_URL + s.product.images[0].path} alt={s.product.name} />
                            </>
                          ) : (
                            <Image className="me-3" size={26} color="#808A95" />
                          )}
                          <Link to={`/producto/${s.productId}`} className="link-sale">
                            {s.product.name}
                          </Link>
                        </div>
                      </td>
                      <td className="text-center">{s.product.sku}</td>
                      <td className="text-center">{s.product.brand.name}</td>
                      <td className="text-center">{s.product.categories.map((c: Category) => c.name).join(', ')}</td>
                      <td className="text-center">{s.unitsShipped}</td>
                      <td className="text-center">{s.unitsReceived}</td>
                      <td className="text-center">
                        <div className="d-flex justify-content-center">
                          <div className={clsx('transfer-product-status-icon', iconClass)} title={iconTitle}></div>
                        </div>
                      </td>
                      {transfer.status === TransferStatus.Incidence && (
                        <td className="text-center">
                          {s.status !== ProductTransferStatus.Completed && (
                            <select name="product-transfer-status" onChange={(e: any) => updateProductAdjustment(s.productId, e.target.value)}>
                              <option value="">Corregir</option>
                              <option value={TransferVerificationType.ProductStoreOrigin}>{verificationTypeToString(TransferVerificationType.ProductStoreOrigin)}</option>
                              <option value={TransferVerificationType.ProductStoreDestination}>{verificationTypeToString(TransferVerificationType.ProductStoreDestination)}</option>
                              {s.unitsReceived < s.unitsShipped && <option value={TransferVerificationType.LostProduct}>{verificationTypeToString(TransferVerificationType.LostProduct)}</option>}
                            </select>
                          )}
                        </td>
                      )}
                      {transfer.status === TransferStatus.Resolved && (
                        <td className="text-center">{s.status === ProductTransferStatus.Resolved && <span>{verificationTypeToString(s.verificationType)}</span>}</td>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        {(transfer.status === TransferStatus.Completed || transfer.status === TransferStatus.Resolved) && (
          <div ref={tableProducts} className="print-source p-4">
            <table style={{ fontSize: 12 }}>
              <thead>
                <tr>
                  <th className="text-start">Producto</th>
                  <th className="text-center">SKU</th>
                  <th className="text-center">Marca</th>
                  <th className="text-center">Categorías</th>
                  <th className="text-center">Uds. Env.</th>
                  <th className="text-center">Uds. Rec.</th>
                  <th className="text-center">Estado</th>
                </tr>
              </thead>
              <tbody>
                {filteredProductTransfers.map((s: ProductTransfer) => {
                  let iconTitle = 'Incidencia';
                  if (s.status === ProductTransferStatus.Completed) {
                    iconTitle = 'Completado';
                  } else if (
                    s.status === ProductTransferStatus.Resolved ||
                    (s.status === ProductTransferStatus.Incidence && adjustments.hasOwnProperty(s.productId) && adjustments[s.productId] !== '')
                  ) {
                    iconTitle = 'Resuelto';
                  }
                  return (
                    <tr key={s.productId}>
                      <td className="text-start">{s.product.name}</td>
                      <td className="text-center">{s.product.sku}</td>
                      <td className="text-center">{s.product.brand.name}</td>
                      <td className="text-center">{s.product.categories.map((c: Category) => c.name).join(', ')}</td>
                      <td className="text-center">{s.unitsShipped}</td>
                      <td className="text-center">{s.unitsReceived}</td>
                      <td className="text-center">{iconTitle}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {transfer.status === TransferStatus.Incidence && (
          <div className="mt-auto container-buttons">
            <div className="d-flex justify-content-end">
              <button disabled={requesting || disabledButton} onClick={fixTransfer} className="finalize-button ms-auto">
                Corregir traspaso
              </button>
            </div>
          </div>
        )}
      </div>
      {imageUrl != null && (
        <div className="position-absolute top-50 start-50 translate-middle preview-image">
          <div className="d-flex justify-content-center">
            <LazyLoadImage src={process.env.REACT_APP_PUBLIC_URL + imageUrl} alt="" />
          </div>
        </div>
      )}
      <NoteModal editable={true} show={showNoteModal} notes={transfer.notes} closeModal={onCloseNoteModal} saveNotes={onSaveNotes} deleteNotes={() => onSaveNotes(null)} />
    </div>
  );
};

export default DetailTransferView;
