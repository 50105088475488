import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import DateRangeSelector from '../components/date-range-selector';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import WithdrawalModal from '../components/withdrawal-modal';
import { DateRange } from '../enums/date-range';
import { PosStatus } from '../enums/pos-status';
import { Role } from '../enums/role';
import { useStores } from '../hooks/use-stores.hook';
import { DateRangeData } from '../interfaces/date-range-data';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { Withdrawal } from '../interfaces/withdrawal';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { formatNumber, getDatesGivenDateRange } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

const dateRange: DateRange = DateRange.ThisYear;

const WithdrawalsViews = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { selectedStoreId, store } = useSelector(storeSelector);
  const tableRef: any = useRef<any>(null);
  const [withdrawals, setWithdrawals] = useState<Withdrawal[]>([]);
  const [showWithdrawalModal, setShowWithdrawalModal] = useState<boolean>(false);
  const [selectedWithdrawal, setSelectedWithdrawals] = useState<Withdrawal | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData>({
    dateRange,
    dates: getDatesGivenDateRange(dateRange),
  });
  const stores: Store[] = useStores();

  const getWithdrawals = async () => {
    setRequesting(true);
    const withdrawals: Withdrawal[] = await api.getWithdrawals(dateRangeData.dates[0], dateRangeData.dates[1], organization!.id, selectedStoreId);
    setWithdrawals(withdrawals);
    setRequesting(false);
  };

  useEffect(() => {
    getWithdrawals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId, dateRangeData]);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Tienda',
        accessor: 'store.name',
        Filter: SelectColumnFilter,
      },
      {
        id: 'createdAt',
        Header: 'Fecha',
        accessor: (withdrawal: Withdrawal) => moment(withdrawal.createdAt).format('DD/MM/YYYY HH:mm'),
        filter: 'fuzzyDate',
        Cell: ({ row }: any) => {
          const withdrawal: Withdrawal = row.original;
          return moment(withdrawal.createdAt).format('DD/MM/YYYY HH:mm');
        },
        sortType: (a: any, b: any) => {
          const momentA = moment(a.original.createdAt);
          const momentB = moment(b.original.createdAt);
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      },
      {
        Header: 'Vendedor',
        accessor: (withdrawal: Withdrawal) => {
          const seller: User = withdrawal.user;
          if (!seller) {
            return '';
          }
          let name = 'Sin nombre';
          if (seller.name && seller.name !== '') {
            name = seller.name;
          }
          if (seller.surnames && seller.surnames !== '') {
            name = `${name} ${seller.surnames}`;
          }
          return name;
        },
        Filter: SelectColumnFilter,
        Cell: (cell: any) => {
          const seller: User = cell.row.original.user;
          if (!seller) {
            return '';
          }
          let name = 'Sin nombre';
          if (seller.name && seller.name !== '') {
            name = seller.name;
          }
          if (seller.surnames && seller.surnames !== '') {
            name = `${name} ${seller.surnames}`;
          }
          return name;
        },
      },
      {
        Header: 'Cantidad',
        accessor: 'amount',
        Cell: ({ value }: any) => `${value}€`,
      },
      {
        Header: 'Nota',
        accessor: 'note',
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <Trash2
              type="button"
              className="mx-2"
              onClick={() => {
                setSelectedWithdrawals(row.original);
                setShowConfirmModal(true);
              }}
              size={14}
            />
          );
        },
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawals]) as any;

  const newWithdrawal = () => {
    if (store?.posStatus === PosStatus.Closed) {
      myToastr.error('Abre la caja para poder crear un gasto');
      return;
    }
    setSelectedWithdrawals(null);
    setShowWithdrawalModal(true);
  };

  const onWithdrawalModalClose = (withdrawal: Withdrawal | null) => {
    if (withdrawal) {
      getWithdrawals();
    }
    setShowWithdrawalModal(false);
    setSelectedWithdrawals(null);
  };

  const deleteWithdrawal = async (b: Withdrawal) => {
    try {
      await api.deleteWithdrawal(b.id);
      const ws: Withdrawal[] = [...withdrawals];
      const index: number = ws.findIndex((w: Withdrawal) => w.id === b.id);
      ws.splice(index, 1);
      setWithdrawals(ws);
      myToastr.success('Retiro eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((w: any) => {
      const withdrawal: Withdrawal = w.original;
      const seller: User = withdrawal.user;
      let sellerName = 'Sin nombre';
      if (seller.name && seller.name !== '') {
        sellerName = seller.name;
      }
      if (seller.surnames && seller.surnames !== '') {
        sellerName = `${sellerName} ${seller.surnames}`;
      }
      data.push({
        Tienda: withdrawal.store.name,
        Fecha: moment(withdrawal.createdAt).format('DD/MM/YYYY HH:mm'),
        Vendedor: sellerName,
        Cantidad: `${formatNumber(withdrawal.amount)}€`,
      });
    });
    setCsvData(data);
  };

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="gastos withdrawals p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Gastos</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="gastos.csv" className="d-flex align-items-center export-csv" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <button className="d-flex align-items-center create-button mx-3" onClick={newWithdrawal}>
          <Plus className="me-1" size={14} /> Nuevo gasto
        </button>
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={false}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
        <button
          className="ms-2 clear-filters"
          disabled={requesting}
          onClick={() => {
            tableRef.current?.clearFilters();
            setDateRangeData({
              dateRange,
              dates: getDatesGivenDateRange(dateRange),
            });
          }}
        >
          Limpiar filtros
        </button>
      </div>
      <Table
        ref={tableRef}
        data={withdrawals}
        columns={columns}
        noDataMessage="No hay gastos"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          sortBy: [{ id: 'createdAt', desc: true }],
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <WithdrawalModal show={showWithdrawalModal} closeModal={onWithdrawalModalClose}></WithdrawalModal>
      {selectedWithdrawal && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Gasto"
          content={`¿Estás seguro que quieres eliminar el gasto de la tienda ${selectedWithdrawal.store.name} con fecha ${moment(selectedWithdrawal.createdAt).format('DD/MM/YYYY HH:mm')}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteWithdrawal(selectedWithdrawal);
            }
            setSelectedWithdrawals(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default WithdrawalsViews;
