import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { InventoryType } from '../enums/inventory-type.enum';
import { useStores } from '../hooks/use-stores.hook';
import { Brand } from '../interfaces/brand';
import { Category } from '../interfaces/category';
import { Collection } from '../interfaces/collection';
import { GetFiltersInventory } from '../interfaces/get-filters-inventory';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Inventory } from '../interfaces/inventory';
import { InventoryFilters } from '../interfaces/inventory-filters';
import { InventoryDto } from '../interfaces/inventory.dto';
import { Store } from '../interfaces/store';
import { SubCategory } from '../interfaces/subcategory';
import { api } from '../services/api';
import myToastr from '../services/toastr';

interface Props {
  show: boolean;
  closeModal: (inventory: Inventory | null) => void;
}

const initialValues: InventoryDto = {
  notes: null,
  storeId: -1,
  inventoryProducts: [],
  brandId: -1,
  collectionId: -1,
  categoryId: -1,
  subCategoryId: -1,
  isDraft: true,
  type: null,
};

const InventoryModal = ({ show, closeModal }: Props) => {
  const stores: Store[] = useStores();
  const [step, setStep] = useState<number>(1);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [inventoryFilters, setInventoryFilters] = useState<InventoryFilters>({
    brands: [],
    collections: [],
    categories: [],
    subCategories: [],
    numProducts: 0,
  });
  const {
    clearErrors,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<InventoryDto>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!show) {
      reset(initialValues);
      setStep(1);
      setInventoryFilters({
        brands: [],
        collections: [],
        categories: [],
        subCategories: [],
        numProducts: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!show) {
      return;
    }
    if (!watch('storeId') || watch('storeId') <= 0) {
      return;
    }
    const getFilters = async () => {
      setRequesting(true);
      try {
        const getFiltersInventory: GetFiltersInventory = {
          storeId: watch('storeId')!,
          brandId: watch('brandId')!,
          collectionId: watch('collectionId')!,
          categoryId: watch('categoryId')!,
          subCategoryId: watch('subCategoryId')!,
        };
        const ifs: InventoryFilters = await api.getInventoryFilters(getFiltersInventory);
        setInventoryFilters(ifs);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data as HttpExceptionDto;
        myToastr.error(httpExceptionDto.message);
      }
      setRequesting(false);
    };
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, watch('storeId'), watch('brandId'), watch('brandId'), watch('collectionId'), watch('categoryId'), watch('subCategoryId')]);

  const close = () => {
    closeModal(null);
  };

  const onSubmit = async (data: InventoryDto) => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        if (!data.type) {
          setError('type', { type: 'manual', message: 'Selecciona un tipo' });
          return;
        }
        setStep(3);
        break;
      case 3:
        try {
          const thresholdDto: InventoryDto = {
            notes: null,
            storeId: data.storeId,
            inventoryProducts: [],
            brandId: data.brandId !== null && data.brandId > 0 ? data.brandId : null,
            collectionId: data.collectionId !== null && data.collectionId > 0 ? data.collectionId : null,
            categoryId: data.categoryId !== null && data.categoryId > 0 ? data.categoryId : null,
            subCategoryId: data.subCategoryId !== null && data.subCategoryId > 0 ? data.subCategoryId : null,
            isDraft: true,
            type: data.type,
          };
          const newInventory: Inventory = await api.createInventory(thresholdDto);
          myToastr.success('Inventario creado');
          closeModal(newInventory);
        } catch (e: any) {
          const httpExceptionDto: HttpExceptionDto = e.response.data;
          myToastr.error(httpExceptionDto.message);
        }
        break;
    }
  };

  return (
    <Modal className="vercomi-modal my-form threshold-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content position-relative">
          <div className="d-flex flex-column text-center justify-content-center">
            <div className="title">Crear Inventario</div>
            <div className="subtitle">Paso {step}</div>
            <button type="button" className="position-absolute top-0 close-button-modal" style={{ right: 15 }} onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center" style={{ height: step === 1 || step === 2 ? 200 : 350 }}>
          {step === 1 && (
            <div
              className="my-3"
              style={{
                paddingLeft: 120,
                paddingRight: 120,
              }}
            >
              <div className={clsx('input-name', { error: errors?.storeId })}>Elige la tienda</div>
              <select
                {...register('storeId', { required: true, min: 1, valueAsNumber: true })}
                className={clsx({ error: errors?.storeId })}
                onChange={(e) => {
                  setValue('storeId', parseInt(e.target.value));
                  setValue('brandId', -1);
                  setValue('collectionId', -1);
                  setValue('categoryId', -1);
                  setValue('subCategoryId', -1);
                }}
              >
                <option value={-1}>Selecciona tienda</option>
                {stores.map((store: Store) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {step === 2 && (
            <React.Fragment>
              <div className="step-2 d-flex flex-row justify-content-evenly">
                <div
                  className={clsx('selection', { selected: watch('type') === InventoryType.Brand, error: errors.hasOwnProperty('type') })}
                  onClick={() => {
                    clearErrors('type');
                    setValue('type', InventoryType.Brand);
                    setValue('categoryId', -1);
                    setValue('subCategoryId', -1);
                    setValue('brandId', -1);
                    setValue('collectionId', -1);
                  }}
                >
                  Marca
                </div>
                <div
                  className={clsx('selection', { selected: watch('type') === InventoryType.Category, error: errors.hasOwnProperty('type') })}
                  onClick={() => {
                    clearErrors('type');
                    setValue('type', InventoryType.Category);
                    setValue('categoryId', -1);
                    setValue('subCategoryId', -1);
                    setValue('brandId', -1);
                    setValue('collectionId', -1);
                  }}
                >
                  Categoría
                </div>
              </div>
            </React.Fragment>
          )}
          {step === 3 && watch('type') === InventoryType.Brand && (
            <React.Fragment>
              <div
                className="my-2"
                style={{
                  paddingLeft: 120,
                  paddingRight: 120,
                }}
              >
                <div className={clsx('input-name', { error: errors?.brandId })}>Elige marca</div>
                <select
                  {...register('brandId', { required: true, min: 1, valueAsNumber: true })}
                  className={clsx({ error: errors?.brandId })}
                  disabled={requesting}
                  onChange={(e) => {
                    setValue('brandId', parseInt(e.target.value, 10));
                    setValue('collectionId', -1);
                    setValue('categoryId', -1);
                    setValue('subCategoryId', -1);
                  }}
                >
                  <option value={-1}>Selecciona marca</option>
                  {inventoryFilters.brands.map((brand: Brand) => (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>
              {watch('brandId')! > 0 && (
                <div
                  className="my-2"
                  style={{
                    paddingLeft: 120,
                    paddingRight: 120,
                  }}
                >
                  <div className={clsx('input-name', { error: errors?.collectionId })}>Elige colección</div>
                  {inventoryFilters.collections.length === 0 ? (
                    <div className="info-no-data">No hay colecciones para esta marca</div>
                  ) : (
                    <select
                      {...register('collectionId', { valueAsNumber: true })}
                      className={clsx({ error: errors?.collectionId })}
                      disabled={requesting}
                      onChange={(e) => {
                        setValue('collectionId', parseInt(e.target.value, 10));
                        setValue('categoryId', -1);
                        setValue('subCategoryId', -1);
                      }}
                    >
                      <option value={-1}>{watch('brandId')! > 0 ? 'Mostrar todas las colecciones' : 'Todas las colecciones'}</option>
                      {inventoryFilters.collections.map((collection: Collection) => (
                        <option key={collection.id} value={collection.id}>
                          {collection.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              )}
              {watch('brandId')! > 0 && (
                <div
                  className="my-2"
                  style={{
                    paddingLeft: 120,
                    paddingRight: 120,
                  }}
                >
                  <div className={clsx('input-name', { error: errors?.categoryId })}>Elige categoría</div>
                  {inventoryFilters.categories.length === 0 ? (
                    <div className="info-no-data">No hay categorías para esta marca</div>
                  ) : (
                    <select
                      {...register('categoryId', { valueAsNumber: true })}
                      className={clsx({ error: errors?.categoryId })}
                      disabled={requesting}
                      onChange={(e) => {
                        setValue('categoryId', parseInt(e.target.value, 10));
                        setValue('subCategoryId', -1);
                      }}
                    >
                      <option value={-1}>{watch('categoryId')! > 0 ? 'Mostrar todas las categorías' : 'Todas las categorías'}</option>
                      {inventoryFilters.categories.map((category: Category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              )}
              {watch('brandId')! > 0 && watch('categoryId')! > 0 && (
                <div
                  className="my-2"
                  style={{
                    paddingLeft: 120,
                    paddingRight: 120,
                  }}
                >
                  <div className={clsx('input-name', { error: errors?.subCategoryId })}>Elige subcategoría</div>
                  {inventoryFilters.subCategories.length === 0 ? (
                    <div className="info-no-data">No hay subcategorías para esta categoría</div>
                  ) : (
                    <select {...register('subCategoryId', { valueAsNumber: true })} className={clsx({ error: errors?.subCategoryId })} disabled={requesting}>
                      <option value={-1}>{watch('subCategoryId')! > 0 ? 'Mostrar todas las subcategorías' : 'Todas las subcategorías'}</option>
                      {inventoryFilters.subCategories.map((subCategory: SubCategory) => (
                        <option key={subCategory.id} value={subCategory.id}>
                          {subCategory.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              )}
              {watch('brandId')! > 0 && (
                <div
                  className="my-2"
                  style={{
                    paddingLeft: 120,
                    paddingRight: 120,
                  }}
                >
                  <div className={clsx('input-name', { error: errors?.subCategoryId })}>Número de productos</div>
                  <div className="info-no-data">{inventoryFilters.numProducts}</div>
                </div>
              )}
            </React.Fragment>
          )}
          {step === 3 && watch('type') === InventoryType.Category && (
            <React.Fragment>
              <div
                className="my-2"
                style={{
                  paddingLeft: 120,
                  paddingRight: 120,
                }}
              >
                <div className={clsx('input-name', { error: errors?.categoryId })}>Elige categoría</div>
                {inventoryFilters.categories.length === 0 ? (
                  <div className="info-no-data">No hay categorías para esta marca</div>
                ) : (
                  <select
                    {...register('categoryId', { valueAsNumber: true })}
                    className={clsx({ error: errors?.categoryId })}
                    disabled={requesting}
                    onChange={(e) => {
                      setValue('categoryId', parseInt(e.target.value, 10));
                      setValue('subCategoryId', -1);
                      setValue('brandId', -1);
                      setValue('collectionId', -1);
                    }}
                  >
                    <option value={-1}>{watch('categoryId')! > 0 ? 'Mostrar todas las categorías' : 'Todas las categorías'}</option>
                    {inventoryFilters.categories.map((category: Category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              {watch('categoryId')! > 0 && (
                <div
                  className="my-2"
                  style={{
                    paddingLeft: 120,
                    paddingRight: 120,
                  }}
                >
                  <div className={clsx('input-name', { error: errors?.subCategoryId })}>Elige subcategoría</div>
                  {inventoryFilters.subCategories.length === 0 ? (
                    <div className="info-no-data">No hay subcategorías para esta categoría</div>
                  ) : (
                    <select
                      {...register('subCategoryId', { valueAsNumber: true })}
                      className={clsx({ error: errors?.subCategoryId })}
                      disabled={requesting}
                      onChange={(e) => {
                        setValue('subCategoryId', parseInt(e.target.value, 10));
                        setValue('brandId', -1);
                        setValue('collectionId', -1);
                      }}
                    >
                      <option value={-1}>{watch('subCategoryId')! > 0 ? 'Mostrar todas las subcategorías' : 'Todas las subcategorías'}</option>
                      {inventoryFilters.subCategories.map((subCategory: SubCategory) => (
                        <option key={subCategory.id} value={subCategory.id}>
                          {subCategory.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              )}
              {watch('categoryId')! > 0 && (
                <div
                  className="my-2"
                  style={{
                    paddingLeft: 120,
                    paddingRight: 120,
                  }}
                >
                  <div className={clsx('input-name', { error: errors?.brandId })}>Elige marca</div>
                  <select
                    {...register('brandId', { required: true, min: 1, valueAsNumber: true })}
                    className={clsx({ error: errors?.brandId })}
                    disabled={requesting}
                    onChange={(e) => {
                      setValue('brandId', parseInt(e.target.value, 10));
                      setValue('collectionId', -1);
                    }}
                  >
                    <option value={-1}>Selecciona marca</option>
                    {inventoryFilters.brands.map((brand: Brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {watch('categoryId')! > 0 && watch('brandId')! > 0 && (
                <div
                  className="my-2"
                  style={{
                    paddingLeft: 120,
                    paddingRight: 120,
                  }}
                >
                  <div className={clsx('input-name', { error: errors?.collectionId })}>Elige colección</div>
                  {inventoryFilters.collections.length === 0 ? (
                    <div className="info-no-data">No hay colecciones para esta marca</div>
                  ) : (
                    <select
                      {...register('collectionId', { valueAsNumber: true })}
                      className={clsx({ error: errors?.collectionId })}
                      disabled={requesting}
                      onChange={(e) => {
                        setValue('collectionId', parseInt(e.target.value, 10));
                        setValue('categoryId', -1);
                        setValue('subCategoryId', -1);
                      }}
                    >
                      <option value={-1}>{watch('brandId')! > 0 ? 'Mostrar todas las colecciones' : 'Todas las colecciones'}</option>
                      {inventoryFilters.collections.map((collection: Collection) => (
                        <option key={collection.id} value={collection.id}>
                          {collection.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              )}
              {watch('categoryId')! > 0 && (
                <div
                  className="my-2"
                  style={{
                    paddingLeft: 120,
                    paddingRight: 120,
                  }}
                >
                  <div className={clsx('input-name', { error: errors?.subCategoryId })}>Número de productos</div>
                  <div className="info-no-data">{inventoryFilters.numProducts}</div>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        {step === 1 ? (
          <button className={`save-button`} type="submit">
            Siguiente
          </button>
        ) : (
          <div className="d-flex flex-row">
            <button
              className="previous-step"
              type="button"
              onClick={() => {
                setStep(step - 1);
                setValue('brandId', -1);
                setValue('collectionId', -1);
                setValue('categoryId', -1);
                setValue('subCategoryId', -1);
              }}
            >
              Anterior
            </button>
            <button className="next-step" type="submit">
              {step === 2 ? 'Siguiente' : 'Guardar'}
            </button>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default InventoryModal;
