import { UilAngleRight } from '@iconscout/react-unicons';
import axios, { AxiosError, AxiosResponse } from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import printJS from 'print-js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { ChevronDown, Download, Maximize2, Minimize2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Role } from '../enums/role';
import { GetSalesStats } from '../interfaces/get-sales-stats';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { ProductsStats, SalesByBrand, SalesByCategory, SalesStats, StatsSeller } from '../interfaces/sales-stats';
import { SalesStatsByProduct } from '../interfaces/sales-stats-by-product';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { arrayBufferToJson, formatNumber, slugify } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';
import Table from './table';

interface Props {
  organizationId: number;
  storeId: number;
  startDate: Date | null;
  endDate: Date | null;
}

const DashboardSalesStats = ({ organizationId, storeId, startDate, endDate }: Props) => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const { store } = useSelector(storeSelector);
  const refContainerSalesByProduct = useRef<any>(null);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [requestingProducts, setRequestingProducts] = useState<boolean>(false);
  const [salesStats, setSalesStats] = useState<SalesStats | null>(null);
  const [salesByBrand, setSalesByBrand] = useState<SalesByBrand | null>(null);
  const [salesStatsByProduct, setSalesStatsByProduct] = useState<SalesStatsByProduct[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [kpisCsvData, setKpisCsvData] = useState<any[]>([]);
  const [csvDataProducts, setCsvDataProducts] = useState<any[]>([]);
  const [requestingPdf, setRequestingPdf] = useState<boolean>(false);
  const columnsMostSelledProducts: any[] = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Producto',
        accessor: 'productName',
        disableFilters: true,
      },
      {
        Header: 'Unidades',
        accessor: 'units',
        Cell: ({ value }: any) => {
          return formatNumber(value, true);
        },
        disableFilters: true,
      },
      {
        Header: 'PVP',
        accessor: 'pvp',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columnsKpisSellers: any[] = useMemo(() => {
    const columnsData = [
      {
        Header: 'Vendedor',
        disableFilters: true,
        accessor: (statsSeller: StatsSeller) => `${statsSeller.userName} ${statsSeller.userSurnames}`,
      },
      {
        Header: 'Unidades por Ticket',
        disableFilters: true,
        accessor: 'unitsPerTicket',
        Cell: ({ value }: any) => formatNumber(value),
      },
      {
        Header: 'Ticket Medio',
        disableFilters: true,
        accessor: 'averageTicket',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
      },
      {
        Header: 'Ventas',
        disableFilters: true,
        accessor: 'pvp',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      columnsData.splice(4, 0, {
        Header: 'Coste Medio',
        disableFilters: true,
        accessor: 'costPrice',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
      });
      columnsData.splice(5, 0, {
        Header: 'Margen Medio',
        disableFilters: true,
        accessor: 'margin',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}%`;
        },
      });
    }
    return columnsData;
  }, [user]);
  const dataSalesByBrand: any[] = useMemo(() => {
    if (!salesStats?.salesByBrand) {
      return [];
    }
    return salesStats.salesByBrand.map((sbb: SalesByBrand) => {
      return {
        ...sbb,
        subRows: sbb.families,
      };
    });
  }, [salesStats?.salesByBrand]);
  const dataSalesByCategory: any[] = useMemo(() => {
    if (!salesStats?.salesByCategory) {
      return [];
    }
    return salesStats.salesByCategory.map((sbc: SalesByCategory) => {
      return {
        ...sbc,
        subRows: sbc.subCategories,
      };
    });
  }, [salesStats?.salesByCategory]);
  const dataSalesBySupplier: any[] = useMemo(() => (Array.isArray(salesStats?.salesBySupplier) ? salesStats!.salesBySupplier : []), [salesStats]);
  const columnsSalesByBrand: any[] = useMemo(() => {
    const columnsData: any[] = [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        accessor: 'expander',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, toggleAllRowsExpanded }: any) => (
          <span
            className={clsx('expander', { active: isAllRowsExpanded })}
            {...getToggleAllRowsExpandedProps({
              onClick: () => {
                toggleAllRowsExpanded(!isAllRowsExpanded);
              },
            })}
          >
            {isAllRowsExpanded ? <Minimize2 size={14} color="#808a95" /> : <Maximize2 size={14} color="#fff" />}
          </span>
        ),
        maxWidth: 10,
        minWidth: 5,
        width: 5,
        Cell: ({ row }: any) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property and paddingLeft to indicate the depth of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
                onClick: () => {
                  row.toggleRowExpanded();
                },
              })}
            >
              {row.isExpanded ? <ChevronDown size={14} /> : <UilAngleRight size={14} />}
            </span>
          ) : null,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Marca',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ row, value }: any) => {
          if (row.depth === 0) {
            const sbb: SalesByBrand = row.original;
            return (
              <span
                className="link-sale clickable"
                title="Obtener el listado de productos"
                onClick={() => {
                  setSalesByBrand(sbb);
                  getSalesStatsByProduct(sbb.id);
                }}
              >
                {value}
              </span>
            );
          } else {
            return <span className="">{value}</span>;
          }
        },
      },
      {
        Header: 'Unidades',
        accessor: 'units',
        Cell: ({ value }: any) => {
          return formatNumber(value, true);
        },
        disableFilters: true,
      },
      {
        Header: 'PVP',
        accessor: 'pvp',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      columnsData.splice(2, 0, {
        Header: 'Precio Coste',
        accessor: 'costPrice',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      });
      columnsData.splice(4, 0, {
        Header: 'Beneficio Neto',
        accessor: 'netProfit',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      });
      columnsData.splice(5, 0, {
        Header: 'Margen',
        accessor: 'margin',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}%`;
        },
        disableFilters: true,
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, salesByBrand, requestingProducts]);
  const columnsSalesByCategory: any[] = useMemo(() => {
    const columnsData: any[] = [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        accessor: 'expander',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, toggleAllRowsExpanded }: any) => (
          <span
            className={clsx('expander', { active: isAllRowsExpanded })}
            {...getToggleAllRowsExpandedProps({
              onClick: () => {
                toggleAllRowsExpanded(!isAllRowsExpanded);
              },
            })}
          >
            {isAllRowsExpanded ? <Minimize2 size={14} color="#808a95" /> : <Maximize2 size={14} color="#fff" />}
          </span>
        ),
        maxWidth: 10,
        minWidth: 5,
        width: 5,
        Cell: ({ row }: any) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property and paddingLeft to indicate the depth of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
                onClick: () => {
                  row.toggleRowExpanded();
                },
              })}
            >
              {row.isExpanded ? <ChevronDown size={14} /> : <UilAngleRight size={14} />}
            </span>
          ) : null,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Categoría',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'Unidades',
        accessor: 'units',
        Cell: ({ value }: any) => {
          return formatNumber(value, true);
        },
        disableFilters: true,
      },
      {
        Header: 'PVP',
        accessor: 'pvp',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      columnsData.splice(2, 0, {
        Header: 'Precio Coste',
        accessor: 'costPrice',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      });
      columnsData.splice(4, 0, {
        Header: 'Beneficio Neto',
        accessor: 'netProfit',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      });
      columnsData.splice(5, 0, {
        Header: 'Margen',
        accessor: 'margin',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}%`;
        },
        disableFilters: true,
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, salesByBrand, requestingProducts]);
  const columnsSalesBySupplier: any[] = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Proveedor',
        accessor: 'name',
        disableFilters: true,
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      columnsData.push({
        Header: 'Precio Coste',
        accessor: 'costPrice',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      });
    }
    columnsData.push({
      Header: 'Unidades',
      accessor: 'units',
      Cell: ({ value }: any) => {
        return formatNumber(value, true);
      },
      disableFilters: true,
    });
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      columnsData.push({
        Header: 'Beneficio Neto',
        accessor: 'netProfit',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      });
      columnsData.push({
        Header: 'Margen',
        accessor: 'margin',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}%`;
        },
        disableFilters: true,
      });
    }
    columnsData.push({
      Header: 'PVP',
      accessor: 'pvp',
      Cell: ({ value }: any) => {
        return `${formatNumber(value)}€`;
      },
      disableFilters: true,
    });
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, salesByBrand, requestingProducts]);
  const columnsSalesByProduct: any[] = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Producto',
        accessor: 'productName',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const ssbp: SalesStatsByProduct = row.original;
          return (
            <Link to={`/producto/${ssbp.productId}`} className="link-sale">
              {ssbp.productName}
            </Link>
          );
        },
      },
      {
        Header: 'SKU',
        accessor: 'productSku',
        disableFilters: true,
      },
      {
        Header: 'Unidades',
        accessor: 'units',
        Cell: ({ value }: any) => {
          return formatNumber(value, true);
        },
        disableFilters: true,
      },
      {
        Header: 'PVP',
        accessor: 'pvp',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
      {
        Header: 'Margen',
        accessor: 'margin',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}%`;
        },
        disableFilters: true,
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      columnsData.splice(3, 0, {
        Header: 'Precio Coste',
        accessor: 'costPrice',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      });
      columnsData.splice(5, 0, {
        Header: 'Beneficio Neto',
        accessor: 'netProfit',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startDate !== null && endDate !== null && (!moment(startDate).isValid() || !moment(endDate).isValid())) {
      setSalesStats(null);
      return;
    }
    const getSaleStats = async () => {
      try {
        setRequesting(true);
        const getSalesStats: GetSalesStats = {
          organizationId,
          storeId,
          startDate,
          endDate,
        };
        const ss: SalesStats = await api.getSalesStats(getSalesStats);
        setSalesStats(ss);
        setRequesting(false);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    };
    getSaleStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, storeId, startDate, endDate]);

  useEffect(() => {
    if (refContainerSalesByProduct.current) {
      refContainerSalesByProduct.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refContainerSalesByProduct?.current]);

  const getSalesStatsByProduct = async (brandId: number) => {
    if (requestingProducts) {
      return;
    }
    try {
      setRequestingProducts(true);
      const ss: SalesStatsByProduct[] = await api.getSalesStatsGivenBrand(organizationId, storeId, brandId, startDate, endDate);
      setSalesStatsByProduct(ss);
      setRequestingProducts(false);
      refContainerSalesByProduct?.current.scrollIntoView({ behavior: 'smooth' });
    } catch (e) {}
  };

  const generateCsvKpis = () => {
    const data: any[] = [];
    salesStats!.sellers.forEach((statsSeller: StatsSeller) => {
      const d: any = {
        Vendedor: `${statsSeller.userName} ${statsSeller.userSurnames}`,
        'Unidades por Ticket': statsSeller.unitsPerTicket,
        'Ticket Medio': statsSeller.averageTicket,
        Ventas: statsSeller.pvp,
        'Coste Medio': statsSeller.costPrice,
        Margen: statsSeller.margin,
      };
      data.push(d);
    });
    setKpisCsvData(data);
  };

  const generateCsvSalesByBrand = () => {
    const data: any[] = [];
    salesStats!.salesByBrand.forEach((salesByBrand: SalesByBrand) => {
      const d: any = {
        Marca: salesByBrand.name,
        Unidades: salesByBrand.units,
        PVP: salesByBrand.pvp,
        Margen: salesByBrand.margin,
      };
      if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
        d['Precio Coste'] = salesByBrand.costPrice;
        d['Beneficio Neto'] = salesByBrand.netProfit;
      }
      data.push(d);
    });
    setCsvData(data);
  };

  const generateCsvSalesByCategory = () => {
    const data: any[] = [];
    salesStats!.salesByCategory.forEach((salesByCategory: SalesByCategory) => {
      const d: any = {
        Categoría: salesByCategory.name,
        Unidades: salesByCategory.units,
        PVP: salesByCategory.pvp,
        Margen: salesByCategory.margin,
      };
      if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
        d['Precio Coste'] = salesByCategory.costPrice;
        d['Beneficio Neto'] = salesByCategory.netProfit;
      }
      data.push(d);
    });
    setCsvData(data);
  };

  const generateCsvSalesBySupplier = () => {
    const data: any[] = [];
    salesStats!.salesBySupplier.forEach((productsStats: ProductsStats) => {
      const d: any = {
        Proveedor: productsStats.name,
        Unidades: productsStats.units,
        PVP: productsStats.pvp,
        Margen: productsStats.margin,
      };
      if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
        d['Precio Coste'] = productsStats.costPrice;
        d['Beneficio Neto'] = productsStats.netProfit;
      }
      data.push(d);
    });
    setCsvData(data);
  };

  const generateCsvProducts = () => {
    const data: any[] = [];
    salesStatsByProduct.forEach((salesByProduct: SalesStatsByProduct) => {
      const d: any = {
        Producto: salesByProduct.productName,
        SKU: salesByProduct.productSku,
        Unidades: salesByProduct.units,
        PVP: salesByProduct.pvp,
        Margen: salesByProduct.margin,
      };
      if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
        d['Precio Coste'] = salesByProduct.costPrice;
        d['Beneficio Neto'] = salesByProduct.netProfit;
      }
      data.push(d);
    });
    setCsvDataProducts(data);
  };

  const getStatsPdf = async () => {
    if (requestingPdf) {
      return;
    }
    setRequestingPdf(true);
    try {
      myToastr.remove();
      myToastr.info('Obteniendo las estadísticas en formato PDF. Espera por favor.');
      const getSalesStats: GetSalesStats = {
        organizationId,
        storeId,
        startDate,
        endDate,
      };
      const result: AxiosResponse = await api.exportSalesStats(getSalesStats);
      myToastr.remove();
      if (result.headers && result.headers['content-type'] && result.headers['content-type'] === 'application/pdf') {
        const url: string = window.URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' }));
        printJS(url, 'pdf');
      } else {
        const data: { message: string } = arrayBufferToJson(result.data);
        myToastr.info(data.message);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = arrayBufferToJson(axiosError.response.data);
          myToastr.remove();
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    } finally {
      setRequestingPdf(false);
    }
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  if (!salesStats) {
    return null;
  }

  return (
    <div className="dashboard-sales-stats">
      {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
        <React.Fragment>
          <div className="d-flex flex-row justify-content-end mb-2">
            <div className="export-csv cursor-pointer" onClick={getStatsPdf}>
              <Download className="me-1" size={14} />
              <span>Exportar PDF</span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="billing-summary mb-3">
                <h4>Ventas</h4>
                <div className="d-flex flex-column">
                  <span className="amount">{formatNumber(salesStats.totalSales.total)}€</span>
                  <div className="row">
                    <div className="col-6">
                      <div className="d-flex flex-row">
                        <strong className="percentage me-2">Efectivo:</strong>
                        <span className="percentage">{formatNumber(salesStats.totalSales.cash.total)}€</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-row">
                        <strong className="percentage me-2">TPV:</strong>
                        <span className="percentage">{formatNumber(salesStats.totalSales.tpv.total)}€</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-row">
                        <strong className="percentage me-2">Saldo:</strong>
                        <span className="percentage">{formatNumber(salesStats.totalSales.balance.total)}€</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-row">
                        <strong className="percentage me-2">Otros:</strong>
                        <span className="percentage">{formatNumber(salesStats.totalSales.others.total)}€</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="billing-summary mb-3">
                <h4>Beneficio Neto</h4>
                <div className="d-flex flex-column">
                  <span className="amount">{formatNumber(salesStats.netProfit.total)}€</span>
                  <span className="percentage">{formatNumber(salesStats.netProfit.percentage)}%</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="billing-summary mb-3">
                <h4>Coste Neto</h4>
                <div className="d-flex flex-column">
                  <span className="amount">{formatNumber(salesStats.cost.total)}€</span>
                  <span className="percentage">{formatNumber(salesStats.cost.percentage)}%</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="billing-summary mb-3">
                <h4>Impuestos</h4>
                <div className="d-flex flex-column">
                  <span className="amount">{formatNumber(salesStats.taxes.total)}€</span>
                  <span className="percentage">{formatNumber(salesStats.taxes.percentage)}%</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="billing-summary mb-3">
                <h4>Unidades por Ticket</h4>
                <div className="d-flex flex-column">
                  <span className="amount">{formatNumber(salesStats.unitsPerTicket)}</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="billing-summary mb-3">
                <h4>Ticket Medio</h4>
                <div className="d-flex flex-column">
                  <span className="amount">{formatNumber(salesStats.averageTicket)}€</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="billing-summary mb-3">
                <h4>Tiempo Medio Composturas</h4>
                <div className="d-flex flex-column">
                  <span className="amount">{formatNumber(salesStats.averageRepairTime)} días</span>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      <div className="row">
        <div className="col px-2 mb-3 mb-xl-0">
          <div className="dashboard-tab active mb-4">
            <div className="d-flex flex-row align-items-center mb-2">
              <h4 className="mb-0 flex-grow-1">KPIs Vendedores</h4>
              <CSVLink
                filename={store ? `kpis_vendedores_${slugify(store?.name)}.csv` : 'kpis_vendedores.csv'}
                className="d-flex align-items-center export-csv me-2"
                data={kpisCsvData}
                onClick={generateCsvKpis}
              >
                <Download className="me-1" size={14} /> Exportar
              </CSVLink>
            </div>
            {salesStats?.salesByBrand && <Table columns={columnsKpisSellers} data={salesStats.sellers} noDataMessage={'No hay ventas en el período seleccionado.'} />}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xl-9 px-2 mb-3 mb-xl-0">
          <div className="dashboard-tab active mb-4">
            <div className="d-flex flex-row align-items-center mb-2">
              <h4 className="mb-0 flex-grow-1">Ventas por marca</h4>
              <CSVLink
                filename={store ? `ventas_marcas_${slugify(store?.name)}.csv` : 'ventas_marcas.csv'}
                className="d-flex align-items-center export-csv me-2"
                data={csvData}
                onClick={generateCsvSalesByBrand}
              >
                <Download className="me-1" size={14} /> Exportar
              </CSVLink>
            </div>
            {salesStats?.salesByBrand && <Table columns={columnsSalesByBrand} data={dataSalesByBrand} noDataMessage={'No hay ventas en el período seleccionado.'} />}
          </div>
          {salesByBrand !== null && (
            <div className="dashboard-tab active">
              <div className="d-flex flex-row align-items-center mb-2">
                <h4 className="mb-0 flex-grow-1">Ventas de la marca {salesByBrand.name}</h4>
                <CSVLink filename={`ventas_marca_${slugify(salesByBrand.name)}.csv`} className="d-flex align-items-center export-csv me-2" data={csvDataProducts} onClick={generateCsvProducts}>
                  <Download className="me-1" size={14} /> Exportar
                </CSVLink>
              </div>
              <div ref={refContainerSalesByProduct}>
                <Table columns={columnsSalesByProduct} data={salesStatsByProduct} noDataMessage={'No hay ventas en el período seleccionado.'} />
              </div>
            </div>
          )}
          <div className="dashboard-tab active mb-4">
            <div className="d-flex flex-row align-items-center mb-2">
              <h4 className="mb-0 flex-grow-1">Ventas por categoría</h4>
              <CSVLink
                filename={store ? `ventas_categorias_${slugify(store?.name)}.csv` : 'ventas_categorias.csv'}
                className="d-flex align-items-center export-csv me-2"
                data={csvData}
                onClick={generateCsvSalesByCategory}
              >
                <Download className="me-1" size={14} /> Exportar
              </CSVLink>
            </div>
            {salesStats?.salesByBrand && <Table columns={columnsSalesByCategory} data={dataSalesByCategory} noDataMessage={'No hay ventas en el período seleccionado.'} />}
          </div>
          <div className="dashboard-tab active mb-4">
            <div className="d-flex flex-row align-items-center mb-2">
              <h4 className="mb-0 flex-grow-1">Ventas por proveedor</h4>
              <CSVLink
                filename={store ? `ventas_proveedor_${slugify(store?.name)}.csv` : 'ventas_proveedor.csv'}
                className="d-flex align-items-center export-csv me-2"
                data={csvData}
                onClick={generateCsvSalesBySupplier}
              >
                <Download className="me-1" size={14} /> Exportar
              </CSVLink>
            </div>
            {salesStats?.salesByBrand && <Table columns={columnsSalesBySupplier} data={dataSalesBySupplier} noDataMessage={'No hay ventas en el período seleccionado.'} />}
          </div>
        </div>
        <div className="col-12 col-xl-3 px-2">
          <div className="best-sellers">
            <h4>Más vendidos</h4>
            <Table columns={columnsMostSelledProducts} data={salesStats.mostSelledProducts} noDataMessage={'No hay ventas en el período seleccionado.'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSalesStats;
