import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Edit, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import FamilyModal from '../components/family-modal';
import Table from '../components/table';
import { Role } from '../enums/role';
import { Family } from '../interfaces/family';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const FamiliesView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [families, setFamilies] = useState<Family[]>([]);
  const [showFamilyModal, setShowFamilyModal] = useState<boolean>(false);
  const [selectedFamily, setSelectedFamily] = useState<Family | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);

  const getFamilies = async () => {
    setRequesting(true);
    const families: Family[] = await api.getFamilies();
    setFamilies(families);
    setRequesting(false);
  };

  useEffect(() => {
    getFamilies();
  }, []);

  const columns = useMemo(() => {
    const columnsData: any[] = [{ Header: 'Nombre', accessor: 'name', filter: 'fuzzyText' }];
    if (user.role === Role.SuperAdmin) {
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                size={16}
                color="#222E3D"
                onClick={() => {
                  setSelectedFamily(row.original);
                  setShowFamilyModal(true);
                }}
                type="button"
                className="mx-2"
              />
              <Trash2
                type="button"
                className="mx-2"
                onClick={() => {
                  setSelectedFamily(row.original);
                  setShowConfirmModal(true);
                }}
                size={14}
              />
            </React.Fragment>
          );
        },
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [families]) as any;

  const newFamily = () => {
    setSelectedFamily(null);
    setShowFamilyModal(true);
  };

  const onFamilyModalClose = (family: Family | null) => {
    if (family) {
      getFamilies();
    }
    setShowFamilyModal(false);
    setSelectedFamily(null);
  };

  const deleteFamily = async (f: Family) => {
    try {
      await api.deleteFamily(f.id);
      const fms: Family[] = [...families];
      const index: number = fms.findIndex((fm: Family) => fm.id === f.id);
      fms.splice(index, 1);
      setFamilies(fms);
      myToastr.success('Familia eliminada correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const family: Family = fr.original;
      data.push({
        Nombre: family.name,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="families p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Familias</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="familias.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {user.role === Role.SuperAdmin && (
          <button className="d-flex align-items-center create-button" onClick={newFamily}>
            <Plus className="me-1" size={14} /> Nueva familia
          </button>
        )}
      </div>
      <Table
        data={families}
        columns={columns}
        noDataMessage="No hay familias"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <FamilyModal show={showFamilyModal} closeModal={onFamilyModalClose} family={selectedFamily} />
      {selectedFamily && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Familia"
          content={`¿Estás seguro que quieres eliminar la familia ${selectedFamily.name}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteFamily(selectedFamily);
            }
            setSelectedFamily(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default FamiliesView;
