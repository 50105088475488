import React, { useMemo } from 'react';
import { Image, Minus, Plus, X } from 'react-feather';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import { useNavigate } from 'react-router-dom';
import { PurchaseOrderStatus } from '../enums/purchase-order-status';
import { ProductImage } from '../interfaces/product-image';
import { PurchaseOrder } from '../interfaces/purchase-order';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { addProductUnit, createPurchaseOrder, ProductInShoppingCart, purchaseOrderSelector, removeProduct, removeProductUnit, updatePurchaseOrder } from '../store/purchase-order-slice';

interface Props {
  onClose: () => void;
}

const ShoppingCartPurchaseOrder = ({ onClose }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { purchaseOrder, products, total, requesting, originOrganizationId, originStoreId, destinationOrganizationId, destinationStoreId } = useAppSelector(purchaseOrderSelector);
  const isValid: boolean = useMemo(
    () => purchaseOrder !== null || (originOrganizationId > 0 && originStoreId > 0 && destinationOrganizationId > 0 && destinationStoreId > 0),
    [purchaseOrder, originOrganizationId, originStoreId, destinationOrganizationId, destinationStoreId],
  );

  const save = async () => {
    if (purchaseOrder) {
      const result = await dispatch(updatePurchaseOrder(PurchaseOrderStatus.Draft));
      if (!result.payload) {
        return;
      }
      myToastr.success('Órden de compra actualizada');
    } else {
      const result = await dispatch(createPurchaseOrder());
      if (!result.payload) {
        return;
      }
      myToastr.success('Órden de compra creada');
    }
    navigate('/ordenes-compra');
  };

  const checkout = async () => {
    if (purchaseOrder) {
      const result = await dispatch(updatePurchaseOrder(PurchaseOrderStatus.Draft));
      if (!result.payload) {
        return;
      }
      const updatedPurchaseOrder: PurchaseOrder = result.payload as PurchaseOrder;
      navigate(`/orden-compra/${updatedPurchaseOrder.id}`);
    } else {
      const result = await dispatch(createPurchaseOrder());
      if (!result.payload) {
        return;
      }
      const newPurchaseOrder: PurchaseOrder = result.payload as PurchaseOrder;
      navigate(`/orden-compra/${newPurchaseOrder.id}`);
    }
  };

  return (
    <div className="shopping-cart-purchase-order d-flex flex-column">
      <div className="d-flex flex-row justify-content-between">
        <h6>Carrito</h6>
        <X
          onClick={() => {
            if (requesting) {
              return;
            }
            onClose();
          }}
          className="clickable"
          size={16}
          color="black"
        />
      </div>
      <div className="d-flex flex-column">
        {products.length === 0 ? (
          <span className="no-products">No hay productos en el carrito</span>
        ) : (
          <React.Fragment>
            {products.map((productInShoppingCart: ProductInShoppingCart) => {
              let productName: string = productInShoppingCart.name;
              if (productInShoppingCart.name.length > 30) {
                productName = productInShoppingCart.name.substring(0, 50) + '...';
              }
              return (
                <div key={productInShoppingCart.productId} className="d-flex flex-row align-items-center container-product">
                  <div className="image-container">
                    {productInShoppingCart.images.length > 0 ? (
                      <div className="me-2">
                        <LightgalleryProvider>
                          {productInShoppingCart.images.map((productImage: ProductImage, index: number) => {
                            return (
                              <LightgalleryItem key={productImage.id} group="group-product-images" src={process.env.REACT_APP_PUBLIC_URL + productImage.path}>
                                {index === 0 && <img src={process.env.REACT_APP_PUBLIC_URL + productImage.path} alt="" className="img-thumbnail" />}
                              </LightgalleryItem>
                            );
                          })}
                        </LightgalleryProvider>
                      </div>
                    ) : (
                      <Image size={38} className="img-thumbnail my-1 me-2" color="#D3D3D3" />
                    )}
                  </div>
                  <div className="d-flex flex-column">
                    <span className="product-name">{productName}</span>
                    <span className="product-retail-price">{formatNumber(productInShoppingCart.retailPrice)}€</span>
                    <div className="d-flex flex-row">
                      <div className="d-flex flex-row align-items-center justify-content-between container-units">
                        <Minus onClick={() => dispatch(removeProductUnit(productInShoppingCart.productId))} className="clickable" size={12} color="black" />
                        <span className="product-units noselect">{productInShoppingCart.units}</span>
                        <Plus onClick={() => dispatch(addProductUnit(productInShoppingCart.productId))} className="clickable" size={12} color="black" />
                      </div>
                      <span onClick={() => dispatch(removeProduct(productInShoppingCart.productId))} className="delete noselect">
                        Eliminar
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="d-flex flex-row justify-content-between container-total">
              <span className="total">Subtotal</span>
              <span className="total-price">{formatNumber(total)}€</span>
            </div>
            <div className="d-flex flex-row justify-content-between container-buttons">
              <button onClick={save} disabled={!isValid || requesting} className="save">
                Guardar
              </button>
              <button onClick={checkout} disabled={!isValid || requesting} className="finalize">
                Checkout
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ShoppingCartPurchaseOrder;
