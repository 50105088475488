import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, DollarSign, Download, Image, Plus, Upload } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import ConfirmModal from '../components/confirm-modal';
import CreateStockModal from '../components/create-stock-modal';
import ImportCsvModal from '../components/import-csv-modal';
import ImportStockModal from '../components/import-stock-modal';
import MiraviaIcon from '../components/miravia-icon';
import NoStoresAssigned from '../components/no-stores-assigned';
import Paginator from '../components/paginator';
import ShopifyIcon from '../components/shopify-icon';
import UpdateOnlineStockModal from '../components/update-online-stock-modal';
import UploadProductImageModal from '../components/upload-product-image-modal';
import WooCommerceIcon from '../components/woocommerce-icon';
import { Entity } from '../enums/entity';
import { OnlineStoreType } from '../enums/online-store-type';
import { Order } from '../enums/order';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import { useStores } from '../hooks/use-stores.hook';
import { Brand } from '../interfaces/brand';
import { Category } from '../interfaces/category';
import { Collection } from '../interfaces/collection';
import { GetPaginatedStockExtended } from '../interfaces/get-paginated-stock-extended';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { OnlineStore } from '../interfaces/online-store';
import { OnlineStoreStock } from '../interfaces/online-store-stock';
import { Organization } from '../interfaces/organization';
import { PaginatedDto } from '../interfaces/paginated.dto';
import { Product } from '../interfaces/product';
import { ProductImage } from '../interfaces/product-image';
import { RawImage } from '../interfaces/raw-image';
import { Stock } from '../interfaces/stock';
import { StockTotals } from '../interfaces/stock-totals';
import { Store } from '../interfaces/store';
import { UpdateOnlineStoreStock } from '../interfaces/update-online-store-stock.dto';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { downloadPdf, formatNumber, roundTwoDecimals, ucFirst } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

const debounceGetData = debounce(
  async (getPaginatedStockExtended: GetPaginatedStockExtended, cb: (result: { paginatedDto: PaginatedDto<Stock>; stockTotals: StockTotals; onlineStores: OnlineStore[] }) => void) => {
    const result: [PaginatedDto<Stock>, StockTotals, OnlineStore[]] = await Promise.all([
      api.getPaginatedStock(getPaginatedStockExtended),
      api.getStockTotals(getPaginatedStockExtended),
      api.getOnlineStoresWithStock(getPaginatedStockExtended.organizationId),
    ]);
    cb({
      paginatedDto: result[0],
      stockTotals: result[1],
      onlineStores: result[2],
    });
  },
  500,
);

const customStyles = {
  container: (provided: any, state: any) => {
    return {
      ...provided,
      flex: 1,
      fontFamily: 'Inter-Regular',
      fontSize: '14px',
      borderColor: '#a0aec1',
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
  }),
  control: (provided: any, state: any) => {
    return {
      ...provided,
    };
  },
  singleValue: (provided: any, state: any) => {
    return { ...provided };
  },
};

interface Filters {
  // Estado del checkbox de cada tienda online y la cantidad de productos seleccionados
  onlineStores: { [onlineStoreId: number]: { numActiveProducts: number } };
  brandId: { label: string; value: number } | null;
  categoryId: { label: string; value: number } | null;
}

const StockView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const { store, selectedStoreId } = useSelector(storeSelector);
  const [paginatedDto, setPaginatedDto] = useState<PaginatedDto<Stock> | null>(null);
  const [filters, setFilters] = useState<Filters>({
    onlineStores: {},
    brandId: null,
    categoryId: null,
  });
  const [onlineStores, setOnlineStores] = useState<OnlineStore[]>([]);
  // Almacena para cada tienda online y para cada producto si está o no seleccionado
  const [stockOnlineStores, setStockOnlineStores] = useState<{ [onlineStoreId: number]: { [stockId: number]: boolean } }>({});
  const [requesting, setRequesting] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [showUploadProductModal, setShowUploadProductModal] = useState<boolean>(false);
  const [showImportStockModal, setShowImportStockModal] = useState<boolean>(false);
  const [showCreateStockModal, setShowCreateStockModal] = useState<boolean>(false);
  const [getPaginatedStockExtended, setGetPaginatedStockExtended] = useState<GetPaginatedStockExtended | null>(null);
  const [generatingLabels, setGeneratingLabels] = useState<boolean>(false);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [showImportStockCsvModal, setShowImportStockCsvModal] = useState<boolean>(false);
  const [inStockFilter, setInStockFilter] = useState<{ label: string; value: boolean | null } | null>(null);
  const [activeFilter, setActiveFilter] = useState<{ label: string; value: boolean | null } | null>(null);
  const [stockTotals, setStockTotals] = useState<StockTotals | null>(null);
  const [selectedOnlineStore, setSelectedOnlineStore] = useState<OnlineStore | null>(null);
  const [showUpdateOnlineStockModal, setShowUpdateOnlineStockModal] = useState<boolean>(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [collections, setCollections] = useState<Collection[]>([]);
  const userStores: Store[] = useStores();
  const [stores, setStores] = useState<Store[]>([]);
  const [showExportProductsModal, setShowExportProductsModal] = useState<boolean>(false);
  const [exportingProducts, setExportingProducts] = useState<boolean>(false);

  const selectedStores: { label: string; value: number }[] = useMemo(() => {
    if (!getPaginatedStockExtended?.storeIds) {
      return [];
    }
    return stores.filter((store: Store) => getPaginatedStockExtended.storeIds!.includes(store.id)).map((store: Store) => ({ label: store.name, value: store.id }));
  }, [stores, getPaginatedStockExtended?.storeIds]);
  const selectedBrands: { label: string; value: number }[] = useMemo(() => {
    if (!getPaginatedStockExtended?.brandIds) {
      return [];
    }
    return brands.filter((brand: Brand) => getPaginatedStockExtended.brandIds!.includes(brand.id)).map((brand: Brand) => ({ label: brand.name, value: brand.id }));
  }, [brands, getPaginatedStockExtended?.brandIds]);
  const selectedCollections: { label: string; value: number }[] = useMemo(() => {
    if (!getPaginatedStockExtended?.collectionIds) {
      return [];
    }
    return collections
      .filter((collection: Collection) => getPaginatedStockExtended.collectionIds!.includes(collection.id))
      .map((collection: Collection) => ({ label: collection.name, value: collection.id }));
  }, [collections, getPaginatedStockExtended?.collectionIds]);
  const selectedCategories: { label: string; value: number }[] = useMemo(() => {
    if (!getPaginatedStockExtended?.categoryIds) {
      return [];
    }
    return categories.filter((category: Category) => getPaginatedStockExtended.categoryIds!.includes(category.id)).map((category: Category) => ({ label: category.name, value: category.id }));
  }, [categories, getPaginatedStockExtended?.categoryIds]);

  const refreshData = () => {
    setRequesting(true);
    debounceGetData(getPaginatedStockExtended!, (e: { paginatedDto: PaginatedDto<Stock>; stockTotals: StockTotals; onlineStores: OnlineStore[] }) => {
      for (const result of e.paginatedDto.results) {
        result.product.images.sort((a: ProductImage, b: ProductImage) => {
          if (a.isMain) {
            return -1;
          }
          if (b.isMain) {
            return 1;
          }
          return 0;
        });
      }
      setPaginatedDto(e.paginatedDto);
      const mapStores: Map<number, Store> = new Map<number, Store>();
      for (const s of e.paginatedDto.results) {
        if (!mapStores.has(s.store.id)) {
          mapStores.set(s.store.id, s.store);
        }
      }
      const stockOnlineStores: { [onlineStoreId: number]: { [productId: number]: boolean } } = {};
      const tmpOnlineStores: { [onlineStoreId: number]: { numActiveProducts: number } } = {};
      e.onlineStores.forEach((onlineStore: OnlineStore) => {
        stockOnlineStores[onlineStore.id] = {};
        tmpOnlineStores[onlineStore.id] = { numActiveProducts: 0 };
        onlineStore.onlineStock.forEach((oss: OnlineStoreStock) => {
          stockOnlineStores[onlineStore.id][oss.stockId] = oss.active;
          if (oss.active) {
            tmpOnlineStores[onlineStore.id].numActiveProducts += 1;
          }
        });
      });
      setStockOnlineStores(stockOnlineStores);
      setFilters({
        ...filters,
        onlineStores: tmpOnlineStores,
      });
      setOnlineStores(e.onlineStores);
      setStockTotals(e.stockTotals);
      setRequesting(false);
    });
  };

  useEffect(() => {
    let gpse: GetPaginatedStockExtended = {
      organizationId: organization!.id,
      storeIds: [],
      brandIds: [],
      collectionIds: [],
      categoryIds: [],
      inStock: null,
      active: null,
      orderField: 'product.sku',
      order: Order.Asc,
      limit: Constants.LIMIT_RESULTS,
      page: 1,
      query: '',
      online: null,
      totals: false,
    };
    const value: string | null = sessionStorage.getItem('search-stock');
    if (value) {
      try {
        gpse = JSON.parse(value);
        gpse.storeIds = gpse?.storeIds
          ? (gpse.storeIds as any)
              .split(',')
              .filter((e: string) => !isNaN(e as any))
              .map((e: string) => parseInt(e, 10))
          : [];
      } catch (e) {
        sessionStorage.removeItem('search-stock');
      }
    }
    setGetPaginatedStockExtended(gpse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getStores = async () => {
      try {
        const ss: Store[] = await api.getStores(organization!.id);
        setStores(ss);
      } catch (e) {}
    };
    getStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const b: Brand[] = await api.getBrands(organization!.id);
        setBrands(b);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    };
    const getCategories = async () => {
      try {
        const c: Category[] = await api.getCategories(organization!.id);
        setCategories(c);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    };
    const getCollections = async () => {
      try {
        const c: Collection[] = await api.getCollections(organization!.id);
        setCollections(c);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    };
    getBrands();
    getCategories();
    getCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!getPaginatedStockExtended || getPaginatedStockExtended.inStock === null) {
      return;
    }
    setInStockFilter({ label: getPaginatedStockExtended.inStock ? 'Sí' : 'No', value: getPaginatedStockExtended.inStock });
  }, [getPaginatedStockExtended]);

  useEffect(() => {
    if (!getPaginatedStockExtended || getPaginatedStockExtended.active === null) {
      return;
    }
    setActiveFilter({ label: getPaginatedStockExtended.active ? 'Sí' : 'No', value: getPaginatedStockExtended.active });
  }, [getPaginatedStockExtended]);

  useEffect(() => {
    if (getPaginatedStockExtended === null) {
      return;
    }
    sessionStorage.setItem('search-stock', JSON.stringify({ ...getPaginatedStockExtended, page: 1 }));
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPaginatedStockExtended]);

  const onChangeStockProduct = async (onlineStore: OnlineStore, stockId: number, checked: boolean) => {
    let stockOnlineStore: { [stockId: number]: boolean } = {};
    if (stockOnlineStores.hasOwnProperty(onlineStore.id)) {
      stockOnlineStore = { ...stockOnlineStores[onlineStore.id] };
    }
    if (!stockOnlineStore.hasOwnProperty(stockId)) {
      stockOnlineStore[stockId] = false;
    }
    stockOnlineStore[stockId] = checked;
    // Comprobar si el checkbox de todos los productos está activo a partir de la selección de cada uno de ellos (ignorar aquellos que no se pueden activar por la configuración de la tienda online)
    let numActiveProducts: number = 0;
    if (paginatedDto !== null) {
      for (const fs of paginatedDto.results) {
        // La tienda online tiene asignada una tienda física por defecto?
        if (onlineStore.defaultStoreId && onlineStore.defaultStoreId > 0) {
          // El stock que estamos revisando es de una tienda física diferente a la tienda predeterminada de la tienda online y además la tienda online NO permite servir pedidos de otras tiendas físicas?
          if (fs.store.id !== onlineStore.defaultStoreId && !onlineStore.useStockRestStores) {
            // No se actualiza el stock de esta tienda física
            continue;
          }
        }
        numActiveProducts += stockOnlineStore[fs.id] ? 1 : 0;
      }
    }
    // Actualizar en el backend el stock de la tienda online
    setRequesting(true);
    try {
      const updateOnlineStoreStock: UpdateOnlineStoreStock = {
        stockIds: [stockId],
        active: checked,
      };
      await api.updateOnlineStoreStock(onlineStore.id, updateOnlineStoreStock);
    } catch (e) {}
    // Actualizar el estado global del componente
    setStockOnlineStores((prev) => ({ ...prev, [onlineStore.id]: stockOnlineStore }));
    setFilters({
      ...filters,
      onlineStores: {
        ...filters.onlineStores,
        [onlineStore.id]: {
          numActiveProducts,
        },
      },
    });
    setRequesting(false);
  };

  const onCloseUploadProductImageModal = (productImage: ProductImage[] | RawImage | null) => {
    if (productImage !== null) {
      refreshData();
    }
    setSelectedProduct(null);
    setShowUploadProductModal(false);
  };

  const generateCsv = async () => {
    if (exportingProducts) {
      return;
    }
    setExportingProducts(true);
    try {
      const id: JQuery<HTMLElement> = myToastr.info('Exportando productos. Espere por favor.');
      const buffer: Buffer = await api.exportStock(getPaginatedStockExtended!);
      const blob: Blob = new Blob([buffer], { type: 'text/csv' });
      const url: string = window.URL.createObjectURL(blob);
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.download = 'productos.csv';
      link.click();
      link.remove();
      myToastr.remove(id);
    } catch (e) {
      myToastr.error('Hubo un error exportando los productos');
    } finally {
      setExportingProducts(false);
    }
  };

  const onCloseImportStockModal = (s: Stock[] | null) => {
    if (s !== null && s.length > 0) {
      refreshData();
    }
    setShowImportStockModal(false);
  };

  const getProductsLabels = async () => {
    if (selectedStoreId <= 0) {
      myToastr.error(organizationType === OrganizationType.B2C ? 'Debe seleccionar una tienda' : 'Debe seleccionar un almacén');
      return;
    }
    if (generatingLabels) {
      return;
    }
    try {
      myToastr.info('Generando etiquetas. Espere por favor.');
      setGeneratingLabels(true);
      const result: ArrayBuffer = await api.getStockLabels(getPaginatedStockExtended!);
      downloadPdf(result, 'etiquetas-productos.pdf');
    } catch (e) {
      myToastr.error('Hubo un error obteniendo la etiqueta del producto');
    } finally {
      setGeneratingLabels(false);
    }
  };

  const onCloseImportStockCsvModal = (result: boolean) => {
    if (result) {
      setGetPaginatedStockExtended({ ...getPaginatedStockExtended!, page: 1 });
    }
    setShowImportStockCsvModal(false);
  };

  const onCloseUpdateOnlineStockModal = (result: boolean) => {
    if (result) {
      setGetPaginatedStockExtended({ ...getPaginatedStockExtended!, page: 1 });
    }
    setSelectedOnlineStore(null);
    setShowUpdateOnlineStockModal(false);
  };

  const onCloseExportsProductsModal = async (result: boolean) => {
    if (!result) {
      setShowExportProductsModal(false);
      return;
    }
    await generateCsv();
    setShowExportProductsModal(false);
  };

  const onCloseCreateStockModal = async (newStock: Stock | null) => {
    if (!newStock) {
      setShowCreateStockModal(false);
      return;
    }
    refreshData();
    setShowCreateStockModal(false);
  };

  if (getPaginatedStockExtended === null) {
    return null;
  }

  if (userStores.length === 0) {
    return <NoStoresAssigned />;
  }

  return (
    <div className="products-view p-4">
      <div className="d-flex flex-row align-items-center mb-2">
        <div className="d-flex align-items-center flex-grow-1">
          <h1 className="me-4">Stock</h1>
          {onlineStores.length > 0 && (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
            <div className="check-switch">
              <input
                type="checkbox"
                checked={getPaginatedStockExtended.online || false}
                onChange={(e: any) => setGetPaginatedStockExtended({ ...getPaginatedStockExtended, online: !e.target.checked ? null : true, page: 1 })}
              />
              <label className="check-totals">Online</label>
            </div>
          )}
          {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
            <div className="check-switch">
              <input
                type="checkbox"
                checked={getPaginatedStockExtended?.totals || false}
                onChange={(e: any) => setGetPaginatedStockExtended({ ...getPaginatedStockExtended, totals: e.target.checked })}
              />
              <label className="check-totals">Totales</label>
            </div>
          )}
        </div>
        {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
          <div
            className="d-flex align-items-center export-csv cursor-pointer"
            onClick={() => {
              if (!store) {
                myToastr.error(organizationType === OrganizationType.B2C ? 'Selecciona una tienda' : 'Selecciona un almacén');
                return;
              }
              setShowImportStockCsvModal(true);
            }}
            title="Importar productos"
          >
            <Upload className="me-1" size={14} /> Importar
          </div>
        )}
        <div className="d-flex align-items-center export-csv clickable" onClick={() => setShowExportProductsModal(true)} title="Exportar productos">
          <Download className="me-1" size={14} />
          <span>Exportar</span>
        </div>
        {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
          <div className="d-flex align-items-center export-csv cursor-pointer" onClick={() => setShowImportStockModal(true)}>
            <DollarSign className="me-1" size={14} /> Ajustar precio/stock
          </div>
        )}
        {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
          <div
            className="d-flex align-items-center export-csv cursor-pointer"
            onClick={() => {
              if (selectedStoreId <= 0) {
                myToastr.error(organizationType === OrganizationType.B2C ? 'Debe seleccionar una tienda' : 'Debe seleccionar un almacén');
                return;
              }
              setShowCreateStockModal(true);
            }}
          >
            <Plus className="me-1" size={14} /> Añadir producto
          </div>
        )}
        <button className="create-button ms-1" disabled={generatingLabels || paginatedDto === null || paginatedDto.results.length === 0} onClick={getProductsLabels}>
          Descargar etiquetas
        </button>
      </div>
      {getPaginatedStockExtended.totals && stockTotals !== null && (
        <div className="totals-wrapper d-flex justify-content-around">
          <span className="totals-info">Precio coste: {formatNumber(stockTotals?.price)}€</span>
          <span className="totals-info">Precio total: {organizationType === OrganizationType.B2B ? formatNumber(stockTotals?.retailPrice) : formatNumber(stockTotals?.pvp)}€</span>
          <span className="totals-info">Unidades: {formatNumber(stockTotals?.quantity, true)}</span>
          <span className="totals-info">Productos: {formatNumber(stockTotals?.totalItems, true)}</span>
        </div>
      )}
      <div className="filters-bar d-flex align-items-center my-3">
        <div className="me-1 sup-label" style={{ width: '180px' }}>
          <span className="">Buscar</span>
          <input
            className="input-filter"
            type="text"
            placeholder="Buscar producto..."
            value={getPaginatedStockExtended.query}
            onChange={(e: any) => setGetPaginatedStockExtended({ ...getPaginatedStockExtended, query: e.target.value || '', page: 1 })}
            style={{ width: '100%' }}
          />
        </div>
        {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
          <div className="me-1 sup-label" style={{ width: '200px' }}>
            <span className="">{organizationType === OrganizationType.B2C ? 'Tiendas' : 'Almacenes'}</span>
            <Select
              isClearable
              placeholder={organizationType === OrganizationType.B2C ? 'Tiendas...' : 'Almacenes...'}
              name="storeIds"
              closeMenuOnSelect={false}
              options={stores.map((s: Store) => ({
                label: s.name,
                value: s.id,
              }))}
              value={selectedStores}
              isMulti
              onChange={(e: any) => setGetPaginatedStockExtended({ ...getPaginatedStockExtended, storeIds: e.map((s: { label: string; value: number }) => s.value) })}
              styles={customStyles}
              className="select-comp"
            />
          </div>
        )}
        <div className="mx-1 sup-label" style={{ width: '135px' }}>
          <span className="">En stock</span>
          <Select
            isClearable
            placeholder="En stock..."
            name="inStock"
            options={[
              {
                label: 'Todos',
                value: null,
              },
              {
                label: 'Sí',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            value={inStockFilter}
            onChange={(e: any) => {
              setInStockFilter(e);
              setGetPaginatedStockExtended({ ...getPaginatedStockExtended, inStock: e !== null && e.hasOwnProperty('value') ? e.value : null });
            }}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <div className="mx-1 sup-label" style={{ width: '135px' }}>
          <span className="">Activo</span>
          <Select
            isClearable
            placeholder="Activo..."
            name="active"
            options={[
              {
                label: 'Todos',
                value: null,
              },
              {
                label: 'Sí',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            value={activeFilter}
            onChange={(e: any) => {
              setActiveFilter(e);
              setGetPaginatedStockExtended({ ...getPaginatedStockExtended, active: e !== null && e.hasOwnProperty('value') ? e.value : null });
            }}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <div className="ms-1 sup-label" style={{ width: '15%' }}>
          <span className="">Marcas</span>
          <Select
            isClearable
            placeholder="Marcas..."
            name="brandIds"
            options={brands.map((brand: Brand) => ({
              label: brand.name,
              value: brand.id,
            }))}
            value={selectedBrands}
            isMulti
            onChange={(e: any) => setGetPaginatedStockExtended({ ...getPaginatedStockExtended, brandIds: e.map((b: { label: string; value: number }) => b.value) })}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <div className="ms-1 sup-label" style={{ width: '15%' }}>
          <span className="">Colecciones</span>
          <Select
            isClearable
            placeholder="Colecciones..."
            name="collectionIds"
            options={collections.map((collection: Collection) => ({
              label: collection.name,
              value: collection.id,
            }))}
            value={selectedCollections}
            isMulti
            onChange={(e: any) => setGetPaginatedStockExtended({ ...getPaginatedStockExtended, collectionIds: e.map((b: { label: string; value: number }) => b.value) })}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <div className="ms-1 sup-label" style={{ width: '15%' }}>
          <span className="">Categorías</span>
          <Select
            isClearable
            placeholder="Categorías..."
            name="categoryIds"
            options={categories.map((category: Category) => ({
              label: category.name,
              value: category.id,
            }))}
            value={selectedCategories}
            isMulti
            onChange={(e) => setGetPaginatedStockExtended({ ...getPaginatedStockExtended, categoryIds: e.map((c: { label: string; value: number }) => c.value) })}
            styles={customStyles}
            className="select-comp"
          />
        </div>
        <button
          className="clear-filters ms-1"
          disabled={requesting}
          onClick={() => {
            setGetPaginatedStockExtended({
              ...getPaginatedStockExtended,
              online: null,
              totals: false,
              query: '',
              inStock: null,
              storeIds: [],
              brandIds: [],
              collectionIds: [],
              categoryIds: [],
              active: null,
              page: 1,
            });
            setInStockFilter(null);
            setActiveFilter(null);
          }}
        >
          Limpiar filtros
        </button>
        <div className="ms-2">
          <span className="num-products">{formatNumber(stockTotals?.totalItems, true)}</span>
        </div>
      </div>
      <table className="my-table">
        <thead>
          <tr>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedStockExtended.orderField === 'product.sku') {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'product.sku', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Sku</span>
                {getPaginatedStockExtended.orderField === 'product.sku' ? (
                  <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedStockExtended.orderField === 'product.name') {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'product.name', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Producto</span>
                {getPaginatedStockExtended.orderField === 'product.name' ? (
                  <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <th>
                <div
                  className="flex flex-row clickable"
                  onClick={() => {
                    if (getPaginatedStockExtended.orderField === 'store.name') {
                      setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                    } else {
                      setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'store.name', order: Order.Asc, page: 1 });
                    }
                  }}
                >
                  <span>{organizationType === OrganizationType.B2C ? 'Tienda' : 'Almacén'}</span>
                  {getPaginatedStockExtended.orderField === 'store.name' ? (
                    <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                  ) : null}
                </div>
              </th>
            )}
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedStockExtended.orderField === 'brand.name') {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'brand.name', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Marca</span>
                {getPaginatedStockExtended.orderField === 'brand.name' ? (
                  <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedStockExtended.orderField === 'collection.name') {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'collection.name', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Colección</span>
                {getPaginatedStockExtended.orderField === 'collection.name' ? (
                  <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedStockExtended.orderField === 'category.name') {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'category.name', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>Categorías</span>
                {getPaginatedStockExtended.orderField === 'category.name' ? (
                  <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <th>
                <div
                  className="flex flex-row clickable"
                  onClick={() => {
                    if (getPaginatedStockExtended.orderField === 'stock.price') {
                      setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                    } else {
                      setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'stock.price', order: Order.Asc, page: 1 });
                    }
                  }}
                >
                  <span>Coste unitario</span>
                  {getPaginatedStockExtended.orderField === 'stock.price' ? (
                    <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                  ) : null}
                </div>
              </th>
            )}
            {organizationType === OrganizationType.B2B && (
              <th>
                <div
                  className="flex flex-row clickable"
                  onClick={() => {
                    if (getPaginatedStockExtended.orderField === 'stock.retailPrice') {
                      setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                    } else {
                      setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'stock.retailPrice', order: Order.Asc, page: 1 });
                    }
                  }}
                >
                  <span>PVM</span>
                  {getPaginatedStockExtended.orderField === 'stock.retailPrice' ? (
                    <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                  ) : null}
                </div>
              </th>
            )}
            <th>
              <div
                className="flex flex-row clickable"
                onClick={() => {
                  if (getPaginatedStockExtended.orderField === 'stock.pvp') {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                  } else {
                    setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'stock.pvp', order: Order.Asc, page: 1 });
                  }
                }}
              >
                <span>PVP</span>
                {getPaginatedStockExtended.orderField === 'stock.pvp' ? (
                  <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                ) : null}
              </div>
            </th>
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <th>
                <div
                  className="flex flex-row clickable"
                  onClick={() => {
                    if (getPaginatedStockExtended.orderField === 'stock.quantity') {
                      setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                    } else {
                      setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'stock.quantity', order: Order.Asc, page: 1 });
                    }
                  }}
                >
                  <span>Unidades</span>
                  {getPaginatedStockExtended.orderField === 'stock.quantity' ? (
                    <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                  ) : null}
                </div>
              </th>
            )}
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <th>
                <div
                  className="flex flex-row clickable"
                  onClick={() => {
                    if (getPaginatedStockExtended.orderField === 'stock.weight') {
                      setGetPaginatedStockExtended({ ...getPaginatedStockExtended, order: getPaginatedStockExtended.order === Order.Asc ? Order.Desc : Order.Asc, page: 1 });
                    } else {
                      setGetPaginatedStockExtended({ ...getPaginatedStockExtended, orderField: 'stock.weight', order: Order.Asc, page: 1 });
                    }
                  }}
                >
                  <span>Peso</span>
                  {getPaginatedStockExtended.orderField === 'stock.weight' ? (
                    <span className="ms-2">{getPaginatedStockExtended.order === Order.Asc ? <ChevronUp color="black" size={16} /> : <ChevronDown color="black" size={16} />}</span>
                  ) : null}
                </div>
              </th>
            )}
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <th>
                <div className="d-flex flex-column">
                  <span>Coste total</span>
                </div>
              </th>
            )}
            <th>Estado</th>
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) &&
              onlineStores.map((onlineStore: OnlineStore) => {
                return (
                  <th
                    key={onlineStore.id}
                    title={onlineStore.name}
                    onClick={() => {
                      setShowUpdateOnlineStockModal(true);
                      setSelectedOnlineStore(onlineStore);
                    }}
                  >
                    <div className="d-flex flex-row align-items-center me-1 clickable" title={ucFirst(onlineStore.type)}>
                      {onlineStore.type === OnlineStoreType.WooCommerce && <WooCommerceIcon />}
                      {onlineStore.type === OnlineStoreType.Shopify && <ShopifyIcon />}
                      {onlineStore.type === OnlineStoreType.Miravia && <MiraviaIcon height={25} width={25} />}
                    </div>
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {paginatedDto === null || requesting ? (
            <tr>
              <td colSpan={20} className="text-center">
                <Loader type="TailSpin" color="#252E3C" height={25} width={25} />
              </td>
            </tr>
          ) : paginatedDto?.results.length === 0 ? (
            <tr>
              <td colSpan={20} className="text-center">
                No hay productos
              </td>
            </tr>
          ) : (
            paginatedDto?.results.map((s: Stock) => {
              let productName: string = s.product.name;
              if (s.product.name.length > 30) {
                productName = s.product.name.substring(0, 50) + '...';
              }
              return (
                <tr id={`tr-${s.id}`} key={s.id}>
                  <td>
                    <Link to={`/producto/${s.product.id}`} className="position-relative link-sale">
                      <span className="fw-bold">{s.product.sku}</span>
                    </Link>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="me-2">
                        {s.product.images.length > 0 ? (
                          <LightgalleryProvider>
                            {s.product.images.map((productImage: ProductImage, index: number) => {
                              return (
                                <LightgalleryItem key={productImage.id} group="group-product-images" src={process.env.REACT_APP_PUBLIC_URL + productImage.path}>
                                  {index === 0 && <LazyLoadImage src={process.env.REACT_APP_PUBLIC_URL + productImage.path} alt="" className="img-thumbnail clickable" />}
                                </LightgalleryItem>
                              );
                            })}
                          </LightgalleryProvider>
                        ) : (
                          <Image size={50} className="img-thumbnail my-1 me-2" color="#D3D3D3" />
                        )}
                      </div>
                      <span className="product-name">{productName}</span>
                    </div>
                  </td>
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && <th>{s.store.name}</th>}
                  <td>{s.product.brand.name}</td>
                  <td>{s.product.collection?.name}</td>
                  <td>{s.product.categories.map((c: Category) => c.name).join(', ')}</td>
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && <td>{formatNumber(s.price)}€</td>}
                  {organizationType === OrganizationType.B2B && <td>{formatNumber(s.retailPrice)}€</td>}
                  <td>{formatNumber(s.pvp)}€</td>
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && <td>{s.quantity}</td>}
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && <td>{s.weight ? `${s.weight}gr` : ''}</td>}
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && <td>{formatNumber(roundTwoDecimals(s.quantity * s.price))}€</td>}
                  <td>
                    <span className={`badge ${s.enabled ? 'my-success' : 'my-danger'}`}>{s.enabled ? 'Activo' : 'Inactivo'}</span>
                  </td>
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) &&
                    onlineStores.map((onlineStore: OnlineStore) => {
                      let disabled: boolean;
                      let checked = false;
                      if (s.store.organizationId !== onlineStore.organizationId) {
                        disabled = true;
                      } else {
                        if (onlineStore.isActive) {
                          if (onlineStore.defaultStoreId && onlineStore.defaultStoreId > 0) {
                            if (onlineStore.useStockRestStores) {
                              disabled = false;
                            } else {
                              disabled = s.store.id !== onlineStore.defaultStoreId;
                            }
                          } else {
                            disabled = false;
                          }
                        } else {
                          disabled = true;
                        }
                        if (stockOnlineStores.hasOwnProperty(onlineStore.id) && stockOnlineStores[onlineStore.id].hasOwnProperty(s.id)) {
                          checked = stockOnlineStores[onlineStore.id][s.id];
                        }
                      }
                      return (
                        <td key={`${s.id}-${onlineStore.id}`} className="text-left">
                          <input
                            type="checkbox"
                            disabled={disabled || requesting}
                            checked={checked}
                            onChange={(e) => {
                              onChangeStockProduct(onlineStore, s.id, e.target.checked);
                            }}
                            style={{ width: 'auto' }}
                          />
                        </td>
                      );
                    })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {paginatedDto !== null && paginatedDto.results.length > 0 && (
        <Paginator paginatedDto={paginatedDto} goToPage={(page: number) => setGetPaginatedStockExtended({ ...getPaginatedStockExtended!, page })} />
      )}
      {selectedProduct && <UploadProductImageModal returnRawData={false} product={selectedProduct} show={showUploadProductModal} closeModal={onCloseUploadProductImageModal} />}
      <ImportStockModal show={showImportStockModal} closeModal={onCloseImportStockModal} />
      <ImportCsvModal show={showImportStockCsvModal} closeModal={onCloseImportStockCsvModal} entity={Entity.Stock} />
      {selectedOnlineStore && (
        <UpdateOnlineStockModal
          numProducts={stockTotals?.totalItems || 0}
          onlineStore={selectedOnlineStore}
          getPaginatedStockExtended={getPaginatedStockExtended}
          show={showUpdateOnlineStockModal}
          onClose={onCloseUpdateOnlineStockModal}
        />
      )}
      {store !== null && showCreateStockModal && <CreateStockModal show={showCreateStockModal} closeModal={onCloseCreateStockModal} />}
      <ConfirmModal
        title="Exportar productos"
        content={`Se van a exportar ${formatNumber(stockTotals?.totalItems, true)} productos.</br>¿Desea continuar?`}
        acceptButtonClass="accept-button"
        disabled={exportingProducts}
        show={showExportProductsModal}
        closeModal={onCloseExportsProductsModal}
      />
    </div>
  );
};

export default StockView;
