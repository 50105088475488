import clsx from 'clsx';
import Fuse from 'fuse.js';
import React, { useEffect, useMemo, useState } from 'react';
import { ChevronLeft, Image, Minus, Plus, Search } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '../components/confirm-modal';
import NoteModal from '../components/note-modal';
import { Role } from '../enums/role';
import { TransferStatus } from '../enums/transfer-status';
import { Category } from '../interfaces/category';
import { GetProductsTags } from '../interfaces/get-product-tags';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { ProductTransfer } from '../interfaces/product-transfer';
import { ProductTransferDto } from '../interfaces/product-transfer.dto';
import { Transfer } from '../interfaces/transfer';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { downloadPdf } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

enum RowStatus {
  All = 'all',
  Filled = 'filled',
  Empty = 'empty',
}

const VerifyTransferView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [showVerifyTransferModal, setShowVerifyTransferModal] = useState<boolean>(false);
  const [showDeleteTransferModal, setShowTransferModal] = useState<boolean>(false);
  const [transfer, setTransfer] = useState<Transfer | null>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedBrandId, setSelectedBrandId] = useState<number>(-1);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1);
  const [selectedStatus, setSelectedStatus] = useState<RowStatus>(RowStatus.All);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [filteredProductTransfers, setFilteredProductTransfers] = useState<ProductTransfer[]>([]);
  const [units, setUnits] = useState<{ [productId: number]: number }>({});
  const [requesting, setRequesting] = useState<boolean>(false);
  const [generatingLabels, setGeneratingLabels] = useState<boolean>(false);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const brands: { id: number; name: string }[] = useMemo(() => {
    let brs: { id: number; name: string }[] = [];
    if (transfer) {
      transfer.transferredProducts.forEach((productTransfer: ProductTransfer) => {
        const index: number = brs.findIndex((b: { id: number; name: string }) => b.id === productTransfer.product.brand.id);
        if (index === -1) {
          brs.push({ id: productTransfer.product.brand.id, name: productTransfer.product.brand.name });
        }
      });
    }
    return brs;
  }, [transfer]);
  const categories: { id: number; name: string }[] = useMemo(() => {
    let crs: { id: number; name: string }[] = [];
    if (transfer) {
      transfer.transferredProducts.forEach((productTransfer: ProductTransfer) => {
        productTransfer.product.categories.forEach((category: Category) => {
          const index: number = crs.findIndex((c: { id: number; name: string }) => c.id === category.id);
          if (index === -1) {
            crs.push({ id: category.id, name: category.name });
          }
        });
      });
    }
    return crs;
  }, [transfer]);

  useEffect(() => {
    if (params?.id) {
      const getTransfer = async () => {
        try {
          const t: Transfer = await api.getTransferToVerify(parseInt(params.id!, 10));
          if (t.status !== TransferStatus.Pending) {
            navigate(`/traspaso/${t.id}`);
            return;
          }
          setTransfer(t);
        } catch (e: any) {
          myToastr.error(e.response.data.message);
          navigate('/traspasos', { replace: true });
        }
      };
      getTransfer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!transfer) {
      return;
    }
    let filteredStock: ProductTransfer[] = [...transfer.transferredProducts];
    if (searchText.length > 0) {
      const fuse = new Fuse(filteredStock, {
        keys: ['product.name', 'product.sku', 'product.ean'],
      });
      const fuseResult: Fuse.FuseResult<ProductTransfer>[] = fuse.search(searchText);
      filteredStock = fuseResult.map((result: Fuse.FuseResult<ProductTransfer>) => result.item);
    }
    // Marca
    if (selectedBrandId > 0) {
      filteredStock = filteredStock.filter((s: ProductTransfer) => s.product.brandId === selectedBrandId);
    }
    // Categoría
    if (selectedCategoryId > 0) {
      filteredStock = filteredStock.filter((s: ProductTransfer) => {
        const category = s.product.categories.find((c: Category) => c.id === selectedCategoryId);
        return category !== undefined;
      });
    }
    // Estado
    if (selectedStatus === RowStatus.Filled) {
      filteredStock = filteredStock.filter((s: ProductTransfer) => units.hasOwnProperty(s.productId));
    } else if (selectedStatus === RowStatus.Empty) {
      filteredStock = filteredStock.filter((s: ProductTransfer) => !units.hasOwnProperty(s.productId));
    }
    setFilteredProductTransfers(filteredStock);
  }, [searchText, selectedBrandId, selectedCategoryId, selectedStatus, units, transfer]);

  if (!transfer) {
    return null;
  }

  const updateUnits = (productId: number, value: number | null) => {
    const u: { [productId: number]: number } = { ...units };
    if (value === null) {
      delete u[productId];
    } else {
      u[productId] = value;
    }
    setUnits({ ...u });
  };

  const incrementUnits = (productId: number) => {
    const u: { [productId: number]: number } = { ...units };
    if (u.hasOwnProperty(productId)) {
      u[productId] = u[productId] + 1;
    } else {
      u[productId] = 1;
    }
    setUnits({ ...u });
  };

  const decrementUnits = (productId: number) => {
    const u: { [productId: number]: number } = { ...units };
    if (u.hasOwnProperty(productId)) {
      u[productId] = u[productId] - 1;
      if (u[productId] < 0) {
        delete u[productId];
      }
    }
    setUnits({ ...u });
  };

  const deleteTransfer = async () => {
    try {
      setRequesting(true);
      await api.deleteTransfer(transfer!.id);
      myToastr.info('Traspaso eliminado');
      navigate('/traspasos', { replace: true });
    } catch (e: any) {
      myToastr.error(e.response.data.message);
      setRequesting(false);
    }
  };

  const verifyTransfer = async () => {
    try {
      setRequesting(true);
      const transferredProductDtos: ProductTransferDto[] = [];
      for (const productId in units) {
        if (units.hasOwnProperty(productId)) {
          transferredProductDtos.push({ productId: parseInt(productId, 10), quantity: units[productId] });
        }
      }
      await api.verifyTransfer(transfer.id, transferredProductDtos);
      navigate('/traspasos', { replace: true });
    } catch (e: any) {
      myToastr.error(e.response.data.message);
      setRequesting(false);
    }
  };

  const getProductsLabels = async () => {
    if (generatingLabels) {
      return;
    }
    try {
      myToastr.info('Generando etiquetas. Este proceso puede llevar segundos. Espere por favor...');
      setGeneratingLabels(true);
      const getProductsTags: GetProductsTags = {
        organizationId: organization!.id,
        storeIds: [transfer.fromStoreId],
        products: transfer.transferredProducts.map((productTransfer: ProductTransfer) => {
          return {
            productId: productTransfer.productId,
            quantity: productTransfer.unitsShipped,
          };
        }),
      };
      const result: ArrayBuffer = await api.getProductsLabels(getProductsTags);
      downloadPdf(result, `etiquetas-traspaso-${transfer.internalId}.pdf`);
    } catch (e) {
      myToastr.error('Hubo un error obteniendo la etiqueta del producto');
    } finally {
      setGeneratingLabels(false);
    }
  };

  const onCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  const onSaveNotes = async (notes: string | null) => {
    try {
      const t: Transfer = await api.updateTransferNotes(transfer.id, notes);
      setTransfer(t);
      setShowNoteModal(false);
      myToastr.info('Notas guardadas');
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

  return (
    <div className="verify-transfer-view position-relative p-4">
      <div className="d-flex flex-column h-100">
        <div className="d-flex flex-row align-items-center mb-2">
          <Link to="/traspasos" className="d-flex flex-row align-items-center come-back me-4">
            <ChevronLeft className="me-1" size={14} />
            <span>Volver</span>
          </Link>
          <div className="flex flex-column flex-grow-1">
            <h1 className="title">Verificar traspaso {transfer.internalId}</h1>
            <div className="flex flex-row subtitle">
              <span>
                Tienda origen: <strong>{transfer.fromStore.name}</strong>
              </span>
              <span className="ms-4">
                Tienda destino: <strong>{transfer.toStore.name}</strong>
              </span>
            </div>
          </div>
          <div
            onClick={() => setShowNoteModal(true)}
            className={clsx('d-flex align-items-center button-header me-3', { 'has-notes': transfer.notes != null && transfer.notes !== '' })}
            style={{ fontSize: '14px', padding: '7px 10px' }}
          >
            {transfer.notes ? (
              '1 Nota'
            ) : (
              <React.Fragment>
                <Plus className="me-1" size={14} />
                <span>Nota</span>
              </React.Fragment>
            )}
          </div>
          <button className="create-button create-expiration me-2" disabled={generatingLabels} onClick={getProductsLabels}>
            Descargar etiquetas
          </button>
        </div>
        <div className="container-filters d-flex flex-row my-2">
          <input type="text" placeholder="Buscar" className="me-1" onChange={(e: any) => setSearchText(e.target.value)} />
          <select name="brand" className="mx-1" onChange={(e: any) => setSelectedBrandId(parseInt(e.target.value, 10))}>
            <option value={-1}>Todas las Marca</option>
            {brands.map((b: { id: number; name: string }) => (
              <option key={b.id} value={b.id}>
                {b.name}
              </option>
            ))}
          </select>
          <select name="category" className="mx-1" onChange={(e: any) => setSelectedCategoryId(parseInt(e.target.value, 10))}>
            <option value={-1}>Todas las Categorías</option>
            {categories.map((c: { id: number; name: string }) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
          <select name="status" className="mx-1" onChange={(e: any) => setSelectedStatus(e.target.value as RowStatus)}>
            <option value={RowStatus.All}>Todos los estados</option>
            <option value={RowStatus.Filled}>Inventariado</option>
            <option value={RowStatus.Empty}>No inventariado</option>
          </select>
        </div>
        <div className="container-table my-2">
          <table>
            <thead>
              <tr>
                <th className="text-start">Producto</th>
                <th className="text-center">SKU</th>
                <th className="text-center">Marca</th>
                <th className="text-center">Categorías</th>
                {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && <th className="text-center">Uds. Enviadas</th>}
                <th className="text-center">Uds. Recibidas</th>
                <th className="text-center">Estado</th>
              </tr>
            </thead>
            <tbody>
              {filteredProductTransfers.length === 0 ? (
                <tr className="text-center">
                  <td colSpan={user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager ? 7 : 6}>No hay productos disponibles con los filtros seleccionados</td>
                </tr>
              ) : (
                filteredProductTransfers.map((s: ProductTransfer) => {
                  let image: string | null = null;
                  if (s.product.images.length > 0) {
                    image = s.product.images[0].path;
                  }
                  return (
                    <tr key={s.productId}>
                      <td className="text-start">
                        <div className="d-flex align-items-center container-product">
                          {s.product.images.length > 0 ? (
                            <>
                              <div
                                style={{ cursor: 'zoom-in' }}
                                onMouseEnter={() => {
                                  setImageUrl(image);
                                }}
                                onMouseLeave={() => {
                                  setImageUrl(null);
                                }}
                              >
                                <Search className="me-3" size={16} color="#808A95" />
                              </div>
                              <LazyLoadImage className="me-3" src={process.env.REACT_APP_PUBLIC_URL + s.product.images[0].path} alt={s.product.name} />
                            </>
                          ) : (
                            <Image className="me-3" size={26} color="#808A95" />
                          )}
                          <span>{s.product.name}</span>
                        </div>
                      </td>
                      <td className="text-center">{s.product.sku}</td>
                      <td className="text-center">{s.product.brand.name}</td>
                      <td className="text-center">{s.product.categories.map((c: Category) => c.name).join(', ')}</td>
                      {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && <td className="text-center">{s.unitsShipped}</td>}
                      <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center container-units">
                          <Minus onClick={() => decrementUnits(s.productId)} className="clickable me-2" size={14} color="#535b66" />
                          <input
                            className="fw-bold"
                            type="number"
                            step={1}
                            min={0}
                            value={units.hasOwnProperty(s.productId) ? units[s.productId] : ''}
                            onChange={(e: any) => updateUnits(s.productId, e?.target?.value && e.target.value.length > 0 ? parseInt(e.target.value, 10) : null)}
                          />
                          <Plus onClick={() => incrementUnits(s.productId)} className="clickable ms-2" size={14} color="#535b66" />
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="d-flex justify-content-center">
                          {units.hasOwnProperty(s.productId) ? (
                            <div className="transfer-product-status-icon filled-icon" title="Verificado"></div>
                          ) : (
                            <div className="transfer-product-status-icon empty-icon" title="Por verificar"></div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-auto container-buttons">
          <div className="d-flex justify-content-end">
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <button disabled={requesting} onClick={() => setShowTransferModal(true)} className="delete-button me-2">
                Eliminar
              </button>
            )}
            <button disabled={requesting || Object.keys(units).length !== transfer.transferredProducts.length} onClick={() => setShowVerifyTransferModal(true)} className="finalize-button">
              Verificar traspaso
            </button>
          </div>
        </div>
      </div>
      {imageUrl != null && (
        <div className="position-absolute top-50 start-50 translate-middle preview-image">
          <div className="d-flex justify-content-center">
            <LazyLoadImage src={process.env.REACT_APP_PUBLIC_URL + imageUrl} alt="" />
          </div>
        </div>
      )}
      <ConfirmModal
        acceptButtonClass="accept-button"
        show={showVerifyTransferModal}
        title="Verificar Traspaso"
        content="¿Estás seguro que quieres verificar el traspaso?"
        closeModal={(result: boolean) => {
          setShowVerifyTransferModal(false);
          if (result) {
            verifyTransfer();
          }
        }}
      ></ConfirmModal>
      <ConfirmModal
        acceptButtonClass="delete-button"
        show={showDeleteTransferModal}
        title="Eliminar Traspaso"
        content="¿Estás seguro que quieres eliminar el traspaso?"
        closeModal={(result: boolean) => {
          setShowTransferModal(false);
          if (result) {
            deleteTransfer();
          }
        }}
      ></ConfirmModal>
      <NoteModal editable={true} show={showNoteModal} notes={transfer.notes} closeModal={onCloseNoteModal} saveNotes={onSaveNotes} deleteNotes={() => onSaveNotes(null)} />
    </div>
  );
};

export default VerifyTransferView;
