import axios, { AxiosError } from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Calendar, ChevronLeft, ChevronRight, Download } from 'react-feather';
import NoteModal from '../components/note-modal';
import WorkShiftsModal from '../components/workshifts-modal';
import { Role } from '../enums/role';
import { GetWorkShiftsQuery } from '../interfaces/get-work-shifts-query';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { WorkShift } from '../interfaces/work-shift';
import { api } from '../services/api';
import { minutesToHourAndMinutesStr, ucFirst } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

moment.locale('es');

const WorkShiftsView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [users, setUsers] = useState<User[]>([]);
  const [workShifts, setWorkShifts] = useState<WorkShift[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [showWorkShiftsModal, setShowWorkShiftsModal] = useState<boolean>(false);
  const [selectedWorkShift, setSelectedWorkShift] = useState<WorkShift | null>(null);
  const [selectedUserDay, setSelectedUserDay] = useState<{ userId: number; date: string } | null>(null);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const days: string[] = useMemo(() => {
    const firstDayOfWeek: moment.Moment = moment(selectedDate).startOf('week');
    const days: string[] = [];
    for (let i = 0; i < 7; i++) {
      days.push(firstDayOfWeek.clone().add(i, 'day').format('YYYY-MM-DD'));
    }
    return days;
  }, [selectedDate]);
  const editable: boolean = useMemo(() => {
    if (!user) {
      return false;
    }
    return user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager;
  }, [user]);

  useEffect(() => {
    if (user.role === Role.Seller) {
      getUser();
    } else {
      getOrganizationUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (days.length === 0) {
      return;
    }
    getWorkShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  const getOrganizationUsers = async () => {
    try {
      const us: User[] = await api.getOrganizationUsers(organization!.id);
      setUsers(us);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getUser = async () => {
    try {
      const u: User = await api.getUser(user!.id);
      setUsers([u]);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getWorkShifts = async () => {
    try {
      const getWorkShiftsQuery: GetWorkShiftsQuery = {
        organizationId: organization!.id,
        startDate: moment(days[0]).toDate(),
        endDate: moment(days[days.length - 1]).toDate(),
      };
      if (user.role === Role.Seller) {
        getWorkShiftsQuery.userId = user.id;
      }
      const workShifts: WorkShift[] = await api.getWorkShifts(getWorkShiftsQuery);
      setWorkShifts(workShifts);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const onCloseWorkShiftsModal = (workShifts: WorkShift[]): void => {
    if (workShifts.length > 0) {
      getWorkShifts();
    }
    setSelectedUserDay(null);
    setSelectedWorkShift(null);
    setShowWorkShiftsModal(false);
  };

  const generateCsv = () => {
    const map: { [userId: number]: { [storeId: number]: number } } = {};
    users.forEach((u: User) => {
      map[u.id] = {};
      days.forEach((day: string) => {
        const wssUserDay: WorkShift[] = workShifts.filter((ws: WorkShift) => ws.userId === u.id && ws.date === day);
        wssUserDay.forEach((w: WorkShift) => {
          if (!map[u.id][w.storeId]) {
            map[u.id][w.storeId] = 0;
          }
          const wssUserDayStore: WorkShift[] = wssUserDay.filter((ws: WorkShift) => ws.storeId === w.storeId);
          map[u.id][w.storeId] = Math.max(map[u.id][w.storeId], wssUserDayStore.length);
        });
      });
    });
    const data: any[] = [];
    for (const userId in map) {
      if (map.hasOwnProperty(userId)) {
        const u: User = users.find((u: User) => u.id === parseInt(userId, 10))!;
        for (const storeId in map[userId]) {
          if (map[userId].hasOwnProperty(storeId)) {
            const userStoreWorkShifts: WorkShift[] = workShifts.filter((w: WorkShift) => w.userId === parseInt(userId, 10) && w.storeId === parseInt(storeId, 10));
            const store: Store = userStoreWorkShifts[0]!.store;
            const numRows: number = map[userId][storeId];
            for (let i = 0; i < numRows; i++) {
              const d: any = {
                Trabajador: `${u.name} ${u.surnames}`,
                Tienda: store.name,
              };
              days.forEach((day: string) => {
                const dayWorkShifts: WorkShift[] = userStoreWorkShifts.filter((w: WorkShift) => w.date === day);
                if (dayWorkShifts.length > i) {
                  const workShift: WorkShift = dayWorkShifts[i];
                  if (workShift.holiday) {
                    d[`${ucFirst(moment(day).format('dddd D'))}`] = 'Vacaciones';
                  } else if (workShift.dayOff) {
                    d[`${ucFirst(moment(day).format('dddd D'))}`] = 'Día libre';
                  } else {
                    d[`${ucFirst(moment(day).format('dddd D'))}`] = `${workShift.clockIn.substring(0, 5)} - ${workShift.clockOut.substring(0, 5)}`;
                  }
                } else {
                  const holidaysUserWorShifts: WorkShift[] = workShifts.filter((w: WorkShift) => w.userId === parseInt(userId, 10) && w.holiday && w.date === day);
                  const dayOffsUserWorShifts: WorkShift[] = workShifts.filter((w: WorkShift) => w.userId === parseInt(userId, 10) && w.dayOff && w.date === day);
                  if (holidaysUserWorShifts.length > 0) {
                    d[`${ucFirst(moment(day).format('dddd D'))}`] = 'Vacaciones';
                  } else if (dayOffsUserWorShifts.length > 0) {
                    d[`${ucFirst(moment(day).format('dddd D'))}`] = 'Día libre';
                  } else {
                    d[`${ucFirst(moment(day).format('dddd D'))}`] = '';
                  }
                }
              });
              data.push(d);
            }
          }
        }
      }
    }
    setCsvData(data);
  };

  return (
    <div className="user-work-clockings-view d-flex flex-column mx-4 my-4">
      <div className="container-header d-flex flex-row align-items-center mb-4">
        <div className="d-flex flex-row align-items-center flex-grow-1">
          <h1 className="mb-0">Turnos</h1>
          <h2 className="mb-0">
            {user.name} {user.surnames}
          </h2>
        </div>
        <CSVLink
          filename={`turnos_semana_${moment(selectedDate).week()}_${ucFirst(moment(selectedDate).format('MMMM YYYY'))}.csv`}
          className="d-flex align-items-center export-csv me-2"
          data={csvData}
          onClick={generateCsv}
          title="Exportar fichaje"
        >
          <Download className="me-1" size={14} /> Exportar
        </CSVLink>
        <div className="d-flex flex-row align-items-center container-year-month no-select">
          <ChevronLeft
            className="cursor-pointer me-1"
            onClick={() => {
              const dateMoment: moment.Moment = moment(selectedDate).subtract(1, 'week');
              setSelectedDate(dateMoment.format('YYYY-MM-DD'));
            }}
            color="gray"
            size={18}
          />
          <Calendar className="mx-2" color="black" size={18} />
          <span className="year-week cursor-default">
            Semana {moment(selectedDate).week()}: {ucFirst(moment(selectedDate).format('MMMM YYYY'))}
          </span>
          <ChevronRight
            className="cursor-pointer ms-2"
            onClick={() => {
              const dateMoment: moment.Moment = moment(selectedDate).add(1, 'week');
              setSelectedDate(dateMoment.format('YYYY-MM-DD'));
            }}
            color="gray"
            size={18}
          />
        </div>
      </div>
      <div className="container-workshifts">
        <table>
          <thead>
            <tr>
              <th className="worker">Trabajador</th>
              {days.map((day: string) => (
                <th key={day}>{ucFirst(moment(day).format('dddd D'))}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {users.map((u: User) => {
              const surnames: string = u.surnames ? u.surnames.split(' ')[0] : '';
              const wssUser: WorkShift[] = workShifts.filter((ws: WorkShift) => ws.userId === u.id);
              const estimatedMinutes: number = wssUser.reduce((acc: number, ws: WorkShift) => {
                const clockInMoment: moment.Moment = moment(ws.clockIn, 'HH:mm');
                const clockOutMoment: moment.Moment = moment(ws.clockOut, 'HH:mm');
                if (clockInMoment.isValid() && clockOutMoment.isValid()) {
                  const diff: number = clockOutMoment.diff(clockInMoment, 'minutes');
                  return acc + diff;
                }
                return acc;
              }, 0);
              return (
                <tr key={u.id}>
                  <td className="worker">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row align-items-center">
                        {u?.color && <span className="user-color" style={{ backgroundColor: u.color }}></span>}
                        <span className="name">
                          {u.name} {surnames}
                        </span>
                      </div>
                      <span className="hours">{minutesToHourAndMinutesStr(estimatedMinutes)}</span>
                    </div>
                  </td>
                  {days.map((day: string) => {
                    const wssUserDay: WorkShift[] = workShifts.filter((ws: WorkShift) => ws.userId === u.id && ws.date === day);
                    const isHoliday: WorkShift | undefined = wssUserDay.find((ws: WorkShift) => ws.holiday);
                    const isDayOff: WorkShift | undefined = wssUserDay.find((ws: WorkShift) => ws.dayOff);
                    const hasNotes: boolean = wssUserDay.some((ws: WorkShift) => ws.notes);
                    return (
                      <td key={`${u.id}-${day}`} className="week-day-workshift">
                        <div className="d-flex flex-column">
                          {isHoliday || isDayOff ? (
                            <div
                              className="d-flex align-items-center justify-content-center container-holiday-day-off"
                              style={{
                                cursor: editable || isHoliday?.notes || isDayOff?.notes ? 'pointer' : 'default',
                              }}
                              onClick={() => {
                                if (!editable) {
                                  if (isHoliday?.notes) {
                                    setSelectedWorkShift(isHoliday);
                                    setShowNoteModal(true);
                                  } else if (isDayOff?.notes) {
                                    setSelectedWorkShift(isDayOff);
                                    setShowNoteModal(true);
                                  }
                                  return;
                                }
                                setSelectedWorkShift(isHoliday ?? isDayOff!);
                                setShowWorkShiftsModal(true);
                              }}
                            >
                              {hasNotes && <span className="notes"></span>}
                              <span className="">
                                {isHoliday ? 'Vacaciones' : ''}
                                {isDayOff ? 'Día libre' : ''}
                              </span>
                            </div>
                          ) : (
                            <React.Fragment>
                              {wssUserDay.map((ws: WorkShift) => {
                                return (
                                  <div
                                    key={ws.id}
                                    className="d-flex flex-column container-user-year-month-week"
                                    style={{
                                      backgroundColor: u?.color,
                                      border: !u?.color ? '0.5px solid gray' : '',
                                      cursor: editable || ws.notes ? 'pointer' : 'default',
                                    }}
                                    onClick={() => {
                                      if (!editable) {
                                        if (ws.notes) {
                                          setSelectedWorkShift(ws);
                                          setShowNoteModal(true);
                                        }
                                        return;
                                      }
                                      setSelectedWorkShift(ws);
                                      setShowWorkShiftsModal(true);
                                    }}
                                  >
                                    {ws.notes && <span className="notes"></span>}
                                    <span className="in-out">
                                      {ws.clockIn.substring(0, 5)} - {ws.clockOut.substring(0, 5)}
                                    </span>
                                    <span className="store">{ws.store.name.length > 20 ? `${ws.store.name.slice(0, 20)}...` : ws.store.name}</span>
                                  </div>
                                );
                              })}
                              {editable && (
                                <div
                                  className="add-workshift"
                                  title="Crear turno"
                                  onClick={() => {
                                    setSelectedUserDay({ userId: u.id, date: day });
                                    setShowWorkShiftsModal(true);
                                  }}
                                >
                                  +
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <WorkShiftsModal show={showWorkShiftsModal} closeModal={onCloseWorkShiftsModal} workShift={selectedWorkShift} selectedUserDay={selectedUserDay} />
      {selectedWorkShift && (
        <NoteModal
          show={showNoteModal}
          notes={selectedWorkShift.notes}
          closeModal={() => {
            setShowNoteModal(false);
            setSelectedWorkShift(null);
          }}
          saveNotes={() => {}}
          deleteNotes={() => {}}
          editable={false}
        />
      )}
    </div>
  );
};

export default WorkShiftsView;
