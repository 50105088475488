import { useState } from 'react';
import { Gift, Mail, Printer } from 'react-feather';

interface Props {
  showGift: boolean;
  onPrint: () => void;
  onSendGift: () => void;
  onSendEmail: () => void;
}

export const TicketOptions = (props: Props) => {
  const [onHoverPrint, setOnHoverPrint] = useState<boolean>(false);
  const [onHoverGift, setOnHoverGift] = useState<boolean>(false);
  const [onHoverEmail, setOnHoverEmail] = useState<boolean>(false);
  return (
    <div className="ticket-options d-flex flex-column mt-4">
      <div className="title mb-3">Ticket</div>
      <div className="row justify-content-around">
        <div className="col-4 px-2">
          <div className="container-contact" onClick={props.onPrint} onMouseEnter={() => setOnHoverPrint(true)} onMouseLeave={() => setOnHoverPrint(false)} title="Imprimir ticket">
            <div className="d-flex align-self-center"></div>
            <div className="d-flex flex-column align-items-center">
              <Printer color={onHoverPrint ? 'white' : '#555555'} size={25} />
              <span className="mt-1">Imprimir ticket</span>
            </div>
          </div>
        </div>
        {props.showGift && (
          <div className="col-4 px-2">
            <div className="container-contact" onClick={props.onSendGift} onMouseEnter={() => setOnHoverGift(true)} onMouseLeave={() => setOnHoverGift(false)} title="Imprimir ticket regalo">
              <div className="d-flex align-self-center"></div>
              <div className="d-flex flex-column align-items-center">
                <Gift color={onHoverGift ? 'white' : '#555555'} size={25} />
                <span className="mt-1">Imprimir ticket regalo</span>
              </div>
            </div>
          </div>
        )}
        <div className="col-4 px-2">
          <div
            className="container-contact"
            onClick={props.onSendEmail}
            onMouseEnter={() => setOnHoverEmail(true)}
            onMouseLeave={() => setOnHoverEmail(false)}
            title="Enviar ticket por correo electrónico"
          >
            <div className="d-flex align-self-center"></div>
            <div className="d-flex flex-column align-items-center">
              <Mail color={onHoverEmail ? 'white' : '#555555'} size={25} />
              <span className="mt-1">Enviar ticket por email</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
