import { UilApps, UilFileCheck } from '@iconscout/react-unicons';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import { ChevronDown, Clipboard, CreditCard, FileText, Inbox, Layers, List, LogOut, Send, ShoppingBag, ShoppingCart, Tag, UserPlus } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Role } from '../enums/role';
import { User } from '../interfaces/user';
import { Constants } from '../services/constants';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { uiSelector } from '../store/ui-slice';
import LogoutModal from './logout-modal';

const AsideB2B = () => {
  const location = useLocation();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const { collapsed } = useSelector(uiSelector);
  const [isOpenedProducts, setIsOpenedProducts] = useState<boolean>(false);
  const [isOpenedShopping, setIsOpenedShopping] = useState<boolean>(false);
  const [isOpenendSales, setIsOpenedSales] = useState<boolean>(false);
  const activeColor: string = Constants.ACTIVE_COLOR_WHOLESALER;
  const showDeliveryNotes: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin, [user]);
  const showExpirations: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin, [user]);
  const showStockControl: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin, [user]);
  const showThresholds: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin, [user]);
  const showInvoices: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin, [user]);
  const showTransfers: boolean = useMemo(() => (user.role === Role.SuperAdmin || user.role === Role.Admin) && user.stores.length > 0, [user]);
  const showProducts: boolean = useMemo(() => user.role === Role.SuperAdmin, [user]);

  useEffect(() => {
    const openedShopping: boolean = location.pathname.startsWith('/compra') || location.pathname.startsWith('/vencimiento');
    setIsOpenedShopping(openedShopping);
    const openedProductsSection: boolean =
      location.pathname.startsWith('/producto') ||
      location.pathname.startsWith('/stock') ||
      location.pathname.startsWith('/control-stock') ||
      location.pathname === '/umbrales' ||
      location.pathname.startsWith('/inventario');
    setIsOpenedProducts(openedProductsSection);
    const openedSales: boolean = location.pathname.startsWith('/pedido') || location.pathname.startsWith('/albaran') || location.pathname.startsWith('/factura');
    setIsOpenedSales(openedSales);
  }, [location.pathname]);

  return (
    <React.Fragment>
      <div className={clsx('aside d-flex flex-column justify-content-between', { collapsed })}>
        <ul className="views">
          {/* <li className={location.pathname === '/' ? 'active' : ''} title="Dashboard">
            <Link to="/" className="d-flex flex-row align-items-center">
              <Home color={location.pathname === '/' ? activeColor : Constants.DISABLED_COLOR} />
              <span>Dashboard</span>
            </Link>
          </li> */}
          {(showDeliveryNotes || showExpirations) && (
            <li title="Compras">
              <div className="d-flex flex-column">
                <div
                  onClick={() => setIsOpenedShopping(!isOpenedShopping)}
                  title="Compras"
                  className={clsx(
                    'd-flex flex-row align-items-center has-submenu',
                    { open: !collapsed && isOpenedShopping },
                    {
                      active: isOpenedShopping,
                    },
                  )}
                >
                  <ShoppingBag color={isOpenedShopping ? activeColor : Constants.DISABLED_COLOR} />
                  <span>Compras</span>
                  <ChevronDown className="to-rotate" color={isOpenedShopping ? activeColor : Constants.DISABLED_COLOR} />
                </div>
                {!collapsed && isOpenedShopping && (
                  <Collapse isOpened={isOpenedShopping}>
                    <ul>
                      {showDeliveryNotes && (
                        <li className={location.pathname.startsWith('/compra') ? 'active' : ''} title="Compras">
                          <Link to="/compras" className="d-flex flex-row align-items-center">
                            <Clipboard color={location.pathname.startsWith('/compra') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Compras</span>
                          </Link>
                        </li>
                      )}
                      {showExpirations && (
                        <li className={location.pathname === '/vencimientos' ? 'active' : ''} title="Vencimientos">
                          <Link to="/vencimientos" className="d-flex flex-row align-items-center">
                            <CreditCard color={location.pathname === '/vencimientos' ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Vencimientos</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                )}
              </div>
            </li>
          )}
          {(showProducts || showStockControl || showThresholds) && (
            <li title="Productos">
              <div className="d-flex flex-column">
                <div
                  onClick={() => setIsOpenedProducts(!isOpenedProducts)}
                  title="Productos"
                  className={clsx(
                    'd-flex flex-row align-items-center has-submenu',
                    { open: !collapsed && isOpenedProducts },
                    {
                      active: isOpenedProducts,
                    },
                  )}
                >
                  <UilApps color={isOpenedProducts ? activeColor : Constants.DISABLED_COLOR} />
                  <span>Productos</span>
                  <ChevronDown className="to-rotate" color={isOpenedProducts ? activeColor : Constants.DISABLED_COLOR} />
                </div>
                {!collapsed && isOpenedProducts && (
                  <Collapse isOpened={isOpenedProducts}>
                    <ul>
                      {showProducts && (
                        <li className={location.pathname.startsWith('/producto') ? 'active' : ''} title="Productos">
                          <Link to="/productos" className="d-flex flex-row align-items-center">
                            <UilApps color={location.pathname.startsWith('/producto') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Productos</span>
                          </Link>
                        </li>
                      )}
                      <li className={location.pathname.startsWith('/stock') ? 'active' : ''} title="Stock">
                        <Link to="/stock" className="d-flex flex-row align-items-center">
                          <Tag color={location.pathname.startsWith('/producto') ? activeColor : Constants.DISABLED_COLOR} />
                          <span>Stock</span>
                        </Link>
                      </li>
                      {showStockControl && (
                        <li className={location.pathname.startsWith('/control-stock') ? 'active' : ''} title="Control stock">
                          <Link to="/control-stock" className="d-flex flex-row align-items-center">
                            <Layers color={location.pathname.startsWith('/control-stock') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Control Stock</span>
                          </Link>
                        </li>
                      )}
                      {showThresholds && (
                        <li className={location.pathname === '/umbrales' ? 'active' : ''} title="Umbrales">
                          <Link to="/umbrales" className="d-flex flex-row align-items-center">
                            <UilFileCheck color={location.pathname === '/umbrales' ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Umbrales</span>
                          </Link>
                        </li>
                      )}
                      <li className={location.pathname.startsWith('/inventario') ? 'active' : ''} title="Inventarios">
                        <Link to="/inventarios" className="d-flex flex-row align-items-center">
                          <List color={location.pathname.startsWith('/inventario') ? activeColor : Constants.DISABLED_COLOR} />
                          <span>Inventarios</span>
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                )}
              </div>
            </li>
          )}
          <li className={location.pathname === '/clientes' ? 'active' : ''} title="Clientes">
            <Link to="/clientes" className="d-flex flex-row align-items-center">
              <UserPlus color={location.pathname === '/clientes' ? activeColor : Constants.DISABLED_COLOR} />
              <span>Clientes</span>
            </Link>
          </li>
          {showTransfers && (
            <li className={location.pathname.startsWith('/traspaso') ? 'active' : ''} title="Traspasos">
              <Link to="/traspasos" className="d-flex flex-row align-items-center">
                <Send color={location.pathname.startsWith('/traspaso') ? activeColor : Constants.DISABLED_COLOR} />
                <span>Traspasos</span>
              </Link>
            </li>
          )}
          {showInvoices && (
            <li title="Ventas">
              <div className="d-flex flex-column">
                <div
                  onClick={() => setIsOpenedSales(!isOpenendSales)}
                  title="Ventas"
                  className={clsx(
                    'd-flex flex-row align-items-center has-submenu',
                    { open: !collapsed && isOpenendSales },
                    {
                      active: isOpenendSales,
                    },
                  )}
                >
                  <Inbox color={isOpenendSales ? activeColor : Constants.DISABLED_COLOR} />
                  <span>Ventas</span>
                  <ChevronDown className="to-rotate" color={isOpenendSales ? activeColor : Constants.DISABLED_COLOR} />
                </div>
                {!collapsed && isOpenendSales && (
                  <Collapse isOpened={isOpenendSales}>
                    <ul>
                      <li className={location.pathname.startsWith('/pedido') ? 'active' : ''} title="Pedidos">
                        <Link to="/pedidos" className="d-flex flex-row align-items-center">
                          <ShoppingCart color={location.pathname.startsWith('/pedido') ? activeColor : Constants.DISABLED_COLOR} />
                          <span>Pedidos</span>
                        </Link>
                      </li>
                      <li className={location.pathname.startsWith('/albaran') ? 'active' : ''}>
                        <Link to="/albaranes" className="d-flex flex-row align-items-center">
                          <Clipboard color={location.pathname.startsWith('/albaran') ? activeColor : Constants.DISABLED_COLOR} />
                          <span>Albaranes</span>
                        </Link>
                      </li>
                      {showInvoices && (
                        <li className={location.pathname.startsWith('/factura') ? 'active' : ''}>
                          <Link to="/facturas" className="d-flex flex-row align-items-center">
                            <FileText color={location.pathname.startsWith('/factura') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Facturas</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                )}
              </div>
            </li>
          )}
        </ul>
        <ul className="menu-logout">
          <li title="Salir">
            <span onClick={() => setShowLogoutModal(true)}>
              <LogOut color={Constants.DISABLED_COLOR} />
              <span>Salir</span>
            </span>
          </li>
        </ul>
      </div>
      <LogoutModal show={showLogoutModal} closeModal={() => setShowLogoutModal(false)} />
    </React.Fragment>
  );
};

export default AsideB2B;
