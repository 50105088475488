import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';
import { Image, Search } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Brand } from '../interfaces/brand';
import { Category } from '../interfaces/category';
import { Organization } from '../interfaces/organization';
import { Stock } from '../interfaces/stock';
import { api } from '../services/api';
import { formatNumber } from '../services/helpers';
import { useAppSelector } from '../store/hooks';
import { addNewProduct } from '../store/sale-slice';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

const getStock = async (storeId: number, brandId: number, categoryId: number, query: string, cb: (s: Stock[]) => void) => {
  const stock: Stock[] = await api.getPosStock(storeId, brandId, categoryId, query);
  cb(stock);
};

const debouncedGetStock = debounce((storeId: number, brandId: number, categoryId: number, query: string, cb: (s: Stock[]) => void) => {
  getStock(storeId, brandId, categoryId, query, cb);
}, 500);

const SaleProductsFinder = () => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const dispatch = useDispatch();
  const [stock, setStock] = useState<Stock[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedBrandId, setSelectedBrandId] = useState<number>(-1);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1);
  const [searchText, setSearchText] = useState<string>('');
  const { selectedStoreId } = useSelector(storeSelector);
  const [requesting, setRequesting] = useState<boolean>(false);

  useEffect(() => {
    const getCategories = async () => {
      const categories: Category[] = await api.getCategories();
      setCategories(categories);
    };
    getCategories();
  }, []);

  useEffect(() => {
    const getBrands = async () => {
      const brands: Brand[] = await api.getBrands(organization!.id);
      setBrands(brands);
    };
    getBrands();
  }, [organization]);

  useEffect(() => {
    if (selectedStoreId === -1 || searchText === '') {
      setStock([]);
      return;
    }
    const getPosStock = async () => {
      setRequesting(true);
      debouncedGetStock(selectedStoreId, selectedBrandId, selectedCategoryId, searchText, (s: Stock[]) => {
        setStock(s);
        setRequesting(false);
      });
    };
    getPosStock();
  }, [selectedStoreId, selectedBrandId, selectedCategoryId, searchText]);

  return (
    <div className="product-finder d-flex flex-column overflow-hidden">
      <div className="mb-4">
        <div className="flex flex-column col">
          <div className="d-flex justify-content-center align-items-center mb-2">
            <h1 className="mb-0">Productos</h1>
          </div>
          <div className="position-relative mb-2">
            <Search className="position-absolute end-0 top-50 translate-middle-y pe-2" />
            <input type="text" placeholder="Buscar" onChange={(e: any) => setSearchText(e.target.value)} disabled={selectedStoreId <= 0} />
          </div>
        </div>
        <div className="row col">
          <div className="col">
            <div className="input-name">Marca</div>
            <select name="category" onChange={(e: any) => setSelectedBrandId(parseInt(e.target.value, 10))} disabled={selectedStoreId <= 0}>
              <option value={-1}>Todas</option>
              {brands.map((brand: Brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.id} - {brand.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <div className="input-name">Categoría</div>
            <select name="category" onChange={(e: any) => setSelectedCategoryId(parseInt(e.target.value, 10))} disabled={selectedStoreId <= 0 || selectedBrandId === -1}>
              <option value={-1}>Todas</option>
              {categories.map((category: Category) => (
                <option key={category.id} value={category.id}>
                  {category.id} - {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        {requesting ? (
          <div className="text-center">
            <Loader type="TailSpin" color="#252E3C" height={40} width={40} />
          </div>
        ) : searchText === '' ? (
          <div></div>
        ) : stock.length === 0 ? (
          <div className="text-center w-100">No hay productos disponibles</div>
        ) : (
          <div className="row product-finder-list-short">
            {stock.map((stock: Stock) => {
              let discountedPrice: number = stock.pvp;
              if (stock.discountValue > 0) {
                discountedPrice = stock.pvp - (stock.pvp * stock.discountValue) / 100;
              }
              return (
                <div
                  key={stock.id}
                  className="product-card col-3 col-md-4 text-center mb-4 mb-md-3"
                  onClick={() => {
                    dispatch(addNewProduct(stock));
                  }}
                >
                  <div className="d-flex flex-row flex-md-column">
                    {stock.product.images.length > 0 ? (
                      <LazyLoadImage className="img-thumbnail" src={process.env.REACT_APP_PUBLIC_URL + stock.product.images[0].path} alt={stock.product.name} width="100px" />
                    ) : (
                      <Image className="img-thumbnail" size={100} color="#808A95" />
                    )}
                    <div className="d-flex flex-column justify-content-around ms-2 product-card-info">
                      <div className="name ">{stock.product.name}</div>
                      <div className="sku ">
                        {stock.product.sku} ({stock.quantity})
                      </div>
                      <div className="price">{formatNumber(discountedPrice)}€</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SaleProductsFinder;
