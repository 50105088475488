import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import Table from '../components/table';
import { OnlineStoreSynchronization } from '../interfaces/online-store-synchronization';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import myToastr from '../services/toastr';

const OnlineStoresSynchronizationsView = () => {
  const [onlineStoresSynchronizations, setOnlineStoreSynchronizations] = useState<OnlineStoreSynchronization[]>([]);
  const [selectedOnlineStoreSynchronization, setOnlineStoreSynchronization] = useState<OnlineStoreSynchronization | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);

  const getData = async () => {
    setRequesting(true);
    const osss: OnlineStoreSynchronization[] = await api.getOnlineStoreSynchronizations();
    setOnlineStoreSynchronizations(osss);
    setRequesting(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Id',
        accessor: 'id',
        filter: 'fuzzyText',
        Cell: ({ value }: any) => {
          return (
            <Link to={`/sincronizacion/${value}`} className="position-relative link-sale">
              <span className="fw-bold">{value}</span>
            </Link>
          );
        },
        disableFilters: true,
      },
      {
        Header: 'Usuario',
        accessor: (onlineStoreSynchronization: OnlineStoreSynchronization) => {
          let value: string = onlineStoreSynchronization.user.name;
          if (onlineStoreSynchronization.user.surnames) {
            value += ` ${onlineStoreSynchronization.user.surnames}`;
          }
          return value;
        },
        filter: 'fuzzyText',
      },
      {
        Header: 'Tienda online',
        accessor: 'onlineStore.name',
        filter: 'fuzzyText',
      },
      {
        Header: 'Fecha inicio sincronización',
        accessor: 'createdAt',
        Cell: ({ value }: any) => {
          return moment(value).format('DD/MM/YYYY HH:mm');
        },
        filter: 'fuzzyText',
      },
      {
        Header: 'Fecha fin sincronización',
        accessor: 'finishedAt',
        Cell: ({ value }: any) => {
          return value ? moment(value).format('DD/MM/YYYY HH:mm') : 'Sin finalizar';
        },
        filter: 'fuzzyText',
      },
      {
        Header: 'Nuevos productos',
        accessor: 'newProducts',
      },
      {
        Header: 'Productos actualizados',
        accessor: 'updatedProducts',
      },
      {
        Header: 'Errores',
        accessor: 'numErrors',
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <Trash2
              type="button"
              className="mx-2"
              onClick={() => {
                setOnlineStoreSynchronization(row.original);
                setShowConfirmModal(true);
              }}
              size={14}
            />
          );
        },
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineStoresSynchronizations]) as any;

  const deleteBrand = async (b: OnlineStoreSynchronization) => {
    try {
      await api.deleteOnlineStoreSynchronization(b.id);
      const osss: OnlineStoreSynchronization[] = [...onlineStoresSynchronizations];
      const index: number = osss.findIndex((br: OnlineStoreSynchronization) => br.id === b.id);
      osss.splice(index, 1);
      setOnlineStoreSynchronizations(osss);
      myToastr.success('Registro eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const onlineStoreSynchronization: OnlineStoreSynchronization = fr.original;
      data.push({
        Nombre: onlineStoreSynchronization.user.name + ' ' + onlineStoreSynchronization.user.surnames,
        'Tienda online': onlineStoreSynchronization.onlineStore.name,
        'Fecha inicio sincronización': moment(onlineStoreSynchronization.createdAt).format('DD/MM/YYYY HH:mm'),
        'Fecha fin sincronización': onlineStoreSynchronization.finishedAt ? moment(onlineStoreSynchronization.finishedAt).format('DD/MM/YYYY HH:mm') : 'Sin finalizar',
        'Nuevos productos': onlineStoreSynchronization.newProducts,
        'Productos actualizados': onlineStoreSynchronization.updatedProducts,
        Errores: onlineStoreSynchronization.numErrors,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="brands p-4">
      <div className="d-flex flex-row align-items-center mb-4">
        <h1 className="flex-grow-1">Sincronizaciones con tiendas online</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="sincronizaciones_tiendas_online.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
      </div>
      <Table
        data={onlineStoresSynchronizations}
        columns={columns}
        noDataMessage="No hay marcas"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      {selectedOnlineStoreSynchronization && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar registro de sincronización"
          content={`¿Estás seguro que quieres eliminar el registro?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteBrand(selectedOnlineStoreSynchronization);
            }
            setOnlineStoreSynchronization(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default OnlineStoresSynchronizationsView;
