import { useEffect, useState } from 'react';
import { Loader } from 'react-feather';
import { useSearchParams } from 'react-router-dom';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { api } from '../services/api';

const CustomerPrivacyView = () => {
  const [searchParams] = useSearchParams();
  const [requesting, setRequesting] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [infoMessage, setInfoMessage] = useState<string | null>('');
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const token: string | null = searchParams.get('token');

  useEffect(() => {
    const verifyToken = async () => {
      setRequesting(true);
      try {
        const response: boolean = await api.verifyCustomerPrivacy({
          token: token!,
        });
        setAccepted(response);
      } catch (e: any) {
        if (e?.response?.data) {
          const httpExceptionDto: HttpExceptionDto = e.response.data;
          switch (httpExceptionDto.statusCode) {
            case 400:
              setErrorMessage('Token inválido.');
              break;
            case 409:
              setInfoMessage('Ya has aceptado el uso y tratamiento de tus datos personales.');
              break;
            default:
              setErrorMessage('Enlace inválido. Solicita uno nuevo.');
              break;
          }
        }
      }
      setRequesting(false);
    };
    if (token && token.length > 0) {
      verifyToken();
    }
  }, [token]);

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="container vh-100">
      <div className="h-100 d-flex align-items-center justify-content-center">
        {accepted && (
          <div className="alert alert-success w-50 text-center" role="alert">
            ¡Gracias!
          </div>
        )}
        {infoMessage && (
          <div className="alert alert-info w-50 text-center" role="alert">
            {infoMessage}
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger w-50 text-center" role="alert">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerPrivacyView;
