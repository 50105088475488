import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { Family } from '../interfaces/family';
import { SubFamilyDto } from '../interfaces/sub-familiy.dto';
import { SubFamily } from '../interfaces/subfamily';
import { api } from '../services/api';
import myToastr from '../services/toastr';

interface Props {
  subFamily: SubFamily | null;
  show: boolean;
  closeModal: (subFamily: SubFamily | null) => void;
}

interface CustomSubFamilyDto {
  name: string;
  familyId: number | null;
}

const initialValues: CustomSubFamilyDto = {
  name: '',
  familyId: null,
};

const SubFamilyModal = ({ subFamily, show, closeModal }: Props) => {
  const [families, setFamilies] = useState<Family[]>([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CustomSubFamilyDto>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    const getFamilies = async () => {
      const families: Family[] = await api.getFamilies();
      setFamilies(families);
    };
    getFamilies();
  }, []);

  useEffect(() => {
    if (show && subFamily) {
      reset({
        name: subFamily.name,
        familyId: subFamily.family.id,
      });
    }
  }, [show, subFamily, reset]);

  const close = () => {
    reset(initialValues);
    closeModal(null);
  };

  const onSubmit = async (customSubFamilyDto: CustomSubFamilyDto) => {
    try {
      const subFamilytDto: SubFamilyDto = {
        name: customSubFamilyDto.name,
        familyId: customSubFamilyDto.familyId!,
      };
      if (subFamily) {
        const updatedSubFamily: SubFamily = await api.updateSubFamily(subFamily.id, subFamilytDto);
        closeModal(updatedSubFamily);
        myToastr.success('Familia actualizada correctamente');
      } else {
        const newSubFamily: SubFamily = await api.createSubFamily(subFamilytDto);
        closeModal(newSubFamily);
        myToastr.success('Familia creada correctamente');
      }
      reset();
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  return (
    <Modal className="vercomi-modal my-form subFamily-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Subfamilia</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.name })}>Nombre *</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.familyId })}>Familia *</div>
              <select {...register('familyId', { required: true, min: 1, valueAsNumber: true })} className={clsx({ error: errors?.familyId })}>
                <option value="">Selecciona una familia</option>
                {families.map((family) => (
                  <option key={family.id} value={family.id}>
                    {family.name}
                  </option>
                ))}
              </select>
              {errors.familyId && <div className="error-message">{errors.familyId.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default SubFamilyModal;
