import { useDispatch, useSelector } from 'react-redux';
import { repairSelector, setSentTechnicalService } from '../store/repair-slice';

const RepairSentTechnicalService = () => {
  const dispatch = useDispatch();
  const { sentTechnicalService, requesting } = useSelector(repairSelector);

  return (
    <div className="d-flex flex-column check-switch w-100 mb-4">
      <label className="subtitle mb-3">Enviado al servicio técnico</label>
      <input
        type="checkbox"
        checked={sentTechnicalService}
        disabled={requesting}
        onChange={(e) => {
          dispatch(setSentTechnicalService(e.target.checked));
        }}
      />
    </div>
  );
};

export default RepairSentTechnicalService;
