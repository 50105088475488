import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { PaymentMethodType } from '../enums/payment-method-type';
import { Balance } from '../interfaces/balance';
import { PaymentMethod } from '../interfaces/payment-method';
import { Store } from '../interfaces/store';
import { api } from '../services/api';

interface Props {
  amount?: number;
  customerId?: number;
  onCancel: () => void;
  onSave: (paymentMethodId: number) => void;
  paymentMethodId: number;
  storeId: number;
  show: boolean;
}

interface FormData {
  paymentMethodId: number;
}

const SelectPaymentMethodModal = ({ amount, customerId, onCancel, onSave, paymentMethodId, storeId, show }: Props) => {
  const [store, setStore] = useState<Store | null>(null);
  const [balance, setBalance] = useState<Balance | null>(null);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    register,
    setValue,
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: {
      paymentMethodId: -1,
    },
  });

  const getStore = useCallback(async () => {
    try {
      const store: Store = await api.getStore(storeId);
      setStore(store);
    } catch (e) {}
  }, [storeId]);

  const getBalance = useCallback(async () => {
    try {
      const balance: Balance = await api.getCustomerBalance(customerId!);
      setBalance(balance);
    } catch (e) {}
  }, [customerId]);

  useEffect(() => {
    if (!show) {
      reset();
    } else {
      setValue('paymentMethodId', paymentMethodId);
    }
  }, [reset, show, paymentMethodId, setValue]);

  useEffect(() => {
    if (!show) {
      setStore(null);
    } else {
      if (storeId > 0) {
        getStore();
      }
    }
  }, [show, setValue, getStore, storeId]);

  useEffect(() => {
    if (!show) {
      setBalance(null);
    } else {
      if (customerId !== undefined && customerId > 0) {
        getBalance();
      }
    }
  }, [show, setValue, customerId, getBalance]);

  const onSubmit = async (formData: FormData) => {
    onSave(formData.paymentMethodId);
  };

  if (!store) {
    return null;
  }

  return (
    <Modal className="vercomi-modal my-form brand-modal" isOpen={show} onRequestClose={onCancel} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">{store.name}</div>
            <button type="button" className="close-button-modal" onClick={onCancel} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.paymentMethodId })}>Método de pago</div>
              <select {...register('paymentMethodId', { required: true, min: 1, valueAsNumber: true })} className={clsx({ error: errors?.paymentMethodId })}>
                <option value={-1}>Selecciona un método de pago</option>
                {store.paymentMethods
                  .filter((paymentMethod: PaymentMethod) => {
                    if (paymentMethod.online) {
                      return false;
                    }
                    if (paymentMethod.deleted) {
                      return false;
                    }
                    if (!paymentMethod.availableInPos) {
                      return false;
                    }
                    if (paymentMethod.type === PaymentMethodType.Balance) {
                      if (!balance || !amount) {
                        return false;
                      }
                      if (balance.money <= 0) {
                        return false;
                      }
                      if (balance.money < amount) {
                        return false;
                      }
                    }
                    return true;
                  })
                  .map((paymentMethod: PaymentMethod) => (
                    <option key={paymentMethod.id} value={paymentMethod.id}>
                      {paymentMethod.name}
                    </option>
                  ))}
              </select>
              {errors.paymentMethodId && <div className="error-message">Campo obligatorio</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default SelectPaymentMethodModal;
