export enum NotificationEntity {
  CashRegister = 'cash-register',
  DeliveryNote = 'delivery-note',
  Inventory = 'inventory',
  OnlineSynchronization = 'online-synchronization',
  Repair = 'repair',
  Sale = 'sale',
  Stock = 'stock',
  System = 'system',
  Transfer = 'transfer',
}
