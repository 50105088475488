import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import myToastr from '../services/toastr';
import browserHistory from './custom-history';
import { websocket } from './websocket';

const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
});
httpClient.interceptors.request.use((config: any) => {
  const token: string | null = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const organizationId: string | null = localStorage.getItem('organizationId');
  if (organizationId) {
    config.headers['x-sierra-organization-id'] = parseInt(organizationId, 10);
  }
  return config;
});
httpClient.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  (error: AxiosError) => {
    if (!error.response) {
      myToastr.error('No se ha podido conectar con el servidor');
      websocket.disconnect();
    } else {
      const httpExceptionDto: HttpExceptionDto = error.response.data;
      if (httpExceptionDto.statusCode === 401 && !httpExceptionDto.hasOwnProperty('error')) {
        // Jwt token expired
        localStorage.clear();
        sessionStorage.clear();
        browserHistory.replace('/login', {
          message: 'Tu sesión ha caducado. Por favor, inicia sesión de nuevo.',
          title: 'Atención',
          type: 'warning',
        });
        window.location.href = '/login';
        return;
      }
      return Promise.reject(error);
    }
  },
);

export default httpClient;
