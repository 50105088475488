import clsx from 'clsx';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit, Trash2, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { ExpirationStatus, ExpirationStatuses } from '../enums/expiration-status';
import { useStores } from '../hooks/use-stores.hook';
import { CreateExpirationDto } from '../interfaces/create-expiration.dto';
import { DeliveryNote } from '../interfaces/delivery-note';
import { Expiration } from '../interfaces/expiration';
import { ExpirationPayment } from '../interfaces/expiration-payment';
import { ExpirationPaymentDto } from '../interfaces/expiration-payment.dto';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { Supplier } from '../interfaces/supplier';
import { api } from '../services/api';
import { expirationStatusToString, formatNumber, toFixedTrunc } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  deliveryNote?: DeliveryNote;
  expiration: Expiration | null;
  show: boolean;
  closeModal: (expiration: Expiration | null) => void;
}

interface ErrorsExpirationPayment {
  expirationDate: boolean;
  amount: boolean;
  showAmountErrorInfo: boolean;
}

interface CustomCreateExpirationDto extends CreateExpirationDto {
  numPayments: number;
  delayDays: number;
}

const initialExpirationDto: CustomCreateExpirationDto = {
  externalNumber: '',
  date: moment().format('YYYY-MM-DD'),
  supplierId: -1,
  storeId: -1,
  deliveryNoteId: -1,
  total: null,
  expirationPayments: [],
  numPayments: 0,
  delayDays: 30,
};

const initialExpirationPaymentDto: ExpirationPaymentDto = {
  expirationDate: moment().add(30, 'days').format('YYYY-MM-DD'),
  amount: 0,
  status: ExpirationStatus.Pending,
};

const ExpirationModal = (props: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [expirationPaymentsDtos, setExpirationPaymentsDtos] = useState<ExpirationPaymentDto[]>([{ ...initialExpirationPaymentDto }]);
  const [indexSelectedExpirationPaymentDto, setIndexSelectedExpirationPaymentDto] = useState<number>(-1);
  const [newExpirationPayment, setNewExpirationPayment] = useState<ExpirationPaymentDto>({ ...initialExpirationPaymentDto });
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const [errorsExpirationPayment, setErrorsExpirationPayment] = useState<ErrorsExpirationPayment>({
    expirationDate: false,
    amount: false,
    showAmountErrorInfo: false,
  });
  const stores: Store[] = useStores();
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const {
    register: registerExpiration,
    handleSubmit: handleSubmitExpiration,
    reset: resetExpiration,
    watch: watchExpiration,
    getValues: getValuesExpiration,
    formState: { errors: errorsExpiration },
    setValue: setValueExpiration,
  } = useForm<CustomCreateExpirationDto>({
    mode: 'onSubmit',
    defaultValues: { ...initialExpirationDto },
  });
  const validNewExpirationPayment: boolean = useMemo(() => {
    if (!newExpirationPayment.expirationDate || !moment(newExpirationPayment.expirationDate).isValid()) {
      return false;
    }
    if (newExpirationPayment.amount === null || newExpirationPayment.amount === 0) {
      return false;
    }
    return true;
  }, [newExpirationPayment]);

  const accumPayments: number = useMemo(() => {
    let total: number = 0;
    expirationPaymentsDtos.forEach((expirationPaymentDto: ExpirationPaymentDto) => {
      total += expirationPaymentDto.amount || 0;
    });
    return total;
  }, [expirationPaymentsDtos]);

  useEffect(() => {
    if (!props.show) {
      setSelectedStore(null);
      setSuppliers([]);
    }
  }, [props.show]);

  useEffect(() => {
    if (!selectedStore) {
      return;
    }
    getSuppliers(selectedStore.organizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore]);

  useEffect(() => {
    if (props.show) {
      if (props.expiration) {
        let delayDays = 0;
        if (props.expiration.expirationPayments.length > 1) {
          const firstExpirationPayment = props.expiration.expirationPayments[0];
          const secondExpirationPayment = props.expiration.expirationPayments[1];
          delayDays = moment(secondExpirationPayment.expirationDate).diff(moment(firstExpirationPayment.expirationDate), 'days');
        }
        resetExpiration({
          externalNumber: props.expiration.externalNumber,
          date: moment(props.expiration.date).format('YYYY-MM-DD'),
          supplierId: props.expiration.supplierId,
          storeId: props.expiration.storeId,
          deliveryNoteId: props.expiration.deliveryNoteId,
          total: props.expiration.total,
          numPayments: props.expiration.expirationPayments.length,
          delayDays,
        });
        const payments: ExpirationPaymentDto[] = [];
        props.expiration.expirationPayments.forEach((expirationPayment: ExpirationPayment) => {
          payments.push({
            id: expirationPayment.id,
            expirationDate: moment(expirationPayment.expirationDate).format('YYYY-MM-DD'),
            amount: expirationPayment.amount,
            status: expirationPayment.status,
          });
        });
        setExpirationPaymentsDtos(payments);
        getSuppliers(organization!.id);
      } else {
        if (props.deliveryNote) {
          setValueExpiration('externalNumber', props.deliveryNote.externalNumber);
          setValueExpiration('total', props.deliveryNote.total);
          setValueExpiration('numPayments', 1);
        }
        setExpirationPaymentsDtos([
          {
            ...initialExpirationPaymentDto,
            expirationDate: moment().add(30, 'days').format('YYYY-MM-DD'),
          },
        ]);
      }
    } else {
      setExpirationPaymentsDtos([{ ...initialExpirationPaymentDto }]);
      setErrorsExpirationPayment({
        expirationDate: false,
        amount: false,
        showAmountErrorInfo: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expiration, props.show, props.deliveryNote]);

  useEffect(() => {
    if (!props.expiration) {
      updateAmountExpirationPaymentsDtos(watchExpiration('total') || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expiration, watchExpiration('total')]);

  useEffect(() => {
    if (props.expiration) {
      return;
    }
    const date: string | null = watchExpiration('date');
    const total: number = watchExpiration('total') || 0;
    const numPayments: number | null = watchExpiration('numPayments');
    const delayDays: number | null = watchExpiration('delayDays');
    if (date && moment(date, 'YYYY-MM-DD').isValid() && total && total !== 0 && numPayments && numPayments > 0 && delayDays && delayDays >= 0) {
      const data: ExpirationPaymentDto[] = [];
      const totalStr: string = total.toString();
      let isPerfectDivision: boolean;
      if (totalStr.includes('.')) {
        isPerfectDivision = Math.trunc(total * 100) % data.length === 0;
      } else {
        isPerfectDivision = total % data.length === 0;
      }
      const amount: number = parseFloat(toFixedTrunc(total / numPayments, 2));
      let accum = 0;
      for (let i = 0; i < numPayments; i++) {
        data.push({
          expirationDate:
            i === 0
              ? moment(date, 'YYYY-MM-DD').add(delayDays, 'days').format('YYYY-MM-DD')
              : moment(data[i - 1].expirationDate, 'YYYY-MM-DD')
                  .add(delayDays, 'days')
                  .format('YYYY-MM-DD'),
          amount: i > 0 && i === numPayments - 1 && !isPerfectDivision ? total - accum : amount,
          status: ExpirationStatus.Pending,
        });
        accum += amount;
      }
      setExpirationPaymentsDtos(data);
    } else {
      setExpirationPaymentsDtos([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expiration, watchExpiration('date'), watchExpiration('total'), watchExpiration('numPayments'), watchExpiration('delayDays')]);

  const getSuppliers = async (organizationId: number) => {
    try {
      const suppliers: Supplier[] = await api.getSuppliers(organizationId);
      setSuppliers(suppliers);
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const updateAmountExpirationPaymentsDtos = useCallback(
    (total: number) => {
      // Repartir el total entre todos los pagos
      const data: ExpirationPaymentDto[] = [...expirationPaymentsDtos];
      const totalStr: string = total.toString();
      let isPerfectDivision: boolean;
      if (totalStr.includes('.')) {
        isPerfectDivision = Math.trunc(total * 100) % data.length === 0;
      } else {
        isPerfectDivision = total % data.length === 0;
      }
      const amount: number = parseFloat(toFixedTrunc(total / data.length, 2));
      let accum = 0;
      for (let i = 0; i < data.length; i++) {
        if (i > 0 && i === data.length - 1 && !isPerfectDivision) {
          data[i].amount = total - accum;
        } else {
          data[i].amount = amount;
        }
        accum += amount;
      }
      setExpirationPaymentsDtos(data);
    },
    [expirationPaymentsDtos],
  );

  const close = () => {
    resetExpiration({ ...initialExpirationDto });
    props.closeModal(null);
  };

  const onSubmit = async (data: CreateExpirationDto) => {
    if (expirationPaymentsDtos.length === 0) {
      myToastr.error('Debes añadir al menos un pago');
      return;
    }
    let total: number = 0;
    expirationPaymentsDtos.forEach((expirationPaymentDto: ExpirationPaymentDto) => {
      total += expirationPaymentDto.amount || 0;
    });
    if (data.total !== total) {
      myToastr.error('El total de los pagos no coincide con el total de la factura');
      return;
    }
    if (props.expiration) {
      try {
        const expiration: Expiration = await api.updateExpiration(props.expiration.id, expirationPaymentsDtos);
        props.closeModal(expiration);
        myToastr.success('Vencimiento actualizado correctamente');
        resetExpiration({ ...initialExpirationDto });
        setExpirationPaymentsDtos([{ ...initialExpirationPaymentDto }]);
        setIndexSelectedExpirationPaymentDto(-1);
        setNewExpirationPayment({ ...initialExpirationPaymentDto });
      } catch (e: any) {
        myToastr.error(e.response.data.message);
      }
    } else {
      const createExpirationDto: CreateExpirationDto = {
        externalNumber: data.externalNumber,
        date: data.date,
        supplierId: props.deliveryNote ? props.deliveryNote.supplierId : data.supplierId!,
        storeId: props.deliveryNote ? props.deliveryNote.storeId : data.storeId,
        deliveryNoteId: props.deliveryNote ? props.deliveryNote.id : null,
        total: data.total!,
        expirationPayments: expirationPaymentsDtos,
      };
      try {
        const expiration: Expiration = await api.createExpiration(createExpirationDto);
        props.closeModal(expiration);
        myToastr.success('Vencimiento creado correctamente');
        resetExpiration({ ...initialExpirationDto });
        setExpirationPaymentsDtos([{ ...initialExpirationPaymentDto }]);
        setIndexSelectedExpirationPaymentDto(-1);
        setNewExpirationPayment({ ...initialExpirationPaymentDto });
      } catch (e: any) {
        myToastr.error(e.response.data.message);
      }
    }
  };

  const addExpirationPaymentDto = () => {
    let hasErrors: boolean = false;
    if (!newExpirationPayment.expirationDate || !moment(newExpirationPayment.expirationDate).isValid()) {
      setErrorsExpirationPayment({ ...errorsExpirationPayment, expirationDate: true });
      hasErrors = true;
    }
    if (newExpirationPayment.amount === null || newExpirationPayment.amount === 0) {
      setErrorsExpirationPayment({ ...errorsExpirationPayment, amount: true });
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }
    const epmDtos: ExpirationPaymentDto[] = [...expirationPaymentsDtos];
    epmDtos.push(newExpirationPayment);
    setExpirationPaymentsDtos(epmDtos);
    setNewExpirationPayment({ ...initialExpirationPaymentDto });
    setErrorsExpirationPayment({ expirationDate: false, amount: false, showAmountErrorInfo: false });
  };

  const cancelExpirationPaymentDto = () => {
    setIndexSelectedExpirationPaymentDto(-1);
    setNewExpirationPayment({ ...initialExpirationPaymentDto });
    setErrorsExpirationPayment({ expirationDate: false, amount: false, showAmountErrorInfo: false });
  };

  const updateExpirationPaymentDto = () => {
    let hasErrors: boolean = false;
    if (!newExpirationPayment.expirationDate || !moment(newExpirationPayment.expirationDate).isValid()) {
      setErrorsExpirationPayment({ ...errorsExpirationPayment, expirationDate: true });
      hasErrors = true;
    }
    if (newExpirationPayment.amount === null || newExpirationPayment.amount === 0) {
      setErrorsExpirationPayment({ ...errorsExpirationPayment, amount: true });
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }
    const epmDtos: ExpirationPaymentDto[] = [...expirationPaymentsDtos];
    epmDtos[indexSelectedExpirationPaymentDto] = newExpirationPayment;
    setExpirationPaymentsDtos(epmDtos);
    setNewExpirationPayment({ ...initialExpirationPaymentDto });
    setErrorsExpirationPayment({ expirationDate: false, amount: false, showAmountErrorInfo: false });
    setIndexSelectedExpirationPaymentDto(-1);
  };

  const deleteExpirationPaymentDto = (index: number) => {
    const epmDtos: ExpirationPaymentDto[] = [...expirationPaymentsDtos];
    epmDtos.splice(index, 1);
    setExpirationPaymentsDtos(epmDtos);
    setValueExpiration('numPayments', epmDtos.length);
  };

  const hasTotal: boolean = getValuesExpiration('total') != null && getValuesExpiration('total')! !== 0;

  return (
    <Modal className="vercomi-modal my-form expiration-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmitExpiration(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Vencimiento {props.deliveryNote ? `para la Compra ${props.deliveryNote.internalId} ` : ''}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errorsExpiration?.externalNumber })}>Número de ref. o factura*</div>
              <input type="text" {...registerExpiration('externalNumber', { required: true })} className={clsx({ error: errorsExpiration?.externalNumber })} placeholder="Número" />
              {errorsExpiration.externalNumber && <div className="error-message">{errorsExpiration.externalNumber.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errorsExpiration?.date })}>Fecha de inicio del vencimiento*</div>
              <input type="date" {...registerExpiration('date', { required: true })} className={clsx({ error: errorsExpiration?.date })} placeholder="Fecha" />
              {errorsExpiration.date && <div className="error-message">{errorsExpiration.date.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            {!props.deliveryNote && (
              <div className="col-6">
                <div className={clsx('input-name', { error: errorsExpiration?.storeId })}>Tienda *</div>
                <select
                  {...registerExpiration('storeId', { required: true, min: 1, valueAsNumber: true })}
                  className={clsx({ error: errorsExpiration?.storeId })}
                  onChange={(e) => {
                    const storeId: number = parseInt(e.target.value);
                    if (storeId === -1) {
                      setSelectedStore(null);
                      setSuppliers([]);
                    } else {
                      const store: Store = stores.find((s: Store) => s.id === storeId)!;
                      setSelectedStore(store);
                    }
                  }}
                  disabled={props.expiration !== null}
                >
                  <option value={-1}>Selecciona una tienda</option>
                  {stores.map((store: Store) => (
                    <option key={store.id} value={store.id}>
                      {store.name}
                    </option>
                  ))}
                </select>
                {errorsExpiration.storeId && <div className="error-message">{errorsExpiration.storeId.message}</div>}
              </div>
            )}
            {!props.deliveryNote && (
              <div className="col-6">
                <div className={clsx('input-name', { error: errorsExpiration?.supplierId })}>Proveedor *</div>
                {selectedStore === null ? (
                  <div className="input-name">Selecciona una tienda</div>
                ) : (
                  <React.Fragment>
                    <select
                      {...registerExpiration('supplierId', { required: true, min: 1, valueAsNumber: true })}
                      className={clsx({ error: errorsExpiration?.supplierId })}
                      disabled={props.expiration !== null}
                    >
                      <option value={-1}>Selecciona un proveedor</option>
                      {suppliers.map((supplier: Supplier) => (
                        <option key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </option>
                      ))}
                    </select>
                    {errorsExpiration.supplierId && <div className="error-message">{errorsExpiration.supplierId.message}</div>}
                  </React.Fragment>
                )}
              </div>
            )}
            <div className={clsx('col-6', { 'mt-3': !props.deliveryNote })}>
              <div className={clsx('input-name', { error: errorsExpiration?.total })}>Total a pagar*</div>
              <input
                type="number"
                {...registerExpiration('total', { required: true, valueAsNumber: true, validate: (value: number | null) => (value !== null && value !== 0 ? true : 'El total no puede ser 0') })}
                step="0.01"
                className={clsx({ error: errorsExpiration?.total })}
                placeholder="Total"
              />
              {errorsExpiration.total && <div className="error-message">{errorsExpiration.total.message}</div>}
            </div>
            {props.expiration === null && (
              <React.Fragment>
                <div className={clsx('col-6', { 'mt-3': !props.deliveryNote })}>
                  <div className={clsx('input-name', { error: errorsExpiration?.numPayments })}>Número de pagos*</div>
                  <input
                    type="number"
                    {...registerExpiration('numPayments', {
                      required: true,
                      valueAsNumber: true,
                      min: 0,
                      validate: (value: number | null) => (value !== null && value >= 0 ? true : 'El número de pagos no puede ser 0'),
                    })}
                    step="1"
                    className={clsx({ error: errorsExpiration?.numPayments })}
                    placeholder="Número de pagos"
                  />
                  {errorsExpiration.numPayments && <div className="error-message">{errorsExpiration.numPayments.message}</div>}
                </div>
                <div className={clsx('col-6 mt-3')}>
                  <div className={clsx('input-name', { error: errorsExpiration?.delayDays })}>Carencia en días*</div>
                  <input
                    type="number"
                    {...registerExpiration('delayDays', {
                      required: true,
                      valueAsNumber: true,
                      min: 0,
                      validate: (value: number | null) => (value !== null && value >= 0 ? true : 'La carencia no puede ser menor que 0'),
                    })}
                    step="1"
                    className={clsx({ error: errorsExpiration?.delayDays })}
                    placeholder="Carencia de pagos"
                  />
                  {errorsExpiration.delayDays && <div className="error-message">{errorsExpiration.delayDays.message}</div>}
                </div>
              </React.Fragment>
            )}
          </div>
          <h6>Listado de pagos</h6>
          <div className="table-payments">
            <table className="my-table">
              <thead>
                <tr>
                  <th>Fecha vencimiento pago</th>
                  <th>Importe</th>
                  <th>Estado</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {!hasTotal ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      Introduce el total de la factura para añadir pagos
                    </td>
                  </tr>
                ) : expirationPaymentsDtos.length === 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No hay pagos
                    </td>
                  </tr>
                ) : (
                  expirationPaymentsDtos.map((expirationPaymentDto: ExpirationPaymentDto, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{moment(expirationPaymentDto.expirationDate).format('DD/MM/YYYY')}</td>
                        <td>{formatNumber(expirationPaymentDto.amount!)}€</td>
                        <td>{expirationStatusToString(expirationPaymentDto.status)}</td>
                        <td>
                          {indexSelectedExpirationPaymentDto === -1 && (
                            <React.Fragment>
                              <Edit
                                size={16}
                                color="#222E3D"
                                onClick={() => {
                                  setIndexSelectedExpirationPaymentDto(index);
                                  setNewExpirationPayment({ ...expirationPaymentDto });
                                }}
                                type="button"
                                className="mx-2"
                              />
                              <Trash2 type="button" className="mx-2" onClick={() => deleteExpirationPaymentDto(index)} size={14} />
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          {hasTotal && indexSelectedExpirationPaymentDto > -1 && (
            <div className="flex flex-column">
              <h6>Editar vencimientos:</h6>
              <div className="d-flex justify-content-between align-items-end mt-3">
                <div className="">
                  <div className={clsx('input-name', { error: errorsExpirationPayment.expirationDate })}>Fecha vencimiento *</div>
                  <input
                    type="date"
                    placeholder="Fecha vencimiento"
                    value={newExpirationPayment.expirationDate}
                    onChange={(e: any) => {
                      setNewExpirationPayment({ ...newExpirationPayment, expirationDate: e.target.value });
                    }}
                    className={clsx({ error: errorsExpirationPayment.expirationDate })}
                  />
                </div>
                <div
                  className=""
                  onClick={() => {
                    if (indexSelectedExpirationPaymentDto > -1 && newExpirationPayment.status === ExpirationStatus.Paid) {
                      setErrorsExpirationPayment({ ...errorsExpirationPayment, showAmountErrorInfo: true });
                    }
                  }}
                >
                  <div className={clsx('input-name', { error: errorsExpirationPayment.amount })}>Importe *</div>
                  <input
                    disabled={indexSelectedExpirationPaymentDto > -1 && newExpirationPayment.status === ExpirationStatus.Paid}
                    value={newExpirationPayment.amount !== null ? newExpirationPayment.amount : ''}
                    className={clsx({ error: errorsExpirationPayment.amount })}
                    onChange={(e: any) => {
                      setErrorsExpirationPayment({ ...errorsExpirationPayment, amount: false });
                      if (e.target.value) {
                        setNewExpirationPayment({ ...newExpirationPayment, amount: parseFloat(e.target.value) });
                      } else {
                        setNewExpirationPayment({ ...newExpirationPayment, amount: null });
                      }
                    }}
                    type="number"
                    placeholder="Importe"
                    step="0.01"
                  />
                </div>
                <div className="">
                  <div>Estado *</div>
                  <select
                    value={newExpirationPayment.status}
                    onChange={(e: any) => {
                      setNewExpirationPayment({ ...newExpirationPayment, status: e.target.value });
                      setErrorsExpirationPayment({ ...errorsExpirationPayment, showAmountErrorInfo: false });
                    }}
                  >
                    {ExpirationStatuses.map((expirationStatus: ExpirationStatus) => (
                      <option key={expirationStatus} value={expirationStatus}>
                        {expirationStatusToString(expirationStatus)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="d-flex flex-row">
                  {indexSelectedExpirationPaymentDto > -1 ? (
                    <React.Fragment>
                      <button type="button" onClick={updateExpirationPaymentDto} className={clsx('button-expiration-payment add-expiration-payment')}>
                        Actualizar pago
                      </button>
                      <button onClick={cancelExpirationPaymentDto} type="button" className={clsx('button-expiration-payment cancel-expiration-payment')}>
                        Cancelar
                      </button>
                    </React.Fragment>
                  ) : (
                    <button type="button" onClick={addExpirationPaymentDto} className={clsx('button-expiration-payment add-expiration-payment', { disabled: !validNewExpirationPayment })}>
                      Añadir pago
                    </button>
                  )}
                </div>
              </div>
              {errorsExpirationPayment.showAmountErrorInfo && <div className="error-message mt-3">No se puede modificar el importe de un pago en estado Pagado</div>}
            </div>
          )}
        </div>
        <button className={clsx(`save-button`, { disabled: accumPayments !== getValuesExpiration().total })} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default ExpirationModal;
