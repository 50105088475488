import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { PurchaseOrderStatus } from '../enums/purchase-order-status';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { ProductImage } from '../interfaces/product-image';
import { PurchaseOrder } from '../interfaces/purchase-order';
import { PurchaseOrderProduct } from '../interfaces/purchase-order-product';
import { PurchaseOrderRevisionDto } from '../interfaces/purchase-order-revision.dto';
import { PurchaseOrderDto } from '../interfaces/purchase-order.dto';
import { Stock } from '../interfaces/stock';
import { UpdatePurchaseOrderDto } from '../interfaces/update-purchase-order.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { RootState } from './store';

export interface ProductInShoppingCart {
  productId: number;
  sku: string;
  name: string;
  images: ProductImage[];
  retailPrice: number;
  units: number;
}

export interface PurchaseOrderState {
  requesting: boolean;
  purchaseOrder: PurchaseOrder | null;
  organizationClientId: number;
  originOrganizationId: number;
  originStoreId: number;
  destinationOrganizationId: number;
  destinationStoreId: number;
  retailStoreId: number;
  products: ProductInShoppingCart[];
  productUnitsMap: { [productId: number]: number };
  total: number;
  shippingCountry: string;
  shippingAddress: string;
  shippingZipCode: string;
  shippingCity: string;
  shippingProvince: string;
  hasEquivalenceSurcharge: boolean;
  sentProducts: { [id: number]: number | undefined };
}

const initialState: PurchaseOrderState = {
  requesting: false,
  purchaseOrder: null,
  organizationClientId: -1,
  originOrganizationId: -1,
  originStoreId: -1,
  destinationOrganizationId: -1,
  destinationStoreId: -1,
  retailStoreId: -1,
  total: 0,
  products: [],
  productUnitsMap: {},
  shippingCountry: '',
  shippingAddress: '',
  shippingZipCode: '',
  shippingCity: '',
  shippingProvince: '',
  hasEquivalenceSurcharge: false,
  sentProducts: {},
};

function* updateTotal() {
  yield put(purchaseOrderSlice.actions.updateTotal());
}

export const getPurchaseOrder = createAsyncThunk('purchaseOrder/getPurchaseOrder', async (id: number, thunkApi): Promise<PurchaseOrder | null> => {
  try {
    const po: PurchaseOrder = await api.getPurchaseOrder(id);
    thunkApi.dispatch(purchaseOrderSlice.actions.setPurchaseOrder(po));
    return po;
  } catch (e: any) {
    const axiosError: AxiosError<HttpExceptionDto> = e;
    if (axiosError.response?.data) {
      const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
      myToastr.error(httpExceptionDto.message);
    }
    return null;
  }
});

export const createPurchaseOrder = createAsyncThunk('purchaseOrder/createPurchaseOrder', async (_: void, thunkApi): Promise<PurchaseOrder | null> => {
  const state: RootState = thunkApi.getState() as RootState;
  const purchaseOrderDto: PurchaseOrderDto = {
    createdByOrganizationId: state.auth.organization!.id,
    originOrganizationId: state.purchaseOrder.originOrganizationId,
    originStoreId: state.purchaseOrder.originStoreId,
    destinationOrganizationId: state.purchaseOrder.destinationOrganizationId,
    destinationStoreId: state.purchaseOrder.destinationStoreId,
    hasEquivalenceSurcharge: state.purchaseOrder.hasEquivalenceSurcharge,
    products: state.purchaseOrder.products.map((productInShoppingCart: ProductInShoppingCart) => ({
      productId: productInShoppingCart.productId,
      requestedUnits: productInShoppingCart.units,
    })),
  };
  thunkApi.dispatch(purchaseOrderSlice.actions.setRequesting(true));
  let purchaseOrder: PurchaseOrder | null = null;
  try {
    purchaseOrder = await api.createPurchaseOrder(purchaseOrderDto);
    thunkApi.dispatch(purchaseOrderSlice.actions.reset());
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
  }
  thunkApi.dispatch(purchaseOrderSlice.actions.setRequesting(false));
  return purchaseOrder;
});

export const updatePurchaseOrder = createAsyncThunk(
  'purchaseOrder/updatePurchaseOrder',
  async (status: PurchaseOrderStatus.Draft | PurchaseOrderStatus.Generated, thunkApi): Promise<PurchaseOrder | null> => {
    const state: RootState = thunkApi.getState() as RootState;
    const updatePurchaseOrderDto: UpdatePurchaseOrderDto = {
      status,
      products: state.purchaseOrder.products.map((productInShoppingCart: ProductInShoppingCart) => ({
        productId: productInShoppingCart.productId,
        requestedUnits: productInShoppingCart.units,
      })),
      shippingCountry: state.purchaseOrder.shippingCountry,
      shippingAddress: state.purchaseOrder.shippingAddress,
      shippingZipCode: state.purchaseOrder.shippingZipCode,
      shippingCity: state.purchaseOrder.shippingCity,
      shippingProvince: state.purchaseOrder.shippingProvince,
      hasEquivalenceSurcharge: state.purchaseOrder.hasEquivalenceSurcharge,
    };
    thunkApi.dispatch(purchaseOrderSlice.actions.setRequesting(true));
    let purchaseOrder: PurchaseOrder | null = null;
    try {
      purchaseOrder = await api.updatePurchaseOrder(state.purchaseOrder.purchaseOrder!.id, updatePurchaseOrderDto);
      thunkApi.dispatch(purchaseOrderSlice.actions.reset());
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
    thunkApi.dispatch(purchaseOrderSlice.actions.setRequesting(false));
    return purchaseOrder;
  },
);

export const revisionPurchaseOrder = createAsyncThunk(
  'purchaseOrder/revisionPurchaseOrder',
  async (status: PurchaseOrderStatus.Generated | PurchaseOrderStatus.DeliveryNote | PurchaseOrderStatus.Invoice, thunkApi): Promise<PurchaseOrder | null> => {
    const state: RootState = thunkApi.getState() as RootState;
    thunkApi.dispatch(purchaseOrderSlice.actions.setRequesting(true));
    let purchaseOrder: PurchaseOrder | null = null;
    try {
      const purchaseOrderRevisionDto: PurchaseOrderRevisionDto = {
        status,
        products: [],
      };
      for (const id in state.purchaseOrder.sentProducts) {
        purchaseOrderRevisionDto.products.push({
          id: parseInt(id as any, 10),
          finalUnits: state.purchaseOrder.sentProducts[id] || 0,
        });
      }
      purchaseOrder = await api.purchaseOrderRevision(state.purchaseOrder.purchaseOrder!.id, purchaseOrderRevisionDto);
      thunkApi.dispatch(purchaseOrderSlice.actions.setPurchaseOrder(purchaseOrder));
      if (status === PurchaseOrderStatus.DeliveryNote) {
        myToastr.success('Albarán generado correctamente');
      } else if (status === PurchaseOrderStatus.Invoice) {
        myToastr.success('Factura generada correctamente');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
    thunkApi.dispatch(purchaseOrderSlice.actions.setRequesting(false));
    return purchaseOrder;
  },
);

export const purchaseOrderSlice = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: {
    setRequesting: (state: PurchaseOrderState, payloadAction: PayloadAction<boolean>) => {
      state.requesting = payloadAction.payload;
    },
    setPurchaseOrder: (state: PurchaseOrderState, payloadAction: PayloadAction<PurchaseOrder>) => {
      state.purchaseOrder = payloadAction.payload;
      state.shippingCountry = payloadAction.payload.shippingCountry;
      state.shippingAddress = payloadAction.payload.shippingAddress;
      state.shippingZipCode = payloadAction.payload.shippingZipCode;
      state.shippingCity = payloadAction.payload.shippingCity;
      state.shippingProvince = payloadAction.payload.shippingProvince;
      state.productUnitsMap = payloadAction.payload.purchaseOrderProducts.reduce((productUnitsMap: { [productId: number]: number }, purchaseOrderProduct: PurchaseOrderProduct) => {
        productUnitsMap[purchaseOrderProduct.product.id] = purchaseOrderProduct.requestedUnits;
        return productUnitsMap;
      }, {});
      state.products = [];
      state.sentProducts = {};
      payloadAction.payload.purchaseOrderProducts.forEach((purchaseOrderProduct: PurchaseOrderProduct) => {
        state.products.push({
          productId: purchaseOrderProduct.product.id,
          sku: purchaseOrderProduct.product.sku,
          name: purchaseOrderProduct.product.name,
          images: purchaseOrderProduct.product.images,
          retailPrice: purchaseOrderProduct.retailPrice,
          units: purchaseOrderProduct.requestedUnits,
        });
        state.sentProducts[purchaseOrderProduct.id] = purchaseOrderProduct.finalUnits;
      });
      state.hasEquivalenceSurcharge = payloadAction.payload.hasEquivalenceSurcharge;
    },
    setOrganizationClientId: (state: PurchaseOrderState, payloadAction: PayloadAction<number>) => {
      state.organizationClientId = payloadAction.payload;
    },
    setRetailStoreId: (state: PurchaseOrderState, payloadAction: PayloadAction<number>) => {
      state.retailStoreId = payloadAction.payload;
    },
    setOriginOrganizationId: (state: PurchaseOrderState, payloadAction: PayloadAction<number>) => {
      state.originOrganizationId = payloadAction.payload;
    },
    setOriginStoreId: (state: PurchaseOrderState, payloadAction: PayloadAction<number>) => {
      state.originStoreId = payloadAction.payload;
    },
    setDestinationOrganizationId: (state: PurchaseOrderState, payloadAction: PayloadAction<number>) => {
      state.destinationOrganizationId = payloadAction.payload;
    },
    setDestinationStoreId: (state: PurchaseOrderState, payloadAction: PayloadAction<number>) => {
      state.destinationStoreId = payloadAction.payload;
    },
    setShippingCountry: (state: PurchaseOrderState, payloadAction: PayloadAction<string>) => {
      state.shippingCountry = payloadAction.payload;
    },
    setShippingAddress: (state: PurchaseOrderState, payloadAction: PayloadAction<string>) => {
      state.shippingAddress = payloadAction.payload;
    },
    setShippingZipCode: (state: PurchaseOrderState, payloadAction: PayloadAction<string>) => {
      state.shippingZipCode = payloadAction.payload;
    },
    setShippingCity: (state: PurchaseOrderState, payloadAction: PayloadAction<string>) => {
      state.shippingCity = payloadAction.payload;
    },
    setShippingProvince: (state: PurchaseOrderState, payloadAction: PayloadAction<string>) => {
      state.shippingProvince = payloadAction.payload;
    },
    setHasEquivalenceSurcharge: (state: PurchaseOrderState, payloadAction: PayloadAction<boolean>) => {
      state.hasEquivalenceSurcharge = payloadAction.payload;
    },
    setUnits: (state: PurchaseOrderState, payloadAction: PayloadAction<{ units: number; stock: Stock }>) => {
      const { units, stock } = payloadAction.payload;
      if (!state.productUnitsMap.hasOwnProperty(stock.productId)) {
        if (units === 0) {
          return;
        }
        state.products.push({
          productId: stock.product.id,
          sku: stock.product.sku,
          name: stock.product.name,
          images: stock.product.images,
          retailPrice: stock.retailPrice,
          units,
        });
      } else {
        if (units === 0) {
          state.products = state.products.filter((productInShoppingCart: ProductInShoppingCart) => productInShoppingCart.productId !== stock.productId);
          delete state.productUnitsMap[stock.productId];
          return;
        }
        state.products = state.products.map((productInShoppingCart: ProductInShoppingCart) => {
          if (productInShoppingCart.productId === stock.productId) {
            productInShoppingCart.units = units;
          }
          return productInShoppingCart;
        });
      }
      state.productUnitsMap[stock.productId] = units;
    },
    addProductUnit: (state: PurchaseOrderState, payloadAction: PayloadAction<number>) => {
      const productId: number = payloadAction.payload;
      if (!state.productUnitsMap.hasOwnProperty(productId)) {
        return;
      }
      state.products = state.products.map((productInShoppingCart: ProductInShoppingCart) => {
        if (productInShoppingCart.productId === productId) {
          productInShoppingCart.units += 1;
        }
        return productInShoppingCart;
      });
      state.productUnitsMap[productId] += 1;
    },
    removeProductUnit: (state: PurchaseOrderState, payloadAction: PayloadAction<number>) => {
      const productId: number = payloadAction.payload;
      if (!state.productUnitsMap.hasOwnProperty(productId)) {
        return;
      }
      state.products = state.products.map((productInShoppingCart: ProductInShoppingCart) => {
        if (productInShoppingCart.productId === productId) {
          productInShoppingCart.units -= 1;
        }
        return productInShoppingCart;
      });
      state.productUnitsMap[productId] -= 1;
      if (state.productUnitsMap[productId] === 0) {
        state.products = state.products.filter((productInShoppingCart: ProductInShoppingCart) => productInShoppingCart.productId !== productId);
        delete state.productUnitsMap[productId];
      }
    },
    removeProduct: (state: PurchaseOrderState, payloadAction: PayloadAction<number>) => {
      const productId = payloadAction.payload;
      if (!state.productUnitsMap.hasOwnProperty(productId)) {
        return;
      }
      state.products = state.products.filter((productInShoppingCart: ProductInShoppingCart) => productInShoppingCart.productId !== productId);
      delete state.productUnitsMap[productId];
    },
    removeAllProducts: (state: PurchaseOrderState) => {
      state.products = [];
      state.productUnitsMap = {};
    },
    reset: () => {
      return initialState;
    },
    updateTotal: (state: PurchaseOrderState) => {
      state.total = state.products.reduce((total: number, productInShoppingCart: ProductInShoppingCart) => total + productInShoppingCart.retailPrice * productInShoppingCart.units, 0);
    },
    setSentUnits: (state: PurchaseOrderState, payloadAction: PayloadAction<{ id: number; finalUnits: number | undefined }>) => {
      state.sentProducts[payloadAction.payload.id] = payloadAction.payload.finalUnits;
    },
  },
});

export const {
  setOriginOrganizationId,
  setOriginStoreId,
  setDestinationOrganizationId,
  setDestinationStoreId,
  setRetailStoreId,
  setOrganizationClientId,
  setUnits,
  reset,
  addProductUnit,
  removeProductUnit,
  removeProduct,
  removeAllProducts,
  setShippingCountry,
  setShippingAddress,
  setShippingZipCode,
  setShippingCity,
  setShippingProvince,
  setSentUnits,
  setHasEquivalenceSurcharge,
} = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;

export const purchaseOrderSelector = (state: RootState) => state.purchaseOrder;

export const purchaseOrderSaga = function* () {
  yield takeLatest(purchaseOrderSlice.actions.setPurchaseOrder.type, updateTotal);
  yield takeLatest(purchaseOrderSlice.actions.setUnits.type, updateTotal);
  yield takeLatest(purchaseOrderSlice.actions.addProductUnit.type, updateTotal);
  yield takeLatest(purchaseOrderSlice.actions.removeProductUnit.type, updateTotal);
  yield takeLatest(purchaseOrderSlice.actions.removeProduct.type, updateTotal);
  yield takeLatest(purchaseOrderSlice.actions.reset.type, updateTotal);
};
