import toastr from 'toastr';

toastr.options = {
  positionClass: 'toast-top-right',
  timeOut: 5000,
  extendedTimeOut: 1000,
  closeButton: true,
  progressBar: false,
  iconClass: 'elerts-toastr-icon',
};

export default toastr;
