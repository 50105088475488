import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { DollarSign, User } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethodType } from '../enums/payment-method-type';
import { SaleStatus } from '../enums/sale-status';
import { BookingPaymentDto } from '../interfaces/booking-payment.dto';
import { PaymentMethod } from '../interfaces/payment-method';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { createSale, posSelector, setBookingPaymentAmount, setChangeInBalance, setPaymentMethodAmount } from '../store/pos-slice';
import { storeSelector } from '../store/store-slice';
import PinModal from './pin-modal';

const MakePayment = () => {
  const dispatch = useDispatch();
  const { store } = useSelector(storeSelector);
  const { changeToClient, pending, total, balance, changeInBalance, loading, status, paymentMethods, bookingPayments } = useSelector(posSelector);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);

  const storePaymentMethods: PaymentMethod[] = useMemo(() => {
    if (!store) {
      return [];
    }
    return store.paymentMethods.filter((paymentMethod: PaymentMethod) => {
      if (paymentMethod.deleted) {
        return false;
      }
      if (paymentMethod.online) {
        return false;
      }
      if (!paymentMethod.availableInPos) {
        return false;
      }
      if (paymentMethod.type === PaymentMethodType.Balance && !balance) {
        return false;
      }
      return true;
    });
  }, [balance, store]);

  const onFinalizePayment = () => {
    if (status === SaleStatus.Pending && pending > 0) {
      myToastr.error(`Faltan por abonar ${formatNumber(pending)}€`);
      return;
    }
    setShowPinModal(true);
  };

  const onClosePinModal = (pin: string | null) => {
    setShowPinModal(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    dispatch(createSale(pin));
  };

  const completePaymentMethod = (paymentMethod: PaymentMethod) => {
    if (status === SaleStatus.Pending) {
      dispatch(
        setPaymentMethodAmount({
          paymentMethodId: paymentMethod.id,
          amount: pending,
        }),
      );
    } else if (status === SaleStatus.Reservation) {
      dispatch(
        setBookingPaymentAmount({
          paymentMethodId: paymentMethod.id,
          amount: pending,
        }),
      );
    }
  };

  return (
    <React.Fragment>
      <div className="make-payment d-flex flex-column position-relative">
        <div className="d-flex flex-column p-4 mb-auto">
          <div>
            <h1 className="mb-3 text-center">{status === SaleStatus.Pending ? 'Realizar pago' : 'Pago a cuenta'}</h1>
          </div>
          <div className="d-flex flex-row justify-content-between mb-3">
            <div className="d-flex flex-column payment-columns me-4">
              {storePaymentMethods.map((paymentMethod: PaymentMethod) => {
                const bookingPaymentDto: BookingPaymentDto | undefined =
                  status === SaleStatus.Reservation ? bookingPayments.find((bookingPayment: BookingPaymentDto) => bookingPayment.paymentMethodId === paymentMethod.id) : undefined;
                return (
                  <div
                    key={paymentMethod.id}
                    className={clsx('mb-3 payment-method', {
                      paytpv: paymentMethod.isTpv,
                      paybalance: paymentMethod.type === PaymentMethodType.Balance,
                      paycash: paymentMethod.type === PaymentMethodType.Cash,
                      paycustom: paymentMethod.type === PaymentMethodType.Custom,
                      payimports: paymentMethod.type === PaymentMethodType.Imports,
                    })}
                  >
                    <div className="name d-flex align-items-center">
                      {paymentMethod.type === PaymentMethodType.Balance && <User className="me-2" size={14} />}
                      <div className="d-flex flex-row position-relative flex-grow-1">
                        <span>{paymentMethod.isTpv ? `TPV - ${paymentMethod.name}` : paymentMethod.name}</span>
                      </div>
                      {paymentMethod.type === PaymentMethodType.Balance && <span className="me-2">{balance ? formatNumber(balance.money) : 0}€</span>}
                      {pending > 0 &&
                        ((status === SaleStatus.Pending && !paymentMethods[paymentMethod.id]) || (status === SaleStatus.Reservation && !bookingPaymentDto)) &&
                        (paymentMethod.type !== PaymentMethodType.Balance || (balance !== null && balance.money >= pending)) && (
                          <div onClick={() => completePaymentMethod(paymentMethod)} className="cursor-pointer me-2 mb-1" title="Completar pago">
                            <DollarSign size={14} />
                          </div>
                        )}
                    </div>
                    <div className="container-amount">
                      <input
                        placeholder="Introduce cantidad"
                        type="number"
                        step="0.01"
                        value={paymentMethods[paymentMethod.id] || bookingPaymentDto?.amount || undefined}
                        min={0}
                        max={paymentMethod.type === PaymentMethodType.Balance ? balance!.money : '1000'}
                        onChange={(e: any) => {
                          let amount: number | null = null;
                          if (e.target.value !== '') {
                            amount = Math.max(0, parseFloat(e.target.value));
                            if (paymentMethod.type === PaymentMethodType.Balance && amount > balance!.money) {
                              amount = balance!.money;
                            } else if (paymentMethod.type === PaymentMethodType.Cash && amount > 1000) {
                              amount = 1000;
                            }
                          }
                          if (status === SaleStatus.Pending) {
                            dispatch(
                              setPaymentMethodAmount({
                                paymentMethodId: paymentMethod.id,
                                amount,
                              }),
                            );
                          } else if (status === SaleStatus.Reservation) {
                            dispatch(
                              setBookingPaymentAmount({
                                paymentMethodId: paymentMethod.id,
                                amount,
                              }),
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex flex-column payment-columns ms-4">
              <div className="d-flex flex-column container-money mb-3">
                <span className="title">Total</span>
                <span className="value">{formatNumber(total)}€</span>
              </div>
              <div className={clsx('d-flex flex-column container-money mb-3', { alert: pending > 0, inactive: pending === 0 })}>
                <span className="title">Pendiente</span>
                <span className="value">{formatNumber(pending)}€</span>
              </div>
              <div className={clsx('d-flex flex-column container-money mb-3', { success: changeToClient > 0, inactive: changeToClient === 0 })}>
                <span className="title">Cambio</span>
                <div className="d-flex flex-column justify-content-between">
                  <span className="value">{formatNumber(changeToClient)}€</span>
                  {changeToClient > 0 && (
                    <div className="save-balance">
                      <input
                        type="checkbox"
                        checked={changeInBalance}
                        onChange={(e: any) => {
                          dispatch(setChangeInBalance(e.target.checked));
                        }}
                      />
                      <label>Guardar en saldo</label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-buttons">
          <button onClick={onFinalizePayment} disabled={loading} className={clsx('finalize-button', { 'valid-sale': pending === 0 || loading || status === SaleStatus.Reservation })}>
            Finalizar
          </button>
        </div>
      </div>
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
    </React.Fragment>
  );
};

export default MakePayment;
