import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DollarSign, RefreshCw, User as UserIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { CashRegisterType } from '../enums/cash-register-type';
import { PaymentMethodType } from '../enums/payment-method-type';
import { RepairStatus } from '../enums/repair-status';
import { Role } from '../enums/role';
import { CashRegisterCheck } from '../interfaces/cash-register-check';
import { PaymentMethod } from '../interfaces/payment-method';
import { PaymentOnAccountDto } from '../interfaces/payment-on-account.dto';
import { RepairPaymentMethod } from '../interfaces/repair-payment-method';
import { RepairPaymentMethodDto } from '../interfaces/repair-payment-method.dto';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { formatNumber } from '../services/helpers';
import { useAppSelector } from '../store/hooks';
import { repairSelector, setChangeInBalance, setPaymentsOnAccount, setRepairPaymentMethodAmount, updateRepairPaymentMethod } from '../store/repair-slice';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';
import SelectPaymentMethodModal from './select-payment-method-modal';

const MakePaymentRepair = () => {
  const user: User | null = useAppSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const { store } = useSelector(storeSelector);
  const { changeToClient, pending, repairPrice, repairPaymentMethods, balance, changeInBalance, repairStatus, repair, paymentsOnAccount } = useSelector(repairSelector);
  const [selectedRepairPaymentMethod, setSelectedRepairPaymentMethod] = useState<RepairPaymentMethod | null>(null);
  const [currentCashRegisterCheck, setCurrentCashRegisterCheck] = useState<CashRegisterCheck | null>(null);

  const storePaymentMethods: PaymentMethod[] = useMemo(() => {
    if (!store) {
      return [];
    }
    return store.paymentMethods.filter((paymentMethod: PaymentMethod) => {
      if (paymentMethod.deleted) {
        return false;
      }
      if (paymentMethod.online) {
        return false;
      }
      if (!paymentMethod.availableInPos) {
        return false;
      }
      if (paymentMethod.type === PaymentMethodType.Balance && !balance) {
        return false;
      }
      return true;
    });
  }, [balance, store]);

  const title: string = useMemo(() => {
    switch (repairStatus) {
      case RepairStatus.InProcess:
        return 'Pago a cuenta';
      case RepairStatus.Finished:
        return 'Realizar pago';
      case RepairStatus.Delivered:
        return 'Pago realizado';
      default:
        return '';
    }
  }, [repairStatus]);

  const disabledInput: boolean = useMemo(() => {
    if (!repair) {
      return true;
    }
    if (repairStatus === RepairStatus.Delivered) {
      return true;
    }
    if (repairStatus === RepairStatus.Finished) {
      return false;
    }
    return false;
  }, [repairStatus, repair]);

  const onCloseSelectPaymentMethod = async (newPaymentMethodId: number) => {
    await dispatch(
      updateRepairPaymentMethod({
        repairPaymentMethodId: selectedRepairPaymentMethod!.id,
        newPaymentMethodId,
      }),
    );
    setSelectedRepairPaymentMethod(null);
    window.location.reload();
  };

  const getCurrentCashRegisterCheck = useCallback(async () => {
    try {
      const crc: CashRegisterCheck = await api.getCurrentCashRegisterCheck(repair!.storeId);
      setCurrentCashRegisterCheck(crc);
    } catch (e) {}
  }, [repair]);

  useEffect(() => {
    if (!repair || !user) {
      return;
    }
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      getCurrentCashRegisterCheck();
    }
  }, [getCurrentCashRegisterCheck, repair, user]);

  const completePaymentMethod = (paymentMethod: PaymentMethod) => {
    if (repairStatus === RepairStatus.InProcess) {
      dispatch(
        setPaymentsOnAccount({
          paymentMethodId: paymentMethod.id,
          amount: pending,
        }),
      );
    } else {
      dispatch(
        setRepairPaymentMethodAmount({
          paymentMethodId: paymentMethod.id,
          amount: pending,
        }),
      );
    }
  };

  if (!repair) {
    return null;
  }

  return (
    <div className="make-payment d-flex flex-column position-relative">
      <div className="d-flex flex-column mb-auto">
        <div className="pt-3">
          <h1 className="mb-3 text-center">{title}</h1>
        </div>
        <div className="d-flex flex-row justify-content-between mb-3">
          <div className="d-flex flex-column payment-columns me-4">
            <div className="d-flex flex-column">
              {storePaymentMethods.map((paymentMethod: PaymentMethod) => {
                const repairPaymentMethodDto: RepairPaymentMethodDto | undefined = repairPaymentMethods.find((rpm: RepairPaymentMethodDto) => rpm.paymentMethodId === paymentMethod.id);
                const paymentOnAccountDto: PaymentOnAccountDto | undefined = paymentsOnAccount.find((poa: PaymentOnAccountDto) => poa.paymentMethodId === paymentMethod.id);
                const repairPaymentMethod: RepairPaymentMethod | undefined = repair.repairPaymentMethods.find((rpm: RepairPaymentMethod) => rpm.paymentMethodId === paymentMethod.id);
                return (
                  <div
                    key={paymentMethod.id}
                    className={clsx('mb-3 payment-method', {
                      paytpv: paymentMethod.isTpv,
                      paybalance: paymentMethod.type === PaymentMethodType.Balance,
                      paycash: paymentMethod.type === PaymentMethodType.Cash,
                      paycustom: paymentMethod.type === PaymentMethodType.Custom,
                      payimports: paymentMethod.type === PaymentMethodType.Imports,
                    })}
                  >
                    <div className="name d-flex align-items-center">
                      {paymentMethod.type === PaymentMethodType.Balance && <UserIcon className="me-2" size={14} />}
                      <div className="d-flex flex-row position-relative flex-grow-1">
                        <span>{paymentMethod.isTpv ? `TPV - ${paymentMethod.name}` : paymentMethod.name}</span>
                      </div>
                      {paymentMethod.type === PaymentMethodType.Balance && <span>{balance ? formatNumber(balance.money) : 0}€</span>}
                      {repairPaymentMethod &&
                        currentCashRegisterCheck !== null &&
                        repair.repairStatus === RepairStatus.Delivered &&
                        currentCashRegisterCheck.type === CashRegisterType.Open &&
                        currentCashRegisterCheck.createdAt <= repair.createdAt && (
                          <div onClick={() => setSelectedRepairPaymentMethod(repairPaymentMethod)} className="cursor-pointer ms-2 mb-1" title="Cambiar método de pago">
                            <RefreshCw size={14} />
                          </div>
                        )}
                      {pending > 0 && (paymentMethod.type !== PaymentMethodType.Balance || (balance !== null && balance.money >= pending)) && (
                        <div onClick={() => completePaymentMethod(paymentMethod)} className="cursor-pointer me-2 mb-1" title="Completar pago">
                          <DollarSign size={14} />
                        </div>
                      )}
                    </div>
                    <div className="container-amount">
                      <input
                        placeholder={repairStatus === RepairStatus.Finished && pending > 0 ? 'Introduce cantidad' : '0.00€'}
                        type="number"
                        disabled={disabledInput}
                        step="0.01"
                        min={0}
                        max={paymentMethod.type === PaymentMethodType.Balance ? balance!.money : ''}
                        value={repairPaymentMethodDto?.amount || paymentOnAccountDto?.amount || undefined}
                        onChange={(e: any) => {
                          let amount: number | null = null;
                          if (e.target.value !== '') {
                            amount = Math.max(0, parseFloat(e.target.value));
                            if (paymentMethod.type === PaymentMethodType.Balance && amount > balance!.money) {
                              amount = balance!.money;
                            } else if (paymentMethod.type === PaymentMethodType.Cash && amount > 1000) {
                              amount = 1000;
                            }
                          }
                          if (repairStatus === RepairStatus.InProcess) {
                            dispatch(
                              setPaymentsOnAccount({
                                paymentMethodId: paymentMethod.id,
                                amount,
                              }),
                            );
                          } else {
                            dispatch(
                              setRepairPaymentMethodAmount({
                                paymentMethodId: paymentMethod.id,
                                amount,
                              }),
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex flex-column payment-columns ms-4">
            <div className="d-flex flex-column">
              <div className="d-flex flex-column container-money">
                <span className="title">Total</span>
                <span className="value">{formatNumber(repairPrice)}€</span>
              </div>
              <div className={clsx('d-flex flex-column container-money my-4', { alert: pending > 0, inactive: pending === 0 })}>
                <span className="title">Pendiente</span>
                <span className="value">{formatNumber(pending)}€</span>
              </div>
              <div className={clsx('d-flex flex-column container-money', { success: changeToClient > 0, inactive: changeToClient === 0 })}>
                <span className="title">Cambio</span>
                <div className="d-flex flex-column justify-content-between">
                  <span className="value">{formatNumber(changeToClient)}€</span>
                  {changeToClient > 0 && (
                    <div className="save-balance">
                      <input
                        disabled={repairStatus === RepairStatus.Delivered}
                        type="checkbox"
                        checked={changeInBalance}
                        onChange={(e: any) => {
                          dispatch(setChangeInBalance(e.target.checked));
                        }}
                      />
                      <label>Guardar en saldo</label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SelectPaymentMethodModal
        amount={selectedRepairPaymentMethod?.amount || 0}
        customerId={repair.customerId}
        onCancel={() => setSelectedRepairPaymentMethod(null)}
        onSave={onCloseSelectPaymentMethod}
        show={selectedRepairPaymentMethod !== null}
        storeId={repair.storeId}
        paymentMethodId={selectedRepairPaymentMethod?.paymentMethod.id || -1}
      />
    </div>
  );
};

export default MakePaymentRepair;
