import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Edit, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import SubFamilyModal from '../components/subfamily-modal';
import Table from '../components/table';
import { Role } from '../enums/role';
import { SubFamily } from '../interfaces/subfamily';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const SubFamiliesView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [subFamilies, setSubFamilies] = useState<SubFamily[]>([]);
  const [showSubFamilyModal, setShowSubFamilyModal] = useState<boolean>(false);
  const [selectedSubFamily, setSelectedSubFamily] = useState<SubFamily | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);

  const getSubFamilies = async () => {
    setRequesting(true);
    const subFamilies: SubFamily[] = await api.getSubFamilies();
    setSubFamilies(subFamilies);
    setRequesting(false);
  };

  useEffect(() => {
    getSubFamilies();
  }, []);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      { Header: 'Nombre', accessor: 'name', filter: 'fuzzyText' },
      {
        Header: 'Subfamilia',
        accessor: 'family.name',
        filter: 'fuzzyText',
      },
    ];
    if (user.role === Role.SuperAdmin) {
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                size={16}
                color="#222E3D"
                onClick={() => {
                  setSelectedSubFamily(row.original);
                  setShowSubFamilyModal(true);
                }}
                type="button"
                className="mx-2"
              />
              <Trash2
                type="button"
                className="mx-2"
                onClick={() => {
                  setSelectedSubFamily(row.original);
                  setShowConfirmModal(true);
                }}
                size={14}
              />
            </React.Fragment>
          );
        },
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subFamilies]) as any;

  const newSubFamily = () => {
    setSelectedSubFamily(null);
    setShowSubFamilyModal(true);
  };

  const onSubFamilyModalClose = (subFamily: SubFamily | null) => {
    if (subFamily) {
      getSubFamilies();
    }
    setShowSubFamilyModal(false);
    setSelectedSubFamily(null);
  };

  const deleteSubFamily = async (f: SubFamily) => {
    try {
      await api.deleteSubFamily(f.id);
      const fms: SubFamily[] = [...subFamilies];
      const index: number = fms.findIndex((fm: SubFamily) => fm.id === f.id);
      fms.splice(index, 1);
      setSubFamilies(fms);
      myToastr.success('Subfamilia eliminada correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const subFamily: SubFamily = fr.original;
      data.push({
        Nombre: subFamily.name,
        Familia: subFamily.family.name,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="subFamilies p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Subfamilias</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="familias.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {user.role === Role.SuperAdmin && (
          <button className="d-flex align-items-center create-button" onClick={newSubFamily}>
            <Plus className="me-1" size={14} /> Nueva subfamilia
          </button>
        )}
      </div>
      <Table
        data={subFamilies}
        columns={columns}
        noDataMessage="No hay subfamilias"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <SubFamilyModal show={showSubFamilyModal} closeModal={onSubFamilyModalClose} subFamily={selectedSubFamily} />
      {selectedSubFamily && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar subfamilia"
          content={`¿Estás seguro que quieres eliminar la subfamilia ${selectedSubFamily.name}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteSubFamily(selectedSubFamily);
            }
            setSelectedSubFamily(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default SubFamiliesView;
