import clsx from 'clsx';
import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { Customer } from '../interfaces/customer';
import { Organization } from '../interfaces/organization';
import { api } from '../services/api';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';
import CustomerModal from './customer-modal';
import Table from './table';

const debouncedGetCustomers = debounce(async (inputValue: string, organizationId: number, cb: (cs: Customer[]) => void) => {
  if (inputValue == null || inputValue === '') {
    cb([]);
    return;
  }
  const customers: Customer[] = await api.getCustomers(organizationId, inputValue);
  cb(customers);
}, 1000);

interface Props {
  show: boolean;
  closeModal: (customer: Customer | null) => void;
}

const AssignCustomerModal = ({ show, closeModal }: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { store } = useSelector(storeSelector);
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const columns = useMemo(() => {
    const columnsData = [
      {
        Header: 'Nombre',
        id: 'customer',
        width: 380,
        accessor: (customer: Customer) => {
          let value = '';
          if (customer.name) {
            value += customer.name;
          }
          if (customer.surnames) {
            value += ` ${customer.surnames}`;
          }
          return value;
        },
        Cell: ({ value, row }: any) => <span className="fw-bold">{value.length === 0 ? 'Sin nombre' : `${value}`}</span>,
        filter: 'fuzzyText',
      },
      {
        Header: 'Email',
        accessor: 'email',
        filter: 'fuzzyText',
      },
      {
        Header: 'Teléfono',
        accessor: 'phone',
        filter: 'fuzzyText',
      },
      {
        Header: 'Tel. Secundario',
        accessor: 'secondaryPhone',
        filter: 'fuzzyText',
      },
      {
        Header: 'Seleccionar',
        Cell: ({ row }: any) => {
          const customer: Customer = row.original;
          return (
            <input
              type="radio"
              style={{ width: 18 }}
              name="customer"
              value={customer.id}
              checked={selectedCustomer?.id === customer.id}
              onChange={(e: any) => {
                if (e.target.value) {
                  setSelectedCustomer(customer);
                } else {
                  setSelectedCustomer(null);
                }
              }}
            />
          );
        },
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, selectedCustomer]);

  useEffect(() => {
    if (!query || query.trim().length === 0) {
      setCustomers([]);
      return;
    }
    setRequesting(true);
    debouncedGetCustomers(query, organization.id, (customers: Customer[]) => {
      setCustomers(customers);
      setRequesting(false);
      setSelectedCustomer(null);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, store]);

  useEffect(() => {
    if (!show) {
      clearData();
    }
  }, [show]);

  const clearData = () => {
    setSelectedCustomer(null);
    setCustomers([]);
    setQuery('');
  };

  const onCloseCustomerModal = (newCustomer: Customer | null) => {
    setShowCustomerModal(false);
    if (newCustomer) {
      closeModal(newCustomer);
    }
  };

  const close = () => {
    closeModal(null);
  };

  const save = async () => {
    closeModal(selectedCustomer);
  };

  return (
    <React.Fragment>
      <Modal className="vercomi-modal my-form reassing-sale-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
        <form>
          <div className="content">
            <div className="d-flex flex-row mb-3">
              <div className="title flex-grow-1">Cambiar cliente</div>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowCustomerModal(true);
                }}
                className="button-create-customer"
              >
                Crear cliente
              </button>
              <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
                <X size={16} />
              </button>
            </div>
            <div className="row my-3">
              <div className="col">
                <input type="text" placeholder="Buscar cliente..." onChange={(e: any) => setQuery(e.target.value)} />
              </div>
            </div>
            <div className="row my-3">
              {requesting && (
                <div className="text-center">
                  <Loader type="TailSpin" color="white" height={40} width={40} />
                </div>
              )}
              {!requesting && query.length > 0 && <Table data={customers} columns={columns} noDataMessage="No hay clientes" onFilteredRowsChanged={() => {}} />}
            </div>
          </div>
          <button disabled={!selectedCustomer} className={clsx(`mt-0 save-button`, { disabled: !selectedCustomer })} type="button" onClick={save}>
            Guardar
          </button>
        </form>
      </Modal>
      <CustomerModal show={showCustomerModal} closeModal={onCloseCustomerModal} customer={null} billingDataRequired={false} />
    </React.Fragment>
  );
};

export default AssignCustomerModal;
