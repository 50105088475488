import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight, X } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import ConfirmModal from '../components/confirm-modal';
import ProductStockTable from '../components/product-stock-table';
import Table from '../components/table';
import UploadProductImageModal from '../components/upload-product-image-modal';
import { Order } from '../enums/order';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import { TransferStatus } from '../enums/transfer-status';
import { Brand } from '../interfaces/brand';
import { Category } from '../interfaces/category';
import { Collection } from '../interfaces/collection';
import { DeliveryNote } from '../interfaces/delivery-note';
import { DeliveryNoteProduct } from '../interfaces/delivery-note-product';
import { Family } from '../interfaces/family';
import { GetStock } from '../interfaces/get-stock';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { InternalEan } from '../interfaces/internal-ean';
import { Organization } from '../interfaces/organization';
import { PreviousNextStock } from '../interfaces/previous-next-stock';
import { Product } from '../interfaces/product';
import { ProductHistory } from '../interfaces/product-history';
import { ProductImage } from '../interfaces/product-image';
import { RawImage } from '../interfaces/raw-image';
import { SubCategory } from '../interfaces/subcategory';
import { SubFamily } from '../interfaces/subfamily';
import { Transfer } from '../interfaces/transfer';
import { UpdateProductDto } from '../interfaces/update-product.dto';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { downloadPdf, productIsEditable } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';
import { multiSelectStylesDeliveryNotes } from '../styless/multiselect-style';

const styleMultiSelect = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#fff',
    borderColor: '#808a95',
    borderRadius: '7px',
    paddingLeft: '5px',
    boxShadow: state.isFocused ? null : null,
    width: '100%',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0 6px',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state: any) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

const ProductView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const { selectedStoreId } = useSelector(storeSelector);
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [brand, setBrand] = useState<{ label: string; value: number } | null>(null);
  const [errorBrand, setErrorBrand] = useState<boolean>(false);
  const [collectionId, setCollectionId] = useState<number>(-1);
  const [sku, setSku] = useState<string>('');
  const [ean, setEan] = useState<string>('');
  const [brands, setBrands] = useState<Brand[]>([]);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [changed, setChanged] = useState<boolean>(false);
  const [productHistory, setProductHistory] = useState<ProductHistory[]>([]);
  const [selectedProductImage, setSelectedProductImage] = useState<ProductImage | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [showUploadProductModal, setShowUploadProductModal] = useState<boolean>(false);
  const [requestingProductLabel, setRequestingProductLabel] = useState<boolean>(false);
  const [deliveryNoteProducts, setDeliveryNoteProducts] = useState<DeliveryNoteProduct[]>([]);
  const [previousNextStock, setPreviousNextStock] = useState<PreviousNextStock | null>(null);
  const [families, setFamilies] = useState<Family[]>([]);
  const [subFamilies, setSubFamilies] = useState<SubFamily[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState<number[]>([]);
  const [selectedFamilies, setSelectedFamilies] = useState<{ value: number; label: string }[]>([]);
  const [selectedSubFamilies, setSelectedSubFamilies] = useState<{ value: number; label: string }[]>([]);
  const [amazonSku, setAmazonSku] = useState<string>('');
  const [amazonAsin, setAmazonAsin] = useState<string>('');
  const [amazonFnsku, setAmazonFnsku] = useState<string>('');
  const [internalEan, setInternalEan] = useState<InternalEan | null>(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false);
  const filteredSubFamilies: { label: string; value: number }[] = useMemo(() => {
    if (selectedFamilies.length === 0) {
      return [];
    }
    return subFamilies
      .filter((sf: SubFamily) => {
        const index: number = selectedFamilies.findIndex((f: { label: string; value: number }) => f.value === sf.familyId);
        return index !== -1;
      })
      .map((sf: SubFamily) => {
        return {
          value: sf.id,
          label: sf.name,
        };
      });
  }, [selectedFamilies, subFamilies]);
  const filteredCollections: Collection[] = useMemo(() => {
    if (!brand) {
      return [];
    }
    return collections.filter((c: Collection) => c.brandId === brand.value);
  }, [collections, brand]);

  const columnsDeliveryNoteProducts = useMemo(() => {
    return [
      {
        Header: 'Número',
        accessor: 'deliveryNote.internalId',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const deliveryNote: DeliveryNote = row.original.deliveryNote;
          return (
            <Link to={`/compra/${deliveryNote.id}`} className="position-relative link-sale">
              <span className="fw-bold">{deliveryNote.internalId}</span>
            </Link>
          );
        },
      },
      {
        Header: 'Fecha',
        accessor: 'createdAt',
        disableFilters: true,
        Cell: ({ value }: any) => moment(value).format('DD/MM/YYYY'),
      },
      {
        Header: 'Proveedor',
        accessor: 'supplier.name',
        disableFilters: true,
      },
      {
        Header: 'Unidades',
        accessor: 'units',
        disableFilters: true,
      },
    ];
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isEditable: boolean = useMemo(() => productIsEditable(user, product), [product]);

  const columnsProductHistory = useMemo(() => {
    const data: any[] = [
      {
        Header: 'Fecha',
        accessor: 'createdAt',
        disableFilters: true,
        Cell: ({ value }: any) => {
          return <span className="text-bold">{moment(value).format('DD/MM/YYYY HH:mm')}</span>;
        },
      },
      {
        Header: organizationType === OrganizationType.B2C ? 'Tienda' : 'Almacén',
        accessor: 'store.name',
        disableFilters: true,
      },
      {
        Header: 'Usuario',
        accessor: (row: any) => {
          let userName = '';
          if (row.user) {
            userName = row.user.name;
            if (row.user.surnames) {
              userName += ` ${row.user.surnames}`;
            }
          }
          return userName;
        },
        disableFilters: true,
      },
      {
        Header: 'Documento',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const productHistory: ProductHistory = row.original;
          if (productHistory?.deliveryNote) {
            return (
              <Link to={`/compra/${productHistory.deliveryNote.id}`} className="position-relative link-sale">
                <span className="fw-bold">{productHistory.deliveryNote.internalId}</span>
              </Link>
            );
          } else if (productHistory?.sale) {
            return (
              <Link to={`/venta/${productHistory.sale.id}`} className="position-relative link-sale">
                <span className="fw-bold">{productHistory.sale.internalId || productHistory.sale.internalReservationId}</span>
              </Link>
            );
          } else if (productHistory?.transfer) {
            const transfer: Transfer = productHistory.transfer;
            let to = `/traspaso/${transfer.id}`;
            if (transfer.status === TransferStatus.Pending) {
              if (moment(transfer.shippingDate).isBefore(moment())) {
                to = `/traspaso/${transfer.id}/verificar`;
              } else {
                to = `/traspaso/${transfer.id}/pendiente`;
              }
            }
            return (
              <Link to={to} className="position-relative link-sale">
                <span className="fw-bold">{transfer.internalId}</span>
              </Link>
            );
          } else if (productHistory?.inventory) {
            return (
              <Link to={`/inventario/${productHistory.inventory.id}`} className="position-relative link-sale">
                <span className="fw-bold">{productHistory.inventory.internalId}</span>
              </Link>
            );
          } else if (productHistory?.repair) {
            return (
              <Link to={`/reparacion/${productHistory.repair.id}`} className="position-relative link-sale">
                <span className="fw-bold">{productHistory.repair.internalId}</span>
              </Link>
            );
          } else if (productHistory?.invoice) {
            return (
              <Link to={`/factura/${productHistory.invoice.id}`} className="position-relative link-sale">
                <span className="fw-bold">{productHistory.invoice.internalId}</span>
              </Link>
            );
          } else if (productHistory?.purchaseOrder) {
            return (
              <Link to={`/orden-compra/${productHistory.purchaseOrder.id}`} className="position-relative link-sale">
                <span className="fw-bold">{productHistory.purchaseOrder.originInternalId}</span>
              </Link>
            );
          } else {
            return '';
          }
        },
      },
      {
        Header: 'Entrada',
        accessor: 'inputUnits',
        disableFilters: true,
      },
      {
        Header: 'Salida',
        accessor: 'outputUnits',
        disableFilters: true,
      },
      {
        Header: 'Descripción',
        accessor: 'text',
        width: '35%',
        disableFilters: true,
        Cell: ({ value }: any) => {
          return value.replaceAll(/null/g, '0');
        },
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.Business) {
      data.splice(4, 0, {
        Header: 'S. Ant.',
        accessor: 'unitsBeforeAction',
        disableFilters: true,
      });
      data.splice(7, 0, {
        Header: 'S. Act.',
        accessor: 'unitsAfterAction',
        disableFilters: true,
      });
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      const cs: Category[] = await api.getCategories(organization!.id);
      setCategories(cs);
    };
    const getSubCategories = async () => {
      const scs: SubCategory[] = await api.getSubCategories(organization!.id);
      setSubCategories(scs);
    };
    const getBrands = async () => {
      const bs: Brand[] = await api.getBrands();
      setBrands(bs);
    };
    const getCollections = async () => {
      const cs: Collection[] = await api.getCollections();
      setCollections(cs);
    };
    getCategories();
    getSubCategories();
    getBrands();
    getCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.role !== Role.SuperAdmin) {
      return;
    }
    const getFamilies = async () => {
      const fs: Family[] = await api.getFamilies();
      setFamilies(fs);
    };
    const getSubFamilies = async () => {
      const sfs: SubFamily[] = await api.getSubFamilies();
      setSubFamilies(sfs);
    };
    getFamilies();
    getSubFamilies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.id) {
      const productId: number = parseInt(params.id!, 10);
      const getProduct = async () => {
        try {
          setLoading(true);
          const p: Product = await api.getProduct(productId);
          setProduct(p);
          setName(p.name);
          setDescription(p.description);
          setEnabled(p.enabled);
          setBrand({ label: p.brand.name, value: p.brand.id });
          setCollectionId(p.collectionId !== null ? p.collectionId : -1);
          setSku(p.sku);
          setEan(p.ean);
          setSelectedCategories(p.categories.map((c: Category) => c.id));
          setSelectedSubCategories(p.subCategories.map((sc: SubCategory) => sc.id));
          setEditable(p.editable);
          setSelectedFamilies(p.families.map((f: Family) => ({ value: f.id, label: f.name })));
          setSelectedSubFamilies(p.subFamilies.map((sf: SubFamily) => ({ value: sf.id, label: sf.name })));
          setAmazonSku(p.amazonSku);
          setAmazonAsin(p.amazonAsin);
          setAmazonFnsku(p.amazonFnsku);
        } catch (e) {
          navigate('/stock');
        } finally {
          setLoading(false);
        }
      };
      const getPreviousNextStock = async () => {
        try {
          let getStock: GetStock = {
            organizationId: organization!.id,
            storeIds: [],
            brandIds: [],
            collectionIds: [],
            categoryIds: [],
            inStock: null,
            active: null,
            orderField: 'product.sku',
            order: Order.Asc,
            query: '',
            online: null,
          };
          const value: string | null = sessionStorage.getItem('search-stock');
          if (value) {
            try {
              getStock = JSON.parse(value);
            } catch (e) {
              sessionStorage.removeItem('search-stock');
            }
          }
          const previousNextStock: PreviousNextStock = await api.getPreviousNextStock(productId, getStock, state !== null && (state as any).hasOwnProperty('isNext') ? (state as any).isNext : true);
          setPreviousNextStock(previousNextStock);
        } catch (e) {}
      };
      const getDeliveryNoteProducts = async () => {
        const dnps: DeliveryNoteProduct[] = await api.getDeliveryNoteProducts(organization.id, selectedStoreId, productId);
        setDeliveryNoteProducts(dnps);
      };
      const getInternalEan = async () => {
        try {
          const iean: InternalEan = await api.getInternalEan(productId, organization!.id);
          setInternalEan(iean);
        } catch (e) {}
      };
      getProduct();
      getPreviousNextStock();
      getProductHistory(productId);
      getDeliveryNoteProducts();
      getInternalEan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, selectedStoreId, state]);

  const getProductHistory = async (productId: number) => {
    const phs: ProductHistory[] = await api.getProductHistory(productId, organization.id, selectedStoreId);
    setProductHistory(phs);
  };

  useEffect(() => {
    if (product) {
      let hasChanged = false;
      if (product.name !== name) {
        hasChanged = true;
      }
      if (product.description !== description) {
        hasChanged = true;
      }
      if (product.enabled !== enabled) {
        hasChanged = true;
      }
      if (brand !== null && product.brandId !== brand.value) {
        hasChanged = true;
      }
      const tmpCollectionId = product.collectionId === null ? -1 : product.collectionId;
      if (tmpCollectionId !== collectionId) {
        hasChanged = true;
      }
      if (product.sku !== sku) {
        hasChanged = true;
      }
      if (product.ean !== ean) {
        hasChanged = true;
      }
      if (product.editable !== editable) {
        hasChanged = true;
      }
      if (product.categories.length !== selectedCategories.length) {
        hasChanged = true;
      } else {
        for (let i = 0; i < product.categories.length; i++) {
          if (!selectedCategories.includes(product.categories[i].id)) {
            hasChanged = true;
            break;
          }
        }
      }
      if (product.subCategories.length !== selectedSubCategories.length) {
        hasChanged = true;
      } else {
        for (let i = 0; i < product.subCategories.length; i++) {
          if (!selectedSubCategories.includes(product.subCategories[i].id)) {
            hasChanged = true;
            break;
          }
        }
      }
      if (product.families.length !== selectedFamilies.length) {
        hasChanged = true;
      } else {
        for (let i = 0; i < product.families.length; i++) {
          if (product.families[i].id !== selectedFamilies[i].value) {
            hasChanged = true;
            break;
          }
        }
      }
      if (product.subFamilies.length !== selectedSubFamilies.length) {
        hasChanged = true;
      } else {
        for (let i = 0; i < product.subFamilies.length; i++) {
          if (product.subFamilies[i].id !== selectedSubFamilies[i].value) {
            hasChanged = true;
            break;
          }
        }
      }
      if (amazonSku !== product.amazonSku) {
        hasChanged = true;
      }
      if (amazonAsin !== product.amazonAsin) {
        hasChanged = true;
      }
      if (amazonFnsku !== product.amazonFnsku) {
        hasChanged = true;
      }
      setChanged(hasChanged);
    }
  }, [
    product,
    name,
    description,
    enabled,
    brand,
    sku,
    ean,
    selectedCategories,
    selectedSubCategories,
    editable,
    selectedFamilies,
    selectedSubFamilies,
    amazonSku,
    amazonAsin,
    amazonFnsku,
    collectionId,
  ]);

  const changeProductStatus = async (enabled: boolean) => {
    setEnabled(enabled);
  };

  const deleteProductImage = async (imageId: number) => {
    setLoading(true);
    try {
      await api.deleteProductImage(imageId);
      const p: Product = { ...product! };
      p.images = p.images.filter((i: ProductImage) => i.id !== imageId);
      setProduct(p);
      myToastr.success('Imagen eliminada');
    } catch (e) {}
    setLoading(false);
  };

  const updateProduct = async () => {
    try {
      if (!name || name.length === 0) {
        myToastr.error(`El nombre no puede estar vacío`);
        return;
      }
      if (!sku || sku.length === 0) {
        myToastr.error(`El SKU no puede estar vacío`);
        return;
      }
      if (!brand) {
        setErrorBrand(true);
        myToastr.error(`Debes seleccionar una marca`);
        return;
      }
      const updateProductDto: UpdateProductDto = {
        iva: parseFloat(process.env.REACT_APP_IVA),
        name,
        description,
        sku,
        ean,
        enabled,
        brandId: brand!.value,
        collectionId: collectionId === -1 ? null : collectionId,
        categoryIds: selectedCategories,
        subCategoryIds: selectedSubCategories,
        editable,
        familyIds: selectedFamilies.map((f: { label: string; value: number }) => f.value),
        subFamilyIds: selectedSubFamilies.map((f: { label: string; value: number }) => f.value),
        amazonSku,
        amazonAsin,
        amazonFnsku,
      };
      const updatedProduct: Product = await api.updateProduct(product!.id, updateProductDto);
      navigate(`/producto/${updatedProduct.id}`, { replace: true });
      myToastr.success(`Producto actualizado correctamente`);
    } catch (e: any) {
      if (e?.response?.data?.message) {
        if (Array.isArray(e.response.data.message)) {
          myToastr.error(e.response.data.message.map((element: string) => element).join('<br>'));
        } else {
          myToastr.error(e.response.data.message);
        }
      }
    }
  };

  const onCloseUploadProductImageModal = (productImage: ProductImage[] | RawImage | null) => {
    if (Array.isArray(productImage)) {
      const p: Product = { ...product! };
      p.images = p.images.concat(productImage as ProductImage[]);
      setProduct(p);
    }
    setShowUploadProductModal(false);
  };

  const printLabel = async () => {
    if (selectedStoreId <= 0) {
      myToastr.error(organizationType === OrganizationType.B2C ? 'Selecciona una tienda' : 'Selecciona un almacén');
      return;
    }
    if (requestingProductLabel) {
      return;
    }
    try {
      myToastr.info('Obteniendo la etiqueta del producto. Espere por favor.');
      setRequestingProductLabel(true);
      const result: ArrayBuffer = await api.getProductLabel(product!.id, organization!.id, selectedStoreId);
      downloadPdf(result, `etiqueta-producto-${product!.sku}.pdf`);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = JSON.parse(new TextDecoder().decode(axiosError.response.data as any));
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    } finally {
      setRequestingProductLabel(false);
    }
  };

  const onChangeMainImage = async (id: number) => {
    try {
      const productImages: ProductImage[] = await api.setProductImageAsMain(id);
      const p: Product = { ...product! };
      p.images = productImages;
      setProduct(p);
      myToastr.success('Imagen principal actualizada');
    } catch (e) {}
  };

  const onDeleteProduct = async (result: boolean) => {
    if (!result) {
      setShowDeleteConfirmModal(false);
      return;
    }
    try {
      await api.deleteProduct(product!.id);
      myToastr.info('Producto eliminado');
      navigate('/productos', { replace: true });
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
    setShowDeleteConfirmModal(false);
  };

  if (!product) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="customer-view d-flex flex-column p-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start flex-grow-1 align-items-center">
            <div onClick={() => navigate('/stock')} className="come-back me-4">
              <ChevronLeft size={14} className="me-2" />
              Volver
            </div>
            <div className="customer-name">{sku || ''}</div>
          </div>
          <div className="flex flex-row align-items-center">
            {previousNextStock !== null && previousNextStock?.previous !== null && (
              <div
                className="come-back me-2"
                onClick={() =>
                  navigate(`/producto/${previousNextStock!.previous}`, {
                    state: {
                      isNext: false,
                    },
                  })
                }
              >
                <ChevronLeft size={14} className="me-2" />
                Anterior
              </div>
            )}
            {previousNextStock !== null && previousNextStock.next !== null && (
              <div
                className="come-back ms-2"
                onClick={() =>
                  navigate(`/producto/${previousNextStock!.next}`, {
                    state: {
                      isNext: true,
                    },
                  })
                }
              >
                Siguiente
                <ChevronRight size={14} className="me-2" />
              </div>
            )}
            <button className="save-changes" onClick={printLabel}>
              Imprimir etiqueta
            </button>
            {(user?.role === Role.SuperAdmin || editable) && (
              <button className="save-changes ms-2" disabled={!changed} onClick={updateProduct}>
                Guardar cambios
              </button>
            )}
            {user?.role === Role.SuperAdmin && (
              <button className="delete-product ms-2" onClick={() => setShowDeleteConfirmModal(true)}>
                Eliminar producto
              </button>
            )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-8 px-2">
            <div className="d-flex flex-column container-info mb-2">
              <div className="row mb-3">
                {user.role === Role.SuperAdmin && (
                  <div className="col">
                    <div className="d-flex flex-column">
                      <div className="title-input mb-2">Sku</div>
                      <input value={sku} placeholder="Sku" onChange={(e: any) => setSku(e?.target?.value ? e.target.value : '')} disabled={!isEditable} />
                    </div>
                  </div>
                )}
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Nombre</div>
                    <input value={name} placeholder="Nombre" onChange={(e: any) => setName(e?.target?.value ? e.target.value : '')} disabled={!isEditable} />
                  </div>
                </div>
              </div>
              {user.role === Role.SuperAdmin && (
                <div className="row mb-3">
                  <div className="col">
                    <div className="d-flex flex-column">
                      <div className="title-input mb-2">Familias</div>
                      <Select
                        isMulti
                        name="families"
                        value={selectedFamilies}
                        options={families.map((f: Family) => ({ value: f.id, label: f.name }))}
                        className="basic-multi-select"
                        onChange={(e: any) => {
                          setSelectedFamilies(e);
                          const newSubFamilies: { value: number; label: string }[] = selectedSubFamilies.filter((sf: { value: number; label: string }) => {
                            const subFamily: SubFamily = subFamilies.find((x: SubFamily) => x.id === sf.value)!;
                            const index: number = e.findIndex((x: { value: number; label: string }) => x.value === subFamily.familyId);
                            return index !== -1;
                          });
                          setSelectedSubFamilies(newSubFamilies);
                        }}
                        styles={styleMultiSelect}
                        placeholder="Selecciona familias"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column">
                      <div className="title-input mb-2">Subfamilias</div>
                      <Select
                        isMulti
                        isDisabled={selectedFamilies.length === 0}
                        name="subFamilies"
                        value={selectedSubFamilies}
                        options={filteredSubFamilies}
                        className="basic-multi-select"
                        onChange={(e: any) => setSelectedSubFamilies(e)}
                        styles={styleMultiSelect}
                        placeholder={selectedFamilies.length === 0 ? 'Selecciona al menos una familia' : 'Selecciona subfamilias'}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Marca</div>
                    <Select
                      isDisabled={!isEditable}
                      options={brands.map((b: Brand) => ({
                        value: b.id,
                        label: b.name,
                      }))}
                      placeholder="Selecciona una marca..."
                      value={brand}
                      onChange={(newBrand: any) => {
                        setBrand(newBrand);
                        if (!newBrand || brand?.value !== newBrand.value) {
                          setCollectionId(-1);
                        }
                      }}
                      onFocus={() => setErrorBrand(false)}
                      isClearable={isEditable}
                      styles={multiSelectStylesDeliveryNotes(errorBrand)}
                      menuPortalTarget={document.body}
                      filterOption={(option, inputValue: string) => (!inputValue || inputValue.length < 2 ? false : option.label.toLowerCase().startsWith(inputValue.toLowerCase()))}
                      noOptionsMessage={({ inputValue }) =>
                        !inputValue ? <div>Introduce la marca</div> : inputValue.length < 2 ? <div>Escribe al menos 2 caracteres</div> : <div>No hay coincidencias</div>
                      }
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Colección</div>
                    {!brand ? (
                      <span>Selecciona una marca</span>
                    ) : filteredCollections.length === 0 ? (
                      <span>La marca no tiene colecciones</span>
                    ) : (
                      <select
                        value={collectionId}
                        placeholder="Colección"
                        onChange={(e: any) => setCollectionId(e?.target?.value ? parseInt(e.target.value, 10) : -1)}
                        disabled={!isEditable || !brand}
                      >
                        {!brand && <option value={-1}>Selecciona una marca</option>}
                        {brand !== null && filteredCollections.length > 0 && <option value={-1}>Selecciona una colección</option>}
                        {filteredCollections.map((c: Collection) => (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Categorías y subcategorías</div>
                    {categories.length === 0 ? (
                      <p>No categorías disponibles</p>
                    ) : (
                      <ul style={{ height: 200, overflowY: 'scroll', paddingLeft: 0 }}>
                        {categories.map((c: Category) => {
                          const scs: SubCategory[] = subCategories.filter((sc: SubCategory) => sc.categoryId === c.id);
                          return (
                            <li key={c.id}>
                              <div className="d-flex flex-row align-items-center">
                                <input
                                  type="checkbox"
                                  checked={selectedCategories.includes(c.id)}
                                  onChange={(e: any) => {
                                    if (e.target.checked) {
                                      setSelectedCategories([...selectedCategories, c.id]);
                                    } else {
                                      setSelectedCategories(selectedCategories.filter((x: number) => x !== c.id));
                                      setSelectedSubCategories(selectedSubCategories.filter((x: number) => !scs.map((sc: SubCategory) => sc.id).includes(x)));
                                    }
                                  }}
                                />
                                <label className="ms-2">{c.name}</label>
                              </div>
                              <ul>
                                {scs.map((sc: SubCategory) => (
                                  <li key={sc.id} className="d-flex flex-row align-items-center">
                                    <input
                                      type="checkbox"
                                      checked={selectedSubCategories.includes(sc.id)}
                                      onChange={(e: any) => {
                                        if (e.target.checked) {
                                          setSelectedSubCategories([...selectedSubCategories, sc.id]);
                                          if (!selectedCategories.includes(c.id)) {
                                            setSelectedCategories([...selectedCategories, c.id]);
                                          }
                                        } else {
                                          setSelectedSubCategories(selectedSubCategories.filter((x: number) => x !== sc.id));
                                        }
                                      }}
                                    />
                                    <label className="ms-2">{sc.name}</label>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Código de barras</div>
                    <input value={ean || ''} placeholder="Código de barras" onChange={(e: any) => setEan(e?.target?.value ? e.target.value : '')} disabled={!isEditable} />
                  </div>
                  <div className="d-flex flex-column mt-2">
                    <div className="title-input mb-2">EAN Interno</div>
                    <input value={internalEan?.ean || ''} placeholder="EAN Interno" disabled />
                  </div>
                </div>
              </div>
            </div>
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <div className="d-flex flex-column container-info container-product-inventory my-3">
                <div className="title mb-2">Amazon</div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="d-flex flex-column">
                      <div className="title-input mb-2">ASKU</div>
                      <input value={amazonSku || ''} placeholder="Código SKU de Amazon" onChange={(e: any) => setAmazonSku(e?.target?.value ? e.target.value : '')} disabled={!isEditable} />
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column">
                      <div className="title-input mb-2">ASIN</div>
                      <input value={amazonAsin || ''} placeholder="Código ASIN" onChange={(e: any) => setAmazonAsin(e?.target?.value ? e.target.value : '')} disabled={!isEditable} />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <div className="title-input mb-2">FNSKU</div>
                      <input value={amazonFnsku || ''} placeholder="Código FNSKU" onChange={(e: any) => setAmazonFnsku(e?.target?.value ? e.target.value : '')} disabled={!isEditable} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex flex-column container-info container-product-inventory">
              <div className="title mb-2">Compras</div>
              <Table data={deliveryNoteProducts} columns={columnsDeliveryNoteProducts} noDataMessage="No hay compras asociadas a este producto"></Table>
            </div>
          </div>
          <div className="col-4 px-2">
            <div className="col mb-2">
              <div className="d-flex flex-column container-info">
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="title-input">Imágenes</div>
                  {isEditable && (
                    <div className="mark-all" onClick={() => setShowUploadProductModal(true)}>
                      Subir imágenes
                    </div>
                  )}
                </div>
                {product.images.length > 0 && (
                  <div className="row">
                    <div className="col-6">
                      <div className="container-product-image">
                        <LightgalleryProvider>
                          {product.images.map((productImage: ProductImage, index: number) => {
                            return (
                              <LightgalleryItem key={productImage.id} group="group-product-images" src={process.env.REACT_APP_PUBLIC_URL + productImage.path}>
                                {index === 0 && <LazyLoadImage key={productImage.id} src={process.env.REACT_APP_PUBLIC_URL + productImage.path} className="clickable" alt="" />}
                              </LightgalleryItem>
                            );
                          })}
                        </LightgalleryProvider>
                        {!loading && isEditable && (
                          <React.Fragment>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                position: 'absolute',
                                left: 0,
                                top: -12,
                              }}
                            >
                              <input name="product-image" type="radio" checked={product.images[0].isMain} onChange={() => onChangeMainImage(product.images[0].id)} />
                            </div>
                            <div className="d-flex align-items-center justify-content-center remove-icon">
                              <X
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedProductImage(product.images[0]);
                                  setShowConfirmModal(true);
                                }}
                                size={14}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <LightgalleryProvider>
                          {product.images.map((productImage: ProductImage, index: number) => {
                            return (
                              <div key={productImage.id} className="col-4">
                                <LightgalleryItem group="group-product-images" src={process.env.REACT_APP_PUBLIC_URL + productImage.path}>
                                  {index > 0 && (
                                    <div className="container-product-image">
                                      <LazyLoadImage key={productImage.id} src={process.env.REACT_APP_PUBLIC_URL + productImage.path} className="" alt="" />
                                      {!loading && isEditable && (
                                        <React.Fragment>
                                          <div
                                            className="d-flex align-items-center justify-content-center"
                                            style={{
                                              position: 'absolute',
                                              left: 0,
                                              top: -12,
                                            }}
                                          >
                                            <input
                                              name="product-image"
                                              type="radio"
                                              checked={productImage.isMain}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                              }}
                                              onChange={() => onChangeMainImage(productImage.id)}
                                            />
                                          </div>
                                          <div className="d-flex align-items-center justify-content-center remove-icon remove-icon-small">
                                            <X
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedProductImage(productImage);
                                                setShowConfirmModal(true);
                                              }}
                                              size={8}
                                            />
                                          </div>
                                        </React.Fragment>
                                      )}
                                    </div>
                                  )}
                                </LightgalleryItem>
                              </div>
                            );
                          })}
                        </LightgalleryProvider>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="container-info">
              {user.role === Role.SuperAdmin && (
                <div className="d-flex flex-column mb-2">
                  <div className="title-input mb-2">Editable</div>
                  {user.role === Role.SuperAdmin ? (
                    <select name="editable" value={editable ? 1 : 0} onChange={(e: any) => setEditable(e.target.value === '1' ? true : false)}>
                      <option value={1}>Sí</option>
                      <option value={0}>No</option>
                    </select>
                  ) : (
                    <div className={clsx('marketing', { 'not-accepted': !product.enabled })}>{product.enabled ? 'Activo' : 'Desactivado'}</div>
                  )}
                </div>
              )}
              <div className="d-flex flex-column mb-2">
                <div className="title-input mb-2">Estado</div>
                {user.role === Role.SuperAdmin ? (
                  <select name="enabled" value={enabled ? 1 : 0} onChange={(e: any) => changeProductStatus(e.target.value === '1' ? true : false)}>
                    <option value={1}>Activo</option>
                    <option value={0}>Desactivado</option>
                  </select>
                ) : (
                  <div className={clsx('marketing', { 'not-accepted': !product.enabled })}>{product.enabled ? 'Activo' : 'Desactivado'}</div>
                )}
              </div>
              <div className="d-flex flex-column mb-2">
                <div className="title-input mb-2">Descripción</div>
                <textarea value={description || ''} placeholder="Descripción" onChange={(e: any) => setDescription(e?.target?.value ? e.target.value : '')} disabled={!isEditable} />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column container-info container-product-inventory mt-4">
          <div className="title mb-2">Stock</div>
          <div className="stock-table">
            <ProductStockTable productId={product.id} onModifiedStock={() => getProductHistory(product!.id)} />
          </div>
        </div>
        <div className="d-flex flex-column container-info container-product-inventory mt-4">
          <div className="title mb-2">Histórico</div>
          <div className="separator"> </div>
          <Table data={productHistory} columns={columnsProductHistory} noDataMessage="El producto no tiene histórico"></Table>
        </div>
      </div>
      {selectedProductImage && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Imagen"
          content={`¿Estás seguro que quieres eliminar la imagen?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteProductImage(selectedProductImage.id);
            }
            setSelectedProductImage(null);
          }}
        ></ConfirmModal>
      )}
      {showDeleteConfirmModal && (
        <ConfirmModal
          acceptButtonClass="delete-button"
          show={showDeleteConfirmModal}
          title="Eliminar producto"
          content={`<p>Esta acción eliminará completamente el producto del sistema así como todas las relaciones que tenga con todas las organizacioens</p><p>¿Estás seguro que quieres el producto?</p><p>Esta acción no se puede deshacer.</p>`}
          closeModal={onDeleteProduct}
        />
      )}
      {product && <UploadProductImageModal returnRawData={false} product={product} show={showUploadProductModal} closeModal={onCloseUploadProductImageModal} />}
    </React.Fragment>
  );
};

export default ProductView;
