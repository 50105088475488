import { AxiosError } from 'axios';
import clsx from 'clsx';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { api } from '../services/api';
import myToastr from '../services/toastr';

interface Props {
  show: boolean;
  closeModal: (result: boolean) => void;
}

const ChangeUserPasswordModal = (props: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });

  const close = () => {
    reset();
    props.closeModal(false);
  };

  const onSubmit = async (data: { password: string; repeatPassword: string }) => {
    try {
      const result: boolean = await api.changeUserPassword({
        password: data.password!,
      });
      props.closeModal(result);
      reset();
    } catch (e: any) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError?.response) {
        if (Array.isArray(axiosError.response.data.message)) {
          myToastr.error(axiosError.response.data.message.join('</br>'));
        } else {
          myToastr.error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form change-user-password-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Cambiar contraseña</div>
            <button type="button" className="close-button-modal" onClick={close}>
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.password ? 'error' : '')}>Nueva contraseña*</div>
              <input
                type="password"
                {...register('password', {
                  required: true,
                  minLength: { value: 8, message: 'La contraseña debe contener al menos 8 caracteres' },
                  maxLength: { value: 50, message: 'La contraseña debe contener como máximo 50 caracteres' },
                })}
                className={clsx({ error: errors?.password })}
                placeholder="Nueva contraseña"
              />
              {errors.password && <div className="error-message">{errors.password.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.repeatPassword ? 'error' : '')}>Repite contraseña*</div>
              <input
                type="password"
                {...register('repeatPassword', {
                  required: true,
                  validate: (value: string) => {
                    return watch('password') === value || 'La contraseña no coincide';
                  },
                })}
                className={clsx({ error: errors?.repeatPassword })}
                placeholder="Repite contraseña"
              />
              {errors.repeatPassword && <div className="error-message">{errors.repeatPassword.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default ChangeUserPasswordModal;
