import 'bootstrap/scss/bootstrap.scss';
import 'lightgallery.js/dist/css/lightgallery.css';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { LightgalleryProvider } from 'react-lightgallery';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Provider } from 'react-redux';
import 'toastr/build/toastr.min.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import './services/font-awesome-library';
import { store } from './store/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LightgalleryProvider>
        <App />
      </LightgalleryProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
