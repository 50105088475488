import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Role } from '../enums/role';
import { Brand } from '../interfaces/brand';
import { Collection } from '../interfaces/collection';
import { GetStatsMostSelledProducts } from '../interfaces/get-stats-most-selled-products';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { StatsMostSelledProduct } from '../interfaces/stats-most-selled-product';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import Table from './table';

interface Props {
  storeId: number;
  startDate: Date | null;
  endDate: Date | null;
}

const LIMIT = 50;

const DashboardMostSelledProducts = ({ storeId, startDate, endDate }: Props) => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [statsMostSelledProduct, setStatsMostSelledProduct] = useState<StatsMostSelledProduct[]>([]);
  const [getStatsMostSelledProducts, setGetStatsMostSelledProducts] = useState<GetStatsMostSelledProducts | null>(null);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [collections, setCollections] = useState<Collection[]>([]);
  const columnsMostSelledProducts: any[] = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Producto',
        accessor: 'productName',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const ssbp: StatsMostSelledProduct = row.original;
          return (
            <Link to={`/producto/${ssbp.productId}`} className="link-sale">
              {ssbp.productName}
            </Link>
          );
        },
      },
      {
        Header: 'Marca',
        accessor: 'brandName',
        disableFilters: true,
      },
      {
        Header: 'SKU',
        accessor: 'productSku',
        disableFilters: true,
      },
      {
        Header: 'Unidades',
        accessor: 'units',
        Cell: ({ value }: any) => {
          return formatNumber(value, true);
        },
        disableFilters: true,
      },
      {
        Header: 'PVP',
        accessor: 'pvp',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      columnsData.splice(3, 0, {
        Header: 'Precio Coste',
        accessor: 'costPrice',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      });
      columnsData.splice(5, 0, {
        Header: 'Beneficio Neto',
        accessor: 'netProfit',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      });
      columnsData.splice(6, 0, {
        Header: 'Margen',
        accessor: 'margin',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}%`;
        },
        disableFilters: true,
      });
    }
    return columnsData;
  }, [user]);
  const filteredCollections: Collection[] = useMemo(() => {
    if (!getStatsMostSelledProducts) {
      return collections;
    }
    if (!getStatsMostSelledProducts.brandId) {
      return collections;
    }
    return collections.filter((collection: Collection) => collection.brandId === getStatsMostSelledProducts.brandId);
  }, [getStatsMostSelledProducts, collections]);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const result: Brand[] = await api.getBrands(organization.id);
        setBrands(result);
      } catch (e) {}
    };
    const getCollections = async () => {
      try {
        const result: Collection[] = await api.getCollections(organization.id);
        setCollections(result);
      } catch (e) {}
    };
    getBrands();
    getCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGetStatsMostSelledProducts({
      ...getStatsMostSelledProducts!,
      organizationId: organization.id,
      startDate,
      endDate,
      storeId,
      limit: LIMIT,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, startDate, endDate]);

  useEffect(() => {
    if (!getStatsMostSelledProducts) {
      return;
    }
    _getStatsMostSelledProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStatsMostSelledProducts]);

  const _getStatsMostSelledProducts = async () => {
    try {
      setRequesting(true);
      const result: StatsMostSelledProduct[] = await api.getStatsMostSelledProducts(getStatsMostSelledProducts!);
      setStatsMostSelledProduct(result);
      setRequesting(false);
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = statsMostSelledProduct.map((statsMostSelledProduct: StatsMostSelledProduct) => ({
      Producto: statsMostSelledProduct.productName,
      Marca: statsMostSelledProduct.brandName,
      SKU: statsMostSelledProduct.productSku,
      Unidades: statsMostSelledProduct.units,
      'Precio Coste': statsMostSelledProduct.costPrice,
      PVP: statsMostSelledProduct.pvp,
      'Beneficio Neto': statsMostSelledProduct.netProfit,
      Margen: statsMostSelledProduct.margin,
    }));
    setCsvData(data);
  };

  if (!statsMostSelledProduct) {
    return null;
  }

  return (
    <div className="dashboard-most-selled-products">
      <div className="row mb-4">
        <div className="col-3">
          <div className="d-flex flex-column">
            <label>Marca</label>
            <select
              name="brandId"
              id="brandId"
              value={getStatsMostSelledProducts?.brandId || ''}
              onChange={(e) => {
                const brandId: number | null = e.target.value ? parseInt(e.target.value) : null;
                setGetStatsMostSelledProducts({ ...getStatsMostSelledProducts!, brandId });
              }}
            >
              <option value="">Selecciona una opción</option>
              {brands.map((brand: Brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-3">
          <div className="d-flex flex-column">
            <label>Colección</label>
            <select
              name="collectionId"
              id="collectionId"
              value={getStatsMostSelledProducts?.collectionId || ''}
              onChange={(e) => {
                const collectionId: number | null = e.target.value ? parseInt(e.target.value) : null;
                setGetStatsMostSelledProducts({ ...getStatsMostSelledProducts!, collectionId });
              }}
            >
              <option value="">Selecciona una opción</option>
              {filteredCollections.map((collection: Collection) => (
                <option key={collection.id} value={collection.id}>
                  {collection.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="dashboard-tab active mb-4">
        <div className="d-flex flex-row align-items-center mb-2">
          <h4 className="mb-0 flex-grow-1">Productos más vendidos</h4>
          <CSVLink filename="mas_vendidos.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        </div>
        {requesting ? (
          <div className="loader">
            <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
          </div>
        ) : (
          <Table
            columns={columnsMostSelledProducts}
            data={statsMostSelledProduct}
            noDataMessage={'No productos.'}
            initialState={{
              pageSize: LIMIT,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardMostSelledProducts;
