import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Select from 'react-select';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { WorkShift } from '../interfaces/work-shift';
import { WorkShiftDto } from '../interfaces/work-shift.dto';
import { WorkShiftsDto } from '../interfaces/work-shifts.dto';
import { api } from '../services/api';
import gregorian_es from '../services/gregorian_en';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  selectedUserDay: {
    userId: number;
    date: string;
  } | null;
  workShift: WorkShift | null;
  show: boolean;
  closeModal: (workShifts: WorkShift[]) => void;
}

interface CustomWorkShiftDto {
  users: { label: string; value: number }[];
  storeId: number | null;
  dates: string[];
  clockIn: string | null;
  clockOut: string | null;
  holiday: boolean;
  dayOff: boolean;
  notes: string;
}

const defaultValues: CustomWorkShiftDto = {
  users: [],
  storeId: -1,
  dates: [],
  clockIn: '',
  clockOut: '',
  holiday: false,
  dayOff: false,
  notes: '',
};

const WorkShiftsModal = ({ selectedUserDay, workShift, show, closeModal }: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [organizationStores, setOrganizationStores] = useState<Store[]>([]);
  const [organizationUsers, setOrganizationUsers] = useState<User[]>([]);
  const [storeUsers, setStoreUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<CustomWorkShiftDto>({
    mode: 'onSubmit',
    defaultValues,
  });
  const availableStores: Store[] = useMemo(() => {
    if (!workShift) {
      return organizationStores;
    }
    if (!user) {
      return [];
    }
    return user.stores;
  }, [workShift, organizationStores, user]);

  useEffect(() => {
    if (!workShift) {
      return;
    }
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workShift]);

  useEffect(() => {
    if (!show) {
      return;
    }
    getOrganizationStores();
    getOrganizationUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (show) {
      return;
    }
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!workShift || organizationStores.length === 0) {
      return;
    }
    setValue('storeId', workShift.storeId);
    setValue('users', [{ label: `${workShift.user.name} ${workShift.user.surnames}`, value: workShift.user.id }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationStores, workShift]);

  useEffect(() => {
    if (show && workShift) {
      reset({
        users: [{ label: `${workShift.user.name} ${workShift.user.surnames}`, value: workShift.user.id }],
        storeId: workShift.storeId,
        dates: [workShift.date],
        clockIn: workShift.clockIn ? workShift.clockIn.substring(0, 5) : '',
        clockOut: workShift.clockOut ? workShift.clockOut.substring(0, 5) : '',
        holiday: workShift.holiday,
        dayOff: workShift.dayOff,
        notes: workShift.notes,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, workShift]);

  useEffect(() => {
    if (!show || !selectedUserDay?.date) {
      return;
    }
    setValue('dates', [selectedUserDay?.date]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, selectedUserDay?.date]);

  useEffect(() => {
    const storeId: number | null = watch('storeId');
    if (storeId === null || storeId <= 0) {
      setStoreUsers([]);
      return;
    }
    getStoreUsers(storeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('storeId')]);

  useEffect(() => {
    if (!show) {
      return;
    }
    if (watch('holiday') || watch('dayOff')) {
      return;
    }
    setValue('storeId', -1);
    if (!workShift) {
      setValue('users', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, watch('holiday'), watch('dayOff'), workShift]);

  const close = () => {
    closeModal([]);
  };

  const getUser = async () => {
    try {
      const user: User = await api.getUser(workShift!.userId);
      setUser(user);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getOrganizationUsers = async () => {
    try {
      const users: User[] = await api.getOrganizationUsers(organization!.id);
      setOrganizationUsers(users);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getOrganizationStores = async () => {
    try {
      const stores: Store[] = await api.getOrganizationStores(organization!.id);
      setOrganizationStores(stores);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getStoreUsers = async (storeId: number) => {
    try {
      const users: User[] = await api.getStoreUsers(storeId);
      if (selectedUserDay?.userId) {
        const user: User | undefined = users.find((u: User) => u.id === selectedUserDay?.userId);
        if (!user) {
          myToastr.error('El usuario seleccionado anteriormente no pertenece a la tienda seleccionada');
        } else {
          setValue('users', [{ label: `${user.name} ${user.surnames}`, value: user.id }]);
        }
      }
      setStoreUsers(users);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const onSubmit = async (customWorkShiftDto: CustomWorkShiftDto) => {
    if (workShift) {
      try {
        const workShiftDto: WorkShiftDto = {
          userId: workShift.userId,
          organizationId: organization!.id,
          storeId: customWorkShiftDto.storeId,
          date: customWorkShiftDto.dates[0],
          clockIn: customWorkShiftDto.clockIn,
          clockOut: customWorkShiftDto.clockOut,
          holiday: customWorkShiftDto.holiday,
          dayOff: customWorkShiftDto.dayOff,
          notes: customWorkShiftDto.notes,
        };
        const updatedWorkShift: WorkShift = await api.updateWorkShift(workShift.id, workShiftDto);
        myToastr.success('Turno actualizado correctamente');
        closeModal([updatedWorkShift]);
      } catch (e: any) {
        const axiosError: AxiosError<HttpExceptionDto> = e;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(httpExceptionDto.message);
        }
      }
    } else {
      try {
        const workShiftsDto: WorkShiftsDto = {
          workShifts: [],
        };
        customWorkShiftDto.dates.forEach((date: string) => {
          customWorkShiftDto.users.forEach((user: { label: string; value: number }) => {
            const workShiftDto: WorkShiftDto = {
              userId: user.value,
              organizationId: organization!.id,
              storeId: customWorkShiftDto.storeId,
              date: moment(date).format('YYYY-MM-DD'),
              clockIn: customWorkShiftDto.clockIn,
              clockOut: customWorkShiftDto.clockOut,
              holiday: customWorkShiftDto.holiday,
              dayOff: customWorkShiftDto.dayOff,
              notes: customWorkShiftDto.notes,
            };
            workShiftsDto.workShifts.push(workShiftDto);
          });
        });
        const newWorkShifts: WorkShift[] = await api.createWorkShifts(workShiftsDto);
        myToastr.success(newWorkShifts.length === 1 ? 'Turno creado corectamente' : 'Turnos creados correctamente');
        closeModal(newWorkShifts);
      } catch (e: any) {
        const axiosError: AxiosError<HttpExceptionDto> = e;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(httpExceptionDto.message);
        }
      }
    }
  };

  const deleteWorkShift = async () => {
    try {
      const deletedWorkShift: WorkShift = await api.deleteWorkShift(workShift?.id as number);
      myToastr.info('Turno eliminado correctamente');
      closeModal([deletedWorkShift]);
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form work-shifts-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="mb-3 text-center position-relative">
            <div className="title">{workShift ? 'Modificar turno' : 'Añadir turnos'}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar" style={{ position: 'absolute', right: 0, top: 0 }}>
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.dates })}>Fecha *</div>
              <Controller
                control={control}
                name="dates"
                rules={{ required: true, minLength: 1 }}
                render={({ field: { onChange, value }, fieldState }) => {
                  return (
                    <DatePicker
                      multiple={!workShift ? true : false}
                      value={!workShift ? value : [value[0]]}
                      locale={gregorian_es}
                      weekStartDayIndex={1}
                      onChange={(e: DateObject[]) => {
                        const dates: string[] = e.map((dateObject) => dateObject.format('YYYY-MM-DD'));
                        if (!workShift) {
                          onChange(dates);
                        } else {
                          if (dates.length === 2) {
                            onChange([dates[1]]);
                          } else {
                            onChange(dates);
                          }
                        }
                      }}
                      render={(v: string, openCalendar: () => void) => {
                        let formattedValue = '';
                        if (v.includes(',')) {
                          formattedValue = v
                            .split(', ')
                            .map((date) => moment(date).format('DD/MM/YYYY'))
                            .join(', ');
                        } else if (v) {
                          formattedValue = moment(v).format('DD/MM/YYYY');
                        }
                        return (
                          <input
                            type="text"
                            readOnly
                            onClick={openCalendar}
                            value={formattedValue}
                            placeholder="Selecciona al menos una fecha"
                            style={{ cursor: 'pointer', width: '100%' }}
                            className={clsx({ error: fieldState.error })}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="d-flex flex-row align-items-center container-checkbox">
                    <input
                      type="checkbox"
                      {...register('holiday')}
                      className="me-2"
                      onChange={(e) => {
                        setValue('holiday', e.target.checked);
                        if (e.target.checked) {
                          setValue('dayOff', false);
                          if (workShift && selectedUserDay?.userId) {
                            const u: User = organizationUsers.find((u) => u.id === selectedUserDay!.userId)!;
                            setValue('users', [{ label: `${u.name} ${u.surnames}`, value: u.id }]);
                          }
                          setValue('storeId', null);
                          setValue('clockIn', '');
                          setValue('clockOut', '');
                        }
                      }}
                    />
                    <label className="">Vacaciones</label>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-row align-items-center container-checkbox">
                    <input
                      type="checkbox"
                      {...register('dayOff')}
                      className="me-2"
                      onChange={(e) => {
                        setValue('dayOff', e.target.checked);
                        if (e.target.checked) {
                          setValue('holiday', false);
                          if (workShift && selectedUserDay?.userId) {
                            const u: User = organizationUsers.find((u) => u.id === selectedUserDay!.userId)!;
                            setValue('users', [{ label: `${u.name} ${u.surnames}`, value: u.id }]);
                          }
                          setValue('storeId', null);
                          setValue('clockIn', '');
                          setValue('clockOut', '');
                        }
                      }}
                    />
                    <label className="">Día libre</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!watch('holiday') && !watch('dayOff') && (
            <React.Fragment>
              <div className="row my-3">
                <div className="col">
                  <div className={clsx('input-name', { error: errors?.clockIn })}>Hora inicio</div>
                  <input
                    type="time"
                    {...register('clockIn', { required: watch('holiday') || watch('dayOff') ? false : true })}
                    className={clsx({ error: errors?.clockIn, 'cursor-not-allowed': watch('holiday') || watch('dayOff') })}
                    disabled={watch('holiday') || watch('dayOff')}
                  />
                  {errors.clockIn && <div className="error-message">{errors.clockIn.message}</div>}
                </div>
                <div className="col">
                  <div className={clsx('input-name', { error: errors?.clockOut })}>Hora fin</div>
                  <input
                    type="time"
                    {...register('clockOut', { required: watch('holiday') || watch('dayOff') ? false : true })}
                    className={clsx({ error: errors?.clockOut, 'cursor-not-allowed': watch('holiday') || watch('dayOff') })}
                    disabled={watch('holiday') || watch('dayOff')}
                  />
                  {errors.clockOut && <div className="error-message">{errors.clockOut.message}</div>}
                </div>
              </div>
              <div className="row my-3">
                <div className="col">
                  <div className={clsx('input-name', { error: errors?.storeId })}>Tienda *</div>
                  <select {...register('storeId', { required: true, min: 1, valueAsNumber: true })} className={clsx({ error: errors?.storeId })}>
                    <option value={-1}>Selecciona una tienda</option>
                    {availableStores.map((store: Store) => (
                      <option key={store.id} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                  </select>
                  {errors.storeId && <div className="error-message">{errors.storeId.message}</div>}
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.users })}>{workShift ? 'Empleado' : 'Empleados'}</div>
              <Controller
                control={control}
                name="users"
                rules={{ required: true, minLength: 1 }}
                render={({ field: { onChange, value }, fieldState }) => {
                  return (
                    <div style={{ cursor: workShift ? 'not-allowed' : watch('storeId') === -1 ? 'not-allowed' : 'default' }}>
                      <Select
                        isDisabled={workShift ? true : !watch('holiday') && !watch('dayOff') && (watch('storeId') === null || watch('storeId') === -1)}
                        value={value}
                        onChange={(e: any) => onChange(e)}
                        isMulti
                        options={
                          watch('holiday') || watch('dayOff')
                            ? organizationUsers.map((user: User) => ({ value: user.id, label: `${user.name} ${user.surnames}` }))
                            : storeUsers.map((user: User) => ({ value: user.id, label: `${user.name} ${user.surnames}` }))
                        }
                        isClearable={true}
                        placeholder={watch('storeId') === -1 ? 'Selecciona una tienda' : 'Selecciona uno a varios empleados'}
                        styles={{
                          control: (provided: any, state: any) => ({
                            ...provided,
                            backgroundColor: 'transparent',
                            border: fieldState.error ? '1px solid #e02760' : '1px solid white',
                            boxShadow: state.isFocused ? 'none' : provided.boxShadow,

                            borderRadius: '10px',
                            cursor: state.isDisabled ? 'not-allowed' : 'pointer',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: fieldState.error ? '#e02760' : 'white',
                            fontSize: '14px',
                            paddingLeft: '14px',
                          }),
                          indicatorSeparator: () => ({}),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: fieldState.error ? '#e02760' : 'white',
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? 'blue' : 'white',
                            color: state.isSelected ? 'white' : 'black',
                          }),
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name')}>Comentarios</div>
              <textarea cols={30} rows={10} {...register('notes')} />
            </div>
          </div>
        </div>
        {workShift ? (
          <div className="d-flex flex-row">
            <button className={`delete-button`} type="button" onClick={deleteWorkShift}>
              Eliminar
            </button>
            <button className={`modify-button`} type="submit">
              Modificar
            </button>
          </div>
        ) : (
          <button className={`save-button`} type="submit">
            {workShift ? 'Guardar' : 'Añadir'}
          </button>
        )}
      </form>
    </Modal>
  );
};

export default WorkShiftsModal;
