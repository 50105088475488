import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, Save, Trash2, XCircle } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import NoteModal from '../components/note-modal';
import PinModal from '../components/pin-modal';
import { PinDto } from '../components/pin.dto';
import UserWorkClockingHistoryDayModal from '../components/user-work-clocking-history-day-modal';
import UserWorkClockingModal from '../components/user-work-clocking-modal';
import { LocalEvents } from '../enums/local-events';
import { Role } from '../enums/role';
import { DeleteUserWorkClockingDto } from '../interfaces/delete-user-work-clocking.dto';
import { GetUserTimeSheet } from '../interfaces/get-user-time-sheets-query';
import { GetUserWorkClockingsQuery } from '../interfaces/get-user-work-clockings-query';
import { GetWorkShiftsQuery } from '../interfaces/get-work-shifts-query';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { UserInOrganization } from '../interfaces/user-in-organization';
import { UserTimeSheet } from '../interfaces/user-time-sheet';
import { UserWorkClocking } from '../interfaces/user-work-clocking';
import { UserWorkClockingHistory } from '../interfaces/user-work-clocking-history';
import { UserWorkClockingDto } from '../interfaces/user-work-clocking.dto';
import { WorkShift } from '../interfaces/work-shift';
import { api } from '../services/api';
import eventEmitter from '../services/events';
import { minutesToHourAndMinutesStr, ucFirst } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

moment.locale('es');

interface NewUWC {
  clockIn: string;
  clockOut: string;
  storeId: number | '';
  notes: string | null;
  valid: boolean;
  error: boolean;
}

const UserWorkClockingsView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const userStore: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const selectedStoreId: number = useAppSelector((state: RootState) => state.store.selectedStoreId);
  const [user, setUser] = useState<User | null>(null);
  const [userTimeSheet, setUserTimeSheet] = useState<UserTimeSheet | null>(null);
  const [workShifts, setWorkShifts] = useState<WorkShift[]>([]);
  const [userWorkClockings, setUserWorkClockings] = useState<UserWorkClocking[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(moment().format('YYYY-MM-01'));
  const [mapNewUserWorkClocking, setMapNewUserWorkClocking] = useState<{ [key: string]: NewUWC }>({});
  const [selectedUserWorkClocking, setSelectedUserWorkClocking] = useState<UserWorkClocking | null>(null);
  const [selectedYearMonthDay, setSelectedYearMonthDay] = useState<string | null>(null);
  const [mapExistingUserWorkClocking, setMapExistingUserWorkClocking] = useState<{ [id: number]: UserWorkClocking }>({});
  const [showPinModal, setShowPinModal] = useState<boolean>(false);
  const [showUserWorkClockingModal, setShowUserWorkClockingModal] = useState<boolean>(false);
  const [pin, setPin] = useState<string | null>(null);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [selectedDayMonthYear, setSelectedDayMonthYear] = useState<string>('');
  const [selectedHistory, setSelectedHistory] = useState<UserWorkClockingHistory[]>([]);
  const [organizationUsers, setOrganizationUsers] = useState<User[]>([]);
  const estimatedMinutes: number = useMemo(() => {
    return workShifts.reduce((acc: number, ws: WorkShift) => {
      const clockInMoment: moment.Moment = moment(ws.clockIn, 'HH:mm:ss', true);
      const clockOutMoment: moment.Moment = moment(ws.clockOut, 'HH:mm:ss', true);
      if (clockInMoment.isValid() || clockOutMoment.isValid()) {
        const diff: number = clockOutMoment.diff(clockInMoment, 'minutes');
        return acc + diff;
      }
      return acc;
    }, 0);
  }, [workShifts]);
  const workedMinutes: number = useMemo(() => {
    return userWorkClockings.reduce((acc: number, uwc: UserWorkClocking) => {
      if (uwc.isDeleted) {
        return acc;
      }
      const clockInMoment: moment.Moment = moment(uwc.clockIn, 'HH:mm:ss', true);
      const clockOutMoment: moment.Moment = moment(uwc.clockOut, 'HH:mm:ss', true);
      if (clockInMoment.isValid() && clockOutMoment.isValid()) {
        const diff: number = clockOutMoment.diff(clockInMoment, 'minutes');
        return acc + diff;
      } else {
        return acc;
      }
    }, 0);
  }, [userWorkClockings]);
  const diffMinutes: number = workedMinutes - estimatedMinutes;
  const optionsYearMonthSelector: string[] = useMemo(() => {
    const result: string[] = [];
    const endMoment: moment.Moment = moment('2023-01-01');
    for (let currentMoment = moment(); currentMoment.isSameOrAfter(endMoment); currentMoment.add(-1, 'month')) {
      result.push(currentMoment.format('YYYY-MM-01'));
    }
    return result;
  }, []);
  const yearsMonthsDays: string[] = useMemo(() => {
    const result: string[] = [];
    const date: moment.Moment = moment(selectedDate);
    for (let day = 1; day <= date.daysInMonth(); day++) {
      result.push(date.date(day).format('YYYY-MM-DD'));
    }
    return result;
  }, [selectedDate]);
  const stores: Store[] = useMemo(() => {
    if (!user) {
      return [];
    }
    return user.stores.filter((store: Store) => store.organizationId === organization!.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const editable: boolean = useMemo(() => {
    if (!userTimeSheet || !user || !userStore) {
      return false;
    }
    if (user.id !== userStore.id) {
      return false;
    }
    if (userTimeSheet.isApproved) {
      return false;
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTimeSheet, user, params.userId]);
  const titleCreateUpdateUswerWorkClockingButton: string = useMemo(() => {
    const result: UserWorkClocking[] = userWorkClockings
      .filter((uwc: UserWorkClocking) => uwc.date === moment().format('YYYY-MM-DD') && !uwc.isDeleted)
      .sort((uwcA: UserWorkClocking, uwcB: UserWorkClocking) => moment(uwcA.clockIn, 'HH:mm:ss', true).diff(moment(uwcB.clockIn, 'HH:mm:ss', true)));
    if (result.length === 0) {
      return 'Fichar entrada';
    }
    return result.some((uwc: UserWorkClocking) => uwc.clockOut === null) ? 'Fichar salida' : 'Fichar entrada';
  }, [userWorkClockings]);

  const getUserWorkClockings = useCallback(async () => {
    try {
      const getUserWorkClockingsQuery: GetUserWorkClockingsQuery = {
        startDate: null,
        endDate: null,
        userTimeSheetId: userTimeSheet!.id,
        userId: user!.id,
      };
      const userWorkClockings: UserWorkClocking[] = await api.getUserWorkClockings(getUserWorkClockingsQuery);
      const map: { [id: number]: UserWorkClocking } = {};
      userWorkClockings.forEach((userWorkClocking: UserWorkClocking) => {
        map[userWorkClocking.id] = { ...userWorkClocking };
        userWorkClocking.history.sort((a: UserWorkClockingHistory, b: UserWorkClockingHistory) => moment(a.createdAt).diff(moment(b.createdAt)));
      });
      setMapExistingUserWorkClocking(map);
      setUserWorkClockings(userWorkClockings);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  }, [user, userTimeSheet]);

  useEffect(() => {
    eventEmitter.on(LocalEvents.NewUserWorkClocking, async () => {
      await getUserWorkClockings();
    });
    eventEmitter.on(LocalEvents.UpdatedUserWorkClocking, async () => {
      await getUserWorkClockings();
    });
    return () => {
      eventEmitter.off(LocalEvents.NewUserWorkClocking);
      eventEmitter.off(LocalEvents.UpdatedUserWorkClocking);
    };
  }, [getUserWorkClockings]);

  useEffect(() => {
    if (userStore.role === Role.Seller || userStore.role === Role.Business) {
      return;
    }
    getOrganizationUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore, organization]);

  useEffect(() => {
    if (!params.userId) {
      setShowPinModal(true);
      return;
    }
    const userId: number = parseInt(params.userId as any, 10);
    if (isNaN(userId)) {
      navigate(-1);
      return;
    }
    if (userStore.role === Role.Seller) {
      if (userId !== userStore.id) {
        navigate(-1);
        return;
      }
      setUser({ ...userStore });
      return;
    }
    getUserById(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.userId]);

  useEffect(() => {
    getUserTimeSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    if (!userTimeSheet || !user) {
      return;
    }
    getWorkShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTimeSheet, user]);

  useEffect(() => {
    if (!userTimeSheet || !user) {
      return;
    }
    getUserWorkClockings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTimeSheet, user]);

  const getOrganizationUsers = async () => {
    try {
      const orgUsers: User[] = await api.getOrganizationUsers(organization!.id);
      const filteredOrganizationUsers: User[] = orgUsers
        .filter((u: User) => {
          if (userStore.role !== Role.SuperAdmin && u.role === Role.SuperAdmin) {
            return false;
          }
          return true;
        })
        .sort((a: User, b: User) => {
          const partsA: string[] = [];
          if (a.name) {
            partsA.push(a.name.trim());
          }
          if (a.surnames) {
            partsA.push(a.surnames.trim());
          }
          const partsB: string[] = [];
          if (b.name) {
            partsB.push(b.name.trim());
          }
          if (b.surnames) {
            partsB.push(b.surnames.trim());
          }
          const nameA: string = partsA.join(' ');
          const nameB: string = partsB.join(' ');
          return nameA.localeCompare(nameB);
        });
      setOrganizationUsers(filteredOrganizationUsers);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const onClosePinModal = async (pin: string | null) => {
    if (!pin) {
      setShowPinModal(false);
      navigate(-1);
      return;
    }
    try {
      const pinDto: PinDto = {
        organizationId: organization!.id,
        pin,
      };
      const u: User = await api.getUserByPin(pinDto);
      const index: number = u.userInOrganizations.findIndex((userInOrganization: UserInOrganization) => userInOrganization.organizationId === organization.id);
      if (index === -1) {
        myToastr.error('El usuario no pertenece a esta organización');
        navigate(-1);
        return;
      }
      setUser(u);
      setShowPinModal(false);
      setPin(pin);
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getUserById = async (userId: number) => {
    try {
      const u: User = await api.getUser(userId);
      setUser(u);
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      navigate(-1);
    }
  };

  const getUserTimeSheet = async () => {
    try {
      const dateMoment: moment.Moment = moment(selectedDate);
      const getUserTimeSheet: GetUserTimeSheet = {
        organizationId: organization!.id,
        year: dateMoment.year(),
        month: dateMoment.month() + 1,
      };
      const userTimeSheet: UserTimeSheet = await api.getUserTimeSheet(getUserTimeSheet);
      setUserTimeSheet(userTimeSheet);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getWorkShifts = async () => {
    try {
      const dateMoment: moment.Moment = moment(`${userTimeSheet!.year}-${userTimeSheet!.month < 10 ? `0${userTimeSheet!.month}` : `${userTimeSheet!.month}`}-01`);
      const getWorkShiftsQuery: GetWorkShiftsQuery = {
        startDate: dateMoment.toDate(),
        endDate: dateMoment.endOf('month').startOf('day').toDate(),
        organizationId: organization!.id,
        userId: user!.id,
      };
      const workShifts: WorkShift[] = await api.getWorkShifts(getWorkShiftsQuery);
      setWorkShifts(workShifts);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const deleteUserWorkClocking = async (userWorkClockingId: number) => {
    try {
      const deleteUserWorkClockingDto: DeleteUserWorkClockingDto = {
        organizationId: organization!.id,
        pin: pin!,
      };
      const updatedUserWorkClocking: UserWorkClocking = await api.deleteUserWorkClocking(userWorkClockingId, deleteUserWorkClockingDto);
      const index: number = userWorkClockings.findIndex((userWorkClocking: UserWorkClocking) => userWorkClocking.id === userWorkClockingId);
      if (index !== -1) {
        const newUserWorkClockings: UserWorkClocking[] = [...userWorkClockings];
        newUserWorkClockings[index] = updatedUserWorkClocking;
        setUserWorkClockings(newUserWorkClockings);
        myToastr.success('Fichaje eliminado correctamente');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const createUserWorkClocking = async (yearMonthDay: string) => {
    const d: NewUWC = mapNewUserWorkClocking[yearMonthDay];
    if (!d.clockIn || !moment(d.clockIn, 'HH:mm:ss', true).isValid()) {
      myToastr.error('La hora de entrada no es válida');
      return;
    }
    if (d.clockOut && moment(d.clockIn, 'HH:mm:ss', true).isSameOrAfter(moment(d.clockOut, 'HH:mm:ss', true))) {
      myToastr.error('La hora de entrada debe ser anterior a la hora de salida');
      return;
    }
    if ((user!.role === Role.Seller || user!.role === Role.Business) && !d.storeId) {
      myToastr.error('Debe seleccionar una tienda');
      return;
    }
    try {
      const userWorkClockingDto: UserWorkClockingDto = {
        clockIn: d.clockIn,
        clockOut: d.clockOut,
        date: yearMonthDay,
        notes: d.notes,
        storeId: d.storeId !== '' ? d.storeId : null,
        userId: user!.id,
        userTimeSheetId: userTimeSheet!.id,
      };
      const userWorkClocking: UserWorkClocking = await api.createUserWorkClocking(userWorkClockingDto);
      await getUserWorkClockings();
      const copyUserWorkClocking: { [key: string]: NewUWC } = { ...mapNewUserWorkClocking };
      delete copyUserWorkClocking[userWorkClocking.date];
      setMapNewUserWorkClocking(copyUserWorkClocking);
      myToastr.success('Fichaje creado correctamente');
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const updateUserWorkClocking = async (userWorkClockingId: number, userWorkClockingDto: UserWorkClockingDto) => {
    try {
      await api.updateUserWorkClocking(userWorkClockingId, userWorkClockingDto);
      await getUserWorkClockings();
      myToastr.success('Fichaje actualizado correctamente');
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const updateUserTimeSheet = async (isApproved: boolean) => {
    try {
      const updatedUserTimeSheet: UserTimeSheet = await api.updateUserTimeSheet(userTimeSheet!.id, { isApproved });
      setUserTimeSheet(updatedUserTimeSheet);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const createAutomaticUserWorkClocking = async () => {
    // Comprobar si hay más de un fichaje para hoy sin hora de salida
    const numOpened: number = userWorkClockings.filter((uwc: UserWorkClocking) => uwc.date === moment().format('YYYY-MM-DD') && !uwc.isDeleted && !uwc.clockOut).length;
    if (numOpened > 1) {
      myToastr.error('No se puede crear un fichaje automático porque hay más de un fichaje en el día de hoy sin hora de salida');
      return;
    }
    // Comprobar si hay fichajes cerrados para hoy cuya hora de salida sea posterior a la hora actual
    const numClosed: number = userWorkClockings.filter(
      (uwc: UserWorkClocking) => uwc.date === moment().format('YYYY-MM-DD') && !uwc.isDeleted && uwc.clockOut && moment(uwc.clockOut, 'HH:mm:ss', true).isAfter(moment()),
    ).length;
    if (numClosed > 0) {
      myToastr.error('No se puede crear un fichaje automático porque hay fichajes cerrados en el día de hoy cuya hora de entrada es anterior a la hora actual');
      return;
    }
    if (user!.role === Role.Seller || user!.role === Role.Business) {
      if (selectedStoreId === -1) {
        myToastr.error('Debe seleccionar una tienda');
        return;
      }
      await createUpdateUserWorkClocking(selectedStoreId);
      return;
    }
    setShowUserWorkClockingModal(true);
  };

  const onCloseUserWorkClockingModal = async (storeId: number | null) => {
    if (storeId === null) {
      setShowUserWorkClockingModal(false);
      return;
    }
    await createUpdateUserWorkClocking(storeId);
    setShowUserWorkClockingModal(false);
  };

  const createUpdateUserWorkClocking = async (storeId: number) => {
    try {
      const numToday: number = userWorkClockings.filter((uwc: UserWorkClocking) => uwc.date === moment().format('YYYY-MM-DD') && !uwc.isDeleted).length;
      const numOpened: number = userWorkClockings.filter((uwc: UserWorkClocking) => uwc.date === moment().format('YYYY-MM-DD') && !uwc.isDeleted && !uwc.clockOut).length;
      if (numToday === 0 || numOpened === 0) {
        // No hay un fichaje creado para hoy o todos los fichajes tienen hora de salida
        const userWorkClockingDto: UserWorkClockingDto = {
          storeId: storeId > 0 ? storeId : null,
          userId: user!.id,
          userTimeSheetId: userTimeSheet!.id,
          date: moment().format('YYYY-MM-DD'),
          clockIn: moment().format('HH:mm:ss'),
          clockOut: null,
          notes: null,
        };
        await api.createUserWorkClocking(userWorkClockingDto);
        await getUserWorkClockings();
        myToastr.success('Fichaje creado correctamente');
        return;
      }
      // Obtener el primer fichaje sin hora de salida
      const currentUserWorkClocking: UserWorkClocking = userWorkClockings.find((uwc: UserWorkClocking) => uwc.date === moment().format('YYYY-MM-DD') && !uwc.isDeleted && !uwc.clockOut)!;
      const userWorkClockingDto: UserWorkClockingDto = {
        ...currentUserWorkClocking,
        clockIn: currentUserWorkClocking.clockIn,
        clockOut: moment().format('HH:mm:ss'),
      };
      await api.updateUserWorkClocking(currentUserWorkClocking.id, userWorkClockingDto);
      await getUserWorkClockings();
      myToastr.success('Fichaje actualizado correctamente');
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const onCloseHistoryModal = () => {
    setSelectedDayMonthYear('');
    setSelectedHistory([]);
    setShowHistoryModal(false);
  };

  const onSelectedUser = async (userId: number) => {
    try {
      const u: User = await api.getUser(userId);
      setUser(u);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  return (
    <div className="user-work-clockings-view d-flex flex-column mx-4 my-4">
      {user && (
        <React.Fragment>
          <div className="container-header d-flex flex-row align-items-center">
            <div className="d-flex flex-row align-items-center flex-grow-1">
              <h1 className="mb-0">Fichaje</h1>
              <h2 className="mb-0">
                {user.name} {user.surnames}
              </h2>
              <select
                disabled={userStore?.role === Role.Seller}
                value={userTimeSheet?.isApproved ? 1 : 0}
                className={clsx('user-time-sheet-status', { completed: userTimeSheet?.isApproved })}
                onChange={(e) => updateUserTimeSheet(e.target.value === '1')}
              >
                <option value={0}>EN PROCESO</option>
                <option value={1}>COMPLETADO</option>
              </select>
            </div>
            {(userStore.role === Role.SuperAdmin || userStore.role === Role.Admin || userStore.role === Role.Manager) && (
              <select className="me-3" value={user.id} onChange={(e: any) => onSelectedUser(e.target.value)}>
                {organizationUsers.map((u: User) => (
                  <option key={u.id} value={u.id}>
                    {u.name} {u.surnames}
                  </option>
                ))}
              </select>
            )}
            <button className="create-button me-3" onClick={createAutomaticUserWorkClocking}>
              {titleCreateUpdateUswerWorkClockingButton}
            </button>
            <div className="d-flex flex-row align-items-center container-year-month">
              <Calendar className="mx-1" color="black" size={18} />
              <select name="year-month" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                {optionsYearMonthSelector.map((date: string) => (
                  <option key={date} value={date}>
                    {ucFirst(moment(date, 'YYYY-MM').format('MMMM YYYY'))}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="container-hours d-flex flex-row align-items-center my-4">
            <div>
              <label>Horas estimadas:</label>
              <span>{minutesToHourAndMinutesStr(estimatedMinutes)}</span>
            </div>
            <div>
              <label>Horas trabajadas:</label>
              <span>{minutesToHourAndMinutesStr(workedMinutes)}</span>
            </div>
            <div>
              <label>Tiempo:</label>
              <span>{minutesToHourAndMinutesStr(diffMinutes)}</span>
            </div>
          </div>
          <div className="container-table">
            <table>
              <thead>
                <tr>
                  <th>Día</th>
                  <th>Fichajes</th>
                  <th>Tienda</th>
                  <th>Horas trabajadas y estimadas</th>
                  <th>Notas</th>
                </tr>
              </thead>
              <tbody>
                {yearsMonthsDays.map((yearMonthDay: string) => {
                  const dayMoment: moment.Moment = moment(yearMonthDay);
                  const wss: WorkShift[] = workShifts.filter((ws: WorkShift) => ws.date === yearMonthDay);
                  const hasDayOff: boolean = wss.some((ws: WorkShift) => ws.dayOff);
                  const hasHoliday: boolean = wss.some((ws: WorkShift) => ws.holiday);
                  const isWorkable: boolean = !hasDayOff && !hasHoliday;
                  const estimatedMinutesYearMonthDay: number = wss.reduce((acc: number, ws: WorkShift) => {
                    const clockInMoment: moment.Moment = moment(ws.clockIn, 'HH:mm:ss', true);
                    const clockOutMoment: moment.Moment = moment(ws.clockOut, 'HH:mm:ss', true);
                    if (clockInMoment.isValid() && clockOutMoment.isValid()) {
                      const diff: number = clockOutMoment.diff(clockInMoment, 'minutes');
                      return acc + diff;
                    }
                    return acc;
                  }, 0);
                  const uwcs: UserWorkClocking[] = userWorkClockings.filter((uwc: UserWorkClocking) => uwc.date === yearMonthDay);
                  const workedMinutesYearMonthDay: number = uwcs.reduce((acc: number, uwc: UserWorkClocking) => {
                    if (uwc.isDeleted) {
                      return acc;
                    }
                    const clockInMoment: moment.Moment = moment(uwc.clockIn, 'HH:mm:ss', true);
                    const clockOutMoment: moment.Moment = moment(uwc.clockOut, 'HH:mm:ss', true);
                    if (clockInMoment.isValid() && clockOutMoment.isValid()) {
                      const diff: number = clockOutMoment.diff(clockInMoment, 'minutes');
                      return acc + diff;
                    }
                    return acc;
                  }, 0);
                  const diffMinutesYearMonthDay: number = workedMinutesYearMonthDay - estimatedMinutesYearMonthDay;
                  const isFuture: boolean = dayMoment.isAfter(moment(), 'day');
                  return (
                    <tr key={yearMonthDay} style={{ backgroundColor: wss.length > 0 ? 'white' : '#E8E8E8' }}>
                      <td>
                        <div className="d-flex flex-column align-self-start container-day-month-weekday">
                          <span className="day-month">
                            {dayMoment.format('D')} {dayMoment.format('MMMM')}
                          </span>
                          <span className="weekday">{ucFirst(dayMoment.format('dddd'))}</span>
                          {uwcs.length > 0 && (
                            <div>
                              <button
                                className="button-show-history"
                                onClick={() => {
                                  setSelectedDayMonthYear(dayMoment.format('DD/MM/YYYY'));
                                  setSelectedHistory(
                                    uwcs
                                      .map((uwc: UserWorkClocking) => uwc.history)
                                      .flat()
                                      .sort((a: UserWorkClockingHistory, b: UserWorkClockingHistory) => moment(b.createdAt).diff(moment(a.createdAt))),
                                  );
                                  setShowHistoryModal(true);
                                }}
                              >
                                Histórico de cambios
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        {uwcs.map((uwcs: UserWorkClocking) => {
                          if (uwcs.isDeleted) {
                            return null;
                          }
                          let showUpdateIcon = false;
                          if (
                            mapExistingUserWorkClocking[uwcs.id]?.clockIn &&
                            uwcs.clockIn &&
                            (mapExistingUserWorkClocking[uwcs.id].clockIn !== uwcs.clockIn ||
                              mapExistingUserWorkClocking[uwcs.id].clockOut !== uwcs.clockOut ||
                              mapExistingUserWorkClocking[uwcs.id].storeId !== uwcs.storeId) &&
                            (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || mapExistingUserWorkClocking[uwcs.id].storeId)
                          ) {
                            showUpdateIcon = true;
                          }
                          return (
                            <div key={uwcs.id} className="d-flex flex-row align-items-center container-input-times">
                              <input
                                disabled={!editable || isFuture}
                                value={mapExistingUserWorkClocking[uwcs.id]?.clockIn?.substring(0, 5) || ''}
                                className={clsx('input-times', { 'cursor-not-allowed': !editable || isFuture })}
                                type="time"
                                onChange={(e) => {
                                  const map: { [id: number]: UserWorkClocking } = { ...mapExistingUserWorkClocking };
                                  map[uwcs.id] = { ...map[uwcs.id], clockIn: e.target.value ? `${e.target.value}:00` : '' };
                                  setMapExistingUserWorkClocking(map);
                                }}
                              />
                              <span className="mx-2">-</span>
                              <input
                                disabled={!editable || isFuture}
                                value={mapExistingUserWorkClocking[uwcs.id]?.clockOut?.substring(0, 5) || ''}
                                className={clsx('input-times', { 'cursor-not-allowed': !editable || isFuture })}
                                type="time"
                                onChange={(e) => {
                                  const map: { [id: number]: UserWorkClocking } = { ...mapExistingUserWorkClocking };
                                  map[uwcs.id] = { ...map[uwcs.id], clockOut: e.target.value ? `${e.target.value}:00` : '' };
                                  setMapExistingUserWorkClocking(map);
                                }}
                              />
                              {editable && !isFuture && (
                                <React.Fragment>
                                  {showUpdateIcon ? (
                                    <React.Fragment>
                                      <div
                                        className="clickable"
                                        title="Actualizar fichaje"
                                        onClick={async () => {
                                          await updateUserWorkClocking(uwcs.id, {
                                            storeId: mapExistingUserWorkClocking[uwcs.id].storeId,
                                            userId: uwcs.userId,
                                            userTimeSheetId: uwcs.userTimeSheetId,
                                            date: uwcs.date,
                                            clockIn: mapExistingUserWorkClocking[uwcs.id].clockIn,
                                            clockOut: mapExistingUserWorkClocking[uwcs.id].clockOut ? mapExistingUserWorkClocking[uwcs.id].clockOut : null,
                                            notes: uwcs.notes,
                                          });
                                        }}
                                      >
                                        <Save className="ms-2" size={18} color="#808A95" />
                                      </div>
                                      <div
                                        className="clickable"
                                        title="Deshacer cambios"
                                        onClick={() => {
                                          const map: { [id: number]: UserWorkClocking } = { ...mapExistingUserWorkClocking };
                                          map[uwcs.id] = { ...uwcs };
                                          setMapExistingUserWorkClocking(map);
                                        }}
                                      >
                                        <XCircle className="ms-2" size={18} color="#808A95" />
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <div className="clickable" title="Eliminar fichaje" onClick={() => deleteUserWorkClocking(uwcs.id)}>
                                      <Trash2 className="ms-2" size={18} color="#808A95" />
                                    </div>
                                  )}
                                </React.Fragment>
                              )}
                              {uwcs.isModified && <span className="modified ms-2">Modificado</span>}
                            </div>
                          );
                        })}
                        {/* NUEVO REGISTRO */}
                        {editable && !isFuture && isWorkable && (
                          <div className="d-flex flex-row align-items-center container-input-times">
                            <input
                              disabled={!editable}
                              value={mapNewUserWorkClocking[yearMonthDay]?.clockIn?.substring(0, 5) || ''}
                              onChange={(e) => {
                                const copy: NewUWC = { ...mapNewUserWorkClocking[yearMonthDay] };
                                let error = true;
                                let clockIn = '';
                                if (moment(e.target.value, 'HH:mm', true).isValid()) {
                                  clockIn = `${e.target.value}:00`;
                                  error = false;
                                }
                                if ((user.role === Role.Seller || user.role === Role.Business) && !copy.storeId) {
                                  error = true;
                                }
                                setMapNewUserWorkClocking({
                                  ...mapNewUserWorkClocking,
                                  [yearMonthDay]: {
                                    ...copy,
                                    clockIn,
                                    error,
                                    valid: !error,
                                  },
                                });
                              }}
                              className={clsx('input-times', { 'invalid-uwc': mapNewUserWorkClocking[yearMonthDay]?.error, 'valid-uwc': mapNewUserWorkClocking[yearMonthDay]?.valid })}
                              type="time"
                            />
                            <span className="mx-2">-</span>
                            <input
                              disabled={!editable || isFuture}
                              value={mapNewUserWorkClocking[yearMonthDay]?.clockOut?.substring(0, 5) || ''}
                              onChange={(e) => {
                                const copy: NewUWC = { ...mapNewUserWorkClocking[yearMonthDay] };
                                let error = true;
                                let clockOut = '';
                                if (moment(e.target.value, 'HH:mm', true).isValid()) {
                                  clockOut = `${e.target.value}:00`;
                                  error = false;
                                }
                                if ((user.role === Role.Seller || user.role === Role.Business) && !copy.storeId) {
                                  error = true;
                                }
                                if (!copy.clockIn || !moment(copy.clockIn, 'HH:mm:ss', true).isValid()) {
                                  error = true;
                                }
                                setMapNewUserWorkClocking({
                                  ...mapNewUserWorkClocking,
                                  [yearMonthDay]: {
                                    ...copy,
                                    clockOut,
                                    error,
                                    valid: !error,
                                  },
                                });
                              }}
                              className={clsx('input-times', { 'invalid-uwc': mapNewUserWorkClocking[yearMonthDay]?.error, 'valid-uwc': mapNewUserWorkClocking[yearMonthDay]?.valid })}
                              type="time"
                            />
                            {mapNewUserWorkClocking[yearMonthDay]?.clockIn &&
                              (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || mapNewUserWorkClocking[yearMonthDay]?.storeId) && (
                                <div className="clickable" title="Crear fichaje" onClick={() => createUserWorkClocking(yearMonthDay)}>
                                  <Save className="ms-2" size={18} color="#26c44b" />
                                </div>
                              )}
                          </div>
                        )}
                      </td>
                      <td>
                        {uwcs.map((uwcs: UserWorkClocking) => {
                          if (uwcs.isDeleted) {
                            return null;
                          }
                          return (
                            <div key={uwcs.id} className="d-flex flex-column">
                              <select
                                disabled={!editable || isFuture}
                                className={clsx('select-store', { 'cursor-not-allowed': !editable || isFuture })}
                                value={mapExistingUserWorkClocking[uwcs.id]?.storeId || ''}
                                onChange={async (e) => {
                                  const map: { [id: number]: UserWorkClocking } = { ...mapExistingUserWorkClocking };
                                  map[uwcs.id] = { ...map[uwcs.id], storeId: e.target.value && !isNaN(e.target.value as any) ? parseInt(e.target.value, 10) : null };
                                  setMapExistingUserWorkClocking(map);
                                }}
                              >
                                {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && <option value="">Ninguna tienda</option>}
                                {stores.map((store: Store) => (
                                  <option key={store.id} value={store.id}>
                                    {store.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          );
                        })}
                        {/* NUEVO REGISTRO */}
                        {editable && !isFuture && isWorkable && (
                          <div className="d-flex flex-column">
                            <select
                              className={clsx('select-store', { 'invalid-uwc': mapNewUserWorkClocking[yearMonthDay]?.error, 'valid-uwc': mapNewUserWorkClocking[yearMonthDay]?.valid })}
                              value={mapNewUserWorkClocking[yearMonthDay]?.storeId || ''}
                              onChange={(e) => {
                                const copy: NewUWC = { ...mapNewUserWorkClocking[yearMonthDay] };
                                const storeId: number | '' = e.target.value && !isNaN(e.target.value as any) ? parseInt(e.target.value, 10) : '';
                                let error = false;
                                if (!copy.clockIn || !moment(copy.clockIn, 'HH:mm:ss', true).isValid()) {
                                  error = true;
                                } else if (copy.clockOut && !moment(copy.clockOut, 'HH:mm:ss', true).isValid()) {
                                  error = true;
                                }
                                if ((user.role === Role.Seller || user.role === Role.Business) && storeId === '') {
                                  error = true;
                                }
                                setMapNewUserWorkClocking({
                                  ...mapNewUserWorkClocking,
                                  [yearMonthDay]: {
                                    ...copy,
                                    storeId,
                                    error,
                                    valid: !error,
                                  },
                                });
                              }}
                            >
                              <option value="">Selecciona una tienda</option>
                              {stores.map((store: Store) => (
                                <option key={store.id} value={store.id}>
                                  {store.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="d-flex flex-column container-worked-estimated-hours">
                          <div className="d-flex flex-row">
                            <label>Estimadas:</label>
                            <span>{minutesToHourAndMinutesStr(estimatedMinutesYearMonthDay)}</span>
                          </div>
                          <div className="d-flex flex-row">
                            <label>Trabajadas:</label>
                            <span>{minutesToHourAndMinutesStr(workedMinutesYearMonthDay)}</span>
                          </div>
                        </div>
                        {diffMinutesYearMonthDay !== 0 && <span className="missing-time">Tiempo: {minutesToHourAndMinutesStr(diffMinutesYearMonthDay)}</span>}
                        {(hasDayOff || hasHoliday) && <span className="not-workable">{hasDayOff ? 'Día libre' : 'Vacaciones'}</span>}
                      </td>
                      <td>
                        <div className="d-flex flex-column">
                          {uwcs.map((uwcs: UserWorkClocking) => {
                            if (uwcs.isDeleted) {
                              return null;
                            }
                            return (
                              <div key={uwcs.id} className="d-flex flex-row">
                                <span key={uwcs.id} onClick={() => setSelectedUserWorkClocking(uwcs)} className={clsx('container-note', uwcs.notes ? 'with-note' : 'no-note')}>
                                  {uwcs.notes ? '1 Nota' : '+ Nota'}
                                </span>
                              </div>
                            );
                          })}
                          {/* NUEVO REGISTRO */}
                          {editable && !isFuture && isWorkable && (
                            <span
                              onClick={() => {
                                setSelectedYearMonthDay(yearMonthDay);
                              }}
                              className={clsx('container-note', mapNewUserWorkClocking[yearMonthDay]?.notes ? 'with-note' : 'no-note', { 'cursor-not-allowed': !editable })}
                            >
                              {mapNewUserWorkClocking[yearMonthDay]?.notes ? '1 Nota' : '+ Nota'}
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {selectedUserWorkClocking !== null && (
            <NoteModal
              notes={selectedUserWorkClocking.notes}
              show={true}
              closeModal={() => setSelectedUserWorkClocking(null)}
              saveNotes={(notes: string | null) => {
                const isFuture: boolean = moment(selectedUserWorkClocking.date, 'YYYY-MM-DD').isAfter(moment(), 'day');
                if (!editable || isFuture) {
                  return;
                }
                updateUserWorkClocking(selectedUserWorkClocking.id, {
                  clockIn: selectedUserWorkClocking.clockIn,
                  clockOut: selectedUserWorkClocking.clockOut ? selectedUserWorkClocking.clockOut : null,
                  storeId: selectedUserWorkClocking.storeId,
                  userId: selectedUserWorkClocking.userId,
                  notes,
                  date: selectedUserWorkClocking.date,
                  userTimeSheetId: selectedUserWorkClocking.userTimeSheetId,
                });
                setSelectedUserWorkClocking(null);
              }}
              deleteNotes={() => {
                updateUserWorkClocking(selectedUserWorkClocking.id, {
                  clockIn: selectedUserWorkClocking.clockIn,
                  clockOut: selectedUserWorkClocking.clockOut ? selectedUserWorkClocking.clockOut : null,
                  storeId: selectedUserWorkClocking.storeId,
                  userId: selectedUserWorkClocking.userId,
                  notes: null,
                  date: selectedUserWorkClocking.date,
                  userTimeSheetId: selectedUserWorkClocking.userTimeSheetId,
                });
                setSelectedUserWorkClocking(null);
              }}
              editable={editable && !moment(selectedUserWorkClocking.date, 'YYYY-MM-DD').isAfter(moment(), 'day')}
            />
          )}
          {selectedYearMonthDay !== null && (
            <NoteModal
              notes={mapNewUserWorkClocking[selectedYearMonthDay]?.notes || null}
              show={true}
              closeModal={() => setSelectedYearMonthDay(null)}
              saveNotes={(notes: string | null) => {
                setMapNewUserWorkClocking({
                  ...mapNewUserWorkClocking,
                  [selectedYearMonthDay]: {
                    ...mapNewUserWorkClocking[selectedYearMonthDay],
                    notes,
                  },
                });
                setSelectedYearMonthDay(null);
              }}
              deleteNotes={() => {
                setMapNewUserWorkClocking({
                  ...mapNewUserWorkClocking,
                  [selectedYearMonthDay]: {
                    ...mapNewUserWorkClocking[selectedYearMonthDay],
                    notes: null,
                  },
                });
                setSelectedYearMonthDay(null);
              }}
              editable={editable}
            />
          )}
        </React.Fragment>
      )}
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
      <UserWorkClockingHistoryDayModal dayMonthYear={selectedDayMonthYear} history={selectedHistory} show={showHistoryModal} closeModal={onCloseHistoryModal} />
      {user !== null && userTimeSheet !== null && (
        <UserWorkClockingModal title={titleCreateUpdateUswerWorkClockingButton} user={user} show={showUserWorkClockingModal} closeModal={onCloseUserWorkClockingModal} />
      )}
    </div>
  );
};

export default UserWorkClockingsView;
