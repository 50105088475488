import { DeliveryNoteStatus } from '../enums/delivery-note-status';
import { deliveryNoteStatusToString } from '../services/helpers';

const DeliveryNoteStatusColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }: any) => {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      <option value={DeliveryNoteStatus.Cancelled}>{deliveryNoteStatusToString(DeliveryNoteStatus.Cancelled)}</option>
      <option value={DeliveryNoteStatus.Pending}>{deliveryNoteStatusToString(DeliveryNoteStatus.Pending)}</option>
      <option value={DeliveryNoteStatus.Received}>{deliveryNoteStatusToString(DeliveryNoteStatus.Received)}</option>
      <option value={DeliveryNoteStatus.Invoiced}>{deliveryNoteStatusToString(DeliveryNoteStatus.Invoiced)}</option>
    </select>
  );
};

export default DeliveryNoteStatusColumnFilter;
