import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerInfo from '../components/customer-info';
import CustomersFinder from '../components/customers-finder';
import NewRepairButtons from '../components/new-repair-buttons';
import NewRepairProduct from '../components/new-repair-product';
import RepairCustomerHeader from '../components/repair-customer-header';
import RepairHeader from '../components/repair-header';
import RepairProductWarranty from '../components/repair-product-warranty';
import { PosStatus } from '../enums/pos-status';
import { Customer } from '../interfaces/customer';
import { repairSelector, reset, setCustomer, setErrorCustomer, setStoreId } from '../store/repair-slice';
import { storeSelector } from '../store/store-slice';

const NewRepairView = () => {
  const dispatch = useDispatch();
  const { store } = useSelector(storeSelector);
  const { customer, balance, errorCustomer, warranty } = useSelector(repairSelector);
  const [files, setFiles] = useState<File[]>([]);
  const [base64Images, setBase64Images] = useState<string[]>([]);

  useEffect(() => {
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (store) {
      dispatch(setStoreId(store.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  if (!store) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <h1>Selecciona una tienda</h1>
      </div>
    );
  }

  if (store.posStatus === PosStatus.Closed) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <h1>Abre la caja para realizar composturas</h1>
      </div>
    );
  }

  return (
    <div className="new-repair inner-layout">
      <div className="l-side">
        <div className="product-side p-4 d-flex flex-column h-100">
          <RepairHeader isNew={true} />
          {warranty === null ? <RepairProductWarranty /> : <NewRepairProduct files={files} setFiles={setFiles} base64Images={base64Images} setBase64Images={setBase64Images} />}
        </div>
      </div>
      <div className="r-side">
        <div className="d-flex flex-column position-relative">
          <div className="p-4 mb-auto">
            <RepairCustomerHeader />
            {!customer ? (
              <CustomersFinder
                customer={customer}
                onClose={(c: Customer | null) => dispatch(setCustomer(c))}
                billingDataRequired={false}
                error={errorCustomer}
                onInputChange={() => dispatch(setErrorCustomer(false))}
              />
            ) : (
              <CustomerInfo
                onRemoveCustomer={() => dispatch(setCustomer(null))}
                customer={customer}
                onAssignCustomer={(newCustomer: Customer) => dispatch(setCustomer(newCustomer))}
                balance={balance}
              />
            )}
          </div>
          {warranty !== null && <NewRepairButtons files={files} base64Images={base64Images} />}
        </div>
      </div>
    </div>
  );
};

export default NewRepairView;
