import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { repairSelector, setAcceptBudget } from '../store/repair-slice';
import PinModal from './pin-modal';

const RepairBudget = () => {
  const dispatch = useDispatch();
  const { requesting, acceptBudget } = useSelector(repairSelector);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);

  const onClosePinModal = async (pin: string | null) => {
    setShowPinModal(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
  };

  return (
    <div className="repair-budget">
      <label>Cliente acepta presupuesto:</label>
      <div className="row">
        <div className="col">
          <button
            disabled={requesting}
            onClick={() => {
              if (acceptBudget) {
                dispatch(setAcceptBudget(null));
              } else {
                dispatch(setAcceptBudget(true));
              }
            }}
            className={clsx({ accept: acceptBudget })}
          >
            Sí acepta
          </button>
        </div>
        <div className="col">
          <button
            disabled={requesting}
            onClick={() => {
              if (acceptBudget) {
                dispatch(setAcceptBudget(false));
              } else if (acceptBudget === false) {
                dispatch(setAcceptBudget(null));
              } else {
                dispatch(setAcceptBudget(false));
              }
            }}
            className={clsx({ decline: acceptBudget === false })}
          >
            NO acepta
          </button>
        </div>
      </div>
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
    </div>
  );
};

export default RepairBudget;
