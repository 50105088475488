import clsx from 'clsx';
import React, { useState } from 'react';
import { ChevronLeft, Plus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { SaleStep } from '../enums/sale-step';
import { Customer } from '../interfaces/customer';
import { clearPaymentMethods, saleSelector, setNotes, setStep, updateSaleCustomer } from '../store/sale-slice';
import CustomerInfo from './customer-info';
import NoteModal from './note-modal';
import ReturnSubtotals from './return-subtotals';
import { ReturnsAndExchangesTable } from './returns-and-exchange-table';

const ReturnSummary = () => {
  const dispatch = useDispatch();
  const { customer, balance, notes } = useSelector(saleSelector);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);

  const onCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  const onSaveNoteModal = (notes: string | null) => {
    dispatch(setNotes(notes));
    setShowNoteModal(false);
  };

  const onDeleteNoteModal = () => {
    dispatch(setNotes(null));
    setShowNoteModal(false);
  };

  return (
    <React.Fragment>
      <div className="sale-summary h-100">
        <div className="d-flex flex-column h-100">
          <div className="mb-auto">
            <div className="px-4 pt-3">
              <div className="d-flex justify-content-between align-items-start mb-3 noselect">
                <span
                  onClick={() => {
                    dispatch(clearPaymentMethods());
                    dispatch(setStep(SaleStep.ReturnsAndExchanges));
                  }}
                  className="d-flex align-items-center come-back"
                  title="Volver al listado de devoluciones"
                >
                  <ChevronLeft className="me-2" size={14} />
                  Volver
                </span>
                <h1 className="mb-0">Devolución</h1>
                <div onClick={() => setShowNoteModal(true)} className={clsx('button-header', { 'has-notes': notes != null && notes !== '' })}>
                  {notes != null && notes !== '' ? (
                    '1 Nota'
                  ) : (
                    <React.Fragment>
                      <Plus className="me-1" size={14} />
                      <span>Nota</span>
                    </React.Fragment>
                  )}
                </div>
              </div>
              <ReturnsAndExchangesTable enableActions={false} />
              <ReturnSubtotals />
              <CustomerInfo balance={balance} customer={customer!} onAssignCustomer={(newCustomer: Customer) => dispatch(updateSaleCustomer(newCustomer.id))} />
            </div>
          </div>
        </div>
      </div>
      <NoteModal editable={true} show={showNoteModal} notes={notes} closeModal={onCloseNoteModal} saveNotes={onSaveNoteModal} deleteNotes={onDeleteNoteModal} />
    </React.Fragment>
  );
};

export default ReturnSummary;
