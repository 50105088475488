import clsx from 'clsx';
import React, { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';

interface Props {
  maxTotal: number;
  show: boolean;
  discountValue: number | null;
  closeModal: () => void;
  saveTotal: (total: number) => void;
  deleteTotal: () => void;
}

const PosProductTotalModal = (props: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (props.show) {
      reset({
        total: null,
      });
    }
  }, [props.show, reset]);

  const close = () => {
    reset({
      total: null,
    });
    props.closeModal();
  };

  const onSubmit = (data: any) => {
    reset({
      total: null,
    });
    props.saveTotal(parseFloat(data.total));
  };

  const deleteTotal = () => {
    reset({
      total: null,
    });
    props.deleteTotal();
  };

  return (
    <Modal className="vercomi-modal my-form pos-discount-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Total</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.total })}>Total*</div>
              <input type="number" {...register('total', { required: true, min: 0, max: props.maxTotal })} min={0} max={props.maxTotal} className={clsx({ error: errors?.total })} step={0.01} />
              {errors.total && <div className="error-message">{errors.total.message}</div>}
            </div>
          </div>
          {props.discountValue != null && props.discountValue > 0 && (
            <div className="row">
              <div className="col">
                <span className="remove-discount" onClick={deleteTotal}>
                  Borrar total
                </span>
              </div>
            </div>
          )}
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default PosProductTotalModal;
