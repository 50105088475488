import { UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { TableState, useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { DefaultColumnFilter, fuzzyFilterTypes } from '../services/table-helpers';
interface Props {
  columns: any[];
  data: any[];
  noDataMessage: string;
  onFilteredRowsChanged?: (filteredRows: any[]) => void;
  initialState?: any;
  onStateChange?: (state: TableState<any>) => void;
}

const Table = forwardRef((props: Props, ref) => {
  const { data, columns, noDataMessage } = props;

  useImperativeHandle(ref, () => ({
    clearFilters() {
      setAllFilters([]);
    },
  }));

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const filterTypes = useMemo(() => fuzzyFilterTypes, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, page, prepareRow, pageCount, state, gotoPage, canPreviousPage, canNextPage, previousPage, nextPage, filteredRows, setAllFilters } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: props.initialState,
        defaultCanSort: true,
      },
      useFilters,
      useSortBy,
      useExpanded,
      usePagination,
    );

  useEffect((): void => {
    if (props.onFilteredRowsChanged) {
      props.onFilteredRowsChanged(filteredRows);
    }
  }, [filteredRows, props]);

  useEffect((): void => {
    if (props.onStateChange) {
      props.onStateChange(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  let right = 0;
  let left = 0;
  if (state.pageIndex === 0) {
    left = 0;
    right = Math.min(pageCount, 4);
  } else if (state.pageIndex === pageCount - 1) {
    left = Math.max(0, pageCount - 5);
    right = pageCount - 1;
  } else if (state.pageIndex - 2 < 0) {
    left = Math.max(0, state.pageIndex - 2);
    right = Math.min(pageCount, state.pageIndex - left + 1 + 2);
  } else if (state.pageIndex + 2 > pageCount - 1) {
    right = Math.min(pageCount - 1, state.pageIndex + 2);
    left = Math.max(0, state.pageIndex - 2 - (pageCount - 1 - right) - 1);
  } else {
    right = Math.min(pageCount - 1, state.pageIndex + 2);
    left = Math.max(0, state.pageIndex - 2);
  }

  const pages: any[] = [];
  for (let i = 0; i < pageCount; i++) {
    if (i >= left && i <= right) {
      pages.push(
        <span
          key={i}
          onClick={() => {
            if (i === state.pageIndex) {
              return;
            }
            gotoPage(i);
          }}
          className={`page ${state.pageIndex === i ? 'active' : 'selectable'}`}
        >
          {i + 1}
        </span>,
      );
    }
  }

  const showFiltersRow = useMemo(() => {
    for (const headerGroup of headerGroups) {
      if (headerGroup.headers.length > 0) {
        for (const header of headerGroup.headers) {
          if (header.canFilter) {
            return true;
          }
        }
      }
    }
    return false;
  }, [headerGroups]);

  return (
    <React.Fragment>
      {/* apply the table props */}
      <div className="table-container">
        <table {...getTableProps()} className="my-table">
          <thead>
            {
              // Loop over the header rows
              showFiltersRow &&
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => (
                        // Apply the header cell props
                        <th
                          {...column.getHeaderProps({
                            style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                          })}
                        >
                          {/* Render the columns filter UI */}
                          <div>{column.canFilter ? column.render('Filter') : null}</div>
                        </th>
                      ))
                    }
                  </tr>
                ))
            }
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps({
                          ...column.getSortByToggleProps(),
                          style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                        })}
                        className="cursor-pointer"
                      >
                        {/* Add a sort direction indicator */}
                        <span>{column.isSorted ? column.isSortedDesc ? <ChevronDown size={14} className="me-1" /> : <ChevronUp size={14} className="me-1" /> : ''}</span>
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {rows.length === 0 ? (
              <tr className="text-center">
                <td colSpan={columns.length}>{noDataMessage}</td>
              </tr>
            ) : (
              // Loop over the table rows
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps({
                              style: { minWidth: cell.column.minWidth, width: cell.column.width, maxWidth: cell.column.maxWidth },
                            })}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {pages.length > 1 && (
        <div className="my-pagination noselect d-flex justify-content-center align-items-center">
          <div
            className={`d-flex justify-content-center align-items-center previous-page ${canPreviousPage ? 'selectable' : 'disabled'}`}
            onClick={() => {
              if (canPreviousPage) {
                previousPage();
              }
            }}
          >
            <UilAngleLeft color="#808a95" size={20} />
            <span>Anterior</span>
          </div>
          {pages}
          <div
            className={`d-flex justify-content-center align-items-center next-page ${canNextPage ? 'selectable' : 'disabled'}`}
            onClick={() => {
              if (canNextPage) {
                nextPage();
              }
            }}
          >
            <span>Siguiente</span>
            <UilAngleRight color="#808a95" size={20} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
});

export default Table;
