import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutAction } from '../store/auth-slice';

interface Props {
  show: boolean;
  closeModal: () => void;
}

const LogoutModal = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const close = () => {
    props.closeModal();
  };

  return (
    <Modal className="vercomi-modal my-form logout-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form>
        <div className="content pt-4 text-center">
          <p>¿Estás seguro que deseas cerrar la sesión?</p>
        </div>
        <div className="d-flex flex-row">
          <button className={`my-button cancel-button`} type="button" onClick={close}>
            Cancelar
          </button>
          <button
            className={`my-button save-button`}
            type="button"
            onClick={() => {
              dispatch(logoutAction());
              navigate('/login');
            }}
          >
            Salir
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default LogoutModal;
