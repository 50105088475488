/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { useState } from 'react';
import { Clipboard, Layers, ShoppingBag, Tag } from 'react-feather';
import { useSelector } from 'react-redux';
import DashboardMostSelledProducts from '../components/dashboard-most-selled-products';
import DashboardSalesStats from '../components/dashboard-sales-stats';
import DashboardShoppingStats from '../components/dashboard-shopping-stats';
import DashboardStockStats from '../components/dashboard-stock-stats';
import DateRangeSelector from '../components/date-range-selector';
import NoStoresAssigned from '../components/no-stores-assigned';
import { DateRange } from '../enums/date-range';
import { Role } from '../enums/role';
import { useStores } from '../hooks/use-stores.hook';
import { DateRangeData } from '../interfaces/date-range-data';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { getDatesGivenDateRange } from '../services/helpers';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

const dateRange: DateRange = DateRange.ThisYear;

enum Tab {
  Sales = 'ventas',
  Purchases = 'compras',
  Stock = 'stock',
  MostSelledProducts = 'most-selled-products',
}

const DashboardView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { selectedStoreId } = useSelector(storeSelector);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData>({
    dateRange,
    dates: getDatesGivenDateRange(dateRange),
  });
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.Sales);
  const stores: Store[] = useStores();

  const handleTabClick = (e: any) => {
    e.preventDefault();
    setCurrentTab(e.target.id);
  };

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  return (
    <div className="dashboard p-4">
      <div className="row">
        <div className="col-12 col-md-10 px-2">
          <ul className="dashboard-tabs">
            <li title="Ventas">
              <a className={clsx({ active: currentTab === Tab.Sales })} id={Tab.Sales} href="#" onClick={handleTabClick}>
                <ShoppingBag color="#fff" size={15} />
                Ventas
              </a>
            </li>
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <li title="Compras">
                <a className={clsx({ active: currentTab === Tab.Purchases })} id={Tab.Purchases} href="#" onClick={handleTabClick}>
                  <Clipboard color="#fff" size={15} />
                  Compras
                </a>
              </li>
            )}
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <li title="Stock">
                <a className={clsx({ active: currentTab === Tab.Stock })} id={Tab.Stock} href="#" onClick={handleTabClick}>
                  <Layers color="#fff" size={15} />
                  Stock
                </a>
              </li>
            )}
            <li title="+ Vendidos">
              <a className={clsx({ active: currentTab === Tab.MostSelledProducts })} id={Tab.MostSelledProducts} href="#" onClick={handleTabClick}>
                <Tag color="#fff" size={15} />
                Ranking SIERRA
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-2 px-2 mb-4 mb-sm-0">
          {(currentTab === Tab.Sales || currentTab === Tab.Purchases || currentTab === Tab.MostSelledProducts) && (
            <DateRangeSelector
              showTimePicker={false}
              dateRangeData={dateRangeData}
              availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear, DateRange.All]}
              onChange={(drd: DateRangeData) => setDateRangeData(drd)}
            />
          )}
        </div>
      </div>
      {currentTab === Tab.Sales && <DashboardSalesStats organizationId={organization.id} storeId={selectedStoreId} startDate={dateRangeData.dates[0]} endDate={dateRangeData.dates[1]} />}
      {currentTab === Tab.Purchases && <DashboardShoppingStats organizationId={organization.id} storeId={selectedStoreId} startDate={dateRangeData.dates[0]} endDate={dateRangeData.dates[1]} />}
      {currentTab === Tab.Stock && <DashboardStockStats organizationId={organization.id} storeId={selectedStoreId} />}
      {currentTab === Tab.MostSelledProducts && <DashboardMostSelledProducts storeId={selectedStoreId} startDate={dateRangeData.dates[0]} endDate={dateRangeData.dates[1]} />}
    </div>
  );
};

export default DashboardView;
