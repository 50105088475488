import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { Organization } from '../interfaces/organization';
import { TechnicalService } from '../interfaces/technical-service';
import { TechnicalServiceDto } from '../interfaces/technical-service.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  technicalService: TechnicalService | null;
  show: boolean;
  closeModal: (ts: TechnicalService | null) => void;
}

const TechnicalServiceModal = (props: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const defaultValues = useMemo(() => {
    const values: TechnicalServiceDto = {
      name: '',
      phone: '',
      cif: '',
      address: '',
      email: '',
      organizationId: -1,
    };
    if (props.technicalService) {
      values.name = props.technicalService.name;
      values.phone = props.technicalService.phone;
      values.cif = props.technicalService.cif;
      values.address = props.technicalService.address;
      values.email = props.technicalService.email;
      values.organizationId = props.technicalService.organizationId;
    }
    return values;
  }, [props.technicalService]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  useEffect(() => {
    if (props.show && props.technicalService) {
      reset(props.technicalService);
    }
  }, [props.show, props.technicalService, reset]);

  const close = () => {
    reset();
    props.closeModal(null);
  };

  const onSubmit = async (data: TechnicalServiceDto) => {
    data.organizationId = organization.id;
    try {
      if (props.technicalService) {
        const ts: TechnicalService = await api.updateTechnicalService(props.technicalService.id, data);
        props.closeModal(ts);
        myToastr.success('Servicio técnico actualizado');
      } else {
        const ts: TechnicalService = await api.createTechnicalService(data);
        props.closeModal(ts);
        myToastr.success('Servicio técnico creado');
      }
      reset();
    } catch (e: any) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError?.response) {
        if (Array.isArray(axiosError.response.data.message)) {
          myToastr.error(axiosError.response.data.message.join('</br>'));
        } else {
          myToastr.error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form technical-service-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Servicio Técnico</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.name ? 'error' : '')}>Nombre*</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.email })}>Email</div>
              <input type="email" {...register('email')} placeholder="Email" className={clsx({ error: errors?.email })} />
              {errors.email && <div className="error-message">{errors.email.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.phone })}>Teléfono</div>
              <input type="number" {...register('phone')} placeholder="Teléfono" className={clsx({ error: errors?.phone })} />
              {errors.phone && <div className="error-message">{errors.phone.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.cif })}>CIF</div>
              <input type="text" {...register('cif')} className={clsx({ error: errors?.cif })} placeholder="CIF" />
              {errors.cif && <div className="error-message">{errors.cif.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.address })}>Dirección</div>
              <input type="text" {...register('address')} className={clsx({ error: errors?.address })} placeholder="Dirección" />
              {errors.address && <div className="error-message">{errors.address.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default TechnicalServiceModal;
