import { forwardRef } from 'react';

const CustomToggleDropdown = forwardRef((props: any, ref: any) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      props.onClick(e);
    }}
  >
    {props.children}
  </div>
));

export default CustomToggleDropdown;
