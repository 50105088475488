import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { repairSelector, setCostPrice, setErrorCostPrice, setErrorRepairPrice, setRepairPrice } from '../store/repair-slice';

const RepairPrices = () => {
  const dispatch = useDispatch();
  const { requesting, costPrice, repairPrice, errorCostPrice, errorRepairPrice } = useSelector(repairSelector);

  return (
    <div className="d-flex flex-column my-2 repair-prices">
      <div className="row content">
        <div className="col-12">
          <div className="title mb-2">Presupuesto</div>
        </div>
        <div className="col d-flex flex-column">
          <label className={clsx({ error: errorCostPrice })}>Precio coste:</label>
          <input
            disabled={requesting}
            className={clsx('input-size', { error: errorCostPrice })}
            type="number"
            placeholder="0.00€"
            step={0.01}
            value={costPrice !== null ? costPrice : ''}
            onChange={(e: any) => {
              dispatch(setErrorCostPrice(false));
              dispatch(setCostPrice(parseFloat(e.target.value === '' ? null : e.target.value)));
            }}
          />
        </div>
        <div className="col d-flex flex-column">
          <label className={clsx({ error: errorRepairPrice })}>Precio reparación:</label>
          <input
            disabled={requesting}
            className={clsx('input-size', { error: errorRepairPrice })}
            type="number"
            placeholder="0.00€"
            step={0.01}
            value={repairPrice !== null ? repairPrice : ''}
            onChange={(e: any) => {
              dispatch(setErrorRepairPrice(false));
              dispatch(setRepairPrice(parseFloat(e.target.value === '' ? null : e.target.value)));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RepairPrices;
