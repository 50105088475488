import axios, { AxiosError } from 'axios';
import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Edit, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import PayrollModal from '../components/payroll-modal';
import PinModal from '../components/pin-modal';
import { PinDto } from '../components/pin.dto';
import Table from '../components/table';
import { Role } from '../enums/role';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { Payroll } from '../interfaces/payroll';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { downloadPdf, monthToString } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const PayrollsView = () => {
  const navigate = useNavigate();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [payrolls, setPayrolls] = useState<Payroll[]>([]);
  const [showPayrollModal, setShowPayrollModal] = useState<boolean>(false);
  const [selectedPayroll, setSelectedPayroll] = useState<Payroll | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [showPinModal, setShowPinModal] = useState<boolean>(true);
  const columns = useMemo(() => {
    const columnsData: any[] = [
      { Header: 'Nombre', accessor: 'name', filter: 'fuzzyText' },
      { Header: 'Año', accessor: 'year', filter: 'fuzzyText' },
      {
        Header: 'Mes',
        accessor: (payroll: Payroll) => monthToString(payroll.month),
        filter: 'fuzzyText',
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          const payroll: Payroll = row.original;
          return (
            <React.Fragment>
              <Download size={16} className="mx-2" color="#222E3D" onClick={() => downloadPayroll(payroll)} type="button" />
              {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                <Edit
                  size={16}
                  className="mx-2"
                  color="#222E3D"
                  onClick={() => {
                    setSelectedPayroll(row.original);
                    setShowPayrollModal(true);
                  }}
                  type="button"
                />
              )}
              {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                <Trash2
                  type="button"
                  className="mx-2"
                  onClick={() => {
                    setSelectedPayroll(row.original);
                    setShowConfirmModal(true);
                  }}
                  size={14}
                />
              )}
            </React.Fragment>
          );
        },
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      columnsData.unshift({
        Header: 'Usuario',
        accessor: (p: Payroll) => `${p.user.name} ${p.user.surnames}`,
        filter: 'fuzzyText',
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onPinModalClose = async (pin: string | null) => {
    if (!pin) {
      navigate(-1);
      return;
    }
    try {
      const pinDto: PinDto = {
        organizationId: organization.id,
        pin,
      };
      await api.checkPin(pinDto);
      await getPayrolls();
      setShowPinModal(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getPayrolls = async () => {
    setRequesting(true);
    const ps: Payroll[] = await api.getPayrolls();
    setPayrolls(ps);
    setRequesting(false);
  };

  const downloadPayroll = async (payroll: Payroll) => {
    try {
      myToastr.info('Obteniendo la nómina. Espere por favor.');
      setRequesting(true);
      const result: ArrayBuffer = await api.downloadPayroll(payroll.id);
      downloadPdf(result, `nomina-${payroll.month}-${payroll.year}.pdf`);
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    } finally {
      setRequesting(false);
    }
  };

  const newPayroll = () => {
    setSelectedPayroll(null);
    setShowPayrollModal(true);
  };

  const onClosePayrollModal = (payroll: Payroll | null) => {
    if (payroll) {
      getPayrolls();
    }
    setShowPayrollModal(false);
    setSelectedPayroll(null);
  };

  const deletePayroll = async (ts: Payroll) => {
    try {
      await api.deletePayroll(ts.id);
      const tss: Payroll[] = [...payrolls];
      const index: number = tss.findIndex((t: Payroll) => t.id === ts.id);
      tss.splice(index, 1);
      setPayrolls(tss);
      myToastr.success('Nómina eliminada correctamente');
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const payroll: Payroll = fr.original;
      data.push({
        Usuario: `${payroll.user.name} ${payroll.user.surnames}`,
        Nombre: payroll.name,
        Año: payroll.year,
        Mes: monthToString(payroll.month),
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <React.Fragment>
      {showPinModal ? (
        <PinModal show={showPinModal} onCloseModal={onPinModalClose} />
      ) : (
        <div className="p-4">
          <div className="d-flex flex-row align-items-center">
            <h1 className="flex-grow-1">Nóminas</h1>
            {filteredRows.length > 0 && (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <CSVLink filename="nominas.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
                <Download className="me-1" size={14} /> Exportar
              </CSVLink>
            )}
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <button className="d-flex align-items-center create-button" onClick={newPayroll}>
                <Plus className="me-1" size={14} /> Subir nómina
              </button>
            )}
          </div>
          <Table
            data={payrolls}
            columns={columns}
            noDataMessage="No hay nóminas"
            onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
            initialState={{
              pageSize: Constants.LIMIT_RESULTS,
            }}
          />
          <PayrollModal show={showPayrollModal} closeModal={onClosePayrollModal} payroll={selectedPayroll} />
          {selectedPayroll && (
            <ConfirmModal
              acceptButtonClass="accept-button"
              show={showConfirmModal}
              title="Eliminar Nómina"
              content={`¿Estás seguro que quieres eliminar la nómina ${selectedPayroll.name}?`}
              closeModal={(result: boolean) => {
                setShowConfirmModal(false);
                if (result) {
                  deletePayroll(selectedPayroll);
                }
                setSelectedPayroll(null);
              }}
            ></ConfirmModal>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default PayrollsView;
