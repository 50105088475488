interface MiraviaIconProps {
  height: number;
  width: number;
}

const MiraviaIcon = ({ height, width }: MiraviaIconProps) => {
  return <img src="/images/miravia.png" alt="" height={height} width={width} />;
};

export default MiraviaIcon;
