import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import myToastr from '../services/toastr';
import { createNewRepair, repairSelector, reset, setErrorBudget, setErrorCostPrice, setErrorCustomer, setErrorProduct, setErrorRepairPrice, setErrorTechnicalService } from '../store/repair-slice';
import PinModal from './pin-modal';

interface Props {
  files: File[];
  base64Images: string[];
}

const NewRepairButtons = ({ files, base64Images }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requesting, customer, warranty, productId, technicalServiceId, item, hasBudget, budgetInStore, costPrice, repairPrice } = useSelector(repairSelector);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);

  const disabled: boolean = useMemo(() => {
    if (!customer) {
      return true;
    }
    if (warranty) {
      if (productId <= 0) {
        return true;
      }
      if (technicalServiceId <= 0) {
        return true;
      }
    } else {
      if (productId <= 0 && !item) {
        return true;
      }
      if (!hasBudget && !budgetInStore) {
        return true;
      }
      if (hasBudget && technicalServiceId <= 0) {
        return true;
      }
      if (budgetInStore && (costPrice === null || repairPrice === null)) {
        return true;
      }
    }
    return false;
  }, [customer, warranty, productId, technicalServiceId, item, hasBudget, budgetInStore, costPrice, repairPrice]);

  const validateRepair = () => {
    if (!customer) {
      dispatch(setErrorCustomer(true));
      myToastr.error('Selecciona un cliente');
      return;
    }
    if (warranty) {
      if (productId <= 0) {
        dispatch(setErrorProduct(true));
        myToastr.error('Selecciona un producto');
        return;
      }
      if (technicalServiceId <= 0) {
        dispatch(setErrorTechnicalService(true));
        myToastr.error('Selecciona un servicio técnico');
        return;
      }
    } else {
      if (productId <= 0 && !item) {
        dispatch(setErrorProduct(true));
        myToastr.error('Selecciona o introduce el nombre del producto');
        return;
      }
      if (!hasBudget && !budgetInStore) {
        dispatch(setErrorBudget(true));
        myToastr.error('Selecciona una opción: Necesita presupuesto o Presupuesto en tienda');
        return;
      }
      if (hasBudget && technicalServiceId <= 0) {
        dispatch(setErrorTechnicalService(true));
        myToastr.error('Selecciona un servicio técnico');
        return;
      }
      if (budgetInStore && (costPrice === null || repairPrice === null)) {
        if (!costPrice) {
          dispatch(setErrorCostPrice(true));
          myToastr.error('Introduce el coste del producto');
        }
        if (!repairPrice) {
          dispatch(setErrorRepairPrice(true));
          myToastr.error('Introduce el precio de reparación');
        }
        return;
      }
    }
    setShowPinModal(true);
  };

  const onClosePinModal = async (pin: string | null) => {
    setShowPinModal(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    const result: any = await dispatch(createNewRepair({ pin, images: files, base64Images }));
    if (result?.payload?.id) {
      navigate(`/reparacion/${result.payload.id}`);
    } else {
      myToastr.error(result.payload.error.message);
    }
  };

  return (
    <div className="new-repair-buttons">
      <div className="d-flex flex-row">
        <button
          onClick={() => {
            dispatch(reset());
            navigate('/reparaciones');
          }}
          className="container-button cancel"
        >
          Cancelar
        </button>
        <button onClick={validateRepair} disabled={requesting} className={clsx('container-button open-repair', { disabled })}>
          Abrir reparación
        </button>
      </div>
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
    </div>
  );
};

export default NewRepairButtons;
