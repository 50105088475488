import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { ChevronLeft, Upload } from 'react-feather';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CashRegisterStatus } from '../enums/cash-register-status';
import { CashRegisterType } from '../enums/cash-register-type';
import { PaymentMethodType } from '../enums/payment-method-type';
import { Role } from '../enums/role';
import { SaleStatus } from '../enums/sale-status';
import { CashRegisterCheck } from '../interfaces/cash-register-check';
import { CashRegisterMovement, DetailCashRegister } from '../interfaces/detail-cash-register';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { UpdateCashRegisterCheckDto } from '../interfaces/update-cash-register-check.dto';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { downloadPdf, formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface PropsContainerOpening {
  cashRegisterCheck: CashRegisterCheck;
  openingNotes: string;
  setOpeningNotes: (value: string) => void;
}

const ContainerOpening = (props: PropsContainerOpening) => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const { cashRegisterCheck } = props;
  return (
    <div className="container-details my-4">
      <div className="title">Resumen Apertura</div>
      <div className="body">
        <div className="d-flex justify-content-between">
          <div className="date">{moment(cashRegisterCheck.createdAt).format('DD/MM/YYYY HH:mm')}</div>
        </div>
        <div className="row my-2 container-info">
          <div className="col">
            <strong>Caja:</strong> {cashRegisterCheck.store.name}
          </div>
          <div className="col">
            <strong>Abierto por:</strong> {cashRegisterCheck.openingUser.name} {cashRegisterCheck.openingUser.surnames}
          </div>
        </div>
        <div className="row my-2 container-info">
          <div className="col d-flex flex-column">
            <strong>Notas:</strong>
            <textarea value={props.openingNotes} onChange={(e: any) => props.setOpeningNotes(e.target.value)} disabled={user?.role === Role.Seller} />
          </div>
        </div>
        {/* <div className="container-total">
          <span className="type me-1">Saldo anterior:</span>
          <span className="total">{formatNumber(cashRegisterCheck.previousBalance)}€</span>
        </div> */}
        <div className="container-total">
          <span className="type me-1">Saldo de apertura:</span>
          <span className="total">{formatNumber(cashRegisterCheck.openingCash)}€</span>
        </div>
        <div className="container-total">
          <span className="type me-1">Descuadre de apertura:</span>
          <span className="total">{formatNumber(cashRegisterCheck.openingMismatch)}€</span>
        </div>
      </div>
    </div>
  );
};

interface PropsContainerClose {
  cashRegisterCheck: CashRegisterCheck;
  closingNotes: string;
  setClosingNotes: (value: string) => void;
}

const ContainerClose = (props: PropsContainerClose) => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const { cashRegisterCheck } = props;
  return (
    <div className="container-details my-4">
      <div className="title">Resumen Cierre</div>
      <div className="body">
        <div className="d-flex justify-content-between">
          <div className="date">{moment(cashRegisterCheck.closingDate).format('DD/MM/YYYY HH:mm')}</div>
        </div>
        <div className="row my-2 container-info">
          <div className="col">
            <strong>Caja:</strong> {cashRegisterCheck.store.name}
          </div>
          <div className="col">
            <strong>Cerrado por:</strong> {cashRegisterCheck.closingUser.name} {cashRegisterCheck.closingUser.surnames}
          </div>
        </div>
        <div className="row my-2 container-info">
          <div className="col d-flex flex-column">
            <strong>Notas:</strong>
            <textarea value={props.closingNotes} onChange={(e: any) => props.setClosingNotes(e.target.value)} disabled={user?.role === Role.Seller} />
          </div>
        </div>
        <div className="row my-2 container-info __title">
          <div className="col">
            <strong>Caja calculada</strong>
          </div>
        </div>
        <div className="container-detail-totals mb-2">
          <div className="row my-1">
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="icon-cash"></div>
                <div className="title-type mx-2">Entrada efectivo día:</div>
                <div className="value">{formatNumber(cashRegisterCheck.dailyCashIn)}€</div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="icon-tpv"></div>
                <div className="title-type mx-2">Entrada TPV día:</div>
                <div className="value">{formatNumber(cashRegisterCheck.dailyCardsIn)}€</div>
              </div>
            </div>
          </div>
          <div className="row my-1">
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="icon-cash"></div>
                <div className="title-type mx-2">Salida efectivo día:</div>
                <div className="value">{formatNumber(cashRegisterCheck.dailyCashOut)}€</div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="icon-tpv"></div>
                <div className="title-type mx-2">Salida TPV día:</div>
                <div className="value">{formatNumber(cashRegisterCheck.dailyCardsOut)}€</div>
              </div>
            </div>
          </div>
          <div className="row my-1">
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="icon-cash"></div>
                <div className="title-type mx-2">Total efectivo día:</div>
                <div className="value">{formatNumber(cashRegisterCheck.totalDailyCash)}€</div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="icon-tpv"></div>
                <div className="title-type mx-2">Total TPV día:</div>
                <div className="value">{formatNumber(cashRegisterCheck.totalDailyCards)}€</div>
              </div>
            </div>
          </div>
          <div className="row my-2 container-info __title">
            <div className="col">
              <strong>Caja real</strong>
            </div>
          </div>
          <div className="row my-1">
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="icon-cash"></div>
                <div className="title-type mx-2">Efectivo al cierre:</div>
                <div className="value">{formatNumber(cashRegisterCheck.closingCash)}€</div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="icon-tpv"></div>
                <div className="title-type mx-2">TPV al cierre:</div>
                <div className="value">{formatNumber(cashRegisterCheck.closingCards)}€</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center">
                <Upload color="#26C44B" size={14} className="" />
                <div className="title-type mx-2">Retiradas al cierre:</div>
                <div className="value">{formatNumber(cashRegisterCheck.closingWithdrawals)}€</div>
              </div>
            </div>
          </div>
          <div className="row my-2 container-info __title">
            <div className="col">
              <strong>Descuadre</strong>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="title-type me-2">Efectivo:</div>
                <div
                  className={clsx(
                    `result value`,
                    { descuadre: cashRegisterCheck.cashMismatch !== 0 },
                    { 'text-danger': cashRegisterCheck.cashMismatch < 0, 'text-success': cashRegisterCheck.cashMismatch >= 0 },
                  )}
                >
                  {formatNumber(cashRegisterCheck.cashMismatch)}€
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="title-type me-2">Total caja:</div>
                <div className="result value">{formatNumber(cashRegisterCheck.closingCash - cashRegisterCheck.openingCash + cashRegisterCheck.closingCards)}€</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="title-type me-2">Tarjeta:</div>
                <div
                  className={clsx(
                    `result value`,
                    { descuadre: cashRegisterCheck.cardsMismatch !== 0 },
                    { 'text-danger': cashRegisterCheck.cardsMismatch < 0, 'text-success': cashRegisterCheck.cardsMismatch >= 0 },
                  )}
                >
                  {formatNumber(cashRegisterCheck.cardsMismatch)}€
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="title-type me-2">Saldo final:</div>
                <div className="result value">{formatNumber(cashRegisterCheck.endBalance)}€</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DetailCashRegisterView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [detailCashRegister, setDetailCashRegister] = useState<DetailCashRegister | null>(null);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [requestingTicket, setRequestingTicket] = useState<boolean>(false);
  const [openingNotes, setOpeningNotes] = useState<string>('');
  const [closingNotes, setClosingNotes] = useState<string>('');
  const incidence: boolean = useMemo(() => {
    if (!detailCashRegister) {
      return false;
    }
    if (detailCashRegister.cashRegisterCheck.type === CashRegisterType.Open) {
      return detailCashRegister.cashRegisterCheck.openingStatus === CashRegisterStatus.Incidence;
    } else {
      return detailCashRegister.cashRegisterCheck.closingStatus === CashRegisterStatus.Incidence;
    }
  }, [detailCashRegister]);
  const disabledSaveButton: boolean = useMemo(() => {
    if (!detailCashRegister) {
      return true;
    }
    return detailCashRegister.cashRegisterCheck.openingNotes === openingNotes && detailCashRegister.cashRegisterCheck.closingNotes === closingNotes;
  }, [detailCashRegister, openingNotes, closingNotes]);

  useEffect(() => {
    if (params?.id) {
      getDetailCashRegister();
    } else {
      navigate(`/arqueos`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getDetailCashRegister = async () => {
    try {
      const dcr: DetailCashRegister = await api.getDetailCashRegisterCheck(parseInt(params.id!, 10));
      setDetailCashRegister(dcr);
      setOpeningNotes(dcr.cashRegisterCheck.openingNotes);
      setClosingNotes(dcr.cashRegisterCheck.closingNotes);
    } catch (e) {
      navigate(`/arqueos`);
    }
  };

  if (!detailCashRegister) {
    return null;
  }

  const markAsResolved = async () => {
    setRequesting(true);
    try {
      const cashRegisterCheck: CashRegisterCheck = await api.markCashRegisterAsResolved(detailCashRegister.cashRegisterCheck.id);
      setDetailCashRegister({
        ...detailCashRegister,
        cashRegisterCheck,
      });
      myToastr.success(`Incidencia resuelta`);
    } catch (e) {
    } finally {
      setRequesting(false);
    }
  };

  const downloadTicketCashRegisterCheck = async () => {
    if (requestingTicket) {
      return;
    }
    try {
      myToastr.info('Obteniendo el ticket del arqueo. Espere por favor.');
      setRequestingTicket(true);
      const result: ArrayBuffer = await api.getTicketCashRegisterCheck(detailCashRegister.cashRegisterCheck.id);
      downloadPdf(result, `arqueo-${detailCashRegister.cashRegisterCheck.id}.pdf`);
    } catch (e) {
      myToastr.error('Hubo un error obteniendo el ticket del arqueo');
    } finally {
      setRequestingTicket(false);
    }
  };

  const saveCashRegisterCheck = async () => {
    try {
      const cashRegisterCheckDto: UpdateCashRegisterCheckDto = {
        openingNotes,
        closingNotes,
      };
      await api.updateCashRegisterCheck(detailCashRegister.cashRegisterCheck.id, cashRegisterCheckDto);
      myToastr.success('Arqueo actualizado correctamente');
      getDetailCashRegister();
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  return (
    <div className="detail-cash-register-view inner-layout">
      <div className="l-side">
        <div className="movements d-flex flex-column">
          <div className="px-4 pt-4">
            <div className="d-flex justify-content-between align-items-start mb-3 noselect">
              <span onClick={() => navigate('/arqueos')} className="d-flex align-items-center come-back" title="Volver al listado arqueos">
                <ChevronLeft className="me-2" size={14} />
                Volver
              </span>
              <h1 className="mb-0">Movimientos</h1>
              <div className={clsx('d-flex align-items-center button-header')}>{moment(detailCashRegister.cashRegisterCheck.createdAt).format('DD/MM/YYYY')}</div>
            </div>
          </div>
          {detailCashRegister.movements.length === 0 ? (
            <div className="h-100 d-flex justify-content-center align-items-center">
              <h6>Sin movimientos</h6>
            </div>
          ) : (
            <div className="table-container">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>Registro</th>
                    <th>Hora</th>
                    <th>Importe</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  {detailCashRegister.movements.map((m: CashRegisterMovement, index: number) => {
                    let details = null;
                    if (m.saleStatus === SaleStatus.Cancelled) {
                      details = `(Reserva cancelada)`;
                    } else if (m.saleStatus === SaleStatus.Return) {
                      details = '(Devolución venta)';
                    } else if (m.saleStatus === SaleStatus.Finalized) {
                      details = '(Venta)';
                    } else if (m.saleStatus === SaleStatus.Reservation) {
                      details = '(Reserva)';
                    } else if (m.repairId) {
                      details = '(Reparación)';
                    } else if (m.withdrawalId) {
                      details = '(Retirada)';
                    }
                    return (
                      <tr key={index}>
                        <td className="link-sale">
                          <div className="d-flex flex-column">
                            {m.saleInternalId ? (
                              <Link to={`/venta/${m.saleId}`} className="">
                                <span className="fw-bold">{m.saleInternalId}</span>
                              </Link>
                            ) : (
                              m.internalReservationId && (
                                <Link to={`/venta/${m.saleId}`} className="">
                                  <span className="fw-bold">{m.internalReservationId}</span>
                                </Link>
                              )
                            )}
                            {m.repairId && (
                              <Link to={`/reparacion/${m.repairId}`} className="">
                                <span className="fw-bold">{m.repairInternalId}</span>
                              </Link>
                            )}
                            {m.withdrawalId && <span className="fw-bold">{m.withdrawalId}</span>}
                            <span>{details}</span>
                          </div>
                        </td>
                        <td>{moment(m.date).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{formatNumber(m.amount)}€</td>
                        <td>
                          {m.paymentMethodType === PaymentMethodType.Cash && (
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="icon-cash me-1"></div>
                              <div>Efectivo</div>
                            </div>
                          )}
                          {m.paymentMethodType === PaymentMethodType.Custom && (
                            <div className="d-flex align-items-center justify-content-center">
                              {m.paymentMethodIsTpv && <div className="icon-tpv me-1"></div>}
                              <div>{m.paymentMethodName}</div>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <div className="r-side">
        <div className="details d-flex flex-column">
          <div className="px-4">
            <div className="mb-3">
              <div className="row container-title noselect align-items-center">
                <div className="col-3 d-flex flex-row align-items-center">
                  {incidence && <div className="title-incidence">Incidencia</div>}
                  {(detailCashRegister.cashRegisterCheck.openingStatus === CashRegisterStatus.Resolved || detailCashRegister.cashRegisterCheck.closingStatus === CashRegisterStatus.Resolved) && (
                    <div className="title-resolved">Resuelto</div>
                  )}
                  {detailCashRegister.cashRegisterCheck.closingStatus === CashRegisterStatus.Incidence &&
                    (user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager) && (
                      <button disabled={requesting} onClick={markAsResolved} className={clsx('mark-as-resolved', { 'ms-2': incidence })}>
                        Resolver
                      </button>
                    )}
                </div>
                <div className="col-6">
                  <h1 className="mb-0 text-center" style={{ fontSize: 22 }}>
                    Arqueo de caja {detailCashRegister.cashRegisterCheck.internalId}
                  </h1>
                </div>
                <div className="col-3 d-flex flex-row justify-content-end">
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                    <button
                      onClick={saveCashRegisterCheck}
                      disabled={disabledSaveButton}
                      className={clsx({
                        disabled: disabledSaveButton,
                        'me-2': detailCashRegister.cashRegisterCheck.type === CashRegisterType.Close,
                      })}
                    >
                      Guardar
                    </button>
                  )}
                  {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.Seller) &&
                    detailCashRegister.cashRegisterCheck.type === CashRegisterType.Close && (
                      <button onClick={downloadTicketCashRegisterCheck} disabled={requestingTicket}>
                        Imprimir
                      </button>
                    )}
                </div>
              </div>
            </div>
            <ContainerOpening openingNotes={openingNotes} setOpeningNotes={setOpeningNotes} cashRegisterCheck={detailCashRegister.cashRegisterCheck} />
            {detailCashRegister.cashRegisterCheck.type === CashRegisterType.Close && (
              <ContainerClose cashRegisterCheck={detailCashRegister.cashRegisterCheck} closingNotes={closingNotes} setClosingNotes={setClosingNotes} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailCashRegisterView;
