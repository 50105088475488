import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { OnlineStoreType } from '../enums/online-store-type';
import { Role } from '../enums/role';
import { OnlineStore } from '../interfaces/online-store';
import { OnlineStoreDto } from '../interfaces/online-store.dto';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import ConfirmModal from './confirm-modal';

enum StockManagement {
  AllStores = 0,
  DefaultStore = 1,
}

interface Props {
  onlineStoreId: number | null;
  show: boolean;
  closeModal: (onlineStore: OnlineStore | null) => void;
}

const defaultValues: OnlineStoreDto = {
  name: '',
  organizationId: -1,
  type: OnlineStoreType.WooCommerce,
  url: '',
  consumerKey: '',
  consumerSecret: '',
  accessToken: null,
  isActive: true,
  defaultStoreId: null,
  useStockRestStores: false,
  payload: {
    warehouseCode: '',
  },
};

const OnlineStoreModal = (props: Props) => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>(null);
  const [stores, setStores] = useState<Store[]>([]);
  const [stockManagement, setStockManagement] = useState<StockManagement>(StockManagement.AllStores);
  const [onlineStore, setOnlineStore] = useState<OnlineStore | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<OnlineStoreDto>({
    mode: 'onSubmit',
    defaultValues,
  });

  const getStores = async () => {
    let strs: Store[] = [];
    if (selectedOrganizationId && selectedOrganizationId > 0) {
      strs = await api.getStores(selectedOrganizationId ?? -1);
    }
    setStores(strs);
  };

  useEffect(() => {
    const getOrganizations = async () => {
      let orgs: Organization[] = await api.getOrganizations();
      orgs = orgs.filter((org: Organization) => org.enabledOnlineStores);
      setOrganizations(orgs);
    };
    getOrganizations();
  }, []);

  useEffect(() => {
    if (props.onlineStoreId) {
      const getOnlineStore = async () => {
        const onlineStore: OnlineStore = await api.getOnlineStore(props.onlineStoreId!);
        if (onlineStore.defaultStoreId && onlineStore.defaultStoreId > 0) {
          setStockManagement(StockManagement.DefaultStore);
        }
        setSelectedOrganizationId(onlineStore.organizationId);
        setOnlineStore(onlineStore);
        reset(onlineStore);
      };
      getOnlineStore();
    }
  }, [props.onlineStoreId, reset]);

  useEffect(() => {
    if (!props.show) {
      reset(defaultValues);
      setStockManagement(StockManagement.AllStores);
    }
  }, [props.show, reset]);

  useEffect(() => {
    getStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  const close = () => {
    reset(defaultValues);
    setStockManagement(StockManagement.AllStores);
    props.closeModal(null);
  };

  const onSubmit = async (data: OnlineStoreDto) => {
    data.organizationId = parseInt(data.organizationId as any, 10);
    if (stockManagement === StockManagement.DefaultStore) {
      data.defaultStoreId = parseInt(data.defaultStoreId as any, 10);
    } else {
      data.defaultStoreId = null;
    }
    data.consumerKey = data.consumerKey!.trim();
    data.consumerSecret = data.consumerSecret!.trim();
    switch (data.type) {
      case OnlineStoreType.Miravia:
        data.payload = JSON.stringify(data.payload) as any;
        break;
      case OnlineStoreType.WooCommerce:
        data.accessToken = null;
        break;
      case OnlineStoreType.Shopify:
        data.accessToken = data.accessToken!.trim();
        break;
    }
    try {
      if (props?.onlineStoreId && props.onlineStoreId > 0) {
        const onlineStore: OnlineStore = await api.updateOnlineStore(props.onlineStoreId!, data);
        myToastr.success('Tienda online actualizada correctamente');
        props.closeModal(onlineStore);
      } else {
        const onlineStore: OnlineStore = await api.createOnlineStore(data);
        myToastr.success('Tienda online creada correctamente');
        props.closeModal(onlineStore);
      }
      reset();
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  return (
    <Modal className="vercomi-modal my-form online-store-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Tienda online</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.name })}>Nombre*</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
            <div className="col">
              <div className={'input-name ' + (errors?.organizationId ? 'error' : '')}>Organización*</div>
              {organizations.length === 0 ? (
                <span className="no-organizations">No hay organizaciones que tengan contratada esta opción.</span>
              ) : (
                <Controller
                  control={control}
                  name="organizationId"
                  rules={{ required: true, min: 1 }}
                  render={({ formState, field }) => {
                    return (
                      <>
                        <select
                          disabled={props.onlineStoreId != null && props.onlineStoreId > 0}
                          onChange={(e) => {
                            field.onChange(e);
                            setSelectedOrganizationId(e.target.value ? parseInt(e.target.value, 10) : null);
                          }}
                          className={clsx({ error: formState.errors?.organizationId })}
                          value={field.value}
                        >
                          <option value={-1}>Selecciona una organización</option>
                          {organizations.map((o: Organization) => (
                            <option key={o.id} value={o.id}>
                              {o.name}
                            </option>
                          ))}
                        </select>
                        {formState.errors?.organizationId && <div className="error-message">{formState.errors?.organizationId.message}</div>}
                      </>
                    );
                  }}
                />
              )}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.type })}>Tipo*</div>
              <select disabled={props.onlineStoreId != null && props.onlineStoreId > 0} {...register('type', { required: true, min: 1 })} className={clsx({ error: errors?.type })}>
                <option value={-1}>Selecciona una opción</option>
                <option value={OnlineStoreType.Miravia}>Miravia</option>
                <option value={OnlineStoreType.Shopify}>Shopify</option>
                <option value={OnlineStoreType.WooCommerce}>WooCommerce</option>
              </select>
              {errors.url && <div className="error-message">{errors.url.message}</div>}
            </div>
            {(watch('type') === OnlineStoreType.Shopify || watch('type') === OnlineStoreType.WooCommerce) && (
              <div className="col-6">
                <div className={clsx('input-name', { error: errors?.name })}>URL*</div>
                <input
                  disabled={props.onlineStoreId != null && props.onlineStoreId > 0 && user.role !== Role.SuperAdmin}
                  type="text"
                  {...register('url', { required: true })}
                  className={clsx({ error: errors?.url })}
                  placeholder="URL"
                />
                {errors.url && <div className="error-message">{errors.url.message}</div>}
              </div>
            )}
            {watch('type') === OnlineStoreType.Miravia && (
              <div className="col">
                <div className={clsx('input-name', { error: (errors?.payload as any)?.warehouseCode })}>Warehouse Code*</div>
                <input type="text" {...register('payload.warehouseCode', { required: true })} className={clsx({ error: (errors?.payload as any)?.warehouseCode })} placeholder="Warehouse Code" />
                {errors.name && <div className="error-message">{(errors.payload as any)?.warehouseCode.message}</div>}
              </div>
            )}
          </div>
          {(watch('type') === OnlineStoreType.Shopify || watch('type') === OnlineStoreType.WooCommerce) && (
            <div className="row my-3">
              <div className="col">
                <div className={clsx('input-name', { error: errors?.consumerKey })}>Consumer Key*</div>
                <input type="text" {...register('consumerKey', { required: true })} className={clsx({ error: errors?.consumerKey })} placeholder="Consumer Key" autoComplete="off" />
                {errors.consumerKey && <div className="error-message">{errors.consumerKey.message}</div>}
              </div>
              <div className="col">
                <div className={clsx('input-name', { error: errors?.consumerSecret })}>Consumer Secret*</div>
                <input type="password" {...register('consumerSecret', { required: true })} className={clsx({ error: errors?.consumerSecret })} placeholder="Consumer Secret" autoComplete="off" />
                {errors.consumerSecret && <div className="error-message">{errors.consumerSecret.message}</div>}
              </div>
            </div>
          )}
          {watch('type') === OnlineStoreType.Shopify && (
            <div className="row my-3">
              <div className="col-6">
                <div className={clsx('input-name', { error: errors?.accessToken })}>Access Token*</div>
                <input type="password" {...register('accessToken', { required: true })} className={clsx({ error: errors?.accessToken })} placeholder="Access Token" autoComplete="off" />
                {errors.accessToken && <div className="error-message">{errors.accessToken.message}</div>}
              </div>
            </div>
          )}
          <div className="row my-3">
            {(watch('type') === OnlineStoreType.Shopify || watch('type') === OnlineStoreType.WooCommerce) && (
              <div className="col-6">
                <div className="input-name">Gestión de la tienda online*</div>
                <select value={stockManagement} onChange={(e) => setStockManagement(parseInt(e.target.value, 10))}>
                  <option value={StockManagement.AllStores}>Todas las tiendas</option>
                  <option value={StockManagement.DefaultStore}>Tienda predeterminada</option>
                </select>
              </div>
            )}
            {stockManagement === StockManagement.DefaultStore && (
              <div className="col-6">
                <div className={clsx('input-name', { error: errors?.defaultStoreId })}>Tienda*</div>
                <Controller
                  control={control}
                  name="defaultStoreId"
                  rules={{ required: true, min: 1 }}
                  render={(props) => {
                    return (
                      <React.Fragment>
                        <select
                          onChange={(e) => {
                            props.field.onChange(e);
                          }}
                          className={clsx({ error: props.formState.errors?.defaultStoreId })}
                          value={props.field.value || ''}
                        >
                          <option value={-1}>Selecciona una tienda</option>
                          {stores.map((s: Store) => (
                            <option key={s.id} value={s.id}>
                              {s.name}
                            </option>
                          ))}
                        </select>
                        {props.formState.errors?.defaultStoreId && <div className="error-message">{props.formState.errors?.defaultStoreId.message}</div>}
                      </React.Fragment>
                    );
                  }}
                />
              </div>
            )}
            {stockManagement === StockManagement.DefaultStore && (
              <div className={clsx('col-6', { 'my-3': stockManagement > 0 })}>
                <input type="checkbox" {...register('useStockRestStores')} className="me-2" />
                <span className="use-stock-rest-stores">En el caso de quedarse sin stock la tienda predeterminada, ¿quiere que las otras tiendas suministren pedidos?</span>
              </div>
            )}
            <div className={clsx('col-6', { 'my-3': stockManagement > 0 })}>
              <div className={clsx('input-name', { error: errors?.isActive })}>Activo</div>
              <input
                type="checkbox"
                {...register('isActive')}
                onChange={(e: any) => {
                  const checked: boolean = e.target.checked;
                  if (onlineStore !== null && onlineStore.isActive && !checked) {
                    setShowConfirmModal(true);
                  } else {
                    setValue('isActive', checked);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
      {onlineStore && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Desactivar la tienda online"
          content={`¿Estás seguro que quieres desactivar la tienda online ${onlineStore.name}? La sincronización de stock y ventas se desactivará.`}
          closeModal={async (result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              setValue('isActive', false);
            } else {
              setValue('isActive', true);
            }
          }}
        ></ConfirmModal>
      )}
    </Modal>
  );
};

export default OnlineStoreModal;
