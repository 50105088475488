import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface UiState {
  collapsed: boolean;
}

const initialState: UiState = {
  collapsed: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleAside: (state: UiState) => {
      state.collapsed = !state.collapsed;
    },
  },
});

export const { toggleAside } = uiSlice.actions;

export default uiSlice.reducer;

export const uiSelector = (state: RootState) => state.ui;
