import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Row, TableState } from 'react-table';
import ellipse from '../assets/images/ellipse.svg';
import ConfirmModal from '../components/confirm-modal';
import DateRangeSelector from '../components/date-range-selector';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import { DateRange } from '../enums/date-range';
import { Role } from '../enums/role';
import { TransferStatus } from '../enums/transfer-status';
import { useStores } from '../hooks/use-stores.hook';
import { DateRangeData } from '../interfaces/date-range-data';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { Transfer } from '../interfaces/transfer';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { getDatesGivenDateRange } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

interface FiltersSessionStorage {
  dateRange: DateRange;
  startDateStr: string | null;
  endDateStr: string | null;
  // Filtros de la tabla
  internalId: string | null;
  fromStore: string | null;
  toStore: string | null;
  createdAt: string | null;
  shippingDate: string | null;
  user: string | null;
  verificationDate: string | null;
  verifyingUser: string | null;
  status: string | null;
}

function TransferStatusColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      <option value={TransferStatus.Draft}>Borrador</option>
      <option value={TransferStatus.Pending}>Pendiente</option>
      <option value={TransferStatus.Completed}>Completado</option>
      <option value={TransferStatus.Incidence}>Incidencia</option>
      <option value={TransferStatus.Resolved}>Resuelto</option>
    </select>
  );
}

const dateRange: DateRange = DateRange.ThisYear;

const TransfersView = () => {
  const navigate = useNavigate();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const tableRef: any = useRef<any>(null);
  const { selectedStoreId, store } = useSelector(storeSelector);
  const [transfers, setTransfers] = useState<Transfer[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [selectedTransfer, setSelectedTransfer] = useState<Transfer | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData | null>(null);
  const [tableFilters, setTableFilters] = useState<{ id: string; value: any }[]>([]);
  const stores: Store[] = useStores();

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Número',
        id: 'internalId',
        accessor: 'internalId',
        Cell: ({ row }: any) => {
          const transfer: Transfer = row.original;
          let to = '';
          if (transfer.status === TransferStatus.Draft) {
            const index: number = user.stores.findIndex((s: Store) => s.id === transfer.fromStoreId);
            if (index === -1) {
              return <span className="link-sale fw-bold cursor-default">{transfer.internalId}</span>;
            }
            to = `/traspaso/${transfer.id}/borrador`;
          } else if (transfer.status === TransferStatus.Pending) {
            if (moment(transfer.shippingDate).isBefore(moment())) {
              to = `/traspaso/${transfer.id}/verificar`;
            } else {
              const index: number = user.stores.findIndex((s: Store) => s.id === transfer.fromStoreId);
              if (index === -1) {
                return <span className="link-sale fw-bold cursor-default">{transfer.internalId}</span>;
              }
              to = `/traspaso/${transfer.id}/pendiente`;
            }
          } else if (transfer.status === TransferStatus.Completed) {
            to = `/traspaso/${transfer.id}`;
          } else if (transfer.status === TransferStatus.Incidence) {
            if (user.role === Role.Seller) {
              return <span className="link-sale fw-bold cursor-default">{transfer.internalId}</span>;
            }
            to = `/traspaso/${transfer.id}`;
          } else if (transfer.status === TransferStatus.Resolved) {
            to = `/traspaso/${transfer.id}`;
          }
          return (
            <Link to={to} className="position-relative link-sale">
              <span className="fw-bold">{transfer.internalId}</span>
              {transfer.notes && transfer.notes.length > 0 && <img src={ellipse} alt="ellipse" className="sale-with-note" />}
            </Link>
          );
        },
      },
      {
        Header: 'Tienda orig.',
        id: 'fromStore',
        accessor: 'fromStore.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Tienda dest.',
        id: 'toStore',
        accessor: 'toStore.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Fecha creación',
        id: 'createdAt',
        accessor: (transfer: Transfer) => moment(transfer.createdAt).format('DD/MM/YYYY HH:mm'),
        filter: 'fuzzyDate',
        Cell: ({ row }: any) => {
          const transfer: Transfer = row.original;
          return moment(transfer.createdAt).format('DD/MM/YYYY HH:mm');
        },
        sortType: (a: any, b: any) => {
          const momentA = moment(a.original.createdAt);
          const momentB = moment(b.original.createdAt);
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      },
      {
        Header: 'Fecha envío',
        id: 'shippingDate',
        accessor: (transfer: Transfer) => moment(transfer.shippingDate).format('DD/MM/YYYY HH:mm'),
        filter: 'fuzzyDate',
        Cell: ({ row }: any) => {
          const transfer: Transfer = row.original;
          return moment(transfer.shippingDate).format('DD/MM/YYYY HH:mm');
        },
      },
      {
        Header: 'Realizado',
        id: 'user',
        accessor: (transfer: Transfer) => {
          const responsable: User = transfer.user;
          return `${responsable.name} ${responsable.surnames}`;
        },
        Filter: SelectColumnFilter,
        Cell: ({ row }: any) => {
          const responsable: User = row.original.user;
          return `${responsable.name} ${responsable.surnames}`;
        },
      },
      {
        Header: 'Fecha verificación',
        id: 'verificationDate',
        accessor: (transfer: Transfer) => (moment(transfer.verificationDate).isValid() ? moment(transfer.verificationDate).format('DD/MM/YYYY HH:mm') : ''),
        filter: 'fuzzyDate',
        Cell: ({ row }: any) => {
          const transfer: Transfer = row.original;
          return moment(transfer.verificationDate).isValid() ? moment(transfer.verificationDate).format('DD/MM/YYYY HH:mm') : '';
        },
      },
      {
        Header: 'Verificado',
        id: 'verifyingUser',
        accessor: (transfer: Transfer) => {
          const verifyingUser: User = transfer.verifyingUser;
          return verifyingUser ? `${verifyingUser.name} ${verifyingUser.surnames}` : null;
        },
        Filter: SelectColumnFilter,
        Cell: ({ row }: any) => {
          const verifyingUser: User = row.original.verifyingUser;
          return verifyingUser ? `${verifyingUser.name} ${verifyingUser.surnames}` : null;
        },
      },
      {
        Header: 'Estado',
        id: 'status',
        accessor: 'status',
        Filter: TransferStatusColumnFilter,
        Cell: ({ value }: any) => {
          let className = '';
          let textOption = '';
          switch (value) {
            case TransferStatus.Draft:
              textOption = 'Borrador';
              className = 'transfer-draft';
              break;
            case TransferStatus.Pending:
              textOption = 'Pendiente';
              className = 'transfer-pending';
              break;
            case TransferStatus.Completed:
              textOption = 'Completado';
              className = 'transfer-completed';
              break;
            case TransferStatus.Incidence:
              textOption = 'Incidencia';
              className = 'transfer-incidence';
              break;
            case TransferStatus.Resolved:
              textOption = 'Resuelto';
              className = 'transfer-resolved';
              break;
          }
          return <span className={`badge ${className}`}>{textOption}</span>;
        },
      },
    ];
    if (user.role === Role.SuperAdmin) {
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => (
          <Trash2
            type="button"
            className="mx-2"
            onClick={() => {
              setSelectedTransfer(row.original);
              setShowConfirmModal(true);
            }}
            size={14}
          />
        ),
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId, transfers]);

  useEffect(() => {
    const value: string | null = sessionStorage.getItem('search-transfers');
    let drd: DateRangeData = {
      dateRange,
      dates: getDatesGivenDateRange(dateRange),
    };
    const tf: { id: string; value: any }[] = [];
    if (value) {
      try {
        const filtersSessionStorage: FiltersSessionStorage = JSON.parse(value);
        drd = {
          dateRange: filtersSessionStorage.dateRange,
          dates: [
            moment(filtersSessionStorage.startDateStr).isValid() ? moment(filtersSessionStorage.startDateStr).toDate() : null,
            moment(filtersSessionStorage.endDateStr).isValid() ? moment(filtersSessionStorage.endDateStr).toDate() : null,
          ],
        };
        if (filtersSessionStorage.internalId) {
          tf.push({ id: 'internalId', value: filtersSessionStorage.internalId });
        }
        if (filtersSessionStorage.fromStore) {
          tf.push({ id: 'fromStore', value: filtersSessionStorage.fromStore });
        }
        if (filtersSessionStorage.toStore) {
          tf.push({ id: 'toStore', value: filtersSessionStorage.toStore });
        }
        if (filtersSessionStorage.createdAt) {
          tf.push({ id: 'createdAt', value: filtersSessionStorage.createdAt });
        }
        if (filtersSessionStorage.shippingDate) {
          tf.push({ id: 'shippingDate', value: filtersSessionStorage.shippingDate });
        }
        if (filtersSessionStorage.user) {
          tf.push({ id: 'user', value: filtersSessionStorage.user });
        }
        if (filtersSessionStorage.verificationDate) {
          tf.push({ id: 'verificationDate', value: filtersSessionStorage.verificationDate });
        }
        if (filtersSessionStorage.verifyingUser) {
          tf.push({ id: 'verifyingUser', value: filtersSessionStorage.verifyingUser });
        }
        if (filtersSessionStorage.status) {
          tf.push({ id: 'status', value: filtersSessionStorage.status });
        }
      } catch (e) {
        sessionStorage.removeItem('search-transfers');
      }
    }
    setTableFilters(tf);
    setDateRangeData(drd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    const getTransfers = async () => {
      setRequesting(true);
      const ts: Transfer[] = await api.getTransfers(dateRangeData.dates[0], dateRangeData.dates[1], organization!.id, selectedStoreId);
      setTransfers(ts);
      setRequesting(false);
    };
    getTransfers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, selectedStoreId]);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    const filtersSessionStorage: FiltersSessionStorage = {
      dateRange: dateRangeData!.dateRange,
      startDateStr: moment(dateRangeData!.dates[0]).format('YYYY-MM-DD'),
      endDateStr: moment(dateRangeData!.dates[1]).format('YYYY-MM-DD'),
      internalId: tableFilters.find((filter: any) => filter.id === 'internalId')?.value || '',
      fromStore: tableFilters.find((filter: any) => filter.id === 'fromStore')?.value || '',
      toStore: tableFilters.find((filter: any) => filter.id === 'toStore')?.value || '',
      createdAt: tableFilters.find((filter: any) => filter.id === 'createdAt')?.value || '',
      shippingDate: tableFilters.find((filter: any) => filter.id === 'shippingDate')?.value || '',
      user: tableFilters.find((filter: any) => filter.id === 'user')?.value || '',
      verificationDate: tableFilters.find((filter: any) => filter.id === 'verificationDate')?.value || '',
      verifyingUser: tableFilters.find((filter: any) => filter.id === 'verifyingUser')?.value || '',
      status: tableFilters.find((filter: any) => filter.id === 'status')?.value || '',
    };
    sessionStorage.setItem('search-transfers', JSON.stringify(filtersSessionStorage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, tableFilters]);

  const deleteTransfer = async (t: Transfer) => {
    try {
      await api.deleteTransfer(t.id);
      const trs: Transfer[] = [...transfers];
      const index: number = trs.findIndex((tr: Transfer) => tr.id === t.id);
      trs.splice(index, 1);
      setTransfers(trs);
      myToastr.success('Traspaso eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const transfer: Transfer = fr.original;
      let statusText = '';
      switch (transfer.status) {
        case TransferStatus.Pending:
          statusText = 'Pendiente';
          break;
        case TransferStatus.Completed:
          statusText = 'Completado';
          break;
        case TransferStatus.Incidence:
          statusText = 'Incidencia';
          break;
        case TransferStatus.Resolved:
          statusText = 'Resuelto';
          break;
      }
      data.push({
        Número: transfer.internalId,
        Tienda: transfer.fromStore.name,
        'Fecha creación': moment(transfer.createdAt).format('DD/MM/YYYY HH:mm'),
        'Fecha envío': moment(transfer.shippingDate).format('DD/MM/YYYY HH:mm'),
        Realizado: `${transfer.user.name} ${transfer.user.surnames}`,
        'Fecha verificación': moment(transfer.verificationDate).isValid() ? moment(transfer.verificationDate).format('DD/MM/YYYY HH:mm') : '',
        Verificado: transfer.verifyingUser ? `${transfer.verifyingUser.name} ${transfer.verifyingUser.surnames}` : '',
        Estado: statusText,
      });
    });
    setCsvData(data);
  };

  const onTableStateChange = (state: TableState<any>) => {
    setTableFilters(state.filters);
  };

  if (!dateRangeData) {
    return null;
  }

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="returns p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Traspasos</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename={store ? `traspasos_${store.name}.csv` : 'traspasos.csv'} className="d-flex align-items-center export-csv" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <button className="d-flex align-items-center create-button mx-3" onClick={() => navigate('/traspaso')}>
          <Plus className="me-1" size={14} /> Nuevo Traspaso
        </button>
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={false}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
        <button
          className="ms-2 clear-filters"
          disabled={requesting}
          onClick={() => {
            tableRef.current?.clearFilters();
            setDateRangeData({
              dateRange,
              dates: getDatesGivenDateRange(dateRange),
            });
          }}
        >
          Limpiar filtros
        </button>
      </div>
      <Table
        ref={tableRef}
        data={transfers}
        columns={columns}
        noDataMessage="No hay traspasos"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          filters: tableFilters,
          sortBy: [{ id: 'createdAt', desc: true }],
          pageSize: Constants.LIMIT_RESULTS,
        }}
        onStateChange={onTableStateChange}
      />
      {selectedTransfer && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Traspaso"
          content={`¿Estás seguro que quieres eliminar el traspaso ${selectedTransfer.id}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteTransfer(selectedTransfer);
            }
            setSelectedTransfer(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default TransfersView;
