import { X } from 'react-feather';
import Modal from 'react-modal';

interface Props {
  title: string;
  content: string;
  show: boolean;
  acceptButtonClass: string;
  disabled?: boolean;
  closeModal: (result: boolean) => void;
}

const ConfirmModal = ({ title, content, show, acceptButtonClass, disabled, closeModal }: Props) => {
  const onAccept = () => {
    closeModal(true);
  };

  const onCancel = () => {
    if (disabled) {
      return;
    }
    closeModal(false);
  };

  return (
    <Modal className="vercomi-modal my-form confirm-modal" isOpen={show} onRequestClose={onCancel} shouldCloseOnOverlayClick={false}>
      <div className="content">
        <div className="d-flex align-items-center">
          <div className="title text-center ms-auto me-auto">{title}</div>
          <div>
            <button type="button" className="close-button-modal mt-0" onClick={onCancel} title="Cerrar">
              <X size={16} />
            </button>
          </div>
        </div>
        <div className="text-center my-4" dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
      <div className="d-flex">
        <button type="button" disabled={disabled} className={`cancel-button`} onClick={onCancel}>
          Cancelar
        </button>
        <button type="button" disabled={disabled} className={acceptButtonClass} onClick={onAccept}>
          Aceptar
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
