import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Edit, Trash2 } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfirmModal from '../components/confirm-modal';
import DateRangeSelector from '../components/date-range-selector';
import ExpirationModal from '../components/expiration-modal';
import Table from '../components/table';
import { DateRange } from '../enums/date-range';
import { ExpirationStatus } from '../enums/expiration-status';
import { DateRangeData } from '../interfaces/date-range-data';
import { Expiration } from '../interfaces/expiration';
import { ExpirationPayment } from '../interfaces/expiration-payment';
import { Organization } from '../interfaces/organization';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { expirationStatusToString, formatNumber, getDatesGivenDateRange } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

interface RowExpiration {
  isPaid: boolean;
  expirationId: number;
  expirationInternalNumber: number;
  expirationExternalNumber: string;
  expirationDate: Date;
  supplierId: number;
  supplierName: string;
  deliveryNoteId: number;
  deliveryNoteInternalNumber: string;
  storeId: number;
  storeName: string;
  numberOfPayments: number;
  total: number;
  expirationPaymentId: number;
  expirationPaymentInternalNumber: string;
  amount: number;
  expirationPaymentDate: Date;
  status: ExpirationStatus;
}

const dateRange: DateRange = DateRange.ThisYear;

const ExpirationsView = () => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { selectedStoreId } = useSelector(storeSelector);
  const tableRef: any = useRef<any>(null);
  const [expirations, setExpirations] = useState<Expiration[]>([]);
  const [filteredRows, setFilteredRows] = useState<RowExpiration[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [selectedExpiration, setSelectedExpiration] = useState<Expiration | null>(null);
  const [showExpirationModal, setShowExpirationModal] = useState<boolean>(false);
  const [showConfirmModalExpiration, setShowConfirmModalExpiration] = useState<boolean>(false);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData>({
    dateRange,
    dates: getDatesGivenDateRange(dateRange),
  });
  const [requesting, setRequesting] = useState<boolean>(false);

  useEffect(() => {
    getExpirations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, selectedStoreId]);

  const data: RowExpiration[] = useMemo(() => {
    const result: any[] = [];
    expirations.forEach((expiration: Expiration) => {
      expiration.expirationPayments.forEach((expirationPayment: ExpirationPayment) => {
        result.push({
          isPaid: expiration.isPaid,
          expirationId: expiration.id,
          expirationInternalNumber: expiration.internalNumber,
          expirationExternalNumber: expiration.externalNumber,
          expirationDate: expiration.date,
          supplierId: expiration.supplierId,
          supplierName: expiration.supplier.name,
          deliveryNoteId: expiration.deliveryNoteId,
          deliveryNoteInternalNumber: expiration.deliveryNote?.internalId,
          storeId: expiration.storeId,
          storeName: expiration.store.name,
          numberOfPayments: expiration.numberOfPayments,
          total: expiration.total,
          expirationPaymentId: expirationPayment.id,
          expirationPaymentInternalNumber: expirationPayment.internalNumber,
          amount: expirationPayment.amount,
          expirationPaymentDate: expirationPayment.expirationDate,
          status: expirationPayment.status,
        });
      });
    });
    return result;
  }, [expirations]);

  const columns = useMemo(
    () => [
      {
        Header: 'Pagado',
        accessor: 'isPaid',
        Cell: ({ value }: any) => {
          return value ? 'Si' : 'No';
        },
      },
      {
        Header: 'Número de la factura',
        accessor: 'expirationExternalNumber',
        filter: 'fuzzyText',
      },
      {
        Header: 'Fecha',
        id: 'expirationDate',
        accessor: (e: any) => moment(e.expirationDate).format('DD/MM/YYYY'),
        filter: 'fuzzyDate',
        Cell: ({ row }: any) => {
          const e: any = row.original;
          return moment(e.expirationDate).format('DD/MM/YYYY');
        },
        sortType: (a: any, b: any) => {
          const momentA = moment(a.original.expirationDate);
          const momentB = moment(b.original.expirationDate);
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      },
      {
        Header: 'Proveedor',
        accessor: 'supplierName',
        Filter: SelectColumnFilter,
        Cell: ({ row }: any) => {
          return row.original.supplierName;
        },
      },
      {
        Header: 'Compra',
        accessor: 'deliveryNoteId',
        filter: 'fuzzyText',
        Cell: ({ value }: any) => {
          return value ? (
            <Link to={`/compra/${value}`} className="link-sale">
              <span className="fw-bold">{value}</span>
            </Link>
          ) : null;
        },
      },
      {
        Header: 'Tienda',
        accessor: 'storeName',
        Filter: SelectColumnFilter,
        Cell: ({ row }: any) => {
          return row.original.storeName;
        },
      },
      {
        Header: 'Número de pagos',
        accessor: 'numberOfPayments',
      },
      {
        Header: 'Total',
        accessor: 'total',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)} €`;
        },
      },
      {
        Header: 'Número de pago',
        accessor: 'expirationPaymentInternalNumber',
      },
      {
        Header: 'Cantidad',
        accessor: 'amount',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)} €`;
        },
      },
      {
        Header: 'Fecha prevista pago',
        accessor: 'expirationPaymentDate',
        Cell: ({ value }: any) => {
          return moment(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: 'Estado',
        accessor: (e: any) => expirationStatusToString(e.status),
        Cell: ({ row }: any) => expirationStatusToString(row.original.status),
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                size={16}
                color="#222E3D"
                onClick={() => {
                  const expiration: Expiration = expirations.find((exp: Expiration) => exp.id === row.original.expirationId)!;
                  setSelectedExpiration(expiration);
                  setShowExpirationModal(true);
                }}
                type="button"
                className="mx-2"
              />
              <Trash2
                type="button"
                className="mx-2"
                onClick={() => {
                  const expiration: Expiration = expirations.find((exp: Expiration) => exp.id === row.original.expirationId)!;
                  setSelectedExpiration(expiration);
                  setShowConfirmModalExpiration(true);
                }}
                size={14}
              />
            </React.Fragment>
          );
        },
      },
    ],
    [expirations],
  );

  const getExpirations = async () => {
    setRequesting(true);
    try {
      const expirations: Expiration[] = await api.getExpirations(dateRangeData.dates[0], dateRangeData.dates[1], organization!.id, selectedStoreId);
      setExpirations(expirations);
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
    setRequesting(false);
  };

  const onExpirationModalClose = (expiration: Expiration | null) => {
    if (expiration) {
      getExpirations();
    }
    setShowExpirationModal(false);
    setSelectedExpiration(null);
  };

  const generateCsv = () => {
    const d: any[] = [];
    filteredRows.forEach((fr: any) => {
      const rowExpiration: RowExpiration = fr.original;
      d.push({
        Pagado: rowExpiration.isPaid ? 'Si' : 'No',
        'Número de la factura': rowExpiration.expirationExternalNumber,
        Fecha: moment(rowExpiration.expirationDate).format('DD/MM/YYYY'),
        Proveedor: rowExpiration.supplierName,
        Compra: rowExpiration?.deliveryNoteInternalNumber ? rowExpiration.deliveryNoteInternalNumber : '',
        Tienda: rowExpiration.storeName,
        'Número de pagos': rowExpiration.numberOfPayments,
        Total: `${formatNumber(rowExpiration.total)} €`,
        'Número de pago': rowExpiration.expirationPaymentInternalNumber,
        Cantidad: `${formatNumber(rowExpiration.amount)} €`,
        'Fecha prevista pago': moment(rowExpiration.expirationPaymentDate).format('DD/MM/YYYY'),
        Estado: expirationStatusToString(rowExpiration.status),
      });
    });
    setCsvData(d);
  };

  const deleteExpiration = async (exp: Expiration) => {
    try {
      await api.deleteExpiration(exp.id);
      const exps: Expiration[] = [...expirations];
      const index: number = exps.findIndex((br: Expiration) => br.id === exp.id);
      exps.splice(index, 1);
      setExpirations(exps);
      myToastr.success('Vencimiento eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  return (
    <div className="expirations p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Vencimientos</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="vencimientos.csv" className="d-flex align-items-center export-csv" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={false}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
        <button
          className="ms-2 clear-filters"
          disabled={requesting}
          onClick={() => {
            tableRef.current?.clearFilters();
            setDateRangeData({
              dateRange,
              dates: getDatesGivenDateRange(dateRange),
            });
          }}
        >
          Limpiar filtros
        </button>
      </div>
      <Table
        ref={tableRef}
        data={data}
        columns={columns}
        noDataMessage="No hay vencimientos"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          sortBy: [{ id: 'expirationDate', desc: true }],
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <ExpirationModal show={showExpirationModal} closeModal={onExpirationModalClose} expiration={selectedExpiration}></ExpirationModal>
      {selectedExpiration && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModalExpiration}
          title="Eliminar Vencimiento"
          content={`¿Estás seguro que quieres eliminar el vencimiento ${selectedExpiration.externalNumber} y todos sus pagos?`}
          closeModal={(result: boolean) => {
            setShowConfirmModalExpiration(false);
            if (result) {
              deleteExpiration(selectedExpiration);
            }
            setSelectedExpiration(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default ExpirationsView;
