export const styleMultiSelectInModal = (hasError: boolean) => {
  const redColor = '#e02760';
  const greenColor = '#26c44b';
  return {
    menu: (provided: any, state: any) => ({
      ...provided,
      background: '#252e3c',
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      border: '1.5px solid white',
      borderRadius: '5px 5px 10px 10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2);',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontFamily: 'Inter-SemiBold',
      color: 'white',
      borderBottom: '1px solid white',
      background: state.isFocused || state.isSelected ? '#26c44b' : 'transparent',
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: 'white',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: hasError ? redColor : 'white',
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      cursor: state.isDisabled ? 'not-allowed' : 'default',
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      pointerEvents: 'all',
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      fontFamily: 'Inter-SemiBold',
      paddingLeft: 10,
      fontSize: 14,
      color: 'gray',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: hasError ? `1px solid ${redColor}` : '1px solid white',
      borderRadius: '10px',
      background: 'transparent',
      boxShadow: 'none !important',
      outline: 'none !important',
      '&:hover': {
        border: state.isDisabled ? '1px solid white' : `1px solid ${greenColor}`,
      },
    }),
    clearIndicator: (provided: any, state: any) => ({
      ...provided,
      color: 'gray',
      '&:hover': {
        color: 'white',
        cursor: 'pointer',
      },
    }),
    loadingMessage: (provided: any, state: any) => ({
      ...provided,
      color: 'white',
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  };
};

export const multiSelectStylesDeliveryNotes = (hasError: boolean) => {
  const redColor = '#e02760';
  const greenColor = '#26c44b';
  return {
    menu: (provided: any, state: any) => ({
      ...provided,
      background: '#252e3c',
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      border: '1.5px solid white',
      borderRadius: '5px 5px 10px 10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2);',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontFamily: 'Inter-SemiBold',
      color: 'white',
      borderBottom: '1px solid white',
      background: state.isFocused || state.isSelected ? '#26c44b' : 'transparent',
    }),
    input: (provided: any, state: any) => ({
      ...provided,
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: hasError ? redColor : 'black',
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      cursor: state.isDisabled ? 'not-allowed' : 'default',
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      pointerEvents: 'all',
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      fontFamily: 'Inter-Regular',
      paddingLeft: 4,
      fontSize: 16,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: hasError ? `1px solid ${redColor}` : '1px solid #808a95',
      borderRadius: '10px',
      background: 'transparent',
      boxShadow: 'none !important',
      outline: 'none !important',
      '&:hover': {
        border: state.isDisabled ? '1px solid gray' : `1px solid ${greenColor}`,
      },
    }),
    clearIndicator: (provided: any, state: any) => ({
      ...provided,
      color: 'gray',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    loadingMessage: (provided: any, state: any) => ({
      ...provided,
      color: 'white',
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  };
};
