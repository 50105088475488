import clsx from 'clsx';
import Fuse from 'fuse.js';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown, ChevronLeft, ChevronUp, Image, Minus, Plus, Search } from 'react-feather';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '../components/confirm-modal';
import { InventoryStatus } from '../enums/inventory-status';
import { Role } from '../enums/role';
import { Category } from '../interfaces/category';
import { Inventory } from '../interfaces/inventory';
import { InventoryProduct } from '../interfaces/inventory-product';
import { InventoryProductDto } from '../interfaces/inventory-product.dto';
import { InventoryDto } from '../interfaces/inventory.dto';
import { Stock } from '../interfaces/stock';
import { SubCategory } from '../interfaces/subcategory';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { setStoreId, storeSelector } from '../store/store-slice';

enum RowStatus {
  All = 'all',
  Inventoried = 'inventoried',
  NotInventoried = 'not-inventoried',
  EnterAmount = 'enter-amount',
  NoIncidence = 'no-incidence',
  Fixed = 'fixed',
  Incidence = 'incidence',
}

enum Column {
  Product = 'product',
  Sku = 'sku',
  Brand = 'brand',
  Collection = 'collection',
  Category = 'category',
  SubCategory = 'subCategory',
}

enum Direction {
  Asc = 'asc',
  Desc = 'desc',
}

enum Mode {
  Gun = 'gun',
  Manual = 'manual',
}

const OFFSET_INFINITE_SCROLL_STOCK = 75;

const InventoryView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const divRef = useRef<any>(null);
  const { selectedStoreId, store } = useSelector(storeSelector);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<RowStatus>(RowStatus.All);
  const [units, setUnits] = useState<{ [productId: number]: number }>({});
  const [stock, setStock] = useState<Stock[]>([]);
  const [filteredStock, setFilteredStock] = useState<Stock[]>([]);
  const [infiniteScrollStock, setInfiniteScrollStock] = useState<Stock[]>([]);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [inventory, setInventory] = useState<Inventory | null>(null);
  const [numIncidences, setNumIncidences] = useState<number>(0);
  const [numIncidencesFixed, setNumIncidencesFixed] = useState<number>(0);
  const [requesting, setRequesting] = useState<boolean>(true);
  const [showFinalizeInventoryModal, setShowFinalizeInventoryModal] = useState<boolean>(false);
  const [showFinalizeInventoryDraftModal, setShowFinalizeInventoryDraftModal] = useState<boolean>(false);
  const [showFixInventoryModal, setShowFixInventoryModal] = useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>(Mode.Manual);
  const inputGunRef = useRef<any>(null);
  const [gunValue, setGunValue] = useState<string>('');
  const [disabledInputGun, setDisabledInputGun] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState<{ column: Column; direction: Direction }>({ column: Column.Product, direction: Direction.Asc });
  const colSpan: number = useMemo(() => {
    let cols = 5;
    if (inventory) {
      cols += 2;
      if (inventory.status === InventoryStatus.Resolved) {
        cols += 1;
      } else if (inventory.status === InventoryStatus.Incidence) {
        cols += 1;
      }
    } else {
      cols += 1;
    }
    return cols;
  }, [inventory]);
  const numFilledProducts: number = useMemo(() => Object.keys(units).length, [units]);
  const numUnitsEntered: number = useMemo(() => {
    let total = 0;
    Object.values(units).forEach((unit: number) => {
      total += unit;
    });
    return total;
  }, [units]);
  const numUnitsSystem: number = useMemo(() => {
    let total = 0;
    if (inventory !== null) {
      filteredStock.forEach((s: Stock) => {
        const inventoryProduct: InventoryProduct | undefined = inventory.inventoryProducts.find((inventoryProduct: InventoryProduct) => inventoryProduct.productId === s.productId);
        if (inventoryProduct) {
          total += inventoryProduct.systemUnits;
        }
      });
    }
    return total;
  }, [inventory, filteredStock]);
  const numUnitsCounted: number = useMemo(() => {
    let total = 0;
    if (inventory !== null) {
      filteredStock.forEach((s: Stock) => {
        const inventoryProduct: InventoryProduct | undefined = inventory.inventoryProducts.find((inventoryProduct: InventoryProduct) => inventoryProduct.productId === s.productId);
        if (inventoryProduct) {
          total += inventoryProduct.countedUnits;
        }
      });
    }
    return total;
  }, [inventory, filteredStock]);
  const numUnitsFinal: number = useMemo(() => {
    let total = 0;
    if (inventory !== null) {
      filteredStock.forEach((s: Stock) => {
        const inventoryProduct: InventoryProduct | undefined = inventory.inventoryProducts.find((inventoryProduct: InventoryProduct) => inventoryProduct.productId === s.productId);
        if (inventoryProduct) {
          total += inventoryProduct.finalUnits;
        }
      });
    }
    return total;
  }, [inventory, filteredStock]);

  useEffect(() => {
    if (params.id) {
      const getInventory = async () => {
        try {
          setRequesting(true);
          const i: Inventory = await api.getInventory(parseInt(params.id!, 10));
          if (user.role === Role.Seller && i.status === InventoryStatus.Incidence) {
            navigate('/inventarios', { replace: true });
            return;
          }
          if (selectedStoreId !== i.storeId) {
            dispatch(setStoreId(i.storeId));
          }
          const u: { [productId: number]: number } = {};
          let inc = 0;
          let incFixed = 0;
          i.inventoryProducts.forEach((ip: InventoryProduct) => {
            u[ip.productId] = ip.countedUnits;
            if (ip.systemUnits !== ip.countedUnits) {
              inc++;
              if (ip.finalUnits != null) {
                incFixed++;
              }
            }
          });
          setInventory(i);
          setUnits(u);
          if (i.status !== InventoryStatus.Draft) {
            setNumIncidences(inc);
            setNumIncidencesFixed(incFixed);
          }
          const repairStock: Stock[] = await api.getRepairStock(i!.storeId, i.brandId, i.collectionId, i.categoryId, i.subCategoryId, '');
          repairStock.forEach((s: Stock) => {
            s.product.categories.sort((a: Category, b: Category) => {
              const aName = a.name.toLowerCase();
              const bName = b.name.toLowerCase();
              if (aName < bName) {
                return -1;
              } else if (aName > bName) {
                return 1;
              } else {
                return 0;
              }
            });
          });
          setStock(repairStock);
          setRequesting(false);
        } catch (e) {
          navigate('/inventarios');
        }
      };
      getInventory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, selectedStoreId]);

  useEffect(() => {
    let fs: Stock[] = [...stock];
    if (searchText.length > 0) {
      const fuse: Fuse<Stock> = new Fuse(fs, {
        keys: ['product.name', 'product.sku', 'product.ean'],
        threshold: 0.2,
        ignoreLocation: true,
      });
      const fuseResult: Fuse.FuseResult<Stock>[] = fuse.search(searchText);
      fs = fuseResult.map((result: Fuse.FuseResult<Stock>) => result.item);
    }
    // Estado
    switch (selectedStatus) {
      case RowStatus.Inventoried:
        if (inventory === null) {
          fs = fs.filter((s: Stock) => {
            return units.hasOwnProperty(s.productId);
          });
        }
        break;
      case RowStatus.NotInventoried:
        if (inventory === null) {
          fs = fs.filter((s: Stock) => {
            return !units.hasOwnProperty(s.productId);
          });
        }
        break;
      case RowStatus.EnterAmount:
        if (inventory !== null) {
          fs = fs.filter((s: Stock) => {
            const inventoryProduct: InventoryProduct | undefined = inventory.inventoryProducts.find((ip: InventoryProduct) => ip.productId === s.productId);
            if (inventoryProduct === undefined) {
              return false;
            } else {
              return !units.hasOwnProperty(s.productId);
            }
          });
        }
        break;
      case RowStatus.NoIncidence:
        if (inventory !== null) {
          fs = fs.filter((s: Stock) => {
            const inventoryProduct: InventoryProduct | undefined = inventory.inventoryProducts.find((ip: InventoryProduct) => ip.productId === s.productId);
            if (inventoryProduct === undefined) {
              return false;
            } else {
              return (
                units.hasOwnProperty(s.productId) &&
                inventoryProduct.systemUnits === inventoryProduct.countedUnits &&
                inventoryProduct.finalUnits === null &&
                inventoryProduct.countedUnits === units[s.productId]
              );
            }
          });
        }
        break;
      case RowStatus.Fixed:
        if (inventory !== null) {
          fs = fs.filter((s: Stock) => {
            const inventoryProduct: InventoryProduct | undefined = inventory.inventoryProducts.find((ip: InventoryProduct) => ip.productId === s.productId);
            if (inventoryProduct === undefined) {
              return false;
            } else {
              return (
                (units.hasOwnProperty(s.productId) && inventoryProduct.systemUnits === inventoryProduct.countedUnits && inventoryProduct.countedUnits !== units[s.productId]) ||
                (units.hasOwnProperty(s.productId) &&
                  inventoryProduct.systemUnits !== inventoryProduct.countedUnits &&
                  inventoryProduct.countedUnits !== units[s.productId] &&
                  inventoryProduct.finalUnits == null) ||
                inventoryProduct.finalUnits !== null
              );
            }
          });
        }
        break;
      case RowStatus.Incidence:
        if (inventory !== null) {
          fs = fs.filter((s: Stock) => {
            const inventoryProduct: InventoryProduct | undefined = inventory.inventoryProducts.find((ip: InventoryProduct) => ip.productId === s.productId);
            if (inventoryProduct === undefined) {
              return false;
            } else {
              return (
                units.hasOwnProperty(s.productId) &&
                inventoryProduct.systemUnits !== inventoryProduct.countedUnits &&
                inventoryProduct.countedUnits === units[s.productId] &&
                inventoryProduct.finalUnits == null
              );
            }
          });
        }
        break;
      default:
        break;
    }
    // Inventario
    if (inventory && inventory.status !== InventoryStatus.Draft) {
      fs = fs.filter((s: Stock) => {
        const inventoryProduct = inventory.inventoryProducts.find((ip: InventoryProduct) => ip.productId === s.productId);
        return inventoryProduct !== undefined;
      });
    }
    fs.sort((a: Stock, b: Stock) => {
      switch (sortColumn.column) {
        case Column.Product:
          const aName: string = a.product.name.toLowerCase();
          const bName: string = b.product.name.toLowerCase();
          if (sortColumn.direction === Direction.Asc) {
            if (aName < bName) {
              return -1;
            } else if (aName > bName) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (aName < bName) {
              return 1;
            } else if (aName > bName) {
              return -1;
            } else {
              return 0;
            }
          }
        case Column.Sku:
          const aSku: string = a.product.sku.toLowerCase();
          const bSku: string = b.product.sku.toLowerCase();
          if (sortColumn.direction === Direction.Asc) {
            if (aSku < bSku) {
              return -1;
            } else if (aSku > bSku) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (aSku < bSku) {
              return 1;
            } else if (aSku > bSku) {
              return -1;
            } else {
              return 0;
            }
          }
        case Column.Brand:
          const aBrand: string = a.product.brand.name.toLowerCase();
          const bBrand: string = b.product.brand.name.toLowerCase();
          if (sortColumn.direction === Direction.Asc) {
            if (aBrand < bBrand) {
              return -1;
            } else if (aBrand > bBrand) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (aBrand < bBrand) {
              return 1;
            } else if (aBrand > bBrand) {
              return -1;
            } else {
              return 0;
            }
          }
        case Column.Collection:
          const aCollection: string = a.product.collection.name.toLowerCase();
          const bCollection: string = b.product.collection.name.toLowerCase();
          if (sortColumn.direction === Direction.Asc) {
            if (aCollection < bCollection) {
              return -1;
            } else if (aCollection > bCollection) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (aCollection < bCollection) {
              return 1;
            } else if (aCollection > bCollection) {
              return -1;
            } else {
              return 0;
            }
          }
        case Column.Category:
          const aCategory: string = a.product.categories
            .map((c: Category) => c.name)
            .join(', ')
            .toLowerCase();
          const bCategory: string = b.product.categories
            .map((c: Category) => c.name)
            .join(', ')
            .toLowerCase();
          if (sortColumn.direction === Direction.Asc) {
            if (aCategory < bCategory) {
              return -1;
            } else if (aCategory > bCategory) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (aCategory < bCategory) {
              return 1;
            } else if (aCategory > bCategory) {
              return -1;
            } else {
              return 0;
            }
          }
        case Column.SubCategory:
          const aSubCategory: string = a.product.subCategories
            .map((sc: SubCategory) => sc.name)
            .join(', ')
            .toLowerCase();
          const bSubCategory: string = b.product.subCategories
            .map((sc: SubCategory) => sc.name)
            .join(', ')
            .toLowerCase();
          if (sortColumn.direction === Direction.Asc) {
            if (aSubCategory < bSubCategory) {
              return -1;
            } else if (aSubCategory > bSubCategory) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (aSubCategory < bSubCategory) {
              return 1;
            } else if (aSubCategory > bSubCategory) {
              return -1;
            } else {
              return 0;
            }
          }
        default:
          const aName2: string = a.product.name.toLowerCase();
          const bName2: string = b.product.name.toLowerCase();
          if (sortColumn.direction === Direction.Asc) {
            if (aName2 < bName2) {
              return -1;
            } else if (aName2 > bName2) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (aName2 < bName2) {
              return 1;
            } else if (aName2 > bName2) {
              return -1;
            } else {
              return 0;
            }
          }
      }
    });
    setFilteredStock(fs);
    setInfiniteScrollStock(fs.slice(0, OFFSET_INFINITE_SCROLL_STOCK));
  }, [stock, searchText, selectedStatus, units, inventory, sortColumn.column, sortColumn.direction]);

  useEffect(() => {
    if (!divRef.current) {
      return;
    }
    divRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [filteredStock]);

  useEffect(() => {
    if (mode === Mode.Manual || !inputGunRef.current) {
      return;
    }
    inputGunRef.current.focus();
  }, [mode, inputGunRef]);

  const updateUnits = (productId: number, value: number | null) => {
    const u: { [productId: number]: number } = { ...units };
    if (value === null) {
      delete u[productId];
    } else {
      u[productId] = value;
    }
    setUnits({ ...u });
  };

  const incrementUnits = (productId: number) => {
    const u: { [productId: number]: number } = { ...units };
    if (u.hasOwnProperty(productId)) {
      u[productId] = u[productId] + 1;
    } else {
      u[productId] = 1;
    }
    setUnits({ ...u });
  };

  const decrementUnits = (productId: number) => {
    const u: { [productId: number]: number } = { ...units };
    if (u.hasOwnProperty(productId)) {
      u[productId] = u[productId] - 1;
      if (u[productId] < 0) {
        delete u[productId];
      }
    } else {
      u[productId] = 0;
    }
    setUnits({ ...u });
  };

  const resetValues = () => {
    setShowResetModal(true);
  };

  const initializeValues = () => {
    const u: { [productId: number]: number } = { ...units };
    for (const s of filteredStock) {
      if (!u.hasOwnProperty(s.productId)) {
        u[s.productId] = 0;
      }
    }
    setUnits(u);
  };

  const deleteInventory = async () => {
    try {
      setRequesting(true);
      await api.deleteInventory(inventory!.id);
      myToastr.success('Inventario eliminado');
      navigate('/inventarios');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
      setRequesting(false);
    }
  };

  const save = async () => {
    setRequesting(true);
    const inventoryProducts: InventoryProductDto[] = [];
    for (const productId in units) {
      if (units.hasOwnProperty(productId)) {
        inventoryProducts.push({
          productId: parseInt(productId, 10),
          countedUnits: 0,
          finalUnits: units[productId],
        });
      }
    }
    const inventoryDto: InventoryDto = {
      notes: inventory!.notes || '',
      storeId: inventory!.storeId,
      inventoryProducts,
      brandId: inventory!.brandId,
      collectionId: inventory!.collectionId,
      categoryId: inventory!.categoryId,
      subCategoryId: inventory!.subCategoryId,
      isDraft: false,
    };
    myToastr.info('Actualizando el inventario. Esta operación puede llevar unos segundos. Espere por favor...');
    try {
      await api.updateInventory(inventory!.id, inventoryDto);
      myToastr.success('Inventario actualizado');
      navigate('/inventarios');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    } finally {
      setRequesting(false);
    }
  };

  const saveDraft = async (isDraft: boolean = true) => {
    setRequesting(true);
    const inventoryProducts: InventoryProductDto[] = [];
    for (const productId in units) {
      if (units.hasOwnProperty(productId)) {
        inventoryProducts.push({
          productId: parseInt(productId, 10),
          countedUnits: units[productId],
          finalUnits: 0,
        });
      }
    }
    const inventoryDto: InventoryDto = {
      notes: inventory!.notes || '',
      storeId: inventory!.storeId,
      inventoryProducts,
      brandId: inventory!.brandId,
      collectionId: inventory!.collectionId,
      categoryId: inventory!.categoryId,
      subCategoryId: inventory!.subCategoryId,
      isDraft,
    };
    myToastr.info('Actualizando el borrador del inventario...');
    try {
      await api.updateDraftInventory(inventory!.id, inventoryDto);
      myToastr.success('Inventario actualizado');
      navigate('/inventarios');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (disabledInputGun) {
      return;
    }
    inputGunRef.current?.focus();
  }, [disabledInputGun]);

  const onScannedProduct = (value: string) => {
    if (disabledInputGun) {
      myToastr.warning('Cierra la alerta antes de continuar');
      return;
    }
    const index: number = filteredStock.findIndex((s) => s.product.ean === value || s.product.internalEans[0].ean === value);
    if (index === -1) {
      setDisabledInputGun(true);
      myToastr.error(`El producto con código <strong>${value}</strong> no se encuentra en el listado`, 'Error', {
        closeButton: true,
        timeOut: 0,
        extendedTimeOut: 0,
        tapToDismiss: false,
        onCloseClick: () => setDisabledInputGun(false),
      });
    } else {
      const s: Stock = filteredStock[index];
      const u: { [productId: number]: number } = { ...units };
      if (u.hasOwnProperty(s.productId)) {
        u[s.productId]++;
      } else {
        u[s.productId] = 1;
      }
      setUnits({ ...u });
      myToastr.success(`Se ha añadido el producto <strong>${s.product.name}</strong> al inventario`);
    }
    setGunValue('');
  };

  const loadMoreStock = () => setInfiniteScrollStock(filteredStock.slice(0, infiniteScrollStock.length + OFFSET_INFINITE_SCROLL_STOCK));

  if (!store) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <h1>Selecciona una tienda</h1>
      </div>
    );
  }

  if (requesting) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <Loader type="TailSpin" color="#252E3C" height={50} width={50} />
      </div>
    );
  }

  return (
    <div className="inventories-view position-relative h-100">
      <div className="d-flex flex-column h-100">
        <div className="d-flex flex-row align-items-center mb-2">
          <Link to="/inventarios" className="d-flex flex-row align-items-center come-back">
            <ChevronLeft className="me-1" size={14} />
            <span>Volver</span>
          </Link>
          <h4 className="ms-4 my-0 flex-grow-1">{inventory ? `Inventario ${inventory?.internalId}` : `Nuevo inventario - ${store.name}`}</h4>
          <React.Fragment>
            <select
              name="mode"
              onChange={(e) => {
                const newMode: Mode = e.target.value as Mode;
                setMode(newMode);
              }}
              className="select-mode"
            >
              <option value={Mode.Manual}>Modo Manual</option>
              <option value={Mode.Gun}>Modo Pistola</option>
            </select>
            {mode === Mode.Gun && (
              <input
                type="text"
                ref={inputGunRef}
                value={gunValue}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    onScannedProduct(e.target.value);
                  }
                }}
                onChange={(e) => setGunValue(e.target.value)}
                onBlur={() => inputGunRef.current?.focus()}
                disabled={disabledInputGun}
                className="ms-2 input-mode"
              />
            )}
          </React.Fragment>
        </div>
        {inventory && (
          <div className="d-flex flex-row align-items-center container-info my-2">
            <div className="store">
              <strong>Fecha:</strong>
              <span className="ms-1">{moment(inventory.createdAt).format('DD/MM/YYYY HH:mm')}</span>
            </div>
            <div className="user">
              <strong>Creado por:</strong>
              <span className="ms-1">
                {inventory.user.name} {inventory.user.surnames}
              </span>
            </div>
            <div className="store">
              <strong>Tienda:</strong>
              <span className="ms-1">{store.name}</span>
            </div>
            {inventory.brand && (
              <div className="store">
                <strong>Marca:</strong>
                <span className="ms-1">{inventory.brand.name}</span>
              </div>
            )}
            {inventory.collection && (
              <div className="store">
                <strong>Colección:</strong>
                <span className="ms-1">{inventory.collection.name}</span>
              </div>
            )}
            {inventory.category && (
              <div className="store">
                <strong>Categoría:</strong>
                <span className="ms-1">{inventory.category.name}</span>
              </div>
            )}
            {inventory.subCategory && (
              <div className="store">
                <strong>Subcategoría:</strong>
                <span className="ms-1">{inventory.subCategory.name}</span>
              </div>
            )}
            {inventory.status !== InventoryStatus.Completed && inventory.status !== InventoryStatus.Draft && (
              <div className="incidence">
                <strong>Incidencias:</strong>
                <span className="ms-1">{numIncidences}</span>
              </div>
            )}
            {inventory.status === InventoryStatus.Resolved && (
              <div className="fixed-incidence">
                <strong>Incidencias corregidas:</strong>
                <span className="ms-1">{numIncidencesFixed}</span>
              </div>
            )}
          </div>
        )}
        <div className="container-filters d-flex flex-row justify-content-between my-2">
          <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Buscar producto" style={{ width: 500 }} className="" />
          <select disabled={mode === Mode.Gun} name="status" className="mx-1" onChange={(e: any) => setSelectedStatus(e.target.value as RowStatus)}>
            <option value={RowStatus.All}>Todos los estados</option>
            {inventory !== null ? (
              <React.Fragment>
                <option value={RowStatus.EnterAmount}>Introduce cantidad</option>
                <option value={RowStatus.NoIncidence}>Sin incidencia</option>
                {inventory.status !== InventoryStatus.Draft && <option value={RowStatus.Fixed}>Corregido</option>}
                {inventory.status !== InventoryStatus.Draft && <option value={RowStatus.Incidence}>Incidencia</option>}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <option value={RowStatus.Inventoried}>Inventariado</option>
                <option value={RowStatus.NotInventoried}>No inventariado</option>
              </React.Fragment>
            )}
          </select>
        </div>
        <React.Fragment>
          <div className="container-table flex-grow-1 my-2" id="scrollableDiv" ref={divRef}>
            <InfiniteScroll
              dataLength={infiniteScrollStock.length}
              next={loadMoreStock}
              hasMore={infiniteScrollStock.length < filteredStock.length}
              loader={
                <div className="text-center">
                  <Loader type="TailSpin" color="#252E3C" height={25} width={25} />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              <table>
                <thead>
                  <tr>
                    <th
                      className="text-start cursor-pointer"
                      onClick={() => {
                        if (sortColumn.column === Column.Product) {
                          setSortColumn({ column: Column.Product, direction: sortColumn.direction === Direction.Asc ? Direction.Desc : Direction.Asc });
                        } else {
                          setSortColumn({ column: Column.Product, direction: Direction.Asc });
                        }
                      }}
                    >
                      {sortColumn.column === Column.Product && sortColumn.direction === Direction.Asc && <ChevronUp size={14} className="me-1" />}
                      {sortColumn.column === Column.Product && sortColumn.direction === Direction.Desc && <ChevronDown size={14} className="me-1" />}
                      <span>Producto</span>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => {
                        if (sortColumn.column === Column.Sku) {
                          setSortColumn({ column: Column.Sku, direction: sortColumn.direction === Direction.Asc ? Direction.Desc : Direction.Asc });
                        } else {
                          setSortColumn({ column: Column.Sku, direction: Direction.Asc });
                        }
                      }}
                    >
                      {sortColumn.column === Column.Sku && sortColumn.direction === Direction.Asc && <ChevronUp size={14} className="me-1" />}
                      {sortColumn.column === Column.Sku && sortColumn.direction === Direction.Desc && <ChevronDown size={14} className="me-1" />}
                      <span>SKU</span>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => {
                        if (sortColumn.column === Column.Brand) {
                          setSortColumn({ column: Column.Brand, direction: sortColumn.direction === Direction.Asc ? Direction.Desc : Direction.Asc });
                        } else {
                          setSortColumn({ column: Column.Brand, direction: Direction.Asc });
                        }
                      }}
                    >
                      {sortColumn.column === Column.Brand && sortColumn.direction === Direction.Asc && <ChevronUp size={14} className="me-1" />}
                      {sortColumn.column === Column.Brand && sortColumn.direction === Direction.Desc && <ChevronDown size={14} className="me-1" />}
                      <span>Marca</span>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => {
                        if (sortColumn.column === Column.Collection) {
                          setSortColumn({ column: Column.Collection, direction: sortColumn.direction === Direction.Asc ? Direction.Desc : Direction.Asc });
                        } else {
                          setSortColumn({ column: Column.Collection, direction: Direction.Asc });
                        }
                      }}
                    >
                      {sortColumn.column === Column.Collection && sortColumn.direction === Direction.Asc && <ChevronUp size={14} className="me-1" />}
                      {sortColumn.column === Column.Collection && sortColumn.direction === Direction.Desc && <ChevronDown size={14} className="me-1" />}
                      <span>Collection</span>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => {
                        if (sortColumn.column === Column.Category) {
                          setSortColumn({ column: Column.Category, direction: sortColumn.direction === Direction.Asc ? Direction.Desc : Direction.Asc });
                        } else {
                          setSortColumn({ column: Column.Category, direction: Direction.Asc });
                        }
                      }}
                    >
                      {sortColumn.column === Column.Category && sortColumn.direction === Direction.Asc && <ChevronUp size={14} className="me-1" />}
                      {sortColumn.column === Column.Category && sortColumn.direction === Direction.Desc && <ChevronDown size={14} className="me-1" />}
                      <span>Categoría</span>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => {
                        if (sortColumn.column === Column.SubCategory) {
                          setSortColumn({ column: Column.SubCategory, direction: sortColumn.direction === Direction.Asc ? Direction.Desc : Direction.Asc });
                        } else {
                          setSortColumn({ column: Column.SubCategory, direction: Direction.Asc });
                        }
                      }}
                    >
                      {sortColumn.column === Column.SubCategory && sortColumn.direction === Direction.Asc && <ChevronUp size={14} className="me-1" />}
                      {sortColumn.column === Column.SubCategory && sortColumn.direction === Direction.Desc && <ChevronDown size={14} className="me-1" />}
                      <span>Subcategoría</span>
                    </th>
                    {inventory && inventory.status !== InventoryStatus.Draft && (
                      <th className="text-center">
                        <div className="d-flex flex-column">
                          <span>Uds. Sistema</span>
                          <span>{numUnitsSystem}</span>
                        </div>
                      </th>
                    )}
                    {inventory && inventory.status !== InventoryStatus.Draft && (
                      <th className="text-center">
                        <div className="d-flex flex-column">
                          <span>Uds. Introducidas</span>
                          <span>{numUnitsCounted}</span>
                        </div>
                      </th>
                    )}
                    {inventory && inventory.status === InventoryStatus.Resolved && (
                      <th className="text-center">
                        <div className="d-flex flex-column">
                          <span>Uds. Finales</span>
                          <span>{numUnitsFinal}</span>
                        </div>
                      </th>
                    )}
                    {(!inventory || inventory.status === InventoryStatus.Incidence || inventory.status === InventoryStatus.Draft) && (
                      <th className="text-center">
                        <div className="d-flex flex-column">
                          <span>Unidades</span>
                          <span>{numUnitsEntered}</span>
                        </div>
                      </th>
                    )}
                    <th className="text-center">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredStock.length === 0 ? (
                    <tr className="text-center">
                      <td colSpan={colSpan}>{stock.length === 0 ? 'La tienda no tiene productos en su stock' : 'No hay productos disponibles con los filtros seleccionados'}</td>
                    </tr>
                  ) : (
                    infiniteScrollStock.map((s: Stock) => {
                      let image: string | null = null;
                      if (s.product.images.length > 0) {
                        image = s.product.images[0].path;
                      }
                      let inventoryProduct: InventoryProduct | undefined = undefined;
                      if (inventory && inventory.status !== InventoryStatus.Draft) {
                        inventoryProduct = inventory.inventoryProducts.find((ip: InventoryProduct) => ip.productId === s.productId);
                      }
                      const productName = s.product.name.length > 85 ? `${s.product.name.substring(0, 85)}...` : s.product.name;
                      return (
                        <tr key={s.productId}>
                          <td className="text-start">
                            <div className="d-flex align-items-center container-product">
                              {s.product.images.length > 0 ? (
                                <>
                                  <div
                                    style={{ cursor: 'zoom-in' }}
                                    onMouseEnter={() => {
                                      setImageUrl(image);
                                    }}
                                    onMouseLeave={() => {
                                      setImageUrl(null);
                                    }}
                                  >
                                    <Search className="me-3" size={16} color="#808A95" />
                                  </div>
                                  <LazyLoadImage className="me-3" src={`${process.env.REACT_APP_PUBLIC_URL}${s.product.images[0].path}`} alt={s.product.name} />
                                </>
                              ) : (
                                <Image style={{ marginLeft: '32px', backgroundColor: 'white', borderRadius: '6px' }} className="me-3" size={35} color="#808A95" />
                              )}
                              <span>{productName}</span>
                            </div>
                          </td>
                          <td className="text-center">{s.product.sku}</td>
                          <td className="text-center">{s.product.brand?.name}</td>
                          <td className="text-center">{s.product.collection?.name}</td>
                          <td className="text-center">{s.product.categories.map((c: Category) => c.name).join(', ')}</td>
                          <td className="text-center">{s.product.subCategories.map((sc: SubCategory) => sc.name).join(', ')}</td>
                          {inventoryProduct && <td className="text-center">{inventoryProduct.systemUnits}</td>}
                          {inventoryProduct && (
                            <td className={clsx('text-center', { 'counted-units-incidence': inventoryProduct.systemUnits !== inventoryProduct.countedUnits })}>{inventoryProduct.countedUnits}</td>
                          )}
                          {inventoryProduct && inventory?.status === InventoryStatus.Resolved && <td className="text-center">{inventoryProduct.finalUnits}</td>}
                          {(!inventory || inventory.status === InventoryStatus.Incidence || inventory.status === InventoryStatus.Draft) && (
                            <td className="text-center">
                              <div className="d-flex align-items-center justify-content-center container-units">
                                {mode === Mode.Manual && <Minus onClick={() => decrementUnits(s.productId)} className="clickable me-2" size={14} color="#535b66" />}
                                <input
                                  className={clsx({
                                    'fw-bold text-danger':
                                      inventoryProduct &&
                                      units.hasOwnProperty(s.productId) &&
                                      inventoryProduct.systemUnits !== inventoryProduct.countedUnits &&
                                      inventoryProduct.countedUnits === units[s.productId],
                                  })}
                                  type="number"
                                  step={1}
                                  min={0}
                                  value={units.hasOwnProperty(s.productId) ? units[s.productId] : ''}
                                  onChange={(e: any) => {
                                    let units = null;
                                    if (e.target.value) {
                                      units = parseInt(e.target.value, 10);
                                      if (isNaN(units)) {
                                        units = null;
                                      } else if (units < 0) {
                                        units = 0;
                                      }
                                    }
                                    updateUnits(s.productId, units);
                                  }}
                                  disabled={mode === Mode.Gun}
                                />
                                {mode === Mode.Manual && <Plus onClick={() => incrementUnits(s.productId)} className="clickable ms-2" size={14} color="#535b66" />}
                              </div>
                            </td>
                          )}
                          <td className="text-center">
                            <div className="d-flex justify-content-center">
                              {/* NUEVO INVENTARIO */}
                              {(!inventory || inventory.status === InventoryStatus.Draft) && !inventoryProduct && units.hasOwnProperty(s.productId) && (
                                <div className="filled-icon" title="Inventariado"></div>
                              )}
                              {(!inventory || inventory.status === InventoryStatus.Draft) && !inventoryProduct && !units.hasOwnProperty(s.productId) && (
                                <div className="empty-icon" title="No inventariado"></div>
                              )}
                              {/* CORREGIR INVENTARIO */}
                              {inventory !== null && inventory.status !== InventoryStatus.Draft && inventoryProduct && !units.hasOwnProperty(s.productId) && (
                                <div className="empty-icon" title="Introduce cantidad"></div>
                              )}
                              {inventory !== null &&
                                inventory.status !== InventoryStatus.Draft &&
                                inventoryProduct &&
                                units.hasOwnProperty(s.productId) &&
                                inventoryProduct.systemUnits === inventoryProduct.countedUnits &&
                                inventoryProduct.finalUnits === null &&
                                inventoryProduct.countedUnits === units[s.productId] && <div className="same-quantity-icon" title="Sin incidencia"></div>}
                              {/* CUADRAN LAS UNIDADES PERO EL STORE ADMIN QUIERE CAMBIAR LAS UNIDADES EN EL STOCK */}
                              {inventory !== null &&
                                inventory.status !== InventoryStatus.Draft &&
                                inventoryProduct &&
                                units.hasOwnProperty(s.productId) &&
                                inventoryProduct.systemUnits === inventoryProduct.countedUnits &&
                                inventoryProduct.countedUnits !== units[s.productId] && <div className="fixed-icon" title="Corregido"></div>}
                              {inventory !== null &&
                                inventory.status !== InventoryStatus.Draft &&
                                inventoryProduct &&
                                units.hasOwnProperty(s.productId) &&
                                inventoryProduct.systemUnits !== inventoryProduct.countedUnits &&
                                inventoryProduct.countedUnits === units[s.productId] &&
                                inventoryProduct.finalUnits == null && <div className="incidence-icon" title="Incidencia"></div>}
                              {inventory !== null &&
                                inventory.status !== InventoryStatus.Draft &&
                                inventoryProduct &&
                                units.hasOwnProperty(s.productId) &&
                                inventoryProduct.systemUnits !== inventoryProduct.countedUnits &&
                                inventoryProduct.countedUnits !== units[s.productId] &&
                                inventoryProduct.finalUnits == null && <div className="fixed-icon" title="Corregido"></div>}
                              {inventory !== null && inventory.status !== InventoryStatus.Draft && inventoryProduct && inventoryProduct.finalUnits !== null && (
                                <div className="fixed-icon" title="Corregido"></div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
          <div className="mt-auto container-buttons">
            <div className="d-flex justify-content-end">
              {inventory && (inventory.status === InventoryStatus.Draft || inventory.status === InventoryStatus.Incidence) && (
                <button disabled={requesting} onClick={deleteInventory} className="delete-button me-2">
                  Eliminar
                </button>
              )}
              {(!inventory || inventory.status === InventoryStatus.Draft) && (
                <button onClick={resetValues} className="reset-values">
                  Reiniciar
                </button>
              )}
              {(!inventory || inventory.status === InventoryStatus.Draft) && (
                <button onClick={initializeValues} className="initialize-values">
                  Poner resto de uds. a cero
                </button>
              )}
              {(!inventory || inventory.status === InventoryStatus.Draft) && (
                <button onClick={() => saveDraft()} disabled={requesting || ((!inventory || inventory.status === InventoryStatus.Draft) && numFilledProducts === 0)} className="draft-button me-2">
                  Guardar borrador
                </button>
              )}
              {(!inventory || inventory.status === InventoryStatus.Incidence || inventory.status === InventoryStatus.Draft) && (
                <button
                  onClick={() => {
                    if (!inventory) {
                      setShowFinalizeInventoryModal(true);
                    } else {
                      if (inventory.status === InventoryStatus.Draft) {
                        setShowFinalizeInventoryDraftModal(true);
                      } else {
                        setShowFixInventoryModal(true);
                      }
                    }
                  }}
                  disabled={
                    requesting ||
                    (!inventory && numFilledProducts === 0) ||
                    (inventory != null && inventory.status === InventoryStatus.Draft && numFilledProducts === 0) ||
                    (inventory != null && inventory.status === InventoryStatus.Incidence && inventory.inventoryProducts.length !== numFilledProducts)
                  }
                  className="finalize-button"
                >
                  {inventory && inventory.status !== InventoryStatus.Draft ? 'Corregir inventario' : 'Finalizar Inventario'}
                </button>
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
      {imageUrl != null && (
        <div className="position-absolute top-50 start-50 translate-middle preview-image">
          <div className="d-flex justify-content-center">
            <LazyLoadImage src={process.env.REACT_APP_PUBLIC_URL + imageUrl} alt="" />
          </div>
        </div>
      )}
      <ConfirmModal
        acceptButtonClass="accept-button"
        show={showFinalizeInventoryModal}
        title="Finalizar Inventario"
        content="¿Estás seguro que deseas finalizar el inventario?"
        closeModal={(result: boolean) => {
          setShowFinalizeInventoryModal(false);
          if (result) {
            save();
          }
        }}
      ></ConfirmModal>
      <ConfirmModal
        acceptButtonClass="accept-button"
        show={showFinalizeInventoryDraftModal}
        title="Finalizar Inventario"
        content="¿Estás seguro que deseas finalizar el inventario en estado borrador?"
        closeModal={(result: boolean) => {
          setShowFinalizeInventoryDraftModal(false);
          if (result) {
            saveDraft(false);
          }
        }}
      ></ConfirmModal>
      <ConfirmModal
        acceptButtonClass="accept-button"
        show={showFixInventoryModal}
        title="Corregir Inventario"
        content="¿Estás seguro que deseas corregir el inventario?"
        closeModal={(result: boolean) => {
          setShowFixInventoryModal(false);
          if (result) {
            save();
          }
        }}
      ></ConfirmModal>
      <ConfirmModal
        acceptButtonClass="accept-button"
        show={showResetModal}
        title="Corregir Inventario"
        content="¿Estás seguro que deseas reiniciar el inventario? Este proceso no se puede deshacer."
        closeModal={(result: boolean) => {
          setShowResetModal(false);
          if (result) {
            const u: { [productId: number]: number } = {};
            setUnits(u);
          }
        }}
      ></ConfirmModal>
    </div>
  );
};

export default InventoryView;
