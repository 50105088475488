import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { Image, Repeat } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SaleStep } from '../enums/sale-step';
import { ManualSaleProduct } from '../interfaces/manual-sale-product';
import { SaleProduct } from '../interfaces/sale-product';
import { formatNumber } from '../services/helpers';
import {
  addManualProductToReturn,
  addProductToReturn,
  removeManualProductToReturn,
  removeProductToReturn,
  saleSelector,
  toggleSelectedManualProduct,
  toggleSelectedProduct,
} from '../store/sale-slice';
import ProductUnitsInOrganization from './product-units-in-stores';
import ReplaceManualSaleProductModal from './replace-manual-sale-product-modal';

export const SaleProductsTable = () => {
  const dispatch = useDispatch();
  const { sale, step, productToReturnIds, manualProductToReturnIds, availableUnits, manualAvailableUnits, selectedProductIds, selectedManualProductIds } = useSelector(saleSelector);
  const organizationId: number = useMemo(() => {
    if (!sale) {
      return -1;
    }
    return sale.store.organizationId;
  }, [sale]);
  const [selectedManualSaleProduct, setSelectedManualSaleProduct] = useState<ManualSaleProduct | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onChangeCheckbox = (checked: boolean, saleProduct: SaleProduct) => {
    if (checked) {
      dispatch(addProductToReturn(saleProduct));
    } else {
      dispatch(removeProductToReturn(saleProduct.productId));
    }
  };

  const onChangeManualCheckbox = (checked: boolean, manualSaleProduct: ManualSaleProduct) => {
    if (checked) {
      dispatch(addManualProductToReturn(manualSaleProduct));
    } else {
      dispatch(removeManualProductToReturn(manualSaleProduct.id));
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
    setSelectedManualSaleProduct(null);
  };

  return (
    <div className="pos-products-table-container">
      <table className="my-table pos-products-table" style={{ maxWidth: '100%', tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th className="text-start" style={{ width: '200px' }}>
              Producto
            </th>
            <th className="text-center" style={{ width: '65px' }}>
              Precio
            </th>
            <th className="text-center" style={{ width: '35px' }}>
              Uds.
            </th>
            <th className="" style={{ width: '65px' }}>
              Dto.
            </th>
            <th className="text-center" style={{ width: '65px' }}>
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {sale!.saleProducts.map((saleProduct: SaleProduct) => {
            if (step === SaleStep.ReturnsAndExchanges && availableUnits[saleProduct.productId] === 0) {
              return null;
            }
            let manualSaleProduct: ManualSaleProduct | undefined = undefined;
            if (saleProduct.manualSaleProductId) {
              manualSaleProduct = sale!.manualSaleProducts.find((msp: ManualSaleProduct) => msp.id === saleProduct.manualSaleProductId);
            }
            return (
              <tr key={saleProduct.productId}>
                <td className="text-start">
                  <div className="d-flex align-items-center">
                    {step === SaleStep.ReturnsAndExchanges && (
                      <input
                        type="checkbox"
                        className="sale-checkbox"
                        checked={productToReturnIds.hasOwnProperty(saleProduct.productId)}
                        onChange={(e: any) => onChangeCheckbox(e.target.checked, saleProduct)}
                      />
                    )}
                    {sale !== null && step === SaleStep.Viewing && (
                      <input
                        type="checkbox"
                        className="sale-checkbox"
                        checked={selectedProductIds.hasOwnProperty(saleProduct.productId) && selectedProductIds[saleProduct.productId]}
                        onChange={(e: any) => dispatch(toggleSelectedProduct(saleProduct.productId))}
                      />
                    )}
                    {saleProduct.product.images.length > 0 ? (
                      <span className="mobile-product-thumbnail">
                        <LazyLoadImage className="img-thumbnail me-1" src={process.env.REACT_APP_PUBLIC_URL + saleProduct.product.images[0].path} alt={saleProduct.product.name} width="50px" />
                      </span>
                    ) : (
                      <span className="mobile-product-thumbnail">
                        <Image className="img-thumbnail me-1" size={50} color="#808A95" />
                      </span>
                    )}
                    <div>
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row">
                          <Link to={`/producto/${saleProduct.productId}`} className="link-sale me-2">
                            <div className="name">{saleProduct.product.name || 'Sin nombre'}</div>
                          </Link>
                          <span>
                            <ProductUnitsInOrganization organizationId={organizationId} productId={saleProduct.productId} />
                          </span>
                        </div>
                        <div className="sku">{saleProduct.product.sku}</div>
                        {manualSaleProduct !== undefined && <div className="name manual">{manualSaleProduct.name}</div>}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-column align-items-center noselect">
                    <div className="price">{formatNumber(saleProduct.pvp)}€</div>
                  </div>
                </td>
                <td className="text-center product-qty">
                  <span className="noselect units">{saleProduct.quantity}</span>
                </td>
                <td className="">
                  <span className={clsx('discount noselect text-center cursor-default', { 'with-value': saleProduct.discountPercentage > 0 })}>
                    {saleProduct.discountPercentage > 0 ? `-${formatNumber(saleProduct.discountPercentage)}%` : '-'}
                  </span>
                </td>
                <td className="text-center">
                  <span className={clsx('total discount noselect cursor-default', { return: saleProduct.total < 0 })}>{formatNumber(saleProduct.total)}€</span>
                </td>
              </tr>
            );
          })}
          {sale!.manualSaleProducts.map((manualSaleProduct: ManualSaleProduct) => {
            if (manualSaleProduct.replaced) {
              return null;
            }
            if (step === SaleStep.ReturnsAndExchanges && manualAvailableUnits[manualSaleProduct.name.toLowerCase()] === 0) {
              return null;
            }
            return (
              <tr key={manualSaleProduct.id}>
                <td className="text-start">
                  <div className="d-flex flex-row align-items-center position-relative">
                    {step === SaleStep.ReturnsAndExchanges && (
                      <input
                        type="checkbox"
                        className="sale-checkbox"
                        checked={manualProductToReturnIds.hasOwnProperty(manualSaleProduct.id)}
                        onChange={(e: any) => onChangeManualCheckbox(e.target.checked, manualSaleProduct)}
                      />
                    )}
                    {sale !== null && step === SaleStep.Viewing && (
                      <input
                        type="checkbox"
                        className="sale-checkbox"
                        checked={selectedManualProductIds.hasOwnProperty(manualSaleProduct.id) && selectedManualProductIds[manualSaleProduct.id]}
                        onChange={(e: any) => dispatch(toggleSelectedManualProduct(manualSaleProduct.id))}
                      />
                    )}
                    <span className="mobile-product-thumbnail">
                      <Image className="img-thumbnail me-3" size={50} color="#808A95" />
                    </span>
                    <div className="name manual">{manualSaleProduct.name}</div>
                    <span title="Reemplazar por un producto del stock">
                      <Repeat
                        type="button"
                        className="units-icon ms-2"
                        onClick={() => {
                          setSelectedManualSaleProduct(manualSaleProduct);
                          setShowModal(true);
                        }}
                        size={16}
                      />
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-column align-items-center noselect">
                    <div className="price">{formatNumber(manualSaleProduct.pvp)}€</div>
                  </div>
                </td>
                <td className="text-center product-qty">
                  <span className="noselect units">{manualSaleProduct.quantity}</span>
                </td>
                <td className="">
                  <span className={clsx('discount noselect text-center cursor-default', { 'with-value': manualSaleProduct.discountPercentage > 0 })}>
                    {manualSaleProduct.discountPercentage > 0 ? `-${formatNumber(manualSaleProduct.discountPercentage)}%` : '-'}
                  </span>
                </td>
                <td className="text-center">
                  <span className={clsx('total discount noselect cursor-default', { return: manualSaleProduct.total < 0 })}>{formatNumber(manualSaleProduct.total)}€</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showModal && selectedManualSaleProduct !== null && sale !== null && (
        <ReplaceManualSaleProductModal storeId={sale.storeId} manualSaleProduct={selectedManualSaleProduct!} show={showModal} closeModal={onCloseModal} />
      )}
    </div>
  );
};
