import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row } from 'react-table';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import { useStores } from '../hooks/use-stores.hook';
import { Organization } from '../interfaces/organization';
import { StockControl } from '../interfaces/stock-control';
import { Store } from '../interfaces/store';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { formatNumber } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

const StockControlView = () => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const tableRef: any = useRef<any>(null);
  const { selectedStoreId, store } = useSelector(storeSelector);
  const [stockControl, setStockControl] = useState<StockControl[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [requesting, setRequesting] = useState<boolean>(false);
  const stores: Store[] = useStores();

  useEffect(() => {
    const getInventories = async () => {
      setRequesting(true);
      const sc: StockControl[] = await api.getStockControl(organization!.id, selectedStoreId);
      setStockControl(sc);
      setRequesting(false);
    };
    getInventories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId]);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Tienda',
        accessor: 'store.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Marca',
        accessor: 'brand.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Unidades',
        accessor: 'units',
      },
      {
        Header: 'Valoración',
        accessor: 'value',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
      },
      {
        Header: 'Último inventario',
        accessor: 'inventory.id',
        Cell: ({ value }: any) => {
          if (value) {
            return (
              <Link to={`/inventario/${value}`} className="position-relative link-sale">
                <span className="fw-bold">{value}</span>
              </Link>
            );
          } else {
            return null;
          }
        },
      },
      {
        Header: 'Fecha inventario',
        accessor: (stockControl: StockControl) => (stockControl?.inventory ? moment(stockControl.inventory.createdAt).format('DD/MM/YYYY HH:mm') : ''),
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockControl, selectedStoreId]);

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const sc: StockControl = fr.original;
      data.push({
        Tienda: sc.store.name,
        Marca: sc.brand.name,
        Unidades: sc.units,
        Valoración: `${sc.value}€`,
        Inventario: sc.inventory?.id,
        'Fecha inventario': sc.inventory?.createdAt ? moment(sc.inventory.createdAt).format('DD/MM/YYYY HH:mm') : '',
      });
    });
    setCsvData(data);
  };

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Control de Stock</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename={store ? `control_stock_${store.name}.csv` : 'control_stock.csv'} className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <button className="clear-filters" disabled={requesting} onClick={() => tableRef.current?.clearFilters()}>
          Limpiar filtros
        </button>
      </div>
      <Table
        ref={tableRef}
        data={stockControl}
        columns={columns}
        noDataMessage="No hay stock"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
    </div>
  );
};

export default StockControlView;
