import debounce from 'lodash.debounce';
import React, { useCallback, useState } from 'react';
import { Plus, Search } from 'react-feather';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Customer } from '../interfaces/customer';
import { Organization } from '../interfaces/organization';
import { api } from '../services/api';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';
import CustomerModal from './customer-modal';

const getOptions = async (inputValue: string, organizationId: number) => {
  if (inputValue == null || inputValue === '') {
    return [];
  }
  const customers: Customer[] = await api.getCustomers(organizationId, inputValue);
  const options: any[] = customers.map((customer: Customer) => {
    const parts: string[] = [];
    if (customer.name) {
      let label = customer.name;
      if (customer.surnames) {
        label += ` ${customer.surnames}`;
      }
      parts.push(label);
    }
    if (customer.phone) {
      parts.push(customer.phone);
    }
    if (customer.email) {
      parts.push(customer.email);
    }
    return {
      value: customer.id,
      label: parts.join(' · '),
      customer,
    };
  });
  options.push({
    custom: true,
  });
  return options;
};

const AddCustomerOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return data.custom ? (
    <div ref={innerRef} {...innerProps} className="d-flex align-items-center customer-option">
      <Plus color="white" size={20} />
      <span className="ms-2">Añadir nuevo</span>
    </div>
  ) : (
    <components.Option {...props} />
  );
};

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Search color="#222E3D" className="me-2" size={16} />
      </components.DropdownIndicator>
    )
  );
};

interface Props {
  customer: Customer | null;
  onClose: (customer: Customer | null) => void;
  billingDataRequired: boolean;
  error?: boolean;
  onInputChange?: () => void;
  parentRef?: any;
}

const CustomersFinder = (props: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const { selectedStoreId } = useSelector(storeSelector);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getOptions(inputText, organization.id).then((options) => callback(options));
    }, 700),
    [],
  );

  const onChangeAsyncSelect = (e: any) => {
    if (e) {
      if (e.hasOwnProperty('customer')) {
        props.onClose(e.customer);
      } else if (e.hasOwnProperty('custom')) {
        setShowCustomerModal(true);
      }
    } else {
      props.onClose(null);
    }
  };

  const onCloseCustomerModal = (customer: Customer | null) => {
    if (customer) {
      props.onClose(customer);
    }
    setShowCustomerModal(false);
  };

  return (
    <React.Fragment>
      <AsyncSelect
        ref={props.parentRef}
        cacheOptions
        placeholder="Buscar cliente"
        defaultOptions
        isDisabled={selectedStoreId === -1}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? (
            <>
              <div className="enter-text my-2">Introduce datos</div>
              <div onClick={() => setShowCustomerModal(true)} className="d-flex align-items-center text-start customer-option" style={{ borderTop: '0.1px solid #707070' }}>
                <Plus color="white" size={20} />
                <span className="ms-2">Añadir nuevo</span>
              </div>
            </>
          ) : (
            <div className="enter-text my-2">No se han encontrado clientes</div>
          )
        }
        onInputChange={() => {
          if (props.onInputChange) {
            props.onInputChange();
          }
        }}
        onChange={onChangeAsyncSelect}
        isClearable
        loadingMessage={() => 'Buscando clientes...'}
        loadOptions={loadOptions}
        components={{ Option: AddCustomerOption, DropdownIndicator }}
        styles={{
          control: (provided: any, state: any) => {
            return {
              ...provided,
              outline: 'none',
              borderStyle: 'none',
              fontSize: '14px',
              border: props?.error ? '2px solid #e02760' : '1px solid #808a95',
              borderRadius: '7px',
              boxShadow: 'none',
              borderColor: props?.error ? '#e02760' : state.isFocused ? '#555555' : provided.borderColor,
              '&:hover': {
                borderColor: props?.error ? '#e02760' : state.isFocused ? '#555555' : provided.borderColor,
              },
              cursor: state.isDisabled ? 'not-allowed' : 'default',
              backgroundColor: state.isDisabled ? '#D3D3D3' : 'white',
            };
          },
          placeholder: (base: any, state: any) => ({
            ...base,
            color: props?.error ? '#e02760' : '#d3d3d3',
          }),
          input: (base: any, state: any) => ({
            ...base,
            cursor: state.isDisabled ? 'not-allowed' : 'default',
          }),
          indicatorSeparator: () => ({}),
          valueContainer: (provided: any) => ({
            ...provided,
            color: '#222E3D',
          }),
          menu: (provided) => {
            return {
              ...provided,
              boxShadow: 'none',
              borderStyle: 'none',
              color: 'white',
              backgroundColor: '#222E3D',
              fontFamily: 'Inter-Regular',
              fontSize: '12px',
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
              marginTop: 0,
              zIndex: 999999,
            };
          },
          option: (provided, state) => {
            let backgroundColor = '#222E3D';
            let color = 'white';
            if (state.isSelected) {
              backgroundColor = '#26C44B';
              color = 'white';
            }
            if (state.isFocused) {
              backgroundColor = '#D3D3D3';
              color = '#222E3D';
            }
            return {
              ...provided,
              color,
              backgroundColor,
              boxShadow: 'none',
              borderBottom: '0.1px solid #707070',
              cursor: state.isDisabled ? 'not-allowed' : 'default',
            };
          },
          menuList: (provided, state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
        }}
      />
      <CustomerModal show={showCustomerModal} closeModal={onCloseCustomerModal} customer={null} billingDataRequired={props.billingDataRequired} />
    </React.Fragment>
  );
};

export default CustomersFinder;
