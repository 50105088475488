import clsx from 'clsx';
import debounce from 'debounce-promise';
import Fuse from 'fuse.js';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronLeft, Image, Plus, Search, X } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { components, InputActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';
import NoteModal from '../components/note-modal';
import { TransferStatus } from '../enums/transfer-status';
import { Category } from '../interfaces/category';
import { CreateTransferDto } from '../interfaces/create-transfer.dto';
import { GetProductsTags } from '../interfaces/get-product-tags';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { Product } from '../interfaces/product';
import { ProductTransfer } from '../interfaces/product-transfer';
import { ProductTransferDto } from '../interfaces/product-transfer.dto';
import { StockTransfer } from '../interfaces/stock-transfer';
import { Store } from '../interfaces/store';
import { Transfer } from '../interfaces/transfer';
import { UpdateTransferDto } from '../interfaces/update-transfer.dto';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { downloadPdf } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const customStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      width: '100%',
      backgroundColor: state.isDisabled ? '#F8F9FA' : 'white',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      height: '40px',
      border: '1px solid #808a95',
      borderRadius: '8px',
      color: 'white',
      fontSize: '14px',
      fontFamily: 'Inter-SemiBold',
    };
  },
  // For the options
  option: (provided: any, state: any) => {
    return {
      ...provided,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    };
  },
};

const debouncedLoadProductOptions = debounce(
  (stockTransfers: StockTransfer[], selectedStockTransfers: StockTransfer[], inputValue: string) => {
    if (inputValue === '') {
      return [];
    }
    const fuse = new Fuse(stockTransfers, {
      keys: ['product.name', 'product.sku', 'product.ean'],
      threshold: 0,
    });
    const fuseResult: Fuse.FuseResult<StockTransfer>[] = fuse.search(inputValue);
    const result: { label: string; value: number; product: Product }[] = [];
    fuseResult.forEach((r: Fuse.FuseResult<StockTransfer>) => {
      const index: number = selectedStockTransfers.findIndex((s: StockTransfer) => s.product.id === r.item.product.id);
      if (index > -1) {
        return;
      }
      const parts: string[] = [r.item.product.name, r.item.product.sku];
      if (r.item.product.ean && r.item.product.ean.length > 0) {
        parts.push(r.item.product.ean);
      }
      result.push({
        label: parts.join(' - '),
        value: r.item.product.id,
        product: r.item.product,
      });
    });
    return result.slice(0, 100);
  },
  750,
  {
    leading: false,
  },
);

interface CustomStockTransfer extends StockTransfer {
  units: number | null;
}

const PendingTransferView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [stores, setStores] = useState<Store[]>([]);
  const [fromStoreId, setFromStoreId] = useState<number>(-1);
  const [toStoreId, setToStoreId] = useState<number>(-1);
  const [stockTransfers, setStockTransfers] = useState<StockTransfer[]>([]);
  const [transfer, setTransfer] = useState<Transfer | null>(null);
  const [shippingDate, setShippingDate] = useState<string | ''>(moment().add(30, 'minutes').format('YYYY-MM-DDTHH:mm'));
  const [selectedStockTransfers, setSelectedStockTransfers] = useState<CustomStockTransfer[]>([]);
  const [generatingLabels, setGeneratingLabels] = useState<boolean>(false);
  const [notes, setNotes] = useState<string | null>('');
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const fromStores: Store[] = useMemo(() => {
    // De todas las tiendas de la organización, obtener las que el usuario está asignado
    return stores.filter((s: Store) => {
      const index: number = user.stores.findIndex((ms: Store) => ms.id === s.id);
      return index > -1;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]);
  const toStores: Store[] = useMemo(() => {
    if (stores.length === 0) {
      return [];
    }
    // Comprobar que primero se haya seleccionado una tienda origen
    const fromStore: Store | undefined = stores.find((store: Store) => store.id === fromStoreId);
    if (!fromStore) {
      return [];
    }
    return stores.filter((store: Store) => store.id !== fromStoreId && store.organizationId === fromStore.organizationId);
  }, [stores, fromStoreId]);
  const totalUnits: number = useMemo(() => {
    let total = 0;
    for (const customStockTransfer of selectedStockTransfers) {
      total += customStockTransfer.units || 0;
    }
    return total;
  }, [selectedStockTransfers]);
  const selectInputRef = useRef<any>(null);
  const [valueSelect, setValueSelect] = useState<{ label: string; value: number; product: Product } | null>(null);
  const storeFrom: Store | undefined = useMemo(() => {
    if (fromStoreId === -1) {
      return undefined;
    }
    return stores.find((store: Store) => store.id === fromStoreId);
  }, [fromStoreId, stores]);
  const storeTo: Store | undefined = useMemo(() => {
    if (toStoreId === -1) {
      return undefined;
    }
    return stores.find((store: Store) => store.id === toStoreId);
  }, [toStoreId, stores]);
  const disabledSaveButton: boolean = useMemo(() => {
    if (!moment(shippingDate).isValid()) {
      return true;
    }
    return totalUnits === 0;
  }, [totalUnits, shippingDate]);

  useEffect(() => {
    getStoresForTransfer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStoresForTransfer = async () => {
    const s: Store[] = await api.getStoresForTransfer(organization.id);
    setStores(s);
  };

  useEffect(() => {
    if (params?.id && user) {
      const getTransfer = async () => {
        try {
          const t: Transfer = await api.getTransfer(parseInt(params.id!, 10));
          if (t.status === TransferStatus.Pending) {
            if (moment(t.shippingDate).isBefore(moment())) {
              navigate(`/traspaso/${t.id}/verificar`, { replace: true });
              return;
            }
          } else if (t.status !== TransferStatus.Draft) {
            navigate(`/traspaso/${t.id}`, { replace: true });
            return;
          }
          // Comprobar si el usuario está asignado a la tienda origen del traspaso
          const index: number = user.stores.findIndex((store: Store) => store.id === t.fromStore.id);
          if (index === -1) {
            navigate(`/traspasos`);
            return;
          }
          setFromStoreId(t.fromStoreId);
          setToStoreId(t.toStoreId);
          setShippingDate(moment(t.shippingDate).format('YYYY-MM-DDTHH:mm'));
          setTransfer(t);
          setNotes(t.notes);
        } catch (e: any) {
          const httpExceptionDto: HttpExceptionDto = e.response.data;
          myToastr.error(httpExceptionDto.message);
          navigate('/traspasos', { replace: true });
        }
      };
      getTransfer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const getStockTransfers = async () => {
      const ignoreTransferId: number | undefined = params?.id ? parseInt(params.id, 10) : undefined;
      const transfers: StockTransfer[] = await api.getStockTransfer(fromStoreId, toStoreId, ignoreTransferId);
      setStockTransfers(transfers);
    };
    if (fromStoreId > 0 && toStoreId > 0) {
      getStockTransfers();
    }
  }, [fromStoreId, params.id, toStoreId]);

  useEffect(() => {
    if (!transfer) {
      return;
    }
    const copySelectedStockTransfers: CustomStockTransfer[] = [];
    transfer.transferredProducts.forEach((productTransfer: ProductTransfer) => {
      const index: number = stockTransfers.findIndex((st: StockTransfer) => st.product.id === productTransfer.product.id);
      if (index > -1) {
        copySelectedStockTransfers.push({
          ...stockTransfers[index],
          units: productTransfer.unitsShipped,
        });
      }
    });
    setSelectedStockTransfers(copySelectedStockTransfers);
  }, [transfer, stockTransfers]);

  const onChangeFromStore = (id: number) => {
    setFromStoreId(id);
    setToStoreId(-1);
    setSelectedStockTransfers([]);
  };

  const onChangeToStore = (id: number) => {
    setToStoreId(id);
    setSelectedStockTransfers([]);
  };

  const deleteProduct = (productId: number) => {
    const sst: CustomStockTransfer[] = [...selectedStockTransfers];
    const index: number = sst.findIndex((s: CustomStockTransfer) => s.product.id === productId);
    if (index > -1) {
      sst.splice(index, 1);
      setSelectedStockTransfers(sst);
    }
  };

  const addProduct = (productId: number) => {
    const sst: CustomStockTransfer[] = [...selectedStockTransfers];
    const index: number = stockTransfers.findIndex((s: StockTransfer) => s.product.id === productId);
    if (index > -1) {
      sst.push({ ...stockTransfers[index], units: 0 });
      setSelectedStockTransfers(sst);
    }
    selectInputRef.current.clearValue();
  };

  const setProductUnits = (productId: number, value: number | null) => {
    const index: number = selectedStockTransfers.findIndex((s: CustomStockTransfer) => s.product.id === productId);
    if (index > -1) {
      const sst: CustomStockTransfer[] = [...selectedStockTransfers];
      sst[index].units = Math.max(value || 0, 0);
      setSelectedStockTransfers(sst);
    }
  };

  const deleteTransfer = async () => {
    try {
      await api.deleteTransfer(transfer!.id);
      myToastr.info('Traspaso eliminado');
      navigate('/traspasos', { replace: true });
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

  const updateTransfer = async (status: TransferStatus) => {
    const transferredProducts: ProductTransferDto[] = [];
    for (const sst of selectedStockTransfers) {
      if (sst.units !== null && sst.units > 0) {
        const productTransfer: ProductTransferDto = {
          productId: sst.product.id,
          quantity: sst.units,
        };
        transferredProducts.push(productTransfer);
      }
    }
    try {
      const updateTransferDto: UpdateTransferDto = {
        shippingDate: moment(shippingDate).toDate(),
        notes,
        transferredProducts,
        status,
      };
      await api.updateTransfer(transfer!.id, updateTransferDto);
      myToastr.info('Se ha actualizado la información del traspaso');
      navigate('/traspasos', { replace: true });
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

  const createTransfer = async (status: TransferStatus) => {
    const transferredProducts: ProductTransferDto[] = [];
    for (const sst of selectedStockTransfers) {
      if (sst.units !== null && sst.units > 0) {
        const productTransfer: ProductTransferDto = {
          productId: sst.product.id,
          quantity: sst.units,
        };
        transferredProducts.push(productTransfer);
      }
    }
    try {
      const createTransferDto: CreateTransferDto = {
        shippingDate: moment(shippingDate).toDate(),
        notes,
        fromStoreId,
        toStoreId,
        transferredProducts,
        status,
      };
      await api.createTransfer(createTransferDto);
      navigate(`/traspasos`, { replace: true });
      myToastr.success(`Traspaso creado correctamente`);
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Search size={18} color="#808a95" />
        </components.DropdownIndicator>
      )
    );
  };

  const Option = (props: any) => {
    const { innerProps, innerRef, data } = props;
    const product: Product = data.product;
    let image = null;
    if (product.images.length > 0) {
      image = product.images[0].path;
    }
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="d-flex flex-row align-items-center px-4"
        style={{ borderBottom: '1px solid #F2F2F2', fontFamily: 'Inter-Regular', fontSize: '14px', color: '#808a95', cursor: 'pointer' }}
      >
        {image ? (
          <LazyLoadImage width={45} height={45} src={process.env.REACT_APP_PUBLIC_URL + image} alt="" className="img-thumbnail my-2 me-2" />
        ) : (
          <Image className="img-thumbnail my-2 me-2" size={45} color="#808A95" />
        )}
        <div>{product.name}</div>
        <div className="mx-3">·</div>
        <div className="">{product.sku}</div>
        {product.ean && (
          <React.Fragment>
            <div className="mx-3">·</div>
            <div>EAN: {product.ean}</div>
          </React.Fragment>
        )}
      </div>
    );
  };

  const getProductsLabels = async () => {
    if (generatingLabels) {
      return;
    }
    if (!transfer) {
      return;
    }
    try {
      myToastr.info('Generando etiquetas. Este proceso puede llevar segundos. Espere por favor...');
      setGeneratingLabels(true);
      const getProductsTags: GetProductsTags = {
        organizationId: organization!.id,
        storeIds: [transfer.fromStoreId],
        products: transfer.transferredProducts.map((productTransfer: ProductTransfer) => {
          return {
            productId: productTransfer.productId,
            quantity: productTransfer.unitsShipped,
          };
        }),
      };
      const result: ArrayBuffer = await api.getProductsLabels(getProductsTags);
      downloadPdf(result, `etiquetas-traspaso-${transfer.internalId}.pdf`);
    } catch (e) {
      myToastr.error('Hubo un error obteniendo la etiqueta del producto');
    } finally {
      setGeneratingLabels(false);
    }
  };

  const onCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  const onSaveNotes = async (notes: string | null) => {
    if (transfer) {
      try {
        const t: Transfer = await api.updateTransferNotes(transfer.id, notes);
        setTransfer(t);
        setNotes(notes);
        setShowNoteModal(false);
        myToastr.info('Notas guardadas');
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    } else {
      setNotes(notes);
    }
    setShowNoteModal(false);
  };

  return (
    <div className="pending-transfer-view h-100">
      <div className="d-flex flex-row align-items-center">
        <Link to="/traspasos" className="d-flex flex-row align-items-center come-back me-2">
          <ChevronLeft className="me-1" size={14} />
          <span>Volver</span>
        </Link>
        <h1 className="title flex-grow-1">{transfer ? `Traspaso ${transfer.internalId}` : 'Nuevo Traspaso'}</h1>
        <div
          onClick={() => setShowNoteModal(true)}
          className={clsx('d-flex align-items-center button-header me-3', { 'has-notes': notes != null && notes !== '' })}
          style={{ fontSize: '12px', padding: '6px 10px' }}
        >
          {notes ? (
            '1 Nota'
          ) : (
            <React.Fragment>
              <Plus className="me-1" size={14} />
              <span>Nota</span>
            </React.Fragment>
          )}
        </div>
        {transfer != null && (
          <button
            className="create-button create-expiration me-2"
            disabled={generatingLabels}
            onClick={getProductsLabels}
            style={{
              borderRadius: '6px',
            }}
          >
            Descargar etiquetas
          </button>
        )}
      </div>
      <div className="container-info row my-2">
        <div className="col-8 px-2">
          <div className="container-details">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row mb-2">
                <div className="col-6 d-flex flex-column pe-4">
                  <div className="title-input">Tienda origen</div>
                  <select disabled={transfer != null} name="fromStoreId" value={fromStoreId} onChange={(e: any) => onChangeFromStore(parseInt(e.target.value, 10))}>
                    <option value={-1}>Selecciona una tienda</option>
                    {fromStores.map((s: Store) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 d-flex flex-column">
                  <div className="title-input">Tienda destino</div>
                  <select disabled={transfer != null || fromStoreId === -1} name="toStoreId" value={toStoreId} onChange={(e: any) => onChangeToStore(parseInt(e.target.value, 10))}>
                    <option value={-1}>Selecciona una tienda</option>
                    {toStores.map((s: Store) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="col-6 d-flex flex-column pe-4">
                  <div className="title-input">Fecha de envío</div>
                  <input type="datetime-local" value={shippingDate} onChange={(e: any) => setShippingDate(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 px-2">
          <div className="container-details">
            <div className="d-flex flex-column">
              <div className="title-input">Estado</div>
              <div
                className={clsx('container-status', {
                  'transfer-draft': !transfer || transfer.status === TransferStatus.Draft,
                  'transfer-pending': transfer?.status === TransferStatus.Pending,
                  'transfer-incidence': transfer?.status === TransferStatus.Incidence,
                  'transfer-completed': transfer?.status === TransferStatus.Completed,
                  'transfer-resolved': transfer?.status === TransferStatus.Resolved,
                })}
              >
                {!transfer || transfer.status === TransferStatus.Draft ? 'Borrador' : ''}
                {transfer?.status === TransferStatus.Pending ? 'Pendiente' : ''}
                {transfer?.status === TransferStatus.Incidence ? 'Incidencia' : ''}
                {transfer?.status === TransferStatus.Completed ? 'Completado' : ''}
                {transfer?.status === TransferStatus.Resolved ? 'Resuelto' : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
      {fromStoreId > -1 && toStoreId > -1 && (
        <React.Fragment>
          <div className="container-table my-4">
            <div className="d-flex flex-column">
              <table>
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th className="text-center">Marca</th>
                    <th className="text-center">Categorías</th>
                    <th className="text-center">Uds. Origen</th>
                    <th className="text-center">Uds. Traspasar</th>
                    <th className="text-center">Uds. Destino</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedStockTransfers.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {stockTransfers.length === 0 ? 'La tienda no tiene productos en stock' : 'No se han añadido productos'}
                      </td>
                    </tr>
                  ) : (
                    selectedStockTransfers.map((customStockTransfer: CustomStockTransfer, index: number) => {
                      let image = null;
                      if (customStockTransfer.product.images.length > 0) {
                        image = customStockTransfer.product.images[0].path;
                      }
                      return (
                        <tr key={index}>
                          <td>
                            <div className="d-flex flex-row align-items-center">
                              <X size={14} className="clickable me-2" onClick={() => deleteProduct(customStockTransfer.product.id)} />
                              {image ? (
                                <LazyLoadImage width={45} height={45} src={process.env.REACT_APP_PUBLIC_URL + image} alt="" className="img-thumbnail my-2 me-2" />
                              ) : (
                                <Image className="img-thumbnail my-2 me-2" size={26} color="#808A95" />
                              )}
                              <div className="d-flex flex-column ms-2">
                                <span className="product-name">{customStockTransfer.product.name}</span>
                                <div className="d-flex flex-row align-items-center">
                                  <span className="product-sku">{customStockTransfer.product.sku}</span>
                                  {customStockTransfer.product.ean && (
                                    <React.Fragment>
                                      <div className="mx-3">·</div>
                                      <span className="product-sku">{customStockTransfer.product.ean}</span>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">{customStockTransfer.product.brand.name}</td>
                          <td className="text-center">{customStockTransfer.product.categories.map((category: Category) => category.name).join(', ')}</td>
                          <td className="text-center">{customStockTransfer.quantityFrom}</td>
                          <td className="text-center">
                            <input
                              type="number"
                              step={1}
                              min={0}
                              max={customStockTransfer.quantityFrom}
                              value={customStockTransfer.units || ''}
                              onChange={(e: any) => {
                                if (e?.target?.value && e.target.value !== '') {
                                  const units: number = Math.min(customStockTransfer.quantityFrom, parseInt(e.target.value, 10));
                                  setProductUnits(customStockTransfer.product.id, units);
                                } else {
                                  setProductUnits(customStockTransfer.product.id, null);
                                }
                              }}
                            />
                          </td>
                          <td className="text-center">{customStockTransfer.quantityTo}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              {stockTransfers.length > 0 && (
                <div className="mt-3">
                  <AsyncSelect
                    autoFocus
                    cacheOptions
                    defaultOptions
                    ref={selectInputRef}
                    name="product"
                    loadOptions={async (inputValue: string, callback) => {
                      const result = await debouncedLoadProductOptions(stockTransfers, selectedStockTransfers, inputValue);
                      callback(result);
                    }}
                    placeholder="Buscar producto..."
                    styles={customStyles}
                    noOptionsMessage={() => 'No se han encontrado coincidencias'}
                    components={{ DropdownIndicator, Option, IndicatorSeparator: () => null, ClearIndicator: () => null }}
                    onChange={(e: any) => {
                      setValueSelect(e);
                      if (e?.product) {
                        addProduct(e.product.id);
                      }
                    }}
                    onInputChange={(newValue: string, actionMeta: InputActionMeta) => console.log('onInputChange', newValue, 'actionMeta', actionMeta)}
                    value={valueSelect}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="my-2 container-resume d-flex flex-row">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th className="text-center">Tienda Origen</th>
                  <th className="text-center">Tienda Destino</th>
                  {transfer && (
                    <React.Fragment>
                      <th className="text-center">Fecha de creación</th>
                      <th className="text-center">Realizado por</th>
                      <th className="text-center">Fecha de actualización</th>
                    </React.Fragment>
                  )}
                  <th className="text-center">Productos</th>
                  <th className="text-center">Total Unidades</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    <div className="container-data-resume">{storeFrom?.name}</div>
                  </td>
                  <td className="text-center">
                    <div className="container-data-resume">{storeTo?.name}</div>
                  </td>
                  {transfer && (
                    <React.Fragment>
                      <td className="text-center">
                        <div className="container-data-resume">{moment(transfer.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                      </td>
                      <td className="text-center">
                        <div className="container-data-resume">
                          {transfer.user.name} {transfer.user.surnames}
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="container-data-resume">{moment(transfer.updatedAt).format('DD/MM/YYYY HH:mm')}</div>
                      </td>
                    </React.Fragment>
                  )}
                  <td className="text-center">
                    <div className="container-data-resume container-number">{selectedStockTransfers.length}</div>
                  </td>
                  <td className="text-center">
                    <div className="container-data-resume container-number">{totalUnits}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="container-buttons mt-4">
            <div className="d-flex">
              {!transfer ? (
                <React.Fragment>
                  <button onClick={() => createTransfer(TransferStatus.Draft)} disabled={disabledSaveButton} className="save-transfer ms-auto me-2">
                    Guardar traspaso
                  </button>
                  <button onClick={() => createTransfer(TransferStatus.Pending)} disabled={disabledSaveButton} className="save-transfer ms-2">
                    Realizar traspaso
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {(transfer.status === TransferStatus.Draft || transfer.status === TransferStatus.Pending) && (
                    <div className="flex-grow-1">
                      <button onClick={deleteTransfer} className="delete-transfer">
                        Eliminar
                      </button>
                    </div>
                  )}
                  {transfer.status === TransferStatus.Draft && (
                    <React.Fragment>
                      <button onClick={() => updateTransfer(TransferStatus.Draft)} disabled={disabledSaveButton} className="save-transfer ms-2">
                        Actualizar traspaso
                      </button>
                      <button onClick={() => updateTransfer(TransferStatus.Pending)} disabled={disabledSaveButton} className="save-transfer ms-2">
                        Realizar traspaso
                      </button>
                    </React.Fragment>
                  )}
                  {transfer.status === TransferStatus.Pending && (
                    <React.Fragment>
                      <button onClick={() => navigate(`/traspaso/${transfer.id}/verificar`)} className="verify-transfer">
                        Verificar traspaso
                      </button>
                      <button onClick={() => updateTransfer(TransferStatus.Pending)} disabled={disabledSaveButton} className="save-transfer ms-2">
                        Actualizar traspaso
                      </button>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
      <NoteModal editable={true} show={showNoteModal} notes={notes} closeModal={onCloseNoteModal} saveNotes={onSaveNotes} deleteNotes={() => onSaveNotes(null)} />
    </div>
  );
};

export default PendingTransferView;
