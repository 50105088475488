import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/images/sierra_logo_home.svg';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { ResetPasswordDto } from '../interfaces/reset-password.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';

interface FormData {
  password: string;
  repeatPassword: string;
}

const ResetPasswordView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });

  useEffect(() => {
    const token: string | null = localStorage.getItem('token');
    if (!token) {
      return;
    }
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (formData: FormData) => {
    try {
      const resetPasswordDto: ResetPasswordDto = {
        token: params.token!,
        password: formData.password,
      };
      await api.resetPassword(resetPasswordDto);
      myToastr.success('Contraseña actualizada correctamente');
      navigate('/login');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  return (
    <div className="login my-form">
      <LazyLoadImage src={logo} alt="" className="home-logo" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Cambiar contraseña</div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.password ? 'error' : '')}>Nueva contraseña *</div>
              <input
                type="password"
                {...register('password', {
                  required: true,
                  minLength: { value: 8, message: 'La contraseña debe contener al menos 8 caracteres' },
                  maxLength: { value: 50, message: 'La contraseña debe contener como máximo 50 caracteres' },
                })}
                className={clsx({ error: errors?.password })}
                placeholder="Introduce una contraseña"
              />
              {errors.password && <div className="error-message">{errors.password.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.repeatPassword ? 'error' : '')}>Repite contraseña*</div>
              <input
                type="password"
                {...register('repeatPassword', {
                  required: true,
                  validate: (value: string) => {
                    return watch('password') === value || 'La contraseña no coincide';
                  },
                })}
                className={clsx({ error: errors?.repeatPassword })}
                placeholder="Repite contraseña"
              />
              {errors.repeatPassword && <div className="error-message">{errors.repeatPassword.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Aceptar
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordView;
