import { matchSorter } from 'match-sorter';
import { useMemo } from 'react';

export function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
  return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id]] });
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

export function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }: any) {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder="Buscar"
    />
  );
}

export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      if (!row.values[id]) {
        return;
      }
      options.add(row.values[id]);
    });
    return Array.from(options.values());
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {options.map((option: any, i) => {
        let textOption = option;
        if (option === true || option === false) {
          textOption = option ? 'Sí' : 'No';
        }
        return (
          <option key={i} value={option}>
            {textOption}
          </option>
        );
      })}
    </select>
  );
}

export const fuzzyFilterTypes = {
  // Add a new fuzzyTextFilterFn filter type.
  fuzzyText: fuzzyTextFilterFn,
  // Or, override the default text filter to use
  // "startWith"
  text: (rows: any, id: any, filterValue: any) => {
    return rows.filter((row: any) => {
      const rowValue = row.values[id];
      return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
    });
  },
};
