import clsx from 'clsx';
import debounce from 'debounce-promise';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Image, X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-modal';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Order } from '../enums/order';
import { ThresholdType } from '../enums/threshold-type.enum';
import { useStores } from '../hooks/use-stores.hook';
import { Brand } from '../interfaces/brand';
import { Category } from '../interfaces/category';
import { Collection } from '../interfaces/collection';
import { GetStock } from '../interfaces/get-stock';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { Product } from '../interfaces/product';
import { Stock } from '../interfaces/stock';
import { Store } from '../interfaces/store';
import { SubCategory } from '../interfaces/subcategory';
import { Threshold } from '../interfaces/threshold';
import { ThresholdDto } from '../interfaces/threshold.dto';
import { api } from '../services/api';
import { truncateText } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const MyProductOption = (props: any) => {
  const { innerProps, innerRef } = props;
  if (props?.data?.product) {
    const product: Product = props.data.product as Product;
    let image = null;
    if (product?.images && product.images.length > 0) {
      image = product.images[0].path;
    }
    return (
      <div ref={innerRef} {...innerProps} className="d-flex flex-row align-items-center custom-product-option">
        <div className="me-2">{image ? <LazyLoadImage src={process.env.REACT_APP_PUBLIC_URL + image} alt="" width={40} height={40} className="my-2 me-2" /> : <Image size={50} color="white" />}</div>
        <div className="d-flex flex-column">
          <div className="name">{truncateText(product.name, 30)}</div>
          <div className="sku">{product.sku}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const debouncedLoadProductOptions = debounce(
  async (getStock: GetStock) => {
    if (!getStock.query) {
      return [];
    }
    const stock: Stock[] = await api.getStock(getStock);
    return stock.map((s: Stock) => {
      return {
        value: s.product.id,
        label: s.product.name,
        product: s.product,
      };
    });
  },
  1000,
  {
    leading: false,
  },
);

interface Props {
  threshold: Threshold | null;
  show: boolean;
  closeModal: (brand: Threshold | null) => void;
}

const initialValues: ThresholdDto = {
  brandId: -1,
  collectionId: -1,
  categoryId: -1,
  subCategoryId: -1,
  productId: -1,
  storeId: -1,
  units: 0,
  type: null,
};

const ThresholdModal = ({ threshold, show, closeModal }: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const stores: Store[] = useStores();
  const productInputRef = useRef<any>(null);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [step, setStep] = useState<number>(1);
  const {
    clearErrors,
    control,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ThresholdDto>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });
  const filteredCollections: Collection[] = useMemo(() => {
    if (collections.length === 0) {
      return [];
    }
    return collections.filter((c: Collection) => c.brandId === watch('brandId'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections, watch('brandId')]);
  const filteredSubCategories: SubCategory[] = useMemo(() => {
    if (subCategories.length === 0) {
      return [];
    }
    return subCategories.filter((subCategory: SubCategory) => subCategory.categoryId === watch('categoryId'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, watch('categoryId')]);

  useEffect(() => {
    if (!show) {
      reset(initialValues);
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const bs: Brand[] = await api.getBrands(organization.id);
        setBrands(bs);
      } catch (e) {}
    };
    const getCollectiosn = async () => {
      try {
        const cs: Collection[] = await api.getCollections(organization.id);
        setCollections(cs);
      } catch (e) {}
    };
    const getCategories = async () => {
      try {
        const cs: Category[] = await api.getCategories(organization.id);
        setCategories(cs);
      } catch (e) {}
    };
    const getSubCategories = async () => {
      try {
        const scs: SubCategory[] = await api.getSubCategories(organization.id);
        setSubCategories(scs);
      } catch (e) {}
    };
    getBrands();
    getCollectiosn();
    getCategories();
    getSubCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (show && threshold) {
      setStep(3);
      reset({
        brandId: threshold.brandId,
        collectionId: threshold.collectionId,
        categoryId: threshold.categoryId,
        subCategoryId: threshold.subCategoryId,
        productId: threshold.productId,
        storeId: threshold.storeId,
        units: threshold.units,
        type: threshold.type,
      });
    }
  }, [show, threshold, reset]);

  useEffect(() => {
    if (productInputRef.current) {
      productInputRef.current.clearValue();
    }
  }, [step]);

  const close = () => {
    closeModal(null);
  };

  const onSubmit = async (data: ThresholdDto) => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        if (!data.type) {
          setError('type', { type: 'manual', message: 'Selecciona un tipo' });
          return;
        }
        setStep(3);
        break;
      case 3:
        if (threshold) {
          const updatedThreshold: Threshold = await api.updateThreshold(threshold.id, data);
          myToastr.success('Umbral actualizado');
          closeModal(updatedThreshold);
        } else {
          try {
            const thresholdDto: ThresholdDto = {
              brandId: null,
              collectionId: null,
              categoryId: null,
              subCategoryId: null,
              productId: null,
              storeId: data.storeId,
              units: data.units,
              type: data.type,
            };
            switch (data.type) {
              case ThresholdType.Category:
                thresholdDto.categoryId = data.categoryId;
                if (data.subCategoryId !== null && data.subCategoryId > 0) {
                  thresholdDto.subCategoryId = data.subCategoryId;
                }
                break;
              case ThresholdType.Brand:
                thresholdDto.brandId = data.brandId;
                if (data.collectionId !== null && data.collectionId > 0) {
                  thresholdDto.collectionId = data.collectionId;
                }
                break;
              case ThresholdType.Product:
                thresholdDto.productId = (data.productId as any).value;
                break;
            }
            const newThreshold: Threshold = await api.createThreshold(thresholdDto);
            myToastr.success('Umbral creado');
            closeModal(newThreshold);
          } catch (e: any) {
            const httpExceptionDto: HttpExceptionDto = e.response.data;
            myToastr.error(httpExceptionDto.message);
          }
        }
        break;
    }
  };

  return (
    <Modal className="vercomi-modal my-form threshold-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content position-relative">
          <div className="d-flex flex-column text-center justify-content-center">
            <div className="title">Crear Umbral</div>
            <div className="subtitle">Paso {step}</div>
            <button type="button" className="position-absolute top-0 close-button-modal" style={{ right: 15 }} onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center" style={{ height: step === 1 || step === 2 ? 200 : 350 }}>
          {step === 1 && (
            <div
              className="my-3"
              style={{
                paddingLeft: 120,
                paddingRight: 120,
              }}
            >
              <div className={clsx('input-name', { error: errors?.storeId })}>Elige la tienda</div>
              <select {...register('storeId', { required: true, min: 1, valueAsNumber: true })} className={clsx({ error: errors?.storeId })}>
                <option value={-1}>Selecciona tienda</option>
                {stores.map((store: Store) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {step === 2 && (
            <React.Fragment>
              <div className="step-2 d-flex flex-row justify-content-between">
                <div
                  className={clsx('selection', { selected: watch('type') === ThresholdType.Brand, error: errors.hasOwnProperty('type') })}
                  onClick={() => {
                    clearErrors('type');
                    setValue('type', ThresholdType.Brand);
                    setValue('categoryId', -1);
                    setValue('subCategoryId', -1);
                    setValue('brandId', -1);
                    setValue('collectionId', -1);
                    setValue('productId', -1);
                  }}
                >
                  Marca
                </div>
                <div
                  className={clsx('selection', { selected: watch('type') === ThresholdType.Category, error: errors.hasOwnProperty('type') })}
                  onClick={() => {
                    clearErrors('type');
                    setValue('type', ThresholdType.Category);
                    setValue('categoryId', -1);
                    setValue('subCategoryId', -1);
                    setValue('brandId', -1);
                    setValue('collectionId', -1);
                    setValue('productId', -1);
                  }}
                >
                  Categoría
                </div>
                <div
                  className={clsx('selection', { selected: watch('type') === ThresholdType.Product, error: errors.hasOwnProperty('type') })}
                  onClick={() => {
                    clearErrors('type');
                    setValue('type', ThresholdType.Product);
                    setValue('categoryId', -1);
                    setValue('subCategoryId', -1);
                    setValue('brandId', -1);
                    setValue('collectionId', -1);
                    setValue('productId', -1);
                  }}
                >
                  Producto
                </div>
              </div>
            </React.Fragment>
          )}
          {step === 3 && watch('type') === ThresholdType.Brand && (
            <React.Fragment>
              <div
                className="my-3"
                style={{
                  paddingLeft: 120,
                  paddingRight: 120,
                }}
              >
                <div className={clsx('input-name', { error: errors?.brandId })}>Elige marca</div>
                <select {...register('brandId', { required: true, min: 1, valueAsNumber: true })} className={clsx({ error: errors?.brandId })} disabled={threshold !== null}>
                  <option value={-1}>Selecciona marca</option>
                  {brands.map((brand: Brand) => (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>
              {watch('brandId')! > 0 && filteredCollections.length > 0 && (
                <div
                  className="my-3"
                  style={{
                    paddingLeft: 120,
                    paddingRight: 120,
                  }}
                >
                  <div className={clsx('input-name', { error: errors?.collectionId })}>Elige colección</div>
                  <select {...register('collectionId', { valueAsNumber: true })} className={clsx({ error: errors?.collectionId })} disabled={threshold !== null}>
                    <option value={-1}>Selecciona colección</option>
                    {filteredCollections.map((collection: Collection) => (
                      <option key={collection.id} value={collection.id}>
                        {collection.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </React.Fragment>
          )}
          {step === 3 && watch('type') === ThresholdType.Category && (
            <React.Fragment>
              <div
                className="my-3"
                style={{
                  paddingLeft: 120,
                  paddingRight: 120,
                }}
              >
                <div className={clsx('input-name', { error: errors?.categoryId })}>Elige categoría</div>
                <select {...register('categoryId', { required: true, min: 1, valueAsNumber: true })} className={clsx({ error: errors?.categoryId })} disabled={threshold !== null}>
                  <option value={-1}>Selecciona categoría</option>
                  {categories.map((category: Category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              {watch('categoryId')! > 0 && filteredSubCategories.length > 0 && (
                <div
                  className="my-3"
                  style={{
                    paddingLeft: 120,
                    paddingRight: 120,
                  }}
                >
                  <div className={clsx('input-name', { error: errors?.subCategoryId })}>Elige subcategoría</div>
                  <select {...register('subCategoryId', { valueAsNumber: true })} className={clsx({ error: errors?.subCategoryId })} disabled={threshold !== null}>
                    <option value={-1}>Selecciona subcategoría</option>
                    {filteredSubCategories.map((subCategory: SubCategory) => (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </React.Fragment>
          )}
          {step === 3 && watch('type') === ThresholdType.Product && (
            <React.Fragment>
              <div
                className="my-3"
                style={{
                  paddingLeft: 120,
                  paddingRight: 120,
                }}
              >
                <div className={clsx('input-name', { error: errors?.productId })}>Busca producto</div>
                {threshold ? (
                  <input type="text" value={threshold.product.name} disabled={true} />
                ) : (
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="productId"
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <AsyncCreatableSelect
                          ref={productInputRef}
                          placeholder="SKU o nombre del producto"
                          defaultOptions
                          noOptionsMessage={({ inputValue }: any) => (!inputValue ? null : <div className="enter-text my-2">No se han encontrado productos</div>)}
                          onChange={(newValue: any, action) => onChange(newValue)}
                          value={value}
                          isClearable
                          menuPlacement="bottom"
                          loadingMessage={() => 'Buscando productos...'}
                          loadOptions={(query: string) =>
                            debouncedLoadProductOptions({
                              orderField: 'product.name',
                              order: Order.Asc,
                              query,
                              organizationId: organization!.id,
                              storeIds: [watch('storeId')!],
                              brandIds: [],
                              collectionIds: [],
                              categoryIds: [],
                              inStock: null,
                              active: null,
                              online: null,
                            })
                          }
                          components={{ DropdownIndicator: null, Option: MyProductOption }}
                          styles={{
                            control: (provided: any, state: any) => {
                              return {
                                ...provided,
                                outline: 'none',
                                borderStyle: 'none',
                                border: `1px solid ${errors?.productId ? '#e02760' : 'white'}`,
                                borderRadius: '7px',
                                boxShadow: 'none',
                                cursor: state.isDisabled ? 'not-allowed' : 'default',
                                backgroundColor: state.isDisabled ? '#D3D3D3' : 'trasparent',
                                width: '100%',
                              };
                            },
                            placeholder: (base: any, state: any) => ({
                              ...base,
                              paddingLeft: '10px',
                              color: errors?.productId ? '#e02760' : 'white',
                              cursor: state.isDisabled ? 'not-allowed' : 'default',
                            }),
                            input: (base: any, state: any) => ({
                              ...base,
                              cursor: state.isDisabled ? 'not-allowed' : 'default',
                              color: 'white',
                              margin: '0px',
                            }),
                            indicatorSeparator: () => ({}),
                            valueContainer: (provided: any) => ({
                              ...provided,
                              color: errors?.productId ? '#e02760' : '#808a95',
                            }),
                            menu: (provided: any) => {
                              return {
                                ...provided,
                                boxShadow: 'none',
                                borderStyle: 'none',
                                color: 'white',
                                backgroundColor: '#222E3D',
                                borderTopLeftRadius: '0px',
                                borderTopRightRadius: '0px',
                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px',
                                marginTop: 0,
                              };
                            },
                            option: (provided: any, state: any) => {
                              let backgroundColor = '#222E3D';
                              let color = 'white';
                              if (state.isSelected) {
                                backgroundColor = '#26C44B';
                                color = 'white';
                              }
                              if (state.isFocused) {
                                backgroundColor = '#D3D3D3';
                                color = '#222E3D';
                              }
                              return {
                                ...provided,
                                color,
                                backgroundColor,
                                boxShadow: 'none',
                                borderBottom: '0.1px solid #707070',
                                cursor: state.isDisabled ? 'not-allowed' : 'default',
                              };
                            },
                            menuList: (provided, state) => ({
                              ...provided,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }),
                            indicatorsContainer: (provided: any, state: any) => ({
                              ...provided,
                            }),
                            singleValue: (provided: any, state: any) => ({
                              ...provided,
                              color: 'white',
                              cursor: state.isDisabled ? 'not-allowed' : 'default',
                            }),
                            loadingMessage: (provided: any, state: any) => ({
                              ...provided,
                              color: 'white',
                              cursor: state.isDisabled ? 'not-allowed' : 'default',
                            }),
                            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      );
                    }}
                  />
                )}
              </div>
            </React.Fragment>
          )}
          {step === 3 && (
            <div
              className="my-3"
              style={{
                paddingLeft: 120,
                paddingRight: 120,
              }}
            >
              <div className={clsx('input-name', { error: errors?.units })}>Umbral</div>
              <input type="number" {...register('units', { required: true, min: 1, valueAsNumber: true })} step={1} className={clsx({ error: errors?.units })} />
            </div>
          )}
        </div>
        {step === 1 ? (
          <button className={`save-button`} type="submit">
            Siguiente
          </button>
        ) : (
          <div className="d-flex flex-row">
            {threshold ? (
              <button className="previous-step" type="button" onClick={close}>
                Cerrar
              </button>
            ) : (
              <button className="previous-step" type="button" onClick={() => setStep(step - 1)}>
                Anterior
              </button>
            )}
            <button className="next-step" type="submit">
              {step === 2 ? 'Siguiente' : 'Guardar'}
            </button>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default ThresholdModal;
