import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Organization } from '../interfaces/organization';
import { useAppSelector } from '../store/hooks';
import { repairSelector, setReceivedFromTechnicalService, setSentTechnicalService, updateRepairWithWarranty } from '../store/repair-slice';
import { RootState } from '../store/store';
import PinModal from './pin-modal';

const RepairWarrantyInProcess = () => {
  const dispatch = useDispatch();
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { sentTechnicalService, receivedFromTechnicalService, requesting, repair } = useSelector(repairSelector);
  const [showPinModalWarranty, setShowPinModalWarranty] = useState<boolean>(false);

  const onClosePinModalWarranty = async (pin: string | null) => {
    setShowPinModalWarranty(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    dispatch(
      updateRepairWithWarranty({
        organizationId: organization.id,
        pin,
        warranty: false,
      }),
    );
  };

  return (
    <div className="repair-warranty-in-process row">
      <div className="col-6">
        <div className="d-flex flex-column check-switch w-100">
          <label className="subtitle mb-3">Enviado al servicio técnico</label>
          <input
            type="checkbox"
            checked={sentTechnicalService || false}
            disabled={requesting || repair?.sentTechnicalService}
            style={{ cursor: requesting || repair?.sentTechnicalService ? 'not-allowed' : 'pointer' }}
            onChange={(e: any) => dispatch(setSentTechnicalService(e.target.checked))}
          />
        </div>
      </div>
      {repair?.sentTechnicalService && (
        <React.Fragment>
          <div className="col">
            <div className="d-flex flex-column check-switch w-100">
              <label className="subtitle mb-3">Recibido por el servicio técnico</label>
              <input type="checkbox" checked={receivedFromTechnicalService || false} disabled={requesting} onChange={(e: any) => dispatch(setReceivedFromTechnicalService(e.target.checked))} />
            </div>
          </div>
          <div className="my-4 text-center">
            <button disabled={requesting} onClick={() => setShowPinModalWarranty(true)}>
              El producto no está en garantía
            </button>
          </div>
        </React.Fragment>
      )}
      <PinModal show={showPinModalWarranty} onCloseModal={onClosePinModalWarranty} />
    </div>
  );
};

export default RepairWarrantyInProcess;
