import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MakePayment from '../components/make-payment';
import NoStoresAssigned from '../components/no-stores-assigned';
import PosDetails from '../components/pos-details';
import PosProductsFinder from '../components/pos-products-finder';
import SaleSummary from '../components/pos-summary';
import { PosStatus } from '../enums/pos-status';
import { PosStep } from '../enums/pos-step';
import { useStores } from '../hooks/use-stores.hook';
import { Store } from '../interfaces/store';
import { posSelector, reset } from '../store/pos-slice';
import { storeSelector } from '../store/store-slice';

const PosView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { step, sale } = useSelector(posSelector);
  const { store } = useSelector(storeSelector);
  const stores: Store[] = useStores();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (sale) {
      navigate(`/venta/${sale.id}`);
      dispatch(reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sale]);

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (!store) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <h1>Selecciona una tienda</h1>
      </div>
    );
  }

  if (store.posStatus === PosStatus.Closed) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <h1>Abre la caja para realizar ventas</h1>
      </div>
    );
  }

  return (
    <div className="pos inner-layout">
      <div className="l-side">
        {step === PosStep.AddingProducts && <PosProductsFinder />}
        {step === PosStep.Paying && <SaleSummary />}
      </div>
      <div className="r-side">
        {step === PosStep.AddingProducts && <PosDetails />}
        {step === PosStep.Paying && <MakePayment />}
      </div>
    </div>
  );
};

export default PosView;
