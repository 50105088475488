import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { Download, Edit, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import ThresholdModal from '../components/threshold-modal';
import { Role } from '../enums/role';
import { ThresholdType } from '../enums/threshold-type.enum';
import { useStores } from '../hooks/use-stores.hook';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { Threshold } from '../interfaces/threshold';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { SelectColumnFilter } from '../services/table-helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

const ThresholdsView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const tableRef: any = useRef<any>(null);
  const { selectedStoreId } = useSelector(storeSelector);
  const [thresholds, setThresholds] = useState<Threshold[]>([]);
  const [showThresholModal, setShowThresholdModal] = useState<boolean>(false);
  const [selectedThreshold, setSelectedThreshold] = useState<Threshold | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const stores: Store[] = useStores();
  const [type, setType] = useState<ThresholdType>(ThresholdType.Brand);

  const getThresholds = async (type: ThresholdType) => {
    setRequesting(true);
    const ts: Threshold[] = await api.getThresholds(organization!.id, type, selectedStoreId);
    setThresholds(ts);
    setRequesting(false);
  };

  useEffect(() => {
    getThresholds(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId, type]);

  const columnsBrands = useMemo(
    () => [
      {
        Header: 'Marca',
        accessor: 'brand.name',
        Cell: ({ row }: any) => {
          const threshold: Threshold = row.original;
          return <span style={{ color: 'black' }}>{threshold.brand?.name}</span>;
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Colección',
        accessor: 'collection.name',
        Filter: SelectColumnFilter,
        Cell: ({ row }: any) => {
          const threshold: Threshold = row.original;
          return <span style={{ color: 'black' }}>{threshold.collection?.name}</span>;
        },
      },
      {
        Header: 'Tienda',
        accessor: 'store.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Unidades',
        accessor: 'stockUnits',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const threshold: Threshold = row.original;
          return <span style={{ color: threshold.stockUnits <= threshold.units ? '#E02760' : '#26C44B' }}>{threshold.stockUnits}</span>;
        },
      },
      {
        Header: 'Umbrales',
        accessor: 'units',
        disableFilters: true,
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                size={16}
                color="#222E3D"
                onClick={() => {
                  setSelectedThreshold(row.original);
                  setShowThresholdModal(true);
                }}
                type="button"
                className="mx-2"
              />
              <Trash2
                type="button"
                color="#222E3D"
                className="mx-2"
                onClick={() => {
                  setSelectedThreshold(row.original);
                  setShowConfirmModal(true);
                }}
                size={14}
              />
            </React.Fragment>
          );
        },
      },
    ],
    [],
  );

  const columnsCategories = useMemo(
    () => [
      {
        Header: 'Categorías',
        accessor: 'category.name',
        Filter: SelectColumnFilter,
        Cell: ({ row }: any) => {
          const threshold: Threshold = row.original;
          return <span style={{ color: 'black' }}>{threshold.category?.name}</span>;
        },
      },
      {
        Header: 'Subcategorias',
        accessor: 'subCategory.name',
        Filter: SelectColumnFilter,
        Cell: ({ row }: any) => {
          const threshold: Threshold = row.original;
          return <span style={{ color: 'black' }}>{threshold.subCategory?.name}</span>;
        },
      },
      {
        Header: 'Tienda',
        accessor: 'store.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Unidades',
        accessor: 'stockUnits',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const threshold: Threshold = row.original;
          return <span style={{ color: threshold.stockUnits <= threshold.units ? '#E02760' : '#26C44B' }}>{threshold.stockUnits}</span>;
        },
      },
      {
        Header: 'Umbrales',
        accessor: 'units',
        disableFilters: true,
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                size={16}
                color="#222E3D"
                onClick={() => {
                  setSelectedThreshold(row.original);
                  setShowThresholdModal(true);
                }}
                type="button"
                className="mx-2"
              />
              <Trash2
                type="button"
                color="#222E3D"
                className="mx-2"
                onClick={() => {
                  setSelectedThreshold(row.original);
                  setShowConfirmModal(true);
                }}
                size={14}
              />
            </React.Fragment>
          );
        },
      },
    ],
    [],
  );

  const columnsProducts = useMemo(
    () => [
      {
        Header: 'SKU',
        accessor: 'product.sku',
        Cell: ({ row }: any) => {
          const threshold: Threshold = row.original;
          return <span style={{ color: 'black' }}>{threshold.product?.sku}</span>;
        },
      },
      {
        Header: 'Producto',
        accessor: 'product.name',
        Cell: ({ row }: any) => {
          const threshold: Threshold = row.original;
          return <span style={{ color: 'black' }}>{threshold.product?.name}</span>;
        },
      },
      {
        Header: 'Marca',
        accessor: 'product.brand.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Tienda',
        accessor: 'store.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Unidades',
        accessor: 'stockUnits',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const threshold: Threshold = row.original;
          return <span style={{ color: threshold.stockUnits <= threshold.units ? '#E02760' : '#26C44B' }}>{threshold.stockUnits}</span>;
        },
      },
      {
        Header: 'Umbrales',
        accessor: 'units',
        disableFilters: true,
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                size={16}
                color="#222E3D"
                onClick={() => {
                  setSelectedThreshold(row.original);
                  setShowThresholdModal(true);
                }}
                type="button"
                className="mx-2"
              />
              <Trash2
                type="button"
                color="#222E3D"
                className="mx-2"
                onClick={() => {
                  setSelectedThreshold(row.original);
                  setShowConfirmModal(true);
                }}
                size={14}
              />
            </React.Fragment>
          );
        },
      },
    ],
    [],
  );

  const newThreshold = () => {
    setSelectedThreshold(null);
    setShowThresholdModal(true);
  };

  const onThresholdModalClose = (threshold: Threshold | null) => {
    if (threshold) {
      getThresholds(type);
    }
    setShowThresholdModal(false);
    setSelectedThreshold(null);
  };

  const deleteThreshold = async (t: Threshold) => {
    try {
      await api.deleteThreshold(t.id);
      const ts: Threshold[] = [...thresholds];
      const index: number = ts.findIndex((e: Threshold) => e.id === t.id);
      ts.splice(index, 1);
      setThresholds(ts);
      myToastr.success('Umbral eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const threshold: Threshold = fr.original;
      switch (type) {
        case ThresholdType.Brand:
          data.push({
            Marca: threshold.brand.name,
            Colección: threshold.collection?.name ? threshold.collection.name : '',
            Tienda: threshold.store.name,
            Unidades: threshold.stockUnits,
            Umbrales: threshold.units,
          });
          break;
        case ThresholdType.Category:
          data.push({
            Categoría: threshold.category.name,
            Subcategoría: threshold.subCategory?.name ? threshold.subCategory.name : '',
            Tienda: threshold.store.name,
            Unidades: threshold.stockUnits,
            Umbrales: threshold.units,
          });
          break;
        case ThresholdType.Product:
          data.push({
            SKU: threshold.product.sku,
            Producto: threshold.product.name,
            Marca: threshold.brand.name,
            Tienda: threshold.store.name,
            Unidades: threshold.stockUnits,
            Umbrales: threshold.units,
          });
          break;
      }
    });
    setCsvData(data);
  };

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="thresholds p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Umbrales</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="marcas.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv} title="Exportar umbrales">
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
          <button className="d-flex align-items-center create-button" onClick={newThreshold}>
            <Plus className="me-1" size={14} /> Nuevo umbral
          </button>
        )}
        <button className="ms-2 clear-filters" disabled={requesting} onClick={() => tableRef.current?.clearFilters()}>
          Limpiar filtros
        </button>
      </div>
      <Tabs activeKey={type} onSelect={(k) => setType(k as ThresholdType)} className="">
        <Tab eventKey={ThresholdType.Brand} title="Marcas">
          <Table
            ref={tableRef}
            data={thresholds}
            columns={columnsBrands}
            noDataMessage="No hay umbrales"
            onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
            initialState={{
              pageSize: Constants.LIMIT_RESULTS,
            }}
          />
        </Tab>
        <Tab eventKey={ThresholdType.Category} title="Categorías">
          <Table
            ref={tableRef}
            data={thresholds}
            columns={columnsCategories}
            noDataMessage="No hay umbrales"
            onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
            initialState={{
              pageSize: Constants.LIMIT_RESULTS,
            }}
          />
        </Tab>
        <Tab eventKey={ThresholdType.Product} title="Productos">
          <Table
            ref={tableRef}
            data={thresholds}
            columns={columnsProducts}
            noDataMessage="No hay umbrales"
            onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
            initialState={{
              pageSize: Constants.LIMIT_RESULTS,
            }}
          />
        </Tab>
      </Tabs>
      <ThresholdModal show={showThresholModal} closeModal={onThresholdModalClose} threshold={selectedThreshold} />
      {selectedThreshold && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Umbral"
          content={`¿Estás seguro que quieres eliminar el umbral?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteThreshold(selectedThreshold);
            }
            setSelectedThreshold(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default ThresholdsView;
