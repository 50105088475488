import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import Modal from 'react-modal';
import { GetPaginatedStockExtended } from '../interfaces/get-paginated-stock-extended';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { OnlineStore } from '../interfaces/online-store';
import { UpdateOnlineStock } from '../interfaces/update-online-stock';
import { api } from '../services/api';
import myToastr from '../services/toastr';

interface Props {
  getPaginatedStockExtended: GetPaginatedStockExtended;
  onClose: (result: boolean) => void;
  onlineStore: OnlineStore;
  numProducts: number;
  show: boolean;
}

const UpdateOnlineStockModal = ({ getPaginatedStockExtended, onClose, onlineStore, numProducts, show }: Props) => {
  const [enable, setEnable] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setEnable(false);
    }
  }, [show]);

  const close = () => {
    if (requesting) {
      myToastr.warning('No puedes cerrar el modal mientras se está procesando la petición');
      return;
    }
    onClose(false);
  };

  const save = async () => {
    setRequesting(true);
    myToastr.info('Procesando petición...');
    try {
      const updateOnlineStock: UpdateOnlineStock = {
        organizationId: getPaginatedStockExtended.organizationId,
        storeIds: getPaginatedStockExtended.storeIds,
        brandIds: getPaginatedStockExtended.brandIds,
        collectionIds: getPaginatedStockExtended.collectionIds,
        categoryIds: getPaginatedStockExtended.categoryIds,
        query: getPaginatedStockExtended.query,
        inStock: getPaginatedStockExtended.inStock,
        active: getPaginatedStockExtended.active,
        onlineStoreId: onlineStore.id,
        enable,
        online: getPaginatedStockExtended.online,
      };
      await api.updateOnlineStock(updateOnlineStock);
      onClose(true);
      myToastr.success('Se ha procesado la petición correctamente. Los productos se actualizarán en la tienda online en unos minutos.');
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
    setRequesting(false);
  };

  return (
    <Modal className="vercomi-modal my-form update-online-stock-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <div className="content noselect">
        <div className="d-flex justify-content-between mb-3">
          <div className="title w-100 text-center">{onlineStore.name}</div>
          <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
            <X size={16} />
          </button>
        </div>
        <div className="my-3 text-center">
          <p>Se van a actualizar {numProducts} productos en la tienda online.</p>
          <div className="d-flex flex-row justify-content-center align-items-center mb-3">
            <input disabled={requesting} type="checkbox" checked={enable} onChange={(e: any) => setEnable(e.target.checked)} />
            <label className="check-totals ms-2" style={{ color: 'white' }}>
              {enable ? 'Activar productos' : 'Desactivar productos'}
            </label>
          </div>
          <p>¿Estás seguro?</p>
          <p>
            <strong>Esta acción no se podrá interrumpir.</strong>
          </p>
        </div>
      </div>
      <button className={`save-button`} type="button" onClick={save} disabled={requesting}>
        Aceptar
      </button>
    </Modal>
  );
};

export default UpdateOnlineStockModal;
