import JSZip from 'jszip';
import moment, { Moment } from 'moment';
import printJS from 'print-js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Plus, Trash2, Upload } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Row, TableState } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import DateRangeSelector from '../components/date-range-selector';
import DeliveryNoteStatusColumnFilter from '../components/delivery-note-status-column-filter';
import ImportCsvModal from '../components/import-csv-modal';
import ImportElectronicInvoiceModal from '../components/import-electronic-invoice-modal';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import { DateRange } from '../enums/date-range';
import { DeliveryNoteStatus } from '../enums/delivery-note-status';
import { Entity } from '../enums/entity';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import { useStores } from '../hooks/use-stores.hook';
import { DateRangeData } from '../interfaces/date-range-data';
import { DeliveryNote } from '../interfaces/delivery-note';
import { DeliveryNotesQuery } from '../interfaces/delivery-notes-query';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { deliveryNoteStatusToString, formatNumber, getDatesGivenDateRange, sleep } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

interface FiltersSessionStorage {
  dateRange: DateRange;
  startDateStr: string | null;
  endDateStr: string | null;
  // Filtros de la tabla
  internalId: string | null;
  externalNumber: string | null;
  storeName: string | null;
  createdBy: string | null;
  date: string | null;
  invoiceDate: string | null;
  invoiceNumber: string | null;
  updatedBy: string | null;
  verifiedBy: string | null;
  verificationDate: string | null;
  supplierName: string | null;
  status: DeliveryNoteStatus | null;
  units: string | null;
  total: string | null;
}

const dateRange: DateRange = DateRange.ThisYear;

const DeliveryNotesView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const tableRef: any = useRef<any>(null);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const navigate = useNavigate();
  const { selectedStoreId, store } = useSelector(storeSelector);
  const [deliveryNotes, setDeliveryNotes] = useState<DeliveryNote[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showImportCsvModal, setShowImportCsvModal] = useState<boolean>(false);
  const [showImportElectronicInvoiceModal, setShowImportElectronicInvoiceModal] = useState<boolean>(false);
  const [selectedDeliveryNote, setSelectedDeliveryNote] = useState<DeliveryNote | null>(null);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData | null>(null);
  const [tableFilters, setTableFilters] = useState<{ id: string; value: any }[]>([]);
  const stores: Store[] = useStores();
  const showImportElectronicInvoiceButton: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showImportButton: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showCreateDeliveryNoteButton: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const [downloadingFile, setDownloadingFile] = useState<boolean>(false);
  const [selectedDeliveryNotes, setSelectedDeliveryNotes] = useState<{ [id: number]: boolean }>({});

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: '-',
        Cell: ({ row }: any) => {
          const deliveryNote: DeliveryNote = row.original;
          if (!deliveryNote.filePath) {
            return null;
          }
          return (
            <div className="d-flex flex-row justify-content-center">
              <input
                type="checkbox"
                className="text-center"
                style={{ width: 14 }}
                checked={selectedDeliveryNotes[deliveryNote.id] || false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedDeliveryNotes({
                      ...selectedDeliveryNotes,
                      [deliveryNote.id]: true,
                    });
                  } else {
                    const newSelectedDeliveryNotes = { ...selectedDeliveryNotes };
                    delete newSelectedDeliveryNotes[deliveryNote.id];
                    setSelectedDeliveryNotes(newSelectedDeliveryNotes);
                  }
                }}
              />
            </div>
          );
        },
      },
      {
        Header: 'Número',
        id: 'internalId',
        accessor: 'internalId',
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const deliveryNote: DeliveryNote = row.original;
          return (
            <Link to={`/compra/${deliveryNote.id}`} className="link-sale">
              <span className="fw-bold">{deliveryNote.internalId}</span>
            </Link>
          );
        },
      },
      {
        Header: 'Documento',
        id: 'externalNumber',
        accessor: 'externalNumber',
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const deliveryNote: DeliveryNote = row.original;
          let externalNumber = '';
          if (deliveryNote.externalNumber) {
            externalNumber = deliveryNote.externalNumber;
          }
          return externalNumber.length > 30 ? `${externalNumber.slice(0, 30)}...` : externalNumber;
        },
      },
      {
        Header: organizationType === OrganizationType.B2C ? 'Tienda' : 'Almacén',
        id: 'storeName',
        accessor: 'store.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Creado por',
        id: 'createdBy',
        accessor: (deliveryNote: DeliveryNote) => {
          const user: User = deliveryNote.createdBy;
          return user ? `${user.name} ${user.surnames}` : '';
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Confirmado por',
        id: 'verifiedBy',
        accessor: (deliveryNote: DeliveryNote) => {
          const user: User = deliveryNote.verifiedBy;
          return user ? `${user.name} ${user.surnames}` : '';
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Fecha',
        id: 'date',
        accessor: (deliveryNote: DeliveryNote) => moment(deliveryNote.date).format('DD/MM/YYYY'),
        filter: 'fuzzyText',
        sortType: (a: any, b: any) => {
          const momentA = moment(a.original.date);
          const momentB = moment(b.original.date);
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      },
      {
        Header: 'Fecha factura',
        id: 'invoiceDate',
        accessor: (deliveryNote: DeliveryNote) => (moment(deliveryNote.invoiceDate).isValid() ? moment(deliveryNote.invoiceDate).format('DD/MM/YYYY') : ''),
        filter: 'fuzzyText',
        sortType: (a: any, b: any) => {
          const momentA: Moment = moment(a.original.invoiceDate);
          const momentB: Moment = moment(b.original.invoiceDate);
          if (!momentA.isValid() && !momentB.isValid()) {
            return 0;
          }
          if (!momentA.isValid()) {
            return 1;
          }
          if (!momentB.isValid()) {
            return -1;
          }
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      },
      {
        Header: 'Nº Factura',
        id: 'invoiceNumber',
        accessor: 'invoiceNumber',
      },
      {
        Header: 'Recepción',
        id: 'verificationDate',
        filter: 'fuzzyText',
        accessor: (deliveryNote: DeliveryNote) => (deliveryNote.verificationDate ? moment(deliveryNote.verificationDate).format('DD/MM/YYYY HH:mm') : ''),
        Cell: ({ row }: any) => {
          const deliveryNote: DeliveryNote = row.original;
          return deliveryNote.verificationDate ? moment(deliveryNote.verificationDate).format('DD/MM/YYYY HH:mm') : '';
        },
      },
      {
        Header: 'Proveedor',
        id: 'supplierName',
        accessor: 'supplier.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Estado',
        id: 'status',
        accessor: 'status',
        Filter: DeliveryNoteStatusColumnFilter,
        Cell: ({ value }: any) => {
          let className = '';
          switch (value) {
            case DeliveryNoteStatus.Cancelled:
              className = 'delivery-note-cancelled';
              break;
            case DeliveryNoteStatus.Pending:
              className = 'delivery-note-pending';
              break;
            case DeliveryNoteStatus.Received:
              className = 'delivery-note-received';
              break;
            case DeliveryNoteStatus.Invoiced:
              className = 'delivery-note-invoiced';
              break;
          }
          return <span className={`badge ${className}`}>{deliveryNoteStatusToString(value)}</span>;
        },
      },
      {
        Header: 'Unidades',
        id: 'units',
        accessor: 'units',
        filter: 'fuzzyText',
      },
      {
        Header: 'Base Imp.',
        id: 'taxBase',
        filter: 'fuzzyText',
        accessor: 'taxBase',
        Cell: (cell: any) => {
          const deliveryNote: DeliveryNote = cell.row.original;
          return `${formatNumber(deliveryNote.taxBase)}€`;
        },
      },
      {
        Header: 'IVA',
        id: 'vat',
        filter: 'fuzzyText',
        accessor: 'vat',
        Cell: (cell: any) => {
          const deliveryNote: DeliveryNote = cell.row.original;
          return `${formatNumber(deliveryNote.vat)}€`;
        },
      },
      {
        Header: 'Rec. Equiv.',
        id: 'equivalenceSurcharge',
        filter: 'fuzzyText',
        accessor: 'equivalenceSurcharge',
        Cell: (cell: any) => {
          const deliveryNote: DeliveryNote = cell.row.original;
          return `${formatNumber(deliveryNote.equivalenceSurcharge)}€`;
        },
      },
      {
        Header: 'Total',
        id: 'total',
        filter: 'fuzzyText',
        accessor: 'total',
        Cell: (cell: any) => {
          const deliveryNote: DeliveryNote = cell.row.original;
          return `${formatNumber(deliveryNote.total)}€`;
        },
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          const deliveryNote: DeliveryNote = row.original;
          return (
            <div className="d-flex flex-row align-items-center">
              {deliveryNote.filePath && (
                <div title="Descargar documento">
                  <Download type="button" className="mx-2" onClick={() => downloadFile(deliveryNote)} size={14} />
                </div>
              )}
              {deliveryNote.status === DeliveryNoteStatus.Pending && (
                <Trash2
                  type="button"
                  className="mx-2"
                  onClick={() => {
                    setSelectedDeliveryNote(row.original);
                    setShowConfirmModal(true);
                  }}
                  size={14}
                />
              )}
            </div>
          );
        },
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryNotes, selectedStoreId, selectedDeliveryNotes]);

  useEffect(() => {
    const value: string | null = sessionStorage.getItem('search-delivery-notes');
    let drd: DateRangeData = {
      dateRange,
      dates: getDatesGivenDateRange(dateRange),
    };
    const tf: { id: string; value: any }[] = [];
    if (value) {
      try {
        const filtersSessionStorage: FiltersSessionStorage = JSON.parse(value);
        drd = {
          dateRange: filtersSessionStorage.dateRange,
          dates: [
            moment(filtersSessionStorage.startDateStr).isValid() ? moment(filtersSessionStorage.startDateStr).toDate() : null,
            moment(filtersSessionStorage.endDateStr).isValid() ? moment(filtersSessionStorage.endDateStr).toDate() : null,
          ],
        };
        if (filtersSessionStorage.internalId) {
          tf.push({ id: 'internalId', value: filtersSessionStorage.internalId });
        }
        if (filtersSessionStorage.externalNumber) {
          tf.push({ id: 'externalNumber', value: filtersSessionStorage.externalNumber });
        }
        if (filtersSessionStorage.storeName) {
          tf.push({ id: 'storeName', value: filtersSessionStorage.storeName });
        }
        if (filtersSessionStorage.createdBy) {
          tf.push({ id: 'createdBy', value: filtersSessionStorage.createdBy });
        }
        if (filtersSessionStorage.date) {
          tf.push({ id: 'date', value: filtersSessionStorage.date });
        }
        if (filtersSessionStorage.invoiceDate) {
          tf.push({ id: 'invoiceDate', value: filtersSessionStorage.invoiceDate });
        }
        if (filtersSessionStorage.invoiceNumber) {
          tf.push({ id: 'invoiceNumber', value: filtersSessionStorage.invoiceNumber });
        }
        if (filtersSessionStorage.updatedBy) {
          tf.push({ id: 'updatedBy', value: filtersSessionStorage.updatedBy });
        }
        if (filtersSessionStorage.verifiedBy) {
          tf.push({ id: 'verifiedBy', value: filtersSessionStorage.verifiedBy });
        }
        if (filtersSessionStorage.verificationDate) {
          tf.push({ id: 'verificationDate', value: filtersSessionStorage.verificationDate });
        }
        if (filtersSessionStorage.supplierName) {
          tf.push({ id: 'supplierName', value: filtersSessionStorage.supplierName });
        }
        if (filtersSessionStorage.status) {
          tf.push({ id: 'status', value: filtersSessionStorage.status });
        }
        if (filtersSessionStorage.units) {
          tf.push({ id: 'units', value: filtersSessionStorage.units });
        }
        if (filtersSessionStorage.total) {
          tf.push({ id: 'total', value: filtersSessionStorage.total });
        }
      } catch (e) {
        sessionStorage.removeItem('search-delivery-notes');
      }
    }
    setTableFilters(tf);
    setDateRangeData(drd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    getDeliveryNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, selectedStoreId]);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    const filtersSessionStorage: FiltersSessionStorage = {
      dateRange: dateRangeData!.dateRange,
      startDateStr: moment(dateRangeData!.dates[0]).format('YYYY-MM-DD'),
      endDateStr: moment(dateRangeData!.dates[1]).format('YYYY-MM-DD'),
      internalId: tableFilters.find((filter: any) => filter.id === 'internalId')?.value || '',
      externalNumber: tableFilters.find((filter: any) => filter.id === 'externalNumber')?.value || '',
      storeName: tableFilters.find((filter: any) => filter.id === 'storeName')?.value || '',
      createdBy: tableFilters.find((filter: any) => filter.id === 'createdBy')?.value || '',
      date: tableFilters.find((filter: any) => filter.id === 'date')?.value || '',
      invoiceDate: tableFilters.find((filter: any) => filter.id === 'invoiceDate')?.value || '',
      invoiceNumber: tableFilters.find((filter: any) => filter.id === 'invoiceNumber')?.value || '',
      updatedBy: tableFilters.find((filter: any) => filter.id === 'updatedBy')?.value || '',
      verifiedBy: tableFilters.find((filter: any) => filter.id === 'verifiedBy')?.value || '',
      verificationDate: tableFilters.find((filter: any) => filter.id === 'verificationDate')?.value || '',
      supplierName: tableFilters.find((filter: any) => filter.id === 'supplierName')?.value || '',
      status: tableFilters.find((filter: any) => filter.id === 'status')?.value || '',
      units: tableFilters.find((filter: any) => filter.id === 'units')?.value || '',
      total: tableFilters.find((filter: any) => filter.id === 'total')?.value || '',
    };
    sessionStorage.setItem('search-delivery-notes', JSON.stringify(filtersSessionStorage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, tableFilters]);

  const getDeliveryNotes = async () => {
    setRequesting(true);
    const deliveryNotesQuery: DeliveryNotesQuery = {
      startDate: dateRangeData!.dates[0]!,
      endDate: dateRangeData!.dates[1]!,
      organizationId: organization!.id,
      storeId: selectedStoreId,
    };
    const dn: DeliveryNote[] = await api.getDeliveryNotes(deliveryNotesQuery);
    setDeliveryNotes(dn);
    setRequesting(false);
  };

  const deleteDeliveryNote = async (dn: DeliveryNote) => {
    try {
      await api.deleteDeliveryNote(dn.id);
      const supps: DeliveryNote[] = [...deliveryNotes];
      const index: number = supps.findIndex((den: DeliveryNote) => den.id === dn.id);
      supps.splice(index, 1);
      setDeliveryNotes(supps);
      myToastr.success('Compra eliminada correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    const orderedRows: any[] = [...filteredRows];
    orderedRows.sort((a: any, b: any) => {
      const momentA: Moment = moment(a.original.date);
      const momentB: Moment = moment(b.original.date);
      return momentA.isBefore(momentB) ? 1 : -1;
    });
    orderedRows.forEach((fr: any) => {
      const dn: DeliveryNote = fr.original;
      let createdBy: string = dn.createdBy.name;
      if (dn.createdBy.surnames) {
        createdBy += ` ${dn.createdBy.surnames}`;
      }
      let verifiedBy: string = '';
      if (dn.verifiedBy) {
        verifiedBy = dn.verifiedBy.name;
        if (dn.verifiedBy.surnames) {
          verifiedBy += ` ${dn.verifiedBy.surnames}`;
        }
      }
      data.push({
        Número: dn.internalId,
        Document: dn.externalNumber,
        [organizationType === OrganizationType.B2C ? 'Tienda' : 'Almacén']: dn.store.name,
        'Creado por': createdBy,
        'Confirmado por': verifiedBy,
        Fecha: moment(dn.date).format('DD/MM/YYYY'),
        'Fecha factura': dn.invoiceDate ? moment(dn.invoiceDate).format('DD/MM/YYYY') : '',
        'Nº Factura': dn.invoiceNumber,
        Recepción: dn.verificationDate ? moment(dn.verificationDate).format('DD/MM/YYYY') : '',
        Proveedor: dn.supplier.name,
        Estado: deliveryNoteStatusToString(dn.status),
        Unidades: dn.units,
        'Base Imp.': `${formatNumber(dn.taxBase)}€`,
        IVA: `${formatNumber(dn.vat)}€`,
        'Rec. Equiv.': `${formatNumber(dn.equivalenceSurcharge)}€`,
        Total: `${formatNumber(dn.total)}€`,
      });
    });
    setCsvData(data);
  };

  const onCloseImportCsvModal = async (result: boolean) => {
    if (result) {
      getDeliveryNotes();
    }
    setShowImportCsvModal(false);
  };

  const onCloseImportElectronicInvoice = (deliveryNote: DeliveryNote | null) => {
    if (deliveryNote) {
      setDeliveryNotes([deliveryNote, ...deliveryNotes]);
    }
    setShowImportElectronicInvoiceModal(false);
  };

  const onTableStateChange = (state: TableState<any>) => {
    setTableFilters(state.filters);
  };

  const downloadFile = async (deliveryNote: DeliveryNote) => {
    if (downloadingFile) {
      return;
    }
    setDownloadingFile(true);
    const id: JQuery<HTMLElement> = myToastr.info('Obteniendo el documento. Espera por favor.');
    const result: ArrayBuffer = await api.downloadApiFileGivenPath(deliveryNote!.filePath);
    const url: string = window.URL.createObjectURL(new Blob([result]));
    await sleep(2000);
    myToastr.clear(id);
    printJS(url);
    setDownloadingFile(false);
  };

  const downloadMultipleFiles = async () => {
    if (downloadingFile) {
      return;
    }
    setDownloadingFile(true);
    const zip: JSZip = new JSZip();
    for (const deliveryNoteId in selectedDeliveryNotes) {
      const deliveryNote: DeliveryNote = deliveryNotes.find((dn: DeliveryNote) => dn.id === Number(deliveryNoteId))!;
      const result: ArrayBuffer = await api.downloadApiFileGivenPath(deliveryNote!.filePath);
      const fileName: string = deliveryNote.filePath!.split('/').pop()!;
      zip.file(fileName, result);
    }
    const content: Blob = await zip.generateAsync({ type: 'blob' });
    const zipUrl: string = window.URL.createObjectURL(content);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = zipUrl;
    link.download = 'documentos.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadingFile(false);
  };

  if (!dateRangeData) {
    return null;
  }

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="delivery-notes-container p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Compras</h1>
        {showImportElectronicInvoiceButton && (
          <div className="d-flex align-items-center export-csv cursor-pointer" onClick={() => setShowImportElectronicInvoiceModal(true)}>
            <Upload className="me-1" size={14} /> Importar Fact. Electrónica
          </div>
        )}
        {filteredRows.length > 0 && (
          <CSVLink filename={store ? `compras_${store.name}.csv` : 'compras.csv'} className="d-flex align-items-center export-csv" data={csvData} onClick={generateCsv} title="Exportar compras">
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {showImportButton && (
          <button
            className="d-flex align-items-center create-button ms-3"
            onClick={() => {
              if (!store) {
                myToastr.error(organizationType === OrganizationType.B2C ? 'Selecciona una tienda' : 'Selecciona un almacén');
                return;
              }
              setShowImportCsvModal(true);
            }}
          >
            <Upload className="me-1" size={14} /> Importar
          </button>
        )}
        {Object.keys(selectedDeliveryNotes).length > 0 && (
          <span className="d-flex align-items-center export-csv clickable" onClick={downloadMultipleFiles}>
            <Download className="me-1" size={14} /> Descargar documentos
          </span>
        )}
        {showCreateDeliveryNoteButton && (
          <button className="d-flex align-items-center create-button mx-3" onClick={() => navigate('/compra')}>
            <Plus className="me-1" size={14} /> Nueva compra
          </button>
        )}
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={false}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
        <button
          className="clear-filters mx-2"
          disabled={requesting}
          onClick={() => {
            tableRef.current?.clearFilters();
            setDateRangeData({
              dateRange,
              dates: getDatesGivenDateRange(dateRange),
            });
          }}
        >
          Limpiar filtros
        </button>
      </div>
      <Table
        ref={tableRef}
        data={deliveryNotes}
        columns={columns}
        noDataMessage="No hay compras"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          filters: tableFilters,
          sortBy: [{ id: 'date', desc: true }],
          pageSize: Constants.LIMIT_RESULTS,
        }}
        onStateChange={onTableStateChange}
      />
      {selectedDeliveryNote && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar compra"
          content={`¿Estás seguro que quieres eliminar la compra ${selectedDeliveryNote.internalId}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteDeliveryNote(selectedDeliveryNote);
            }
            setSelectedDeliveryNote(null);
          }}
        ></ConfirmModal>
      )}
      <ImportCsvModal show={showImportCsvModal} closeModal={onCloseImportCsvModal} entity={Entity.DeliveryNote} />
      <ImportElectronicInvoiceModal show={showImportElectronicInvoiceModal} closeModal={onCloseImportElectronicInvoice} />
    </div>
  );
};

export default DeliveryNotesView;
