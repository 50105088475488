import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { Family } from '../interfaces/family';
import { FamilyDto } from '../interfaces/family.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';

interface Props {
  family: Family | null;
  show: boolean;
  closeModal: (family: Family | null) => void;
}

const initialValues: FamilyDto = {
  name: '',
};

const FamilyModal = ({ family, show, closeModal }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FamilyDto>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (show && family) {
      reset({
        name: family.name,
      });
    }
  }, [show, family, reset]);

  const close = () => {
    reset(initialValues);
    closeModal(null);
  };

  const onSubmit = async (data: FamilyDto) => {
    try {
      if (family) {
        const updatedFamily: Family = await api.updateFamily(family.id, data);
        closeModal(updatedFamily);
        myToastr.success('Familia actualizada correctamente');
      } else {
        const newFamily: Family = await api.createFamily(data);
        closeModal(newFamily);
        myToastr.success('Familia creada correctamente');
      }
      reset();
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  return (
    <Modal className="vercomi-modal my-form family-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Familia</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.name })}>Nombre *</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default FamilyModal;
