import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PaymentMethod } from '../interfaces/payment-method';
import { Store } from '../interfaces/store';
import { api } from '../services/api';
import { posSlice } from './pos-slice';
import { RootState } from './store';

export interface StoreState {
  selectedStoreId: number;
  store: Store | null;
  showCashRegiserModal: boolean;
}

const initialState: StoreState = {
  selectedStoreId: -1,
  store: null,
  showCashRegiserModal: false,
};

function* getStore(): any {
  try {
    const state: RootState = yield select();
    const selectedStoreId: number = state.store.selectedStoreId;
    if (selectedStoreId > 0) {
      const store: Store = yield api.getStore(selectedStoreId);
      store.paymentMethods.sort((a: PaymentMethod, b: PaymentMethod) => a.id - b.id);
      yield put(storeSlice.actions.setStore(store));
    } else {
      yield put(storeSlice.actions.setStore(null));
    }
    yield put(posSlice.actions.clearShoppingCart());
  } catch (e) {
    yield put(storeSlice.actions.setStore(null));
  }
}

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStoreId: (state: StoreState, action: PayloadAction<number>) => {
      state.selectedStoreId = action.payload;
      localStorage.setItem('storeId', state.selectedStoreId.toString());
    },
    setStore: (state: StoreState, action: PayloadAction<Store | null>) => {
      state.store = action.payload;
    },
    setShowCashRegiserModal: (state: StoreState, action: PayloadAction<boolean>) => {
      state.showCashRegiserModal = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setStoreId, setShowCashRegiserModal } = storeSlice.actions;

export default storeSlice.reducer;

export const storeSelector = (state: RootState) => state.store;

export const storeSaga = function* () {
  yield takeLatest(storeSlice.actions.setStoreId.type, getStore);
  yield takeLatest(storeSlice.actions.setShowCashRegiserModal.type, getStore);
};
