import { AxiosError } from 'axios';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/images/sierra_logo_home.svg';
import { Role } from '../enums/role';
import { AccessToken } from '../interfaces/access-token';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { LoginDto } from '../interfaces/login.dto';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { UserInOrganization } from '../interfaces/user-in-organization';
import { api } from '../services/api';
import { getDefaultPathGivenRole } from '../services/helpers';
import myToastr from '../services/toastr';
import { getOrganization, setUser as setUserAuth } from '../store/auth-slice';
import { setStoreId } from '../store/store-slice';

interface LoginData extends LoginDto {
  organizationId: number;
  storeId: number;
}

const LoginView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [organizationId, setOrganizationId] = useState<number>(-1);

  useEffect(() => {
    const token: string | null = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!location.state) {
      return;
    }
    const state: any = location.state;
    // Limpoiar el estado de la ruta
    navigate(location.pathname, { replace: true });
    if (!state.message || !state.title || !state.type) {
      return;
    }
    (myToastr as any)[state.type](state.message, state.title, {
      closeButton: true,
      timeOut: 0,
      extendedTimeOut: 0,
      tapToDismiss: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (!user) {
      setTimeout(() => clearErrors(), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<LoginData>({
    defaultValues: {
      username: '',
      password: '',
      organizationId: -1,
      storeId: -1,
    },
  });

  const userInOrganization: UserInOrganization | null = useMemo(() => {
    if (!user) {
      return null;
    }
    return user.userInOrganizations.find((uio: UserInOrganization) => uio.organizationId === watch('organizationId'))!;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, watch('organizationId')]);

  const stores: Store[] = useMemo(() => {
    if (!user || !userInOrganization) {
      return [];
    }
    return user.stores.filter((s: Store) => s.organizationId === userInOrganization.organizationId).sort((a: Store, b: Store) => a.name.localeCompare(b.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userInOrganization]);

  const onSubmit = async (loginData: LoginData) => {
    myToastr.clear();
    if (user !== null) {
      if (organizationId === -1) {
        if (loginData.organizationId === -1) {
          myToastr.error('Debes seleccionar una organización');
          return;
        }
        const uio: UserInOrganization = user.userInOrganizations.find((uio: UserInOrganization) => uio.organizationId === loginData.organizationId)!;
        const ss: Store[] = user.stores.filter((s: Store) => s.organizationId === uio.organizationId);
        if (uio.role === Role.SuperAdmin || uio.role === Role.Admin || uio.role === Role.Manager) {
          dispatch(getOrganization(loginData.organizationId));
          if (ss.length === 1) {
            dispatch(setStoreId(ss[0].id));
          }
          const defaultPath: string = getDefaultPathGivenRole(uio.role);
          dispatch(setUserAuth(user));
          navigate(defaultPath, { replace: true });
          return;
        }
        if (ss.length === 1) {
          dispatch(getOrganization(loginData.organizationId));
          // El usuario sólo tiene asignado una tienda
          dispatch(setStoreId(ss[0].id));
          const defaultPath: string = getDefaultPathGivenRole(uio.role);
          dispatch(setUserAuth(user));
          navigate(defaultPath, { replace: true });
          return;
        }
        setOrganizationId(organizationId);
        return;
      }
      const uio: UserInOrganization = user.userInOrganizations.find((uio: UserInOrganization) => uio.organizationId === loginData.organizationId)!;
      dispatch(getOrganization(loginData.organizationId));
      // El usuario ha seleccionado una tienda
      dispatch(setStoreId(loginData.storeId));
      const defaultPath: string = getDefaultPathGivenRole(uio.role);
      dispatch(setUserAuth(user));
      navigate(defaultPath, { replace: true });
      return;
    }
    try {
      const loginDto: LoginDto = {
        username: loginData.username.trim(),
        password: loginData.password.trim(),
      };
      const accessToken: AccessToken = await api.login(loginDto);
      localStorage.setItem('token', accessToken.token);
      localStorage.setItem('refreshToken', accessToken.refreshToken);
      const user: User = await api.me();
      setUser(user);
      user.userInOrganizations.sort((a: UserInOrganization, b: UserInOrganization) => a.organization.name.localeCompare(b.organization.name));
      if (user.userInOrganizations.length === 0) {
        // El usuario no tiene organizaciones
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
      } else if (user.userInOrganizations.length === 1) {
        // El usuario pertenece a una sóla organización
        const uio: UserInOrganization = user.userInOrganizations[0];
        const ss: Store[] = user.stores.filter((s: Store) => s.organizationId === uio.organizationId);
        if (ss.length <= 1 || uio.role === Role.SuperAdmin || uio.role === Role.Admin || uio.role === Role.Manager) {
          dispatch(getOrganization(uio.organizationId));
          if (ss.length === 1) {
            // El usuario sólo tiene asignado una tienda
            dispatch(setStoreId(ss[0].id));
          }
          const defaultPath: string = getDefaultPathGivenRole(uio.role);
          dispatch(setUserAuth(user));
          navigate(defaultPath, { replace: true });
          return;
        }
        setValue('organizationId', uio.organizationId);
        setOrganizationId(uio.organizationId);
      }
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
        reset({
          username: loginData.username,
          password: '',
        });
      }
    }
  };

  return (
    <div className="login my-form">
      <LazyLoadImage src={logo} alt="" className="home-logo" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Iniciar sesión</div>
          </div>
          {user !== null ? (
            <div className="d-flex flex-column">
              {organizationId === -1 && (
                <div className="row my-3">
                  <div className="col">
                    {user.userInOrganizations.length === 0 ? (
                      <div className="error-message">No perteneces a ninguna organización. Contacta con el administrador.</div>
                    ) : (
                      <React.Fragment>
                        <div className={'input-name ' + (errors?.storeId ? 'error' : '')}>Organización *</div>
                        <select {...register('organizationId', { required: true, valueAsNumber: true })} className={clsx({ error: errors?.organizationId })}>
                          <option value={-1}>Selecciona una organización</option>
                          {user.userInOrganizations.map((uio: UserInOrganization) => (
                            <option key={uio.id} value={uio.organizationId}>
                              {uio.organization.name}
                            </option>
                          ))}
                        </select>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}
              {organizationId !== -1 && userInOrganization && (userInOrganization.role === Role.Seller || userInOrganization.role === Role.Business) && (
                <div className="row my-3">
                  <div className="col">
                    {stores.length === 0 ? (
                      <div className="error-message">
                        {userInOrganization.role === Role.Seller
                          ? 'No tienes ninguna tienda asignada. Contacta con el administrador.'
                          : 'No tienes ningún almacén asignado. Contacta con el administrador. '}
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className={'input-name ' + (errors?.storeId ? 'error' : '')}>{userInOrganization.role === Role.Seller ? 'Tienda' : 'Almacén'} *</div>
                        <select {...register('storeId', { required: true, valueAsNumber: true, min: 1 })} className={clsx({ error: errors?.storeId })}>
                          <option value={-1}>{userInOrganization.role === Role.Seller ? 'Selecciona una tienda' : 'Selecciona un almacén'}</option>
                          {stores.map((store: Store) => (
                            <option key={store.id} value={store.id}>
                              {store.name}
                            </option>
                          ))}
                        </select>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <React.Fragment>
              <div className="row my-3">
                <div className="col">
                  <div className={'input-name ' + (errors?.username ? 'error' : '')}>Nombre de usuario *</div>
                  <input type="text" {...register('username', { required: true })} className={clsx({ error: errors?.username })} placeholder="Nombre de usuario" />
                  {errors.username && <div className="error-message">{errors.username.message}</div>}
                </div>
              </div>
              <div className="row my-3">
                <div className="col">
                  <div className={'input-name ' + (errors?.password ? 'error' : '')}>Contraseña *</div>
                  <input
                    type="password"
                    {...register('password', {
                      required: true,
                      minLength: { value: 8, message: 'La contraseña debe contener al menos 8 caracteres' },
                      maxLength: { value: 50, message: 'La contraseña debe contener como máximo 50 caracteres' },
                    })}
                    className={clsx({ error: errors?.password })}
                    placeholder="Contraseña"
                  />
                  {errors.password && <div className="error-message">{errors.password.message}</div>}
                </div>
              </div>
              <div className="text-center">
                <Link to="/recover-password" className="recover-password">
                  ¿Has olvidado tu contraseña?
                </Link>
              </div>
            </React.Fragment>
          )}
        </div>
        {!user ? (
          <button className={`save-button`} type="submit">
            Iniciar sesión
          </button>
        ) : user.userInOrganizations.length > 0 ? (
          stores.length > 0 ||
          userInOrganization?.role === Role.SuperAdmin ||
          userInOrganization?.role === Role.Admin ||
          userInOrganization?.role === Role.Manager ||
          userInOrganization?.role === Role.Business ? (
            <button className={`save-button`} type="submit">
              Acceder
            </button>
          ) : (
            <button
              className={`save-button`}
              type="button"
              onClick={() => {
                setOrganizationId(-1);
                setUser(null);
                reset();
              }}
            >
              Volver
            </button>
          )
        ) : (
          <button
            className={`save-button`}
            type="button"
            onClick={() => {
              setUser(null);
              setOrganizationId(-1);
              reset();
            }}
          >
            Volver
          </button>
        )}
      </form>
    </div>
  );
};

export default LoginView;
