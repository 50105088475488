import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { Organization } from '../interfaces/organization';
import { Supplier } from '../interfaces/supplier';
import { SupplierDto } from '../interfaces/supplier.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  supplier: Supplier | null;
  show: boolean;
  closeModal: (supplier: Supplier | null) => void;
}

export interface CustomSupplierDto {
  name: string;
  businessName: string;
  cif: string;
  address: string;
  phone: string;
}

const initialValues = {
  name: '',
  businessName: '',
  cif: '',
  address: '',
  phone: '',
};

const SupplierModal = (props: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CustomSupplierDto>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!props.show) {
      return;
    }
    getOrganizations();
  }, [props.show]);

  useEffect(() => {
    if (organizations.length === 0 || props.supplier === null) {
      return;
    }
    reset({
      name: props.supplier.name,
      businessName: props.supplier.businessName,
      cif: props.supplier.cif,
      address: props.supplier.address,
      phone: props.supplier.phone,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations, props.supplier]);

  const getOrganizations = async () => {
    try {
      const organizations: Organization[] = await api.getOrganizations();
      organizations.sort((a: Organization, b: Organization) => a.name.localeCompare(b.name));
      setOrganizations(organizations);
    } catch (e) {
      myToastr.error('Error al obtener las organizaciones');
    }
  };

  const close = () => {
    reset();
    props.closeModal(null);
  };

  const onSubmit = async (data: CustomSupplierDto) => {
    const supplierDto: SupplierDto = {
      name: data.name,
      businessName: data.businessName,
      cif: data.cif,
      address: data.address,
      phone: data.phone,
      organizationId: organization.id,
    };
    try {
      if (props.supplier) {
        const supplier: Supplier = await api.updateSupplier(props.supplier.id, supplierDto);
        props.closeModal(supplier);
      } else {
        const supplier: Supplier = await api.createSupplier(supplierDto);
        props.closeModal(supplier);
      }
      reset();
    } catch (e: any) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError?.response) {
        if (Array.isArray(axiosError.response.data.message)) {
          myToastr.error(axiosError.response.data.message.join('</br>'));
        } else {
          myToastr.error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form supplier-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Proveedor</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.name ? 'error' : '')}>Nombre *</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.businessName })}>Razón Social *</div>
              <input type="text" {...register('businessName', { required: true })} placeholder="Razón social" className={clsx({ error: errors?.businessName })} />
              {errors.businessName && <div className="error-message">{errors.businessName.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.phone })}>Teléfono *</div>
              <input type="number" {...register('phone', { required: true })} placeholder="Teléfono" className={clsx({ error: errors?.phone })} />
              {errors.phone && <div className="error-message">{errors.phone.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.cif })}>CIF *</div>
              <input type="text" {...register('cif', { required: true })} className={clsx({ error: errors?.cif })} placeholder="CIF" />
              {errors.cif && <div className="error-message">{errors.cif.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.address })}>Dirección *</div>
              <input type="text" {...register('address', { required: true })} className={clsx({ error: errors?.address })} placeholder="Dirección" />
              {errors.address && <div className="error-message">{errors.address.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default SupplierModal;
