import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useStores } from '../hooks/use-stores.hook';
import { DeliveryNote } from '../interfaces/delivery-note';
import { ImportElectronicInvoiceDto } from '../interfaces/import-electronic-invoice.dto';
import { Store } from '../interfaces/store';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { storeSelector } from '../store/store-slice';

interface Props {
  show: boolean;
  closeModal: (deliveryNote: DeliveryNote | null) => void;
}

const initialValues: ImportElectronicInvoiceDto = {
  storeId: -1,
  fileList: null,
};

const ImportElectronicInvoiceModal = (props: Props) => {
  const { selectedStoreId } = useSelector(storeSelector);
  const [requesting, setRequesting] = useState<boolean>(false);
  const stores: Store[] = useStores();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ImportElectronicInvoiceDto>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!props.show) {
      reset(initialValues);
    } else {
      reset({ ...initialValues, storeId: selectedStoreId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show, reset]);

  const close = () => {
    reset();
    props.closeModal(null);
  };

  const onSubmit = async (importElectronicInvoiceDto: ImportElectronicInvoiceDto) => {
    myToastr.info('Importando factura electrónica. Espere por favor.');
    setRequesting(true);
    try {
      const deliveryNote: DeliveryNote = await api.importElectronicInvoice(importElectronicInvoiceDto);
      myToastr.remove();
      myToastr.success('Factura electrónica importada correctamente');
      props.closeModal(deliveryNote);
    } catch (e: any) {
      myToastr.remove();
      myToastr.error(e.response.data.message);
    }
    setRequesting(false);
  };

  return (
    <Modal className="vercomi-modal my-form import-electronic-invoice-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Importar Factura Electrónica</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.storeId })}>Tienda *</div>
              <select {...register('storeId', { required: true, min: 1, valueAsNumber: true })} className={clsx({ error: errors?.storeId })}>
                <option value={-1}>Selecciona una tienda</option>
                {stores.map((store: Store) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
              </select>
              {errors.storeId && <div className="error-message">{errors.storeId.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.fileList })}>Fichero XML *</div>
              <input
                type="file"
                {...register('fileList', { required: true })}
                className={clsx({ error: errors?.fileList })}
                accept="text/xml"
                onChange={(e: any) => {
                  if (e.target.files[0].type !== 'text/xml') {
                    myToastr.error('El fichero debe ser un XML');
                    setValue('fileList', null);
                  }
                }}
                onClick={(e: any) => {
                  e.target.value = null;
                }}
              />
              {errors.fileList && <div className="error-message">{errors.fileList.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} disabled={requesting} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default ImportElectronicInvoiceModal;
