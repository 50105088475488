import moment from 'moment';
import { useSelector } from 'react-redux';
import { formatNumber } from '../services/helpers';
import { repairSelector } from '../store/repair-slice';

const RepairBudgetInfo = () => {
  const { repair } = useSelector(repairSelector);

  return (
    <div className="repair-summary">
      <div className="resume">
        <div className="title">Presupuesto</div>
        <div className="body d-flex flex-column my-2">
          <label className="date mb-2">{moment(repair?.createdAt).format('YYYY-MM-DD HH:mm')}</label>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <label className="me-1">Precio de coste:</label>
              <span className="prices">{formatNumber(repair?.costPrice)}€</span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <label className="me-1">Precio reparación:</label>
              <span className="prices">{formatNumber(repair?.repairPrice)}€</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepairBudgetInfo;
