import { AlertTriangle } from 'react-feather';

const RepairRejectedBudget = () => {
  return (
    <div className="repair-rejected-budget">
      <div className="d-flex flex-row align-items-center">
        <div className="me-3">
          <AlertTriangle color="white" size={20} />
        </div>
        <span>Presupuesto no aceptado</span>
      </div>
    </div>
  );
};

export default RepairRejectedBudget;
