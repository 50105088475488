import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronLeft, Image } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import Loader from 'react-loader-spinner';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '../components/confirm-modal';
import Table from '../components/table';
import { DeliveryNoteStatus } from '../enums/delivery-note-status';
import { OrganizationType } from '../enums/organization-type';
import { PurchaseOrderStatus } from '../enums/purchase-order-status';
import { Role } from '../enums/role';
import { Organization } from '../interfaces/organization';
import { ProductImage } from '../interfaces/product-image';
import { PurchaseOrder } from '../interfaces/purchase-order';
import { PurchaseOrderProduct } from '../interfaces/purchase-order-product';
import { User } from '../interfaces/user';
import { Constants } from '../services/constants';
import { ellipsis, formatNumber, purchaseOrderStatusStr, roundTwoDecimals } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  getPurchaseOrder as getPurchaseOrderStore,
  purchaseOrderSelector,
  reset,
  revisionPurchaseOrder,
  setSentUnits,
  setShippingAddress,
  setShippingCity,
  setShippingCountry,
  setShippingProvince,
  setShippingZipCode,
  updatePurchaseOrder,
} from '../store/purchase-order-slice';
import { RootState } from '../store/store';

interface Totals {
  requestedUnits: number;
  finalUnits: number;
  taxBase: number;
  totalVat: number;
  totalSurchargeEquivalence: number;
  total: number;
}

const PurchaseOrderView = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const [requesting, setRequesting] = useState<boolean>(true);
  const [showCreateDeliveryNoteModal, setShowCreatelDeliveryNoteModal] = useState<boolean>(false);
  const {
    hasEquivalenceSurcharge: equivalenceSurcharge,
    purchaseOrder,
    shippingCountry,
    shippingAddress,
    shippingZipCode,
    shippingCity,
    shippingProvince,
    requesting: requestingStore,
    sentProducts,
  } = useAppSelector(purchaseOrderSelector);
  const [errorShippingCountry, setErrorShippingCountry] = useState<boolean>(false);
  const [errorShippingAddress, setErrorShippingAddress] = useState<boolean>(false);
  const [errorShippingZipCode, setErrorShippingZipCode] = useState<boolean>(false);
  const [errorShippingCity, setErrorShippingCity] = useState<boolean>(false);
  const [errorShippingProvince, setErrorShippingProvince] = useState<boolean>(false);
  const inputFinalUnitsRef = useRef<any>(null);
  const internalId: string | null = useMemo(() => {
    if (!purchaseOrder) {
      return null;
    }
    return purchaseOrder.originOrganizationId === organization.id ? purchaseOrder.originInternalId : purchaseOrder.destinationInternalId;
  }, [organization, purchaseOrder]);
  const columns = useMemo(() => {
    if (!purchaseOrder) {
      return [];
    }
    const data: any[] = [
      {
        Header: 'Sku',
        accessor: 'product.sku',
      },
      {
        Header: 'Producto',
        accessor: 'product.name',
        Cell: ({ row }: any) => {
          const purchaseOrderProduct: PurchaseOrderProduct = row.original;
          return (
            <div className="d-flex align-items-center">
              <div className="product-image me-2">
                {purchaseOrderProduct.product.images.length > 0 ? (
                  <LightgalleryProvider>
                    {purchaseOrderProduct.product.images.map((productImage: ProductImage, index: number) => {
                      return (
                        <LightgalleryItem key={productImage.id} group="group-product-images" src={process.env.REACT_APP_PUBLIC_URL + productImage.path}>
                          {index === 0 && <LazyLoadImage src={process.env.REACT_APP_PUBLIC_URL + productImage.path} alt="" className="img-thumbnail" />}
                        </LightgalleryItem>
                      );
                    })}
                  </LightgalleryProvider>
                ) : (
                  <Image size={50} className="img-thumbnail my-1 me-2" color="#D3D3D3" />
                )}
              </div>
              <Link to={`/producto/${purchaseOrderProduct.productId}`} className="link-sale">
                {ellipsis(purchaseOrderProduct.product.name, 50)}
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'Marca',
        accessor: 'product.brand.name',
      },
      {
        Header: 'Colección',
        accessor: 'product.collection.name',
      },
    ];
    if (user.role === Role.SuperAdmin || ((user.role === Role.Admin || user.role === Role.Manager) && purchaseOrder?.originOrganizationId === organization.id)) {
      data.push({
        Header: 'Coste unitario',
        accessor: 'stock.price',
        Cell: ({ row }: any) => {
          const purchaseOrderProduct: PurchaseOrderProduct = row.original;
          return <span>{formatNumber(purchaseOrderProduct.stock.price)}€</span>;
        },
      });
    }
    data.push({
      Header: 'PVM',
      accessor: 'retailPrice',
      Cell: ({ row }: any) => {
        const purchaseOrderProduct: PurchaseOrderProduct = row.original;
        return <span>{formatNumber(purchaseOrderProduct.retailPrice)}€</span>;
      },
    });
    data.push({
      Header: 'Uds. Solicitadas',
      accessor: 'requestedUnits',
    });
    if (purchaseOrder.status === PurchaseOrderStatus.Generated && purchaseOrder.originOrganizationId === organization.id && user.role === Role.SuperAdmin) {
      data.push({
        Header: 'Uds. Disponibles',
        accessor: 'stock.quantity',
      });
    }
    if (purchaseOrder.status !== PurchaseOrderStatus.Draft) {
      data.push({
        Header: 'Uds. Enviadas',
        accessor: 'finalUnits',
        Cell: ({ row }: any) => {
          const purchaseOrderProduct: PurchaseOrderProduct = row.original;
          if (purchaseOrder.status === PurchaseOrderStatus.Generated && purchaseOrder.originOrganizationId === organization.id) {
            return (
              <input
                className="final-units"
                type="number"
                min={0}
                max={purchaseOrderProduct.stock.quantity}
                value={sentProducts[purchaseOrderProduct.id]}
                onChange={(e) => {
                  inputFinalUnitsRef.current = purchaseOrderProduct.id;
                  const finalUnits: number | undefined = e.target.value ? parseInt(e.target.value, 10) : undefined;
                  dispatch(setSentUnits({ id: purchaseOrderProduct.id, finalUnits }));
                }}
                autoFocus={inputFinalUnitsRef.current === purchaseOrderProduct.id}
                step={1}
              />
            );
          } else {
            return <span>{purchaseOrder.status === PurchaseOrderStatus.DeliveryNote || purchaseOrder.status === PurchaseOrderStatus.Invoice ? purchaseOrderProduct.finalUnits : '-'}</span>;
          }
        },
      });
    }
    data.push({
      Header: 'Total',
      accessor: (purchaseOrderProduct: PurchaseOrderProduct) => {
        let total = 0;
        if (
          purchaseOrder.status === PurchaseOrderStatus.Draft ||
          (purchaseOrder.status === PurchaseOrderStatus.Generated && purchaseOrder.destinationOrganizationId === organization.id) ||
          purchaseOrder.status === PurchaseOrderStatus.Cancelled
        ) {
          total = purchaseOrderProduct.requestedUnits * purchaseOrderProduct.retailPrice;
        } else if (purchaseOrder.status === PurchaseOrderStatus.Generated && purchaseOrder.originOrganizationId === organization.id) {
          total = (sentProducts[purchaseOrderProduct.id] || 0) * purchaseOrderProduct.retailPrice;
        } else {
          total = purchaseOrderProduct.finalUnits * purchaseOrderProduct.retailPrice;
        }
        return `${formatNumber(total)}€`;
      },
    });
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, purchaseOrder, sentProducts]);
  const totals: Totals = useMemo(() => {
    const result: Totals = {
      requestedUnits: 0,
      finalUnits: 0,
      taxBase: 0,
      totalVat: 0,
      totalSurchargeEquivalence: 0,
      total: 0,
    };
    if (!purchaseOrder) {
      return result;
    }
    purchaseOrder.purchaseOrderProducts.forEach((purchaseOrderProduct: PurchaseOrderProduct) => {
      result.requestedUnits += purchaseOrderProduct.requestedUnits;
      let finalUnits = sentProducts[purchaseOrderProduct.id] || 0;
      if (purchaseOrder.status === PurchaseOrderStatus.Draft) {
        finalUnits = purchaseOrderProduct.requestedUnits;
      } else if (purchaseOrder.status === PurchaseOrderStatus.Generated && purchaseOrder.originOrganizationId === organization.id) {
        finalUnits = sentProducts[purchaseOrderProduct.id] || 0;
      } else if (purchaseOrder.status === PurchaseOrderStatus.Cancelled) {
        finalUnits = 0;
      }
      result.finalUnits += finalUnits;
      result.taxBase += finalUnits * purchaseOrderProduct.retailPrice;
    });
    result.taxBase = roundTwoDecimals(result.taxBase);
    result.totalVat = roundTwoDecimals((result.taxBase * parseFloat(process.env.REACT_APP_IVA)) / 100);
    result.totalSurchargeEquivalence = equivalenceSurcharge ? roundTwoDecimals((result.taxBase * parseFloat(process.env.REACT_APP_EQUIVALENCE_SURCHARGE)) / 100) : 0;
    result.total = roundTwoDecimals(result.taxBase + result.totalVat + result.totalSurchargeEquivalence);
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrder, equivalenceSurcharge, sentProducts]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!params.id) {
      return;
    }
    const id: number = parseInt(params.id);
    if (isNaN(id)) {
      navigate(organizationType === OrganizationType.B2C ? '/ordenes-compra' : '/pedidos');
      return;
    }
    getPurchaseOrder(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, organizationType]);

  const getPurchaseOrder = async (id: number) => {
    const result = await dispatch(getPurchaseOrderStore(id));
    if (!result.payload) {
      navigate(organizationType === OrganizationType.B2C ? '/ordenes-compra' : '/pedidos');
      return;
    }
    setRequesting(false);
  };

  const generatePurchaseOrder = async () => {
    if (!shippingAddress) {
      myToastr.error('Debes indicar una dirección de envío');
      setErrorShippingAddress(true);
      return;
    }
    if (!shippingZipCode) {
      myToastr.error('Debes indicar un código postal de envío');
      setErrorShippingZipCode(true);
      return;
    }
    if (!shippingCity) {
      myToastr.error('Debes indicar una ciudad de envío');
      setErrorShippingCity(true);
      return;
    }
    if (!shippingProvince) {
      myToastr.error('Debes indicar una provincia de envío');
      setErrorShippingProvince(true);
      return;
    }
    if (!shippingCountry) {
      myToastr.error('Debes indicar un país de envío');
      setErrorShippingCountry(true);
      return;
    }
    const updatedPurchaseOrder: PurchaseOrder = (await dispatch(updatePurchaseOrder(PurchaseOrderStatus.Generated))) as any;
    if (!updatedPurchaseOrder) {
      return;
    }
    myToastr.success(organizationType === OrganizationType.B2C ? 'Órden de compra generada' : 'Pedido generado');
    navigate(organizationType === OrganizationType.B2C ? '/ordenes-compra' : '/pedidos');
  };

  const savePurchaseOrder = async () => {
    const result = await dispatch(revisionPurchaseOrder(PurchaseOrderStatus.Generated));
    if (!result.payload) {
      return;
    }
    myToastr.success(organizationType === OrganizationType.B2C ? 'Órden de compra actualizada' : 'Pedido actualizado');
  };

  const onCloseCreateDeliveryNoteModal = async (result: boolean) => {
    if (!result) {
      setShowCreatelDeliveryNoteModal(false);
      return;
    }
    await dispatch(revisionPurchaseOrder(PurchaseOrderStatus.DeliveryNote));
    setShowCreatelDeliveryNoteModal(false);
  };

  const createInvoice = async () => {
    await dispatch(revisionPurchaseOrder(PurchaseOrderStatus.Invoice));
  };

  const modifyDraftPurchaseOrder = async () => {
    navigate(organizationType === OrganizationType.B2C ? `/orden-compra/${purchaseOrder!.id}/actualizar` : `/pedido/${purchaseOrder!.id}/actualizar`);
  };

  if (requesting) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <Loader type="TailSpin" color="#252E3C" height={60} width={60} />
      </div>
    );
  }

  if (!purchaseOrder) {
    return null;
  }

  return (
    <div className="purchase-order-view d-flex flex-column p-4">
      <div className="d-flex flex-row align-items-center">
        <div onClick={() => navigate(organizationType === OrganizationType.B2C ? '/ordenes-compra' : '/pedidos')} className="d-flex flex-row align-items-center come-back">
          <ChevronLeft className="me-1" size={14} />
          <span>Volver</span>
        </div>
        <div className="flex-grow-1 container-title d-flex flex-column justify-content-center">
          <h1 className="text-center">
            {organizationType === OrganizationType.B2C ? 'Órden de compra' : 'Pedido'} {internalId}
          </h1>
          <span className="text-center centered-item datetime mb-1">Fecha creación: {moment(purchaseOrder.createdAt).format('YYYY-MM-DD HH:mm')}</span>
          {purchaseOrder.status === PurchaseOrderStatus.DeliveryNote && purchaseOrder.originOrganizationId === organization.id ? (
            <span className={clsx('purchase-order-status clickable', purchaseOrder.status, 'd-inline-block')} onClick={() => navigate(`/albaran/${purchaseOrder.deliveryNoteId}`)}>
              {purchaseOrderStatusStr(purchaseOrder.status)} {purchaseOrder.deliveryNote.internalId}
            </span>
          ) : purchaseOrder.status === PurchaseOrderStatus.DeliveryNote && purchaseOrder.destinationOrganizationId === organization.id ? (
            <span className={clsx('purchase-order-status clickable', purchaseOrder.status, 'd-inline-block')} onClick={() => navigate(`/compra/${purchaseOrder.deliveryNoteId}`)}>
              {purchaseOrderStatusStr(purchaseOrder.status)} {purchaseOrder.deliveryNote.internalId}
            </span>
          ) : (
            <span className={clsx('purchase-order-status', purchaseOrder.status, 'd-inline-block')}>{purchaseOrderStatusStr(purchaseOrder.status)}</span>
          )}
        </div>
        {purchaseOrder.status === PurchaseOrderStatus.Draft && organization.id === purchaseOrder.createdByOrganizationId && (
          <React.Fragment>
            <button className="modify-draft-purhcase-order-button" onClick={modifyDraftPurchaseOrder}>
              Modificar carrito
            </button>
            <button disabled={requestingStore} className="finalize-button" onClick={generatePurchaseOrder}>
              Generar
            </button>
          </React.Fragment>
        )}
        {purchaseOrder.status === PurchaseOrderStatus.Generated && organization.id === purchaseOrder.originOrganizationId && (
          <React.Fragment>
            <button disabled={requestingStore} className="finalize-button" onClick={savePurchaseOrder}>
              Guardar cambios
            </button>
            <button disabled={requestingStore} className="finalize-button mx-2" onClick={() => setShowCreatelDeliveryNoteModal(true)}>
              Crear albarán
            </button>
            <button disabled={requestingStore} className="finalize-button" onClick={createInvoice}>
              Crear factura
            </button>
          </React.Fragment>
        )}
        {purchaseOrder.status === PurchaseOrderStatus.DeliveryNote && purchaseOrder.deliveryNote.status !== DeliveryNoteStatus.Cancelled && organization.id === purchaseOrder.originOrganizationId && (
          <button disabled={requestingStore} className="finalize-button" onClick={createInvoice}>
            Crear factura
          </button>
        )}
      </div>
      <div className="container-info my-4 d-flex flex-row">
        <div className="d-flex flex-column">
          <span className="title">Mayorista:</span>
          <span className="value">
            {purchaseOrder.originOrganization.name} - {purchaseOrder.originOrganization.cif}
          </span>
          <span className="value">
            {purchaseOrder.originOrganization.address}, {purchaseOrder.originOrganization.postalCode} {purchaseOrder.originOrganization.city ? `(${purchaseOrder.originOrganization.city})` : ''}
          </span>
          <span className="value">
            {purchaseOrder.originOrganization.province} {purchaseOrder.originOrganization.country}
          </span>
          {purchaseOrder.originOrganization.phone && <span className="value">{purchaseOrder.originOrganization.phone}</span>}
          {purchaseOrder.originOrganization.email && <span className="value">{purchaseOrder.originOrganization.email}</span>}
        </div>
        <div className="d-flex flex-column ms-4" style={{ minWidth: 700 }}>
          {purchaseOrder.status !== PurchaseOrderStatus.Draft && (
            <React.Fragment>
              <span className="title">Cliente:</span>
              <span className="value">
                {purchaseOrder.destinationOrganization.name} - {purchaseOrder.destinationOrganization.cif}
              </span>
            </React.Fragment>
          )}
          {purchaseOrder.status === PurchaseOrderStatus.Draft && organization.id === purchaseOrder.createdByOrganizationId ? (
            <div className="d-flex flex-column">
              <span className="title">Entregar en:</span>
              <div className="row my-2">
                <div className="col-8">
                  <input
                    className={clsx({ error: errorShippingAddress })}
                    value={shippingAddress}
                    onChange={(e) => {
                      dispatch(setShippingAddress(e.target.value));
                      setErrorShippingAddress(false);
                    }}
                    type="text"
                    placeholder="Dirección"
                  />
                </div>
                <div className="col-4">
                  <input
                    className={clsx({ error: errorShippingZipCode })}
                    value={shippingZipCode}
                    onChange={(e) => {
                      dispatch(setShippingZipCode(e.target.value));
                      setErrorShippingZipCode(false);
                    }}
                    type="text"
                    placeholder="Código postal"
                  />
                </div>
              </div>
              <div className="row my-2">
                <div className="col">
                  <input
                    className={clsx({ error: errorShippingCity })}
                    value={shippingCity}
                    onChange={(e) => {
                      dispatch(setShippingCity(e.target.value));
                      setErrorShippingCity(false);
                    }}
                    type="text"
                    placeholder="Ciudad"
                  />
                </div>
                <div className="col">
                  <input
                    className={clsx({ error: errorShippingProvince })}
                    value={shippingProvince}
                    onChange={(e) => {
                      dispatch(setShippingProvince(e.target.value));
                      setErrorShippingProvince(false);
                    }}
                    type="text"
                    placeholder="Provincia"
                  />
                </div>
                <div className="col">
                  <input
                    className={clsx({ error: errorShippingCountry })}
                    value={shippingCountry}
                    onChange={(e) => {
                      dispatch(setShippingCountry(e.target.value));
                      setErrorShippingCountry(false);
                    }}
                    type="text"
                    placeholder="País"
                  />
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <span className="title">Entregar en:</span>
              <span className="value">
                {purchaseOrder.shippingAddress}, {purchaseOrder.shippingZipCode} {purchaseOrder.destinationOrganization.city ? `(${purchaseOrder.destinationOrganization.city})` : ''}
              </span>
              <span className="value">
                {purchaseOrder.shippingCity} {purchaseOrder.shippingCountry}
              </span>
              {purchaseOrder.destinationOrganization.phone && <span className="value">{purchaseOrder.destinationOrganization.phone}</span>}
              {purchaseOrder.destinationOrganization.email && <span className="value">{purchaseOrder.destinationOrganization.email}</span>}
            </React.Fragment>
          )}
        </div>
      </div>
      <Table
        data={purchaseOrder.purchaseOrderProducts}
        columns={columns}
        noDataMessage={organizationType === OrganizationType.B2C ? 'No hay órdenes compras' : 'No hay pedidos'}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <div className="resume-totals mt-4">
        <table className="">
          <thead>
            <tr>
              <th>Uds. Solicitadas</th>
              <th>Uds. Enviadas</th>
              <th>Base Imponible</th>
              <th>IVA ({formatNumber(parseFloat(process.env.REACT_APP_IVA))}%)</th>
              <th>Rec Equi. ({formatNumber(parseFloat(process.env.REACT_APP_EQUIVALENCE_SURCHARGE))}%)</th>
              <th>Total €</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>{formatNumber(totals.requestedUnits, true)}</div>
              </td>
              <td>
                <div>{formatNumber(totals.finalUnits, true)}</div>
              </td>
              <td>
                <div>{formatNumber(totals.taxBase)}€</div>
              </td>
              <td>
                <div>{formatNumber(totals.totalVat)}€</div>
              </td>
              <td>
                <div>{formatNumber(totals.totalSurchargeEquivalence)}€</div>
              </td>
              <td>
                <div className="selected">{formatNumber(totals.total)}€</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ConfirmModal
        acceptButtonClass="accept-button"
        show={showCreateDeliveryNoteModal}
        title="Confirmar crear albarán"
        content={`<p>¿Estás seguro que quieres crear el albarán?</p>`}
        closeModal={onCloseCreateDeliveryNoteModal}
      />
    </div>
  );
};

export default PurchaseOrderView;
