import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Check, Download, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Row } from 'react-table';
import AcceptOrganizationRelationModal from '../components/accept-organization-relation-modal';
import ConfirmModal from '../components/confirm-modal';
import OrganizationClientModal from '../components/organization-client-modal';
import Table from '../components/table';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { OrganizationClient } from '../interfaces/organization-client';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const OrganizationClientsView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const [organizationClients, setOrganizationClients] = useState<OrganizationClient[]>([]);
  const [showOrganizationClientModal, setShowOrganizationClientModal] = useState<boolean>(false);
  const [selectedOrganizationClient, setSelectedOrganizationClient] = useState<OrganizationClient | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showAcceptOrganizationRelationModal, setShowAcceptOrganizationRelationModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const isWholeSaler: boolean = useMemo(() => organizationType === OrganizationType.B2B, [organizationType]);

  const getOrganizationClients = async () => {
    setRequesting(true);
    const ocs: OrganizationClient[] = await api.getOrganizationClients(organization.id);
    setOrganizationClients(ocs);
    setRequesting(false);
  };

  useEffect(() => {
    getOrganizationClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const columns = React.useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Nombre',
        filter: 'fuzzyText',
        accessor: (organizationClient: OrganizationClient) =>
          organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.name : organizationClient.organization.name,
      },
      {
        Header: 'CIF',
        filter: 'fuzzyText',
        accessor: (organizationClient: OrganizationClient) =>
          organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.cif : organizationClient.organization.cif,
      },
      {
        Header: 'Razón social',
        filter: 'fuzzyText',
        accessor: (organizationClient: OrganizationClient) =>
          organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.businessName : organizationClient.organization.businessName,
      },
      {
        Header: 'Teléfono',
        filter: 'fuzzyText',
        accessor: (organizationClient: OrganizationClient) =>
          organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.phone : organizationClient.organization.phone,
      },
      {
        Header: 'Email',
        filter: 'fuzzyText',
        accessor: (organizationClient: OrganizationClient) =>
          organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.email : organizationClient.organization.email,
      },
      {
        Header: 'Dirección',
        filter: 'fuzzyText',
        accessor: (organizationClient: OrganizationClient) =>
          organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.address : organizationClient.organization.address,
      },
      {
        Header: 'Ciudad',
        filter: 'fuzzyText',
        accessor: (organizationClient: OrganizationClient) =>
          organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.city : organizationClient.organization.city,
      },
      {
        Header: 'Código postal',
        filter: 'fuzzyText',
        accessor: (organizationClient: OrganizationClient) =>
          organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.postalCode : organizationClient.organization.postalCode,
      },
      {
        Header: 'Provincia',
        filter: 'fuzzyText',
        accessor: (organizationClient: OrganizationClient) =>
          organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.province : organizationClient.organization.province,
      },
      {
        Header: 'País',
        filter: 'fuzzyText',
        accessor: (organizationClient: OrganizationClient) =>
          organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.country : organizationClient.organization.country,
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          const organizationClient: OrganizationClient = row.original;
          return (
            <div className="d-flex flex-row">
              {organization.id === organizationClient.relatedOrganizationId && user.role !== Role.WholesaleCustomer && (
                <div title="Aceptar relación con la organización">
                  <Check
                    type="button"
                    className="mx-2"
                    onClick={() => {
                      setSelectedOrganizationClient(organizationClient);
                      setShowAcceptOrganizationRelationModal(true);
                    }}
                    size={14}
                  />
                </div>
              )}
              {user.role === Role.SuperAdmin && (
                <Trash2
                  type="button"
                  className="mx-2"
                  onClick={() => {
                    setSelectedOrganizationClient(organizationClient);
                    setShowConfirmModal(true);
                  }}
                  size={14}
                />
              )}
            </div>
          );
        },
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWholeSaler]) as any;

  const newOrganization = () => {
    setSelectedOrganizationClient(null);
    setShowOrganizationClientModal(true);
  };

  const onOrganizationModalClose = (organization: OrganizationClient | null) => {
    if (organization) {
      getOrganizationClients();
    }
    setShowOrganizationClientModal(false);
    setSelectedOrganizationClient(null);
  };

  const onAcceptOrganizationRelationModalClose = (organization: OrganizationClient | null) => {
    if (organization) {
      getOrganizationClients();
    }
    setShowAcceptOrganizationRelationModal(false);
    setSelectedOrganizationClient(null);
  };

  const deleteOrganizationClient = async (o: OrganizationClient) => {
    try {
      await api.deleteOrganizationClient(o.id);
      const orgs: OrganizationClient[] = [...organizationClients];
      const index: number = orgs.findIndex((org: OrganizationClient) => org.id === o.id);
      orgs.splice(index, 1);
      setOrganizationClients(orgs);
      myToastr.success('Cliente eliminado correctamente');
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const organizationClient: OrganizationClient = fr.original;
      data.push({
        Nombre: organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.name : organizationClient.organization.name,
        CIF: organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.cif : organizationClient.organization.cif,
        'Razón social': organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.businessName : organizationClient.organization.businessName,
        Teléfono: organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.phone : organizationClient.organization.phone,
        Email: organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.email : organizationClient.organization.email,
        Dirección: organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.address : organizationClient.organization.address,
        Ciudad: organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.city : organizationClient.organization.city,
        'Código postal': organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.postalCode : organizationClient.organization.postalCode,
        Provincia: organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.province : organizationClient.organization.province,
        País: organizationClient.organizationId === organization.id ? organizationClient.relatedOrganization.country : organizationClient.organization.country,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">{isWholeSaler ? 'Clientes' : 'Mayoristas'}</h1>
        {filteredRows.length > 0 && isWholeSaler && (
          <CSVLink filename="organizaciones.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && isWholeSaler && (
          <button className="d-flex align-items-center create-button" onClick={newOrganization}>
            <Plus className="me-1" size={14} /> Nuevo cliente
          </button>
        )}
      </div>
      <Table
        data={organizationClients}
        columns={columns}
        noDataMessage={`No hay ${isWholeSaler ? 'clientes' : 'mayoristas'}`}
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <OrganizationClientModal show={showOrganizationClientModal} closeModal={onOrganizationModalClose} />
      {selectedOrganizationClient !== null && (
        <AcceptOrganizationRelationModal organizationClient={selectedOrganizationClient} show={showAcceptOrganizationRelationModal} closeModal={onAcceptOrganizationRelationModalClose} />
      )}
      {selectedOrganizationClient && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Organización"
          content={`¿Estás seguro que quieres eliminar el cliente ${
            selectedOrganizationClient.organizationId === organization.id ? selectedOrganizationClient.relatedOrganization.name : selectedOrganizationClient.organization.name
          }?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteOrganizationClient(selectedOrganizationClient);
            }
            setSelectedOrganizationClient(null);
          }}
        />
      )}
    </div>
  );
};

export default OrganizationClientsView;
