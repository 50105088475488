import EventEmitter from 'eventemitter3';
import { LocalEvents } from '../enums/local-events';

const eventEmitter = new EventEmitter();

const Emitter = {
  on: (localEvent: LocalEvents, fn: any) => eventEmitter.on(localEvent, fn),
  once: (localEvent: LocalEvents, fn: any) => eventEmitter.once(localEvent, fn),
  off: (localEvent: LocalEvents, fn?: any) => eventEmitter.off(localEvent, fn),
  emit: (localEvent: LocalEvents, payload?: any) => eventEmitter.emit(localEvent, payload),
};

Object.freeze(Emitter);

export default Emitter;
