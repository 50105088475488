import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { roundTwoDecimals } from '../services/helpers';

interface Props {
  weight: number | null;
  show: boolean;
  closeModal: (weight: number | null, costPrice: number | null) => void;
}

interface FormData {
  weight: number | null;
  pricePerGram: number | null;
}

const initialData: FormData = {
  weight: null,
  pricePerGram: null,
};

const ProductWeightModal = ({ weight, show, closeModal }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: initialData,
  });

  useEffect(() => {
    if (!show) {
      return;
    }
    reset(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!show) {
      return;
    }
    setValue('weight', weight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weight, show]);

  const close = () => {
    closeModal(null, null);
  };

  const onSubmit = async (formData: FormData) => {
    const { weight, pricePerGram } = formData;
    const costPrice: number = roundTwoDecimals(weight! * pricePerGram!);
    closeModal(weight, costPrice);
  };

  return (
    <Modal className="vercomi-modal my-form brand-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Cálculo precio de coste</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.weight })}>Peso (gr.) *</div>
              <input type="number" step="0.01" {...register('weight', { required: true, min: 0 })} className={clsx({ error: errors?.weight })} placeholder="Peso (gr.)" />
              {errors.weight && <div className="error-message">{errors.weight.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.pricePerGram })}>Precio (€)/gr. *</div>
              <input type="number" step="0.01" {...register('pricePerGram', { required: true, min: 0 })} className={clsx({ error: errors?.pricePerGram })} placeholder="Precio (€)/gr." />
              {errors.pricePerGram && <div className="error-message">{errors.pricePerGram.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default ProductWeightModal;
