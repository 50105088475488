import clsx from 'clsx';
import React, { useState } from 'react';
import { Plus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { SaleStep } from '../enums/sale-step';
import { Customer } from '../interfaces/customer';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { clearShoppingCart, saleSelector, setNotes, setStep, updateSaleCustomer } from '../store/sale-slice';
import { storeSelector } from '../store/store-slice';
import CustomerInfo from './customer-info';
import NoteModal from './note-modal';
import { ReturnsAndExchangesTable } from './returns-and-exchange-table';
import SaleSubtotalsReturns from './sale-subtotals-returns';

const SaleDetails = () => {
  const dispatch = useDispatch();
  const { balance, customer, productsToReturn, manualProductPendingToAdd, manualProductsToReturn, total, notes, newProducts } = useSelector(saleSelector);
  const { selectedStoreId } = useSelector(storeSelector);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);

  const onCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  const onSaveNoteModal = (notes: string | null) => {
    dispatch(setNotes(notes));
    setShowNoteModal(false);
  };

  const onDeleteNoteModal = () => {
    dispatch(setNotes(null));
    setShowNoteModal(false);
  };

  return (
    <React.Fragment>
      <div className="sale-details h-100 d-flex flex-column position-relative">
        <div className="mb-auto">
          <div className="px-4 pt-3">
            <div className="d-flex justify-content-between align-items-center mb-3 noselect">
              <span onClick={() => dispatch(clearShoppingCart())} className="button-header" title="Cancelar cambios y devoluciones">
                Cancelar
              </span>
              <h1 className="mb-0">Cambios y devoluciones</h1>
              <span onClick={() => setShowNoteModal(true)} className={clsx('d-flex align-items-center button-header', { 'has-notes': notes != null && notes !== '' })}>
                {notes != null && notes !== '' ? (
                  '1 Nota'
                ) : (
                  <React.Fragment>
                    <Plus className="me-1" size={14} />
                    Nota
                  </React.Fragment>
                )}
              </span>
            </div>
            <div className="position-relative mb-3">
              <CustomerInfo balance={balance} customer={customer!} onAssignCustomer={(newCustomer: Customer) => dispatch(updateSaleCustomer(newCustomer.id))} />
            </div>
            <ReturnsAndExchangesTable enableActions={true} />
            <SaleSubtotalsReturns />
          </div>
        </div>
        <div className="container-buttons">
          <button
            className={clsx(newProducts.length > 0 ? 'change-button' : 'return-button', { disabled: (productsToReturn.length === 0 && manualProductsToReturn.length === 0) || selectedStoreId === -1 })}
            onClick={() => {
              if (selectedStoreId === -1) {
                myToastr.error('Selecciona una tienda');
                return;
              }
              if (productsToReturn.length === 0 && manualProductsToReturn.length === 0) {
                myToastr.info('Selecciona al menos un producto para devolución');
                return;
              }
              if (manualProductPendingToAdd) {
                myToastr.info('No has añadido el producto manual al carrito');
                return;
              }
              dispatch(setStep(SaleStep.Payment));
            }}
          >
            {newProducts.length > 0 ? 'Cambio' : 'Devolver'}
          </button>
          <button className="total-info">Total: {formatNumber(total)}€</button>
        </div>
      </div>
      <NoteModal editable={true} show={showNoteModal} notes={notes} closeModal={onCloseNoteModal} saveNotes={onSaveNoteModal} deleteNotes={onDeleteNoteModal} />
    </React.Fragment>
  );
};

export default SaleDetails;
