import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Edit, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import SupplierModal from '../components/supplier-modal';
import Table from '../components/table';
import { Organization } from '../interfaces/organization';
import { Supplier } from '../interfaces/supplier';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const SuppliersView = () => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [showSupplierModal, setShowSupplierModal] = useState<boolean>(false);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);

  useEffect(() => {
    getSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSuppliers = async () => {
    setRequesting(true);
    const suppliers: Supplier[] = await api.getSuppliers(organization.id);
    setSuppliers(suppliers);
    setRequesting(false);
  };

  const columns = useMemo(() => {
    const columnsData: any[] = [
      { Header: 'Nombre', accessor: 'name', filter: 'fuzzyText' },
      { Header: 'Razón social', accessor: 'businessName', filter: 'fuzzyText' },
      { Header: 'CIF', accessor: 'cif', filter: 'fuzzyText' },
      { Header: 'Dirección', accessor: 'address', filter: 'fuzzyText' },
      { Header: 'Teléfono', accessor: 'phone', filter: 'fuzzyText' },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => (
          <React.Fragment>
            <Edit
              size={16}
              className="mx-2"
              color="#222E3D"
              onClick={() => {
                setSelectedSupplier(row.original);
                setShowSupplierModal(true);
              }}
              type="button"
            />
            <Trash2
              type="button"
              className="mx-2"
              onClick={() => {
                setSelectedSupplier(row.original);
                setShowConfirmModal(true);
              }}
              size={14}
            />
          </React.Fragment>
        ),
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) as any;

  const newSupplier = () => {
    setSelectedSupplier(null);
    setShowSupplierModal(true);
  };

  const onCloseCustomerModal = (supplier: Supplier | null) => {
    if (supplier) {
      getSuppliers();
    }
    setShowSupplierModal(false);
    setSelectedSupplier(null);
  };

  const deleteSupplier = async (s: Supplier) => {
    try {
      await api.deleteSupplier(s.id);
      const supps: Supplier[] = [...suppliers];
      const index: number = supps.findIndex((usr: Supplier) => usr.id === s.id);
      supps.splice(index, 1);
      setSuppliers(supps);
      myToastr.success('Proveedor eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const supplier: Supplier = fr.original;
      data.push({
        Nombre: supplier.name,
        'Razón social': supplier.businessName,
        CIF: supplier.cif,
        Dirección: supplier.address,
        Teléfono: supplier.phone,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Proveedores</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="proveedores.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <button className="d-flex align-items-center create-button" onClick={newSupplier}>
          <Plus className="me-1" size={14} /> Nuevo proveedor
        </button>
      </div>
      <Table
        data={suppliers}
        columns={columns}
        noDataMessage="No hay proveedores"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <SupplierModal show={showSupplierModal} closeModal={onCloseCustomerModal} supplier={selectedSupplier} />
      {selectedSupplier && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Proveedor"
          content={`¿Estás seguro que quieres eliminar el proveedor ${selectedSupplier.name}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteSupplier(selectedSupplier);
            }
            setSelectedSupplier(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default SuppliersView;
