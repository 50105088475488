export enum Role {
  Admin = 'admin', // Administrador
  Business = 'business', // Comercial
  Manager = 'manager', // Gerente
  Seller = 'seller', // Vendedor
  // TODO: eliminar las 2 siguientes lineas
  StoreAdmin = 'store-admin', // Administrador de la organización minotirsta
  StoreManager = 'store-manager', // Administrador de la tienda
  SuperAdmin = 'super-admin', // Super administrador
  WholesaleCustomer = 'wholesale-customer', // Cliente mayorista
}
