import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { OrganizationType } from '../enums/organization-type';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { User } from '../interfaces/user';
import { UserInOrganization } from '../interfaces/user-in-organization';
import { api } from '../services/api';
import { alertsSlice } from './alerts-slice';
import { notificationsSlice } from './notifications-slice';
import { posSlice } from './pos-slice';
import { purchaseOrderSlice } from './purchase-order-slice';
import { repairSlice } from './repair-slice';
import { saleSlice } from './sale-slice';
import { AppThunk, RootState } from './store';
import { storeSlice } from './store-slice';

export interface AuthState {
  user: User | null;
  organization: Organization | null;
  organizationType: OrganizationType | null;
  loading: boolean;
  connectedToWs: boolean;
}

const initialState: AuthState = {
  user: null,
  organization: null,
  organizationType: null,
  loading: true,
  connectedToWs: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOrganization: (state: AuthState, action: PayloadAction<Organization | null>) => {
      state.organization = action.payload;
    },
    setOrganizationType: (state: AuthState, action: PayloadAction<OrganizationType>) => {
      state.organizationType = action.payload;
      localStorage.setItem('organizationType', action.payload);
    },
    setUser: (state: AuthState, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.loading = false;
    },
    reset: (state: AuthState) => {
      state.user = null;
      state.organization = null;
      state.loading = false;
      state.connectedToWs = false;
    },
    setConnectedToWs: (state: AuthState, action: PayloadAction<boolean>) => {
      state.connectedToWs = action.payload;
    },
    setRole: (state: AuthState) => {
      if (!state.user || !state.organization) {
        return;
      }
      const userInOrganization: UserInOrganization | undefined = state.user.userInOrganizations.find(
        (userInOrganization: UserInOrganization) => userInOrganization.organizationId === state.organization!.id,
      );
      if (userInOrganization) {
        state.user.role = userInOrganization.role;
      }
    },
  },
});

export const getUserInfo =
  (): AppThunk<AuthState> =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(authSlice.actions.setLoading(true));
      const user: User = await api.me();
      dispatch(authSlice.actions.setUser(user));
      dispatch(authSlice.actions.setLoading(false));
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError?.response?.data?.statusCode === 401) {
        dispatch(logoutAction());
      }
    }
  };

export const getOrganization =
  (organizationId: number): AppThunk<AuthState> =>
  async (dispatch): Promise<Organization | null> => {
    if (organizationId <= 0) {
      dispatch(authSlice.actions.setOrganization(null));
      return null;
    }
    localStorage.setItem('organizationId', organizationId.toString());
    try {
      const organization: Organization = await api.getOrganization(organizationId);
      dispatch(authSlice.actions.setOrganization(organization));
      return organization;
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError?.response?.data?.statusCode === 401) {
        dispatch(logoutAction());
      }
      return null;
    }
  };

export const logoutAction =
  (): AppThunk<AuthState> =>
  async (dispatch): Promise<void> => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(alertsSlice.actions.reset());
    dispatch(authSlice.actions.reset());
    dispatch(storeSlice.actions.reset());
    dispatch(notificationsSlice.actions.reset());
    dispatch(posSlice.actions.reset());
    dispatch(repairSlice.actions.reset());
    dispatch(saleSlice.actions.reset());
    dispatch(purchaseOrderSlice.actions.reset());
  };

export const { setOrganizationType, setRole, setUser, setConnectedToWs } = authSlice.actions;

export default authSlice.reducer;

export const authSelector = (state: RootState) => state.auth;
