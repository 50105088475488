import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { Image, Search, X } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { Order } from '../enums/order';
import { GetPaginatedStock } from '../interfaces/get-paginated-stock';
import { ManualSaleProduct } from '../interfaces/manual-sale-product';
import { Organization } from '../interfaces/organization';
import { PaginatedDto } from '../interfaces/paginated.dto';
import { ReplaceManualSaleProductDto } from '../interfaces/replace-manual-sale-product.dto';
import { Stock } from '../interfaces/stock';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { useAppSelector } from '../store/hooks';
import { replaceManualSaleProduct } from '../store/sale-slice';
import { RootState } from '../store/store';
import PinModal from './pin-modal';
import Table from './table';

interface Props {
  storeId: number;
  manualSaleProduct: ManualSaleProduct;
  show: boolean;
  closeModal: () => void;
}

const debouncedGetStock = debounce(async (getPaginatedStock: GetPaginatedStock, cb: (result: PaginatedDto<Stock>) => void) => {
  const paginatedDto: PaginatedDto<Stock> = await api.getPaginatedPosStock(getPaginatedStock);
  cb(paginatedDto);
}, 500);

const ReplaceManualSaleProductModal = ({ manualSaleProduct, show, closeModal, storeId }: Props) => {
  const dispatch = useDispatch();
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const [query, setQuery] = useState<string>('');
  const [paginatedDto, setPaginatedDto] = useState<PaginatedDto<Stock> | null>(null);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [selectedStockId, setSelectedStockId] = useState<number>(-1);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);
  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Seleccionar',
        accessor: 'id',
        width: '100px',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const s: Stock = row.original;
          return <input type="radio" name="stockId" value={s.id} onChange={(e: any) => setSelectedStockId(parseInt(e.target.value, 10))} />;
        },
      },
      {
        Header: 'Imagen',
        accessor: 'product.images',
        Cell: ({ row }: any) => {
          const s: Stock = row.original;
          return s.product.images.length > 0 ? (
            <LazyLoadImage className="img-thumbnail" src={process.env.REACT_APP_PUBLIC_URL + s.product.images[0].path} alt={s.product.name} width="50px" />
          ) : (
            <Image className="img-thumbnail" size={50} color="#808A95" />
          );
        },
        disableFilters: true,
        width: '60px',
      },
      {
        Header: 'Nombre',
        accessor: 'product.name',
        disableFilters: true,
        width: '200px',
      },
      {
        Header: 'SKU',
        accessor: 'product.sku',
        disableFilters: true,
      },
      {
        Header: 'Unidades',
        accessor: 'quantity',
        disableFilters: true,
        width: '60px',
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedDto?.results]);

  useEffect(() => {
    if (!show) {
      setPaginatedDto(null);
    }
  }, [show]);

  useEffect(() => {
    if (!query) {
      setPaginatedDto(null);
      return;
    }

    setRequesting(true);
    setPaginatedDto(null);
    const getPaginatedStock: GetPaginatedStock = {
      organizationId: organization.id,
      orderField: 'product.name',
      order: Order.Asc,
      limit: Constants.LIMIT_RESULTS,
      page: 1,
      query,
      brandId: -1,
      categoryId: -1,
      storeId,
    };
    debouncedGetStock(getPaginatedStock, (pdto: PaginatedDto<Stock>) => {
      setPaginatedDto(pdto);
      setRequesting(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const close = () => {
    closeModal();
  };

  const onClosePinModal = async (pin: string | null) => {
    if (!pin) {
      setShowPinModal(false);
      return;
    }
    const replaceManualSaleProductDto: ReplaceManualSaleProductDto = {
      organizationId: organization.id,
      pin,
      manualSaleProductId: manualSaleProduct.id,
      stockId: selectedStockId,
    };
    dispatch(replaceManualSaleProduct(replaceManualSaleProductDto));
    closeModal();
  };

  return (
    <Modal className="vercomi-modal my-form replace-manual-sale-product-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">{manualSaleProduct.name}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="position-relative mb-2">
              <Search className="position-absolute end-0 top-50 translate-middle-y me-4" />
              <input type="text" className="form-control" placeholder="Buscar producto en el stock" value={query} onChange={(e: any) => setQuery(e.target.value)} />
            </div>
          </div>
          {requesting && (
            <div className="text-center mt-2">
              <Loader type="TailSpin" color="white" height={30} width={30} />
            </div>
          )}
          {paginatedDto !== null && (
            <div className="table-products">
              <Table
                data={paginatedDto.results}
                columns={columns}
                noDataMessage="No hay productos"
                initialState={{
                  pageSize: 5,
                }}
              />
            </div>
          )}
        </div>
        <button onClick={() => setShowPinModal(true)} className={`save-button`} type="button" disabled={selectedStockId === -1}>
          Reemplazar
        </button>
      </form>
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
    </Modal>
  );
};

export default ReplaceManualSaleProductModal;
