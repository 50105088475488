import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ColorResult, SketchPicker } from 'react-color';
import { Plus, X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import { StoreType } from '../enums/store-type';
import { TypeOfContract } from '../enums/type-of-contract.enum';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { UserInOrganization } from '../interfaces/user-in-organization';
import { UserDto } from '../interfaces/user.dto';
import { api } from '../services/api';
import { isEmail, roleToString } from '../services/helpers';
import myToastr from '../services/toastr';
import { setUser } from '../store/auth-slice';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { styleMultiSelectInModal } from '../styless/multiselect-style';
import CustomMenuDropdown from '../views/custom-menu-dropdown';
import CustomToggleDropdown from './custom-toggle-dropdown';

interface Props {
  user: User | null;
  show: boolean;
  closeModal: (user: User | null) => void;
}

interface CustomCreateUserDto {
  username: string;
  name: string;
  surnames: string;
  role: Role | '';
  active: boolean;
  organizationId: number;
  phone: string | null;
  email: string;
  birthDate: string | null;
  nid: string | null;
  socialSecurityNumber: string | null;
  address: string | null;
  secondaryAddress: string | null;
  province: string | null;
  postalCode: string | null;
  color: string | null;
  bankAccount: string | null;
  swiftBic: string | null;
  emergencyContactName: string | null;
  emergencyContactPhone: string | null;
  daysOff: number | null;
  holidays: number | null;
  typeOfContract: TypeOfContract | null;
  trialPeriodStartDate: string | null;
  trialPeriodEndDate: string | null;
  contractEndDate: string | null;
  stores: { label: string; value: number }[];
}

const defaultValues: CustomCreateUserDto = {
  username: '',
  name: '',
  surnames: '',
  role: '',
  active: true,
  organizationId: -1,
  phone: null,
  email: '',
  birthDate: null,
  nid: null,
  socialSecurityNumber: null,
  address: null,
  secondaryAddress: null,
  province: null,
  postalCode: null,
  color: null,
  bankAccount: null,
  swiftBic: null,
  emergencyContactName: null,
  emergencyContactPhone: null,
  daysOff: null,
  holidays: null,
  typeOfContract: null,
  trialPeriodStartDate: null,
  trialPeriodEndDate: null,
  contractEndDate: null,
  stores: [],
};

const UserModal = (props: Props) => {
  const dispatch = useDispatch();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const [organizationStores, setOrganizationStores] = useState<Store[]>([]);
  const inputPhotoRef = useRef<any>(null);
  const [showColorPickerDropdown, setShowColorPickerDropdown] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  useEffect(() => {
    if (!props.show) {
      return;
    }
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      getOrganizationStores();
    }
    if (props.user) {
      getUser();
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show, props.user]);

  const getOrganizationStores = async () => {
    try {
      const stores: Store[] = await api.getOrganizationStores(organization.id);
      setOrganizationStores(stores);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getUser = async () => {
    try {
      const u: User = await api.getUser(props.user!.id);
      const userInOrganization: UserInOrganization | undefined = u.userInOrganizations.find((uio: UserInOrganization) => uio.organizationId === organization.id);
      reset({
        username: u.username,
        name: u.name,
        surnames: u.surnames,
        role: userInOrganization ? userInOrganization.role : '',
        active: u.active,
        organizationId: organization.id,
        phone: u.phone,
        email: u.email,
        birthDate: u.birthDate ? moment(u.birthDate).format('YYYY-MM-DD') : null,
        nid: u.nid,
        socialSecurityNumber: u.socialSecurityNumber,
        address: u.address,
        secondaryAddress: u.secondaryAddress,
        province: u.province,
        postalCode: u.postalCode,
        color: u.color,
        bankAccount: u.bankAccount,
        swiftBic: u.swiftBic,
        emergencyContactName: u.emergencyContactName,
        emergencyContactPhone: u.emergencyContactPhone,
        daysOff: u.daysOff,
        holidays: u.holidays,
        typeOfContract: u.typeOfContract,
        trialPeriodStartDate: u.trialPeriodStartDate ? moment(u.trialPeriodStartDate).format('YYYY-MM-DD') : null,
        trialPeriodEndDate: u.trialPeriodEndDate ? moment(u.trialPeriodEndDate).format('YYYY-MM-DD') : null,
        contractEndDate: u.contractEndDate ? moment(u.contractEndDate).format('YYYY-MM-DD') : null,
        stores: u.stores.filter((s: Store) => s.organizationId === organization.id).map((s: Store) => ({ value: s.id, label: s.name })),
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const updateUserImage = async (file: File) => {
    try {
      const u: User = await api.updateUserImage(props.user!.id, file);
      dispatch(setUser({ ...u, imagePath: u.imagePath }));
      myToastr.success('Imagen actualizada correctamente');
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
  };

  const deleteUserImage = async () => {
    try {
      const u: User = await api.deleteUserImage(props.user!.id);
      dispatch(setUser({ ...u, imagePath: u.imagePath }));
      myToastr.success('Imagen eliminada correctamente');
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
  };

  const close = () => {
    reset();
    props.closeModal(null);
  };

  const onSubmit = async (customCreateUserDto: CustomCreateUserDto) => {
    const userDto: UserDto = {
      username: customCreateUserDto.username,
      name: customCreateUserDto.name,
      surnames: customCreateUserDto.surnames,
      role: customCreateUserDto.role as Role,
      active: customCreateUserDto.active,
      organizationId: organization.id,
      phone: customCreateUserDto.phone,
      email: customCreateUserDto.email,
      birthDate: customCreateUserDto.birthDate,
      nid: customCreateUserDto.nid,
      socialSecurityNumber: customCreateUserDto.socialSecurityNumber,
      address: customCreateUserDto.address,
      secondaryAddress: customCreateUserDto.secondaryAddress,
      province: customCreateUserDto.province,
      postalCode: customCreateUserDto.postalCode,
      color: customCreateUserDto.color,
      bankAccount: customCreateUserDto.bankAccount,
      swiftBic: customCreateUserDto.swiftBic,
      emergencyContactName: customCreateUserDto.emergencyContactName,
      emergencyContactPhone: customCreateUserDto.emergencyContactPhone,
      daysOff: customCreateUserDto.daysOff,
      holidays: customCreateUserDto.holidays,
      typeOfContract: customCreateUserDto.typeOfContract,
      trialPeriodStartDate: customCreateUserDto.trialPeriodStartDate,
      trialPeriodEndDate: customCreateUserDto.trialPeriodEndDate,
      contractEndDate: customCreateUserDto.contractEndDate,
      storeIds: customCreateUserDto.stores.map((s: { label: string; value: number }) => s.value),
    };
    try {
      if (props.user) {
        const user: User = await api.updateUser(props.user.id, userDto);
        props.closeModal(user);
      } else {
        const user: User = await api.createUser(userDto);
        props.closeModal(user);
      }
      reset();
    } catch (e: any) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError?.response) {
        if (Array.isArray(axiosError.response.data.message)) {
          myToastr.error(axiosError.response.data.message.join('</br>'));
        } else {
          myToastr.error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form user-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Usuario</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <span className="form-section">Información general</span>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.name })}>Nombre *</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" autoComplete="off" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.surnames })}>Apellidos *</div>
              <input type="text" {...register('surnames', { required: true })} className={clsx({ error: errors?.surnames })} placeholder="Apellidos" autoComplete="off" />
              {errors.surnames && <div className="error-message">{errors.surnames.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.username })}>Nombre de usuario*</div>
              <input type="text" {...register('username', { required: true })} className={clsx({ error: errors?.username })} placeholder="Nombre de usuario" autoComplete="off" />
              {errors.username && <div className="error-message">{errors.username.message}</div>}
            </div>
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <div className="col-6">
                <div className={clsx('input-name', { error: errors?.role })}>Rol *</div>
                <select
                  {...register('role', {
                    required: true,
                  })}
                  className={clsx({ error: errors?.role })}
                  onChange={(e) => {
                    setValue('role', e.target.value as any);
                    setValue('stores', []);
                  }}
                >
                  <option value={''}>Selecciona una opción</option>
                  <option value={Role.Admin}>{roleToString(Role.Admin)}</option>
                  {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2B) && <option value={Role.Business}>{roleToString(Role.Business)}</option>}
                  <option value={Role.Manager}>{roleToString(Role.Manager)}</option>
                  {user.role === Role.SuperAdmin && <option value={Role.SuperAdmin}>{roleToString(Role.SuperAdmin)}</option>}
                  {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2C) && <option value={Role.Seller}>{roleToString(Role.Seller)}</option>}
                </select>
              </div>
            )}
            {watch('role') === Role.SuperAdmin && (
              <small className="mt-3" style={{ fontSize: 11 }}>
                Un usuario con el rol <strong>{roleToString(Role.SuperAdmin)}</strong> tiene acceso a todas las organizaciones de Sierra. El <strong>{roleToString(Role.SuperAdmin)}</strong> se aplica
                a todas las organizaciones.
              </small>
            )}
          </div>
          {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
            <div className="row">
              <div className="col">
                <div className="input-name">{organizationType === OrganizationType.B2C ? 'Tiendas' : 'Almacenes'}</div>
                <Controller
                  control={control}
                  name="stores"
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    if (organizationStores.length === 0) {
                      return <div className="input-name">La organización no tiene {organizationType === OrganizationType.B2C ? 'tiendas' : 'almacenes'}</div>;
                    } else {
                      return (
                        <Select
                          onChange={onChange}
                          closeMenuOnSelect={false}
                          value={value}
                          isMulti
                          isDisabled={watch('role') === ''}
                          options={organizationStores
                            .filter((s: Store) => {
                              const role: Role = watch('role') as Role;
                              switch (role) {
                                case Role.SuperAdmin:
                                case Role.Admin:
                                case Role.Manager:
                                  return true;
                                case Role.Business:
                                  return s.type === StoreType.B2B;
                                case Role.Seller:
                                  return s.type === StoreType.B2C;
                                default:
                                  return false;
                              }
                            })
                            .map((s: Store) => ({ value: s.id, label: s.name }))}
                          placeholder={organizationType === OrganizationType.B2C ? 'Selecciona tiendas' : 'Selecciona almacenes'}
                          styles={styleMultiSelectInModal(!!error)}
                          menuPortalTarget={document.body}
                          noOptionsMessage={() => null}
                          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null }}
                        />
                      );
                    }
                  }}
                />
              </div>
            </div>
          )}
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.phone })}>Teléfono</div>
              <input type="text" {...register('phone')} className={clsx({ error: errors?.phone })} placeholder="Teléfono" autoComplete="off" />
              {errors.phone && <div className="error-message">{errors.phone.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.email })}>Correo electrónico *</div>
              <input
                type="email"
                {...register('email', {
                  required: true,
                  validate: (value) => isEmail(value) || 'Correo electrónico inválido',
                })}
                className={clsx({ error: errors?.email })}
                placeholder="Correo electrónico"
                autoComplete="off"
              />
              {errors.email && <div className="error-message">{errors.email.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.birthDate })}>Fecha nacimiento</div>
              <input type="date" {...register('birthDate')} className={clsx({ error: errors?.birthDate })} placeholder="Fecha nacimiento" autoComplete="off" />
              {errors.birthDate && <div className="error-message">{errors.birthDate.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.nid })}>DNI</div>
              <input type="text" {...register('nid')} className={clsx({ error: errors?.nid })} placeholder="DNI" autoComplete="off" />
              {errors.nid && <div className="error-message">{errors.nid.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.socialSecurityNumber })}>Nº Seguridad Social</div>
              <input type="text" {...register('socialSecurityNumber')} className={clsx({ error: errors?.socialSecurityNumber })} placeholder="Nº Seguridad Social" autoComplete="off" />
              {errors.socialSecurityNumber && <div className="error-message">{errors.socialSecurityNumber.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.address })}>Dirección (línea 1)</div>
              <input type="text" {...register('address')} className={clsx({ error: errors?.address })} placeholder="Dirección (línea 1)" autoComplete="off" />
              {errors.address && <div className="error-message">{errors.address.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.secondaryAddress })}>Dirección (línea 2)</div>
              <input type="text" {...register('secondaryAddress')} className={clsx({ error: errors?.secondaryAddress })} placeholder="Dirección (línea 2)" autoComplete="off" />
              {errors.secondaryAddress && <div className="error-message">{errors.secondaryAddress.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.province })}>Provincia</div>
              <input type="text" {...register('province')} className={clsx({ error: errors?.province })} placeholder="Provincia" autoComplete="off" />
              {errors.province && <div className="error-message">{errors.province.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.postalCode })}>Código Postal</div>
              <input type="text" {...register('postalCode')} className={clsx({ error: errors?.postalCode })} placeholder="Código Postal" autoComplete="off" />
              {errors.postalCode && <div className="error-message">{errors.postalCode.message}</div>}
            </div>
            <div className="col-3">
              <div className={clsx('input-name', { error: errors?.color })}>Color</div>
              <Dropdown autoClose={'outside'} show={showColorPickerDropdown} onToggle={(e) => setShowColorPickerDropdown(!showColorPickerDropdown)}>
                <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components">
                  <div className="me-4">
                    <div className="position-relative" title="Selecciona un color para el usuario">
                      <div
                        className="my-color-picker"
                        style={{
                          backgroundColor: watch('color') || '#0092FD',
                        }}
                      ></div>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenuDropdown} show={showColorPickerDropdown}>
                  <Controller
                    control={control}
                    name="color"
                    render={({ field: { onChange, value } }) => <SketchPicker color={value || '#0092FD'} onChange={(color: ColorResult) => onChange(color.hex)} />}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-3">
              {props?.user && (
                <React.Fragment>
                  <div className={clsx('input-name')}>Foto</div>
                  {user.imagePath ? (
                    <div className="position-relative user-photo">
                      <LazyLoadImage src={process.env.REACT_APP_PUBLIC_URL + user.imagePath} alt="user" className="img-fluid" />
                      {user.id === props.user.id && (
                        <div className="d-flex align-items-center justify-content-center remove-icon remove-icon-small">
                          <X onClick={() => deleteUserImage()} size={10} />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="container-add-photo d-flex justify-content-center align-items-center" onClick={() => inputPhotoRef.current?.click()}>
                      <Plus color="#808A95" size={20} />
                    </div>
                  )}
                  <input
                    type="file"
                    ref={inputPhotoRef}
                    accept="image/*"
                    onClick={(event: any) => {
                      event.target.value = null;
                    }}
                    onChange={async (e: any) => {
                      if (e.target.files !== null && e.target.files.length > 0) {
                        await updateUserImage(e.target.files[0]);
                        e.target.value = null;
                      }
                    }}
                    style={{ display: 'none' }}
                    autoComplete="off"
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          <span className="form-section">Información bancaria</span>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.bankAccount })}>Cuenta bancaria</div>
              <input type="text" {...register('bankAccount')} className={clsx({ error: errors?.bankAccount })} placeholder="Cuenta bancaria" autoComplete="off" />
              {errors.bankAccount && <div className="error-message">{errors.bankAccount.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.swiftBic })}>SWIFT/BIC</div>
              <input type="text" {...register('swiftBic')} className={clsx({ error: errors?.swiftBic })} placeholder="SWIFT/BIC" autoComplete="off" />
              {errors.swiftBic && <div className="error-message">{errors.swiftBic.message}</div>}
            </div>
          </div>
          <span className="form-section">Contacto de emergencia</span>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.emergencyContactName })}>Nombre contacto emergencia</div>
              <input type="text" {...register('emergencyContactName')} className={clsx({ error: errors?.emergencyContactName })} placeholder="Nombre contacto emergencia" autoComplete="off" />
              {errors.emergencyContactName && <div className="error-message">{errors.emergencyContactName.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.emergencyContactPhone })}>Teléfono contacto emergencia</div>
              <input type="text" {...register('emergencyContactPhone')} className={clsx({ error: errors?.emergencyContactPhone })} placeholder="Teléfono contacto emergencia" autoComplete="off" />
              {errors.emergencyContactPhone && <div className="error-message">{errors.emergencyContactPhone.message}</div>}
            </div>
          </div>
          {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
            <React.Fragment>
              <span className="form-section">Horario</span>
              <div className="row my-3">
                <div className="col">
                  <div className={clsx('input-name', { error: errors?.daysOff })}>Días libres</div>
                  <input type="number" {...register('daysOff', { min: 0, valueAsNumber: true })} className={clsx({ error: errors?.daysOff })} placeholder="Días libres" autoComplete="off" />
                  {errors.daysOff && <div className="error-message">{errors.daysOff.message}</div>}
                </div>
                <div className="col">
                  <div className={clsx('input-name', { error: errors?.holidays })}>Vacaciones</div>
                  <input type="number" {...register('holidays', { min: 0, valueAsNumber: true })} className={clsx({ error: errors?.holidays })} placeholder="Vacaciones" autoComplete="off" />
                  {errors.holidays && <div className="error-message">{errors.holidays.message}</div>}
                </div>
              </div>
              <div className="row my-3">
                <div className="col">
                  <div className={clsx('input-name', { error: errors?.typeOfContract })}>Tipo contrato</div>
                  <select {...register('typeOfContract')} className={clsx({ error: errors?.typeOfContract })}>
                    <option value="">Selecciona tipo de contrato</option>
                    <option value={TypeOfContract.Indefinite}>Indefinido</option>
                    <option value={TypeOfContract.Temporary}>Temporal</option>
                  </select>
                  {errors.typeOfContract && <div className="error-message">{errors.typeOfContract.message}</div>}
                </div>
                <div className="col">
                  <div className={clsx('input-name', { error: errors?.contractEndDate })}>Fecha finalización contrato</div>
                  <input type="date" {...register('contractEndDate')} className={clsx({ error: errors?.contractEndDate })} placeholder="Fecha finalización contrato" autoComplete="off" />
                  {errors.contractEndDate && <div className="error-message">{errors.contractEndDate.message}</div>}
                </div>
              </div>
              <div className="row my-3">
                <div className="col">
                  <div className={clsx('input-name', { error: errors?.trialPeriodStartDate })}>Fecha inicio periodo de prueba</div>
                  <input type="date" {...register('trialPeriodStartDate')} className={clsx({ error: errors?.trialPeriodStartDate })} placeholder="Fecha inicio periodo de prueba" autoComplete="off" />
                  {errors.trialPeriodStartDate && <div className="error-message">{errors.trialPeriodStartDate.message}</div>}
                </div>
                <div className="col">
                  <div className={clsx('input-name', { error: errors?.trialPeriodEndDate })}>Fecha fin periodo de prueba</div>
                  <input type="date" {...register('trialPeriodEndDate')} className={clsx({ error: errors?.trialPeriodEndDate })} placeholder="Fecha fin periodo de prueba" autoComplete="off" />
                  {errors.trialPeriodEndDate && <div className="error-message">{errors.trialPeriodEndDate.message}</div>}
                </div>
              </div>
            </React.Fragment>
          )}
          {user.role === Role.SuperAdmin && (
            <div className="row my-3">
              <div className="col-6">
                <div className={'input-name'} style={{ marginTop: '12px' }}>
                  Activo *
                </div>
                <input type="checkbox" {...register('active')} autoComplete="off" />
              </div>
            </div>
          )}
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default UserModal;
