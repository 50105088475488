import { OrganizationType } from '../enums/organization-type';

export class Constants {
  public static readonly ACTIVE_COLOR_RETAIL = '#26C44B';
  public static readonly ACTIVE_COLOR_WHOLESALER = '#26C4C4';
  public static readonly DEFAULT_PATH_BUSINESS = '/productos';
  public static readonly DEFAULT_PATH_SELLER = '/pos';
  public static readonly DEFAULT_PATH_STORE_ADMIN = '/';
  public static readonly DEFAULT_PATH_STORE_MANAGER = '/';
  public static readonly DEFAULT_PATH_SUPERADMIN = '/';
  public static readonly DEFAULT_PATH_WHOLESALE_CUSTOMER = '/ordenes-compra';
  public static readonly DISABLED_COLOR = '#808A95';
  public static readonly INFINITE_SCROLL_DATA_OFFSET = 50;
  public static readonly INFINITE_SCROLL_TIMEOUT_DATA_MS = 600;
  public static readonly LIMIT_RESULTS = 50;
  public static readonly ORGANIZATION_TYPES: string[] = [OrganizationType.ALL, OrganizationType.B2B, OrganizationType.B2C];
}
