import clsx from 'clsx';
import moment from 'moment';
import { useRef, useState } from 'react';
import { Plus, X } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import { useDispatch, useSelector } from 'react-redux';
import { RepairStatus } from '../enums/repair-status';
import { RawImage } from '../interfaces/raw-image';
import { RepairImage } from '../interfaces/repair-image';
import { RepairRecord } from '../interfaces/repair-record';
import { addRepairRecord, deleteRepairImage, getRepair, repairSelector, setErrorItem, setItem, setRepairConcept } from '../store/repair-slice';
import ConfirmModal from './confirm-modal';
import UploadRepairImageModal from './upload-repair-image-modal';

const RepairSummary = () => {
  const dispatch = useDispatch();
  const { repair, item, repairConcept, requesting, errorItem } = useSelector(repairSelector);
  const [selectedRepairImage, setSelectedRepairImage] = useState<RepairImage | null>(null);
  const repairRecordsRef = useRef<any>(null);
  const [textAction, setTextAction] = useState<string>('');
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showUploadRepairImageModal, setShowUploadRepairImageModal] = useState<boolean>(false);

  const onCloseUploadRepairImageModal = async (repairImage: RepairImage[] | RawImage | null) => {
    if (repairImage !== null) {
      await dispatch(getRepair(repair!.id));
    }
    setShowUploadRepairImageModal(false);
  };

  const addNewAction = async () => {
    await dispatch(
      addRepairRecord({
        repairId: repair!.id,
        description: textAction,
      }),
    );
    setTextAction('');
    repairRecordsRef.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
  };

  return (
    <div className="repair-summary">
      <div className="resume mb-4">
        <div className="title">Reparación</div>
        <div className="body d-flex flex-column my-2">
          <div className="d-flex flex-column mb-2">
            <div className="d-flex flex-row justify-content-between mb-2">
              <span className="date">{moment(repair?.createdAt).format('YYYY-MM-DD HH:mm')}</span>
              <span className="repair-id">{repair?.internalId}</span>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-row">
                <span className="store-title me-1">Caja:</span>
                <span className="store-name">{repair?.store.name}</span>
              </div>
              <div className="d-flex flex-row">
                <span className="store-title me-1">Creada por:</span>
                <span className="store-name">
                  {repair?.user.name} {repair?.user.surnames}
                </span>
              </div>
            </div>
          </div>
          {repair?.technicalService && (
            <div className="d-flex flex-column my-1">
              <div className="d-flex flex-row">
                <label className="me-1">Servicio Técnico:</label>
                <span className="value">{repair?.technicalService.name}</span>
              </div>
              <div className="d-flex flex-row justify-content-between my-1 ">
                <div className="d-flex flex-row">
                  <span className="store-title me-1">Teléfono:</span>
                  {repair?.technicalService.phone && repair?.technicalService.phone.length > 0 ? (
                    <a href={`tel:${repair?.technicalService.phone}`} className="technical-service-info">
                      {repair?.technicalService.phone}
                    </a>
                  ) : (
                    <span className="technical-service-info">-</span>
                  )}
                </div>
                <div className="d-flex flex-row">
                  <span className="store-title me-1">Email:</span>
                  {repair?.technicalService.email && repair?.technicalService.email.length > 0 ? (
                    <a href={`mailto:${repair?.technicalService.email}`} className="technical-service-info">
                      {repair?.technicalService.email}
                    </a>
                  ) : (
                    <span className="technical-service-info">-</span>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="d-flex flex-row my-2">
            <label className="me-1">Referencia interna:</label>
            <span className="value">{repair?.internalReference}</span>
          </div>
          <div className="d-flex flex-row align-items-center my-2">
            <label className={clsx('me-1', { error: errorItem })}>Producto:</label>
            {repair?.product?.sku && repair?.product?.sku.length > 0 ? (
              <span className="value">{repair?.product.sku}</span>
            ) : (
              <input
                className={clsx({ error: errorItem })}
                type="text"
                value={item}
                onChange={(e: any) => {
                  dispatch(setErrorItem(false));
                  dispatch(setItem(e.target.value));
                }}
              ></input>
            )}
          </div>
          {repair?.product && (
            <div className="d-flex flex-row my-2">
              <label className="me-1">Utiliza stock:</label>
              <span className="value">{repair.useStock ? 'Sí' : 'No'}</span>
            </div>
          )}
          <div className="d-flex flex-column my-2">
            <label className="mb-2">Descripción</label>
            <textarea rows={10} value={repairConcept} onChange={(e: any) => dispatch(setRepairConcept(e.target.value))}></textarea>
          </div>
          <div className="d-flex flex-column">
            <label className="mb-2">Imágenes</label>
            {(repair?.repairStatus === RepairStatus.Delivered || repair?.repairStatus === RepairStatus.Canceled) && repair?.images.length === 0 && <span className="no-images">No hay imágenes</span>}
            <div className="d-flex flex-wrap">
              <LightgalleryProvider>
                {repair?.images.map((repairImage: RepairImage, index: number) => (
                  <div className="position-relative container-image d-flex align-items-center justify-content-center me-2 mb-2" key={index}>
                    <LightgalleryItem group="group-product-images" src={process.env.REACT_APP_PUBLIC_URL + repairImage.path}>
                      <LazyLoadImage className="product-image" src={process.env.REACT_APP_PUBLIC_URL + repairImage.path} alt="" />
                    </LightgalleryItem>
                    {!requesting && repair.repairStatus !== RepairStatus.Canceled && repair?.repairStatus !== RepairStatus.Delivered && (
                      <span
                        className="delete-image"
                        onClick={() => {
                          setSelectedRepairImage(repairImage);
                          setShowConfirmModal(true);
                        }}
                      >
                        <X color="white" size="15" />
                      </span>
                    )}
                  </div>
                ))}
              </LightgalleryProvider>
              {repair?.repairStatus !== RepairStatus.Canceled && repair?.repairStatus !== RepairStatus.Delivered && (
                <div
                  className={clsx('container-image d-flex align-items-center justify-content-center me-2', { clickable: !requesting })}
                  title="Subir imágenes del producto"
                  onClick={() => {
                    if (requesting) {
                      return;
                    }
                    setShowUploadRepairImageModal(true);
                  }}
                >
                  <Plus color="#252e3c" size="30" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="repair-records flex-grow-1 mb-4">
        <div className="title mb-2">Acciones</div>
        <div>
          <table className="my-table">
            <thead>
              <tr>
                <th style={{ width: '25%', paddingLeft: 5, lineHeight: '3', background: '#F8F9FA', position: 'sticky', top: 0 }}>Fecha</th>
                <th style={{ width: '75%', paddingLeft: 5, lineHeight: '3', background: '#F8F9FA', position: 'sticky', top: 0 }}>Acción</th>
              </tr>
            </thead>
            <tbody>
              {repair?.repairRecords.map((record: RepairRecord, index: number) => (
                <tr key={index}>
                  <td>{moment(record.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{record.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div ref={repairRecordsRef} />
        </div>
        {repair?.repairStatus !== RepairStatus.Canceled && (
          <>
            <input
              className="my-2"
              value={textAction}
              placeholder="Nueva acción"
              type="text"
              onChange={(e) => setTextAction(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === 'Enter' && textAction && textAction.trim() !== '') {
                  addNewAction();
                }
              }}
            />
            <button disabled={!textAction || textAction.length === 0} className="new-action mt-1" onClick={addNewAction}>
              Añadir nueva acción
            </button>
          </>
        )}
      </div>
      {selectedRepairImage && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Imagen"
          content={`¿Estás seguro que quieres eliminar la imagen?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              dispatch(deleteRepairImage(selectedRepairImage));
            }
            setSelectedRepairImage(null);
          }}
        ></ConfirmModal>
      )}
      <UploadRepairImageModal repair={repair!} closeModal={onCloseUploadRepairImageModal} show={showUploadRepairImageModal} />
    </div>
  );
};

export default RepairSummary;
