import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Edit, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import Table from '../components/table';
import TechnicalServiceModal from '../components/technical-service-modal';
import { TechnicalService } from '../interfaces/technical-service';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import myToastr from '../services/toastr';

const TechnicalServicesView = () => {
  const [technicalServices, setTechnicalServices] = useState<TechnicalService[]>([]);
  const [showTechnicalServiceModal, setShowTechnicalServiceModal] = useState<boolean>(false);
  const [selectedTechnicalService, setSelectedTechnicalService] = useState<TechnicalService | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);

  const getTechnicalServices = async () => {
    setRequesting(true);
    const ts: TechnicalService[] = await api.getTechnicalServices();
    setTechnicalServices(ts);
    setRequesting(false);
  };

  useEffect(() => {
    getTechnicalServices();
  }, []);

  const columns = React.useMemo(() => {
    const columnsData: any[] = [
      { Header: 'Nombre', accessor: 'name', filter: 'fuzzyText' },
      { Header: 'Organización', accessor: 'organization.name', filter: 'fuzzyText' },
      { Header: 'CIF', accessor: 'cif', filter: 'fuzzyText' },
      { Header: 'Dirección', accessor: 'address', filter: 'fuzzyText' },
      { Header: 'Teléfono', accessor: 'phone', filter: 'fuzzyText' },
      { Header: 'Email', accessor: 'email', filter: 'fuzzyText' },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => (
          <>
            <Edit
              size={16}
              className="mx-2"
              color="#222E3D"
              onClick={() => {
                setSelectedTechnicalService(row.original);
                setShowTechnicalServiceModal(true);
              }}
              type="button"
            />
            <Trash2
              type="button"
              className="mx-2"
              onClick={() => {
                setSelectedTechnicalService(row.original);
                setShowConfirmModal(true);
              }}
              size={14}
            />
          </>
        ),
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technicalServices]) as any;

  const newTechnicalService = () => {
    setSelectedTechnicalService(null);
    setShowTechnicalServiceModal(true);
  };

  const onCloseTechnicalServiceModal = (technicalService: TechnicalService | null) => {
    if (technicalService) {
      getTechnicalServices();
    }
    setShowTechnicalServiceModal(false);
    setSelectedTechnicalService(null);
  };

  const deleteTechnicalService = async (ts: TechnicalService) => {
    try {
      await api.deleteTechnicalService(ts.id);
      const tss: TechnicalService[] = [...technicalServices];
      const index: number = tss.findIndex((t: TechnicalService) => t.id === ts.id);
      tss.splice(index, 1);
      setTechnicalServices(tss);
      myToastr.success('Servicio técnico eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const technicalService: TechnicalService = fr.original;
      data.push({
        Nombre: technicalService.name,
        CIF: technicalService.cif,
        Dirección: technicalService.address,
        Teléfono: technicalService.phone,
        Email: technicalService.email,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Servicios Técnicos</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="servicios_tecnicos.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <button className="d-flex align-items-center create-button" onClick={newTechnicalService}>
          <Plus className="me-1" size={14} /> Nuevo Serv. técnico
        </button>
      </div>
      <Table
        data={technicalServices}
        columns={columns}
        noDataMessage="No hay servicios técnicos"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <TechnicalServiceModal show={showTechnicalServiceModal} closeModal={onCloseTechnicalServiceModal} technicalService={selectedTechnicalService} />
      {selectedTechnicalService && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Servicio Técnico"
          content={`¿Estás seguro que quieres eliminar el servicio técnico ${selectedTechnicalService.name}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteTechnicalService(selectedTechnicalService);
            }
            setSelectedTechnicalService(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default TechnicalServicesView;
