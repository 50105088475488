import React from 'react';
import { useSelector } from 'react-redux';
import { formatNumber } from '../services/helpers';
import { saleSelector } from '../store/sale-slice';

const SaleSubtotals = () => {
  const { sale } = useSelector(saleSelector);
  return (
    <div className="pos-subtotals noselect">
      <div className="d-flex flex-column totals mt-2">
        {sale && sale.total >= 0 && (
          <React.Fragment>
            <div className="d-flex flex-row justify-content-end mb-1 taxes">
              <div className="">Total IVA ({formatNumber(parseFloat(process.env.REACT_APP_IVA))}%):</div>
              <div className="ms-1">{formatNumber(sale!.totalIva)}€</div>
            </div>
            <div className="d-flex flex-row justify-content-end mb-1 taxes">
              <div className="">Descuento:</div>
              <div className="ms-1">{formatNumber(sale!.totalDiscount)}€</div>
            </div>
          </React.Fragment>
        )}
        <div className="d-flex flex-row justify-content-end mb-1 total">
          <div className="">Total:</div>
          <div className="ms-1">{formatNumber(sale!.total)}€</div>
        </div>
      </div>
    </div>
  );
};

export default SaleSubtotals;
