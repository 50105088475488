import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Plus, Trash2, Upload } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Row, TableState } from 'react-table';
import ellipse from '../assets/images/ellipse.svg';
import ConfirmModal from '../components/confirm-modal';
import DateRangeSelector from '../components/date-range-selector';
import ImportCsvModal from '../components/import-csv-modal';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import { DateRange } from '../enums/date-range';
import { Entity } from '../enums/entity';
import { RepairStatus } from '../enums/repair-status';
import { Role } from '../enums/role';
import { useStores } from '../hooks/use-stores.hook';
import { DateRangeData } from '../interfaces/date-range-data';
import { Organization } from '../interfaces/organization';
import { Repair } from '../interfaces/repair';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { getDatesGivenDateRange, getTextAndColorRepairStatus, roundTwoDecimals } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { setStoreId, storeSelector } from '../store/store-slice';

interface FiltersSessionStorage {
  storeId: number | null;
  dateRange: DateRange;
  startDateStr: string | null;
  endDateStr: string | null;
  // Filtros de la tabla
  internalId: string;
  createdAt: string;
  customer: string;
  product: string;
  repairStatus: RepairStatus | null;
  storeName: string;
  seller: string;
  total: number | null;
}

function RepairStatusColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
  return (
    <select value={filterValue} onChange={(e: any) => setFilter(e.target.value || undefined)}>
      <option value="">Todos</option>
      <option value={RepairStatus.Opened}>Abierta</option>
      <option value={RepairStatus.InProcess}>En proceso</option>
      <option value={RepairStatus.Budget}>Presupuesto</option>
      <option value={RepairStatus.Finished}>Terminada</option>
      <option value={RepairStatus.Delivered}>Entregada</option>
      <option value={RepairStatus.Canceled}>Cancelada</option>
    </select>
  );
}

const dateRange: DateRange = DateRange.ThisYear;

const RepairsView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { store } = useSelector(storeSelector);
  const tableRef: any = useRef<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedStoreId } = useSelector(storeSelector);
  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [selectedRepair, setSelectedRepair] = useState<Repair | null>(null);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData | null>(null);
  const [showImportCsvModal, setShowImportCsvModal] = useState<boolean>(false);
  const [tableFilters, setTableFilters] = useState<{ id: string; value: any }[]>([]);
  const stores: Store[] = useStores();

  const getRepairs = async () => {
    setRequesting(true);
    const rs: Repair[] = await api.getRepairs(dateRangeData!.dates[0], dateRangeData!.dates[1], organization!.id, selectedStoreId);
    setRepairs(rs);
    setRequesting(false);
  };

  useEffect(() => {
    const value: string | null = sessionStorage.getItem('search-repairs');
    let drd: DateRangeData = {
      dateRange,
      dates: getDatesGivenDateRange(dateRange),
    };
    const tf: { id: string; value: any }[] = [];
    if (value) {
      try {
        const filtersSessionStorage: FiltersSessionStorage = JSON.parse(value);
        if (filtersSessionStorage.storeId !== null) {
          dispatch(setStoreId(filtersSessionStorage.storeId));
        }
        drd = {
          dateRange: filtersSessionStorage.dateRange,
          dates: [
            moment(filtersSessionStorage.startDateStr).isValid() ? moment(filtersSessionStorage.startDateStr).toDate() : null,
            moment(filtersSessionStorage.endDateStr).isValid() ? moment(filtersSessionStorage.endDateStr).toDate() : null,
          ],
        };
        if (filtersSessionStorage.internalId) {
          tf.push({ id: 'internalId', value: filtersSessionStorage.internalId });
        }
        if (filtersSessionStorage.createdAt) {
          tf.push({ id: 'createdAt', value: filtersSessionStorage.createdAt });
        }
        if (filtersSessionStorage.customer) {
          tf.push({ id: 'customer', value: filtersSessionStorage.customer });
        }
        if (filtersSessionStorage.product) {
          tf.push({ id: 'product', value: filtersSessionStorage.product });
        }
        if (filtersSessionStorage.repairStatus) {
          tf.push({ id: 'repairStatus', value: filtersSessionStorage.repairStatus });
        }
        if (filtersSessionStorage.storeName) {
          tf.push({ id: 'storeName', value: filtersSessionStorage.storeName });
        }
        if (filtersSessionStorage.seller) {
          tf.push({ id: 'seller', value: filtersSessionStorage.seller });
        }
        if (filtersSessionStorage.total) {
          tf.push({ id: 'total', value: filtersSessionStorage.total });
        }
      } catch (e) {
        sessionStorage.removeItem('search-sales');
      }
    }
    setTableFilters(tf);
    setDateRangeData(drd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    getRepairs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData]);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    getRepairs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId]);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    const filtersSessionStorage: FiltersSessionStorage = {
      storeId: selectedStoreId,
      dateRange: dateRangeData!.dateRange,
      startDateStr: moment(dateRangeData!.dates[0]).format('YYYY-MM-DD'),
      endDateStr: moment(dateRangeData!.dates[1]).format('YYYY-MM-DD'),
      internalId: tableFilters.find((filter: any) => filter.id === 'internalId')?.value || '',
      createdAt: tableFilters.find((filter: any) => filter.id === 'createdAt')?.value || '',
      customer: tableFilters.find((filter: any) => filter.id === 'customer')?.value || '',
      product: tableFilters.find((filter: any) => filter.id === 'product')?.value || '',
      repairStatus: tableFilters.find((filter: any) => filter.id === 'repairStatus')?.value || '',
      storeName: tableFilters.find((filter: any) => filter.id === 'storeName')?.value || '',
      seller: tableFilters.find((filter: any) => filter.id === 'seller')?.value || '',
      total: tableFilters.find((filter: any) => filter.id === 'total')?.value || '',
    };
    sessionStorage.setItem('search-repairs', JSON.stringify(filtersSessionStorage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, selectedStoreId, tableFilters]);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        id: 'internalId',
        Header: 'Número',
        accessor: 'internalId',
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const repair: Repair = row.original;
          return (
            <Link to={`/reparacion/${repair.id}`} className="position-relative link-sale">
              <span className="fw-bold">{repair.internalId}</span>
              {repair.notes && repair.notes.length > 0 && <img src={ellipse} alt="ellipse" className="sale-with-note" />}
            </Link>
          );
        },
      },
      {
        id: 'internalReference',
        Header: 'Ref. Interna',
        accessor: 'internalReference',
        filter: 'fuzzyText',
      },
      {
        id: 'createdAt',
        Header: 'Apertura',
        accessor: (repair: Repair) => moment(repair.createdAt).format('DD/MM/YYYY HH:mm'),
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const repair: Repair = row.original;
          return <span>{moment(repair.createdAt).format('DD/MM/YYYY HH:mm')}</span>;
        },
        sortType: (a: any, b: any) => {
          const momentA = moment(a.original.createdAt);
          const momentB = moment(b.original.createdAt);
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      },
      {
        id: 'customer',
        Header: 'Cliente',
        accessor: (repair: Repair) => {
          let name = repair.customer.email;
          if (repair.customer?.name) {
            name = repair.customer.name;
            if (repair?.customer?.surnames) {
              name += ` ${repair.customer.surnames}`;
            }
          }
          return name;
        },
        filter: 'fuzzyText',
      },
      {
        id: 'product',
        Header: 'Producto',
        accessor: (repair: Repair) => {
          let product = '';
          if (repair.product?.sku) {
            product = repair.product.sku;
          }
          if (repair.item?.length > 0) {
            product = repair.item;
          }
          return product;
        },
        filter: 'fuzzyText',
      },
      {
        id: 'repairStatus',
        Header: 'Estado',
        accessor: 'repairStatus',
        Filter: RepairStatusColumnFilter,
        Cell: ({ row }: any) => {
          const repair: Repair = row.original;
          const { text, color } = getTextAndColorRepairStatus(repair);
          return (
            <span className="status" style={{ backgroundColor: color }}>
              {text}
            </span>
          );
        },
      },
      {
        id: 'store',
        Header: 'Tienda',
        accessor: 'store.name',
        Filter: SelectColumnFilter,
      },
      {
        id: 'seller',
        Header: 'Vendedor',
        accessor: (repair: Repair) => {
          let seller = repair.user.name;
          if (repair?.user?.surnames) {
            seller += ` ${repair.user.surnames}`;
          }
          return seller;
        },
        Filter: SelectColumnFilter,
      },
      {
        id: 'total',
        Header: 'Total',
        accessor: (repair: Repair) => roundTwoDecimals(repair.repairPrice),
        Cell: ({ row }: any) => {
          const repair: Repair = row.original;
          return <span>{roundTwoDecimals(repair.repairPrice)}€</span>;
        },
      },
    ];
    if (user.role === Role.SuperAdmin) {
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => (
          <Trash2
            type="button"
            className="mx-2"
            onClick={() => {
              setSelectedRepair(row.original);
              setShowConfirmModal(true);
            }}
            size={14}
          />
        ),
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repairs]) as any;

  const deleteRepair = async (r: Repair) => {
    try {
      await api.deleteRepair(r.id);
      const reps: Repair[] = [...repairs];
      const index: number = reps.findIndex((usr: Repair) => usr.id === r.id);
      reps.splice(index, 1);
      setRepairs(reps);
      myToastr.success('Reparación eliminada correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const repair: Repair = fr.original;
      let customerName = repair.customer.email;
      if (repair.customer?.name) {
        customerName = repair.customer.name;
        if (repair?.customer?.surnames) {
          customerName += ` ${repair.customer.surnames}`;
        }
      }
      const { text: statusText } = getTextAndColorRepairStatus(repair);
      let seller = repair.user.name;
      if (repair?.user?.surnames) {
        seller += ` ${repair.user.surnames}`;
      }
      data.push({
        Número: repair.id,
        Apertura: moment(repair.createdAt).format('DD/MM/YYYY'),
        Cliente: customerName,
        Estado: statusText,
        Tienda: repair.store.name,
        Vendedor: seller,
        Total: `${roundTwoDecimals(repair.repairPrice)}€`,
      });
    });
    setCsvData(data);
  };

  const onCloseImportCsvModal = (result: boolean) => {
    if (result) {
      getRepairs();
    }
    setShowImportCsvModal(false);
  };

  const onTableStateChange = (state: TableState<any>) => {
    setTableFilters(state.filters);
  };

  if (!dateRangeData) {
    return null;
  }

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="repairs p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Composturas</h1>
        {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
          <div
            className="d-flex align-items-center export-csv cursor-pointer"
            onClick={() => {
              if (!store) {
                myToastr.error(`Selecciona una tienda`);
                return;
              }
              setShowImportCsvModal(true);
            }}
            title="Importar composturas"
          >
            <Upload className="me-1" size={14} /> Importar
          </div>
        )}
        {filteredRows.length > 0 && (
          <CSVLink filename="proveedores.csv" className="d-flex align-items-center export-csv" data={csvData} onClick={generateCsv} title="Exportar composturas">
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <button className="d-flex align-items-center create-button mx-3" onClick={() => navigate('/reparacion')}>
          <Plus className="me-1" size={14} /> Nueva compostura
        </button>
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={false}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
        <button
          className="ms-2 clear-filters"
          disabled={requesting}
          onClick={() => {
            tableRef.current?.clearFilters();
            setDateRangeData({
              dateRange,
              dates: getDatesGivenDateRange(dateRange),
            });
          }}
        >
          Limpiar filtros
        </button>
      </div>
      <Table
        ref={tableRef}
        data={repairs}
        columns={columns}
        noDataMessage="No hay composturas"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          filters: tableFilters,
          sortBy: [{ id: 'createdAt', desc: true }],
          pageSize: Constants.LIMIT_RESULTS,
        }}
        onStateChange={onTableStateChange}
      ></Table>
      {selectedRepair && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Reparación"
          content={`¿Estás seguro que quieres eliminar la reparación ${selectedRepair.internalId}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteRepair(selectedRepair);
            }
            setSelectedRepair(null);
          }}
        ></ConfirmModal>
      )}
      <ImportCsvModal show={showImportCsvModal} closeModal={onCloseImportCsvModal} entity={Entity.Repair}></ImportCsvModal>
    </div>
  );
};

export default RepairsView;
