import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { MinusCircle, PlusCircle, Upload, X } from 'react-feather';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { PosStatus } from '../enums/pos-status';
import { ClosingCashRegisterCheckDto } from '../interfaces/closing-cash-register-check.dto';
import { OpeningCashRegisterCheckDto } from '../interfaces/opening-cash-register-check.dto';
import { PaymentMethod } from '../interfaces/payment-method';
import { api } from '../services/api';
import { formatNumber, roundTwoDecimals } from '../services/helpers';
import myToastr from '../services/toastr';
import { storeSelector } from '../store/store-slice';

interface PropsContainerCash {
  value: number;
  quantity: number | string;
  image: string;
  onChange: (value: number, quantity: number | string) => void;
}

const ContainerCash = (props: PropsContainerCash) => {
  const total: number | string = props.quantity !== '' ? props.value * (props.quantity as number) : '';
  return (
    <div className={clsx('container-money px-0', total !== '' && (total as number) > 0 ? 'with-value-cash' : '')}>
      <div className="money-type"></div>
      <div className="d-flex align-items-center header-money">
        <div className="flex-grow-1">
          <img height="50" src={props.image} alt="" />
        </div>
        <div>{props.value}€</div>
      </div>
      <div className="separator"></div>
      <div className="body-money">
        <div className="d-flex justify-content-between align-items-center">
          <input
            className={clsx('input-cash', props.quantity !== '' && (props.quantity as number) > 0 ? 'with-value-cash' : '')}
            type="number"
            min={0}
            value={props.quantity}
            onChange={(e: any) => {
              if (e.target.value && e.target.value !== '') {
                props.onChange(props.value, parseInt(e.target.value, 10));
              } else {
                props.onChange(props.value, '');
              }
            }}
          />
          <div className="total-money">{total === '' ? '0' : roundTwoDecimals(total as number)}€</div>
        </div>
      </div>
    </div>
  );
};

interface PropsContainerTPV {
  paymentMethod: PaymentMethod;
  value: number;
  onChange: (id: number, value: number) => void;
}

const ContainerTPV = (props: PropsContainerTPV) => {
  const [values, setValues] = useState<(number | string)[]>(['']);
  const total: number = useMemo(() => {
    return values.reduce((acc: number, value: number | string) => {
      return value !== '' && (value as number) >= 0 ? acc + (value as number) : acc;
    }, 0);
  }, [values]);

  useEffect(() => {
    props.onChange(props.paymentMethod.id, roundTwoDecimals(total));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  const addValue = () => {
    const newValues = [...values];
    newValues.push('');
    setValues(newValues);
  };

  const removeValue = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  };

  const updateValue = (index: number, value: number | string) => {
    const newValues: (number | string)[] = [...values];
    newValues[index] = value;
    setValues(newValues);
  };

  return (
    <div className={clsx('container-money px-0', total > 0 ? 'with-value-tpv' : '')}>
      <div className="d-flex align-items-center justify-content-center header-tpv">
        <div className="icon-tpv me-2"></div>
        <div>{props.paymentMethod.name}</div>
      </div>
      <div className="separator"></div>
      {values.map((value: number | string, index: number) => {
        return (
          <div key={index} className="d-flex align-items-center body-money">
            <input
              className={clsx('input-tpv flex-grow-1', value !== '' && (value as number) > 0 ? 'input-tpv-value' : '')}
              type="number"
              step={0.01}
              onChange={(e: any) => {
                if (e.target.value && e.target.value !== '' && !isNaN(e.target.value)) {
                  updateValue(index, parseFloat(e.target.value));
                } else {
                  updateValue(index, '');
                }
              }}
            />
            {index === values.length - 1 ? (
              <PlusCircle onClick={addValue} color="#808A95" size={18} className="clickable ms-2" />
            ) : (
              <MinusCircle onClick={() => removeValue(index)} color="#808A95" size={18} className="clickable ms-2" />
            )}
          </div>
        );
      })}
    </div>
  );
};

interface PropsWithdrawals {
  value: number;
  onChange: (value: number) => void;
}

const ContainerWithdrawals = (props: PropsWithdrawals) => {
  const [value, setValue] = useState<number | string>('');

  useEffect(() => {
    const total: number = !value || value === '' ? 0 : (value as number);
    props.onChange(roundTwoDecimals(total));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={clsx('container-money px-0', value !== '' && (value as number) > 0 ? 'with-value-withdrawals' : '')}>
      <div className="d-flex align-items-center justify-content-center header-tpv">
        <Upload color="#26C44B" size={18} className="me-2" />
        <div>Retirada fondo</div>
      </div>
      <div className="separator"></div>
      <div className="d-flex align-items-center body-money">
        <input
          className={clsx('input-withdrawals flex-grow-1', value !== '' && (value as number) > 0 ? 'input-withdrawals-value' : '')}
          type="number"
          min={0}
          step={0.01}
          value={value}
          onChange={(e: any) => {
            if (e.target.value && e.target.value !== '') {
              setValue(parseFloat(e.target.value));
            } else {
              setValue('');
            }
          }}
        />
      </div>
    </div>
  );
};

interface Props {
  show: boolean;
  closeModal: () => void;
}

const initialCashValues: { [value: number]: number | string } = {
  500: '',
  200: '',
  100: '',
  50: '',
  20: '',
  10: '',
  5: '',
  2: '',
  1: '',
  0.5: '',
  0.2: '',
  0.1: '',
  0.05: '',
  0.02: '',
  0.01: '',
};

export const CashRegisterModal = (props: Props) => {
  const { store } = useSelector(storeSelector);
  const [cashValues, setCashValues] = useState<{ [value: number]: number | string }>(initialCashValues);
  const [tpvValues, setTpvValues] = useState<{ [paymentMethodId: number]: number }>({});
  const [totalWithdrawals, setTotalWithdrawals] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const tpvs: PaymentMethod[] = useMemo(() => (store ? store.paymentMethods.filter((p: PaymentMethod) => p.isTpv && !p.deleted) : []), [store]);
  const totalCash: number = useMemo(() => {
    let total = 0;
    for (const element in cashValues) {
      if (cashValues.hasOwnProperty(element) && cashValues[element] !== '') {
        total += (cashValues[element] as number) * parseFloat(element);
      }
    }
    return total;
  }, [cashValues]);
  const totalTpv: number = useMemo(() => {
    let total = 0;
    for (const element in tpvValues) {
      if (tpvValues.hasOwnProperty(element)) {
        total += tpvValues[element];
      }
    }
    return total;
  }, [tpvValues]);

  useEffect(() => {
    if (props.show) {
      const tpvValues: { [value: number]: number } = {};
      tpvs.forEach((p: PaymentMethod) => {
        tpvValues[p.id] = 0;
      });
      setTpvValues(tpvValues);
    }
  }, [tpvs, props.show]);

  const close = () => {
    setCashValues(initialCashValues);
    setTpvValues({});
    setTotalWithdrawals(0);
    setNotes('');
    props.closeModal();
  };

  const openCashRegister = async () => {
    try {
      const openingCashRegisterCheckDto: OpeningCashRegisterCheckDto = {
        storeId: store!.id,
        openingCash: totalCash,
        notes,
      };
      await api.openCashRegisterCheck(openingCashRegisterCheckDto);
      myToastr.success('Caja abierta correctamente');
      close();
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const closeCashRegister = async () => {
    try {
      const closingCashRegisterCheckDto: ClosingCashRegisterCheckDto = {
        storeId: store!.id,
        closingCash: totalCash,
        closingCards: totalTpv,
        closingWithdrawals: totalWithdrawals,
        notes,
      };
      await api.closeCashRegisterCheck(closingCashRegisterCheckDto);
      myToastr.success('Caja cerrada correctamente');
      close();
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const save = async () => {
    if (store?.posStatus === PosStatus.Closed) {
      openCashRegister();
    } else {
      closeCashRegister();
    }
  };

  const onChangeCash = (value: number, quantity: number | string) => {
    const newCashValues: { [value: number]: number | string } = { ...cashValues };
    newCashValues[value] = quantity;
    setCashValues(newCashValues);
  };

  const onChangeTpv = (paymentMethodId: number, value: number) => {
    const newTpvValues: { [paymentMethodId: number]: number } = { ...tpvValues };
    newTpvValues[paymentMethodId] = value;
    setTpvValues(newTpvValues);
  };

  return (
    <Modal className="vercomi-modal my-form cash-register-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form>
        <div className="content">
          <div className="d-flex justify-content-between mb-3 text-center">
            <div className="title flex-grow-1">Arqueo de Caja de {store?.posStatus === PosStatus.Opened ? 'Cierre' : 'Apertura'}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row">
            <ContainerCash value={500} quantity={cashValues[500]} onChange={onChangeCash} image="/images/500.png" />
            <ContainerCash value={200} quantity={cashValues[200]} onChange={onChangeCash} image="/images/200.png" />
            <ContainerCash value={100} quantity={cashValues[100]} onChange={onChangeCash} image="/images/100.png" />
            <ContainerCash value={50} quantity={cashValues[50]} onChange={onChangeCash} image="/images/50.png" />
            <ContainerCash value={20} quantity={cashValues[20]} onChange={onChangeCash} image="/images/20.png" />
            <ContainerCash value={10} quantity={cashValues[10]} onChange={onChangeCash} image="/images/10.png" />
            <ContainerCash value={5} quantity={cashValues[5]} onChange={onChangeCash} image="/images/5.png" />
            <ContainerCash value={2} quantity={cashValues[2]} onChange={onChangeCash} image="/images/2.png" />
            <ContainerCash value={1} quantity={cashValues[1]} onChange={onChangeCash} image="/images/1.png" />
            <ContainerCash value={0.5} quantity={cashValues[0.5]} onChange={onChangeCash} image="/images/0,50.png" />
            <ContainerCash value={0.2} quantity={cashValues[0.2]} onChange={onChangeCash} image="/images/0,20.png" />
            <ContainerCash value={0.1} quantity={cashValues[0.1]} onChange={onChangeCash} image="/images/0,10.png" />
            <ContainerCash value={0.05} quantity={cashValues[0.05]} onChange={onChangeCash} image="/images/0,05.png" />
            <ContainerCash value={0.02} quantity={cashValues[0.02]} onChange={onChangeCash} image="/images/0,02.png" />
            <ContainerCash value={0.01} quantity={cashValues[0.01]} onChange={onChangeCash} image="/images/0,01.png" />
            {store?.posStatus === PosStatus.Opened && (
              <>
                {tpvs.map((p: PaymentMethod) => (
                  <ContainerTPV key={p.id} paymentMethod={p} value={tpvValues[p.id]} onChange={onChangeTpv} />
                ))}
                <ContainerWithdrawals value={totalWithdrawals} onChange={(value: number) => setTotalWithdrawals(value)} />
              </>
            )}
          </div>
          <div className="row mt-3 mb-4">
            <div className="d-flex justify-content-around totals">
              <div className="d-flex flex-row align-items-center">
                <div className="money-type me-2"></div>
                <span>Total Efectivo: {formatNumber(totalCash)}€</span>
              </div>
              {store?.posStatus === PosStatus.Opened && (
                <>
                  <div className="d-flex flex-row align-items-center">
                    <div className="icon-tpv me-2"></div>
                    <span>Total Tarjetas: {formatNumber(totalTpv)}€</span>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <Upload color="#26C44B" size={18} className="me-2" />
                    <span>Total Retirado: {formatNumber(totalWithdrawals)}€</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col px-1">
              <textarea value={notes} onChange={(e: any) => setNotes(e.target.value ? e.target.value : '')} name="notes" placeholder="Notas" rows={3}></textarea>
            </div>
          </div>
        </div>
        <button onClick={save} className={`save-button`} type="button">
          Guardar
        </button>
      </form>
    </Modal>
  );
};
