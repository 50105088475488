import axios, { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import packageJson from '../../package.json';

const useCacheBuster = () => {
  const location = useLocation();
  const parseVersion = (str: string): number => +str.replace(/\D/g, '');

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    clearBrowserCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const clearBrowserCache = async () => {
    try {
      const axiosResponse: AxiosResponse = await axios.get(`/meta.json?v=${new Date().getTime()}`, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });
      const meta: { version: string } = axiosResponse.data;
      if (!meta.version) {
        return;
      }
      const metaVersion: number = parseVersion(meta.version);
      const packageVersion: number = parseVersion(packageJson.version);
      if (packageVersion < metaVersion) {
        console.log(`Versión anterior ${packageVersion} versión actual ${metaVersion}`);
        window.location.href = `${window.location.href}?v=${metaVersion}`;
      }
    } catch (e) {
      console.error('Something went wrong fetching meta.json', e);
    }
  };

  return null;
};

export default useCacheBuster;
