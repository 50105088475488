import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { DeliveryNote } from '../interfaces/delivery-note';
import { DeliveryNoteFileDto } from '../interfaces/delivery-note-file.dto';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { api } from '../services/api';
import { sleep } from '../services/helpers';
import myToastr from '../services/toastr';

interface Props {
  deliveryNote: DeliveryNote;
  show: boolean;
  closeModal: (deliveryNote: DeliveryNote | null) => void;
}

interface FormData {
  file: FileList | null;
}

const initialData: FormData = {
  file: null,
};

const UploadFileDeliveryNoteModal = ({ deliveryNote, show, closeModal }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: initialData,
  });

  useEffect(() => {
    if (!show) {
      return;
    }
    reset(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const close = () => {
    closeModal(null);
  };

  const onSubmit = async (formData: FormData) => {
    try {
      const id: JQuery<HTMLElement> = myToastr.info('Subiendo documento...');
      const deliveryNoteFileDto: DeliveryNoteFileDto = {
        file: formData.file![0]!,
      };
      const updatedDeliveryNote: DeliveryNote = await api.uploadFileForDeliveryNote(deliveryNote.id, deliveryNoteFileDto);
      myToastr.clear(id);
      await sleep(2000);
      myToastr.success('Documento subido correctamente');
      closeModal(updatedDeliveryNote);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form brand-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">{deliveryNote.filePath ? 'Reemplazar documento' : 'Subir documento'}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.file })}>Documento *</div>
              <input type="file" {...register('file', { required: true })} className={clsx({ error: errors?.file })} placeholder="Selecciona un documento desde tu dispositivo" />
              {errors.file && <div className="error-message">{errors.file.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default UploadFileDeliveryNoteModal;
