import { Action, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { ThunkAction } from 'redux-thunk';
import alertsReducer, { alertsSaga } from './alerts-slice';
import authReducer from './auth-slice';
import notificationsReducer, { notificationsSaga } from './notifications-slice';
import posReducer, { posSaga } from './pos-slice';
import purchaseOrderReducer, { purchaseOrderSaga } from './purchase-order-slice';
import repairReducer, { repairSaga } from './repair-slice';
import saleReducer, { saleSaga } from './sale-slice';
import storeReducer, { storeSaga } from './store-slice';
import uiReducer from './ui-slice';

const devTools = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    alerts: alertsReducer,
    auth: authReducer,
    notifications: notificationsReducer,
    pos: posReducer,
    purchaseOrder: purchaseOrderReducer,
    repair: repairReducer,
    sale: saleReducer,
    store: storeReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  devTools,
});

function* rootSaga() {
  yield all([notificationsSaga(), posSaga(), repairSaga(), saleSaga(), storeSaga(), alertsSaga(), purchaseOrderSaga()]);
}

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<T> = ThunkAction<void, T, unknown, Action<string>>;
