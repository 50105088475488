import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';

interface Props {
  notes: string | null;
  show: boolean;
  closeModal: () => void;
  saveNotes: (notes: string | null) => void;
  deleteNotes: () => void;
  editable: boolean;
}

const NoteModal = (props: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      notes: '',
    },
  });

  useEffect(() => {
    if (props.show && props.notes) {
      reset({
        notes: props.notes,
      });
    }
  }, [props.show, props.notes, reset]);

  const close = () => {
    reset({
      notes: '',
    });
    props.closeModal();
  };

  const onSubmit = async (data: any) => {
    reset({
      notes: '',
    });
    props.saveNotes(data.notes ? data.notes.trim() : null);
  };

  const deleteNote = () => {
    reset({
      notes: '',
    });
    props.deleteNotes();
  };

  return (
    <Modal className="vercomi-modal my-form notes-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Notas</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <textarea {...register('notes')} className={clsx({ error: errors?.notes })} placeholder="Nota" disabled={!props.editable}></textarea>
            </div>
          </div>
          {props.notes && props.editable && (
            <div className="row">
              <div className="col">
                <span className="remove-notes" onClick={deleteNote}>
                  Borrar nota
                </span>
              </div>
            </div>
          )}
        </div>
        <button className={`save-button`} type="submit" disabled={!props.editable}>
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default NoteModal;
