import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';

interface Props {
  discountValue: number | null;
  show: boolean;
  closeModal: () => void;
  saveDiscount: (discountValue: number) => void;
  deleteDiscount: () => void;
}

const PosDiscountModal = (props: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (props.show && props.discountValue) {
      reset({
        discountValue: null,
      });
    }
  }, [props.show, props.discountValue, reset]);

  const close = () => {
    reset({
      discountValue: null,
    });
    props.closeModal();
  };

  const onSubmit = (data: any) => {
    reset({
      discountValue: null,
    });
    props.saveDiscount(parseFloat(data.discountValue));
  };

  const deleteDiscount = () => {
    reset({
      discountValue: null,
    });
    props.deleteDiscount();
  };

  return (
    <Modal className="vercomi-modal my-form pos-discount-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Descuento</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.discountValue })}>Porcentaje descuento*</div>
              <input type="number" {...register('discountValue', { required: true, min: 0, max: 100 })} min={0} max={100} className={clsx({ error: errors?.discountValue })} step="0.01" />
              {errors.discountValue && <div className="error-message">{errors.discountValue.message}</div>}
            </div>
          </div>
          {props.discountValue != null && props.discountValue > 0 && (
            <div className="row">
              <div className="col">
                <span className="remove-discount" onClick={deleteDiscount}>
                  Borrar descuento
                </span>
              </div>
            </div>
          )}
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default PosDiscountModal;
