import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { repairSelector, setNotes } from '../store/repair-slice';
import NoteModal from './note-modal';

const RepairCustomerHeader = () => {
  const dispatch = useDispatch();
  const { notes } = useSelector(repairSelector);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);

  const onCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  const onSaveNoteModal = (notes: string | null) => {
    if (notes !== null) {
      dispatch(setNotes(notes));
    }
    setShowNoteModal(false);
  };

  const onDeleteNoteModal = () => {
    dispatch(setNotes(null));
    setShowNoteModal(false);
  };

  return (
    <div className="repair-customer-header">
      <div className="d-flex align-items-center">
        <div className="col"></div>
        <div className="col text-center">
          <h1 className="title mb-0">Clientes</h1>
        </div>
        <div className="col">
          {notes != null && notes !== '' ? (
            <span onClick={() => setShowNoteModal(true)} className="has-notes">
              1 Nota
            </span>
          ) : (
            <span onClick={() => setShowNoteModal(true)} className="notes">
              + Notas
            </span>
          )}
        </div>
      </div>
      <NoteModal editable={true} show={showNoteModal} notes={notes} closeModal={onCloseNoteModal} saveNotes={onSaveNoteModal} deleteNotes={onDeleteNoteModal} />
    </div>
  );
};

export default RepairCustomerHeader;
