import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Clock, Download, Edit, Key, Lock, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import AddUserToOrganizationModal from '../components/add-user-to-organization-modal';
import ChangeUserPasswordModal from '../components/change-user-password-modal';
import ConfirmModal from '../components/confirm-modal';
import PinModal from '../components/pin-modal';
import { PinDto } from '../components/pin.dto';
import Table from '../components/table';
import UserModal from '../components/user-modal';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { RecoverPinDto } from '../interfaces/recover-pin.dto';
import { User } from '../interfaces/user';
import { UserInOrganization } from '../interfaces/user-in-organization';
import { UserInOrganizationDto } from '../interfaces/user-in-organization.dto';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { roleToString } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import myToastr from '../services/toastr';
import { logoutAction } from '../store/auth-slice';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

function RoleColumnFilter({ column: { filterValue, setFilter } }: any, _: any, user: User, organization: Organization) {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2B) && <option value={Role.Business}>{roleToString(Role.Business)}</option>}
      {user.role === Role.SuperAdmin && <option value={Role.SuperAdmin}>{roleToString(Role.SuperAdmin)}</option>}
      {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2C) && <option value={Role.Admin}>{roleToString(Role.Admin)}</option>}
      {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2C) && <option value={Role.Manager}>{roleToString(Role.Manager)}</option>}
      {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2C) && <option value={Role.Seller}>{roleToString(Role.Seller)}</option>}
    </select>
  );
}

const UsersViews = () => {
  const dispatch = useDispatch();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [showAddUserToOrganizationModal, setShowAddUserToOrganizationModal] = useState<boolean>(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);

  const getUsers = async () => {
    setRequesting(true);
    const users: User[] = await api.getUsers(organization.id);
    setUsers(users);
    setRequesting(false);
  };

  useEffect(() => {
    if (user.role === Role.Seller) {
      setShowPinModal(true);
      return;
    }
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recoverPin = async (u: User) => {
    if (!u.email) {
      myToastr.error(`El usuario no tiene email`);
      return;
    }
    try {
      const recoverPinDto: RecoverPinDto = {
        organizationId: organization.id,
        userId: u.id,
      };
      await api.recoverPin(recoverPinDto);
      myToastr.success('Se ha enviado un email al usuario con su pin');
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
  };

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Nombre',
        accessor: 'name',
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const u: User = row.original;
          let name = '';
          if (u.name && u.name.length > 0) {
            name = u.name;
          }
          if (u.surnames && u.surnames.length > 0) {
            name = `${name} ${u.surnames}`;
          }
          return <span className="fw-bold">{name}</span>;
        },
      },
      {
        Header: 'Usuario',
        accessor: 'username',
        filter: 'fuzzyText',
      },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          const u: User = row.original;
          const userInOrganization: UserInOrganization = u.userInOrganizations.find((uio: UserInOrganization) => uio.organizationId === organization.id)!;
          return (
            <div className="d-flex flex-row">
              <div className="fw-bold clickable" title="Editar información del usuario">
                <Edit
                  type="button"
                  className="mx-2"
                  onClick={() => {
                    setSelectedUser(u);
                    setShowUserModal(true);
                  }}
                  size={14}
                />
              </div>
              {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                <div title="Recuperar pin">
                  <Key type="button" className="mx-2" onClick={() => recoverPin(u)} size={14} />
                </div>
              )}
              {user.id === u.id && (
                <div title="Cambiar contraseña">
                  <Lock className="mx-2" type="button" onClick={() => setShowChangePasswordModal(true)} size={14} />
                </div>
              )}
              {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && userInOrganization.role !== Role.SuperAdmin && (
                <div title="Eliminar usuario de la organización">
                  <Trash2
                    type="button"
                    className="mx-2"
                    onClick={() => {
                      setSelectedUser(u);
                      setShowConfirmModal(true);
                    }}
                    size={14}
                  />
                </div>
              )}
            </div>
          );
        },
      },
    ];
    if (user.role === Role.SuperAdmin || user.role === Role.Admin) {
      columnsData.splice(1, 0, {
        Header: 'Rol',
        accessor: 'role',
        Filter: (a: any, b: any) => RoleColumnFilter(a, b, user, organization),
        Cell: (cell: any) => {
          const user: User = cell.row.original;
          const userInOrganization: UserInOrganization = user.userInOrganizations.find((uio: UserInOrganization) => uio.organizationId === organization.id)!;
          let badgeClass = '';
          switch (userInOrganization?.role) {
            case Role.Business:
              badgeClass = 'badge my-warning';
              break;
            case Role.Seller:
              badgeClass = 'badge my-warning';
              break;
            case Role.Admin:
              badgeClass = 'badge my-success';
              break;
            case Role.Manager:
              badgeClass = 'badge my-info';
              break;
            case Role.SuperAdmin:
              badgeClass = 'badge my-pink';
              break;
            default:
              break;
          }
          return <span className={`badge ${badgeClass}`}>{roleToString(userInOrganization?.role)}</span>;
        },
      });
    }
    if (user.role === Role.SuperAdmin) {
      columnsData.splice(3, 0, {
        Header: 'PIN',
        accessor: (u: User) => {
          const userInOrganization: UserInOrganization = u.userInOrganizations.find((uio: UserInOrganization) => uio.organizationId === organization.id)!;
          return userInOrganization?.pin ?? '';
        },
        filter: 'fuzzyText',
      });
      columnsData.splice(4, 0, {
        Header: 'Activo',
        accessor: 'active',
        Cell: ({ value }: any) => {
          return <span className={`badge ${value ? 'my-success' : 'my-danger'}`}>{value ? 'Sí' : 'No'}</span>;
        },
        Filter: SelectColumnFilter,
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]) as any;

  const addUserToOrganization = () => {
    setShowAddUserToOrganizationModal(true);
  };

  const newUser = () => {
    setSelectedUser(null);
    setShowUserModal(true);
  };

  const onUserModalClose = (u: User | null) => {
    if (u) {
      getUsers();
    }
    setShowUserModal(false);
    setSelectedUser(null);
  };

  const onChangeUserPasswordModalClose = (result: boolean) => {
    setShowChangePasswordModal(false);
  };

  const deleteUserInOrganization = async (u: User) => {
    try {
      const userInOrganizationDto: UserInOrganizationDto = {
        userId: u.id,
        organizationId: organization.id,
      };
      await api.deleteUserInOrganization(userInOrganizationDto);
      if (u.id === user.id) {
        // El usuario ha decidio salir de la organización
        dispatch(logoutAction());
        navigate('/login');
        myToastr.info('Has salido de la organización');
        return;
      }
      const usrs: User[] = [...users];
      const index: number = usrs.findIndex((usr: User) => usr.id === u.id);
      usrs.splice(index, 1);
      setUsers(usrs);
      myToastr.success('Usuario eliminado de la organización');
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const u: User = fr.original;
      let name = '';
      if (u.name && u.name.length > 0) {
        name = u.name;
      }
      if (u.surnames && u.surnames.length > 0) {
        name = `${name} ${u.surnames}`;
      }
      const userInOrganization: UserInOrganization | undefined = u.userInOrganizations.find((uio: UserInOrganization) => uio.organizationId === organization.id);
      const d: any = {
        Nombre: name,
        Rol: userInOrganization ? roleToString(userInOrganization.role) : '',
        Usuario: u.username,
      };
      if (u.role === Role.SuperAdmin) {
        d.PIN = userInOrganization?.pin ?? '';
        d.Activo = u.active ? 'Sí' : 'No';
      }
      data.push(d);
    });
    setCsvData(data);
  };

  const onClosePinModal = async (pin: string | null) => {
    if (!pin) {
      navigate(-1);
      return;
    }
    try {
      const pinDto: PinDto = {
        organizationId: organization.id,
        pin,
      };
      await api.checkPin(pinDto);
      await getUsers();
      setShowPinModal(false);
    } catch (e: any) {
      const axiosError: AxiosError<HttpExceptionDto> = e;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(httpExceptionDto.message);
      }
    }
  };

  const onCloseAddUserToOrganizationModal = (added: boolean) => {
    if (added) {
      getUsers();
    }
    setShowAddUserToOrganizationModal(false);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Usuarios</h1>
        {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
          <React.Fragment>
            {filteredRows.length > 0 && (
              <CSVLink filename="usuarios.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
                <Download className="me-1" size={14} /> Exportar
              </CSVLink>
            )}
            <button
              className="d-flex align-items-center create-button me-2"
              style={{
                backgroundColor: '#0092FD',
                textDecoration: 'none',
              }}
              onClick={() => navigate('/turnos')}
            >
              <Clock color="white" size={14} className="me-1" />
              Turnos
            </button>
            <button
              className="d-flex align-items-center create-button me-2"
              style={{
                backgroundColor: '#E09200',
                textDecoration: 'none',
              }}
              onClick={() => navigate('/control-horario')}
            >
              Control horario
            </button>
            <button className="d-flex align-items-center create-button me-2" onClick={addUserToOrganization}>
              <Plus className="me-1" size={14} /> Añadir usuario
            </button>
            <button className="d-flex align-items-center create-button" onClick={newUser}>
              <Plus className="me-1" size={14} /> Nuevo usuario
            </button>
          </React.Fragment>
        )}
      </div>
      <Table
        data={users}
        columns={columns}
        noDataMessage="No hay usuarios"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      {showUserModal && <UserModal show={showUserModal} user={selectedUser} closeModal={onUserModalClose} />}
      {showChangePasswordModal && <ChangeUserPasswordModal closeModal={onChangeUserPasswordModalClose} show={showChangePasswordModal} />}
      {selectedUser && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Usuario"
          content={
            selectedUser.id === user.id
              ? '¿Estás seguro que quieres salir de la organización?'
              : `¿Estás seguro que quieres eliminar el usuario ${selectedUser.name} ${selectedUser.surnames} de la organización?`
          }
          closeModal={async (result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              await deleteUserInOrganization(selectedUser);
            }
            setSelectedUser(null);
          }}
        ></ConfirmModal>
      )}
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
      <AddUserToOrganizationModal show={showAddUserToOrganizationModal} closeModal={onCloseAddUserToOrganizationModal} />
    </div>
  );
};

export default UsersViews;
