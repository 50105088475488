import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import debounce from 'debounce-promise';
import moment from 'moment';
import printJS from 'print-js';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Tab, Tabs } from 'react-bootstrap';
import { ChevronLeft, Clipboard, Copy, Download, Hash, Image, MoreHorizontal, Plus, Save, Trash2, X } from 'react-feather';
import Barcode from 'react-jsbarcode';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import ReactToPrint from 'react-to-print';
import ConfirmModal from '../components/confirm-modal';
import CustomToggleDropdown from '../components/custom-toggle-dropdown';
import ExpirationModal from '../components/expiration-modal';
import PinModal from '../components/pin-modal';
import ProductOption from '../components/product-option';
import ProductWeightModal from '../components/product-weight-modal';
import ProductsFinderModal from '../components/products-finder-modal';
import SupplierModal from '../components/supplier-modal';
import UploadFileDeliveryNoteModal from '../components/upload-file-delivery-note-modal';
import UploadProductImageModal from '../components/upload-product-image-modal';
import { DeliveryNoteStatus } from '../enums/delivery-note-status';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import { usePrompt } from '../hooks/navigation-hooks';
import { Brand } from '../interfaces/brand';
import { Category } from '../interfaces/category';
import { Collection } from '../interfaces/collection';
import { DeliveryNote } from '../interfaces/delivery-note';
import { DeliveryNoteCreateTransfersDto } from '../interfaces/delivery-note-create-transfers.dto';
import { DeliveryNoteDraftTransfersDto } from '../interfaces/delivery-note-draft-transfers.dto';
import { DeliveryNoteImportError } from '../interfaces/delivery-note-import-error';
import { DeliveryNoteInvoiceDetailsDto } from '../interfaces/delivery-note-invoice-details.dto';
import { DeliveryNoteProduct } from '../interfaces/delivery-note-product';
import { DeliveryNoteProductDraftTransfer } from '../interfaces/delivery-note-product-draft-transfer';
import { DeliveryNoteProductDraftTransferStoreDto } from '../interfaces/delivery-note-product-draft-transfer.dto';
import { DeliveryNoteProductInfo } from '../interfaces/delivery-note-product-info.dto';
import { DeliveryNoteProductTransferStore } from '../interfaces/delivery-note-product-transfer-store';
import { DeliveryNoteProductTransferStoreDto } from '../interfaces/delivery-note-product-transfer.dto';
import { DeliveryNoteProductDto } from '../interfaces/delivery-note-product.dto';
import { DeliveryNoteDto } from '../interfaces/delivery-note.dto';
import { Expiration } from '../interfaces/expiration';
import { Family } from '../interfaces/family';
import { GetProductsTags } from '../interfaces/get-product-tags';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { InitialDeliveryNoteProduct } from '../interfaces/initial-delivery-note-product';
import { Organization } from '../interfaces/organization';
import { Product } from '../interfaces/product';
import { ProductImage } from '../interfaces/product-image';
import { RawImage } from '../interfaces/raw-image';
import { Store } from '../interfaces/store';
import { SubCategory } from '../interfaces/subcategory';
import { SubFamily } from '../interfaces/subfamily';
import { Supplier } from '../interfaces/supplier';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { deliveryNoteStatusToString, downloadPdf, ellipsis, formatNumber, roundTwoDecimals, sleep } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { setStoreId, storeSelector } from '../store/store-slice';
import { multiSelectStylesDeliveryNotes } from '../styless/multiselect-style';
import CustomMenuDropdown from './custom-menu-dropdown';

const ALERT_INTERVAL = 10 * 60 * 1000; // 10 minutos

const styleMultiSelect = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#fff',
    borderColor: '#808a95',
    borderRadius: '7px',
    paddingLeft: '5px',
    boxShadow: state.isFocused ? null : null,
    width: '100%',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0 6px',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state: any) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

const Menu = (props: any) => {
  const optionSelectedLength = props.getValue().length || 0;
  return <components.Menu {...props}>{optionSelectedLength < 10 ? props.children : <div style={{ margin: 15 }}>Límite alcanzado</div>}</components.Menu>;
};

const CustomInput = forwardRef((props: any, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      style={{
        border: 'none',
      }}
    />
  );
});

const styles = {
  option: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Inter-SemiBold',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '36px',
  }),
  container: (provided: any, state: any) => ({
    ...provided,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    marginBottom: state.isFocused ? '0px' : '10px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #808a95',
    borderRadius: '7px',
  }),
  clearIndicator: (provided: any, state: any) => {
    return {
      ...provided,
    };
  },
};

const promiseProductOptions = async (inputValue: string) => {
  if (inputValue == null || inputValue === '') {
    return [];
  }
  const products: Product[] = await api.getProducts({ query: inputValue });
  const options: any[] = [
    {
      custom: true,
      value: inputValue,
      label: inputValue,
    },
  ];
  products.forEach((product: Product) => {
    options.push({
      value: product.id,
      label: product.sku,
      product,
    });
  });
  return options;
};

const debouncedLoadProductOptions = debounce((inputValue: string) => promiseProductOptions(inputValue), 1000, {
  leading: false,
});

interface EntryDeliveryNote {
  productId: number;
  product?: Product | null;
  sku: string;
  ean: string | null;
  weight: number | null;
  name: string;
  categories: { label: string; value: number }[];
  subCategories: { label: string; value: number }[];
  units: number | null;
  unitCost: number | null;
  discount: number | null;
  retailPrice: number | null;
  pvp: number | null;
  total: number | null;
  margin: number | null;
  brand: { label: string; value: number } | null;
  collectionId: number | null;
  id?: number;
  value: { label: string; value: any } | undefined;
  families: { label: string; value: number }[];
  subFamilies: { label: string; value: number }[];
}

interface ResumeDeliveryNote {
  totalUnits: number;
  totalCost: number;
  totalDiscount: number;
  totalIva: number;
  totalEquivalenceSurcharge: number;
  total: number;
  totalMargin: number;
}

enum DeliveryNoteTab {
  Products = 'products',
  ImportErrors = 'import-errors',
  Transfers = 'transfers',
}

const DeliveryNoteView = () => {
  const dispatch = useDispatch();
  const tableProducts = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const { selectedStoreId, store } = useSelector(storeSelector);
  const skuRef = useRef<string | null>(null);
  const [deliveryNote, setDeliveryNote] = useState<DeliveryNote | null>(null);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<{ label: string; value: number } | null>(null);
  const [date, setDate] = useState<Date | null>(new Date());
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [externalNumber, setExternalNumber] = useState<string>('');
  const [requesting, setRequesting] = useState<boolean>(false);
  const [showPinModalConfirmDeliveryNote, setShowPinModalConfirmDeliveryNote] = useState<boolean>(false);
  const [newEntry, setNewEntry] = useState<EntryDeliveryNote>({
    productId: -1,
    product: null,
    sku: '',
    ean: '',
    weight: null,
    name: '',
    categories: [],
    subCategories: [],
    units: 1,
    unitCost: 0,
    discount: null,
    retailPrice: null,
    pvp: null,
    total: null,
    margin: null,
    brand: null,
    collectionId: null,
    value: undefined,
    families: [],
    subFamilies: [],
  });
  const [showDropdownNewProduct, setShowDropdownNewProduct] = useState<boolean>(false);
  const [entries, setEntries] = useState<EntryDeliveryNote[]>([]);
  const [families, setFamilies] = useState<Family[]>([]);
  const [subFamilies, setSubFamilies] = useState<SubFamily[]>([]);
  const [showProductFinderModal, setShowProductFinderModal] = useState<boolean>(false);
  const [categoriesOptions, setCategoriesOptions] = useState<{ label: string; value: number }[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [showConfirmDeliveryNoteModal, setShowConfirmDeliveryNoteModal] = useState<boolean>(false);
  const [showDeleteDeliveryNoteModal, setShowDeleteDeliveryNoteModal] = useState<boolean>(false);
  const [supplierOptions, setSupplierOptions] = useState<{ label: string; value: number }[]>([]);
  const skuSelectInputRef = useRef<any>(null);
  const [hasErrorExternalNumber, setHasErrorExternalNumber] = useState<boolean>(false);
  const [hasErrorDate, setHasErrorDate] = useState<boolean>(false);
  const [hasErrorInvoiceDate, setHasErrorInvoiceDate] = useState<boolean>(false);
  const [hasErrorSku, setHasErrorSku] = useState<boolean>(false);
  const [hasErrorName, setHasErrorName] = useState<boolean>(false);
  const [hasErrorBrand, setHasErrorBrand] = useState<boolean>(false);
  const [hasErrorUnits, setHasErrorUnits] = useState<boolean>(false);
  const [hasErrorWeight, setHasErrorWeight] = useState<boolean>(false);
  const [hasErrorUnitCost, setHasErrorUnitCost] = useState<boolean>(false);
  const [hasErrorDiscount, setHasErrorDiscount] = useState<boolean>(false);
  const [hasErrorPvp, setHasErrorPvp] = useState<boolean>(false);
  const [hasErrorRetailPrice, setHasErrorRetailPrice] = useState<boolean>(false);
  const [showSupplierModal, setShowSupplierModal] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<DeliveryNoteTab>(DeliveryNoteTab.Products);
  const [generatingLabels, setGeneratingLabels] = useState<boolean>(false);
  const [downloadingFile, setDownloadingFile] = useState<boolean>(false);
  const [showProductWeightModal, setShowProductWeightModal] = useState<boolean>(false);
  const [showUploadFileDeliveryNoteModal, setShowUploadFileDeliveryNoteModal] = useState<boolean>(false);
  const [indexRowWithError, setIndexRowWithError] = useState<number>(-1);
  const showPrompt: boolean = useMemo(() => {
    if (creating || updating || confirming) {
      return false;
    }
    if (deliveryNote) {
      if (deliveryNote.status === DeliveryNoteStatus.Received) {
        return false;
      }
      if (deliveryNote.status === DeliveryNoteStatus.Pending) {
        if (deliveryNote.deliveryNoteProducts.length !== entries.length) {
          return true;
        }
        for (const deliveryNoteProduct of deliveryNote.deliveryNoteProducts) {
          const index: number = entries.findIndex((entry: EntryDeliveryNote) => entry.id === deliveryNoteProduct.id);
          if (index === -1) {
            // Se ha eliminado algún producto del albarán original
            return true;
          }
          const entryDeliveryNote: EntryDeliveryNote = entries[index];
          if (
            entryDeliveryNote.units !== deliveryNoteProduct.units ||
            entryDeliveryNote.unitCost !== deliveryNoteProduct.unitCost ||
            entryDeliveryNote.discount !== deliveryNoteProduct.discount ||
            entryDeliveryNote.pvp !== deliveryNoteProduct.pvp
          ) {
            // Ha cambiado alguna propiedad
            return true;
          }
        }
      }
      return false;
    } else {
      return entries.length > 0;
    }
  }, [deliveryNote, entries, creating, updating, confirming]);
  usePrompt('Hay cambios en la compra que no han sido guardados. ¿Seguro que quieres salir de la página?', showPrompt);
  const [showExpirationModal, setShowExpirationModal] = useState<boolean>(false);
  // imágenes de productos
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [showUploadProductModal, setShowUploadProductModal] = useState<boolean>(false);
  const [productsImages, setProductsImages] = useState<{ [sku: string]: RawImage }>({});
  const [newEntryImages, setNewEntryImages] = useState<RawImage | null>(null);
  const [productsImagesUrls, setProductsImagesUrls] = useState<{ [sku: string]: string[] }>({});
  const [newEntryImagesUrls, setNewEntryImagesUrls] = useState<string[]>([]);
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(-1);
  const deleteDeliveryNoteProducts: InitialDeliveryNoteProduct[] = useMemo(() => {
    if (!deliveryNote) {
      return [];
    }
    return deliveryNote.initialDeliveryNoteProducts.filter((initialDeliveryNoteProduct: InitialDeliveryNoteProduct) => {
      const index: number = deliveryNote.deliveryNoteProducts.findIndex((deliveryNoteProduct: DeliveryNoteProduct) => {
        return deliveryNoteProduct.productId === initialDeliveryNoteProduct.productId;
      });
      return index === -1;
    });
  }, [deliveryNote]);
  const [stores, setStores] = useState<Store[]>([]);
  const [transferUnits, setTransferUnits] = useState<{
    [deliveryNoteProductId: number]: {
      [storeId: number]: number | null;
    };
  }>({});
  const [creatingTransfers, setCreatingTransfers] = useState<boolean>(false);
  const [errorProductTransfer, setErrorProductTransfer] = useState<{ [deliveryNoteProductId: number]: boolean }>({});
  const storesForTransfers: Store[] = useMemo(() => {
    if (!deliveryNote) {
      return [];
    }
    if (deliveryNote.status !== DeliveryNoteStatus.Received) {
      return [];
    }
    return stores.filter((store: Store) => store.id !== deliveryNote.storeId);
  }, [stores, deliveryNote]);
  const transfersEnabled: boolean = useMemo(() => {
    if (!deliveryNote) {
      return false;
    }
    if (deliveryNote.status !== DeliveryNoteStatus.Received) {
      return false;
    }
    // No se han creado traspasos para ningún producto
    return deliveryNote.deliveryNoteProducts.every((deliveryNoteProduct: DeliveryNoteProduct) => deliveryNoteProduct.deliveryNoteProductTransfers.length === 0);
  }, [deliveryNote]);
  const deliveryNoteProductForTransfers: DeliveryNoteProduct[] = useMemo(() => {
    if (!deliveryNote) {
      return [];
    }
    if (deliveryNote.status !== DeliveryNoteStatus.Received) {
      return [];
    }
    return transfersEnabled
      ? deliveryNote.deliveryNoteProducts
      : deliveryNote.deliveryNoteProducts.filter((deliveryNoteProduct: DeliveryNoteProduct) => deliveryNoteProduct.deliveryNoteProductTransfers.length > 0);
  }, [deliveryNote, transfersEnabled]);
  const filteredSubFamilies: { label: string; value: number }[] = useMemo(() => {
    if (newEntry.families.length === 0) {
      return [];
    }
    return subFamilies
      .filter((sf: SubFamily) => {
        const index: number = newEntry.families.findIndex((f: { label: string; value: number }) => f.value === sf.familyId);
        return index !== -1;
      })
      .map((sf: SubFamily) => {
        return {
          value: sf.id,
          label: sf.name,
        };
      });
  }, [newEntry.families, subFamilies]);
  const filteredCollections: Collection[] = useMemo(() => {
    if (newEntry.brand === null || newEntry.brand.value === null) {
      return [];
    }
    return collections.filter((collection: Collection) => collection.brandId === newEntry.brand!.value);
  }, [newEntry.brand, collections]);
  const filteredSubCategories: { label: string; value: number }[] = useMemo(() => {
    if (newEntry.categories.length === 0) {
      return [];
    }
    return subCategories
      .filter((sc: SubCategory) => {
        const index: number = newEntry.categories.findIndex((c: { label: string; value: number }) => c.value === sc.categoryId);
        return index !== -1;
      })
      .map((sc: SubCategory) => {
        return {
          value: sc.id,
          label: sc.name,
        };
      });
  }, [newEntry.categories, subCategories]);
  const [marginToApply, setMarginToApply] = useState<number | string>('');
  const [selectedIndexToCopyValues, setSelectedIndexToCopyValues] = useState<number>(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      myToastr.warning(`Tiempo de inactividad superado. Recuerde guardar para evitar la pérdida de datos.`, undefined, {
        timeOut: 0,
        extendedTimeOut: 0,
      });
    }, ALERT_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const getBrands = async () => {
      const brands: Brand[] = await api.getBrands();
      setBrands(brands);
    };
    const getCollections = async () => {
      const collections: Collection[] = await api.getCollections();
      setCollections(collections);
    };
    const getCategories = async () => {
      const categories: Category[] = await api.getCategories(organization!.id);
      setCategoriesOptions(
        categories.map((category: Category) => ({
          label: category.name,
          value: category.id,
        })),
      );
    };
    const getSubCategories = async () => {
      const subCategories: SubCategory[] = await api.getSubCategories(organization.id);
      setSubCategories(subCategories);
    };
    const getFamilies = async () => {
      const families: Family[] = await api.getFamilies();
      setFamilies(families);
    };
    const getSubFamilies = async () => {
      const subFamilies: SubFamily[] = await api.getSubFamilies();
      setSubFamilies(subFamilies);
    };
    getBrands();
    getCollections();
    getCategories();
    getSubCategories();
    if (user.role === Role.SuperAdmin) {
      getFamilies();
      getSubFamilies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!store) {
      setSuppliers([]);
      return;
    }
    getSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  useEffect(() => {
    if (params?.id) {
      const getDeliveryNote = async () => {
        try {
          setRequesting(true);
          const dn: DeliveryNote = await api.getDeliveryNote(parseInt(params.id!, 10));
          if (dn.organizationId !== organization.id) {
            navigate(`/albaran/${dn.id}`, { replace: true });
            return;
          }
          setDeliveryNote(dn);
          setSelectedSupplier({ label: dn.supplier.name, value: dn.supplier.id });
          setExternalNumber(dn.externalNumber);
          setDate(dn.date);
          setInvoiceDate(dn.invoiceDate && moment(dn.invoiceDate).isValid() ? moment(dn.invoiceDate).toDate() : null);
          setInvoiceNumber(dn.invoiceNumber);
          const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
          setEntries(
            dn.deliveryNoteProducts.map((dnp: DeliveryNoteProduct) => {
              const discountedCost: number = roundTwoDecimals(dnp.discount && dnp.discount > 0 ? dnp.unitCost * (1 - dnp.discount / 100) : dnp.unitCost);
              const total: number = roundTwoDecimals(dnp.units * discountedCost);
              let margin = 0;
              if (organizationType === OrganizationType.B2B) {
                margin = roundTwoDecimals(((dnp.retailPrice / (1 + iva) - discountedCost) / dnp.retailPrice) * 100);
              } else {
                margin = roundTwoDecimals(((dnp.pvp / (1 + iva) - discountedCost) / dnp.pvp) * 100);
              }
              return {
                productId: dnp.productId,
                product: dnp.product,
                sku: dnp.product.sku,
                ean: dnp.product.ean,
                weight: dnp.weight,
                name: dnp.product.name,
                categories: dnp.product.categories.map((category: Category) => ({ label: category.name, value: category.id })),
                subCategories: dnp.product.subCategories.map((subCategory: SubCategory) => ({ label: subCategory.name, value: subCategory.id })),
                description: dnp.product.description,
                units: dnp.units,
                unitCost: dnp.unitCost,
                discount: dnp.discount,
                retailPrice: dnp.retailPrice,
                pvp: dnp.pvp,
                total,
                margin,
                brand: { label: dnp.product.brand.name, value: dnp.product.brand.id },
                collectionId: dnp.product.collectionId,
                id: dnp.id,
                value: { value: dnp.product.id, label: dnp.product.sku },
                families: dnp.product.families.map((family: Family) => ({ label: family.name, value: family.id })),
                subFamilies: dnp.product.subFamilies.map((subFamily: SubFamily) => ({ label: subFamily.name, value: subFamily.id })),
              };
            }),
          );
          setProductsImages({});
          setNewEntryImages(null);
          setProductsImagesUrls({});
          setNewEntryImagesUrls([]);
          const tus: {
            [deliveryNoteProductId: number]: {
              [storeId: number]: number | null;
            };
          } = {};
          dn.deliveryNoteProducts.forEach((dnp: DeliveryNoteProduct) => {
            tus[dnp.id] = {};
            dnp.deliveryNoteProductDraftTransfers.forEach((dnpdt: DeliveryNoteProductDraftTransfer) => {
              tus[dnp.id][dnpdt.storeId] = dnpdt.units;
            });
          });
          setTransferUnits(tus);
          if (dn.storeId !== selectedStoreId) {
            dispatch(setStoreId(dn.storeId));
          }
          setRequesting(false);
        } catch (e: any) {
          navigate('/compras', { replace: true });
        }
      };
      getDeliveryNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!deliveryNote || deliveryNote.status !== DeliveryNoteStatus.Received) {
      return;
    }
    getStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryNote]);

  const resumeDeliveryNote: ResumeDeliveryNote = useMemo(() => {
    let totalUnits = 0;
    let totalCost = 0;
    let totalDiscount = 0;
    let a = 0;
    let b = 0;
    let c = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    entries.forEach((p: EntryDeliveryNote) => {
      const units: number = p.units || 0;
      const unitCost: number = p.unitCost || 0;
      const retailPrice: number = p.retailPrice || 0;
      const pvp: number = p.pvp || 0;
      const discount = p.discount || 0;
      const discountedCostEntry: number = discount && discount > 0 ? unitCost * (1 - discount / 100) : unitCost;
      const totalEntry: number = units * discountedCostEntry;
      totalCost += totalEntry;
      totalDiscount += units * (unitCost - discountedCostEntry);
      if (organizationType === OrganizationType.B2B) {
        a += (retailPrice * units) / (1 + iva);
        c += retailPrice * units;
      } else {
        a += (pvp * units) / (1 + iva);
        c += pvp * units;
      }
      b += discountedCostEntry * units;
      totalUnits += units;
    });
    const totalEquivalenceSurcharge: number = organization.equivalenceSurcharge ? (totalCost * parseFloat(process.env.REACT_APP_EQUIVALENCE_SURCHARGE!)) / 100.0 : 0;
    const totalIva: number = totalCost * iva;
    const total: number = totalCost + totalIva + totalEquivalenceSurcharge;
    const totalMargin: number = c > 0 ? ((a - b) / c) * 100 : 0;
    return {
      totalUnits: roundTwoDecimals(totalUnits),
      totalCost: roundTwoDecimals(totalCost),
      totalDiscount: roundTwoDecimals(totalDiscount),
      totalIva: roundTwoDecimals(totalIva),
      totalEquivalenceSurcharge: roundTwoDecimals(totalEquivalenceSurcharge),
      total: roundTwoDecimals(total),
      totalMargin: roundTwoDecimals(totalMargin),
    };
  }, [entries, organization.equivalenceSurcharge, organizationType]);

  const getStores = async () => {
    try {
      const ss: Store[] = await api.getStores(organization.id);
      setStores(ss);
    } catch (e) {}
  };

  const addNewEntry = () => {
    const index: number = entries.length;
    if (newEntry.sku === null || newEntry.sku === '') {
      myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce el SKU del producto`);
      setHasErrorSku(true);
      setIndexRowWithError(index);
      return;
    }
    if (newEntry.name === null || newEntry.name === '') {
      myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce el nombre del producto`);
      setHasErrorName(true);
      setIndexRowWithError(index);
      return;
    }
    if (newEntry.brand === null || newEntry.brand.value === null) {
      myToastr.error(`<strong>Fila ${index + 1}</strong>: Selecciona la marca del producto`);
      setHasErrorBrand(true);
      setIndexRowWithError(index);
      return;
    }
    if (newEntry.units === null) {
      myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce las unidades del producto`);
      setHasErrorUnits(true);
      setIndexRowWithError(index);
      return;
    }
    if (newEntry.weight !== null && (isNaN(newEntry.weight) || newEntry.weight <= 0)) {
      myToastr.error(`<strong>Fila ${index + 1}</strong>: El peso del producto debe ser mayor que 0`);
      setHasErrorWeight(true);
      setIndexRowWithError(index);
      return false;
    }
    if (newEntry.unitCost === null || newEntry.unitCost === 0) {
      myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce el coste unitario del producto`);
      setHasErrorUnitCost(true);
      setIndexRowWithError(index);
      return;
    }
    if (newEntry.discount !== null && (isNaN(newEntry.discount) || newEntry.discount < 0)) {
      myToastr.error(`<strong>Fila ${index + 1}</strong>: El descuento del producto debe ser mayor o igual que 0`);
      setHasErrorDiscount(true);
      setIndexRowWithError(index);
      return false;
    }
    if (organizationType === OrganizationType.B2B) {
      if (newEntry.retailPrice === null || newEntry.retailPrice === 0) {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce el precio de venta al minorista`);
        setHasErrorRetailPrice(true);
        setIndexRowWithError(index);
        return;
      }
    }
    if (newEntry.pvp === null || newEntry.pvp === 0) {
      myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce el precio de venta unitario del producto`);
      setHasErrorPvp(true);
      setIndexRowWithError(index);
      return;
    }
    const productAlreadyAdded: EntryDeliveryNote | undefined = entries.find((e: EntryDeliveryNote) => e.sku?.trim().toUpperCase() === newEntry.sku.trim().toUpperCase());
    if (productAlreadyAdded) {
      myToastr.error(`<strong>Fila ${index + 1}</strong>: El producto ${productAlreadyAdded.sku} - ${productAlreadyAdded.name} ya ha sido añadido a la compra`);
      setIndexRowWithError(index);
      return;
    }
    if (!newEntry.ean) {
      newEntry.ean = null;
    }
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const units: number = newEntry.units || 0;
    const unitCost: number = newEntry.unitCost || 0;
    let total: number;
    let margin: number;
    if (newEntry.discount !== null && newEntry.discount >= 0) {
      const discountedCostEntry: number = unitCost * (1 - newEntry.discount / 100);
      total = units * discountedCostEntry;
      margin = newEntry.pvp > 0 ? ((newEntry.pvp / (1 + iva) - discountedCostEntry) / newEntry.pvp) * 100 : 0;
    } else {
      total = units * unitCost;
      margin = newEntry.pvp > 0 ? ((newEntry.pvp / (1 + iva) - unitCost) / newEntry.pvp) * 100 : 0;
    }
    newEntry.total = total;
    newEntry.margin = margin;
    const newEntries: EntryDeliveryNote[] = [...entries, { ...newEntry }];
    setEntries(newEntries);
    skuSelectInputRef.current.clearValue();
    setNewEntry({
      productId: -1,
      product: null,
      sku: '',
      ean: '',
      weight: null,
      name: '',
      categories: [],
      subCategories: [],
      units: 1,
      unitCost: null,
      discount: null,
      retailPrice: null,
      pvp: null,
      total: null,
      margin: null,
      brand: null,
      collectionId: null,
      value: undefined,
      families: [],
      subFamilies: [],
    });
    if (newEntryImages !== null) {
      const copyProductsImages: { [sku: string]: RawImage } = { ...productsImages };
      copyProductsImages[newEntry.sku] = newEntryImages;
      setProductsImages(copyProductsImages);
      if (newEntryImagesUrls.length > 0) {
        const copyProductsImagesUrls: { [sku: string]: string[] } = { ...productsImagesUrls };
        copyProductsImagesUrls[newEntry.sku] = newEntryImagesUrls;
        setProductsImagesUrls(copyProductsImagesUrls);
      }
    }
    setNewEntryImages(null);
    setNewEntryImagesUrls([]);
    skuSelectInputRef?.current.focus();
  };

  const getSuppliers = async () => {
    const suppliers: Supplier[] = await api.getSuppliers(organization.id);
    setSuppliers(suppliers);
    setSupplierOptions(suppliers.map((supplier: Supplier) => ({ label: supplier.name, value: supplier.id })));
  };

  const onCloseCustomerModal = (supplier: Supplier | null) => {
    if (supplier) {
      myToastr.success('Proveedor creado correctamente');
      getSuppliers();
    }
    setShowSupplierModal(false);
  };

  const deleteDeliveryNote = async () => {
    try {
      await api.deleteDeliveryNote(deliveryNote!.id);
      navigate('/compras', { replace: true });
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const checkDeliveryNoteValidity = (): boolean => {
    if (externalNumber === null || externalNumber === '') {
      myToastr.error('Introduce el Nº de documento');
      setHasErrorExternalNumber(true);
      return false;
    }
    if (date === null) {
      myToastr.error('Introduce la fecha de emisión de la compra');
      setHasErrorDate(true);
      return false;
    }
    if (!moment(date).isValid()) {
      myToastr.error('La fecha de emisión de la compra no es válida');
      setHasErrorDate(true);
      return false;
    }
    if (invoiceDate && !moment(invoiceDate).isValid()) {
      myToastr.error('La fecha de la factura no es válida');
      setHasErrorInvoiceDate(true);
      return false;
    }
    if (entries.length === 0) {
      myToastr.error('Añade al menos un producto');
      return false;
    }
    let index = 0;
    for (const entry of entries) {
      if (entry.sku === null || entry.sku === '') {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce el SKU de producto`);
        setIndexRowWithError(index);
        return false;
      }
      if (entry.name === null || entry.name === '') {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce el nombre del producto`);
        setIndexRowWithError(index);
        return false;
      }
      if (entry.brand === null || entry.brand.value === null) {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: Selecciona la marca del producto`);
        setIndexRowWithError(index);
        return false;
      }
      if (entry.units === null) {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce las unidades del producto`);
        setIndexRowWithError(index);
        return false;
      }
      if (entry.weight !== null && (isNaN(entry.weight) || entry.weight <= 0)) {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: El peso del producto debe ser mayor que 0`);
        setIndexRowWithError(index);
        return false;
      }
      if (entry.unitCost === null || entry.unitCost === 0) {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce el coste unitario del producto`);
        setIndexRowWithError(index);
        return false;
      }
      if (entry.discount !== null && (isNaN(entry.discount) || entry.discount < 0)) {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: El descuento del producto debe ser mayor o igual que 0`);
        setIndexRowWithError(index);
        return false;
      }
      if (organizationType === OrganizationType.B2B) {
        if (entry.retailPrice === null || entry.retailPrice === 0) {
          myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce el precio de venta al minorista`);
          setIndexRowWithError(index);
          return false;
        }
      }
      if (entry.pvp === null || entry.pvp === 0) {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: Introduce el precio de venta unitario del producto`);
        setIndexRowWithError(index);
        return false;
      }
      index++;
    }
    return true;
  };

  useEffect(() => {
    if (creating) {
      createDeliveryNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creating]);

  useEffect(() => {
    if (updating) {
      updateDeliveryNote(DeliveryNoteStatus.Pending, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updating]);

  const saveDeliveryNote = async () => {
    if (!checkDeliveryNoteValidity()) {
      return;
    }
    if (deliveryNote) {
      setUpdating(true);
    } else {
      setCreating(true);
    }
  };

  const createDeliveryNote = async () => {
    try {
      for (let i = 0; i < entries.length; i++) {
        const currentEntry = entries[i];
        for (let j = i + 1; j < entries.length; j++) {
          const nextEntry = entries[j];
          if (currentEntry.sku === nextEntry.sku) {
            myToastr.error(`Fila ${j + 1}: El producto ${currentEntry.sku} - ${currentEntry.name} aparece duplicado en la compra`);
            setIndexRowWithError(j);
            setUpdating(false);
            return;
          }
        }
      }
      const deliveryNoteProducts: DeliveryNoteProductDto[] = [];
      for (const entryDeliveryNote of entries) {
        const deliveryNoteProduct: DeliveryNoteProductDto = {
          units: entryDeliveryNote.units!,
          unitCost: entryDeliveryNote.unitCost!,
          discount: entryDeliveryNote.discount,
          retailPrice: entryDeliveryNote.retailPrice,
          pvp: entryDeliveryNote.pvp!,
          weight: entryDeliveryNote.weight,
        };
        if (entryDeliveryNote?.productId && entryDeliveryNote.productId > 0) {
          deliveryNoteProduct.productId = entryDeliveryNote.productId;
        }
        deliveryNoteProduct.product = {
          name: entryDeliveryNote.name,
          description: null,
          sku: entryDeliveryNote.sku,
          ean: entryDeliveryNote.ean ? entryDeliveryNote.ean : null,
          enabled: true,
          organizationId: organization.id,
          supplierIds: [selectedSupplier!.value],
          brandId: entryDeliveryNote.brand!.value,
          collectionId: entryDeliveryNote.collectionId !== null && entryDeliveryNote.collectionId !== -1 ? entryDeliveryNote.collectionId : null,
          categoryIds: entryDeliveryNote.categories.map((c: { label: string; value: number }) => c.value),
          subCategoryIds: entryDeliveryNote.subCategories.map((sc: { label: string; value: number }) => sc.value),
          familyIds: entryDeliveryNote.families.map((f: { label: string; value: number }) => f.value),
          subFamilyIds: entryDeliveryNote.subFamilies.map((sf: { label: string; value: number }) => sf.value),
        };
        deliveryNoteProducts.push(deliveryNoteProduct);
      }
      const deliveryNoteDto: DeliveryNoteDto = {
        date: date!,
        status: DeliveryNoteStatus.Pending,
        supplierId: selectedSupplier!.value,
        externalNumber,
        storeId: selectedStoreId,
        deliveryNoteProducts,
        invoiceDate: invoiceDate ? moment(invoiceDate).format('YYYY-MM-DD') : null,
        invoiceNumber,
        organizationId: organization.id,
        pin: null,
      };
      const deliveryNote: DeliveryNote = await api.createDeliveryNote(deliveryNoteDto);
      // Imágenes de productos
      for (const sku in productsImages) {
        const index: number = deliveryNote.deliveryNoteProducts.findIndex((dnp: DeliveryNoteProduct) => dnp.product.sku === sku.toUpperCase());
        if (index !== -1) {
          const productId: number = deliveryNote.deliveryNoteProducts[index].productId;
          const rawProductImage: RawImage = productsImages[sku];
          if (rawProductImage.files !== null && Array.isArray(rawProductImage.files) && rawProductImage.files.length > 0) {
            for (const file of rawProductImage.files) {
              await api.uploadProductImage(productId, file);
            }
          } else if (rawProductImage.link !== null) {
            await api.uploadProductImageFromLink(productId, { link: rawProductImage.link });
          } else if (rawProductImage.dataUri !== null) {
            await api.uploadProductImageFromBase64(productId, { image: rawProductImage.dataUri });
          }
        }
      }
      setCreating(false);
      myToastr.success('Documento creado');
      window.location.href = `${window.location.origin}/compra/${deliveryNote.id}`;
    } catch (e: any) {
      if (Array.isArray(e.response.data.message)) {
        myToastr.error(e.response.data.message.join('<br>'));
      } else {
        myToastr.error(e.response.data.message);
      }
      setCreating(false);
    }
  };

  const updateDeliveryNote = async (status: DeliveryNoteStatus, pin: string | null) => {
    try {
      for (let i = 0; i < entries.length; i++) {
        const currentEntry = entries[i];
        for (let j = i + 1; j < entries.length; j++) {
          const nextEntry = entries[j];
          if (currentEntry.sku === nextEntry.sku) {
            myToastr.error(`Fila ${j + 1}: El producto ${currentEntry.sku} - ${currentEntry.name} aparece duplicado en la compra`);
            setIndexRowWithError(j);
            setUpdating(false);
            return;
          }
        }
      }
      const deliveryNoteProducts: DeliveryNoteProductDto[] = [];
      for (const entryDeliveryNote of entries) {
        const deliveryNoteProduct: DeliveryNoteProductDto = {
          units: entryDeliveryNote.units!,
          unitCost: entryDeliveryNote.unitCost!,
          discount: entryDeliveryNote.discount,
          retailPrice: entryDeliveryNote.retailPrice,
          pvp: entryDeliveryNote.pvp!,
          weight: entryDeliveryNote.weight,
        };
        if (entryDeliveryNote?.productId && entryDeliveryNote.productId > 0) {
          deliveryNoteProduct.productId = entryDeliveryNote.productId;
        } else {
          deliveryNoteProduct.product = {
            name: entryDeliveryNote.name,
            description: null,
            sku: entryDeliveryNote.sku,
            ean: entryDeliveryNote.ean,
            enabled: true,
            brandId: entryDeliveryNote.brand!.value,
            collectionId: entryDeliveryNote.collectionId !== null && entryDeliveryNote.collectionId !== -1 ? entryDeliveryNote.collectionId : null,
            supplierIds: [selectedSupplier!.value],
            categoryIds: entryDeliveryNote.categories.map((c: { label: string; value: number }) => c.value),
            subCategoryIds: entryDeliveryNote.subCategories.map((sc: { label: string; value: number }) => sc.value),
            organizationId: organization.id,
            familyIds: entryDeliveryNote.families.map((f: { label: string; value: number }) => f.value),
            subFamilyIds: entryDeliveryNote.subFamilies.map((sf: { label: string; value: number }) => sf.value),
          };
        }
        if (entryDeliveryNote?.id) {
          deliveryNoteProduct.id = entryDeliveryNote.id;
        }
        deliveryNoteProducts.push(deliveryNoteProduct);
      }
      const deliveryNoteDto: DeliveryNoteDto = {
        date: date!,
        status,
        supplierId: deliveryNote!.supplierId,
        externalNumber,
        storeId: deliveryNote!.storeId,
        deliveryNoteProducts,
        invoiceDate: invoiceDate ? moment(invoiceDate).format('YYYY-MM-DD') : null,
        invoiceNumber,
        organizationId: organization.id,
        pin,
      };
      const updatedDeliveryNote: DeliveryNote = await api.updateDeliveryNote(deliveryNote!.id, deliveryNoteDto);
      // Imágenes de productos
      for (const sku in productsImages) {
        const index: number = updatedDeliveryNote.deliveryNoteProducts.findIndex((dnp: DeliveryNoteProduct) => dnp.product.sku === sku.toUpperCase());
        if (index !== -1) {
          const productId: number = updatedDeliveryNote.deliveryNoteProducts[index].productId;
          const rawProductImage: RawImage = productsImages[sku];
          if (rawProductImage.files !== null && Array.isArray(rawProductImage.files) && rawProductImage.files.length > 0) {
            for (const file of rawProductImage.files) {
              await api.uploadProductImage(productId, file);
            }
          } else if (rawProductImage.link !== null) {
            await api.uploadProductImageFromLink(productId, { link: rawProductImage.link });
          } else if (rawProductImage.dataUri !== null) {
            await api.uploadProductImageFromBase64(productId, { image: rawProductImage.dataUri });
          }
        }
      }
      setUpdating(false);
      navigate(`/compra/${updatedDeliveryNote.id}`, { replace: true });
      if (status === DeliveryNoteStatus.Pending) {
        myToastr.success('Documento actualizado');
      } else if (status === DeliveryNoteStatus.Received) {
        myToastr.success('Compra confirmada');
      }
    } catch (e: any) {
      if (Array.isArray(e.response.data.message)) {
        myToastr.error(e.response.data.message.join('<br>'));
      } else {
        myToastr.error(e.response.data.message);
      }
      setUpdating(false);
    }
  };

  const confirmDeliveryNote = async () => {
    if (!checkDeliveryNoteValidity()) {
      return;
    }
    setShowPinModalConfirmDeliveryNote(true);
  };

  const onClosePinModal = async (pin: string | null) => {
    if (!pin) {
      setShowPinModalConfirmDeliveryNote(false);
      return;
    }
    setConfirming(true);
    await updateDeliveryNote(DeliveryNoteStatus.Received, pin);
    setConfirming(false);
    setShowPinModalConfirmDeliveryNote(false);
  };

  const onCloseProductsFinderModal = async (product: Product | null) => {
    if (product) {
      const index: number = entries.findIndex((p: EntryDeliveryNote) => p.sku?.trim().toUpperCase() === product.sku.trim().toUpperCase());
      if (index !== -1) {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: El producto ${product.sku} - ${product.name} ya ha sido añadido a la compra`);
        return;
      }
      const deliveryNoteProductInfo: DeliveryNoteProductInfo = await api.getDeliveryNoteProductInfo(selectedSupplier!.value, selectedStoreId, product.id);
      setNewEntry({
        ...newEntry,
        productId: product.id,
        product,
        sku: product.sku,
        ean: product.ean,
        weight: deliveryNoteProductInfo.weight,
        pvp: deliveryNoteProductInfo.pvp,
        unitCost: deliveryNoteProductInfo.costPrice,
        discount: deliveryNoteProductInfo.discountValue,
        name: product.name,
        categories: product.categories.map((c: Category) => ({ label: c.name, value: c.id })),
        brand: { label: product.brand.name, value: product.brand.id },
      });
    }
    setShowProductFinderModal(false);
  };

  const removeResumeDeliveryNote = (index: number) => {
    const newEntries: EntryDeliveryNote[] = [...entries];
    const copyProductsImages = { ...productsImages };
    newEntries.splice(index, 1);
    if (
      copyProductsImages.hasOwnProperty(entries[index].sku) &&
      copyProductsImages[entries[index].sku].files !== null &&
      copyProductsImages[entries[index].sku].files!.length > 0 &&
      productsImagesUrls.hasOwnProperty(entries[index].sku)
    ) {
      // Eliminar imágenes que provienen de ficheros
      for (const e of productsImagesUrls[entries[index].sku]) {
        URL.revokeObjectURL(e);
      }
      delete copyProductsImages[entries[index].sku];
    }
    setEntries(newEntries);
    setProductsImages(copyProductsImages);
  };

  const removeNewEntry = () => {
    if (generatingLabels || creating || updating || confirming) {
      return;
    }
    skuSelectInputRef.current.clearValue();
    setNewEntry({
      productId: -1,
      product: null,
      sku: '',
      ean: '',
      weight: null,
      name: '',
      categories: [],
      subCategories: [],
      units: 1,
      unitCost: null,
      discount: null,
      retailPrice: null,
      pvp: null,
      total: null,
      margin: null,
      brand: null,
      collectionId: null,
      value: undefined,
      families: [],
      subFamilies: [],
    });
    if (newEntryImages !== null && newEntryImages.files !== null && newEntryImages.files.length > 0) {
      // Eliminar imágenes que provienen de ficheros
      for (const e of newEntryImagesUrls) {
        URL.revokeObjectURL(e);
      }
    }
    setNewEntryImages(null);
    setNewEntryImagesUrls([]);
  };

  // EXISTING ENTRIES

  const setEntrySku = (index: number, sku: string) => {
    const newEntries: EntryDeliveryNote[] = [...entries];
    newEntries[index].sku = sku.trim().toUpperCase();
    setEntries(newEntries);
    setIndexRowWithError(-1);
  };

  const setEntryName = (index: number, name: string) => {
    const newEntries: EntryDeliveryNote[] = [...entries];
    newEntries[index].name = name;
    setEntries(newEntries);
    setIndexRowWithError(-1);
  };

  const setEntryUnits = (index: number, units: number | null) => {
    const newEntries = [...entries];
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const unitCost: number = newEntries[index].unitCost || 0;
    const discount: number = newEntries[index].discount || 0;
    const retailPrice: number = newEntries[index].retailPrice || 0;
    const pvp: number = newEntries[index].pvp || 0;
    if (organizationType === OrganizationType.B2B) {
      if (units !== null && units > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        margin = retailPrice > 0 ? ((retailPrice / (1 + iva) - discountedCostEntry) / retailPrice) * 100 : 0;
      }
    } else {
      if (units !== null && units > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        margin = pvp > 0 ? ((pvp / (1 + iva) - discountedCostEntry) / pvp) * 100 : 0;
      }
    }
    newEntries[index].units = units;
    newEntries[index].total = roundTwoDecimals(total);
    newEntries[index].margin = roundTwoDecimals(margin);
    setEntries(newEntries);
    setIndexRowWithError(-1);
  };

  const setEntryWeight = (index: number, weight: number | null) => {
    const newEntries = [...entries];
    newEntries[index].weight = weight;
    setEntries(newEntries);
    setIndexRowWithError(-1);
  };

  const setEntryUnitCost = (index: number, unitCost: number | null) => {
    const newEntries = [...entries];
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const units: number = newEntries[index].units || 0;
    const discount: number = newEntries[index].discount || 0;
    const retailPrice: number = newEntries[index].retailPrice || 0;
    let pvp: number = newEntries[index].pvp || 0;
    if (organizationType === OrganizationType.B2B) {
      if (unitCost !== null && unitCost > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        margin = retailPrice > 0 ? ((retailPrice / (1 + iva) - discountedCostEntry) / retailPrice) * 100 : 0;
      }
    } else {
      if (unitCost !== null && unitCost > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        if (marginToApply !== '') {
          pvp = unitCost * (marginToApply as number);
        }
        margin = pvp > 0 ? ((pvp / (1 + iva) - discountedCostEntry) / pvp) * 100 : 0;
      }
    }
    newEntries[index].unitCost = unitCost;
    newEntries[index].pvp = roundTwoDecimals(pvp);
    newEntries[index].total = roundTwoDecimals(total);
    newEntries[index].margin = roundTwoDecimals(margin);
    setEntries(newEntries);
    setIndexRowWithError(-1);
  };

  const setEntryDiscount = (index: number, discount: number | null) => {
    const newEntries = [...entries];
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const units: number = newEntries[index].units || 0;
    const unitCost: number = newEntries[index].unitCost || 0;
    const retailPrice: number = newEntries[index].retailPrice || 0;
    const pvp: number = newEntries[index].pvp || 0;
    if (organizationType === OrganizationType.B2B) {
      const discountedCostEntry: number = unitCost * (1 - (discount || 0) / 100);
      total = units * discountedCostEntry;
      margin = retailPrice > 0 ? ((retailPrice / (1 + iva) - discountedCostEntry) / retailPrice) * 100 : 0;
    } else {
      const discountedCostEntry: number = unitCost * (1 - (discount || 0) / 100);
      total = units * discountedCostEntry;
      margin = pvp > 0 ? ((pvp / (1 + iva) - discountedCostEntry) / pvp) * 100 : 0;
    }
    newEntries[index].discount = discount;
    newEntries[index].total = roundTwoDecimals(total);
    newEntries[index].margin = roundTwoDecimals(margin);
    setEntries(newEntries);
    setIndexRowWithError(-1);
  };

  const setEntryRetailPrice = (index: number, retailPrice: number | null) => {
    const newEntries = [...entries];
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const units: number = newEntries[index].units || 0;
    const unitCost: number = newEntries[index].unitCost || 0;
    const discount: number = newEntries[index].discount || 0;
    if (retailPrice !== null && retailPrice > 0) {
      const discountedCostEntry: number = unitCost * (1 - discount / 100);
      total = units * discountedCostEntry;
      margin = retailPrice > 0 ? ((retailPrice / (1 + iva) - discountedCostEntry) / retailPrice) * 100 : 0;
    }
    newEntries[index].retailPrice = retailPrice;
    newEntries[index].total = roundTwoDecimals(total);
    newEntries[index].margin = roundTwoDecimals(margin);
    setEntries(newEntries);
    setIndexRowWithError(-1);
  };

  const setEntryPvp = (index: number, pvp: number | null) => {
    const newEntries = [...entries];
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const units: number = newEntries[index].units || 0;
    const unitCost: number = newEntries[index].unitCost || 0;
    const discount: number = newEntries[index].discount || 0;
    const retailPrice: number = newEntries[index].retailPrice || 0;
    if (organizationType === OrganizationType.B2B) {
      if (retailPrice !== null && retailPrice > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        margin = retailPrice > 0 ? ((retailPrice / (1 + iva) - discountedCostEntry) / retailPrice) * 100 : 0;
      }
    } else {
      if (pvp !== null && pvp > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        margin = pvp > 0 ? ((pvp / (1 + iva) - discountedCostEntry) / pvp) * 100 : 0;
      }
    }
    newEntries[index].pvp = pvp;
    newEntries[index].total = roundTwoDecimals(total);
    newEntries[index].margin = roundTwoDecimals(margin);
    setEntries(newEntries);
    setIndexRowWithError(-1);
  };

  // NEW ENTRY

  const onChangeNewEntrySku = async (e: any) => {
    if (e?.product) {
      const { product } = e;
      const index: number = entries.findIndex((p: EntryDeliveryNote) => p.sku?.trim().toUpperCase() === product.sku.trim().toUpperCase());
      if (index !== -1) {
        myToastr.error(`<strong>Fila ${index + 1}</strong>: El producto ${entries[index].sku} - ${entries[index].name} ya ha sido añadido a la compra`);
        setIndexRowWithError(index);
        return;
      }
      const deliveryNoteProductInfo: DeliveryNoteProductInfo = await api.getDeliveryNoteProductInfo(selectedSupplier!.value, selectedStoreId, product.id);
      setNewEntry({
        ...newEntry,
        productId: product.id,
        product,
        sku: product.sku.trim().toUpperCase(),
        ean: product.ean,
        weight: deliveryNoteProductInfo.weight,
        pvp: deliveryNoteProductInfo.pvp,
        unitCost: deliveryNoteProductInfo.costPrice,
        discount: deliveryNoteProductInfo.discountValue,
        name: product.name,
        families: product.families.map((f: Family) => ({ label: f.name, value: f.id })),
        subFamilies: product.subFamilies.map((sf: SubFamily) => ({ label: sf.name, value: sf.id })),
        brand: { label: product.brand.name, value: product.brand.id },
        collectionId: product.collectionId,
        categories: product.categories.map((c: Category) => ({ label: c.name, value: c.id })),
        subCategories: product.subCategories.map((sc: SubCategory) => ({ label: sc.name, value: sc.id })),
        value: { value: product.id, label: product.sku },
      });
    } else if (e == null) {
      setNewEntry({
        ...newEntry,
        productId: -1,
        product: null,
        sku: '',
        ean: '',
        weight: null,
        pvp: null,
        unitCost: null,
        discount: null,
        name: '',
        families: [],
        subFamilies: [],
        brand: null,
        categories: [],
        subCategories: [],
        value: undefined,
      });
    }
    setIndexRowWithError(-1);
  };

  const handleCreateNewEntrySku = (sku: any) => {
    setNewEntry({
      ...newEntry,
      value: { label: sku, value: -1 },
      sku,
    });
    setIndexRowWithError(-1);
  };

  const setNewEntryEan = (ean: string) => {
    setNewEntry({
      ...newEntry,
      ean,
    });
    setIndexRowWithError(-1);
  };

  const setNewWeight = (weight: number | null) => {
    setNewEntry({
      ...newEntry,
      weight,
    });
    setIndexRowWithError(-1);
  };

  const setNewEntryName = (name: string) => {
    setNewEntry({
      ...newEntry,
      name,
    });
    setIndexRowWithError(-1);
  };

  const setNewEntryCategories = (categories: any) => {
    const newSubCategories: { value: number; label: string }[] = newEntry.subCategories.filter((sf: { value: number; label: string }) => {
      const subCategory: SubCategory = subCategories.find((x: SubCategory) => x.id === sf.value)!;
      const index: number = categories.findIndex((x: { value: number; label: string }) => x.value === subCategory.categoryId);
      return index !== -1;
    });
    setNewEntry({
      ...newEntry,
      categories,
      subCategories: newSubCategories,
    });
    setIndexRowWithError(-1);
  };

  const setNewEntrySubCategories = (subCategories: any) => {
    setNewEntry({
      ...newEntry,
      subCategories,
    });
    setIndexRowWithError(-1);
  };

  const setNewEntryFamilies = (families: any) => {
    const newSubFamilies: { value: number; label: string }[] = newEntry.subFamilies.filter((sf: { value: number; label: string }) => {
      const subFamily: SubFamily = subFamilies.find((x: SubFamily) => x.id === sf.value)!;
      const index: number = families.findIndex((x: { value: number; label: string }) => x.value === subFamily.familyId);
      return index !== -1;
    });
    setNewEntry({
      ...newEntry,
      families,
      subFamilies: newSubFamilies,
    });
    setIndexRowWithError(-1);
  };

  const setNewEntrySubFamilies = (subFamilies: any) => {
    setNewEntry({
      ...newEntry,
      subFamilies,
    });
    setIndexRowWithError(-1);
  };

  const setNewEntryBrand = (e: { label: string; value: number } | null) => {
    const copyNewEntry = { ...newEntry };
    if (e === null || e.value === null) {
      copyNewEntry.collectionId = null;
    } else if (copyNewEntry.brand?.value !== e.value) {
      copyNewEntry.collectionId = null;
    }
    copyNewEntry.brand = e;
    setNewEntry(copyNewEntry);
    setIndexRowWithError(-1);
  };

  const setNewEntryCollection = (collectionId: number | null) => {
    setNewEntry({
      ...newEntry,
      collectionId,
    });
    setIndexRowWithError(-1);
  };

  const setNewEntryUnits = (units: number | null) => {
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const unitCost: number = newEntry.unitCost || 0;
    const discount: number = newEntry.discount || 0;
    const retailPrice: number = newEntry.retailPrice || 0;
    const pvp: number = newEntry.pvp || 0;
    if (organizationType === OrganizationType.B2B) {
      if (units !== null && units > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        margin = retailPrice > 0 ? ((retailPrice / (1 + iva) - discountedCostEntry) / retailPrice) * 100 : 0;
      }
    } else {
      if (units !== null && units > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        margin = pvp > 0 ? ((pvp / (1 + iva) - discountedCostEntry) / pvp) * 100 : 0;
      }
    }
    setNewEntry({
      ...newEntry,
      units,
      total: roundTwoDecimals(total),
      margin: roundTwoDecimals(margin),
    });
    setIndexRowWithError(-1);
  };

  const setNewEntryUnitCost = (unitCost: number | null) => {
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const units: number = newEntry.units || 0;
    const discount: number = newEntry.discount || 0;
    const retailPrice: number = newEntry.retailPrice || 0;
    let pvp: number = newEntry.pvp || 0;
    if (organizationType === OrganizationType.B2B) {
      if (unitCost !== null && unitCost > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        margin = retailPrice > 0 ? ((retailPrice / (1 + iva) - discountedCostEntry) / retailPrice) * 100 : 0;
      }
    } else {
      if (unitCost !== null && unitCost > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        if (marginToApply !== '') {
          pvp = unitCost * (marginToApply as number);
        }
        margin = pvp > 0 ? ((pvp / (1 + iva) - discountedCostEntry) / pvp) * 100 : 0;
      }
    }
    setNewEntry({
      ...newEntry,
      unitCost,
      pvp: roundTwoDecimals(pvp),
      total: roundTwoDecimals(total),
      margin: roundTwoDecimals(margin),
    });
    setIndexRowWithError(-1);
  };

  const setNewEntryDiscount = (discount: number | null) => {
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const units: number = newEntry.units || 0;
    const unitCost: number = newEntry.unitCost || 0;
    const retailPrice: number = newEntry.retailPrice || 0;
    const pvp: number = newEntry.pvp || 0;
    if (organizationType === OrganizationType.B2B) {
      const discountedCostEntry: number = unitCost * (1 - (discount || 0) / 100);
      total = units * discountedCostEntry;
      margin = retailPrice > 0 ? ((retailPrice / (1 + iva) - discountedCostEntry) / retailPrice) * 100 : 0;
    } else {
      const discountedCostEntry: number = unitCost * (1 - (discount || 0) / 100);
      total = units * discountedCostEntry;
      margin = pvp > 0 ? ((pvp / (1 + iva) - discountedCostEntry) / pvp) * 100 : 0;
    }
    setNewEntry({
      ...newEntry,
      discount,
      total: roundTwoDecimals(total),
      margin: roundTwoDecimals(margin),
    });
    setIndexRowWithError(-1);
  };

  const setNewEntryRetailPrice = (retailPrice: number | null) => {
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const units: number = newEntry.units || 0;
    const unitCost: number = newEntry.unitCost || 0;
    const discount: number = newEntry.discount || 0;
    if (retailPrice !== null && retailPrice > 0) {
      const discountedCostEntry: number = unitCost * (1 - discount / 100);
      total = units * discountedCostEntry;
      margin = retailPrice > 0 ? ((retailPrice / (1 + iva) - discountedCostEntry) / retailPrice) * 100 : 0;
    }
    setNewEntry({
      ...newEntry,
      retailPrice,
      total: roundTwoDecimals(total),
      margin: roundTwoDecimals(margin),
    });
    setIndexRowWithError(-1);
  };

  const setNewEntryPvp = (pvp: number | null) => {
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const units: number = newEntry.units || 0;
    const unitCost: number = newEntry.unitCost || 0;
    const discount: number = newEntry.discount || 0;
    const retailPrice: number = newEntry.retailPrice || 0;
    if (organizationType === OrganizationType.B2B) {
      if (retailPrice !== null && retailPrice > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        margin = retailPrice > 0 ? ((retailPrice / (1 + iva) - discountedCostEntry) / retailPrice) * 100 : 0;
      }
    } else {
      if (pvp !== null && pvp > 0) {
        const discountedCostEntry: number = unitCost * (1 - discount / 100);
        total = units * discountedCostEntry;
        margin = pvp > 0 ? ((pvp / (1 + iva) - discountedCostEntry) / pvp) * 100 : 0;
      }
    }
    setNewEntry({
      ...newEntry,
      pvp,
      total: roundTwoDecimals(total),
      margin: roundTwoDecimals(margin),
    });
    setIndexRowWithError(-1);
  };

  const onExpirationModalClose = (expiration: Expiration | null) => {
    if (expiration) {
      setDeliveryNote({ ...deliveryNote!, expiration });
    }
    setShowExpirationModal(false);
  };

  if (selectedStoreId === -1 && !params.hasOwnProperty('id')) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <h1>{organizationType === OrganizationType.B2C ? 'Selecciona una tienda' : 'Selecciona un almacén'}</h1>
      </div>
    );
  }

  const getProductsLabels = async () => {
    if (selectedStoreId <= 0) {
      myToastr.error(organizationType === OrganizationType.B2C ? 'Selecciona una tienda' : 'Selecciona un almacén');
      return;
    }
    if (generatingLabels) {
      return;
    }
    try {
      myToastr.info('Generando etiquetas. Este proceso puede llevar segundos. Espere por favor...');
      setGeneratingLabels(true);
      const getProductsTags: GetProductsTags = {
        organizationId: organization!.id,
        storeIds: [selectedStoreId],
        products: entries.map((entryDeliveryNote: EntryDeliveryNote) => {
          return {
            productId: entryDeliveryNote.productId,
            quantity: entryDeliveryNote.units || 1,
          };
        }),
      };
      const result: ArrayBuffer = await api.getProductsLabels(getProductsTags);
      downloadPdf(result, 'etiquetas.pdf');
    } catch (e: any) {
      const jsonString: string = new TextDecoder().decode(new Uint8Array(e.response.data));
      const httpExceptionDto: HttpExceptionDto = JSON.parse(jsonString);
      myToastr.error(httpExceptionDto.message);
    } finally {
      setGeneratingLabels(false);
    }
  };

  const onCloseUploadProductImageModal = (productImage: ProductImage[] | RawImage | null) => {
    if (productImage) {
      const pi: RawImage = productImage as RawImage;
      const images: string[] = [];
      if (pi.files !== null && pi.files.length > 0) {
        for (const file of pi.files) {
          images.push(URL.createObjectURL(file));
        }
      } else if (pi.link) {
        images.push(pi.link);
      } else if (pi.dataUri) {
        images.push(pi.dataUri);
      }
      if (selectedProductIndex === -1) {
        if (newEntryImages !== null && newEntryImages.files !== null && newEntryImages.files.length > 0) {
          // Eliminar imágenes que provienen de ficheros
          for (const e of newEntryImagesUrls) {
            URL.revokeObjectURL(e);
          }
        }
        setNewEntryImages(pi);
        setNewEntryImagesUrls(images);
      } else {
        const sku: string = entries[selectedProductIndex].sku;
        if (productsImages.hasOwnProperty(sku) && productsImages[sku].files !== null && productsImages[sku].files!.length > 0) {
          // Eliminar imágenes que provienen de ficheros
          for (const e of productsImagesUrls[sku]) {
            URL.revokeObjectURL(e);
          }
        }
        const copyProductsImages: { [sku: string]: RawImage } = { ...productsImages };
        copyProductsImages[sku] = pi;
        setProductsImages(copyProductsImages);
        const copyProductsImagesUrls: { [sku: string]: string[] } = { ...productsImagesUrls };
        copyProductsImagesUrls[sku] = images;
        setProductsImagesUrls(copyProductsImagesUrls);
      }
    }
    setSelectedProduct(null);
    setShowUploadProductModal(false);
    setSelectedProductIndex(-1);
  };

  const onChangeTranferUnits = (deliveryNoteProductId: number, storeId: number, units: number | null): void => {
    const copyTransferUnits = { ...transferUnits };
    if (!copyTransferUnits[deliveryNoteProductId]) {
      copyTransferUnits[deliveryNoteProductId] = {};
    }
    copyTransferUnits[deliveryNoteProductId][storeId] = units;
    setTransferUnits(copyTransferUnits);
  };

  const updateDraftTransfers = async () => {
    setCreatingTransfers(true);
    try {
      const deliveryNoteDraftTransfersDto: DeliveryNoteDraftTransfersDto = {
        deliveryNoteProductDrafTransfers: [],
      };
      for (const deliveryNoteProductIdStr in transferUnits) {
        if (transferUnits.hasOwnProperty(deliveryNoteProductIdStr)) {
          const deliveryNoteProductDraftTransferStores: DeliveryNoteProductDraftTransferStoreDto[] = [];
          for (const storeIdStr in transferUnits[deliveryNoteProductIdStr]) {
            if (transferUnits[deliveryNoteProductIdStr].hasOwnProperty(storeIdStr)) {
              let units: number | null = transferUnits[deliveryNoteProductIdStr][storeIdStr];
              if (units === undefined || isNaN(units as any)) {
                units = null;
              }
              deliveryNoteProductDraftTransferStores.push({
                storeId: parseInt(storeIdStr, 10),
                units,
              });
            }
          }
          deliveryNoteDraftTransfersDto.deliveryNoteProductDrafTransfers.push({
            deliveryNoteProductId: parseInt(deliveryNoteProductIdStr, 10),
            deliveryNoteProductDraftTransferStores,
          });
        }
      }
      if (deliveryNoteDraftTransfersDto.deliveryNoteProductDrafTransfers.length === 0) {
        myToastr.error('No se han introducido productos para transferir');
        setCreatingTransfers(false);
        return;
      }
      const updatedDeliveryNote: DeliveryNote = await api.updateDraftTransfersFromDeliveryNote(deliveryNote!.id, deliveryNoteDraftTransfersDto);
      setDeliveryNote(updatedDeliveryNote);
      myToastr.success('Datos guardados correctamente');
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
    setCreatingTransfers(false);
  };

  const createTransfers = async () => {
    let hasErrors = false;
    const newErrorProductTransfer: { [productId: number]: boolean } = {};
    for (const deliveryNoteProduct of deliveryNote!.deliveryNoteProducts) {
      let sum = 0;
      if (!transferUnits[deliveryNoteProduct.id]) {
        continue;
      }
      for (const storeId in transferUnits[deliveryNoteProduct.id]) {
        if (transferUnits[deliveryNoteProduct.id].hasOwnProperty(storeId)) {
          const units: number | null = transferUnits[deliveryNoteProduct.id][storeId];
          if (units !== undefined && units !== null && units < 0) {
            newErrorProductTransfer[deliveryNoteProduct.id] = true;
            hasErrors = true;
            myToastr.error(`La cantidad de unidades a transferir del producto <strong>${deliveryNoteProduct.product.name}</strong> es incorrecta`);
            break;
          }
          sum += transferUnits[deliveryNoteProduct.id][storeId]!;
        }
      }
      if (sum > deliveryNoteProduct.units) {
        newErrorProductTransfer[deliveryNoteProduct.id] = true;
        hasErrors = true;
        myToastr.error(`La suma de unidades a transferir del producto <strong>${deliveryNoteProduct.product.name}</strong> es mayor que las unidades de la compra`);
      }
    }
    if (hasErrors) {
      setErrorProductTransfer(newErrorProductTransfer);
      return;
    }
    setCreatingTransfers(true);
    try {
      const deliveryNoteCreateTransfersDto: DeliveryNoteCreateTransfersDto = {
        deliveryNoteProductTransfers: [],
      };
      for (const deliveryNoteProductIdStr in transferUnits) {
        if (transferUnits.hasOwnProperty(deliveryNoteProductIdStr)) {
          const deliveryNoteProductTransferStores: DeliveryNoteProductTransferStoreDto[] = [];
          for (const storeIdStr in transferUnits[deliveryNoteProductIdStr]) {
            if (transferUnits[deliveryNoteProductIdStr].hasOwnProperty(storeIdStr)) {
              const units: number | null = transferUnits[deliveryNoteProductIdStr][storeIdStr];
              if (units === null || units === undefined || isNaN(units) || units <= 0) {
                continue;
              }
              deliveryNoteProductTransferStores.push({
                storeId: parseInt(storeIdStr, 10),
                units,
              });
            }
          }
          if (deliveryNoteProductTransferStores.length > 0) {
            deliveryNoteCreateTransfersDto.deliveryNoteProductTransfers.push({
              deliveryNoteProductId: parseInt(deliveryNoteProductIdStr, 10),
              deliveryNoteProductTransferStores,
            });
          }
        }
      }
      if (deliveryNoteCreateTransfersDto.deliveryNoteProductTransfers.length === 0) {
        myToastr.error('No se han introducido productos para transferir');
        setCreatingTransfers(false);
        return;
      }
      const updatedDeliveryNote: DeliveryNote = await api.createTransfersFromDeliveryNote(deliveryNote!.id, deliveryNoteCreateTransfersDto);
      setDeliveryNote(updatedDeliveryNote);
      myToastr.success('Traspasos confirmados correctamente');
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
    setCreatingTransfers(false);
  };

  const onCloseProductWeightModal = (weight: number | null, unitCost: number | null) => {
    if (!weight && !unitCost) {
      setShowProductWeightModal(false);
      return;
    }
    let total = 0;
    let margin = 0;
    const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
    const units: number = newEntry.units || 0;
    const discount: number = newEntry.discount || 0;
    const pvp: number = newEntry.pvp || 0;
    if (unitCost !== null && unitCost > 0) {
      const discountedCostEntry: number = unitCost * (1 - discount / 100);
      total = units * discountedCostEntry;
      margin = pvp > 0 ? ((pvp / (1 + iva) - discountedCostEntry) / pvp) * 100 : 0;
    }
    setNewEntry({
      ...newEntry,
      weight,
      unitCost,
      total: roundTwoDecimals(total),
      margin: roundTwoDecimals(margin),
    });
    setShowProductWeightModal(false);
  };

  const onCloseUploadFileDeliveryNoteModal = async (updatedDeliveryNote: DeliveryNote | null) => {
    if (!updatedDeliveryNote) {
      setShowUploadFileDeliveryNoteModal(false);
      return;
    }
    setDeliveryNote(updatedDeliveryNote);
    setShowUploadFileDeliveryNoteModal(false);
  };

  const downloadFile = async () => {
    if (downloadingFile) {
      return;
    }
    setDownloadingFile(true);
    const id: JQuery<HTMLElement> = myToastr.info('Obteniendo el documento. Espera por favor.');
    const result: ArrayBuffer = await api.downloadApiFileGivenPath(deliveryNote!.filePath);
    const url: string = window.URL.createObjectURL(new Blob([result]));
    await sleep(2000);
    myToastr.remove(id);
    printJS(url);
    setDownloadingFile(false);
  };

  const updateInvoiceDetails = async () => {
    try {
      const deliveryNoteInvoiceDetailsDto: DeliveryNoteInvoiceDetailsDto = {
        invoiceDate: invoiceDate ? moment(invoiceDate).format('YYYY-MM-DD') : null,
        invoiceNumber,
      };
      const updatedDeliveryNote: DeliveryNote = await api.updateDeliveryNoteInvoiceDetails(deliveryNote!.id, deliveryNoteInvoiceDetailsDto);
      setDeliveryNote(updatedDeliveryNote);
      myToastr.success('Datos de la factura actualizados');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const pasteValues = () => {
    setNewEntry({
      ...newEntry,
      name: entries[selectedIndexToCopyValues].name,
      weight: entries[selectedIndexToCopyValues].weight,
      categories: entries[selectedIndexToCopyValues].categories,
      subCategories: entries[selectedIndexToCopyValues].subCategories,
      units: entries[selectedIndexToCopyValues].units,
      unitCost: entries[selectedIndexToCopyValues].unitCost,
      discount: entries[selectedIndexToCopyValues].discount,
      retailPrice: entries[selectedIndexToCopyValues].retailPrice,
      pvp: entries[selectedIndexToCopyValues].pvp,
      total: entries[selectedIndexToCopyValues].total,
      margin: entries[selectedIndexToCopyValues].margin,
      brand: entries[selectedIndexToCopyValues].brand,
      collectionId: entries[selectedIndexToCopyValues].collectionId,
      families: entries[selectedIndexToCopyValues].families,
      subFamilies: entries[selectedIndexToCopyValues].subFamilies,
    });
    skuSelectInputRef?.current.focus();
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="delivery-note-view d-flex flex-column p-4">
        <div className="d-flex justify-content-start align-items-center">
          <div onClick={() => navigate('/compras')} className="d-flex align-items-center come-back me-4">
            <ChevronLeft size={14} className="me-2" />
            Volver
          </div>
          <div className="customer-name flex-grow-1">{deliveryNote ? `Compra ${deliveryNote.internalId}` : 'Nueva compra'}</div>
          {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && deliveryNote?.status === DeliveryNoteStatus.Received && (
            <React.Fragment>
              <button
                className="create-button create-expiration me-2"
                disabled={generatingLabels}
                onClick={() => {
                  setShowExpirationModal(true);
                }}
              >
                {deliveryNote.expiration ? 'Actualizar' : 'Crear'} Vencimiento
              </button>
            </React.Fragment>
          )}
          {deliveryNote !== null && deliveryNote.status !== DeliveryNoteStatus.Cancelled && (
            <button className="create-button create-expiration me-2" disabled={generatingLabels} onClick={getProductsLabels}>
              Descargar etiquetas
            </button>
          )}
          {deliveryNote?.status === DeliveryNoteStatus.Received && (
            <ReactToPrint
              trigger={() => (
                <div className="create-button create-expiration d-flex align-items-center me-2">
                  <span>Imprimir</span>
                </div>
              )}
              content={() => tableProducts.current}
            />
          )}
          {(!deliveryNote || deliveryNote.status !== DeliveryNoteStatus.Cancelled) && user.role !== Role.WholesaleCustomer && (
            <button
              disabled={generatingLabels || creating || updating || confirming}
              className="create-button create-supplier"
              onClick={() => {
                setShowSupplierModal(true);
              }}
            >
              Crear proveedor
            </button>
          )}
        </div>
        <div className="row mt-4">
          <div className="col-8 px-2">
            <div className="d-flex flex-column container-info mb-3">
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className="title-input mb-2">Proveedor</div>
                    {deliveryNote ? (
                      <input type="text" value={selectedSupplier?.label || ''} disabled />
                    ) : suppliers.length === 0 ? (
                      <p>No hay proveedores disponibles</p>
                    ) : (
                      <Select
                        isDisabled={suppliers.length === 0 || deliveryNote !== null}
                        isClearable={true}
                        isSearchable={true}
                        value={selectedSupplier}
                        onChange={(e) => setSelectedSupplier(e)}
                        name="supplierId"
                        options={supplierOptions}
                        placeholder="Selecciona un proveedor"
                        noOptionsMessage={() => 'No hay proveedores'}
                        styles={styles}
                      />
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className={clsx('title-input mb-2', { error: hasErrorExternalNumber })}>Nº documento (Ref. albarán o factura)</div>
                    <input
                      disabled={deliveryNote !== null && deliveryNote.status !== DeliveryNoteStatus.Pending}
                      value={externalNumber}
                      onChange={(e: any) => {
                        setHasErrorExternalNumber(false);
                        setExternalNumber(e.target.value);
                      }}
                      onFocus={() => setHasErrorExternalNumber(false)}
                      type="text"
                      placeholder="Compra proveedor"
                      className={clsx({ error: hasErrorExternalNumber })}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-column">
                    <div className={clsx('title-input mb-2', { error: hasErrorDate })}>Fecha entrada prevista</div>
                    <input
                      value={moment(date).format('YYYY-MM-DD')}
                      placeholder="Fecha"
                      type="date"
                      disabled={deliveryNote !== null && deliveryNote.status !== DeliveryNoteStatus.Pending}
                      onChange={(e) => {
                        setHasErrorDate(false);
                        if (e.target.value && e.target.value.length > 0 && moment(e.target.value).isValid()) {
                          setDate(moment(e.target.value).toDate());
                        } else {
                          setDate(null);
                        }
                      }}
                      onFocus={() => setHasErrorDate(false)}
                      className={clsx({ error: hasErrorDate })}
                    />
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </div>
            {(!deliveryNote || deliveryNote.status !== DeliveryNoteStatus.Cancelled) && (
              <div className="d-flex flex-column container-info">
                <div className="row align-items-end">
                  <div className="row align-items-end justify-content-between mb-3">
                    <div className="title w-auto">Factura asociada</div>
                    {deliveryNote !== null && (
                      <div className="d-flex flex-row align-items-end justify-content-end w-auto">
                        {deliveryNote.filePath && (
                          <React.Fragment>
                            <div className="d-flex align-items-end export-csv cursor-pointer w-auto" onClick={downloadFile}>
                              <Download className="me-1" size={14} /> Descargar documento
                            </div>
                            {organization.id === deliveryNote.organizationId && (
                              <button className="create-button create-expiration me-2" onClick={() => setShowUploadFileDeliveryNoteModal(true)}>
                                {deliveryNote.filePath ? 'Reemplazar documento' : 'Subir documento'}
                              </button>
                            )}
                          </React.Fragment>
                        )}
                        {!deliveryNote.filePath && (
                          <div className="d-flex align-items-end export-csv cursor-pointer w-auto" onClick={() => setShowUploadFileDeliveryNoteModal(true)}>
                            <Plus className="me-1" size={14} /> Subir documento
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column">
                      <div className="title-input mb-2">Número factura</div>
                      <input type="text" value={invoiceNumber ?? ''} onChange={(e) => setInvoiceNumber(e.target.value)} placeholder="Número factura" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column">
                      <div className={clsx('title-input mb-2', { error: hasErrorInvoiceDate })}>Fecha factura</div>
                      <input
                        value={invoiceDate ? moment(invoiceDate).format('YYYY-MM-DD') : ''}
                        placeholder="Fecha"
                        type="date"
                        onChange={(e) => {
                          setHasErrorInvoiceDate(false);
                          if (e.target.value && e.target.value.length > 0 && moment(e.target.value).isValid()) {
                            setInvoiceDate(moment(e.target.value).toDate());
                          } else {
                            setInvoiceDate(null);
                          }
                        }}
                        onFocus={() => setHasErrorInvoiceDate(false)}
                        className={clsx({ error: hasErrorInvoiceDate })}
                      />
                    </div>
                  </div>
                  {deliveryNote !== null && (
                    <div className="col-1 pb-1">
                      <span style={{ cursor: generatingLabels ? 'not-allowed' : 'pointer' }} onClick={updateInvoiceDetails} className="icon-button">
                        <Save size={24} color={generatingLabels ? '#c7cfd8' : '#222E3D'} />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="col-4 px-2">
            <div className="container-info">
              <div className="d-flex flex-column cursor-default mb-3">
                <div className="title-input mb-2">Estado</div>
                {deliveryNote?.status === DeliveryNoteStatus.Cancelled && (
                  <div className="delivery-note-status delivery-note-cancelled">{deliveryNoteStatusToString(DeliveryNoteStatus.Cancelled)}</div>
                )}
                {(deliveryNote == null || deliveryNote?.status === DeliveryNoteStatus.Pending) && (
                  <div className="delivery-note-status delivery-note-pending">{deliveryNoteStatusToString(DeliveryNoteStatus.Pending)}</div>
                )}
                {deliveryNote?.status === DeliveryNoteStatus.Received && <div className="delivery-note-status delivery-note-received">{deliveryNoteStatusToString(DeliveryNoteStatus.Received)}</div>}
                {deliveryNote?.status === DeliveryNoteStatus.Invoiced && <div className="delivery-note-status delivery-note-invoiced">{deliveryNoteStatusToString(DeliveryNoteStatus.Invoiced)}</div>}
              </div>
              {deliveryNote?.verificationDate && (
                <div className="d-flex flex-column">
                  <div className="title-input mb-2">Confirmación de la recepción: {moment(deliveryNote.verificationDate).format('DD/MM/YYYY')}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="my-4">
          <Tabs activeKey={selectedTab} onSelect={(k) => setSelectedTab(k as DeliveryNoteTab)} className="">
            {(deliveryNote || selectedStoreId >= 1) && selectedSupplier && selectedSupplier.value >= 1 && (
              <Tab eventKey={DeliveryNoteTab.Products} title="Listado de productos">
                <React.Fragment>
                  <div className="row" style={{ marginBottom: 80 }}>
                    <div className="col">
                      <div className="container-info">
                        <div className="d-flex align-items-center gap-3 mb-3">
                          <label className="title-input">Fijar margen al precio de coste:</label>
                          <input type="number" value={marginToApply} onChange={(e) => setMarginToApply(e.target.value !== '' ? parseFloat(e.target.value) : '')} style={{ width: 80 }} />
                        </div>
                        <div className="container-my-table">
                          <table className="my-table delivery-note-products">
                            <thead>
                              <tr>
                                <th>Fila</th>
                                {(deliveryNote == null || deliveryNote.status === DeliveryNoteStatus.Pending) && <th className="actions">Acción</th>}
                                <th className="image">Imagen</th>
                                <th className="sku">SKU</th>
                                <th className="">Nombre</th>
                                <th className="">Marca</th>
                                <th className="numeric-input">Uds. Tot.</th>
                                <th className="numeric-input">Peso (gr)</th>
                                {user.role !== Role.Seller && (
                                  <th className="" style={{ width: 120 }}>
                                    Coste Ud. € <br></br>(IVA no incl.)
                                  </th>
                                )}
                                {user.role !== Role.Seller && <th className="numeric-input">Desc. %</th>}
                                {organizationType === OrganizationType.B2B && (
                                  <th className="numeric-input" title="Precio venta minorista">
                                    PVM Ud. €
                                  </th>
                                )}
                                <th className="numeric-input">PVP Ud. €</th>
                                {user.role !== Role.Seller && <th className="">Total €</th>}
                                {user.role !== Role.Seller && <th className="">Margen %</th>}
                                {(deliveryNote == null || (deliveryNote.status === DeliveryNoteStatus.Pending && !deliveryNote.originOrganizationId)) && <th></th>}
                              </tr>
                            </thead>
                            <tbody>
                              {/* PRODUCTOS AÑADIDOS A LA COMPRA */}
                              {entries.length > 0 &&
                                entries.map((entryDeliveryNote: EntryDeliveryNote, index: number) => {
                                  let urls: string[] = [];
                                  if (productsImagesUrls.hasOwnProperty(entryDeliveryNote.sku) && productsImagesUrls[entryDeliveryNote.sku].length > 0) {
                                    urls = productsImagesUrls[entryDeliveryNote.sku];
                                  } else if (entryDeliveryNote.product?.images && entryDeliveryNote.product.images.length > 0) {
                                    urls = [...urls, ...entryDeliveryNote.product.images.map((productImage: ProductImage) => process.env.REACT_APP_PUBLIC_URL + productImage.path)];
                                  }
                                  let initialDeliveryNoteProduct: InitialDeliveryNoteProduct | null = null;
                                  if (deliveryNote) {
                                    initialDeliveryNoteProduct = deliveryNote.initialDeliveryNoteProducts.find((p: InitialDeliveryNoteProduct) => p.productId === entryDeliveryNote.productId) || null;
                                  }
                                  let rowClass = 'existing-product';
                                  if (index === indexRowWithError) {
                                    rowClass = 'error-product';
                                  } else if (entryDeliveryNote.productId === -1) {
                                    rowClass = 'new-product';
                                  }
                                  return (
                                    <tr key={`edn-${index}`} className={rowClass}>
                                      <td style={{ textAlign: 'center' }}>
                                        <strong>{index + 1}</strong>
                                      </td>
                                      {(deliveryNote == null || deliveryNote.status === DeliveryNoteStatus.Pending) && (
                                        <td className="text-center">
                                          <div className="d-flex flex-row gap-2">
                                            {(deliveryNote == null || !deliveryNote.originOrganizationId) && (
                                              // Nuevo albarán o albarán que no proviene de un mayorista
                                              <span title="Eliminar producto" className="clickable">
                                                <Trash2
                                                  onClick={() => {
                                                    if (generatingLabels || creating || updating || confirming) {
                                                      return;
                                                    }
                                                    removeResumeDeliveryNote(index);
                                                  }}
                                                  size={16}
                                                  className="clickable"
                                                />
                                              </span>
                                            )}
                                            {entryDeliveryNote.productId === -1 && newEntryImagesUrls.length === 0 && urls.length === 0 && (
                                              <span title="Añadir imagen al producto" className="clickable">
                                                <Plus
                                                  onClick={() => {
                                                    setSelectedProduct({ name: entryDeliveryNote.name });
                                                    setSelectedProductIndex(index);
                                                    setShowUploadProductModal(true);
                                                  }}
                                                  size={16}
                                                />
                                              </span>
                                            )}
                                            <span title="Copiar valores" className="clickable">
                                              <Copy onClick={() => setSelectedIndexToCopyValues(index)} size={16} />
                                            </span>
                                          </div>
                                        </td>
                                      )}
                                      <td className={clsx(urls.length > 0 ? 'clickable' : '')}>
                                        {urls.length > 0 ? (
                                          <LightgalleryProvider>
                                            {urls.map((url: string, index: number) => {
                                              return (
                                                <LightgalleryItem key={index} group="group-product-images" src={url}>
                                                  {index === 0 && <LazyLoadImage src={url} alt="" className="img-thumbnail" />}
                                                </LightgalleryItem>
                                              );
                                            })}
                                          </LightgalleryProvider>
                                        ) : (
                                          <Image size={65} className="img-thumbnail" color="#D3D3D3" />
                                        )}
                                      </td>
                                      <td>
                                        {deliveryNote?.status === DeliveryNoteStatus.Received ? (
                                          <span>{entryDeliveryNote.sku}</span>
                                        ) : entryDeliveryNote.product !== null ? (
                                          <span>{entryDeliveryNote.sku}</span>
                                        ) : (
                                          <input
                                            className={clsx({ error: entryDeliveryNote.sku == null || entryDeliveryNote.sku.length === 0 })}
                                            value={entryDeliveryNote.sku}
                                            onChange={(e: any) => setEntrySku(index, e.target.value ? e.target.value : null)}
                                            type="text"
                                            placeholder="Referencia"
                                          />
                                        )}
                                      </td>
                                      <td>
                                        {deliveryNote?.status === DeliveryNoteStatus.Received || deliveryNote?.originOrganizationId ? (
                                          <Link to={`/producto/${entryDeliveryNote.productId}`} className="link-sale">
                                            {ellipsis(entryDeliveryNote.name, 35)}
                                          </Link>
                                        ) : entryDeliveryNote.product !== null ? (
                                          <span>{ellipsis(entryDeliveryNote.name, 35)}</span>
                                        ) : (
                                          <input
                                            className={clsx({ error: entryDeliveryNote.name == null || entryDeliveryNote.name.length === 0 })}
                                            value={entryDeliveryNote.name}
                                            onChange={(e: any) => setEntryName(index, e.target.value)}
                                            type="text"
                                            placeholder="Nombre"
                                          />
                                        )}
                                      </td>
                                      <td>
                                        <span>{entryDeliveryNote.brand?.label || ''}</span>
                                      </td>
                                      <td>
                                        {deliveryNote?.status === DeliveryNoteStatus.Received || deliveryNote?.originOrganizationId ? (
                                          <React.Fragment>
                                            {initialDeliveryNoteProduct !== null && initialDeliveryNoteProduct.units !== entryDeliveryNote.units && (
                                              <span
                                                style={{
                                                  marginRight: '5px',
                                                  color: 'red',
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                ({initialDeliveryNoteProduct.units})
                                              </span>
                                            )}
                                            <span>{entryDeliveryNote.units}</span>
                                          </React.Fragment>
                                        ) : (
                                          <input
                                            className={clsx({ error: entryDeliveryNote.units == null })}
                                            disabled={deliveryNote !== null && deliveryNote.status !== DeliveryNoteStatus.Pending}
                                            value={entryDeliveryNote.units !== null ? entryDeliveryNote.units : ''}
                                            onChange={(e: any) => {
                                              const parsedValue: number = parseInt(e.target.value, 10);
                                              if (!isNaN(parsedValue)) {
                                                setEntryUnits(index, parsedValue);
                                              } else {
                                                setEntryUnits(index, null);
                                              }
                                            }}
                                            type="number"
                                            step={1}
                                            placeholder="Unidades"
                                          />
                                        )}
                                      </td>
                                      <td>
                                        {deliveryNote?.status === DeliveryNoteStatus.Received || deliveryNote?.originOrganizationId ? (
                                          <React.Fragment>
                                            {initialDeliveryNoteProduct !== null && initialDeliveryNoteProduct.weight !== entryDeliveryNote.weight && (
                                              <span
                                                style={{
                                                  marginRight: '5px',
                                                  color: 'red',
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                ({initialDeliveryNoteProduct.weight ?? '-'})
                                              </span>
                                            )}
                                            <span>{entryDeliveryNote.weight}</span>
                                          </React.Fragment>
                                        ) : (
                                          <input
                                            className={clsx({ error: entryDeliveryNote.weight !== null && (isNaN(entryDeliveryNote.weight as any) || entryDeliveryNote.weight <= 0) })}
                                            disabled={deliveryNote !== null && deliveryNote.status !== DeliveryNoteStatus.Pending}
                                            value={entryDeliveryNote.weight !== null ? entryDeliveryNote.weight : ''}
                                            onChange={(e: any) => {
                                              const parsedValue: number = parseFloat(e.target.value);
                                              if (!isNaN(parsedValue)) {
                                                setEntryWeight(index, parsedValue);
                                              } else {
                                                setEntryWeight(index, null);
                                              }
                                            }}
                                            type="number"
                                            step={1}
                                            placeholder="Peso"
                                          />
                                        )}
                                      </td>
                                      {user.role !== Role.Seller && (
                                        <td>
                                          {deliveryNote?.status === DeliveryNoteStatus.Received || deliveryNote?.originOrganizationId ? (
                                            <React.Fragment>
                                              {initialDeliveryNoteProduct !== null && initialDeliveryNoteProduct.unitCost !== entryDeliveryNote.unitCost && (
                                                <span
                                                  style={{
                                                    marginRight: '5px',
                                                    color: 'red',
                                                    fontWeight: 'bold',
                                                  }}
                                                >
                                                  ({formatNumber(initialDeliveryNoteProduct.unitCost)}€)
                                                </span>
                                              )}
                                              <span>{formatNumber(entryDeliveryNote.unitCost)}€</span>
                                            </React.Fragment>
                                          ) : (
                                            <input
                                              className={clsx({ error: entryDeliveryNote.unitCost == null || entryDeliveryNote.unitCost <= 0 })}
                                              disabled={deliveryNote !== null && deliveryNote.status !== DeliveryNoteStatus.Pending}
                                              value={entryDeliveryNote.unitCost !== null ? entryDeliveryNote.unitCost : ''}
                                              onChange={(e: any) => {
                                                if (e.target.value) {
                                                  setEntryUnitCost(index, parseFloat(e.target.value));
                                                } else {
                                                  setEntryUnitCost(index, null);
                                                }
                                              }}
                                              type="number"
                                              min={0}
                                              placeholder="Coste"
                                            />
                                          )}
                                        </td>
                                      )}
                                      {user.role !== Role.Seller && (
                                        <td>
                                          {deliveryNote?.status === DeliveryNoteStatus.Received || deliveryNote?.originOrganizationId ? (
                                            <React.Fragment>
                                              {initialDeliveryNoteProduct !== null && initialDeliveryNoteProduct.discount !== entryDeliveryNote.discount && (
                                                <span
                                                  style={{
                                                    marginRight: '5px',
                                                    color: 'red',
                                                    fontWeight: 'bold',
                                                  }}
                                                >
                                                  ({formatNumber(initialDeliveryNoteProduct.discount)}%)
                                                </span>
                                              )}
                                              <span>{entryDeliveryNote.discount !== null ? `${formatNumber(entryDeliveryNote.discount)}%` : '-'}</span>
                                            </React.Fragment>
                                          ) : (
                                            <input
                                              className={clsx({ error: entryDeliveryNote.discount !== null && (isNaN(entryDeliveryNote.discount as any) || entryDeliveryNote.discount < 0) })}
                                              disabled={deliveryNote !== null && deliveryNote.status !== DeliveryNoteStatus.Pending}
                                              value={entryDeliveryNote.discount !== null ? entryDeliveryNote.discount : ''}
                                              onChange={(e: any) => {
                                                if (e.target.value) {
                                                  setEntryDiscount(index, parseFloat(e.target.value));
                                                } else {
                                                  setEntryDiscount(index, null);
                                                }
                                              }}
                                              type="number"
                                              min={0}
                                              placeholder="Desc"
                                            />
                                          )}
                                        </td>
                                      )}
                                      {organizationType === OrganizationType.B2B && (
                                        <td>
                                          {deliveryNote?.status === DeliveryNoteStatus.Received ? (
                                            <React.Fragment>
                                              {initialDeliveryNoteProduct !== null && initialDeliveryNoteProduct.retailPrice !== entryDeliveryNote.retailPrice && (
                                                <span
                                                  style={{
                                                    marginRight: '5px',
                                                    color: 'red',
                                                    fontWeight: 'bold',
                                                  }}
                                                >
                                                  ({formatNumber(initialDeliveryNoteProduct.retailPrice)}€)
                                                </span>
                                              )}
                                              <span>{formatNumber(entryDeliveryNote.retailPrice)}€</span>
                                            </React.Fragment>
                                          ) : (
                                            <input
                                              className={clsx({ error: entryDeliveryNote.retailPrice == null || entryDeliveryNote.retailPrice <= 0 })}
                                              disabled={deliveryNote !== null && deliveryNote.status !== DeliveryNoteStatus.Pending}
                                              value={entryDeliveryNote?.retailPrice ? entryDeliveryNote.retailPrice : ''}
                                              onChange={(e: any) => {
                                                if (e.target.value) {
                                                  setEntryRetailPrice(index, parseFloat(e.target.value));
                                                } else {
                                                  setEntryRetailPrice(index, null);
                                                }
                                              }}
                                              type="number"
                                              placeholder="PVM Ud. €"
                                            />
                                          )}
                                        </td>
                                      )}
                                      <td>
                                        {deliveryNote?.status === DeliveryNoteStatus.Received || deliveryNote?.originOrganizationId ? (
                                          <React.Fragment>
                                            {initialDeliveryNoteProduct !== null && initialDeliveryNoteProduct.pvp !== entryDeliveryNote.pvp && (
                                              <span
                                                style={{
                                                  marginRight: '5px',
                                                  color: 'red',
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                ({formatNumber(initialDeliveryNoteProduct.pvp)}€)
                                              </span>
                                            )}
                                            <span>{formatNumber(entryDeliveryNote.pvp)}€</span>
                                          </React.Fragment>
                                        ) : (
                                          <input
                                            className={clsx({ error: entryDeliveryNote.pvp == null || entryDeliveryNote.pvp <= 0 })}
                                            disabled={deliveryNote !== null && deliveryNote.status !== DeliveryNoteStatus.Pending}
                                            value={entryDeliveryNote?.pvp ? entryDeliveryNote.pvp : ''}
                                            onChange={(e: any) => {
                                              if (e.target.value) {
                                                setEntryPvp(index, parseFloat(e.target.value));
                                              } else {
                                                setEntryPvp(index, null);
                                              }
                                            }}
                                            type="number"
                                            placeholder="PVP"
                                          />
                                        )}
                                      </td>
                                      {user.role !== Role.Seller && (
                                        <td>
                                          <span>{formatNumber(entryDeliveryNote.total)}€</span>
                                        </td>
                                      )}
                                      {user.role !== Role.Seller && (
                                        <td>
                                          <span>{entryDeliveryNote.margin !== null ? `${formatNumber(entryDeliveryNote.margin)}%` : ''}</span>
                                        </td>
                                      )}
                                      {(deliveryNote == null || (deliveryNote.status === DeliveryNoteStatus.Pending && !deliveryNote.originOrganizationId)) && <td></td>}
                                    </tr>
                                  );
                                })}
                              {deleteDeliveryNoteProducts.map((initialDeliveryNoteProduct: InitialDeliveryNoteProduct) => {
                                let urls: string[] = [];
                                if (initialDeliveryNoteProduct.product?.images && initialDeliveryNoteProduct.product.images.length > 0) {
                                  urls = initialDeliveryNoteProduct.product.images.map((productImage: ProductImage) => process.env.REACT_APP_PUBLIC_URL + productImage.path);
                                }
                                let brandName: string | null = null;
                                if (initialDeliveryNoteProduct.product.brandId) {
                                  const brand: Brand | undefined = brands.find((b: Brand) => b.id === initialDeliveryNoteProduct.product.brandId);
                                  if (brand) {
                                    brandName = brand.name;
                                  }
                                }
                                let total = 0;
                                if (initialDeliveryNoteProduct.discount) {
                                  total = initialDeliveryNoteProduct.units * initialDeliveryNoteProduct.pvp * (1 - initialDeliveryNoteProduct.discount / 100);
                                } else {
                                  total = initialDeliveryNoteProduct.units * initialDeliveryNoteProduct.pvp;
                                }
                                let margin = 0;
                                if (initialDeliveryNoteProduct.unitCost) {
                                  const iva: number = parseFloat(process.env.REACT_APP_IVA!) / 100;
                                  margin = ((initialDeliveryNoteProduct.pvp / (1 + iva) - initialDeliveryNoteProduct.unitCost) / initialDeliveryNoteProduct.pvp) * 100;
                                }
                                return (
                                  <tr key={initialDeliveryNoteProduct.id} style={{ backgroundColor: '#ffe2e2' }}>
                                    <td></td>
                                    <td className={clsx(urls.length > 0 ? 'clickable' : '')}>
                                      {urls.length > 0 && (
                                        <LightgalleryProvider>
                                          {urls.map((url: string, index: number) => {
                                            return (
                                              <LightgalleryItem key={index} group="group-product-images" src={url}>
                                                {index === 0 && <LazyLoadImage src={url} alt="" className="img-thumbnail clickable" />}
                                              </LightgalleryItem>
                                            );
                                          })}
                                        </LightgalleryProvider>
                                      )}
                                    </td>
                                    <td>
                                      <span>{initialDeliveryNoteProduct.product.sku}</span>
                                    </td>
                                    <td>
                                      <span>{initialDeliveryNoteProduct.product.name}</span>
                                    </td>
                                    <td>{brandName}</td>
                                    <td>
                                      <span>{initialDeliveryNoteProduct.units}</span>
                                    </td>
                                    <td>
                                      <span>{formatNumber(initialDeliveryNoteProduct.unitCost)}€</span>
                                    </td>
                                    <td>
                                      <span>{initialDeliveryNoteProduct.discount !== null ? `${formatNumber(initialDeliveryNoteProduct.discount)}%` : '-'}</span>
                                    </td>
                                    <td>
                                      <span>{formatNumber(initialDeliveryNoteProduct.pvp)}€</span>
                                    </td>
                                    {user.role !== Role.Seller && (
                                      <td>
                                        <span>{formatNumber(total)}€</span>
                                      </td>
                                    )}
                                    {user.role !== Role.Seller && (
                                      <td>
                                        <span>{formatNumber(margin)}%</span>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                              {/* AÑADIR PRODUCTO */}
                              {(deliveryNote == null || (deliveryNote.status === DeliveryNoteStatus.Pending && !deliveryNote.originOrganizationId)) && (
                                <tr className={clsx({ 'error-product': entries.length === indexRowWithError })}>
                                  <td style={{ textAlign: 'center' }}>
                                    <strong>{entries.length + 1}</strong>
                                  </td>
                                  <td>
                                    <div className="d-flex flex-row align-items-center gap-1">
                                      {newEntry?.productId && newEntry.productId > 0 && (
                                        <span title="Eliminar producto" className="clickable">
                                          <Trash2 color="#808a95" size={16} onClick={removeNewEntry} />
                                        </span>
                                      )}
                                      {newEntryImagesUrls.length > 0 && (
                                        <span title="Eliminar imágenes del producto" className="clickable">
                                          <X
                                            color="#808a95"
                                            size={16}
                                            onClick={() => {
                                              if (generatingLabels || creating || updating || confirming) {
                                                return;
                                              }
                                              if (newEntryImages !== null && newEntryImages.files !== null && newEntryImages.files.length > 0) {
                                                // Eliminar imágenes que provienen de ficheros
                                                for (const e of newEntryImagesUrls) {
                                                  URL.revokeObjectURL(e);
                                                }
                                              }
                                              setNewEntryImagesUrls([]);
                                            }}
                                          />
                                        </span>
                                      )}
                                      {newEntry.productId === -1 && newEntryImagesUrls.length === 0 && (
                                        <span title="Añadir imagen al producto" className="clickable">
                                          <Image
                                            color="#808a95"
                                            size={16}
                                            onClick={() => {
                                              if (generatingLabels || creating || updating || confirming) {
                                                return;
                                              }
                                              setSelectedProduct({ name: newEntry.name || 'Nuevo producto' });
                                              setSelectedProductIndex(-1);
                                              setShowUploadProductModal(true);
                                            }}
                                          />
                                        </span>
                                      )}
                                      {selectedIndexToCopyValues !== -1 && (
                                        <span title="Pegar valores" onClick={pasteValues} className="cursor-pointer">
                                          <Clipboard color="#808a95" size={16} />
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    {newEntry?.product && newEntry.product.images.length > 0 ? (
                                      <LightgalleryProvider>
                                        {newEntry.product.images.map((productImage: ProductImage, index: number) => {
                                          const url = `${process.env.REACT_APP_PUBLIC_URL}${productImage.path}`;
                                          return (
                                            <LightgalleryItem key={index} group="group-product-images" src={url}>
                                              {index === 0 && <LazyLoadImage src={url} alt="" className="img-thumbnail clickable" />}
                                            </LightgalleryItem>
                                          );
                                        })}
                                      </LightgalleryProvider>
                                    ) : newEntryImagesUrls.length > 0 ? (
                                      <LightgalleryProvider>
                                        {newEntryImagesUrls.map((url: string, index: number) => {
                                          return (
                                            <LightgalleryItem key={index} group="group-product-images" src={url}>
                                              {index === 0 && <LazyLoadImage src={url} alt="" className="img-thumbnail clickable" />}
                                            </LightgalleryItem>
                                          );
                                        })}
                                      </LightgalleryProvider>
                                    ) : (
                                      <Image size={65} className="img-thumbnail" color="#D3D3D3" />
                                    )}
                                  </td>
                                  <td>
                                    <AsyncCreatableSelect
                                      ref={skuSelectInputRef}
                                      placeholder="SKU"
                                      defaultOptions
                                      noOptionsMessage={({ inputValue }: any) => (!inputValue ? null : <div className="enter-text my-2">No se han encontrado productos</div>)}
                                      onChange={(value) => {
                                        setHasErrorSku(false);
                                        onChangeNewEntrySku(value);
                                      }}
                                      onFocus={() => setHasErrorSku(false)}
                                      value={newEntry?.value}
                                      onCreateOption={handleCreateNewEntrySku}
                                      isClearable
                                      menuPlacement="bottom"
                                      menuPosition="fixed"
                                      loadingMessage={() => 'Buscando productos...'}
                                      onInputChange={(inputValue: string) => (skuRef.current = inputValue)}
                                      onKeyDown={async (e) => {
                                        if ((e.key === 'Enter' || e.key === 'Tab') && skuRef.current) {
                                          e.stopPropagation();
                                          const products: Product[] = await api.getProducts({ sku: skuRef.current! });
                                          if (products.length === 1) {
                                            onChangeNewEntrySku({ product: products[0] });
                                          } else {
                                            setNewEntry({ ...newEntry, sku: skuRef.current! });
                                          }
                                          skuRef.current = null;
                                        }
                                      }}
                                      loadOptions={(inputValue: string) => debouncedLoadProductOptions(inputValue)}
                                      components={{
                                        DropdownIndicator: null,
                                        Option: (props: any) => <ProductOption {...props} onCreate={(sku: any) => handleCreateNewEntrySku(props.data.value)} />,
                                      }}
                                      styles={multiSelectStylesDeliveryNotes(hasErrorSku)}
                                      menuPortalTarget={document.body}
                                      autoFocus={true}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className={clsx({ error: hasErrorName })}
                                      disabled={newEntry.product != null}
                                      onChange={(e: any) => {
                                        setHasErrorName(false);
                                        setNewEntryName(e.target.value);
                                      }}
                                      onFocus={() => setHasErrorName(false)}
                                      value={newEntry.name || ''}
                                      type="text"
                                      placeholder="Nombre"
                                    />
                                  </td>
                                  <td>
                                    <Select
                                      placeholder="Marca"
                                      value={newEntry.brand}
                                      isDisabled={newEntry.product !== null}
                                      isClearable={true}
                                      onFocus={() => setHasErrorBrand(false)}
                                      options={brands.map((brand: Brand) => ({ label: brand.name, value: brand.id }))}
                                      onChange={(e) => {
                                        setHasErrorBrand(false);
                                        setNewEntryBrand(e);
                                      }}
                                      styles={multiSelectStylesDeliveryNotes(hasErrorBrand)}
                                      menuPortalTarget={document.body}
                                      filterOption={(option, inputValue: string) => (!inputValue || inputValue.length < 2 ? false : option.label.toLowerCase().startsWith(inputValue.toLowerCase()))}
                                      noOptionsMessage={({ inputValue }) =>
                                        newEntry.brand ? null : !inputValue ? (
                                          <div>Introduce la marca</div>
                                        ) : inputValue.length < 2 ? (
                                          <div>Escribe al menos 2 caracteres</div>
                                        ) : (
                                          <div>No hay coincidencias</div>
                                        )
                                      }
                                      components={{
                                        DropdownIndicator: null,
                                        Input: (props) => (newEntry.brand ? null : <CustomInput {...props} placeholder="Marca" />),
                                        Placeholder: () => null,
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className={clsx({ error: hasErrorUnits })}
                                      onChange={(e: any) => {
                                        setHasErrorUnits(false);
                                        const parsedValue: number = parseInt(e.target.value, 10);
                                        if (!isNaN(parsedValue)) {
                                          setNewEntryUnits(parsedValue);
                                        } else {
                                          setNewEntryUnits(null);
                                        }
                                      }}
                                      onFocus={() => setHasErrorUnits(false)}
                                      value={newEntry.units !== null ? newEntry.units : ''}
                                      type="number"
                                      step={1}
                                      placeholder="Uds."
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className={clsx({ error: hasErrorWeight })}
                                      onChange={(e: any) => {
                                        setHasErrorWeight(false);
                                        const parsedValue: number = parseFloat(e.target.value);
                                        if (!isNaN(parsedValue)) {
                                          setNewWeight(parsedValue);
                                        } else {
                                          setNewWeight(null);
                                        }
                                      }}
                                      onFocus={() => setHasErrorWeight(false)}
                                      value={newEntry.weight !== null ? newEntry.weight : ''}
                                      type="number"
                                      min={0}
                                      placeholder="Peso"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className={clsx({ error: hasErrorUnitCost })}
                                      onChange={(e: any) => {
                                        setHasErrorUnitCost(false);
                                        if (e.target.value) {
                                          setNewEntryUnitCost(parseFloat(e.target.value));
                                        } else {
                                          setNewEntryUnitCost(null);
                                        }
                                      }}
                                      onFocus={() => setHasErrorUnitCost(false)}
                                      value={newEntry.unitCost !== null ? newEntry.unitCost : ''}
                                      type="number"
                                      min={0}
                                      placeholder="Coste"
                                      style={{ width: 80 }}
                                    />
                                    <button title="Calcular coste a partir del precio/gr" className="copy-sku-button" onClick={() => setShowProductWeightModal(true)}>
                                      <Hash color="#808a95" size={16} />
                                    </button>
                                  </td>
                                  <td>
                                    <input
                                      className={clsx({ error: hasErrorDiscount })}
                                      onChange={(e: any) => {
                                        setHasErrorDiscount(false);
                                        if (e.target.value) {
                                          setNewEntryDiscount(parseFloat(e.target.value));
                                        } else {
                                          setNewEntryDiscount(null);
                                        }
                                      }}
                                      value={newEntry.discount !== null ? newEntry.discount : ''}
                                      type="number"
                                      min={0}
                                      placeholder="Desc"
                                    />
                                  </td>
                                  {organizationType === OrganizationType.B2B && (
                                    <td>
                                      <input
                                        className={clsx({ error: hasErrorRetailPrice })}
                                        onChange={(e: any) => {
                                          setHasErrorRetailPrice(false);
                                          if (e.target.value) {
                                            setNewEntryRetailPrice(parseFloat(e.target.value));
                                          } else {
                                            setNewEntryRetailPrice(null);
                                          }
                                        }}
                                        onFocus={() => setHasErrorRetailPrice(false)}
                                        value={newEntry.retailPrice !== null ? newEntry.retailPrice : ''}
                                        type="number"
                                        placeholder="PVM Ud. €"
                                      />
                                    </td>
                                  )}
                                  <td>
                                    <input
                                      className={clsx({ error: hasErrorPvp })}
                                      onChange={(e: any) => {
                                        setHasErrorPvp(false);
                                        if (e.target.value) {
                                          setNewEntryPvp(parseFloat(e.target.value));
                                        } else {
                                          setNewEntryPvp(null);
                                        }
                                      }}
                                      onFocus={() => setHasErrorPvp(false)}
                                      value={newEntry.pvp !== null ? newEntry.pvp : ''}
                                      type="number"
                                      placeholder="PVP"
                                    />
                                  </td>
                                  {user.role !== Role.Seller && (
                                    <td>
                                      <span>{formatNumber(newEntry.total)}€</span>
                                    </td>
                                  )}
                                  {user.role !== Role.Seller && (
                                    <td>
                                      <span>{`${formatNumber(newEntry.margin)}%`}</span>
                                    </td>
                                  )}
                                  <td>
                                    <Dropdown autoClose={false} show={showDropdownNewProduct}>
                                      <Dropdown.Toggle as={CustomToggleDropdown}>
                                        <span title="Mostrar más datos" className="clickable">
                                          <MoreHorizontal color="#808a95" size={16} onClick={() => setShowDropdownNewProduct(!showDropdownNewProduct)} />
                                        </span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu as={CustomMenuDropdown} show={showDropdownNewProduct}>
                                        <div className="d-flex flex-column px-3 py-3 gap-2 position-relative" style={{ width: 260 }}>
                                          <div className="cursor-pointer d-flex flex-row justify-content-end" title="Cerrar">
                                            <X color="#808a95" size={16} onClick={() => setShowDropdownNewProduct(false)} />
                                          </div>
                                          <input disabled={newEntry.product != null} onChange={(e: any) => setNewEntryEan(e.target.value)} value={newEntry.ean || ''} type="text" placeholder="EAN" />
                                          <select
                                            disabled={newEntry.product !== null || newEntry.brand === null || newEntry.brand.value === null || filteredCollections.length === 0}
                                            onChange={(e: any) => {
                                              setNewEntryCollection(parseInt(e.target.value, 10));
                                            }}
                                            value={newEntry.collectionId || -1}
                                          >
                                            <option value={-1}>
                                              {newEntry.brand === null || newEntry.brand.value === null
                                                ? 'Selecciona una marca'
                                                : filteredCollections.length === 0
                                                ? 'La marca no tiene colecciones'
                                                : 'Selecciona una colección'}
                                            </option>
                                            {filteredCollections.map((collection: Collection) => (
                                              <option key={collection.id} value={collection.id}>
                                                {collection.name}
                                              </option>
                                            ))}
                                          </select>
                                          <Select
                                            isMulti
                                            onChange={setNewEntryCategories}
                                            options={categoriesOptions}
                                            value={newEntry.categories}
                                            noOptionsMessage={() => 'No hay categorías'}
                                            menuPlacement="bottom"
                                            menuPosition="fixed"
                                            components={{ Menu }}
                                            placeholder="Selecciona categorías"
                                            menuPortalTarget={document.body}
                                            styles={styleMultiSelect}
                                            closeMenuOnSelect={false}
                                          />
                                          <Select
                                            isMulti
                                            onChange={setNewEntrySubCategories}
                                            options={filteredSubCategories}
                                            value={newEntry.subCategories}
                                            noOptionsMessage={() => 'No hay subcategorías'}
                                            menuPlacement="bottom"
                                            menuPosition="fixed"
                                            components={{ Menu }}
                                            isDisabled={newEntry.categories.length === 0 || filteredSubCategories.length === 0}
                                            placeholder={
                                              newEntry.categories.length === 0 ? 'Selecciona categorías' : filteredSubCategories.length === 0 ? 'No hay subcategorías' : 'Selecciona subcategorías'
                                            }
                                            menuPortalTarget={document.body}
                                            styles={styleMultiSelect}
                                          />
                                          <Select
                                            isDisabled={newEntry.product != null || families.length === 0}
                                            isMulti
                                            onChange={setNewEntryFamilies}
                                            options={families.map((family: Family) => ({ value: family.id, label: family.name }))}
                                            value={newEntry.families}
                                            noOptionsMessage={() => 'No hay familias'}
                                            menuPlacement="bottom"
                                            menuPosition="fixed"
                                            components={{ Menu }}
                                            placeholder={newEntry.product !== null && newEntry.product!.families.length === 0 ? 'Producto sin familias' : 'Selecciona familias'}
                                            menuPortalTarget={document.body}
                                            styles={styleMultiSelect}
                                          />
                                          <Select
                                            isDisabled={newEntry.product != null || filteredSubFamilies.length === 0}
                                            isMulti
                                            onChange={setNewEntrySubFamilies}
                                            options={filteredSubFamilies}
                                            value={newEntry.subFamilies}
                                            noOptionsMessage={() => 'No hay subfamilias'}
                                            menuPlacement="bottom"
                                            menuPosition="fixed"
                                            components={{ Menu }}
                                            placeholder={newEntry.product !== null && newEntry.product!.subFamilies.length === 0 ? 'Producto sin subfamilias' : 'Selecciona subfamilias'}
                                            menuPortalTarget={document.body}
                                            styles={styleMultiSelect}
                                          />
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {/* IMPRIMIR TABLA */}
                          {deliveryNote?.status === DeliveryNoteStatus.Received && (
                            <div ref={tableProducts} className="print-source p-4">
                              <table className="my-table" style={{ fontSize: 12 }}>
                                <thead>
                                  <tr>
                                    <th className="reference">SKU</th>
                                    <th className="name">Nombre</th>
                                    <th className="brand">Marca</th>
                                    <th className="total-units">Unidades</th>
                                    {organizationType === OrganizationType.B2B && <th className="pvp-unit">PVM Ud. €</th>}
                                    <th className="pvp-unit">PVP Ud. €</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {entries.map((entryDeliveryNote: EntryDeliveryNote, index: number) => {
                                    return (
                                      <tr key={`edn-${index}`} className={clsx(entryDeliveryNote.productId !== -1 ? 'existing-product' : 'new-product')}>
                                        <td>{entryDeliveryNote.sku}</td>
                                        <td>{entryDeliveryNote.name.length > 150 ? entryDeliveryNote.name.slice(0, 150) + '...' : entryDeliveryNote.name}</td>
                                        <td>{entryDeliveryNote.brand?.label || ''}</td>
                                        <td>{entryDeliveryNote.units}</td>
                                        {organizationType === OrganizationType.B2B && <td>{formatNumber(entryDeliveryNote.retailPrice)}€</td>}
                                        <td>{formatNumber(entryDeliveryNote.pvp)}€</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {(deliveryNote == null || (deliveryNote.status === DeliveryNoteStatus.Pending && !deliveryNote.originOrganizationId)) && (
                            <div className="d-flex justify-content-end my-2">
                              <button
                                onClick={addNewEntry}
                                disabled={generatingLabels || creating || updating || confirming}
                                className="add-register"
                                title="Añadir producto a la compra"
                                style={{ height: '35px' }}
                              >
                                Añadir producto
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {user.role !== Role.Seller && (
                    <div className="row mt-4">
                      <div className="col">
                        <div className="resume-totals">
                          <table className="">
                            <thead>
                              <tr>
                                <th>Uds. Tot.</th>
                                <th>Coste Total €</th>
                                <th>Desc. Total €</th>
                                <th>IVA ({formatNumber(parseFloat(process.env.REACT_APP_IVA))}%)</th>
                                <th>Rec. Equi. ({formatNumber(parseFloat(process.env.REACT_APP_EQUIVALENCE_SURCHARGE))}%)</th>
                                <th>Total €</th>
                                <th>Margen Total %</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div>{resumeDeliveryNote.totalUnits}</div>
                                </td>
                                <td>
                                  <div>{formatNumber(resumeDeliveryNote.totalCost)}€</div>
                                </td>
                                <td>
                                  <div>{formatNumber(resumeDeliveryNote.totalDiscount)}€</div>
                                </td>
                                <td>
                                  <div>{formatNumber(resumeDeliveryNote.totalIva)}€</div>
                                </td>
                                <td>
                                  <div>{formatNumber(resumeDeliveryNote.totalEquivalenceSurcharge)}€</div>
                                </td>
                                <td>
                                  <div className="selected">{formatNumber(resumeDeliveryNote.total)}€</div>
                                </td>
                                <td>
                                  <div className="selected">{formatNumber(resumeDeliveryNote.totalMargin)}%</div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              </Tab>
            )}
            {deliveryNote?.status === DeliveryNoteStatus.Pending && deliveryNote?.importErrors.length > 0 && (
              <Tab eventKey={DeliveryNoteTab.ImportErrors} title="Errores de importación">
                <div className="container-info">
                  <table className="my-table">
                    <thead>
                      <tr>
                        <th>Fila</th>
                        <th>Descripción del error</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deliveryNote?.importErrors.map((deliveryNoteImportError: DeliveryNoteImportError) => (
                        <tr key={deliveryNoteImportError.id}>
                          <td>{deliveryNoteImportError.row}</td>
                          <td>{deliveryNoteImportError.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Tab>
            )}
            {deliveryNote?.status === DeliveryNoteStatus.Received && storesForTransfers.length > 0 && (
              <Tab eventKey={DeliveryNoteTab.Transfers} title={transfersEnabled ? 'Traspasar productos' : 'Traspasos'}>
                <div className="container-info">
                  <div className="d-flex flex-column">
                    <table className="my-table">
                      <thead>
                        <tr style={{ cursor: 'default' }}>
                          <th className="image">Imagen</th>
                          <th className="reference">SKU</th>
                          <th className="asin">ASIN</th>
                          <th className="name">Nombre</th>
                          <th className="brand">Marca</th>
                          <th className="total-units">Uds. Tot.</th>
                          {storesForTransfers.map((store: Store) => {
                            const name: string = store.name.length > 12 ? store.name.substring(0, 12) + '...' : store.name;
                            return (
                              <th key={store.id} style={{ width: 70 }} title={store.name}>
                                {name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {deliveryNoteProductForTransfers.map((deliveryNoteProduct: DeliveryNoteProduct) => {
                          let urls: string[] = [];
                          if (deliveryNoteProduct.product.images && deliveryNoteProduct.product.images.length > 0) {
                            urls = deliveryNoteProduct.product.images.map((productImage: ProductImage) => process.env.REACT_APP_PUBLIC_URL + productImage.path);
                          }
                          let brandName: string | null = null;
                          if (deliveryNoteProduct.product.brandId) {
                            const brand: Brand | undefined = brands.find((b: Brand) => b.id === deliveryNoteProduct.product.brandId);
                            if (brand) {
                              brandName = brand.name;
                            }
                          }
                          return (
                            <tr key={deliveryNoteProduct.id}>
                              <td>
                                {urls.length > 0 && (
                                  <LightgalleryProvider>
                                    {urls.map((url: string, index: number) => {
                                      return (
                                        <LightgalleryItem key={index} group="group-product-images" src={url}>
                                          {index === 0 && <LazyLoadImage src={url} alt="" className="img-thumbnail clickable" />}
                                        </LightgalleryItem>
                                      );
                                    })}
                                  </LightgalleryProvider>
                                )}
                              </td>
                              <td>{deliveryNoteProduct.product.sku}</td>
                              <td>{deliveryNoteProduct.product.amazonAsin}</td>
                              <td>{deliveryNoteProduct.product.name}</td>
                              <td>{brandName}</td>
                              <td style={{ textAlign: 'center' }}>{deliveryNoteProduct.units}</td>
                              {storesForTransfers.map((store: Store) => {
                                let value: number | string = '';
                                if (transferUnits[deliveryNoteProduct.id] && transferUnits[deliveryNoteProduct.id].hasOwnProperty(store.id)) {
                                  value = transferUnits[deliveryNoteProduct.id][store.id] || '';
                                }
                                let deliveryNoteProductTransferStore: DeliveryNoteProductTransferStore | undefined = undefined;
                                for (const e of deliveryNoteProduct.deliveryNoteProductTransfers) {
                                  deliveryNoteProductTransferStore = e.deliveryNoteProductTransferStores.find((d: DeliveryNoteProductTransferStore) => d.storeId === store.id);
                                  if (deliveryNoteProductTransferStore) {
                                    break;
                                  }
                                }
                                return (
                                  <td key={store.id} style={{ width: 70, textAlign: 'center' }} title={store.name}>
                                    {transfersEnabled ? (
                                      <input
                                        type="number"
                                        value={value}
                                        min={0}
                                        style={{
                                          width: 60,
                                          border: errorProductTransfer[deliveryNoteProduct.id] ? '1px solid red' : '',
                                        }}
                                        onChange={(e) => {
                                          let units: number | null = null;
                                          if (e.target.value && !isNaN(e.target.value as any)) {
                                            units = parseInt(e.target.value, 10);
                                          }
                                          onChangeTranferUnits(deliveryNoteProduct.id, store.id, units);
                                          const copyErrorProductTransfer = { ...errorProductTransfer };
                                          copyErrorProductTransfer[deliveryNoteProduct.id] = false;
                                          setErrorProductTransfer(copyErrorProductTransfer);
                                        }}
                                      />
                                    ) : (
                                      deliveryNoteProductTransferStore && <Link to={`/traspaso/${deliveryNoteProductTransferStore.transferId}`}>{deliveryNoteProductTransferStore.units}</Link>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {transfersEnabled && (
                      <div className="d-flex flex-row-reverse mt-4 gap-4">
                        <button disabled={creatingTransfers} className="confirm-delivery-note" onClick={createTransfers}>
                          Confirmar
                        </button>
                        <button disabled={creatingTransfers} className="save-delivery-note" onClick={updateDraftTransfers}>
                          Guardar cambios
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
      {(deliveryNote || selectedStoreId >= 1) && selectedSupplier && selectedSupplier.value >= 1 && (
        <div className="delivery-note-actions d-flex justify-content-end flex-grow-1 mb-3">
          {deliveryNote?.status === DeliveryNoteStatus.Pending && (
            <button disabled={generatingLabels || creating || updating || confirming} onClick={() => setShowDeleteDeliveryNoteModal(true)} className="delete-delivery-note">
              Eliminar
            </button>
          )}
          {deliveryNote?.status === DeliveryNoteStatus.Pending && (
            <button disabled={generatingLabels || creating || updating || confirming} onClick={() => setShowConfirmDeliveryNoteModal(true)} className="confirm-delivery-note mx-4">
              Confirmar recepción
            </button>
          )}
          {(!deliveryNote || deliveryNote.status !== DeliveryNoteStatus.Cancelled) && selectedTab !== DeliveryNoteTab.Transfers && (
            <button disabled={generatingLabels || creating || updating || confirming} onClick={saveDeliveryNote} className="save-delivery-note">
              Guardar
            </button>
          )}
        </div>
      )}
      {generatingLabels && (
        <div style={{ marginTop: '1000px' }}>
          {deliveryNote?.deliveryNoteProducts.map((deliveryNoteProduct: DeliveryNoteProduct) => (
            <div key={deliveryNoteProduct.id} className="print-label" style={{ display: 'hidden', width: '200px', textAlign: 'center' }}>
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >
                {deliveryNoteProduct.product.sku}
              </div>
              {deliveryNoteProduct.product.ean && <Barcode value={deliveryNoteProduct.product.ean} options={{ displayValue: false, height: 30, width: 2 }} />}
            </div>
          ))}
        </div>
      )}
      {showProductFinderModal && <ProductsFinderModal show={showProductFinderModal} onClose={onCloseProductsFinderModal} />}
      <ConfirmModal
        acceptButtonClass="accept-button"
        show={showConfirmDeliveryNoteModal}
        title="Confirmar recepción de compra"
        content={`<p>Vas a confirmar que los productos de tu pedido han sido recibidos en ${
          organizationType === OrganizationType.B2C ? 'la tienda' : 'el almacén'
        }. Después ya no podrás hacer cambios.</p>
        <p>Es importante verificar que la cantidad de productos recibidos coincide con los valores registrados en el pedido. Esto ayuda a mantener un control preciso de tu inventario.</p>        
        <p>Verifica que has recibido todas las unidades que estaban en el pedido</p>
        <p>Si la cantidad recibida coincide con la cantidad pedida, puedes confirmar la recepción haciendo clic en 'Confirmar'.</p>        
        <p>Si la cantidad recibida no coincide con la cantidad pedida contacta al proveedor para informarles sobre la discrepancia y/o modifica tu compra en Sierra para que coincida.</p>        
        <p>Es fundamental mantener tus registros precisos para una gestión eficiente del stock.</p>`}
        closeModal={(result: boolean) => {
          setShowConfirmDeliveryNoteModal(false);
          if (result) {
            confirmDeliveryNote();
          }
        }}
      ></ConfirmModal>
      {deliveryNote && (
        <ConfirmModal
          acceptButtonClass="delete-button"
          show={showDeleteDeliveryNoteModal}
          title="Eliminar compra"
          content={`¿Estás seguro que quieres eliminar la compra?`}
          closeModal={(result: boolean) => {
            if (result) {
              deleteDeliveryNote();
            }
            setShowDeleteDeliveryNoteModal(false);
          }}
        ></ConfirmModal>
      )}
      {deliveryNote && deliveryNote.status === DeliveryNoteStatus.Received && (
        <ExpirationModal show={showExpirationModal} closeModal={onExpirationModalClose} expiration={deliveryNote?.expiration || null} deliveryNote={deliveryNote} />
      )}
      <SupplierModal show={showSupplierModal} closeModal={onCloseCustomerModal} supplier={null} />
      {selectedProduct && <UploadProductImageModal returnRawData={true} product={selectedProduct} show={showUploadProductModal} closeModal={onCloseUploadProductImageModal} />}
      <ProductWeightModal closeModal={onCloseProductWeightModal} show={showProductWeightModal} weight={newEntry.weight} />
      {deliveryNote !== null && <UploadFileDeliveryNoteModal closeModal={onCloseUploadFileDeliveryNoteModal} show={showUploadFileDeliveryNoteModal} deliveryNote={deliveryNote} />}
      <PinModal show={showPinModalConfirmDeliveryNote} onCloseModal={onClosePinModal} />
    </React.Fragment>
  );
};

export default DeliveryNoteView;
