import { useMemo } from 'react';
import Modal from 'react-modal';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import './assets/styles/_responsive.scss';
import './assets/styles/_style.scss';
import { PrivateRoutes } from './components/private-routes';
import { OrganizationType } from './enums/organization-type';
import { Role } from './enums/role';
import { User } from './interfaces/user';
import { getDefaultPathGivenRole } from './services/helpers';
import { useAppSelector } from './store/hooks';
import { RootState } from './store/store';
import BrandsView from './views/brands-view';
import CashMovementsView from './views/cash-movements-view';
import CashRegistersView from './views/cash-registers-view';
import CategoriesView from './views/categories-view';
import CollectionsView from './views/collections-view';
import CustomerAdvertisingView from './views/customer-advertising-view';
import CustomerPrivacyView from './views/customer-privacy-view';
import Customer from './views/customer-view';
import CustomersView from './views/customers-view';
import DashboardView from './views/dashboard-view';
import DeliveryNoteView from './views/delivery-note-view';
import DeliveryNoteWholesalerView from './views/delivery-note-wholesaler-view';
import DeliveryNotesView from './views/delivery-notes-view';
import DeliveryNotesWholesalerView from './views/delivery-notes-wholesaler-view';
import DetailCashRegisterView from './views/detail-cash-register-view';
import DetailTransferView from './views/detail-transfer-view';
import ExpirationsView from './views/expirations-view';
import FamiliesView from './views/families-view';
import HomeView from './views/home-view';
import InventoriesView from './views/inventories-view';
import InventoryView from './views/inventory-view';
import InvoiceView from './views/invoice-view';
import InvoicesView from './views/invoices-view';
import LoginView from './views/login-view';
import NewPurchaseOrderView from './views/new-purchase-order-view';
import NewRepairView from './views/new-repair-view';
import NotificationsView from './views/notifications-view';
import OnlineStoreSynchronizationView from './views/online-store-synchronization-view';
import OnlineStoreSynchronizations from './views/online-store-synchronizations-view';
import OnlineStoresView from './views/online-stores-view';
import OrganizationClientsView from './views/organization-clients-view';
import OrganizationsView from './views/organizations-view';
import PayrollsView from './views/payrolls-view';
import PendingTransferView from './views/pending-transfer-view';
import PosView from './views/pos-view';
import Product from './views/product-view';
import ProductsView from './views/products-view';
import PurchaseOrderView from './views/purchase-order-view';
import PurchaseOrdersView from './views/purchase-orders-view';
import RecoverPasswordView from './views/recover-password-view';
import RepairsView from './views/repairs-view';
import ResetPasswordView from './views/reset-password-view';
import Sale from './views/sale-view';
import SalesView from './views/sales-view';
import StockControlView from './views/stock-control-view';
import StockView from './views/stock-view';
import StoresView from './views/stores-view';
import SubFamiliesView from './views/subfamilies-view';
import SuppliersView from './views/suppliers-view';
import TechnicalServicesView from './views/technical-services-view';
import ThresholdsView from './views/thresholds-view';
import TimeControlView from './views/time-control-view';
import TransfersView from './views/transfers-view';
import UnsubscribeAdvertisingView from './views/unsubscribe-advertising-view';
import UpdateRepairView from './views/update-repair-view';
import UserView from './views/user-view';
import UserWorkClockingsView from './views/user-work-clockings-view';
import UsersViews from './views/users-views';
import VerifyTransferView from './views/verify-transfer-view';
import WithdrawalsViews from './views/withdrawals-views';
import WorkShiftsView from './views/work-shifts-view';

Modal.setAppElement('#root');

const App = () => {
  const user: User | null = useAppSelector((state: RootState) => state.auth.user);
  const organizationType: OrganizationType | null = useAppSelector((state: RootState) => state.auth.organizationType);
  const defaultPath: string = useMemo(() => {
    if (!user) {
      return '/login';
    }
    return getDefaultPathGivenRole(user.role);
  }, [user]);

  return (
    <Router>
      <Routes>
        <Route path="/cancelar-subscripcion" element={<UnsubscribeAdvertisingView />} />
        <Route path="/login" element={<LoginView />} />
        <Route path="/recover-password" element={<RecoverPasswordView />} />
        <Route path="/reset-password/:token" element={<ResetPasswordView />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<HomeView />}>
            <Route
              path="/"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || Role.WholesaleCustomer ? <DashboardView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/albaranes"
              element={organizationType === OrganizationType.B2B && (user?.role === Role.SuperAdmin || user?.role === Role.Business) ? <DeliveryNotesWholesalerView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/albaran/:id"
              element={organizationType === OrganizationType.B2B && (user?.role === Role.SuperAdmin || user?.role === Role.Business) ? <DeliveryNoteWholesalerView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/almacenes"
              element={user?.role === Role.SuperAdmin ? <StoresView /> : user?.role === Role.Admin || user?.role === Role.Manager ? <Navigate to="/tiendas" /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/arqueos"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <CashRegistersView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/arqueo/:id"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <DetailCashRegisterView /> : <Navigate to={defaultPath} />
              }
            />
            <Route path="/categorias" element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <CategoriesView /> : <Navigate to={defaultPath} />} />
            <Route
              path="/clientes"
              element={organizationType === OrganizationType.B2B ? <OrganizationClientsView /> : user?.role !== Role.WholesaleCustomer ? <CustomersView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/cliente/:id"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <Customer /> : <Navigate to={defaultPath} />}
            />
            <Route path="/colecciones" element={user?.role === Role.SuperAdmin ? <CollectionsView /> : <Navigate to={defaultPath} />} />
            <Route path="/compra" element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <DeliveryNoteView /> : <Navigate to={defaultPath} />} />
            <Route
              path="/compra/:id"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.WholesaleCustomer ? (
                  <DeliveryNoteView />
                ) : (
                  <Navigate to={defaultPath} />
                )
              }
            />
            <Route
              path="/compras"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.WholesaleCustomer ? (
                  <DeliveryNotesView />
                ) : (
                  <Navigate to={defaultPath} />
                )
              }
            />
            <Route path="/control-horario" element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <TimeControlView /> : <Navigate to={defaultPath} />} />
            <Route
              path="/control-stock"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <StockControlView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/facturas"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.WholesaleCustomer ? <InvoicesView /> : <Navigate to={defaultPath} />
              }
            />
            <Route
              path="/factura"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.WholesaleCustomer ? <InvoiceView /> : <Navigate to={defaultPath} />
              }
            />
            <Route
              path="/factura/:id"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.WholesaleCustomer ? <InvoiceView /> : <Navigate to={defaultPath} />
              }
            />
            <Route path="/familias" element={user?.role === Role.SuperAdmin ? <FamiliesView /> : <Navigate to={defaultPath} />} />
            <Route path="/fichaje" element={<UserWorkClockingsView />} />
            <Route path="/fichaje/:userId" element={<UserWorkClockingsView />} />
            <Route path="/gastos" element={organizationType === OrganizationType.B2C && user?.role !== Role.WholesaleCustomer ? <WithdrawalsViews /> : <Navigate to={defaultPath} />} />
            <Route
              path="/inventarios"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <InventoriesView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/inventario/:id"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <InventoryView /> : <Navigate to={defaultPath} />}
            />
            <Route path="/marcas" element={user?.role === Role.SuperAdmin ? <BrandsView /> : <Navigate to={defaultPath} />} />
            <Route path="/mayoristas" element={organizationType === OrganizationType.B2C ? <OrganizationClientsView /> : <Navigate to="/clientes" />} />
            <Route
              path="/movimientos"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <CashMovementsView /> : <Navigate to={defaultPath} />}
            />
            <Route path="/nominas" element={user?.role !== Role.WholesaleCustomer ? <PayrollsView /> : <Navigate to={defaultPath} />} />
            <Route
              path="/notificaciones"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <NotificationsView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/ordenes-compra"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.WholesaleCustomer ? (
                  <PurchaseOrdersView />
                ) : (
                  <Navigate to={defaultPath} />
                )
              }
            />
            <Route
              path="/orden-compra"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.WholesaleCustomer ? (
                  <NewPurchaseOrderView />
                ) : (
                  <Navigate to={defaultPath} />
                )
              }
            />
            <Route
              path="/orden-compra/:id"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.WholesaleCustomer ? (
                  <PurchaseOrderView />
                ) : (
                  <Navigate to={defaultPath} />
                )
              }
            />
            <Route
              path="/orden-compra/:id/actualizar"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.WholesaleCustomer ? (
                  <NewPurchaseOrderView />
                ) : (
                  <Navigate to={defaultPath} />
                )
              }
            />
            <Route
              path="/organizaciones"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <OrganizationsView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/pedidos"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Business ? <PurchaseOrdersView /> : <Navigate to={defaultPath} />
              }
            />
            <Route
              path="/pedido"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Business ? <NewPurchaseOrderView /> : <Navigate to={defaultPath} />
              }
            />
            <Route
              path="/pedido/:id"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Business ? <PurchaseOrderView /> : <Navigate to={defaultPath} />
              }
            />
            <Route
              path="/pedido/:id/actualizar"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Business ? <NewPurchaseOrderView /> : <Navigate to={defaultPath} />
              }
            />
            <Route
              path="/pos"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <PosView /> : <Navigate to={defaultPath} />}
            />
            <Route path="/productos" element={user?.role === Role.SuperAdmin ? <ProductsView /> : <Navigate to={defaultPath} />} />
            <Route path="/producto/:id" element={user?.role !== Role.WholesaleCustomer ? <Product /> : <Navigate to={defaultPath} />} />
            <Route path="/proveedores" element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <SuppliersView /> : <Navigate to={defaultPath} />} />
            <Route
              path="/reparaciones"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <RepairsView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/reparacion"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <NewRepairView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/reparacion/:id"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <UpdateRepairView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/servicios-tecnicos"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <TechnicalServicesView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/sincronizaciones"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <OnlineStoreSynchronizations /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/sincronizacion/:id"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <OnlineStoreSynchronizationView /> : <Navigate to={defaultPath} />}
            />
            <Route path="/subfamilias" element={user?.role === Role.SuperAdmin ? <SubFamiliesView /> : <Navigate to={defaultPath} />} />
            <Route path="/stock" element={user?.role !== Role.WholesaleCustomer ? <StockView /> : <Navigate to={defaultPath} />} />
            <Route path="/tiendas" element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <StoresView /> : <Navigate to={defaultPath} />} />
            <Route path="/tiendas-online" element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <OnlineStoresView /> : <Navigate to={defaultPath} />} />
            <Route
              path="/traspasos"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <TransfersView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/traspaso/:id/borrador"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <PendingTransferView /> : <Navigate to={defaultPath} />
              }
            />
            <Route
              path="/traspaso/:id/pendiente"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <PendingTransferView /> : <Navigate to={defaultPath} />
              }
            />
            <Route
              path="/traspaso/:id/verificar"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <VerifyTransferView /> : <Navigate to={defaultPath} />
              }
            />
            <Route
              path="/traspaso/:id"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <DetailTransferView /> : <Navigate to={defaultPath} />
              }
            />
            <Route
              path="/traspaso"
              element={
                user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <PendingTransferView /> : <Navigate to={defaultPath} />
              }
            />
            <Route path="/turnos" element={user?.role !== Role.WholesaleCustomer ? <WorkShiftsView /> : <Navigate to={defaultPath} />} />
            <Route path="/umbrales" element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <ThresholdsView /> : <Navigate to={defaultPath} />} />
            <Route path="/usuarios" element={user?.role === Role.WholesaleCustomer ? <Navigate to={defaultPath} /> : <UsersViews />} />
            <Route path="/usuario/:id" element={user?.role === Role.WholesaleCustomer ? <Navigate to={defaultPath} /> : <UserView />} />
            <Route path="/vencimientos" element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager ? <ExpirationsView /> : <Navigate to={defaultPath} />} />
            <Route
              path="/ventas"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <SalesView /> : <Navigate to={defaultPath} />}
            />
            <Route
              path="/venta/:id"
              element={user?.role === Role.SuperAdmin || user?.role === Role.Admin || user?.role === Role.Manager || user?.role === Role.Seller ? <Sale /> : <Navigate to={defaultPath} />}
            />
            <Route path="*" element={<Navigate to={defaultPath} />} />
          </Route>
        </Route>
        <Route path="/privacidad" element={<CustomerPrivacyView />} />
        <Route path="/publicidad" element={<CustomerAdvertisingView />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
