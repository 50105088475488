import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import StoreModal from '../components/store-modal';
import Table from '../components/table';
import { OrganizationType } from '../enums/organization-type';
import { PosStatus } from '../enums/pos-status';
import { Role } from '../enums/role';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

function StatusPosColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return Array.from(options.values());
  }, [id, preFilteredRows]) as any[];

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {options.map((option: any, i) => {
        let textOption = '';
        switch (option) {
          case PosStatus.Opened:
            textOption = 'Abierto';
            break;
          case PosStatus.Closed:
            textOption = 'Cerrado';
            break;
        }
        return (
          <option key={i} value={option}>
            {textOption}
          </option>
        );
      })}
    </select>
  );
}

const StoresView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const [stores, setStores] = useState<Store[]>([]);
  const [showStoreModal, setShowStoreModal] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const showCreateStoreButton: boolean = useMemo(() => user.role === Role.SuperAdmin, [user]);

  useEffect(() => {
    getStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStores = async () => {
    setRequesting(true);
    const stores: Store[] = await api.getStores(organization.id);
    setStores(stores);
    setRequesting(false);
  };

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Nombre',
        accessor: 'name',
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const store: Store = row.original as Store;
          return (
            <strong
              onClick={() => {
                setSelectedStore(store);
                setShowStoreModal(true);
              }}
              className="clickable"
            >
              {store.name}
            </strong>
          );
        },
      },
      {
        Header: 'Tipo',
        accessor: (store: Store) => store.type.toUpperCase(),
      },
      {
        Header: 'Usuarios',
        filter: 'fuzzyText',
        accessor: (store: Store) => store.users.map((u: User) => `${u.name} ${u.surnames}`).join(', '),
        Cell: (cell: any) => {
          const store: Store = cell.row.original;
          return store.users.map((u: User) => `${u.name} ${u.surnames}`).join(', ');
        },
      },
      { Header: 'Dirección', accessor: 'address', filter: 'fuzzyText' },
      { Header: 'Teléfono', accessor: 'phone', filter: 'fuzzyText' },
      {
        Header: 'Razón Social',
        accessor: 'businessName',
        filter: 'fuzzyText',
      },
      {
        Header: 'CIF',
        accessor: 'cif',
        filter: 'fuzzyText',
      },
      {
        Header: 'Dinero en caja',
        accessor: 'posMoney',
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const store: Store = row.original as Store;
          return `${formatNumber(store.posMoney)}€`;
        },
      },
      {
        Header: 'Estado de la caja',
        accessor: 'posStatus',
        Cell: (row: any) => {
          let className = '';
          let textOption = '';
          switch (row.value) {
            case PosStatus.Opened:
              textOption = 'Abierto';
              className = 'my-warning';
              break;
            case PosStatus.Closed:
              textOption = 'Cerrado';
              className = 'my-success';
              break;
          }
          return <span className={`badge ${className}`}>{textOption}</span>;
        },
        Filter: StatusPosColumnFilter,
      },
      {
        Header: 'Vendedores',
        Cell: (cell: any) => cell.row.original.users.filter((u: User) => u.role === Role.Seller).length,
      },
    ];
    if (user.role === Role.SuperAdmin) {
      columnsData.unshift({
        Header: 'Organización',
        accessor: 'organization.name',
      });
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <Trash2
              type="button"
              className="mx-2"
              onClick={() => {
                setSelectedStore(row.original);
                setShowConfirmModal(true);
              }}
              size={14}
            />
          );
        },
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]) as any;

  const newStore = () => {
    setSelectedStore(null);
    setShowStoreModal(true);
  };

  const onCloseStoreModal = () => {
    setShowStoreModal(false);
    setSelectedStore(null);
  };

  const deleteStore = async (store: Store) => {
    try {
      await api.deleteStore(store.id);
      const strs: Store[] = [...stores];
      const index: number = strs.findIndex((str: Store) => str.id === store.id);
      strs.splice(index, 1);
      setStores(strs);
      myToastr.success(organizationType === OrganizationType.B2C ? 'Tienda eliminada correctamente' : 'Almacén eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const s: Store = fr.original;
      data.push({
        Nombre: s.name,
        Tipo: s.type.toUpperCase(),
        Usuarios: s.users.map((u: User) => `${u.name} ${u.surnames}`.trim()).join(', '),
        Dirección: s.address,
        Teléfono: s.phone,
        'Razón Social': s.businessName,
        CIF: s.cif,
        'Dinero en caja': s.posMoney,
        'Estado de la caja': s.posStatus,
        Vendedores: s.users.filter((u: User) => u.role === Role.Seller).length,
        'Métodos de pago': s.paymentMethods.length,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="stores p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">{organizationType === OrganizationType.B2C ? 'Tiendas' : 'Almacenes'}</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename={organizationType === OrganizationType.B2C ? 'tiendas.csv' : 'almacenes.csv'} className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {showCreateStoreButton && (
          <button className="d-flex align-items-center create-button" onClick={newStore}>
            <Plus className="me-1" size={14} /> {organizationType === OrganizationType.B2C ? 'Nueva tienda' : 'Nuevo almacén'}
          </button>
        )}
      </div>
      <Table
        data={stores}
        columns={columns}
        noDataMessage={organizationType === OrganizationType.B2C ? 'No hay tiendas' : 'No hay almacenes'}
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <StoreModal show={showStoreModal} closeModal={onCloseStoreModal} storeId={selectedStore?.id} />
      {selectedStore && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title={organizationType === OrganizationType.B2C ? 'Eliminar Tienda' : 'Eliminar Almacén'}
          content={
            organizationType === OrganizationType.B2C ? `¿Estás seguro que quieres eliminar la tienda ${selectedStore.name}?` : `¿Estás seguro que quieres eliminar el almacén ${selectedStore.name}?`
          }
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteStore(selectedStore);
            }
            setSelectedStore(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default StoresView;
