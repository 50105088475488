import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { ImportProductsCsvDto } from '../interfaces/import-products-csv.dto';
import { Organization } from '../interfaces/organization';
import { Product } from '../interfaces/product';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  show: boolean;
  closeModal: (products: Product[]) => void;
}

interface UpdateProductsByCsv {
  file: FileList | null;
}

const initialValues: UpdateProductsByCsv = {
  file: null,
};

const UpdateProductsModal = (props: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateProductsByCsv>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!props.show) {
      reset(initialValues);
    }
  }, [props.show, reset]);

  const close = () => {
    reset();
    props.closeModal([]);
  };

  const onSubmit = async (updateProductsByCsv: UpdateProductsByCsv) => {
    myToastr.info('Actualizando productos por csv. Espere por favor...');
    try {
      const importProductsCsvDto: ImportProductsCsvDto = {
        organizationId: organization.id,
        file: updateProductsByCsv.file![0]!,
      };
      const products: Product[] = await api.updateProductsByCsv(importProductsCsvDto);
      myToastr.remove();
      myToastr.success('Productos actualizados correctamente');
      props.closeModal(products);
    } catch (e: any) {
      myToastr.remove();
      myToastr.error(e.response.data.message);
    }
  };

  return (
    <Modal className="vercomi-modal my-form update-products-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Actualizar productos</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.file })}>Fichero CSV *</div>
              <input type="file" {...register('file', { required: true })} className={clsx({ error: errors?.file })} accept=".csv" />
              {errors.file && <div className="error-message">{errors.file.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name')}>Columnas CSV (se deben indicar todas las columnas y deben aparecer con el mismo nombre y órden):</div>
              <ul>
                <li>sku (obligatorio - texto)</li>
                <li>nombre (texto)</li>
                <li>ean (texto)</li>
                <li>estado (0,1)</li>
                <li>editable (0,1)</li>
                <li>descripcion (texto)</li>
                <li>marca (texto)</li>
                <li>coleccion (texto)</li>
                <li>
                  categorias (texto)
                  <span>
                    Ejemplo: cat1{'>'}subCat1.1|cat1{'>'}subCat.1.2
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default UpdateProductsModal;
