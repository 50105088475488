import { useState } from 'react';
import { Mail, Phone, Smartphone } from 'react-feather';
import { useSelector } from 'react-redux';
import { repairSelector } from '../store/repair-slice';

const RepairNotifyCustomer = () => {
  const { customer } = useSelector(repairSelector);
  const [onHoverPhone, setOnHoverPhone] = useState<boolean>(false);
  const [onHoverSms, setOnHoverSms] = useState<boolean>(false);
  const [onHoverEmail, setOnHoverEmail] = useState<boolean>(false);

  if (!customer) {
    return null;
  }

  let hasPhone: boolean = false;
  if (customer?.phone && customer.phone.length > 0) {
    hasPhone = true;
  }

  let hasEmail: boolean = false;
  if (customer?.email && customer.email.length > 0) {
    hasEmail = true;
  }

  if (!hasPhone && !hasEmail) {
    return null;
  }

  return (
    <div className="repair-notify d-flex flex-column mt-3">
      <div className="title mb-3">Aviso al cliente</div>
      <div className="row justify-content-around">
        {hasPhone && (
          <div className="col-4">
            <div className="container-contact" onMouseEnter={() => setOnHoverPhone(true)} onMouseLeave={() => setOnHoverPhone(false)}>
              <div className="d-flex align-self-center"></div>
              <div className="d-flex flex-column align-items-center">
                <Phone color={onHoverPhone ? 'white' : '#555555'} size={25} />
                <a href={`tel:${customer.phone}`} className="mt-1">
                  Teléfono
                </a>
              </div>
            </div>
          </div>
        )}
        {hasPhone && (
          <div className="col-4">
            <div className="container-contact" onMouseEnter={() => setOnHoverSms(true)} onMouseLeave={() => setOnHoverSms(false)}>
              <div className="d-flex align-self-center"></div>
              <div className="d-flex flex-column align-items-center">
                <Smartphone color={onHoverSms ? 'white' : '#555555'} size={25} />
                <a href={`sms:${customer.phone}`} className="mt-1">
                  SMS
                </a>
              </div>
            </div>
          </div>
        )}
        {hasEmail && (
          <div className="col-4">
            <div className="container-contact" onMouseEnter={() => setOnHoverEmail(true)} onMouseLeave={() => setOnHoverEmail(false)}>
              <div className="d-flex align-self-center"></div>
              <div className="d-flex flex-column align-items-center">
                <Mail color={onHoverEmail ? 'white' : '#555555'} size={25} />
                <a href={`mailto:${customer.email}`} className="mt-1">
                  Email
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RepairNotifyCustomer;
