import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { Brand } from '../interfaces/brand';
import { BrandDto } from '../interfaces/brand.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';

interface Props {
  brand: Brand | null;
  show: boolean;
  closeModal: (brand: Brand | null) => void;
}

const BrandModal = (props: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<BrandDto>({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (props.show && props.brand) {
      reset(props.brand);
    }
  }, [props.show, props.brand, reset]);

  const close = () => {
    reset();
    props.closeModal(null);
  };

  const onSubmit = async (data: BrandDto) => {
    try {
      if (props.brand) {
        const brand: Brand = await api.updateBrand(props.brand.id, data);
        props.closeModal(brand);
        myToastr.success('Marca actualizada correctamente');
      } else {
        const brand: Brand = await api.createBrand(data);
        props.closeModal(brand);
        myToastr.success('Marca creada correctamente');
      }
      reset();
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  return (
    <Modal className="vercomi-modal my-form brand-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Marca</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.name })}>Nombre</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default BrandModal;
