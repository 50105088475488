import axios, { AxiosError } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, X } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Brand } from '../interfaces/brand';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { StockByBrand, StockStats } from '../interfaces/stock-stats';
import { StockStatsByProduct } from '../interfaces/stock-stats-by-product';
import { api } from '../services/api';
import { formatNumber, slugify } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';
import Table from './table';

interface Props {
  organizationId: number;
  storeId: number;
}

const DashboardStockStats = ({ organizationId, storeId }: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { store } = useSelector(storeSelector);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [shoppingStats, setShoppingStats] = useState<StockStats | null>(null);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [csvDataProducts, setCsvDataProducts] = useState<any[]>([]);
  const [requestingProducts, setRequestingProducts] = useState<boolean>(false);
  const refContainerSalesByProduct = useRef<any>(null);
  const [stockStatsByProduct, setStockStatsByProduct] = useState<StockStatsByProduct[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [brand, setBrand] = useState<Brand | null>(null);
  const columnsShoppingByBrand: any[] = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Marca',
        accessor: 'brandName',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const sbb: StockByBrand = row.original;
          return (
            <span
              className="link-sale clickable"
              title="Obtener el listado de productos"
              onClick={() => {
                const b: Brand = brands.find((b: Brand) => b.id === sbb.brandId)!;
                getStockStatsByProduct(b.id);
                setBrand(b);
              }}
            >
              {sbb.brandName}
            </span>
          );
        },
      },
      {
        Header: 'Unidades',
        accessor: 'units',
        Cell: ({ value }: any) => {
          return formatNumber(value, true);
        },
        disableFilters: true,
      },
      {
        Header: 'Precio Coste',
        accessor: 'costPrice',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
      {
        Header: 'PVP',
        accessor: 'pvp',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
      {
        Header: 'Beneficio',
        accessor: 'netProfit',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
      {
        Header: 'Margen',
        accessor: 'margin',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}%`;
        },
        disableFilters: true,
      },
      {
        Header: 'PSP',
        accessor: 'psp',
        Cell: ({ value }: any) => {
          return formatNumber(value);
        },
        disableFilters: true,
      },
      {
        Header: 'Rotación',
        accessor: 'rotation',
        Cell: ({ value }: any) => {
          return formatNumber(value);
        },
        disableFilters: true,
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands]) as any;
  const columnsStockByProduct: any[] = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Producto',
        accessor: 'productName',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const ssbp: StockStatsByProduct = row.original;
          return (
            <Link to={`/producto/${ssbp.productId}`} className="link-sale">
              {ssbp.productName}
            </Link>
          );
        },
      },
      {
        Header: 'SKU',
        accessor: 'productSku',
        disableFilters: true,
      },
      {
        Header: 'Unidades',
        accessor: 'units',
        Cell: ({ value }: any) => {
          return formatNumber(value, true);
        },
        disableFilters: true,
      },
      {
        Header: 'Precio Coste',
        accessor: 'costPrice',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
      {
        Header: 'PVP',
        accessor: 'pvp',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
      {
        Header: 'Beneficio',
        accessor: 'netProfit',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}€`;
        },
        disableFilters: true,
      },
      {
        Header: 'Margen',
        accessor: 'margin',
        Cell: ({ value }: any) => {
          return `${formatNumber(value)}%`;
        },
        disableFilters: true,
      },
      {
        Header: 'PSP',
        accessor: 'psp',
        Cell: ({ value }: any) => {
          return formatNumber(value);
        },
        disableFilters: true,
      },
      {
        Header: 'Rotación',
        accessor: 'rotation',
        Cell: ({ value }: any) => {
          return formatNumber(value);
        },
        disableFilters: true,
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSaleStats();
    if (brand !== null) {
      getStockStatsByProduct(brand.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, storeId, brand]);

  const getBrands = async () => {
    try {
      const bs: Brand[] = await api.getBrands(organization.id);
      setBrands(bs);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getSaleStats = async () => {
    try {
      setRequesting(true);
      const ss: StockStats = await api.getStockStats(organizationId, storeId, brand?.id || -1);
      setShoppingStats(ss);
      setRequesting(false);
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const getStockStatsByProduct = async (brandId: number) => {
    if (requestingProducts) {
      return;
    }
    try {
      setRequestingProducts(true);
      const ss: StockStatsByProduct[] = await api.getStockStatsGivenBrand(organizationId, storeId, brandId);
      setStockStatsByProduct(ss);
      setRequestingProducts(false);
      refContainerSalesByProduct?.current.scrollIntoView({ behavior: 'smooth' });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    shoppingStats!.stockByBrand.forEach((stockByBrand: StockByBrand) => {
      data.push({
        Marca: stockByBrand.brandName,
        Unidades: stockByBrand.units,
        'Precio Coste': stockByBrand.costPrice,
        PVP: stockByBrand.pvp,
        Beneficio: stockByBrand.netProfit,
        Margen: stockByBrand.margin,
        PSP: stockByBrand.psp,
        Rotación: stockByBrand.rotation,
      });
    });
    setCsvData(data);
  };

  const generateCsvProducts = () => {
    const data: any[] = [];
    stockStatsByProduct.forEach((stockByProduct: StockStatsByProduct) => {
      data.push({
        Producto: stockByProduct.productName,
        SKU: stockByProduct.productSku,
        Unidades: stockByProduct.units,
        'Precio Coste': stockByProduct.costPrice,
        PVP: stockByProduct.pvp,
        Beneficio: stockByProduct.netProfit,
        Margen: stockByProduct.margin,
        PSP: stockByProduct.psp,
        Rotación: stockByProduct.rotation,
      });
    });
    setCsvDataProducts(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  if (!shoppingStats) {
    return null;
  }

  return (
    <div className="dashboard-stock-stats">
      <div className="row">
        <div className="col">
          <div className="billing-summary mb-3">
            <h4>Valoración</h4>
            <div>
              <span className="amount">{formatNumber(shoppingStats.costPrice)}€</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="billing-summary mb-3">
            <h4>Precio de Venta</h4>
            <div className="d-flex flex-column">
              <span className="amount">{formatNumber(shoppingStats.pvp)}€</span>
            </div>
          </div>
        </div>
        <div className="col"></div>
      </div>
      <div className="row mb-4">
        <div className="col-3">
          <div className="d-flex flex-column">
            <label>Marca</label>
            <div className="d-flex flex-row align-items-center">
              <select
                name="brandId"
                id="brandId"
                value={brand?.id || ''}
                onChange={(e) => {
                  if (e.target.value) {
                    const brandId: number = parseInt(e.target.value);
                    const index: number = brands.findIndex((b: Brand) => b.id === brandId);
                    if (index > -1) {
                      setBrand(brands[index]);
                    } else {
                      setBrand(null);
                    }
                  } else {
                    setBrand(null);
                  }
                }}
              >
                <option value="">Selecciona una opción</option>
                {brands.map((brand: Brand) => (
                  <option key={brand.id} value={brand.id}>
                    {brand.name}
                  </option>
                ))}
              </select>
              {brand !== null && (
                <div className="clickable ms-2" title="Deseleccionar marca" onClick={() => setBrand(null)}>
                  <X color="gray" size={20} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col px-2 mb-3 mb-xl-0">
          <div className="dashboard-tab active mb-4">
            <div className="d-flex flex-row align-items-center mb-2">
              <h4 className="mb-0 flex-grow-1">Stock por marca</h4>
              <CSVLink filename={`stock_marcas_${slugify(store?.name)}.csv`} className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
                <Download className="me-1" size={14} /> Exportar
              </CSVLink>
            </div>
            {shoppingStats?.stockByBrand && <Table columns={columnsShoppingByBrand} data={shoppingStats.stockByBrand} noDataMessage={'No hay productos en stock en el período seleccionado.'} />}
          </div>
          {brand !== null && (
            <div className="dashboard-tab active">
              <div className="d-flex flex-row align-items-center mb-2">
                <h4 className="mb-0 flex-grow-1">
                  Stock de la marca <strong>{brand.name}</strong>
                </h4>
                <CSVLink filename={`ventas_marca_${slugify(brand.name)}.csv`} className="d-flex align-items-center export-csv me-2" data={csvDataProducts} onClick={generateCsvProducts}>
                  <Download className="me-1" size={14} /> Exportar
                </CSVLink>
              </div>
              {requestingProducts ? (
                <div className="d-flex flex-row justify-content-center">
                  <Loader type="TailSpin" color="#252E3C" height={30} width={30} />
                </div>
              ) : (
                <div ref={refContainerSalesByProduct}>
                  <Table columns={columnsStockByProduct} data={stockStatsByProduct} noDataMessage={'No hay productos de la marca seleccionada.'} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardStockStats;
