import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { OrganizationType } from '../enums/organization-type';
import { useStores } from '../hooks/use-stores.hook';
import { Stock } from '../interfaces/stock';
import { Store } from '../interfaces/store';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  show: boolean;
  closeModal: (stock: Stock[]) => void;
}

interface ImportStockByCsv {
  storeId: number;
  file: FileList | null;
}

const initialValues: ImportStockByCsv = {
  storeId: -1,
  file: null,
};

const ImportStockModal = (props: Props) => {
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const stores: Store[] = useStores();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ImportStockByCsv>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!props.show) {
      reset(initialValues);
    }
  }, [props.show, reset]);

  const close = () => {
    reset();
    props.closeModal([]);
  };

  const onSubmit = async (importStockByCsv: ImportStockByCsv) => {
    myToastr.info('Actualizando el stock mediante csv. Espere por favor...');
    try {
      const stock: Stock[] = await api.updateStockByCsv(importStockByCsv.storeId, importStockByCsv.file![0]!);
      myToastr.remove();
      myToastr.success('Stock actualizado correctamente');
      props.closeModal(stock);
    } catch (e: any) {
      myToastr.remove();
      myToastr.error(e.response.data.message);
    }
  };

  return (
    <Modal className="vercomi-modal my-form import-stock-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Ajustar precio/stock</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.storeId })}>{organizationType === OrganizationType.B2B ? 'Almacén' : 'Tienda'} *</div>
              <select {...register('storeId', { required: true, min: 1, valueAsNumber: true })} className={clsx({ error: errors?.storeId })}>
                <option value={-1}>{organizationType === OrganizationType.B2B ? 'Selecciona un almacén' : 'Selecciona una tienda'}</option>
                {stores.map((store: Store) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
              </select>
              {errors.storeId && <div className="error-message">{errors.storeId.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.file })}>Fichero CSV *</div>
              <input type="file" {...register('file', { required: true })} className={clsx({ error: errors?.file })} accept=".csv" />
              {errors.file && <div className="error-message">{errors.file.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name')}>Columnas CSV</div>
              <ul>
                <li>sku (texto)</li>
                <li>unidades (número entero)</li>
                {organizationType === OrganizationType.B2B && <li>pvm (número flotante)</li>}
                <li>pvp (número flotante)</li>
              </ul>
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default ImportStockModal;
