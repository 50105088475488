import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CustomerInfo from '../components/customer-info';
import MakePaymentRepair from '../components/make-payment-repair';
import RepairBudget from '../components/repair-budget';
import RepairBudgetInfo from '../components/repair-budget-info';
import RepairHeader from '../components/repair-header';
import RepairNotifyCustomer from '../components/repair-notify-customer';
import RepairNotifyTechnicalService from '../components/repair-notify-technical-service';
import RepairPrices from '../components/repair-prices';
import RepairReceivedFromTechnicalService from '../components/repair-received-technical-service';
import RepairRejectedBudget from '../components/repair-rejected-budget';
import RepairSentTechnicalService from '../components/repair-sent-technical-service';
import RepairStatusView from '../components/repair-status-view';
import RepairSummary from '../components/repair-summary';
import RepairUpdateHeader from '../components/repair-update-header';
import RepairWarrantyInProcess from '../components/repair-warranty-in-process';
import TablePaymentsOnAccount from '../components/table-payments-on-account';
import { TicketOptions } from '../components/ticket-options';
import UpdateRepairButtons from '../components/update-repair-buttons';
import { RepairStatus } from '../enums/repair-status';
import { Customer } from '../interfaces/customer';
import { api } from '../services/api';
import { downloadPdf } from '../services/helpers';
import myToastr from '../services/toastr';
import { changeRepairCustomer, getRepair, repairSelector, reset } from '../store/repair-slice';
import { setStoreId as setStoreIdStore, storeSelector } from '../store/store-slice';

const UpdateRepairView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { selectedStoreId } = useSelector(storeSelector);
  const { repair, errorRepair, customer, balance } = useSelector(repairSelector);
  const [requestingTicket, setRequestingTicket] = useState<boolean>(false);
  const [sendingTicketByEmail, setSendingTicketByEmail] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.id) {
      const repairId: number = parseInt(params.id, 10);
      dispatch(getRepair(repairId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    if (repair && repair.storeId !== selectedStoreId) {
      dispatch(setStoreIdStore(repair.storeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repair, selectedStoreId]);

  useEffect(() => {
    if (errorRepair) {
      navigate('/reparaciones');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorRepair]);

  const onPrintTicket = async () => {
    if (requestingTicket) {
      return;
    }
    try {
      myToastr.info('Obteniendo el ticket de la reparación. Espere por favor.');
      setRequestingTicket(true);
      const result: ArrayBuffer = await api.getRepairTicket(repair!.id);
      downloadPdf(result, `reparacion-${repair!.internalId}.pdf`);
    } catch (e) {
      myToastr.error('Hubo un error obteniendo el ticket de la reparación');
    } finally {
      setRequestingTicket(false);
    }
  };

  const onSendEmail = async () => {
    if (sendingTicketByEmail) {
      return;
    }
    if (!customer?.email) {
      myToastr.error('El cliente no tiene un email asociado');
      return;
    }
    myToastr.info('Enviando ticket por correo electrónico. Espere por favor.');
    try {
      setSendingTicketByEmail(true);
      const result: boolean = await api.sendRepairTicketByEmail(repair!.id);
      if (result) {
        myToastr.success('Se ha enviado el ticket por correo electrónico');
      } else {
        myToastr.error('Hubo un error enviando el ticket por correo electrónico');
      }
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          myToastr.error(Array.isArray(axiosError.response.data.message) ? axiosError.response.data.message.join('<br>') : axiosError.response.data.message);
        }
      } else {
        myToastr.error('Hubo un error enviando el ticket por correo electrónico');
      }
    } finally {
      setSendingTicketByEmail(false);
    }
  };

  if (!repair) {
    return null;
  }

  return (
    <div className="new-repair inner-layout">
      <div className="l-side">
        <div className="product-side p-4 d-flex flex-column h-100">
          <RepairHeader isNew={false} />
          <RepairStatusView />
          {repair.warranty && repair.repairStatus === RepairStatus.InProcess && <RepairWarrantyInProcess />}
          {repair.repairStatus === RepairStatus.Budget && !repair.sentTechnicalService && <RepairSentTechnicalService />}
          {repair.repairStatus === RepairStatus.Budget && repair.sentTechnicalService && repair.costPrice === null && repair.repairPrice === null && <RepairPrices />}
          {repair.repairStatus === RepairStatus.Budget && repair.costPrice !== null && repair.repairPrice !== null && repair.acceptBudget === null && <RepairBudget />}
          {repair.repairStatus === RepairStatus.InProcess && repair.costPrice !== null && repair.repairPrice !== null && repair.sentTechnicalService && repair.acceptBudget !== null && (
            <RepairReceivedFromTechnicalService />
          )}
          {repair.repairStatus === RepairStatus.InProcess && repair.acceptBudget === false && <RepairRejectedBudget />}
          {repair.costPrice !== null && repair.repairPrice !== null && <RepairBudgetInfo />}
          <RepairSummary />
        </div>
      </div>
      <div className="r-side">
        <div className="d-flex flex-column position-relative">
          <div className="p-4 mb-auto">
            <RepairUpdateHeader />
            {(repair.repairStatus === RepairStatus.InProcess ||
              repair.repairStatus === RepairStatus.Budget ||
              repair.repairStatus === RepairStatus.Finished ||
              repair.repairStatus === RepairStatus.Canceled ||
              repair.repairStatus === RepairStatus.Delivered) && (
              <CustomerInfo customer={customer!} onAssignCustomer={(newCustomer: Customer) => dispatch(changeRepairCustomer(newCustomer.id))} balance={balance} />
            )}
            {repair.warranty && repair.repairStatus === RepairStatus.InProcess && <RepairNotifyTechnicalService />}
            {repair.warranty && repair.repairStatus === RepairStatus.Finished && <div className="repair-product-with-warranty">Producto en garantía</div>}
            {!repair.warranty && (repair.repairStatus === RepairStatus.Finished || repair.repairStatus === RepairStatus.Delivered) && repair.acceptBudget === false && (
              <div className="repair-budget-not-accepted">Presupuesto no aceptado</div>
            )}
            {(repair.repairStatus === RepairStatus.Finished || repair.repairStatus === RepairStatus.Delivered || repair.repairStatus === RepairStatus.InProcess) &&
              repair.acceptBudget &&
              repair.warranty === false && <MakePaymentRepair />}
            {((repair.repairStatus === RepairStatus.Finished && repair.paymentsOnAccount.length > 0) ||
              (repair.repairStatus === RepairStatus.InProcess && !repair.warranty && repair.acceptBudget)) && <TablePaymentsOnAccount />}
            {(repair.repairStatus === RepairStatus.Budget || repair.repairStatus === RepairStatus.InProcess || repair.repairStatus === RepairStatus.Canceled) && <RepairNotifyCustomer />}
            <TicketOptions showGift={false} onPrint={onPrintTicket} onSendGift={() => {}} onSendEmail={onSendEmail} />
          </div>
          <UpdateRepairButtons />
        </div>
      </div>
    </div>
  );
};

export default UpdateRepairView;
