import { UilApps, UilFileCheck } from '@iconscout/react-unicons';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import {
  ChevronDown,
  Clipboard,
  CornerDownLeft,
  CreditCard,
  DollarSign,
  FileText,
  Home,
  Inbox,
  Layers,
  Layout,
  List,
  LogOut,
  Scissors,
  Send,
  ShoppingBag,
  ShoppingCart,
  Tag,
  UserPlus,
} from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Role } from '../enums/role';
import { User } from '../interfaces/user';
import { Constants } from '../services/constants';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { uiSelector } from '../store/ui-slice';
import LogoutModal from './logout-modal';

const Aside = () => {
  const location = useLocation();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const { collapsed } = useSelector(uiSelector);
  const [isOpenedProducts, setIsOpenedProducts] = useState<boolean>(false);
  const [isOpenedShopping, setIsOpenedShopping] = useState<boolean>(false);
  const [isOpenedCaja, setIsOpenedCaja] = useState<boolean>(false);
  const activeColor: string = Constants.ACTIVE_COLOR_RETAIL;
  const showDashboard: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showSales: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showProducts: boolean = useMemo(() => user.role === Role.SuperAdmin, [user]);
  const showStock: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showDeliveryNotes: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.WholesaleCustomer, [user]);
  const showStockControl: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager, [user]);
  const showThresholds: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager, [user]);
  const showInventories: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showClients: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showTransfers: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showPurchaseOrders: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.WholesaleCustomer, [user]);
  const showRepairs: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showWithdrawals: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showExpirations: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager, [user]);
  const showInvoices: boolean = useMemo(() => user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.WholesaleCustomer, [user]);
  const showPos: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showCashRegisters: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);
  const showMovements: boolean = useMemo(() => user.role !== Role.WholesaleCustomer, [user]);

  useEffect(() => {
    const openedShopping: boolean =
      location.pathname.startsWith('/compra') || location.pathname.startsWith('/ordenes-compra') || location.pathname.startsWith('/orden-compra') || location.pathname.startsWith('/vencimiento');
    setIsOpenedShopping(openedShopping);
    const openedProductsSection: boolean =
      location.pathname.startsWith('/producto') ||
      location.pathname.startsWith('/stock') ||
      location.pathname.startsWith('/control-stock') ||
      location.pathname === '/umbrales' ||
      location.pathname.startsWith('/inventario');
    setIsOpenedProducts(openedProductsSection);
    const openedCashRegistersSection: boolean =
      location.pathname.startsWith('/arqueo') ||
      location.pathname.startsWith('/movimientos') ||
      location.pathname.startsWith('/albaran') ||
      location.pathname.startsWith('/factura') ||
      location.pathname.startsWith('/gastos');
    setIsOpenedCaja(openedCashRegistersSection);
  }, [location.pathname]);

  return (
    <React.Fragment>
      <div className={clsx('aside d-flex flex-column justify-content-between', { collapsed })}>
        <ul className="views">
          {showDashboard && (
            <li className={location.pathname === '/' ? 'active' : ''} title="Dashboard">
              <Link to="/" className="d-flex flex-row align-items-center">
                <Home color={location.pathname === '/' ? activeColor : Constants.DISABLED_COLOR} />
                <span>Dashboard</span>
              </Link>
            </li>
          )}
          {(showDeliveryNotes || showExpirations || showPurchaseOrders) && (
            <li title="Compras">
              <div className="d-flex flex-column">
                <div
                  onClick={() => setIsOpenedShopping(!isOpenedShopping)}
                  title="Compras"
                  className={clsx(
                    'd-flex flex-row align-items-center has-submenu',
                    { open: !collapsed && isOpenedShopping },
                    {
                      active: isOpenedShopping,
                    },
                  )}
                >
                  <ShoppingBag color={isOpenedShopping ? activeColor : Constants.DISABLED_COLOR} />
                  <span>Compras</span>
                  <ChevronDown className="to-rotate" color={isOpenedShopping ? activeColor : Constants.DISABLED_COLOR} />
                </div>
                {!collapsed && isOpenedShopping && (
                  <Collapse isOpened={isOpenedShopping}>
                    <ul>
                      {showDeliveryNotes && (
                        <li className={location.pathname.startsWith('/compra') ? 'active' : ''} title="Compras">
                          <Link to="/compras" className="d-flex flex-row align-items-center">
                            <Clipboard color={location.pathname.startsWith('/compra') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Compras</span>
                          </Link>
                        </li>
                      )}
                      {showPurchaseOrders && (
                        <li className={location.pathname.startsWith('/orden') ? 'active' : ''} title="Órdenes de compra">
                          <Link to="/ordenes-compra" className="d-flex flex-row align-items-center">
                            <ShoppingCart color={location.pathname.startsWith('/orden') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Órdenes compra</span>
                          </Link>
                        </li>
                      )}
                      {showExpirations && (
                        <li className={location.pathname === '/vencimientos' ? 'active' : ''} title="Vencimientos">
                          <Link to="/vencimientos" className="d-flex flex-row align-items-center">
                            <CreditCard color={location.pathname === '/vencimientos' ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Vencimientos</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                )}
              </div>
            </li>
          )}
          {showSales && (
            <li className={location.pathname.startsWith('/venta') ? 'active' : ''} title="Ventas">
              <Link to="/ventas" className="d-flex flex-row align-items-center">
                <ShoppingCart color={location.pathname.startsWith('/venta') ? activeColor : Constants.DISABLED_COLOR} />
                <span>Ventas</span>
              </Link>
            </li>
          )}
          {(showProducts || showStock || showStockControl || showThresholds || showInventories) && (
            <li title="Productos">
              <div className="d-flex flex-column">
                <div
                  onClick={() => setIsOpenedProducts(!isOpenedProducts)}
                  title="Productos"
                  className={clsx(
                    'd-flex flex-row align-items-center has-submenu',
                    { open: !collapsed && isOpenedProducts },
                    {
                      active: isOpenedProducts,
                    },
                  )}
                >
                  <UilApps color={isOpenedProducts ? activeColor : Constants.DISABLED_COLOR} />
                  <span>Productos</span>
                  <ChevronDown className="to-rotate" color={isOpenedProducts ? activeColor : Constants.DISABLED_COLOR} />
                </div>
                {!collapsed && isOpenedProducts && (
                  <Collapse isOpened={isOpenedProducts}>
                    <ul>
                      {showProducts && (
                        <li className={location.pathname.startsWith('/producto') ? 'active' : ''} title="Productos">
                          <Link to="/productos" className="d-flex flex-row align-items-center">
                            <UilApps color={location.pathname.startsWith('/producto') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Productos</span>
                          </Link>
                        </li>
                      )}
                      {showStock && (
                        <li className={location.pathname.startsWith('/stock') ? 'active' : ''} title="Stock">
                          <Link to="/stock" className="d-flex flex-row align-items-center">
                            <Tag color={location.pathname.startsWith('/stock') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Stock</span>
                          </Link>
                        </li>
                      )}
                      {showStockControl && (
                        <li className={location.pathname.startsWith('/control-stock') ? 'active' : ''} title="Control stock">
                          <Link to="/control-stock" className="d-flex flex-row align-items-center">
                            <Layers color={location.pathname.startsWith('/control-stock') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Control Stock</span>
                          </Link>
                        </li>
                      )}
                      {showThresholds && (
                        <li className={location.pathname === '/umbrales' ? 'active' : ''} title="Umbrales">
                          <Link to="/umbrales" className="d-flex flex-row align-items-center">
                            <UilFileCheck color={location.pathname === '/umbrales' ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Umbrales</span>
                          </Link>
                        </li>
                      )}
                      {showInventories && (
                        <li className={location.pathname.startsWith('/inventario') ? 'active' : ''} title="Inventarios">
                          <Link to="/inventarios" className="d-flex flex-row align-items-center">
                            <List color={location.pathname.startsWith('/inventario') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Inventarios</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                )}
              </div>
            </li>
          )}
          {showClients && (
            <li className={location.pathname === '/clientes' ? 'active' : ''} title="Clientes">
              <Link to="/clientes" className="d-flex flex-row align-items-center">
                <UserPlus color={location.pathname === '/clientes' ? activeColor : Constants.DISABLED_COLOR} />
                <span>Clientes</span>
              </Link>
            </li>
          )}
          {showTransfers && (
            <li className={location.pathname.startsWith('/traspaso') ? 'active' : ''} title="Traspasos">
              <Link to="/traspasos" className="d-flex flex-row align-items-center">
                <Send color={location.pathname.startsWith('/traspaso') ? activeColor : Constants.DISABLED_COLOR} />
                <span>Traspasos</span>
              </Link>
            </li>
          )}
          {showRepairs && (
            <li className={location.pathname.startsWith('/reparacion') ? 'active' : ''} title="Composturas">
              <Link to="/reparaciones" className="d-flex flex-row align-items-center">
                <Scissors color={location.pathname.startsWith('/reparaciones') ? activeColor : Constants.DISABLED_COLOR} />
                <span>Composturas</span>
              </Link>
            </li>
          )}
          {showPos && (
            <li className={location.pathname === '/pos' ? 'active' : ''} title="TPV / POS">
              <Link to="/pos" className="d-flex flex-row align-items-center">
                <Layout color={location.pathname === '/pos' ? activeColor : Constants.DISABLED_COLOR} />
                <span>TPV</span>
              </Link>
            </li>
          )}
          {(showCashRegisters || showMovements || showInvoices || showWithdrawals) && (
            <li title="Caja">
              <div className="d-flex flex-column">
                <div
                  onClick={() => setIsOpenedCaja(!isOpenedCaja)}
                  title="Caja"
                  className={clsx(
                    'd-flex flex-row align-items-center has-submenu',
                    { open: !collapsed && isOpenedCaja },
                    {
                      active: isOpenedCaja,
                    },
                  )}
                >
                  <Inbox color={isOpenedCaja ? activeColor : Constants.DISABLED_COLOR} />
                  <span>Caja</span>
                  <ChevronDown className="to-rotate" color={isOpenedCaja ? activeColor : Constants.DISABLED_COLOR} />
                </div>
                {!collapsed && isOpenedCaja && (
                  <Collapse isOpened={isOpenedCaja}>
                    <ul>
                      {showCashRegisters && (
                        <li className={location.pathname.startsWith('/arqueo') ? 'active' : ''} title="Arqueos">
                          <Link to="/arqueos" className="d-flex flex-row align-items-center">
                            <Inbox color={location.pathname.startsWith('/arqueo') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Arqueos</span>
                          </Link>
                        </li>
                      )}
                      {showMovements && (
                        <li className={location.pathname.startsWith('/movimientos') ? 'active' : ''} title="Movimientos caja">
                          <Link to="/movimientos" className="d-flex flex-row align-items-center">
                            <DollarSign color={location.pathname.startsWith('/movimientos') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Movimientos</span>
                          </Link>
                        </li>
                      )}
                      {showInvoices && (
                        <li className={location.pathname.startsWith('/factura') ? 'active' : ''}>
                          <Link to="/facturas" className="d-flex flex-row align-items-center">
                            <FileText color={location.pathname.startsWith('/factura') ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Facturas</span>
                          </Link>
                        </li>
                      )}
                      {showWithdrawals && (
                        <li className={location.pathname === '/gastos' ? 'active' : ''} title="Gastos">
                          <Link to="/gastos" className="d-flex flex-row align-items-center">
                            <CornerDownLeft color={location.pathname === '/gastos' ? activeColor : Constants.DISABLED_COLOR} />
                            <span>Gastos</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                )}
              </div>
            </li>
          )}
        </ul>
        <ul className="menu-logout">
          <li title="Salir">
            <span onClick={() => setShowLogoutModal(true)}>
              <LogOut color={Constants.DISABLED_COLOR} />
              <span>Salir</span>
            </span>
          </li>
        </ul>
      </div>
      <LogoutModal show={showLogoutModal} closeModal={() => setShowLogoutModal(false)} />
    </React.Fragment>
  );
};

export default Aside;
