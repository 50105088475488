import { useDispatch, useSelector } from 'react-redux';
import { repairSelector, setReceivedFromTechnicalService } from '../store/repair-slice';

const RepairReceivedFromTechnicalService = () => {
  const dispatch = useDispatch();
  const { receivedFromTechnicalService, requesting } = useSelector(repairSelector);

  return (
    <div className="d-flex flex-column check-switch w-100 mb-4">
      <label className="subtitle mb-3">Recibido del servicio técnico</label>
      <input
        type="checkbox"
        checked={receivedFromTechnicalService || false}
        disabled={requesting}
        onChange={(e) => {
          dispatch(setReceivedFromTechnicalService(e.target.checked));
        }}
      />
    </div>
  );
};

export default RepairReceivedFromTechnicalService;
