import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { NotificationStatus } from '../enums/notification-status';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { MarkAllNotificationsAsReadDto } from '../interfaces/mark-all-notifications-as-read.dto';
import { Notification } from '../interfaces/notification';
import { TotalUnreadNotifications } from '../interfaces/total-unread-notifications.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { authSlice } from './auth-slice';
import { AppThunk, RootState } from './store';

export interface NotificationsState {
  totalUnreadNotifications: TotalUnreadNotifications | null;
}

const initialState: NotificationsState = {
  totalUnreadNotifications: null,
};

function* getTotalNumberOfUnreadNotifications(): any {
  try {
    const totalUnreadNotifications: TotalUnreadNotifications = yield api.getTotalNumberOfUnreadNotifications();
    yield put(notificationsSlice.actions.setTotalUnreadNotifications(totalUnreadNotifications));
  } catch (e) {
    yield put(notificationsSlice.actions.setTotalUnreadNotifications(null));
  }
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setTotalUnreadNotifications: (state: NotificationsState, action: PayloadAction<TotalUnreadNotifications | null>) => {
      state.totalUnreadNotifications = action.payload;
    },
    deleteNotification: (state: NotificationsState, action: PayloadAction<Notification>) => {
      if (!state.totalUnreadNotifications) {
        return;
      }
      const notification: Notification = action.payload;
      const index: number = state.totalUnreadNotifications!.notifications.findIndex((n: Notification) => n.id === notification.id);
      if (index > -1) {
        state.totalUnreadNotifications!.total--;
        state.totalUnreadNotifications!.notifications.splice(index, 1);
      }
    },
    newNotification: (state: NotificationsState, action: PayloadAction<Notification>) => {
      if (!state.totalUnreadNotifications) {
        return;
      }
      const notification: Notification = action.payload;
      const index: number = state.totalUnreadNotifications!.notifications.findIndex((n: Notification) => n.id === notification.id);
      if (index === -1 && notification.status === NotificationStatus.Unread) {
        state.totalUnreadNotifications!.notifications.unshift(notification);
      } else if (index > -1 && notification.status === NotificationStatus.Read) {
        state.totalUnreadNotifications!.notifications.splice(index, 1);
      }
    },
    reset: () => {
      return initialState;
    },
  },
});

export const markNotificationAsRead =
  (id: number): AppThunk<NotificationsState> =>
  async (dispatch): Promise<void> => {
    try {
      const notification: Notification = await api.markNotificationAsRead(id);
      dispatch(notificationsSlice.actions.deleteNotification(notification));
    } catch (e) {}
  };

export const markAllNotificationsAsRead =
  (markAllNotificationsAsReadDto: MarkAllNotificationsAsReadDto): AppThunk<NotificationsState> =>
  async (dispatch): Promise<void> => {
    try {
      await api.markAllNotificationsAsRead(markAllNotificationsAsReadDto);
      dispatch(
        notificationsSlice.actions.setTotalUnreadNotifications({
          total: 0,
          notifications: [],
        }),
      );
      myToastr.success('Todas las notificaciones han sido marcadas como leídas');
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

export const { newNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const notificationsSelector = (state: RootState) => state.notifications;

export const notificationsSaga = function* () {
  yield takeLatest(authSlice.actions.setUser.type, getTotalNumberOfUnreadNotifications);
  yield takeLatest(notificationsSlice.actions.deleteNotification.type, getTotalNumberOfUnreadNotifications);
};
