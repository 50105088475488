import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  title: string;
  user: User;
  show: boolean;
  closeModal: (storeId: number | null) => void;
}

interface FormData {
  storeId: number;
}

const defaultValues: FormData = {
  storeId: -1,
};

const UserWorkClockingModal = ({ title, user, show, closeModal }: Props) => {
  const selectedStoreId: number = useAppSelector((state: RootState) => state.store.selectedStoreId);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues,
  });

  useEffect(() => {
    if (show) {
      return;
    }
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!user || !show) {
      return;
    }
    setValue('storeId', selectedStoreId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, show]);

  const close = () => {
    closeModal(-1);
  };

  const onSubmit = async (formData: FormData) => {
    closeModal(formData.storeId);
  };

  return (
    <Modal className="vercomi-modal my-form brand-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">{title}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.storeId })}>Tienda</div>
              <select {...register('storeId', { required: false, valueAsNumber: true })} className={clsx({ error: errors?.storeId })}>
                <option value={-1}>Selecciona una tienda</option>
                {user.stores.map((store: Store) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default UserWorkClockingModal;
