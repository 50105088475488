import { OrganizationType } from '../enums/organization-type';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const NoStoresAssigned = () => {
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <h1>{organizationType === OrganizationType.B2C ? 'No tienes ninguna tienda asignada' : 'No tienes ningún almacén asignado'}</h1>
    </div>
  );
};

export default NoStoresAssigned;
