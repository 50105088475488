import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { put, select, takeLatest } from 'redux-saga/effects';
import { RepairStatus } from '../enums/repair-status';
import { Balance } from '../interfaces/balance';
import { CancelRepairDto } from '../interfaces/cancel-repair.dto';
import { ChangeRepairCustomerDto } from '../interfaces/change-repair-customer.dto';
import { CreateRepairDto } from '../interfaces/create-repair.dto';
import { Customer } from '../interfaces/customer';
import { DeliverRepairDto } from '../interfaces/deliver-repair.dto';
import { FromBase64Dto } from '../interfaces/from-base64.dto';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { NotesDto } from '../interfaces/notes.dto';
import { PaymentOnAccountDto } from '../interfaces/payment-on-account.dto';
import { Repair } from '../interfaces/repair';
import { RepairAcceptBudgetDto } from '../interfaces/repair-accept-budget.dto';
import { RepairBudgetInStoreDto } from '../interfaces/repair-budget-in-store.dto';
import { RepairImage } from '../interfaces/repair-image';
import { RepairPaymentMethod } from '../interfaces/repair-payment-method';
import { RepairPaymentMethodDto } from '../interfaces/repair-payment-method.dto';
import { RepairPricesDto } from '../interfaces/repair-prices.dto';
import { RepairReceivedFromTechnicalServiceDto } from '../interfaces/repair-received-from-technical-service.dto';
import { RepairRecord } from '../interfaces/repair-record';
import { RepairRecordDto } from '../interfaces/repair-record.dto';
import { RepairSentTechnicalServiceDto } from '../interfaces/repair-sent-technical-service.dto';
import { UpdatePaymentOnAccountDto } from '../interfaces/update-payment-on-account.dto';
import { UpdateRepairPaymentMethodDto } from '../interfaces/update-repair-payment-method.dto';
import { UpdateRepairWithWarrantyDto } from '../interfaces/update-repair-with-warranty.dto';
import { UpdateRepairDto } from '../interfaces/update-repair.dto';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { AuthState } from './auth-slice';
import { RootState } from './store';
import { StoreState } from './store-slice';

export interface RepairState {
  repair: Repair | null;
  errorRepair: boolean;
  // RepairDto
  notes: string | null;
  sentTechnicalService: boolean;
  hasBudget: boolean;
  acceptBudget: boolean | null;
  useBalance: boolean;
  amountBalance: number;
  repairStatus: RepairStatus;
  costPrice: number | null;
  repairPrice: number | null;
  totalIva: number;
  repairConcept: string;
  productId: number;
  item: string;
  storeId: number;
  customerId: number;
  repairPaymentMethods: RepairPaymentMethodDto[];
  paymentsOnAccount: PaymentOnAccountDto[];
  changeToClient: number;
  changeInBalance: boolean;
  technicalServiceId: number;
  warranty: boolean | null;
  budgetInStore: boolean;
  receivedFromTechnicalService: boolean;
  useStock: boolean;
  internalReference: string | null;
  // Payment
  pending: number;
  paying: boolean;
  // Others
  customer: Customer | null;
  balance: Balance | null;
  images: string[];
  imagesBase64: string[];
  requesting: boolean;
  // Errores
  errorProduct: boolean;
  errorCostPrice: boolean;
  errorRepairPrice: boolean;
  errorTechnicalService: boolean;
  errorCustomer: boolean;
  errorBudget: boolean;
  errorItem: boolean;
}

const initialState: RepairState = {
  repair: null,
  errorRepair: false,
  // RepairDto
  notes: '',
  sentTechnicalService: false,
  hasBudget: false,
  acceptBudget: null,
  useBalance: false,
  amountBalance: 0,
  repairStatus: RepairStatus.Opened,
  costPrice: null,
  repairPrice: null,
  totalIva: 0,
  repairConcept: '',
  productId: -1,
  item: '',
  storeId: -1,
  customerId: -1,
  repairPaymentMethods: [],
  paymentsOnAccount: [],
  changeToClient: 0,
  changeInBalance: false,
  technicalServiceId: -1,
  warranty: null,
  budgetInStore: false,
  receivedFromTechnicalService: false,
  useStock: false,
  internalReference: null,
  // Payment
  pending: 0,
  paying: false,
  // Others
  customer: null,
  balance: null,
  images: [],
  imagesBase64: [],
  requesting: false,
  // Errores
  errorProduct: false,
  errorCostPrice: false,
  errorRepairPrice: false,
  errorTechnicalService: false,
  errorCustomer: false,
  errorBudget: false,
  errorItem: false,
};

function* getCustomerBalance(): any {
  try {
    const state: RootState = yield select();
    const customer: Customer | null = state.repair.customer;
    if (customer) {
      const balance: Balance = yield api.getCustomerBalance(customer.id);
      yield put(repairSlice.actions.setBalance(balance));
    } else {
      yield put(repairSlice.actions.setBalance(null));
    }
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    yield put(repairSlice.actions.setBalance(null));
  }
}

export const getRepair = createAsyncThunk('repair/getRepair', async (id: number, { dispatch }) => {
  try {
    const repair: Repair = await api.getRepair(id);
    if (repair?.customerId) {
      try {
        const balance: Balance = await api.getCustomerBalance(repair.customerId);
        dispatch(repairSlice.actions.setBalance(balance));
      } catch (e) {}
    }
    return repair;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    return null;
  }
});

export const deleteRepairImage = createAsyncThunk('repair/deleteRepairImage', async (repairImage: RepairImage) => {
  try {
    return api.deleteRepairImage(repairImage.id);
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    return null;
  }
});

export const addRepairRecord = createAsyncThunk('repair/addRepairRecord', async (repairRecordDto: RepairRecordDto) => {
  try {
    return api.addRepairRecord(repairRecordDto);
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    return null;
  }
});

export const changeRepairCustomer = createAsyncThunk('repair/changeCustomer', async (customerId: number, { getState, dispatch }): Promise<Repair | null> => {
  try {
    dispatch(repairSlice.actions.setRequesting(true));
    const state: RootState = getState() as RootState;
    const changeRepairCustomerDto: ChangeRepairCustomerDto = {
      customerId,
    };
    const repair: Repair = await api.changeRepairCustomer(state.repair.repair!.id, changeRepairCustomerDto);
    dispatch(repairSlice.actions.setRepair(repair));
    dispatch(repairSlice.actions.setRequesting(false));
    myToastr.success('Cliente de la compostura actualizado');
    return repair;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(repairSlice.actions.setRequesting(false));
    return null;
  }
});

export const createNewRepair = createAsyncThunk('repair/createNewRepair', async (args: { pin: string; images: File[]; base64Images: string[] }, { getState, dispatch }) => {
  try {
    dispatch(repairSlice.actions.setRequesting(true));
    const authState: AuthState = (getState() as RootState).auth;
    const repairState: RepairState = (getState() as RootState).repair;
    const storeState: StoreState = (getState() as RootState).store;
    const createRepairDto: CreateRepairDto = {
      organizationId: authState.organization!.id,
      pin: args.pin,
      notes: repairState.notes,
      hasBudget: repairState.hasBudget,
      costPrice: repairState.costPrice,
      repairPrice: repairState.repairPrice,
      repairConcept: repairState.repairConcept,
      productId: repairState?.productId && repairState.productId > 0 ? repairState.productId : null,
      item: repairState?.item && repairState.item.length > 0 ? repairState.item : null,
      storeId: storeState.selectedStoreId,
      customerId: repairState.customerId,
      technicalServiceId: repairState.technicalServiceId >= 1 ? repairState.technicalServiceId : null,
      budgetInStore: repairState.budgetInStore,
      warranty: !repairState.warranty ? false : true,
      useStock: repairState.useStock,
      internalReference: repairState.internalReference,
    };
    const repair: Repair = await api.createNewRepair(createRepairDto);
    for (const image of args.images) {
      const repairImage: RepairImage = await api.uploadRepairImage(repair.id, image);
      repair.images.push(repairImage);
    }
    for (const base64Image of args.base64Images) {
      const fromBase64Dto: FromBase64Dto = {
        image: base64Image,
      };
      const repairImage: RepairImage = await api.uploadRepairImageFromBase64(repair.id, fromBase64Dto);
      repair.images.push(repairImage);
    }
    dispatch(repairSlice.actions.clearImages());
    dispatch(repairSlice.actions.setRequesting(false));
    dispatch(repairSlice.actions.reset());
    return repair;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(repairSlice.actions.setRequesting(false));
    return null;
  }
});

export const updateRepair = createAsyncThunk('repair/updateRepair', async (args: { pin: string }, { getState, dispatch }): Promise<Repair | null> => {
  try {
    dispatch(repairSlice.actions.setRequesting(true));
    const authState: AuthState = (getState() as RootState).auth;
    const repairState: RepairState = (getState() as RootState).repair;
    const updateRepairDto: UpdateRepairDto = {
      organizationId: authState.organization!.id,
      pin: args.pin,
      item: repairState.item,
      repairConcept: repairState.repairConcept,
      paymentsOnAccount: repairState.paymentsOnAccount,
      changeToClient: repairState.changeToClient,
      changeInBalance: repairState.changeInBalance,
    };
    const repair: Repair = await api.updateRepair(repairState.repair!.id, updateRepairDto);
    dispatch(repairSlice.actions.setRequesting(false));
    return repair;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(repairSlice.actions.setRequesting(false));
    return null;
  }
});

export const updateRepairWithWarranty = createAsyncThunk(
  'repair/updateRepairWithWarranty',
  async (updateRepairWithWarrantyDto: UpdateRepairWithWarrantyDto, { getState, dispatch }): Promise<Repair | null> => {
    try {
      dispatch(repairSlice.actions.setRequesting(true));
      const state: RootState = getState() as RootState;
      const repair: Repair = await api.updateRepairWithWarranty(state.repair.repair!.id, updateRepairWithWarrantyDto);
      dispatch(repairSlice.actions.setRequesting(false));
      return repair;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      dispatch(repairSlice.actions.setRequesting(false));
      return null;
    }
  },
);

export const updateRepairPrices = createAsyncThunk('repair/updateRepairPrices', async (repairPricesDto: RepairPricesDto, { getState, dispatch }): Promise<Repair | null> => {
  try {
    dispatch(repairSlice.actions.setRequesting(true));
    const state: RootState = getState() as RootState;
    const repair: Repair = await api.updateRepairPrices(state.repair.repair!.id, repairPricesDto);
    dispatch(repairSlice.actions.setRequesting(false));
    return repair;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(repairSlice.actions.setRequesting(false));
    return null;
  }
});

export const updateRepairAcceptBudget = createAsyncThunk('repair/updateRepairAcceptBudget', async (repairAcceptBudgetDto: RepairAcceptBudgetDto, { getState, dispatch }): Promise<Repair | null> => {
  try {
    dispatch(repairSlice.actions.setRequesting(true));
    const state: RootState = getState() as RootState;
    const repair: Repair = await api.updateRepairAcceptBudget(state.repair.repair!.id, repairAcceptBudgetDto);
    dispatch(repairSlice.actions.setRequesting(false));
    return repair;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(repairSlice.actions.setRequesting(false));
    return null;
  }
});

export const updateRepairSentTechnicalService = createAsyncThunk(
  'repair/updateRepairSentTechnicalService',
  async (repairSentTechnicalServiceDto: RepairSentTechnicalServiceDto, { getState, dispatch }): Promise<Repair | null> => {
    try {
      dispatch(repairSlice.actions.setRequesting(true));
      const state: RootState = getState() as RootState;
      const repair: Repair = await api.updateRepairSentTechnicalService(state.repair.repair!.id, repairSentTechnicalServiceDto);
      dispatch(repairSlice.actions.setRequesting(false));
      return repair;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      dispatch(repairSlice.actions.setRequesting(false));
      return null;
    }
  },
);

export const updateRepairReceivedFromTechnicalService = createAsyncThunk(
  'repair/updateRepairReceivedFromTechnicalService',
  async (repairReceivedFromTechnicalServiceDto: RepairReceivedFromTechnicalServiceDto, { getState, dispatch }): Promise<Repair | null> => {
    try {
      dispatch(repairSlice.actions.setRequesting(true));
      const state: RootState = getState() as RootState;
      const repair: Repair = await api.updateRepairReceivedFromTechnicalService(state.repair.repair!.id, repairReceivedFromTechnicalServiceDto);
      dispatch(repairSlice.actions.setRequesting(false));
      return repair;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      dispatch(repairSlice.actions.setRequesting(false));
      return null;
    }
  },
);

export const updateRepairBudgetInStore = createAsyncThunk(
  'repair/updateRepairBudgetInStore',
  async (repairBudgetInStoreDto: RepairBudgetInStoreDto, { getState, dispatch }): Promise<Repair | null> => {
    try {
      dispatch(repairSlice.actions.setRequesting(true));
      const state: RootState = getState() as RootState;
      const repair: Repair = await api.updateRepairBudgetInStore(state.repair.repair!.id, repairBudgetInStoreDto);
      dispatch(repairSlice.actions.setRequesting(false));
      return repair;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      dispatch(repairSlice.actions.setRequesting(false));
      return null;
    }
  },
);

export const cancelRepair = createAsyncThunk('repair/cancelRepair', async (cancelRepairDto: CancelRepairDto, { getState, dispatch }): Promise<Repair | null> => {
  try {
    dispatch(repairSlice.actions.setRequesting(true));
    const repairState: RepairState = (getState() as RootState).repair;
    const repair: Repair = await api.cancelRepair(repairState.repair!.id, cancelRepairDto);
    dispatch(repairSlice.actions.setRequesting(false));
    return repair;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(repairSlice.actions.setRequesting(false));
    return null;
  }
});

export const deliverNewRepair = createAsyncThunk('repair/deliverNewRepair', async (pin: string, { getState, dispatch }): Promise<Repair | null> => {
  try {
    dispatch(repairSlice.actions.setRequesting(true));
    const authState: AuthState = (getState() as RootState).auth;
    const repairState: RepairState = (getState() as RootState).repair;
    const deliverRepairDto: DeliverRepairDto = {
      organizationId: authState.organization!.id,
      pin,
      repairPaymentMethods: repairState.repairPaymentMethods,
      changeToClient: repairState.changeToClient,
      changeInBalance: repairState.changeInBalance,
    };
    const repair: Repair = await api.deliverRepair(repairState.repair!.id, deliverRepairDto);
    const balance: Balance = await api.getCustomerBalance(repair.customerId);
    dispatch(repairSlice.actions.setBalance(balance));
    dispatch(repairSlice.actions.setRequesting(false));
    myToastr.success(`Reparación entregada`);
    return repair;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(repairSlice.actions.setRequesting(false));
    return null;
  }
});

export const updateNotes = createAsyncThunk('repair/updateNotes', async (notesDto: NotesDto, { getState, dispatch }): Promise<Repair | null> => {
  try {
    dispatch(repairSlice.actions.setRequesting(true));
    const repairState: RepairState = (getState() as RootState).repair;
    const repair: Repair = await api.updateRepairNotes(repairState.repair!.id, notesDto);
    dispatch(repairSlice.actions.setRequesting(false));
    return repair;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(repairSlice.actions.setRequesting(false));
    return null;
  }
});

export const updateRepairPaymentMethod = createAsyncThunk(
  'repair/update-repair-payment-method',
  async (updateRepairPaymentMethodDto: UpdateRepairPaymentMethodDto, { dispatch, getState }): Promise<Repair | null> => {
    try {
      dispatch(repairSlice.actions.setRequesting(true));
      const state: RootState = getState() as RootState;
      const repair: Repair = await api.updateRepairPaymentMethod(state.repair.repair!.id, updateRepairPaymentMethodDto);
      dispatch(repairSlice.actions.setRepair(repair));
      myToastr.success('Método de pago actualizado');
      return repair;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      dispatch(repairSlice.actions.setRequesting(false));
      return null;
    }
  },
);

export const updatePaymentOnAccount = createAsyncThunk(
  'repair/update-payment-on-accountmethod',
  async (updatePaymentOnAccountDto: UpdatePaymentOnAccountDto, { dispatch, getState }): Promise<Repair | null> => {
    try {
      dispatch(repairSlice.actions.setRequesting(true));
      const state: RootState = getState() as RootState;
      const repair: Repair = await api.updatePaymentOnAccount(state.repair.repair!.id, updatePaymentOnAccountDto);
      dispatch(repairSlice.actions.setRepair(repair));
      myToastr.success('Método de pago actualizado');
      return repair;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      dispatch(repairSlice.actions.setRequesting(false));
      return null;
    }
  },
);

export const repairSlice = createSlice({
  name: 'repair',
  initialState,
  reducers: {
    setNotes: (state: RepairState, action: PayloadAction<string | null>) => {
      state.notes = action.payload;
    },
    setSentTechnicalService: (state: RepairState, action: PayloadAction<boolean>) => {
      state.sentTechnicalService = action.payload;
    },
    setHasBudget: (state: RepairState, action: PayloadAction<boolean>) => {
      state.hasBudget = action.payload;
    },
    setAcceptBudget: (state: RepairState, action: PayloadAction<boolean | null>) => {
      state.acceptBudget = action.payload;
    },
    setUseBalance: (state: RepairState, action: PayloadAction<boolean>) => {
      state.useBalance = action.payload;
    },
    setAmountBalance: (state: RepairState, action: PayloadAction<number>) => {
      state.amountBalance = action.payload;
    },
    setRepairStatus: (state: RepairState, action: PayloadAction<RepairStatus>) => {
      state.repairStatus = action.payload;
    },
    setCostPrice: (state: RepairState, action: PayloadAction<number | null>) => {
      state.costPrice = action.payload;
    },
    setRepairPrice: (state: RepairState, action: PayloadAction<number | null>) => {
      state.repairPrice = action.payload;
    },
    setTotalIva: (state: RepairState, action: PayloadAction<number>) => {
      state.totalIva = action.payload;
    },
    setRepairConcept: (state: RepairState, action: PayloadAction<string>) => {
      state.repairConcept = action.payload;
    },
    setProductId: (state: RepairState, action: PayloadAction<number>) => {
      if (action.payload !== -1) {
        state.item = '';
      }
      state.productId = action.payload;
    },
    setItem: (state: RepairState, action: PayloadAction<string>) => {
      state.item = action.payload;
    },
    setStoreId: (state: RepairState, action: PayloadAction<number>) => {
      state.storeId = action.payload;
    },
    setRepairPaymentMethods: (state: RepairState, action: PayloadAction<RepairPaymentMethodDto[]>) => {
      state.repairPaymentMethods = action.payload;
    },
    setChangeToClient: (state: RepairState, action: PayloadAction<number>) => {
      state.changeToClient = action.payload;
    },
    setChangeInBalance: (state: RepairState, action: PayloadAction<boolean>) => {
      state.changeInBalance = action.payload;
    },
    setTechnicalServiceId: (state: RepairState, action: PayloadAction<number>) => {
      state.technicalServiceId = action.payload;
    },
    setCustomer: (state: RepairState, action: PayloadAction<Customer | null>) => {
      if (action.payload) {
        const customer = action.payload;
        state.customer = customer;
        state.customerId = customer.id;
      } else {
        state.customer = null;
        state.customerId = -1;
      }
    },
    clearImages: (state: RepairState) => {
      state.images = [];
      state.imagesBase64 = [];
    },
    addImage: (state: RepairState, action: PayloadAction<string>) => {
      state.images.push(action.payload);
    },
    addImageBase64: (state: RepairState, action: PayloadAction<string>) => {
      state.imagesBase64.push(action.payload);
    },
    removeImage: (state: RepairState, action: PayloadAction<number>) => {
      const images: string[] = [...state.images];
      images.splice(action.payload, 1);
      state.images = images;
    },
    removeImageBase64: (state: RepairState, action: PayloadAction<number>) => {
      const imagesBase64: string[] = [...state.imagesBase64];
      imagesBase64.splice(action.payload, 1);
      state.imagesBase64 = imagesBase64;
    },
    setBalance: (state: RepairState, action: PayloadAction<Balance | null>) => {
      state.balance = action.payload;
    },
    reset: () => {
      return initialState;
    },
    setRepairPaymentMethodAmount: (state: RepairState, action: PayloadAction<{ paymentMethodId: number; amount: number | null }>) => {
      const repairPaymentMethodDtos: RepairPaymentMethodDto[] = [...state.repairPaymentMethods];
      const index: number = repairPaymentMethodDtos.findIndex((repairPaymentMethod: RepairPaymentMethodDto) => repairPaymentMethod.paymentMethodId === action.payload.paymentMethodId);
      if (action.payload.amount !== null && action.payload.amount > 0) {
        if (index !== -1) {
          repairPaymentMethodDtos[index].amount = action.payload.amount;
        } else {
          repairPaymentMethodDtos.push({
            paymentMethodId: action.payload.paymentMethodId,
            amount: action.payload.amount,
            repairId: state.repair ? state.repair.id : -1,
          });
        }
      } else {
        if (index !== -1) {
          repairPaymentMethodDtos.splice(index, 1);
        }
      }
      state.repairPaymentMethods = repairPaymentMethodDtos;
      repairSlice.caseReducers.updatePaymentInfo(state);
    },
    setPaymentsOnAccount: (state: RepairState, action: PayloadAction<{ paymentMethodId: number; amount: number | null }>) => {
      let index: number = state.paymentsOnAccount.findIndex((paymentOnAccountDto: PaymentOnAccountDto) => paymentOnAccountDto.paymentMethodId === action.payload.paymentMethodId);
      if (action.payload.amount !== null && action.payload.amount > 0) {
        if (index !== -1) {
          state.paymentsOnAccount[index].amount = action.payload.amount;
        } else {
          state.paymentsOnAccount.push({
            paymentMethodId: action.payload.paymentMethodId,
            amount: action.payload.amount,
          });
        }
      } else {
        if (index !== -1) {
          state.paymentsOnAccount.splice(index, 1);
        }
      }
      repairSlice.caseReducers.updatePaymentInfo(state);
    },
    updatePaymentInfo: (state: RepairState) => {
      if (state.repairPrice !== null && state.repairPrice > 0) {
        state.totalIva = state.repairPrice / ((100 + parseFloat(process.env.REACT_APP_IVA)) / 100);
        let payed: number = 0;
        if (Array.isArray(state.repairPaymentMethods)) {
          payed += state.repairPaymentMethods.reduce((accumulator: number, current: RepairPaymentMethodDto) => accumulator + current.amount, 0);
        }
        if ((state.repairStatus === RepairStatus.InProcess || state.repairStatus === RepairStatus.Finished) && payed < state.repairPrice && Array.isArray(state.paymentsOnAccount)) {
          payed += state.paymentsOnAccount.reduce((accumulator: number, current: PaymentOnAccountDto) => accumulator + current.amount, 0);
        }
        if ((state.repairStatus === RepairStatus.InProcess || state.repairStatus === RepairStatus.Finished) && payed < state.repairPrice && Array.isArray(state.repair?.paymentsOnAccount)) {
          payed += state.repair!.paymentsOnAccount.reduce((accumulator: number, current: PaymentOnAccountDto) => accumulator + current.amount, 0);
        }
        state.pending = state.repairPrice - payed;
        if (state.pending > 0) {
          state.changeToClient = 0;
        } else {
          state.changeToClient = -state.pending;
          state.pending = 0;
        }
      } else {
        state.totalIva = 0;
        state.pending = 0;
        state.changeToClient = 0;
      }
    },
    setRequesting: (state: RepairState, action: PayloadAction<boolean>) => {
      state.requesting = action.payload;
    },
    setErrorProduct: (state: RepairState, action: PayloadAction<boolean>) => {
      state.errorProduct = action.payload;
    },
    setErrorCostPrice: (state: RepairState, action: PayloadAction<boolean>) => {
      state.errorCostPrice = action.payload;
    },
    setErrorRepairPrice: (state: RepairState, action: PayloadAction<boolean>) => {
      state.errorRepairPrice = action.payload;
    },
    setErrorTechnicalService: (state: RepairState, action: PayloadAction<boolean>) => {
      state.errorTechnicalService = action.payload;
    },
    setErrorCustomer: (state: RepairState, action: PayloadAction<boolean>) => {
      state.errorCustomer = action.payload;
    },
    setWarranty: (state: RepairState, action: PayloadAction<boolean | null>) => {
      if (!action.payload) {
        state.useStock = false;
      }
      state.warranty = action.payload;
    },
    setBudgetInStore: (state: RepairState, action: PayloadAction<boolean>) => {
      state.budgetInStore = action.payload;
    },
    setErrorBudget: (state: RepairState, action: PayloadAction<boolean>) => {
      state.errorBudget = action.payload;
    },
    setErrorItem: (state: RepairState, action: PayloadAction<boolean>) => {
      state.errorItem = action.payload;
    },
    setReceivedFromTechnicalService: (state: RepairState, action: PayloadAction<boolean>) => {
      state.receivedFromTechnicalService = action.payload;
    },
    setUseStock: (state: RepairState, action: PayloadAction<boolean>) => {
      state.useStock = action.payload;
    },
    setInternalReference: (state: RepairState, action: PayloadAction<string>) => {
      state.internalReference = action.payload;
    },
    setRepair: (state: RepairState, action: PayloadAction<Repair | null>) => {
      if (action.payload) {
        const repair: Repair = action.payload;
        state.repair = repair;
        state.errorRepair = false;
        // repairDto
        state.notes = repair.notes;
        state.sentTechnicalService = repair.sentTechnicalService;
        state.hasBudget = repair.hasBudget;
        state.acceptBudget = repair.acceptBudget;
        state.useBalance = repair.useBalance;
        state.amountBalance = repair.amountBalance;
        state.repairStatus = repair.repairStatus;
        state.costPrice = repair.costPrice;
        state.repairPrice = repair.repairPrice;
        state.totalIva = repair.totalIva;
        state.repairConcept = repair.repairConcept;
        state.productId = repair.productId;
        state.item = repair.item;
        state.storeId = repair.storeId;
        state.customerId = repair.customerId;
        state.customer = repair.customer;
        state.repairPaymentMethods = repair.repairPaymentMethods.map((repairPaymentMethod: RepairPaymentMethod) => ({
          repairId: repair.id,
          paymentMethodId: repairPaymentMethod.paymentMethodId,
          amount: repairPaymentMethod.amount,
        }));
        state.paymentsOnAccount = [];
        state.changeToClient = repair.changeToClient;
        state.changeInBalance = repair.changeInBalance;
        state.technicalServiceId = repair.technicalServiceId;
        state.warranty = repair.warranty;
        state.budgetInStore = repair.budgetInStore;
        state.receivedFromTechnicalService = repair.receivedFromTechnicalService;
        state.useStock = repair.useStock;
        state.internalReference = repair.internalReference;
        // Payment
        repairSlice.caseReducers.updatePaymentInfo(state);
      } else {
        state.errorRepair = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRepair.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      repairSlice.caseReducers.setRepair(state, action);
    });
    builder.addCase(updateRepair.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      if (action?.payload) {
        repairSlice.caseReducers.setRepair(state, action);
      }
    });
    builder.addCase(updateRepairWithWarranty.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      if (action?.payload) {
        repairSlice.caseReducers.setRepair(state, action);
      }
    });
    builder.addCase(updateRepairPrices.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      if (action?.payload) {
        repairSlice.caseReducers.setRepair(state, action);
      }
    });
    builder.addCase(updateRepairAcceptBudget.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      if (action?.payload) {
        repairSlice.caseReducers.setRepair(state, action);
      }
    });
    builder.addCase(updateRepairSentTechnicalService.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      if (action?.payload) {
        repairSlice.caseReducers.setRepair(state, action);
      }
    });
    builder.addCase(updateRepairReceivedFromTechnicalService.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      if (action?.payload) {
        repairSlice.caseReducers.setRepair(state, action);
      }
    });
    builder.addCase(updateRepairBudgetInStore.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      if (action?.payload) {
        repairSlice.caseReducers.setRepair(state, action);
      }
    });
    builder.addCase(deleteRepairImage.fulfilled, (state: RepairState, action: PayloadAction<RepairImage | null>) => {
      if (action.payload && state.repair) {
        const repairImage: RepairImage = action.payload;
        const repairImages: RepairImage[] = [...state.repair.images];
        const index: number = repairImages.findIndex((image: RepairImage) => image.id === repairImage.id);
        if (index !== -1) {
          repairImages.splice(index, 1);
        }
        state.repair.images = repairImages;
      }
    });
    builder.addCase(addRepairRecord.fulfilled, (state: RepairState, action: PayloadAction<RepairRecord | null>) => {
      if (action.payload && state.repair) {
        const repairRecord: RepairRecord = action.payload;
        const repair: Repair = Object.assign({}, state.repair);
        repair.repairRecords.push(repairRecord);
        state.repair = repair;
      }
    });
    builder.addCase(cancelRepair.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      if (action?.payload) {
        repairSlice.caseReducers.setRepair(state, action);
      }
    });
    builder.addCase(updateNotes.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      repairSlice.caseReducers.setRepair(state, action);
    });
    builder.addCase(deliverNewRepair.fulfilled, (state: RepairState, action: PayloadAction<Repair | null>) => {
      if (action?.payload) {
        repairSlice.caseReducers.setRepair(state, action);
      }
    });
  },
});

export default repairSlice.reducer;

export const repairSelector = (state: RootState) => state.repair;

export const {
  addImage,
  addImageBase64,
  removeImage,
  removeImageBase64,
  setRepair,
  setNotes,
  setSentTechnicalService,
  setHasBudget,
  setAcceptBudget,
  setUseBalance,
  setAmountBalance,
  setRepairStatus,
  setRepairPaymentMethodAmount,
  setPaymentsOnAccount,
  setCostPrice,
  setRepairPrice,
  setTotalIva,
  setRepairConcept,
  setProductId,
  setItem,
  setStoreId,
  setCustomer,
  setRepairPaymentMethods,
  setChangeToClient,
  setChangeInBalance,
  setTechnicalServiceId,
  reset,
  setErrorProduct,
  setErrorCostPrice,
  setErrorRepairPrice,
  setErrorTechnicalService,
  setErrorCustomer,
  setErrorBudget,
  setErrorItem,
  setWarranty,
  setBudgetInStore,
  setReceivedFromTechnicalService,
  setUseStock,
  setInternalReference,
} = repairSlice.actions;

export const repairSaga = function* () {
  yield takeLatest(repairSlice.actions.setCustomer, getCustomerBalance);
};
