import clsx from 'clsx';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Image, Plus } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ManualSaleProduct } from '../interfaces/manual-sale-product';
import { Sale } from '../interfaces/sale';
import { SaleProduct } from '../interfaces/sale-product';
import { formatNumber } from '../services/helpers';
import { saleSelector } from '../store/sale-slice';
import NoteModal from './note-modal';
import ProductUnitsInOrganization from './product-units-in-stores';

export const ReturnsProductsTable = () => {
  const { sale } = useSelector(saleSelector);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [selectedRelatedSale, setSelectedRelatedSale] = useState<Sale | null>(null);
  const organizationId: number = useMemo(() => {
    if (!sale) {
      return -1;
    }
    return sale.store.organizationId;
  }, [sale]);

  const onCloseNoteModal = () => {
    setSelectedRelatedSale(null);
    setShowNoteModal(false);
  };

  return (
    <React.Fragment>
      {sale?.relatedSales.map((relatedSale: Sale) => {
        let title = 'Venta original';
        if (relatedSale?.saleReturnId) {
          title = 'Devolución';
        } else if (relatedSale?.saleChangeId) {
          title = 'Cambio';
        }
        return (
          <div key={relatedSale.id} className="container-return my-4">
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="d-flex flex-column return-info">
                <span>{relatedSale.store.name}</span>
                <span>
                  {relatedSale.user.name} {relatedSale.user.surnames}
                </span>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-row mb-0">
                  <h1 className="mb-0 me-2">{title}</h1>
                  <Link to={`/venta/${relatedSale.id}`}>{relatedSale.internalId}</Link>
                </div>
                <span className="date">{moment(relatedSale.createdAt).format('YYYY-MM-DD HH:mm')}</span>
              </div>
              <div
                onClick={() => {
                  setSelectedRelatedSale(relatedSale);
                  setShowNoteModal(true);
                }}
                className={clsx('d-flex align-items-center button-header', { 'has-notes': relatedSale.notes != null && relatedSale.notes !== '' })}
              >
                {relatedSale.notes != null && relatedSale.notes !== '' ? (
                  '1 Nota'
                ) : (
                  <>
                    <Plus className="me-1" size={14} />
                    <span>Nota</span>
                  </>
                )}
              </div>
            </div>
            <div className="pos-products-table-container my-3">
              <table className="my-table noselect pos-products-table" style={{ maxWidth: '100%', tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th className="text-start" style={{ width: '200px' }}>
                      Producto
                    </th>
                    <th className="text-center" style={{ width: '65px' }}>
                      Precio
                    </th>
                    <th className="text-center" style={{ width: '35px' }}>
                      Uds.
                    </th>
                    <th className="" style={{ width: '65px' }}>
                      Dto.
                    </th>
                    <th className="text-center" style={{ width: '65px' }}>
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {relatedSale!.saleProducts.map((saleProduct: SaleProduct) => {
                    let manualSaleProduct: ManualSaleProduct | undefined = undefined;
                    if (saleProduct.manualSaleProductId) {
                      manualSaleProduct = relatedSale!.manualSaleProducts.find((msp: ManualSaleProduct) => msp.id === saleProduct.manualSaleProductId);
                    }
                    return (
                      <tr key={saleProduct.id}>
                        <td className="text-start">
                          <div className="d-flex align-items-center">
                            {saleProduct.product.images.length > 0 ? (
                              <span className="mobile-product-thumbnail">
                                <LazyLoadImage className="img-thumbnail me-1" src={process.env.REACT_APP_PUBLIC_URL + saleProduct.product.images[0].path} alt={saleProduct.product.name} width="50px" />
                              </span>
                            ) : (
                              <span className="mobile-product-thumbnail">
                                <Image className="img-thumbnail me-1" size={50} color="#808A95" />
                              </span>
                            )}
                            <div>
                              <div className="d-flex flex-column">
                                <div className="d-flex flex-row">
                                  <Link to={`/producto/${saleProduct.productId}`} className="link-sale me-2">
                                    <div className="name">{saleProduct.product.name || 'Sin nombre'}</div>
                                  </Link>
                                  <span>
                                    <ProductUnitsInOrganization organizationId={organizationId} productId={saleProduct.productId} />
                                  </span>
                                </div>
                                <div className="sku">{saleProduct.product.sku}</div>
                                {manualSaleProduct !== undefined && <div className="replacedBy">{manualSaleProduct.name}</div>}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="d-flex flex-column align-items-center noselect">
                            <div className="price">{formatNumber(saleProduct.pvp)}€</div>
                          </div>
                        </td>
                        <td className="text-center product-qty">
                          <span className="noselect units">{saleProduct.quantity}</span>
                        </td>
                        <td className="">
                          <span className={clsx('discount noselect text-center cursor-default', { 'with-value': saleProduct.discountPercentage > 0 })}>
                            {saleProduct.discountPercentage > 0 ? `-${formatNumber(saleProduct.discountPercentage)}%` : '-'}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className={clsx('total discount noselect cursor-default', { return: saleProduct.total < 0 })}>{formatNumber(saleProduct.total)}€</span>
                        </td>
                      </tr>
                    );
                  })}
                  {relatedSale!.manualSaleProducts.map((manualSaleProduct: ManualSaleProduct) => {
                    if (manualSaleProduct.replaced) {
                      return null;
                    }
                    return (
                      <tr key={manualSaleProduct.id}>
                        <td className="text-start">
                          <div className="name manual me-2">{manualSaleProduct.name}</div>
                        </td>
                        <td className="text-center">
                          <div className="d-flex flex-column align-items-center noselect">
                            <div className="price">{formatNumber(manualSaleProduct.pvp)}€</div>
                          </div>
                        </td>
                        <td className="text-center product-qty">
                          <span className="noselect units">{manualSaleProduct.quantity}</span>
                        </td>
                        <td className="">
                          <span className={clsx('discount noselect text-center cursor-default', { 'with-value': manualSaleProduct.discountPercentage > 0 })}>
                            {manualSaleProduct.discountPercentage > 0 ? `-${formatNumber(manualSaleProduct.discountPercentage)}%` : '-'}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className={clsx('total discount noselect cursor-default', { return: manualSaleProduct.total < 0 })}>{formatNumber(manualSaleProduct.total)}€</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
      {selectedRelatedSale && <NoteModal editable={false} show={showNoteModal} notes={selectedRelatedSale.notes} closeModal={onCloseNoteModal} saveNotes={() => {}} deleteNotes={() => {}} />}
    </React.Fragment>
  );
};
