import clsx from 'clsx';
import debounce from 'debounce-promise';
import { useEffect, useState } from 'react';
import { Loader, Plus, X } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { Role } from '../enums/role';
import { Organization } from '../interfaces/organization';
import { RawImage } from '../interfaces/raw-image';
import { RepairImage } from '../interfaces/repair-image';
import { Stock } from '../interfaces/stock';
import { TechnicalService } from '../interfaces/technical-service';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { useAppSelector } from '../store/hooks';
import {
  addImage,
  addImageBase64,
  deleteRepairImage,
  removeImage,
  removeImageBase64,
  repairSelector,
  setBudgetInStore,
  setCostPrice,
  setErrorBudget,
  setErrorCostPrice,
  setErrorProduct,
  setErrorRepairPrice,
  setErrorTechnicalService,
  setHasBudget,
  setInternalReference,
  setItem,
  setProductId,
  setRepairConcept,
  setRepairPrice,
  setTechnicalServiceId,
  setUseStock,
} from '../store/repair-slice';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';
import ConfirmModal from './confirm-modal';
import TechnicalServiceModal from './technical-service-modal';
import UploadRepairImageModal from './upload-repair-image-modal';

const promiseProductOptions = async (storeId: number, query: string): Promise<{ value: number; label: string }[]> => {
  if (query == null || query === '') {
    return [];
  }
  const stock: Stock[] = await api.getRepairStock(storeId, -1, -1, -1, -1, query);
  return stock.map((s: Stock) => {
    const parts: string[] = [s.product.name, s.product.sku];
    if (s.product.ean && s.product.ean.length > 0) {
      parts.push(s.product.ean);
    }
    return {
      label: parts.join(' - '),
      value: s.productId,
    };
  });
};

const debouncedLoadProductOptions = debounce((storeId: number, query: string) => promiseProductOptions(storeId, query), 1000);

const getStyles = (error: boolean) => ({
  control: (provided: any, state: any) => {
    return {
      ...provided,
      outline: 'none',
      borderStyle: 'none',
      fontSize: '14px',
      border: error ? '2px solid #e02760' : '1px solid #808a95',
      borderRadius: '7px',
      boxShadow: 'none',
      borderColor: error ? '#e02760' : state.isFocused ? '#555555' : provided.borderColor,
      '&:hover': {
        borderColor: error ? '#e02760' : state.isFocused ? '#555555' : provided.borderColor,
      },
      cursor: state.isDisabled ? 'not-allowed' : 'default',
      backgroundColor: state.isDisabled ? '#D3D3D3' : 'white',
    };
  },
  placeholder: (base: any, state: any) => ({
    ...base,
    color: error ? '#e02760' : '#d3d3d3',
  }),
  input: (base: any, state: any) => ({
    ...base,
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  indicatorSeparator: () => ({}),
  valueContainer: (provided: any) => ({
    ...provided,
    color: '#222E3D',
  }),
  menu: (provided: any) => {
    return {
      ...provided,
      boxShadow: 'none',
      borderStyle: 'none',
      color: 'white',
      backgroundColor: '#222E3D',
      fontFamily: 'Inter-Regular',
      fontSize: '12px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      marginTop: 0,
    };
  },
  option: (provided: any, state: any) => {
    let backgroundColor = '#222E3D';
    let color = 'white';
    if (state.isSelected) {
      backgroundColor = '#26C44B';
      color = 'white';
    }
    if (state.isFocused) {
      backgroundColor = '#D3D3D3';
      color = '#222E3D';
    }
    return {
      ...provided,
      color,
      backgroundColor,
      boxShadow: 'none',
      borderBottom: '0.1px solid #707070',
      cursor: state.isDisabled ? 'not-allowed' : 'default',
    };
  },
  menuList: (provided: any, state: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
});

interface Props {
  base64Images: string[];
  files: File[];
  setBase64Images: (base64Images: string[]) => void;
  setFiles: (files: File[]) => void;
}

const NewRepairProduct = ({ base64Images, files, setBase64Images, setFiles }: Props) => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const dispatch = useDispatch();
  const { selectedStoreId } = useSelector(storeSelector);
  const { errorProduct, errorTechnicalService, images, requesting, item, productId, warranty, hasBudget, budgetInStore, errorCostPrice, errorRepairPrice, errorBudget, useStock } =
    useSelector(repairSelector);
  const [selectedRepairImage, setSelectedRepairImage] = useState<RepairImage | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [technicalServices, setTechnicalServices] = useState<TechnicalService[]>([]);
  const [showTechnicalServiceModal, setShowTechnicalServiceModal] = useState<boolean>(false);
  const [showUploadRepairImageModal, setShowUploadRepairImageModal] = useState<boolean>(false);

  useEffect(() => {
    if (!organization) {
      return;
    }
    getTechnicalServices(organization.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const getTechnicalServices = async (organizationId: number) => {
    const tss: TechnicalService[] = await api.getTechnicalServices(organizationId);
    setTechnicalServices(tss);
  };

  const onCloseUploadRepairImageModal = (repairImage: RepairImage[] | RawImage | null) => {
    if (repairImage) {
      const pi: RawImage = repairImage as RawImage;
      if (pi.files !== null && pi.files.length > 0) {
        setFiles([...files, ...pi.files]);
        for (const file of pi.files) {
          dispatch(addImage(URL.createObjectURL(file)));
        }
      } else if (pi.dataUri) {
        setBase64Images([...base64Images, pi.dataUri]);
        dispatch(addImageBase64(pi.dataUri));
      }
    }
    setShowUploadRepairImageModal(false);
  };

  const onDeleteImage = (index: number) => {
    const newFiles: File[] = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    dispatch(removeImage(index));
  };

  const onDeleteImageBase64 = (index: number) => {
    const newBase64Images: string[] = [...base64Images];
    newBase64Images.splice(index, 1);
    setBase64Images(newBase64Images);
    dispatch(removeImageBase64(index));
  };

  const onCloseTechnicalServiceModal = (technicalService: TechnicalService | null) => {
    if (technicalService) {
      getTechnicalServices(organization.id);
    }
    setShowTechnicalServiceModal(false);
  };

  return (
    <div className="new-repair-product">
      <div className="d-flex flex-column mb-3">
        <div className={clsx('subtitle mb-3')}>Referencia interna</div>
        <input className={clsx('input-size')} type="text" placeholder="Referencia interna" onChange={(e: any) => dispatch(setInternalReference(e.target.value))} disabled={requesting} />
      </div>
      {(item === null || item.length === 0) && (
        <div className="d-flex flex-column mb-3">
          <div className={clsx('subtitle mb-3', { error: errorProduct })}>Producto</div>
          <AsyncSelect
            isDisabled={requesting}
            cacheOptions
            placeholder="Buscar producto en stock"
            defaultOptions={[]}
            styles={getStyles(errorProduct)}
            isClearable
            loadingMessage={() => 'Buscando productos...'}
            noOptionsMessage={({ inputValue }) => (!inputValue || inputValue === '' ? 'Introduce el texto' : 'No hay productos')}
            loadOptions={(query: string) => debouncedLoadProductOptions(selectedStoreId, query)}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              LoadingIndicator: () => (
                <div className="pe-3">
                  <Loader type="TailSpin" color="#252E3C" height={24} width={24} />
                </div>
              ),
            }}
            onInputChange={() => dispatch(setErrorProduct(false))}
            onChange={(option: any) => {
              dispatch(setErrorProduct(false));
              if (option == null) {
                dispatch(setProductId(-1));
                return;
              }
              dispatch(setProductId(option.value));
            }}
          />
          {(warranty || productId !== -1) && (
            <div className="mt-3 d-flex flex-row align-items-center use-stock">
              <input checked={useStock} type="checkbox" onChange={(e) => dispatch(setUseStock(e.target.checked))} />
              <label>Restar stock</label>
            </div>
          )}
        </div>
      )}
      {!warranty && productId === -1 && (
        <div className="d-flex flex-column my-2">
          <div className={clsx('subtitle mb-3', { error: errorProduct })}>Nombre del nuevo producto</div>
          <input
            className={clsx('input-size', { error: errorProduct })}
            type="text"
            placeholder="Reloj Casio modelo X"
            onChange={(e: any) => {
              dispatch(setErrorProduct(false));
              dispatch(setItem(e.target.value));
            }}
            disabled={requesting}
          />
        </div>
      )}
      <div className="d-flex flex-column mb-3">
        <div className="subtitle mb-3">Descripción de la reparación</div>
        <textarea placeholder="Texto" rows={10} onChange={(e: any) => dispatch(setRepairConcept(e.target.value))} disabled={requesting}></textarea>
      </div>

      {!warranty && (
        <div className="row my-4">
          <div className="col">
            <div className={clsx('container-budget d-flex flex-row align-items-center', { error: errorBudget })}>
              <input
                type="checkbox"
                checked={hasBudget}
                onChange={(e) => {
                  const checked: boolean = e.target.checked;
                  dispatch(setErrorBudget(false));
                  dispatch(setHasBudget(checked));
                  if (checked) {
                    dispatch(setBudgetInStore(false));
                  }
                }}
              />
              <label>Necesita presupuesto</label>
            </div>
          </div>
          <div className="col">
            <div className={clsx('container-budget d-flex flex-row align-items-center', { error: errorBudget })}>
              <input
                type="checkbox"
                checked={budgetInStore}
                onChange={(e) => {
                  const checked: boolean = e.target.checked;
                  dispatch(setErrorBudget(false));
                  dispatch(setBudgetInStore(checked));
                  if (checked) {
                    dispatch(setHasBudget(false));
                  }
                }}
              />
              <label>Presupuesto en tienda</label>
            </div>
          </div>
        </div>
      )}

      {budgetInStore && (
        <div className="d-flex flex-column mb-4">
          <div className="row">
            <div className="col">
              <div className={clsx('subtitle mb-3', { error: errorCostPrice })}>Precio coste</div>
              <input
                disabled={requesting}
                className={clsx('input-size', { error: errorCostPrice })}
                type="number"
                placeholder="0.00€"
                step={0.01}
                onChange={(e: any) => {
                  dispatch(setErrorCostPrice(false));
                  if (e.target.value && e.target.value.length > 0) {
                    dispatch(setCostPrice(parseFloat(e.target.value)));
                  } else {
                    dispatch(setCostPrice(0));
                  }
                }}
              />
            </div>
            <div className="col">
              <div className={clsx('subtitle mb-3', { error: errorRepairPrice })}>Precio reparación</div>
              <input
                disabled={requesting}
                className={clsx('input-size', { error: errorRepairPrice })}
                type="number"
                placeholder="0.00€"
                step={0.01}
                onChange={(e: any) => {
                  dispatch(setErrorRepairPrice(false));
                  if (e.target.value && e.target.value.length > 0) {
                    dispatch(setRepairPrice(parseFloat(e.target.value)));
                  } else {
                    dispatch(setRepairPrice(0));
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}

      {!budgetInStore && (
        <div className="d-flex flex-column mb-3">
          <div className="d-flex flex-row justify-content-between">
            <div className={clsx('subtitle mb-4', { error: errorTechnicalService })}>Servicio técnico</div>
            {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
              <button className="create-technical-service" onClick={() => setShowTechnicalServiceModal(true)}>
                Crear servicio técnico
              </button>
            )}
          </div>
          {technicalServices.length === 0 ? (
            <label>No hay servicios técnicos</label>
          ) : (
            <select
              disabled={requesting}
              className={clsx('input-size', { error: errorTechnicalService })}
              name="technicalService"
              onChange={(e: any) => {
                dispatch(setErrorTechnicalService(false));
                dispatch(setTechnicalServiceId(parseInt(e.target.value, 10)));
              }}
            >
              <option value={-1}>Selecciona un servicio técnico</option>
              {technicalServices.map((technicalService: TechnicalService) => (
                <option key={technicalService.id} value={technicalService.id}>
                  {technicalService.name}
                </option>
              ))}
            </select>
          )}
        </div>
      )}

      <div className="d-flex flex-column my-2">
        <div className="subtitle mb-3">Imágenes</div>
        <div className="d-flex flex-wrap">
          <LightgalleryProvider>
            {images.map((image: string, index: number) => (
              <div className="position-relative container-image d-flex align-items-center justify-content-center me-2 mb-2" key={index}>
                <LightgalleryItem group="group-product-images" src={image}>
                  <LazyLoadImage className="product-image" src={image} alt="" />
                </LightgalleryItem>
                <span className="delete-image" onClick={() => onDeleteImage(index)}>
                  <X color="white" size="15" />
                </span>
              </div>
            ))}
            {base64Images.map((base64Image: string, index: number) => (
              <div className="position-relative container-image d-flex align-items-center justify-content-center me-2 mb-2" key={index}>
                <LightgalleryItem group="group-product-images" src={base64Image}>
                  <LazyLoadImage className="product-image" src={base64Image} alt="" />
                </LightgalleryItem>
                <span className="delete-image" onClick={() => onDeleteImageBase64(index)}>
                  <X color="white" size="15" />
                </span>
              </div>
            ))}
          </LightgalleryProvider>
          <div
            className={clsx('container-image d-flex align-items-center justify-content-center me-2', { clickable: !requesting })}
            title="Subir imágenes del producto"
            onClick={() => {
              if (requesting) {
                return;
              }
              setShowUploadRepairImageModal(true);
            }}
          >
            <Plus color="#252e3c" size="30" />
          </div>
        </div>
      </div>
      <TechnicalServiceModal show={showTechnicalServiceModal} closeModal={onCloseTechnicalServiceModal} technicalService={null} />
      {selectedRepairImage && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Imagen"
          content={`¿Estás seguro que quieres eliminar la imagen?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              dispatch(deleteRepairImage(selectedRepairImage));
            }
            setSelectedRepairImage(null);
          }}
        ></ConfirmModal>
      )}
      <UploadRepairImageModal closeModal={onCloseUploadRepairImageModal} show={showUploadRepairImageModal} />
    </div>
  );
};

export default NewRepairProduct;
