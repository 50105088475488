import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { Row } from 'react-table';
import Table from '../components/table';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { OnlineStoreSynchronization } from '../interfaces/online-store-synchronization';
import { api } from '../services/api';
import myToastr from '../services/toastr';

const OnlineStoreSynchronizationView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [onlineStoreSynchronization, setOnlineStoreSynchronization] = useState<OnlineStoreSynchronization | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [requesting, setRequesting] = useState<boolean>(false);

  useEffect(() => {
    if (!params.id) {
      return;
    }
    const getData = async () => {
      try {
        setRequesting(true);
        const id: number = parseInt(params.id as string, 10);
        const oss: OnlineStoreSynchronization = await api.getOnlineStoreSynchronization(id);
        setOnlineStoreSynchronization(oss);
        setRequesting(false);
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
        navigate('/sincronizaciones');
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Fecha',
        accessor: 'createdAt',
        Cell: ({ value }: any) => {
          return moment(value).format('DD/MM/YYYY HH:mm:ss');
        },
        filter: 'fuzzyText',
      },
      {
        Header: 'Sku',
        accessor: 'product.sku',
        filter: 'fuzzyText',
      },
      {
        Header: 'Producto',
        accessor: 'product.name',
        filter: 'fuzzyText',
      },
      {
        Header: 'Cantidad',
        accessor: 'quantity',
        disableFilters: true,
      },
      {
        Header: 'Activo',
        accessor: 'active',
        Cell: ({ value }: any) => {
          return value ? 'Si' : 'No';
        },
        disableFilters: true,
      },
      {
        Header: 'Mensaje',
        accessor: 'message',
        filter: 'fuzzyText',
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineStoreSynchronization?.synchronizationErrors]) as any;

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      data.push({
        Fecha: moment(fr.original.createdAt).format('DD/MM/YYYY HH:mm'),
        Producto: `${fr.original.product.sku} ${fr.original.product.name}`,
        Cantidad: fr.original.quantity,
        Activo: fr.original.active ? 'Si' : 'No',
        Mensaje: fr.original.message,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="brands p-4">
      <div className="d-flex flex-row align-items-center mb-4">
        <div className="d-flex flex-column flex-grow-1">
          <h1 className="">Detalles de la sincronización</h1>
          {onlineStoreSynchronization !== null && (
            <div className="">
              <span className="me-4">
                Tienda online: <strong>{onlineStoreSynchronization.onlineStore.name}</strong>
              </span>
              <span className="me-4">
                Usuario:{' '}
                <strong>
                  {onlineStoreSynchronization.user.name} {onlineStoreSynchronization.user.surnames}
                </strong>
              </span>
              {onlineStoreSynchronization.finishedAt && (
                <span className="me-4">
                  Fecha: <strong>{moment(onlineStoreSynchronization.finishedAt).format('DD/MM/YYYY HH:mm:ss')}</strong>
                </span>
              )}
            </div>
          )}
        </div>
        {filteredRows.length > 0 && (
          <CSVLink filename="sincronizaciones_tiendas_online.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
      </div>
      <Table
        data={onlineStoreSynchronization?.synchronizationErrors || []}
        columns={columns}
        noDataMessage="No hay errores"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
      ></Table>
    </div>
  );
};

export default OnlineStoreSynchronizationView;
