import clsx from 'clsx';
import debounce from 'debounce-promise';
import moment from 'moment';
import printJS from 'print-js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { ChevronLeft, Download, File, Plus, Printer, X } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '../components/confirm-modal';
import CustomerInfo from '../components/customer-info';
import CustomerModal from '../components/customer-modal';
import CustomersFinder from '../components/customers-finder';
import NoteModal from '../components/note-modal';
import { InvoiceStatus } from '../enums/invoice-status';
import { InvoiceType } from '../enums/invoice-type';
import { Order } from '../enums/order';
import { Customer } from '../interfaces/customer';
import { GetPaginatedStockOrganization } from '../interfaces/get-paginated-stock-organization';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Invoice } from '../interfaces/invoice';
import { InvoiceProduct } from '../interfaces/invoice-product';
import { InvoiceDto } from '../interfaces/invoice.dto';
import { ManualInvoiceProduct } from '../interfaces/manual-invoice-product';
import { Organization } from '../interfaces/organization';
import { PaginatedDto } from '../interfaces/paginated.dto';
import { Product } from '../interfaces/product';
import { Stock } from '../interfaces/stock';
import { api } from '../services/api';
import { formatNumber, roundTwoDecimals } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

interface ExtendedInvoiceProductDto {
  productId: number;
  units: number | null;
  amount: number | null;
  equivalenceSurcharge: number;
  discount: number | null;
  vatPercentage: number | null;
  total: number;
  product: Product | null;
  hasErrorUnits: boolean;
  hasErrorAmount: boolean;
  hasErrorVatPercentage: boolean;
}

interface ExtendedManualInvoiceProductDto {
  name: string;
  units: number | null;
  amount: number | null;
  equivalenceSurcharge: number;
  discount: number | null;
  vatPercentage: number | null;
  total: number;
  hasErrorUnits: boolean;
  hasErrorAmount: boolean;
  hasErrorVatPercentage: boolean;
}

interface NewInvoiceProductDto {
  productId: number;
  name: string;
  units: number | null;
  amount: number | null;
  equivalenceSurcharge: number;
  discount: number | null;
  vatPercentage: number | null;
  total: number;
  product: Product | null;
  hasErrorUnits: boolean;
  hasErrorAmount: boolean;
  hasErrorVatPercentage: boolean;
}

interface ExtendedInvoiceDto {
  observations: string;
  date: string;
  invoiceType: InvoiceType;
  invoiceStatus: InvoiceStatus;
  storeId: number;
  customerId: number;
  customer: Customer | null;
  customerName: string;
  invoiceProducts: ExtendedInvoiceProductDto[];
  manualInvoiceProducts: ExtendedManualInvoiceProductDto[];
  notes: string;
}

interface InvoiceTotals {
  units: number;
  taxBase: number;
  discount: number;
  equivalenceSurcharge: number;
  vat: number;
  total: number;
}

const debouncedStock = debounce(async (organizationId: number, query: string) => {
  try {
    const getPaginatedStockOrganization: GetPaginatedStockOrganization = {
      orderField: 'product.name',
      order: Order.Asc,
      limit: 50,
      page: 1,
      query,
      organizationId,
      storeId: -1,
      brandId: -1,
      categoryId: -1,
    };
    const paginatedDto: PaginatedDto<Stock> = await api.getStockOfOrganization(getPaginatedStockOrganization);
    return paginatedDto.results;
  } catch (e) {
    console.error(e);
    return [];
  }
}, 1000);

const InvoiceView = () => {
  const customerSelectRef = useRef<any>(null);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedStoreId } = useSelector(storeSelector);
  const [stock, setStock] = useState<Stock[]>([]);
  const [query, setQuery] = useState<string>('');
  const [requesting, setRequesting] = useState<boolean>(false);
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [extendedInvoiceDto, setExtendedInvoiceDto] = useState<ExtendedInvoiceDto>({
    observations: '',
    date: moment().format('YYYY-MM-DD'),
    invoiceType: (location.state as any)?.invoiceType ? (location.state as any).invoiceType : InvoiceType.Normal,
    invoiceStatus: InvoiceStatus.Pending,
    storeId: selectedStoreId,
    customerId: -1,
    customer: null,
    customerName: '',
    invoiceProducts: [],
    manualInvoiceProducts: [],
    notes: '',
  });
  const [newInvoiceProductDto, setNewInvoiceProductDto] = useState<NewInvoiceProductDto>({
    productId: -1,
    name: '',
    units: null,
    amount: null,
    equivalenceSurcharge: 0,
    discount: null,
    vatPercentage: parseFloat(process.env.REACT_APP_IVA!),
    total: 0,
    product: null,
    hasErrorUnits: false,
    hasErrorAmount: false,
    hasErrorVatPercentage: false,
  });
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [requestingPdf, setRequestingPdf] = useState<boolean>(false);
  const [requestingXml, setRequestingXml] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const invoiceTotals: InvoiceTotals = useMemo(() => {
    const it: InvoiceTotals = {
      units: 0,
      taxBase: 0,
      discount: 0,
      equivalenceSurcharge: 0,
      vat: 0,
      total: 0,
    };
    extendedInvoiceDto.invoiceProducts.forEach((extendedInvoiceProductDto: ExtendedInvoiceProductDto) => {
      const units: number = extendedInvoiceProductDto.units !== null ? extendedInvoiceProductDto.units! : 0;
      const amount: number = extendedInvoiceProductDto.amount !== null ? extendedInvoiceProductDto.amount! : 0;
      const discount: number = extendedInvoiceProductDto.discount !== null ? extendedInvoiceProductDto.discount! : 0;
      const vatPercentage: number = extendedInvoiceProductDto.vatPercentage !== null ? extendedInvoiceProductDto.vatPercentage! : 0;
      const taxBase: number = amount * units;
      const discountAmount: number = (taxBase * discount) / 100;
      const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * extendedInvoiceProductDto.equivalenceSurcharge) / 100;
      const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
      it.units += units;
      it.taxBase += taxBase;
      it.discount += discountAmount;
      it.equivalenceSurcharge += equivalenceSurchargeAmount;
      it.vat += vatAmount;
      it.total += taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount;
    });
    extendedInvoiceDto.manualInvoiceProducts.forEach((extendedManualInvoiceProductDto: ExtendedManualInvoiceProductDto) => {
      const units: number = extendedManualInvoiceProductDto.units !== null ? extendedManualInvoiceProductDto.units! : 0;
      const amount: number = extendedManualInvoiceProductDto.amount !== null ? extendedManualInvoiceProductDto.amount! : 0;
      const discount: number = extendedManualInvoiceProductDto.discount !== null ? extendedManualInvoiceProductDto.discount! : 0;
      const vatPercentage: number = extendedManualInvoiceProductDto.vatPercentage !== null ? extendedManualInvoiceProductDto.vatPercentage! : 0;
      const taxBase: number = amount * units;
      const discountAmount: number = (taxBase * discount) / 100;
      const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * extendedManualInvoiceProductDto.equivalenceSurcharge) / 100;
      const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
      it.units += units;
      it.taxBase += taxBase;
      it.discount += discountAmount;
      it.equivalenceSurcharge += equivalenceSurchargeAmount;
      it.vat += vatAmount;
      it.total += taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount;
    });
    return {
      units: it.units,
      taxBase: roundTwoDecimals(it.taxBase),
      discount: roundTwoDecimals(it.discount),
      equivalenceSurcharge: roundTwoDecimals(it.equivalenceSurcharge),
      vat: roundTwoDecimals(it.vat),
      total: roundTwoDecimals(it.total),
    };
  }, [extendedInvoiceDto]);
  const isAddProductDisabled = useMemo(() => {
    if (newInvoiceProductDto.productId === -1 && newInvoiceProductDto.name === '') {
      return true;
    }
    if (newInvoiceProductDto.units === null || newInvoiceProductDto.units <= 0) {
      return true;
    }
    if (extendedInvoiceDto.invoiceType === InvoiceType.Normal) {
      if (newInvoiceProductDto.amount === null || newInvoiceProductDto.amount <= 0) {
        return true;
      }
    } else {
      if (newInvoiceProductDto.amount === null || newInvoiceProductDto.amount >= 0) {
        return true;
      }
    }
    if (newInvoiceProductDto.vatPercentage === null || newInvoiceProductDto.vatPercentage <= 0) {
      return true;
    }
    return false;
  }, [newInvoiceProductDto, extendedInvoiceDto]);
  const disableButton: boolean = useMemo(() => {
    if (!extendedInvoiceDto.customer) {
      return true;
    }
    if (extendedInvoiceDto.invoiceProducts.length === 0 && extendedInvoiceDto.manualInvoiceProducts.length === 0) {
      return true;
    }
    return false;
  }, [extendedInvoiceDto]);

  useEffect(() => {
    if (!params.id) {
      return;
    }
    const id: number = parseInt(params.id);
    const getInvoice = async () => {
      try {
        const i: Invoice = await api.getInvoice(id);
        setInvoice(i);
        setExtendedInvoiceDto({
          observations: i.observations,
          date: moment(i.date).format('YYYY-MM-DD'),
          invoiceType: i.invoiceType,
          invoiceStatus: i.invoiceStatus,
          storeId: i.storeId,
          customerId: i.customerId,
          customer: i.customer,
          customerName: i.customer.name,
          invoiceProducts: i.invoiceProducts.map((invoiceProduct: InvoiceProduct) => ({
            productId: invoiceProduct.productId,
            units: invoiceProduct.units,
            amount: invoiceProduct.amount,
            equivalenceSurcharge: invoiceProduct.equivalenceSurcharge,
            discount: invoiceProduct.discount,
            vatPercentage: invoiceProduct.vatPercentage,
            total: invoiceProduct.total,
            product: invoiceProduct.product,
            hasErrorUnits: false,
            hasErrorAmount: false,
            hasErrorVatPercentage: false,
          })),
          manualInvoiceProducts: i.manualInvoiceProducts.map((manualInvoiceProduct: ManualInvoiceProduct) => ({
            name: manualInvoiceProduct.name,
            units: manualInvoiceProduct.units,
            amount: manualInvoiceProduct.amount,
            equivalenceSurcharge: manualInvoiceProduct.equivalenceSurcharge,
            discount: manualInvoiceProduct.discount,
            vatPercentage: manualInvoiceProduct.vatPercentage,
            total: manualInvoiceProduct.total,
            hasErrorUnits: false,
            hasErrorAmount: false,
            hasErrorVatPercentage: false,
          })),
          notes: i.notes,
        });
      } catch (e: any) {
        const httpExceptionDto: HttpExceptionDto = e.response.data;
        myToastr.error(httpExceptionDto.message);
        navigate('/facturas');
      }
    };
    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (invoice !== null) {
      return;
    }
    if (selectedStoreId) {
      setExtendedInvoiceDto({ ...extendedInvoiceDto, storeId: selectedStoreId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId]);

  useEffect(() => {
    if (!query) {
      setStock([]);
      return;
    }
    getStock(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const getStock = async (query: string) => {
    debouncedStock(organization.id, query).then((response: Stock[]) => {
      const map: Map<number, Stock> = new Map<number, Stock>();
      response.forEach((stock: Stock) => {
        if (map.has(stock.product.id)) {
          // Prevalece el stock de la tienda
          if (stock.store.id === selectedStoreId) {
            map.set(stock.product.id, stock);
          }
        } else {
          map.set(stock.product.id, stock);
        }
      });
      const distinctStock: Stock[] = Array.from(map.values()).sort((a: Stock, b: Stock) => a.product.name.localeCompare(b.product.name));
      setStock(distinctStock);
    });
  };

  // PRODUCTOS BD

  const removeProduct = (index: number) => {
    const invoiceProducts = [...extendedInvoiceDto.invoiceProducts];
    invoiceProducts.splice(index, 1);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, invoiceProducts });
  };

  const onChangeUnits = (index: number, newUnits: number | null) => {
    const invoiceProducts = [...extendedInvoiceDto.invoiceProducts];
    if (newUnits === null) {
      invoiceProducts[index].units = null;
      invoiceProducts[index].hasErrorUnits = true;
    } else {
      invoiceProducts[index].units = newUnits;
      invoiceProducts[index].hasErrorUnits = false;
    }
    const amount: number = invoiceProducts[index].amount !== null ? invoiceProducts[index].amount! : 0;
    const discount: number = invoiceProducts[index].discount !== null ? invoiceProducts[index].discount! : 0;
    const vatPercentage: number = invoiceProducts[index].vatPercentage !== null ? invoiceProducts[index].vatPercentage! : 0;
    const taxBase: number = amount * (invoiceProducts[index].units || 0);
    const discountAmount: number = taxBase * (discount / 100);
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * invoiceProducts[index].equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    invoiceProducts[index].total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, invoiceProducts });
  };

  const onChangeAmount = (index: number, newAmount: number | null) => {
    const invoiceProducts = [...extendedInvoiceDto.invoiceProducts];
    if (newAmount === null) {
      invoiceProducts[index].amount = null;
      invoiceProducts[index].hasErrorAmount = true;
      invoiceProducts[index].total = 0;
    } else {
      invoiceProducts[index].amount = newAmount;
      if (extendedInvoiceDto.invoiceType === InvoiceType.Normal) {
        invoiceProducts[index].hasErrorAmount = newAmount <= 0;
      } else {
        invoiceProducts[index].hasErrorAmount = newAmount >= 0;
      }
    }
    const units: number = invoiceProducts[index].units !== null ? invoiceProducts[index].units! : 0;
    const discount: number = invoiceProducts[index].discount !== null ? invoiceProducts[index].discount! : 0;
    const vatPercentage: number = invoiceProducts[index].vatPercentage !== null ? invoiceProducts[index].vatPercentage! : 0;
    const taxBase: number = (invoiceProducts[index].amount || 0) * units;
    const discountAmount: number = taxBase * (discount / 100);
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * invoiceProducts[index].equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    invoiceProducts[index].total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, invoiceProducts });
  };

  const onChangeDiscount = (index: number, newDiscount: number | null) => {
    const invoiceProducts = [...extendedInvoiceDto.invoiceProducts];
    if (newDiscount === null) {
      invoiceProducts[index].discount = null;
    } else {
      invoiceProducts[index].discount = newDiscount;
    }
    const units: number = invoiceProducts[index].units !== null ? invoiceProducts[index].units! : 0;
    const discount: number = newDiscount || 0;
    const vatPercentage: number = invoiceProducts[index].vatPercentage !== null ? invoiceProducts[index].vatPercentage! : 0;
    const taxBase: number = (invoiceProducts[index].amount || 0) * units;
    const discountAmount: number = taxBase * (discount / 100);
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * invoiceProducts[index].equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    invoiceProducts[index].total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, invoiceProducts });
  };

  const onChangeVatPercentage = (index: number, newVatPercentage: number | null) => {
    const invoiceProducts = [...extendedInvoiceDto.invoiceProducts];
    if (newVatPercentage === null) {
      invoiceProducts[index].vatPercentage = null;
      invoiceProducts[index].hasErrorVatPercentage = true;
    } else {
      invoiceProducts[index].vatPercentage = newVatPercentage;
      invoiceProducts[index].hasErrorVatPercentage = false;
    }
    const units: number = invoiceProducts[index].units !== null ? invoiceProducts[index].units! : 0;
    const discount: number = invoiceProducts[index].discount !== null ? invoiceProducts[index].discount! : 0;
    const vatPercentage: number = newVatPercentage || 0;
    const taxBase: number = (invoiceProducts[index].amount || 0) * units;
    const discountAmount: number = taxBase * (discount / 100);
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * invoiceProducts[index].equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    invoiceProducts[index].total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, invoiceProducts });
  };

  const onChangeEquivalenceSurcharge = (index: number, equivalenceSurcharge: boolean) => {
    const invoiceProducts = [...extendedInvoiceDto.invoiceProducts];
    invoiceProducts[index].equivalenceSurcharge = equivalenceSurcharge ? parseFloat(process.env.REACT_APP_EQUIVALENCE_SURCHARGE!) : 0;
    const amount: number = invoiceProducts[index].amount !== null ? invoiceProducts[index].amount! : 0;
    const units: number = invoiceProducts[index].units !== null ? invoiceProducts[index].units! : 0;
    const discount: number = invoiceProducts[index].discount !== null ? invoiceProducts[index].discount! : 0;
    const vatPercentage: number = invoiceProducts[index].vatPercentage !== null ? invoiceProducts[index].vatPercentage! : 0;
    const taxBase: number = amount * units;
    const discountAmount: number = taxBase * (discount / 100);
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * invoiceProducts[index].equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    invoiceProducts[index].total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, invoiceProducts });
  };

  // PRODUCTOS MANUALES

  const removeManualProduct = (index: number) => {
    const manualInvoiceProducts = [...extendedInvoiceDto.manualInvoiceProducts];
    manualInvoiceProducts.splice(index, 1);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, manualInvoiceProducts });
  };

  const onChangeManualUnits = (index: number, newUnits: number | null) => {
    const manualInvoiceProducts = [...extendedInvoiceDto.manualInvoiceProducts];
    if (newUnits === null) {
      manualInvoiceProducts[index].units = null;
      manualInvoiceProducts[index].hasErrorUnits = true;
    } else {
      manualInvoiceProducts[index].units = newUnits;
      manualInvoiceProducts[index].hasErrorUnits = false;
    }
    const amount: number = manualInvoiceProducts[index].amount !== null ? manualInvoiceProducts[index].amount! : 0;
    const discount: number = manualInvoiceProducts[index].discount !== null ? manualInvoiceProducts[index].discount! : 0;
    const vatPercentage: number = manualInvoiceProducts[index].vatPercentage !== null ? manualInvoiceProducts[index].vatPercentage! : 0;
    const taxBase: number = amount * (manualInvoiceProducts[index].units || 0);
    const discountAmount: number = taxBase * (discount / 100);
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * manualInvoiceProducts[index].equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    manualInvoiceProducts[index].total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, manualInvoiceProducts });
  };

  const onChangeManualAmount = (index: number, newAmount: number | null) => {
    const manualInvoiceProducts = [...extendedInvoiceDto.manualInvoiceProducts];
    if (newAmount === null) {
      manualInvoiceProducts[index].amount = null;
      manualInvoiceProducts[index].hasErrorAmount = true;
    } else {
      manualInvoiceProducts[index].amount = newAmount;
      if (extendedInvoiceDto.invoiceType === InvoiceType.Normal) {
        manualInvoiceProducts[index].hasErrorAmount = newAmount <= 0;
      } else {
        manualInvoiceProducts[index].hasErrorAmount = newAmount >= 0;
      }
    }
    const units: number = manualInvoiceProducts[index].units !== null ? manualInvoiceProducts[index].units! : 0;
    const discount: number = manualInvoiceProducts[index].discount !== null ? manualInvoiceProducts[index].discount! : 0;
    const vatPercentage: number = manualInvoiceProducts[index].vatPercentage !== null ? manualInvoiceProducts[index].vatPercentage! : 0;
    const taxBase: number = (manualInvoiceProducts[index].amount || 0) * units;
    const discountAmount: number = taxBase * (discount / 100);
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * manualInvoiceProducts[index].equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    manualInvoiceProducts[index].total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, manualInvoiceProducts });
  };

  const onChangeManualDiscount = (index: number, newDiscount: number | null) => {
    const manualInvoiceProducts = [...extendedInvoiceDto.manualInvoiceProducts];
    if (newDiscount === null) {
      manualInvoiceProducts[index].discount = null;
    } else {
      manualInvoiceProducts[index].discount = newDiscount;
    }
    const units: number = manualInvoiceProducts[index].units !== null ? manualInvoiceProducts[index].units! : 0;
    const discount: number = newDiscount || 0;
    const vatPercentage: number = manualInvoiceProducts[index].vatPercentage !== null ? manualInvoiceProducts[index].vatPercentage! : 0;
    const taxBase: number = (manualInvoiceProducts[index].amount || 0) * units;
    const discountAmount: number = taxBase * (discount / 100);
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * manualInvoiceProducts[index].equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    manualInvoiceProducts[index].total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, manualInvoiceProducts });
  };

  const onChangeManualVatPercentage = (index: number, newVatPercentage: number | null) => {
    const manualInvoiceProducts = [...extendedInvoiceDto.manualInvoiceProducts];
    if (newVatPercentage === null) {
      manualInvoiceProducts[index].vatPercentage = null;
      manualInvoiceProducts[index].hasErrorVatPercentage = true;
    } else {
      manualInvoiceProducts[index].vatPercentage = newVatPercentage;
      manualInvoiceProducts[index].hasErrorVatPercentage = false;
    }
    const units: number = manualInvoiceProducts[index].units !== null ? manualInvoiceProducts[index].units! : 0;
    const discount: number = manualInvoiceProducts[index].discount !== null ? manualInvoiceProducts[index].discount! : 0;
    const vatPercentage: number = newVatPercentage || 0;
    const taxBase: number = (manualInvoiceProducts[index].amount || 0) * units;
    const discountAmount: number = taxBase * (discount / 100);
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * manualInvoiceProducts[index].equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    manualInvoiceProducts[index].total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, manualInvoiceProducts });
  };

  const onChangeManualEquivalenceSurcharge = (index: number, equivalenceSurcharge: boolean) => {
    const manualInvoiceProducts = [...extendedInvoiceDto.manualInvoiceProducts];
    manualInvoiceProducts[index].equivalenceSurcharge = equivalenceSurcharge ? parseFloat(process.env.REACT_APP_EQUIVALENCE_SURCHARGE!) : 0;
    const amount: number = manualInvoiceProducts[index].amount !== null ? manualInvoiceProducts[index].amount! : 0;
    const units: number = manualInvoiceProducts[index].units !== null ? manualInvoiceProducts[index].units! : 0;
    const discount: number = manualInvoiceProducts[index].discount !== null ? manualInvoiceProducts[index].discount! : 0;
    const vatPercentage: number = manualInvoiceProducts[index].vatPercentage !== null ? manualInvoiceProducts[index].vatPercentage! : 0;
    const taxBase: number = amount * units;
    const discountAmount: number = taxBase * (discount / 100);
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * manualInvoiceProducts[index].equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    manualInvoiceProducts[index].total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setExtendedInvoiceDto({ ...extendedInvoiceDto, manualInvoiceProducts });
  };

  // NEW PRODUCT

  const removeNewProduct = () => {
    setNewInvoiceProductDto({
      productId: -1,
      name: '',
      units: null,
      amount: null,
      equivalenceSurcharge: 0,
      discount: null,
      vatPercentage: parseFloat(process.env.REACT_APP_IVA!),
      total: 0,
      product: null,
      hasErrorUnits: false,
      hasErrorAmount: false,
      hasErrorVatPercentage: false,
    });
    setQuery('');
  };

  const onChangeNewInvoiceProductUnits = (newUnits: number | null) => {
    const copyNewInvoiceProductDto = { ...newInvoiceProductDto };
    if (newUnits === null) {
      copyNewInvoiceProductDto.units = null;
      copyNewInvoiceProductDto.hasErrorUnits = true;
    } else {
      copyNewInvoiceProductDto.units = newUnits;
      copyNewInvoiceProductDto.hasErrorUnits = false;
    }
    const amount: number = copyNewInvoiceProductDto.amount !== null ? copyNewInvoiceProductDto.amount! : 0;
    const discount: number = copyNewInvoiceProductDto.discount !== null ? copyNewInvoiceProductDto.discount! : 0;
    const vatPercentage: number = copyNewInvoiceProductDto.vatPercentage !== null ? copyNewInvoiceProductDto.vatPercentage! : 0;
    const taxBase: number = amount * (newUnits || 0);
    const discountAmount: number = (taxBase * discount) / 100;
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * copyNewInvoiceProductDto.equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    copyNewInvoiceProductDto.total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setNewInvoiceProductDto(copyNewInvoiceProductDto);
  };

  const onChangeNewInvoiceProductAmount = (newAmount: number | null) => {
    const copyNewInvoiceProductDto = { ...newInvoiceProductDto };
    if (newAmount === null) {
      copyNewInvoiceProductDto.amount = null;
      copyNewInvoiceProductDto.hasErrorAmount = true;
    } else {
      copyNewInvoiceProductDto.amount = newAmount;
      if (extendedInvoiceDto.invoiceType === InvoiceType.Normal) {
        copyNewInvoiceProductDto.hasErrorAmount = newAmount <= 0;
      } else {
        copyNewInvoiceProductDto.hasErrorAmount = newAmount >= 0;
      }
    }
    const amount: number = copyNewInvoiceProductDto.amount !== null ? copyNewInvoiceProductDto.amount! : 0;
    const discount: number = copyNewInvoiceProductDto.discount !== null ? copyNewInvoiceProductDto.discount! : 0;
    const vatPercentage: number = copyNewInvoiceProductDto.vatPercentage !== null ? copyNewInvoiceProductDto.vatPercentage! : 0;
    const taxBase: number = amount * (copyNewInvoiceProductDto.units || 0);
    const discountAmount: number = (taxBase * discount) / 100;
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * copyNewInvoiceProductDto.equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    copyNewInvoiceProductDto.total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setNewInvoiceProductDto(copyNewInvoiceProductDto);
  };

  const onChangeNewInvoiceProductDiscount = (newDiscount: number | null) => {
    const copyNewInvoiceProductDto = { ...newInvoiceProductDto };
    if (newDiscount === null) {
      copyNewInvoiceProductDto.discount = null;
    } else {
      copyNewInvoiceProductDto.discount = newDiscount;
    }
    const amount: number = copyNewInvoiceProductDto.amount !== null ? copyNewInvoiceProductDto.amount! : 0;
    const discount: number = copyNewInvoiceProductDto.discount !== null ? copyNewInvoiceProductDto.discount! : 0;
    const vatPercentage: number = copyNewInvoiceProductDto.vatPercentage !== null ? copyNewInvoiceProductDto.vatPercentage! : 0;
    const taxBase: number = amount * (copyNewInvoiceProductDto.units || 0);
    const discountAmount: number = (taxBase * discount) / 100;
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * copyNewInvoiceProductDto.equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    copyNewInvoiceProductDto.total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setNewInvoiceProductDto(copyNewInvoiceProductDto);
  };

  const onChangeNewInvoiceProductVatPercentage = (newVatPercentage: number | null) => {
    const copyNewInvoiceProductDto = { ...newInvoiceProductDto };
    if (newVatPercentage === null) {
      copyNewInvoiceProductDto.vatPercentage = null;
      copyNewInvoiceProductDto.hasErrorVatPercentage = true;
    } else {
      copyNewInvoiceProductDto.vatPercentage = newVatPercentage;
      copyNewInvoiceProductDto.hasErrorVatPercentage = false;
    }
    const amount: number = copyNewInvoiceProductDto.amount !== null ? copyNewInvoiceProductDto.amount! : 0;
    const discount: number = copyNewInvoiceProductDto.discount !== null ? copyNewInvoiceProductDto.discount! : 0;
    const vatPercentage: number = copyNewInvoiceProductDto.vatPercentage !== null ? copyNewInvoiceProductDto.vatPercentage! : 0;
    const taxBase: number = amount * (copyNewInvoiceProductDto.units || 0);
    const discountAmount: number = (taxBase * discount) / 100;
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * copyNewInvoiceProductDto.equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    copyNewInvoiceProductDto.total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setNewInvoiceProductDto(copyNewInvoiceProductDto);
  };

  const onChangeNewInvoiceProductEquivalenceSurcharge = (equivalenceSurcharge: boolean) => {
    const copyNewInvoiceProductDto = { ...newInvoiceProductDto };
    copyNewInvoiceProductDto.equivalenceSurcharge = equivalenceSurcharge ? parseFloat(process.env.REACT_APP_EQUIVALENCE_SURCHARGE!) : 0;
    const amount: number = copyNewInvoiceProductDto.amount !== null ? copyNewInvoiceProductDto.amount! : 0;
    const discount: number = copyNewInvoiceProductDto.discount !== null ? copyNewInvoiceProductDto.discount! : 0;
    const vatPercentage: number = copyNewInvoiceProductDto.vatPercentage !== null ? copyNewInvoiceProductDto.vatPercentage! : 0;
    const taxBase: number = amount * (copyNewInvoiceProductDto.units || 0);
    const discountAmount: number = (taxBase * discount) / 100;
    const equivalenceSurchargeAmount: number = ((taxBase - discountAmount) * copyNewInvoiceProductDto.equivalenceSurcharge) / 100;
    const vatAmount: number = ((taxBase - discountAmount) * vatPercentage) / 100;
    copyNewInvoiceProductDto.total = roundTwoDecimals(taxBase - discountAmount + equivalenceSurchargeAmount + vatAmount);
    setNewInvoiceProductDto(copyNewInvoiceProductDto);
  };

  const addInvoiceProduct = () => {
    const copyExtendedInvoiceDto = { ...extendedInvoiceDto };
    if (newInvoiceProductDto.productId !== null && newInvoiceProductDto.productId > 0) {
      copyExtendedInvoiceDto.invoiceProducts.push({
        productId: newInvoiceProductDto.productId,
        units: newInvoiceProductDto.units,
        amount: newInvoiceProductDto.amount,
        equivalenceSurcharge: newInvoiceProductDto.equivalenceSurcharge,
        discount: newInvoiceProductDto.discount,
        vatPercentage: newInvoiceProductDto.vatPercentage,
        total: newInvoiceProductDto.total,
        product: newInvoiceProductDto.product,
        hasErrorUnits: newInvoiceProductDto.hasErrorUnits,
        hasErrorAmount: newInvoiceProductDto.hasErrorAmount,
        hasErrorVatPercentage: newInvoiceProductDto.hasErrorVatPercentage,
      });
    } else {
      copyExtendedInvoiceDto.manualInvoiceProducts.push({
        name: newInvoiceProductDto.name,
        units: newInvoiceProductDto.units,
        amount: newInvoiceProductDto.amount,
        equivalenceSurcharge: newInvoiceProductDto.equivalenceSurcharge,
        discount: newInvoiceProductDto.discount,
        vatPercentage: newInvoiceProductDto.vatPercentage,
        total: newInvoiceProductDto.total,
        hasErrorUnits: newInvoiceProductDto.hasErrorUnits,
        hasErrorAmount: newInvoiceProductDto.hasErrorAmount,
        hasErrorVatPercentage: newInvoiceProductDto.hasErrorVatPercentage,
      });
    }
    setExtendedInvoiceDto(copyExtendedInvoiceDto);
    setNewInvoiceProductDto({
      productId: -1,
      name: '',
      units: null,
      amount: null,
      equivalenceSurcharge: 0,
      discount: null,
      vatPercentage: parseFloat(process.env.REACT_APP_IVA!),
      total: 0,
      product: null,
      hasErrorUnits: false,
      hasErrorAmount: false,
      hasErrorVatPercentage: false,
    });
    setQuery('');
  };

  const exportElectronicInvoice = async () => {
    if (requestingXml) {
      return;
    }
    setRequestingXml(true);
    try {
      myToastr.info('Obteniendo la factura electrónica. Espera por favor.');
      const result: ArrayBuffer = await api.getInvoiceXml(invoice!.id);
      const url: string = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `factura-${invoice!.internalId}.xml`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      myToastr.error('Hubo un error obteniendo el XML de la factura');
    } finally {
      setRequestingXml(false);
    }
  };

  const printInvoice = async () => {
    if (requestingPdf) {
      return;
    }
    setRequestingPdf(true);
    try {
      myToastr.info('Obteniendo la factura en formato PDF. Espera por favor.');
      const result: ArrayBuffer = await api.getInvoicePdf(invoice!.id);
      const url: string = window.URL.createObjectURL(new Blob([result]));
      printJS(url);
    } catch (e) {
      myToastr.error('Hubo un error obteniendo la factura en formato PDF.');
    } finally {
      setRequestingPdf(false);
    }
  };

  const onCloseCustomerModal = (customer: Customer | null) => {
    setSelectedCustomer(null);
    setShowCustomerModal(false);
    if (!customer) {
      myToastr.error('Debes rellenar los datos obligatorios del cliente para poder crear la factura.');
      customerSelectRef?.current?.clearValue();
      return;
    }
    if (customer) {
      const copyExtendedInvoiceDto = { ...extendedInvoiceDto };
      copyExtendedInvoiceDto.customerId = customer.id;
      copyExtendedInvoiceDto.customer = customer;
      copyExtendedInvoiceDto.customerName = customer.name;
      setExtendedInvoiceDto(copyExtendedInvoiceDto);
    }
  };

  const deleteInvoice = async () => {
    setRequesting(true);
    try {
      await api.deleteInvoice(invoice!.id);
      myToastr.success('Factura eliminada');
      navigate('/facturas');
      return;
    } catch (e) {
      myToastr.error('Error al eliminar la factura');
    }
    setRequesting(false);
  };

  const saveInvoice = async () => {
    if (extendedInvoiceDto.customerId === null || extendedInvoiceDto.customerId <= 0) {
      myToastr.error('Debes seleccionar un cliente');
      return;
    }
    if (extendedInvoiceDto.invoiceProducts.length === 0 && extendedInvoiceDto.manualInvoiceProducts.length === 0) {
      myToastr.error('Debes añadir al menos un producto');
      return;
    }
    const hasInvoiceProductsErrors: boolean = extendedInvoiceDto.invoiceProducts.some(
      (invoiceProduct: ExtendedInvoiceProductDto) => invoiceProduct.hasErrorUnits || invoiceProduct.hasErrorAmount || invoiceProduct.hasErrorVatPercentage,
    );
    if (hasInvoiceProductsErrors) {
      myToastr.error('Hay errores en los productos');
      return;
    }
    const hasManualInvoiceProductsErrors: boolean = extendedInvoiceDto.manualInvoiceProducts.some(
      (extendedManualInvoiceProductDto: ExtendedManualInvoiceProductDto) =>
        extendedManualInvoiceProductDto.hasErrorUnits || extendedManualInvoiceProductDto.hasErrorAmount || extendedManualInvoiceProductDto.hasErrorVatPercentage,
    );
    if (hasManualInvoiceProductsErrors) {
      myToastr.error('Hay errores en los productos');
      return;
    }
    try {
      const invoiceDto: InvoiceDto = {
        observations: extendedInvoiceDto.observations,
        date: extendedInvoiceDto.date,
        invoiceType: extendedInvoiceDto.invoiceType,
        invoiceStatus: extendedInvoiceDto.invoiceStatus,
        storeId: extendedInvoiceDto.storeId,
        customerId: extendedInvoiceDto.customerId,
        invoiceProducts: extendedInvoiceDto.invoiceProducts.map((extendedInvoiceProductDto: ExtendedInvoiceProductDto) => ({
          productId: extendedInvoiceProductDto.productId,
          units: extendedInvoiceProductDto.units!,
          amount: extendedInvoiceProductDto.amount!,
          equivalenceSurcharge: extendedInvoiceProductDto.equivalenceSurcharge,
          discount: extendedInvoiceProductDto.discount!,
          vatPercentage: extendedInvoiceProductDto.vatPercentage!,
          total: extendedInvoiceProductDto.total,
        })),
        manualInvoiceProducts: extendedInvoiceDto.manualInvoiceProducts.map((extendedManualInvoiceProductDto: ExtendedManualInvoiceProductDto) => ({
          name: extendedManualInvoiceProductDto.name,
          units: extendedManualInvoiceProductDto.units!,
          amount: extendedManualInvoiceProductDto.amount!,
          equivalenceSurcharge: extendedManualInvoiceProductDto.equivalenceSurcharge,
          discount: extendedManualInvoiceProductDto.discount!,
          vatPercentage: extendedManualInvoiceProductDto.vatPercentage!,
          total: extendedManualInvoiceProductDto.total,
        })),
        notes: extendedInvoiceDto.notes,
      };
      if (invoice !== null) {
        await api.updateInvoice(invoice.id, invoiceDto);
        myToastr.success('Factura actualizada');
        navigate(0);
      } else {
        const newInvoice: Invoice = await api.createInvoice(invoiceDto);
        myToastr.success('Factura creada');
        navigate(`/factura/${newInvoice.id}`);
      }
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

  const postInvoice = async () => {
    if (extendedInvoiceDto.customerId === null || extendedInvoiceDto.customerId <= 0) {
      myToastr.error('Debes seleccionar un cliente');
      return;
    }
    if (extendedInvoiceDto.invoiceProducts.length === 0 && extendedInvoiceDto.manualInvoiceProducts.length === 0) {
      myToastr.error('Debes añadir al menos un producto');
      return;
    }
    const hasInvoiceProductsErrors: boolean = extendedInvoiceDto.invoiceProducts.some(
      (invoiceProduct: ExtendedInvoiceProductDto) => invoiceProduct.hasErrorUnits || invoiceProduct.hasErrorAmount || invoiceProduct.hasErrorVatPercentage,
    );
    if (hasInvoiceProductsErrors) {
      myToastr.error('Hay errores en los productos');
      return;
    }
    const hasManualInvoiceProductsErrors: boolean = extendedInvoiceDto.manualInvoiceProducts.some(
      (extendedManualInvoiceProductDto: ExtendedManualInvoiceProductDto) =>
        extendedManualInvoiceProductDto.hasErrorUnits || extendedManualInvoiceProductDto.hasErrorAmount || extendedManualInvoiceProductDto.hasErrorVatPercentage,
    );
    if (hasManualInvoiceProductsErrors) {
      myToastr.error('Hay errores en los productos');
      return;
    }
    try {
      const invoiceDto: InvoiceDto = {
        observations: extendedInvoiceDto.observations,
        date: extendedInvoiceDto.date,
        invoiceType: extendedInvoiceDto.invoiceType,
        invoiceStatus: InvoiceStatus.Posted,
        storeId: extendedInvoiceDto.storeId,
        customerId: extendedInvoiceDto.customerId,
        invoiceProducts: extendedInvoiceDto.invoiceProducts.map((extendedInvoiceProductDto: ExtendedInvoiceProductDto) => ({
          productId: extendedInvoiceProductDto.productId,
          units: extendedInvoiceProductDto.units!,
          amount: extendedInvoiceProductDto.amount!,
          equivalenceSurcharge: extendedInvoiceProductDto.equivalenceSurcharge,
          discount: extendedInvoiceProductDto.discount!,
          vatPercentage: extendedInvoiceProductDto.vatPercentage!,
          total: extendedInvoiceProductDto.total,
        })),
        manualInvoiceProducts: extendedInvoiceDto.manualInvoiceProducts.map((extendedManualInvoiceProductDto: ExtendedManualInvoiceProductDto) => ({
          name: extendedManualInvoiceProductDto.name,
          units: extendedManualInvoiceProductDto.units!,
          amount: extendedManualInvoiceProductDto.amount!,
          equivalenceSurcharge: extendedManualInvoiceProductDto.equivalenceSurcharge,
          discount: extendedManualInvoiceProductDto.discount!,
          vatPercentage: extendedManualInvoiceProductDto.vatPercentage!,
          total: extendedManualInvoiceProductDto.total,
        })),
        notes: extendedInvoiceDto.notes,
      };
      if (invoice !== null) {
        await api.updateInvoice(invoice.id, invoiceDto);
        myToastr.success('Factura actualizada');
        navigate(0);
      } else {
        const newInvoice: Invoice = await api.createInvoice(invoiceDto);
        myToastr.success('Factura creada');
        navigate(`/factura/${newInvoice.id}`);
      }
    } catch (e: any) {
      const httpExceptionDto: HttpExceptionDto = e.response.data;
      myToastr.error(httpExceptionDto.message);
    }
  };

  // NOTES

  const onCloseNoteModal = () => {
    setShowNotesModal(false);
  };

  const onSaveNoteModal = async (notes: string | null) => {
    try {
      const updatedInvoice: Invoice = await api.updateInvoiceNotes(invoice!.id, { notes: notes === null ? '' : notes });
      setInvoice(updatedInvoice);
      setExtendedInvoiceDto({ ...extendedInvoiceDto, notes: updatedInvoice.notes });
    } catch (e) {}
    setShowNotesModal(false);
  };

  const onDeleteNoteModal = async () => {
    try {
      const updatedInvoice: Invoice = await api.updateInvoiceNotes(invoice!.id, { notes: '' });
      setInvoice(updatedInvoice);
      setExtendedInvoiceDto({ ...extendedInvoiceDto, notes: updatedInvoice.notes });
    } catch (e) {}
    setShowNotesModal(false);
  };

  const onCustomerChange = (customer: Customer | null) => {
    if (!customer) {
      setExtendedInvoiceDto({
        ...extendedInvoiceDto,
        customerId: -1,
        customer: null,
      });
      return;
    }
    if (!customer.billingBusinessName) {
      myToastr.info('El cliente seleccionado no tiene Razón social, por favor, añádelo');
      setSelectedCustomer(customer);
      setShowCustomerModal(true);
      return;
    }
    if (!customer.cif) {
      myToastr.info('El cliente seleccionado no tiene CIF/NIF, por favor, añádelo');
      setSelectedCustomer(customer);
      setShowCustomerModal(true);
      return;
    }
    if (!customer.billingAddress1) {
      myToastr.info('El cliente seleccionado no tiene dirección de facturación, por favor, añádela');
      setSelectedCustomer(customer);
      setShowCustomerModal(true);
      return;
    }
    if (!customer.billingPostalCode) {
      myToastr.info('El cliente seleccionado no tiene código postal de facturación, por favor, añádelo');
      setSelectedCustomer(customer);
      setShowCustomerModal(true);
      return;
    }
    if (!customer.billingCity) {
      myToastr.info('El cliente seleccionado no tiene ciudad de facturación, por favor, añádela');
      setSelectedCustomer(customer);
      setShowCustomerModal(true);
      return;
    }
    if (!customer.billingProvince) {
      myToastr.info('El cliente seleccionado no tiene provincia de facturación, por favor, añádela');
      setSelectedCustomer(customer);
      setShowCustomerModal(true);
      return;
    }
    if (!customer.billingCountry) {
      myToastr.info('El cliente seleccionado no tiene país de facturación, por favor, añádelo');
      setSelectedCustomer(customer);
      setShowCustomerModal(true);
      return;
    }
    setExtendedInvoiceDto({
      ...extendedInvoiceDto,
      customerId: customer.id,
      customer,
    });
  };

  if (selectedStoreId <= 0) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <h1>Selecciona una tienda</h1>
      </div>
    );
  }

  return (
    <div className="invoice-view">
      <div className="d-flex flex-row align-items-center invoice-view-header">
        <Link to="/facturas">
          <ChevronLeft className="me-1" size={16} />
          Volver
        </Link>
        <h1 className="flex-grow-1">
          {invoice !== null ? `Factura #${invoice.internalId}` : `Crear factura ${extendedInvoiceDto.invoiceType === InvoiceType.Normal ? '' : 'rectificativa / abono'.trim()}`}
        </h1>
        {extendedInvoiceDto.notes !== null && extendedInvoiceDto.notes !== '' ? (
          <div className="d-flex flex-row align-items-center clickable has-notes" onClick={() => setShowNotesModal(true)}>
            <Plus className="me-1" size={14} />
            <span>1 Nota</span>
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center clickable" onClick={() => setShowNotesModal(true)}>
            <File className="me-1" size={16} />
            <span>Añadir nota</span>
          </div>
        )}
        {extendedInvoiceDto.invoiceStatus === InvoiceStatus.Posted && (
          <React.Fragment>
            <div className="d-flex flex-row align-items-center cursor-pointer mx-3" onClick={exportElectronicInvoice}>
              <Download className="me-1" size={16} />
              <span>Exportar fac. electrónica</span>
            </div>
            <div className="d-flex flex-row align-items-center cursor-pointer" onClick={printInvoice}>
              <Printer className="me-1" size={16} />
              <span>Imprimir</span>
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="row">
        <div className="col-8">
          <div className="white-container">
            <div className="row">
              <div className="col-8">
                <div className="d-flex flex-column">
                  <label>Cliente:</label>
                  {invoice ? (
                    <CustomerInfo customer={invoice.customer} onAssignCustomer={onCustomerChange} balance={null} />
                  ) : extendedInvoiceDto.customer ? (
                    <CustomerInfo onRemoveCustomer={() => onCustomerChange(null)} customer={extendedInvoiceDto.customer} onAssignCustomer={onCustomerChange} balance={null} />
                  ) : (
                    <CustomersFinder customer={extendedInvoiceDto.customer} onClose={onCustomerChange} billingDataRequired={true} parentRef={customerSelectRef} />
                  )}
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex flex-column">
                  <label>Fecha:</label>
                  <input
                    type="date"
                    value={extendedInvoiceDto.date || ''}
                    onChange={(e) => setExtendedInvoiceDto({ ...extendedInvoiceDto, date: e.target.value })}
                    disabled={extendedInvoiceDto.invoiceStatus === InvoiceStatus.Posted}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="white-container d-flex flex-column">
            <label>Estado:</label>
            <div className={clsx('d-flex align-items-center invoice-status', extendedInvoiceDto.invoiceStatus)}>
              {extendedInvoiceDto.invoiceStatus === InvoiceStatus.Posted ? 'Contabilizada' : 'Pendiente'}
            </div>
          </div>
        </div>
      </div>
      <div className="white-container invoice-product-lines">
        <table>
          <thead>
            <tr>
              <th className="delete text-center"></th>
              <th className="">Concepto</th>
              <th className="units text-center">Unidades</th>
              <th className="amount text-center">
                <div className="d-flex flex-column">
                  <span>Importe</span>
                  {extendedInvoiceDto.invoiceType === InvoiceType.Rectification && <span style={{ fontSize: 10 }}>Valor en negativo</span>}
                </div>
              </th>
              <th className="discount-product text-center">Desc. %</th>
              <th className="vat text-center">IVA %</th>
              <th className="equivalenceSurcharge text-center">Recargo de equi.</th>
              <th className="total text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            {extendedInvoiceDto.invoiceStatus === InvoiceStatus.Posted ? (
              <React.Fragment>
                {extendedInvoiceDto.invoiceProducts.map((extendedInvoiceProductDto: ExtendedInvoiceProductDto, index: number) => {
                  return (
                    <tr key={index}>
                      <td></td>
                      <td className="text-left">
                        <div className="d-flex flex-column">
                          <Link to={`/producto/${extendedInvoiceProductDto.product!.id}`} style={{ textDecoration: 'none' }}>
                            {extendedInvoiceProductDto.product!.name}
                          </Link>
                          <span style={{ fontSize: 11 }}>{extendedInvoiceProductDto.product!.sku}</span>
                        </div>
                      </td>
                      <td className="text-center">{extendedInvoiceProductDto.units}</td>
                      <td className="text-center">{formatNumber(extendedInvoiceProductDto.amount)}€</td>
                      <td className="text-center">{extendedInvoiceProductDto.discount ? `${extendedInvoiceProductDto.discount}%` : ''}</td>
                      <td className="text-center">{extendedInvoiceProductDto.vatPercentage}</td>
                      <td className="text-center">
                        <input type="checkbox" checked={extendedInvoiceProductDto.equivalenceSurcharge > 0} disabled />
                      </td>
                      <td className="text-right">{formatNumber(extendedInvoiceProductDto.total)}€</td>
                    </tr>
                  );
                })}
                {extendedInvoiceDto.manualInvoiceProducts.map((extendedManualInvoiceProductDto: ExtendedManualInvoiceProductDto, index: number) => {
                  return (
                    <tr key={index}>
                      <td></td>
                      <td className="text-left">{extendedManualInvoiceProductDto.name}</td>
                      <td className="text-center">{extendedManualInvoiceProductDto.units}</td>
                      <td className="text-center">{formatNumber(extendedManualInvoiceProductDto.amount)}€</td>
                      <td className="text-center">{extendedManualInvoiceProductDto.discount}%</td>
                      <td className="text-center">{extendedManualInvoiceProductDto.vatPercentage}</td>
                      <td className="text-center">
                        <input type="checkbox" checked={extendedManualInvoiceProductDto.equivalenceSurcharge > 0} disabled />
                      </td>
                      <td className="text-right">{formatNumber(extendedManualInvoiceProductDto.total)}€</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {extendedInvoiceDto.invoiceProducts.map((extendedInvoiceProductDto: ExtendedInvoiceProductDto, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div title="Eliminar línea">
                          <X className="cursor-pointer" onClick={() => removeProduct(index)} size={12} color="#808A95" />
                        </div>
                      </td>
                      <td>{extendedInvoiceProductDto.product!.name}</td>
                      <td>
                        <input
                          type="number"
                          className={clsx({ 'is-invalid': extendedInvoiceProductDto.hasErrorUnits })}
                          value={extendedInvoiceProductDto.units || ''}
                          min={1}
                          placeholder="Uds."
                          onChange={(e: any) => {
                            let value = e.target.value;
                            if (e.target.value) {
                              value = parseInt(e.target.value);
                            } else {
                              value = null;
                            }
                            onChangeUnits(index, value);
                          }}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          className={clsx({ 'is-invalid': extendedInvoiceProductDto.hasErrorAmount })}
                          value={extendedInvoiceProductDto.amount || ''}
                          min={0}
                          placeholder="Importe €"
                          onChange={(e: any) => {
                            let value = e.target.value;
                            if (e.target.value) {
                              value = parseFloat(e.target.value);
                            } else {
                              value = null;
                            }
                            onChangeAmount(index, value);
                          }}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          value={extendedInvoiceProductDto.discount || ''}
                          min={0}
                          max={100}
                          placeholder="Desc. %"
                          onChange={(e: any) => {
                            let value = e.target.value;
                            if (e.target.value) {
                              value = Math.min(100, parseFloat(e.target.value));
                            } else {
                              value = null;
                            }
                            onChangeDiscount(index, value);
                          }}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          className={clsx({ 'is-invalid': extendedInvoiceProductDto.hasErrorVatPercentage })}
                          value={extendedInvoiceProductDto.vatPercentage || ''}
                          min={0}
                          max={100}
                          placeholder="IVA %"
                          onChange={(e: any) => {
                            let value = e.target.value;
                            if (e.target.value) {
                              value = Math.min(100, parseFloat(e.target.value));
                            } else {
                              value = null;
                            }
                            onChangeVatPercentage(index, value);
                          }}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          checked={extendedInvoiceProductDto.equivalenceSurcharge > 0}
                          onChange={(e: any) => {
                            onChangeEquivalenceSurcharge(index, e.target.checked);
                          }}
                        />
                      </td>
                      <td className="text-center">{formatNumber(extendedInvoiceProductDto.total)}€</td>
                    </tr>
                  );
                })}
                {extendedInvoiceDto.manualInvoiceProducts.map((extendedManualInvoiceProductDto: ExtendedManualInvoiceProductDto, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div title="Eliminar línea manual">
                          <X className="cursor-pointer" onClick={() => removeManualProduct(index)} size={12} color="#808A95" />
                        </div>
                      </td>
                      <td>{extendedManualInvoiceProductDto.name}</td>
                      <td className="text-center">
                        <input
                          type="number"
                          className={clsx({ 'is-invalid': extendedManualInvoiceProductDto.hasErrorUnits })}
                          value={extendedManualInvoiceProductDto.units || ''}
                          min={1}
                          placeholder="Uds."
                          onChange={(e: any) => {
                            let value = e.target.value;
                            if (e.target.value) {
                              value = parseInt(e.target.value);
                            } else {
                              value = null;
                            }
                            onChangeManualUnits(index, value);
                          }}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          className={clsx({ 'is-invalid': extendedManualInvoiceProductDto.hasErrorAmount })}
                          value={extendedManualInvoiceProductDto.amount || ''}
                          min={0}
                          placeholder="Importe €"
                          onChange={(e: any) => {
                            let value = e.target.value;
                            if (e.target.value) {
                              value = parseFloat(e.target.value);
                            } else {
                              value = null;
                            }
                            onChangeManualAmount(index, value);
                          }}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          value={extendedManualInvoiceProductDto.discount || ''}
                          min={0}
                          max={100}
                          placeholder="Desc. %"
                          onChange={(e: any) => {
                            let value = e.target.value;
                            if (e.target.value) {
                              value = Math.min(100, parseFloat(e.target.value));
                            } else {
                              value = null;
                            }
                            onChangeManualDiscount(index, value);
                          }}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          className={clsx({ 'is-invalid': extendedManualInvoiceProductDto.hasErrorVatPercentage })}
                          value={extendedManualInvoiceProductDto.vatPercentage || ''}
                          min={0}
                          max={100}
                          placeholder="IVA %"
                          onChange={(e: any) => {
                            let value = e.target.value;
                            if (e.target.value) {
                              value = Math.min(100, parseFloat(e.target.value));
                            } else {
                              value = null;
                            }
                            onChangeManualVatPercentage(index, value);
                          }}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          checked={extendedManualInvoiceProductDto.equivalenceSurcharge > 0}
                          onChange={(e: any) => {
                            onChangeManualEquivalenceSurcharge(index, e.target.checked);
                          }}
                        />
                      </td>
                      <td className="text-center">{formatNumber(extendedManualInvoiceProductDto.total)}€</td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="">
                    <div title="Eliminar línea">
                      <X className="cursor-pointer" onClick={removeNewProduct} size={12} color="#808A95" />
                    </div>
                  </td>
                  <td className="">
                    <Autocomplete
                      inputProps={{
                        placeholder: 'Buscar producto o añadir línea manual',
                        style: {
                          textAlign: 'left',
                          width: '100%',
                        },
                      }}
                      wrapperStyle={{ position: 'relative', display: 'inline-block', width: '100%' }}
                      value={newInvoiceProductDto?.product?.name || newInvoiceProductDto?.name || query || ''}
                      items={stock}
                      getItemValue={(item: Stock) => item.product.name}
                      onSelect={(_: string, item: Stock) => {
                        // Eliminar el iva al precio de venta
                        let amount: number = roundTwoDecimals(item.pvp / (1 + parseFloat(process.env.REACT_APP_IVA) / 100));
                        if (extendedInvoiceDto.invoiceType === InvoiceType.Rectification) {
                          amount *= -1;
                        }
                        setNewInvoiceProductDto({
                          ...newInvoiceProductDto,
                          product: item.product,
                          productId: item.product.id,
                          amount,
                        });
                      }}
                      onChange={(_, value: string) => {
                        setQuery(value);
                        setNewInvoiceProductDto({
                          ...newInvoiceProductDto,
                          name: value,
                        });
                      }}
                      renderMenu={(children) => {
                        return <div className="menu menu-items">{children}</div>;
                        // if (requestingStock) {
                        //   return (
                        //     <div className="menu menu-loading">
                        //       <div className="d-flex justify-content-center">
                        //         <Loader type="TailSpin" color="#222E3D" height={30} width={30} />
                        //       </div>
                        //     </div>
                        //   );
                        // } else if (stock.length > 0) {
                        //   return <div className="menu menu-items">{children}</div>;
                        // } else {
                        //   return <div />;
                        // }
                      }}
                      renderItem={(item: Stock, isHighlighted: boolean) => (
                        <div className={clsx('item d-flex flex-row', { 'item-highlighted': isHighlighted })} key={item.id}>
                          <span>{item.store.name}</span>
                          <span className="mx-2">-</span>
                          <span>{item.product.sku}</span>
                          <span className="mx-2">-</span>
                          <span>{item.product.name}</span>
                        </div>
                      )}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="number"
                      className={clsx({ 'is-invalid': newInvoiceProductDto.hasErrorUnits })}
                      value={newInvoiceProductDto.units || ''}
                      min={1}
                      placeholder="Uds."
                      onChange={(e: any) => {
                        let value = e.target.value;
                        if (e.target.value) {
                          value = parseInt(e.target.value);
                        } else {
                          value = null;
                        }
                        onChangeNewInvoiceProductUnits(value);
                      }}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="number"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      className={clsx({ 'is-invalid': newInvoiceProductDto.hasErrorAmount })}
                      value={newInvoiceProductDto.amount || ''}
                      placeholder="Importe €"
                      onChange={(e: any) => {
                        let value = e.target.value;
                        if (e.target.value) {
                          value = parseFloat(e.target.value);
                        } else {
                          value = null;
                        }
                        onChangeNewInvoiceProductAmount(value);
                      }}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="number"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      value={newInvoiceProductDto.discount || ''}
                      min={0}
                      max={100}
                      placeholder="Desc. %"
                      onChange={(e: any) => {
                        let value = e.target.value;
                        if (e.target.value) {
                          value = Math.min(100, parseFloat(e.target.value));
                        } else {
                          value = null;
                        }
                        onChangeNewInvoiceProductDiscount(value);
                      }}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="number"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      className={clsx({ 'is-invalid': newInvoiceProductDto.hasErrorVatPercentage })}
                      value={newInvoiceProductDto.vatPercentage || ''}
                      min={0}
                      max={100}
                      placeholder="IVA %"
                      onChange={(e: any) => {
                        let value = e.target.value;
                        if (e.target.value) {
                          value = Math.min(100, parseFloat(e.target.value));
                        } else {
                          value = null;
                        }
                        onChangeNewInvoiceProductVatPercentage(value);
                      }}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={newInvoiceProductDto.equivalenceSurcharge > 0}
                      onChange={(e: any) => {
                        onChangeNewInvoiceProductEquivalenceSurcharge(e.target.checked);
                      }}
                    />
                  </td>
                  <td className="text-center">
                    <button className="filled" onClick={addInvoiceProduct} disabled={isAddProductDisabled}>
                      Añadir
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end">
        <div className="invoice-totals">
          <table>
            <thead>
              <tr>
                <th>Uds. total</th>
                <th>Base imponible</th>
                <th>Desc. total</th>
                <th>Recargo de equiv.</th>
                <th>IVA total</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="text" readOnly disabled value={invoiceTotals.units} />
                </td>
                <td>
                  <input type="text" readOnly disabled value={`${formatNumber(invoiceTotals.taxBase)}€`} />
                </td>
                <td>
                  <input type="text" readOnly disabled value={`${formatNumber(invoiceTotals.discount)}€`} />
                </td>
                <td>
                  <input type="text" readOnly disabled value={`${formatNumber(invoiceTotals.equivalenceSurcharge)}€`} />
                </td>
                <td>
                  <input type="text" readOnly disabled value={`${formatNumber(invoiceTotals.vat)}€`} />
                </td>
                <td>
                  <input type="text" readOnly disabled value={`${formatNumber(invoiceTotals.total)}€`} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="white-container">
        <div className="d-flex flex-column">
          <label>Observaciones:</label>
          <textarea
            value={extendedInvoiceDto.observations}
            onChange={(e: any) =>
              setExtendedInvoiceDto({
                ...extendedInvoiceDto,
                observations: e.target.value,
              })
            }
            disabled={extendedInvoiceDto.invoiceStatus === InvoiceStatus.Posted}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          {invoice !== null && extendedInvoiceDto.invoiceStatus === InvoiceStatus.Pending && (
            <button className="outlined" onClick={() => setShowConfirmModal(true)}>
              Eliminar
            </button>
          )}
        </div>
        <div>
          {(invoice === null || extendedInvoiceDto.invoiceStatus === InvoiceStatus.Pending) && (
            <button className={clsx('me-3 outlined')} onClick={postInvoice} disabled={requesting}>
              Contabilizar
            </button>
          )}
          {(invoice === null || extendedInvoiceDto.invoiceStatus === InvoiceStatus.Pending) && (
            <button className={clsx('filled', { disabled: disableButton })} onClick={saveInvoice} disabled={requesting}>
              Guardar
            </button>
          )}
        </div>
      </div>
      <CustomerModal show={showCustomerModal} closeModal={onCloseCustomerModal} customer={selectedCustomer} billingDataRequired={true} />
      {invoice !== null && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Factura"
          content={`¿Estás seguro que quieres eliminar la factura #${invoice.internalId}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteInvoice();
            }
          }}
        ></ConfirmModal>
      )}
      <NoteModal editable={true} show={showNotesModal} notes={extendedInvoiceDto.notes} closeModal={onCloseNoteModal} saveNotes={onSaveNoteModal} deleteNotes={onDeleteNoteModal} />
    </div>
  );
};

export default InvoiceView;
