import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row } from 'react-table';
import DateRangeSelector from '../components/date-range-selector';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import { CashMovementType } from '../enums/cash-movement-type.enum';
import { DateRange } from '../enums/date-range';
import { PaymentMethodType } from '../enums/payment-method-type';
import { useStores } from '../hooks/use-stores.hook';
import { CashMovement } from '../interfaces/cash-movement';
import { DateRangeData } from '../interfaces/date-range-data';
import { Store } from '../interfaces/store';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { cashMovementToString, formatNumber, getDatesGivenDateRange } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import { storeSelector } from '../store/store-slice';

const dateRange: DateRange = DateRange.ThisWeek;

const CashMovementsView = () => {
  const { selectedStoreId, store } = useSelector(storeSelector);
  const tableRef: any = useRef<any>(null);
  const [cashMovements, setCashMovements] = useState<CashMovement[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData>({
    dateRange,
    dates: getDatesGivenDateRange(dateRange),
  });
  const stores: Store[] = useStores();
  const totals: { label: string; value: number; percentage: number }[] = useMemo(() => {
    const map: Map<string, number> = new Map<string, number>();
    let total = 0;
    filteredRows.forEach((row: Row) => {
      const cashMovement: CashMovement = row.original as CashMovement;
      const key = cashMovement.paymentMethod.name;
      if (!map.has(key)) {
        map.set(key, 0);
      }
      map.set(key, map.get(key)! + cashMovement.amount);
      total += cashMovement.amount;
    });
    const result: { label: string; value: number; percentage: number }[] = [];
    map.forEach((value: number, key: string) => {
      const percentage = total === 0 ? 0 : (value / total) * 100;
      result.push({ label: key, value, percentage });
    });
    result.sort((a, b) => b.value - a.value);
    result.unshift({ label: 'Total', value: total, percentage: 100 });
    return result;
  }, [filteredRows]);

  useEffect(() => {
    if (selectedStoreId === -1 || !dateRangeData.dates[0] || !dateRangeData.dates[1]) {
      return;
    }
    const getData = async () => {
      setRequesting(true);
      try {
        const ms: CashMovement[] = await api.getCashMovements(selectedStoreId, dateRangeData.dates[0]!, dateRangeData.dates[1]!);
        setCashMovements(ms);
      } catch (e) {}
      setRequesting(false);
    };
    getData();
  }, [dateRangeData, selectedStoreId]);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Tipo',
        id: 'type',
        accessor: (cashMovement: CashMovement) => cashMovementToString(cashMovement.type),
        Filter: SelectColumnFilter,
        Cell: ({ row }: any) => {
          const cashMovement: CashMovement = row.original;
          let to = '/';
          switch (cashMovement.type) {
            case CashMovementType.Sale:
            case CashMovementType.Reservation:
              to = `/venta/${cashMovement.entityId}`;
              break;
            case CashMovementType.Repair:
              to = `/reparacion/${cashMovement.entityId}`;
              break;
            case CashMovementType.Withdrawal:
              to = `/gastos`;
              break;
            default:
              break;
          }
          return (
            <div className="d-flex flex-column">
              <Link to={to} className="position-relative link-sale">
                <span className="fw-bold">{cashMovement.entityInternalId}</span>
              </Link>
              <span>{cashMovementToString(cashMovement.type)}</span>
            </div>
          );
        },
        filter: SelectColumnFilter,
      },
      {
        Header: 'Fecha',
        id: 'date',
        accessor: (cashMovement: CashMovement) => moment(cashMovement.date).format('DD/MM/YYYY HH:mm'),
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const cashMovement: CashMovement = row.original;
          return moment(cashMovement.date).format('DD/MM/YYYY HH:mm');
        },
        sortType: (a: any, b: any) => {
          const momentA = moment(a.original.date);
          const momentB = moment(b.original.date);
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      },
      {
        Header: 'Cantidad',
        id: 'amount',
        accessor: 'amount',
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const cashMovement: CashMovement = row.original;
          return `${formatNumber(cashMovement.amount)}€`;
        },
      },
      {
        Header: 'Método de pago',
        id: 'paymentMethod',
        accessor: (cashMovement: CashMovement) => cashMovement.paymentMethod.name,
        Filter: SelectColumnFilter,
        Cell: ({ row }: any) => {
          const cashMovement: CashMovement = row.original;
          return (
            <div className="d-flex align-items-center">
              <div
                className={clsx('me-1', {
                  'icon-cash': cashMovement.paymentMethod.type === PaymentMethodType.Cash,
                  'icon-tpv': cashMovement.paymentMethod.type === PaymentMethodType.Custom && cashMovement.paymentMethod.isTpv,
                  'icon-balance': cashMovement.paymentMethod.type === PaymentMethodType.Balance,
                  'icon-other': cashMovement.paymentMethod.type === PaymentMethodType.Custom && !cashMovement.paymentMethod.isTpv,
                })}
              ></div>
              <div>
                {cashMovement.paymentMethod.name} {cashMovement.isChange ? '(cambio cliente)' : ''}
              </div>
            </div>
          );
        },
      },
    ];
    return columnsData;
  }, []);

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (!store) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <h1>Selecciona una tienda</h1>
      </div>
    );
  }

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      data.push({
        Id: fr.original.entityInternalId,
        Tipo: cashMovementToString(fr.original.type),
        Fecha: moment(fr.original.date).format('DD/MM/YYYY HH:mm'),
        Cantidad: `${formatNumber(fr.original.amount)}€`,
        'Método de pago': fr.original.paymentMethod.name + (fr.original.isChange ? ' (cambio cliente)' : ''),
      });
    });
    setCsvData(data);
  };

  return (
    <div className="cash-register movements p-4">
      <div className="d-flex flex-row align-items-center mb-2">
        <div className="d-flex align-items-center flex-grow-1">
          <h1 className="me-4">Movimientos de caja</h1>
        </div>
        {filteredRows.length > 0 && (
          <CSVLink filename={`movimientos_caja_${store.name}.csv`} className="d-flex align-items-center export-csv me-3" data={csvData} onClick={generateCsv} title="Exportar movimientos de caja">
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={false}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
        <button
          className="ms-2 clear-filters"
          disabled={requesting}
          onClick={() => {
            tableRef.current?.clearFilters();
            setDateRangeData({
              dateRange,
              dates: getDatesGivenDateRange(dateRange),
            });
          }}
        >
          Limpiar filtros
        </button>
      </div>
      <div className="row">
        {totals.map((e: { label: string; value: number; percentage: number }, index: number) => (
          <div key={index} className={clsx('col-' + Math.floor(12 / totals.length))}>
            <div className="billing-summary mb-3">
              <h4>{e.label}</h4>
              <div className="d-flex flex-column">
                <span className="amount">{formatNumber(e.value)}€</span>
                {index > 0 && <span className="percentage">{formatNumber(e.percentage)}%</span>}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Table
        ref={tableRef}
        data={cashMovements}
        columns={columns}
        noDataMessage="No hay movimientos de caja"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
    </div>
  );
};

export default CashMovementsView;
