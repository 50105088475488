import clsx from 'clsx';
import React, { useState } from 'react';
import { Plus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { PosStep } from '../enums/pos-step';
import { SaleStatus } from '../enums/sale-status';
import { Customer } from '../interfaces/customer';
import myToastr from '../services/toastr';
import { clearShoppingCart, createSale, posSelector, setCustomer, setNotes, setStatus, setStep } from '../store/pos-slice';
import { storeSelector } from '../store/store-slice';
import CustomerInfo from './customer-info';
import CustomersFinder from './customers-finder';
import NoteModal from './note-modal';
import PinModal from './pin-modal';
import { PosProductsTable } from './pos-products-table';
import PosSubtotals from './pos-subtotals';

const PosDetails = () => {
  const dispatch = useDispatch();
  const { store } = useSelector(storeSelector);
  const { customer } = useSelector(posSelector);
  const { items, notes, balance, manualSaleProducts, manualProductPendingToAdd } = useSelector(posSelector);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);

  const onCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  const onSaveNoteModal = (notes: string | null) => {
    dispatch(setNotes(notes));
    setShowNoteModal(false);
  };

  const onDeleteNoteModal = () => {
    dispatch(setNotes(null));
    setShowNoteModal(false);
  };

  const onClosePinModal = (pin: string | null) => {
    setShowPinModal(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    dispatch(setStatus(SaleStatus.Reservation));
    dispatch(createSale(pin));
  };

  return (
    <React.Fragment>
      <div className="pos-details d-flex flex-column h-100 position-relative">
        <div className="mb-auto">
          <div className="px-4 pt-3">
            <div className="row justify-content-between align-items-center mb-3 no-select">
              <div className="col">
                <span onClick={() => dispatch(clearShoppingCart())} className="button-header" title="Vaciar carrito">
                  Borrar
                </span>
              </div>
              <div className="col text-center">
                <h1 className="mb-0">Cliente</h1>
              </div>
              <div className="col">
                <div className="d-flex flex-row justify-content-end">
                  {store && store.defaultCustomer && !customer && (
                    <span className="d-flex align-items-center button-header me-2 cursor-pointer" onClick={() => dispatch(setCustomer(store.defaultCustomer))}>
                      <Plus className="me-1" size={14} />
                      Cliente invitado
                    </span>
                  )}
                  <span onClick={() => setShowNoteModal(true)} className={clsx('d-flex align-items-center button-header', { 'has-notes': notes != null && notes !== '' })}>
                    {notes != null && notes !== '' ? (
                      '1 Nota'
                    ) : (
                      <React.Fragment>
                        <Plus className="me-1" size={14} />
                        Nota
                      </React.Fragment>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="position-relative mb-3">
              {customer ? (
                <CustomerInfo
                  customer={customer}
                  onAssignCustomer={(newCustomer: Customer) => dispatch(setCustomer(newCustomer))}
                  onRemoveCustomer={() => dispatch(setCustomer(null))}
                  balance={balance}
                />
              ) : (
                <CustomersFinder customer={customer} onClose={(c: Customer | null) => dispatch(setCustomer(c))} billingDataRequired={false} />
              )}
            </div>
            <PosProductsTable enableActions={true} />
            <PosSubtotals showTotal={true} />
          </div>
        </div>
        <div className="container-buttons">
          <button
            className={clsx('reservation-button', { disabled: (items.length === 0 && manualSaleProducts.length === 0) || customer == null })}
            onClick={() => {
              if (items.length === 0 && manualSaleProducts.length === 0) {
                myToastr.info('Añade al menos un producto al carrito');
                return;
              }
              if (!customer) {
                myToastr.info('Añade un cliente');
                return;
              }
              if (manualProductPendingToAdd) {
                myToastr.info('No has añadido el producto manual al carrito');
                return;
              }
              setShowPinModal(true);
            }}
          >
            Reservar
          </button>
          <button
            className={clsx('pay-button', { disabled: (items.length === 0 && manualSaleProducts.length === 0) || customer == null })}
            onClick={() => {
              if (items.length === 0 && manualSaleProducts.length === 0) {
                myToastr.info('Añade al menos un producto al carrito');
                return;
              }
              if (!customer) {
                myToastr.info('Añade un cliente');
                return;
              }
              if (manualProductPendingToAdd) {
                myToastr.info('No has añadido el producto manual al carrito');
                return;
              }
              dispatch(setStep(PosStep.Paying));
            }}
          >
            Pagar
          </button>
        </div>
      </div>
      <NoteModal editable={true} show={showNoteModal} notes={notes} closeModal={onCloseNoteModal} saveNotes={onSaveNoteModal} deleteNotes={onDeleteNoteModal} />
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
    </React.Fragment>
  );
};

export default PosDetails;
