import moment, { Moment } from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row } from 'react-table';
import DateRangeSelector from '../components/date-range-selector';
import DeliveryNoteStatusColumnFilter from '../components/delivery-note-status-column-filter';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import { DateRange } from '../enums/date-range';
import { DeliveryNoteStatus } from '../enums/delivery-note-status';
import { useStores } from '../hooks/use-stores.hook';
import { DateRangeData } from '../interfaces/date-range-data';
import { DeliveryNote } from '../interfaces/delivery-note';
import { DeliveryNotesQuery } from '../interfaces/delivery-notes-query';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { deliveryNoteStatusToString, formatNumber, getDatesGivenDateRange } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

interface FiltersSessionStorage {
  dateRange: DateRange;
  startDateStr: string | null;
  endDateStr: string | null;
  // Filtros de la tabla
  internalId: string | null;
  externalNumber: string | null;
  storeName: string | null;
  createdBy: string | null;
  date: string | null;
  invoiceDate: string | null;
  invoiceNumber: string | null;
  updatedBy: string | null;
  verifiedBy: string | null;
  verificationDate: string | null;
  supplierName: string | null;
  status: DeliveryNoteStatus | null;
  units: string | null;
  total: string | null;
}

const dateRange: DateRange = DateRange.ThisYear;

const DeliveryNotesWholesalerView = () => {
  const tableRef: any = useRef<any>(null);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { selectedStoreId } = useSelector(storeSelector);
  const [deliveryNotes, setDeliveryNotes] = useState<DeliveryNote[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData | null>(null);
  const [tableFilters, setTableFilters] = useState<{ id: string; value: any }[]>([]);
  const stores: Store[] = useStores();

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Número',
        id: 'internalId',
        accessor: 'internalId',
        filter: 'fuzzyText',
        Cell: ({ row }: any) => {
          const deliveryNote: DeliveryNote = row.original;
          return (
            <Link to={`/albaran/${deliveryNote.id}`} className="link-sale">
              <span className="fw-bold">{deliveryNote.internalId}</span>
            </Link>
          );
        },
      },
      {
        Header: 'Cliente',
        accessor: 'organization.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Tienda',
        accessor: 'store.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Fecha',
        id: 'date',
        accessor: (deliveryNote: DeliveryNote) => moment(deliveryNote.createdAt).format('DD/MM/YYYY'),
        filter: 'fuzzyText',
        sortType: (a: any, b: any) => {
          const momentA = moment(a.original.createdAt);
          const momentB = moment(b.original.createdAt);
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      },
      {
        Header: 'Estado',
        id: 'status',
        accessor: 'status',
        Filter: DeliveryNoteStatusColumnFilter,
        Cell: ({ value }: any) => {
          let className = '';
          switch (value) {
            case DeliveryNoteStatus.Cancelled:
              className = 'delivery-note-cancelled';
              break;
            case DeliveryNoteStatus.Pending:
              className = 'delivery-note-pending';
              break;
            case DeliveryNoteStatus.Received:
              className = 'delivery-note-received';
              break;
            case DeliveryNoteStatus.Invoiced:
              className = 'delivery-note-invoiced';
              break;
          }
          return <span className={`badge ${className}`}>{deliveryNoteStatusToString(value)}</span>;
        },
      },
      {
        Header: 'Unidades',
        id: 'units',
        accessor: 'units',
        filter: 'fuzzyText',
      },
      {
        Header: 'Base Imp.',
        id: 'taxBase',
        filter: 'fuzzyText',
        accessor: 'taxBase',
        Cell: (cell: any) => {
          const deliveryNote: DeliveryNote = cell.row.original;
          return `${formatNumber(deliveryNote.taxBase)}€`;
        },
      },
      {
        Header: 'IVA',
        id: 'vat',
        filter: 'fuzzyText',
        accessor: 'vat',
        Cell: (cell: any) => {
          const deliveryNote: DeliveryNote = cell.row.original;
          return `${formatNumber(deliveryNote.vat)}€`;
        },
      },
      {
        Header: 'Rec. Equiv.',
        id: 'equivalenceSurcharge',
        filter: 'fuzzyText',
        accessor: 'equivalenceSurcharge',
        Cell: (cell: any) => {
          const deliveryNote: DeliveryNote = cell.row.original;
          return `${formatNumber(deliveryNote.equivalenceSurcharge)}€`;
        },
      },
      {
        Header: 'Total',
        id: 'total',
        filter: 'fuzzyText',
        accessor: 'total',
        Cell: (cell: any) => {
          const deliveryNote: DeliveryNote = cell.row.original;
          return `${formatNumber(deliveryNote.total)}€`;
        },
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryNotes, selectedStoreId]);

  useEffect(() => {
    const value: string | null = sessionStorage.getItem('search-delivery-notes-wholesaler');
    let drd: DateRangeData = {
      dateRange,
      dates: getDatesGivenDateRange(dateRange),
    };
    const tf: { id: string; value: any }[] = [];
    if (value) {
      try {
        const filtersSessionStorage: FiltersSessionStorage = JSON.parse(value);
        drd = {
          dateRange: filtersSessionStorage.dateRange,
          dates: [
            moment(filtersSessionStorage.startDateStr).isValid() ? moment(filtersSessionStorage.startDateStr).toDate() : null,
            moment(filtersSessionStorage.endDateStr).isValid() ? moment(filtersSessionStorage.endDateStr).toDate() : null,
          ],
        };
        if (filtersSessionStorage.internalId) {
          tf.push({ id: 'internalId', value: filtersSessionStorage.internalId });
        }
        if (filtersSessionStorage.externalNumber) {
          tf.push({ id: 'externalNumber', value: filtersSessionStorage.externalNumber });
        }
        if (filtersSessionStorage.storeName) {
          tf.push({ id: 'storeName', value: filtersSessionStorage.storeName });
        }
        if (filtersSessionStorage.createdBy) {
          tf.push({ id: 'createdBy', value: filtersSessionStorage.createdBy });
        }
        if (filtersSessionStorage.date) {
          tf.push({ id: 'date', value: filtersSessionStorage.date });
        }
        if (filtersSessionStorage.invoiceDate) {
          tf.push({ id: 'invoiceDate', value: filtersSessionStorage.invoiceDate });
        }
        if (filtersSessionStorage.invoiceNumber) {
          tf.push({ id: 'invoiceNumber', value: filtersSessionStorage.invoiceNumber });
        }
        if (filtersSessionStorage.updatedBy) {
          tf.push({ id: 'updatedBy', value: filtersSessionStorage.updatedBy });
        }
        if (filtersSessionStorage.verifiedBy) {
          tf.push({ id: 'verifiedBy', value: filtersSessionStorage.verifiedBy });
        }
        if (filtersSessionStorage.verificationDate) {
          tf.push({ id: 'verificationDate', value: filtersSessionStorage.verificationDate });
        }
        if (filtersSessionStorage.supplierName) {
          tf.push({ id: 'supplierName', value: filtersSessionStorage.supplierName });
        }
        if (filtersSessionStorage.status) {
          tf.push({ id: 'status', value: filtersSessionStorage.status });
        }
        if (filtersSessionStorage.units) {
          tf.push({ id: 'units', value: filtersSessionStorage.units });
        }
        if (filtersSessionStorage.total) {
          tf.push({ id: 'total', value: filtersSessionStorage.total });
        }
      } catch (e) {
        sessionStorage.removeItem('search-delivery-notes-wholesaler');
      }
    }
    setTableFilters(tf);
    setDateRangeData(drd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    getDeliveryNotesWholesaler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, selectedStoreId]);

  useEffect(() => {
    if (!dateRangeData) {
      return;
    }
    const filtersSessionStorage: FiltersSessionStorage = {
      dateRange: dateRangeData!.dateRange,
      startDateStr: moment(dateRangeData!.dates[0]).format('YYYY-MM-DD'),
      endDateStr: moment(dateRangeData!.dates[1]).format('YYYY-MM-DD'),
      internalId: tableFilters.find((filter: any) => filter.id === 'internalId')?.value || '',
      externalNumber: tableFilters.find((filter: any) => filter.id === 'externalNumber')?.value || '',
      storeName: tableFilters.find((filter: any) => filter.id === 'storeName')?.value || '',
      createdBy: tableFilters.find((filter: any) => filter.id === 'createdBy')?.value || '',
      date: tableFilters.find((filter: any) => filter.id === 'date')?.value || '',
      invoiceDate: tableFilters.find((filter: any) => filter.id === 'invoiceDate')?.value || '',
      invoiceNumber: tableFilters.find((filter: any) => filter.id === 'invoiceNumber')?.value || '',
      updatedBy: tableFilters.find((filter: any) => filter.id === 'updatedBy')?.value || '',
      verifiedBy: tableFilters.find((filter: any) => filter.id === 'verifiedBy')?.value || '',
      verificationDate: tableFilters.find((filter: any) => filter.id === 'verificationDate')?.value || '',
      supplierName: tableFilters.find((filter: any) => filter.id === 'supplierName')?.value || '',
      status: tableFilters.find((filter: any) => filter.id === 'status')?.value || '',
      units: tableFilters.find((filter: any) => filter.id === 'units')?.value || '',
      total: tableFilters.find((filter: any) => filter.id === 'total')?.value || '',
    };
    sessionStorage.setItem('search-delivery-notes-wholesaler', JSON.stringify(filtersSessionStorage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, tableFilters]);

  const getDeliveryNotesWholesaler = async () => {
    setRequesting(true);
    const deliveryNotesQuery: DeliveryNotesQuery = {
      startDate: dateRangeData!.dates[0]!,
      endDate: dateRangeData!.dates[1]!,
      organizationId: organization!.id,
      storeId: selectedStoreId,
    };
    const dn: DeliveryNote[] = await api.getDeliveryNotesWholesaler(deliveryNotesQuery);
    setDeliveryNotes(dn);
    setRequesting(false);
  };

  const generateCsv = () => {
    const data: any[] = [];
    const orderedRows: any[] = [...filteredRows];
    orderedRows.sort((a: any, b: any) => {
      const momentA: Moment = moment(a.original.date);
      const momentB: Moment = moment(b.original.date);
      return momentA.isBefore(momentB) ? 1 : -1;
    });
    orderedRows.forEach((fr: any) => {
      const dn: DeliveryNote = fr.original;
      data.push({
        Número: dn.internalId,
        Cliente: dn.organization.name,
        Tienda: dn.store.name,
        Fecha: moment(dn.createdAt).format('DD/MM/YYYY'),
        Estado: deliveryNoteStatusToString(dn.status),
        Unidades: dn.units,
        'Base Imp.': `${formatNumber(dn.taxBase)}€`,
        IVA: `${formatNumber(dn.vat)}€`,
        'Rec. Equiv.': `${formatNumber(dn.equivalenceSurcharge)}€`,
        Total: `${formatNumber(dn.total)}€`,
      });
    });
    setCsvData(data);
  };

  if (!dateRangeData) {
    return null;
  }

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="delivery-notes-container p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">Albaranes</h1>{' '}
        {filteredRows.length > 0 && (
          <CSVLink filename="albaranes.csv" className="d-flex align-items-center export-csv" data={csvData} onClick={generateCsv} title="Exportar compras">
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={false}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
        <button
          className="clear-filters mx-2"
          disabled={requesting}
          onClick={() => {
            tableRef.current?.clearFilters();
            setDateRangeData({
              dateRange,
              dates: getDatesGivenDateRange(dateRange),
            });
          }}
        >
          Limpiar filtros
        </button>
      </div>
      <Table
        ref={tableRef}
        data={deliveryNotes}
        columns={columns}
        noDataMessage="No hay albaranes"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          filters: tableFilters,
          sortBy: [{ id: 'date', desc: true }],
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
    </div>
  );
};

export default DeliveryNotesWholesalerView;
