import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { ChevronLeft, Image } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery';
import Loader from 'react-loader-spinner';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '../components/confirm-modal';
import Table from '../components/table';
import { DeliveryNoteStatus } from '../enums/delivery-note-status';
import { PurchaseOrderStatus } from '../enums/purchase-order-status';
import { DeliveryNote } from '../interfaces/delivery-note';
import { DeliveryNoteProduct } from '../interfaces/delivery-note-product';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { ProductImage } from '../interfaces/product-image';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { deliveryNoteStatusToString, ellipsis, formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const DeliveryNoteWholesalerView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [deliveryNote, setDeliveryNote] = useState<DeliveryNote | null>(null);
  const [requesting, setRequesting] = useState<boolean>(true);
  const [showCancelDeliveryNoteModal, setShowCancelDeliveryNoteModal] = useState<boolean>(false);
  const columns = useMemo(() => {
    const data: any[] = [
      {
        Header: 'Sku',
        accessor: 'product.sku',
      },
      {
        Header: 'Producto',
        accessor: 'product.name',
        Cell: ({ row }: any) => {
          const deliveryNoteProduct: DeliveryNoteProduct = row.original;
          return (
            <div className="d-flex align-items-center">
              <div className="product-image me-2">
                {deliveryNoteProduct.product.images.length > 0 ? (
                  <LightgalleryProvider>
                    {deliveryNoteProduct.product.images.map((productImage: ProductImage, index: number) => {
                      return (
                        <LightgalleryItem key={productImage.id} group="group-product-images" src={process.env.REACT_APP_PUBLIC_URL + productImage.path}>
                          {index === 0 && <LazyLoadImage src={process.env.REACT_APP_PUBLIC_URL + productImage.path} alt="" className="img-thumbnail" />}
                        </LightgalleryItem>
                      );
                    })}
                  </LightgalleryProvider>
                ) : (
                  <Image size={50} className="img-thumbnail my-1 me-2" color="#D3D3D3" />
                )}
              </div>
              <Link to={`/producto/${deliveryNoteProduct.productId}`} className="link-sale">
                {ellipsis(deliveryNoteProduct.product.name, 50)}
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'Marca',
        accessor: 'product.brand.name',
      },
      {
        Header: 'Colección',
        accessor: 'product.collection.name',
      },
      {
        Header: 'PVM',
        accessor: 'pvp',
        Cell: ({ row }: any) => {
          const deliveryNoteProduct: DeliveryNoteProduct = row.original;
          return <span>{formatNumber(deliveryNoteProduct.unitCost)}€</span>;
        },
      },
      {
        Header: 'Unidades',
        accessor: 'units',
        Cell: ({ row }: any) => {
          const deliveryNoteProduct: DeliveryNoteProduct = row.original;
          return <span>{formatNumber(deliveryNoteProduct.units, true)}</span>;
        },
      },
      {
        Header: 'Total',
        accessor: (deliveryNoteProduct: DeliveryNoteProduct) => {
          return `${formatNumber(deliveryNoteProduct.unitCost * deliveryNoteProduct.units)}€`;
        },
        Cell: ({ row }: any) => {
          const deliveryNoteProduct: DeliveryNoteProduct = row.original;
          return `${formatNumber(deliveryNoteProduct.unitCost * deliveryNoteProduct.units)}€`;
        },
      },
    ];
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (!params.id) {
      return;
    }
    const id: number = parseInt(params.id);
    if (isNaN(id)) {
      navigate('/albaranes');
      return;
    }
    getDeliveryNote(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const getDeliveryNote = async (id: number) => {
    setRequesting(false);
    try {
      const dn: DeliveryNote = await api.getDeliveryNote(id);
      setDeliveryNote(dn);
      setRequesting(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      navigate('/albaranes');
    }
  };

  const onCloseCancelDeliveryNoteModal = async (result: boolean) => {
    if (!result) {
      setShowCancelDeliveryNoteModal(false);
      return;
    }
    setRequesting(false);
    try {
      const dn: DeliveryNote = await api.cancelDeliveryNote(deliveryNote!.id);
      setDeliveryNote(dn);
      setRequesting(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    }
    setShowCancelDeliveryNoteModal(false);
  };

  // TODO:
  const createInvoice = async () => {};

  if (requesting) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <Loader type="TailSpin" color="#252E3C" height={60} width={60} />
      </div>
    );
  }

  if (!deliveryNote) {
    return null;
  }

  return (
    <div className="purchase-order-view d-flex flex-column p-4">
      <div className="d-flex flex-row align-items-center">
        <div onClick={() => navigate('/albaranes')} className="d-flex flex-row align-items-center come-back">
          <ChevronLeft className="me-1" size={14} />
          <span>Volver</span>
        </div>
        <div className="flex-grow-1 container-title d-flex flex-column justify-content-center">
          <h1 className="text-center">Albarán {deliveryNote.internalId}</h1>
          <span className="text-center centered-item datetime mb-1">Fecha: {moment(deliveryNote.createdAt).format('YYYY-MM-DD HH:mm')}</span>
          {deliveryNote.status === DeliveryNoteStatus.Cancelled && (
            <span className={clsx('purchase-order-status clickable delivery-note-cancelled d-inline-block mb-1')} onClick={() => navigate(`/orden-compra/${deliveryNote.purchaseOrderId}`)}>
              {deliveryNoteStatusToString(DeliveryNoteStatus.Cancelled)}
            </span>
          )}
          <span className={clsx('purchase-order-status clickable', PurchaseOrderStatus.DeliveryNote, 'd-inline-block')} onClick={() => navigate(`/orden-compra/${deliveryNote.purchaseOrderId}`)}>
            Órden de compra {deliveryNote.purchaseOrder.originInternalId}
          </span>
        </div>
        {deliveryNote.status === DeliveryNoteStatus.Pending && (
          <button disabled={requesting} className="cancel-button" onClick={() => setShowCancelDeliveryNoteModal(true)}>
            Cancelar
          </button>
        )}
        {!deliveryNote.invoiceId && deliveryNote.status !== DeliveryNoteStatus.Cancelled && (
          <button disabled={requesting} className="finalize-button ms-2" onClick={createInvoice}>
            Crear factura
          </button>
        )}
      </div>
      <div className="container-info my-4 d-flex flex-row">
        <div className="d-flex flex-column ms-4" style={{ minWidth: 700 }}>
          <span className="title">Cliente:</span>
          <span className="value">
            {deliveryNote.organization.name} - {deliveryNote.organization.cif}
          </span>
          <span className="title">Tienda:</span>
          <span className="value">{deliveryNote.store.name}</span>
        </div>
        <div className="d-flex flex-column ms-4">
          <span className="title">Entregar en:</span>
          <span className="value">
            {deliveryNote.purchaseOrder.shippingAddress}, {deliveryNote.purchaseOrder.shippingZipCode} {deliveryNote.organization.city ? `(${deliveryNote.organization.city})` : ''}
          </span>
          <span className="value">
            {deliveryNote.purchaseOrder.shippingCity} {deliveryNote.purchaseOrder.shippingCountry}
          </span>
          {deliveryNote.organization.phone && <span className="value">{deliveryNote.organization.phone}</span>}
          {deliveryNote.organization.email && <span className="value">{deliveryNote.organization.email}</span>}
        </div>
      </div>
      <Table
        data={deliveryNote.deliveryNoteProducts}
        columns={columns}
        noDataMessage="No hay órdenes compras"
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <div className="resume-totals mt-4">
        <table className="">
          <thead>
            <tr>
              <th>Unidades</th>
              <th>Base Imponible</th>
              <th>IVA ({formatNumber(parseFloat(process.env.REACT_APP_IVA))}%)</th>
              <th>Rec Equi. ({formatNumber(parseFloat(process.env.REACT_APP_EQUIVALENCE_SURCHARGE))}%)</th>
              <th>Total €</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>{formatNumber(deliveryNote.units, true)}</div>
              </td>
              <td>
                <div>{formatNumber(deliveryNote.taxBase)}€</div>
              </td>
              <td>
                <div>{formatNumber(deliveryNote.vat)}€</div>
              </td>
              <td>
                <div>{formatNumber(deliveryNote.equivalenceSurcharge)}€</div>
              </td>
              <td>
                <div className="selected">{formatNumber(deliveryNote.total)}€</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ConfirmModal
        acceptButtonClass="accept-button"
        show={showCancelDeliveryNoteModal}
        title="Confirmar cancelar albarán"
        content={`<p>¿Estás seguro que quieres cancelar el albarán?.</p><p>Estos cambios no pueden deshacerse.</p>`}
        closeModal={onCloseCancelDeliveryNoteModal}
      />
    </div>
  );
};

export default DeliveryNoteWholesalerView;
