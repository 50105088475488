import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RepairStatus } from '../enums/repair-status';
import { Organization } from '../interfaces/organization';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import {
  deliverNewRepair,
  repairSelector,
  setErrorCostPrice,
  setErrorItem,
  setErrorRepairPrice,
  updateRepair,
  updateRepairAcceptBudget,
  updateRepairBudgetInStore,
  updateRepairPrices,
  updateRepairReceivedFromTechnicalService,
  updateRepairSentTechnicalService,
  updateRepairWithWarranty,
} from '../store/repair-slice';
import { RootState } from '../store/store';
import PinModal from './pin-modal';

const UpdateRepairButton = () => {
  const dispatch = useDispatch();
  const { requesting, repair, item, repairConcept, paymentsOnAccount } = useSelector(repairSelector);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);
  const disabled: boolean = useMemo(() => {
    if (requesting) {
      return true;
    }
    if (repair!.item && !item) {
      return true;
    }
    let someDataChanged = false;
    if (repair!.item !== item) {
      someDataChanged = true;
    }
    if (repair!.repairConcept !== repairConcept) {
      someDataChanged = true;
    }
    if (paymentsOnAccount.length > 0) {
      someDataChanged = true;
    }
    return !someDataChanged;
  }, [requesting, repair, item, repairConcept, paymentsOnAccount]);

  const openPinModal = () => {
    if (repair!.item && !item) {
      myToastr.error(`El nombre del producto es obligatorio`);
      dispatch(setErrorItem(true));
      return;
    }
    let someDataChanged = false;
    if (repair!.item !== item) {
      someDataChanged = true;
    }
    if (repair!.repairConcept !== repairConcept) {
      someDataChanged = true;
    }
    if (paymentsOnAccount.length > 0) {
      someDataChanged = true;
    }
    if (!someDataChanged) {
      return;
    }
    setShowPinModal(true);
  };

  const onUpdateRepair = async (pin: string | null) => {
    setShowPinModal(false);
    if (!pin) {
      return;
    }
    await dispatch(updateRepair({ pin }));
    myToastr.success('Compostura actualizada');
    window.location.reload();
  };

  return (
    <React.Fragment>
      <button className={clsx('save', { disabled })} onClick={openPinModal}>
        Guardar
      </button>
      <PinModal show={showPinModal} onCloseModal={onUpdateRepair} />
    </React.Fragment>
  );
};

const UpdateRepairButtons = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { requesting, pending, repair, costPrice, repairPrice, acceptBudget, sentTechnicalService, receivedFromTechnicalService } = useSelector(repairSelector);
  const [showPinModalSentTS, setShowPinModalSentTS] = useState<boolean>(false);
  const [showPinModalSentTS2, setShowPinModalSentTS2] = useState<boolean>(false);
  const [showPinModalReceivedFromTS, setShowPinModalReceivedFromTS] = useState<boolean>(false);
  const [showPinModalReceivedFromTSWithWarranty, setShowPinModalReceivedFromTSWithWarranty] = useState<boolean>(false);
  const [showPinModalBudgetPrice, setShowPinModalBudgetPrice] = useState<boolean>(false);
  const [showPinModalAcceptBudget, setShowPinModalAcceptBudget] = useState<boolean>(false);
  const [showPinModalBudgetInStore, setShowPinModalBudgetInStore] = useState<boolean>(false);
  const [showPinModalWarrantyFinished, setShowPinModalWarrantyFinished] = useState<boolean>(false);

  const disabledNoWarrantySentTS: boolean = useMemo(() => {
    return !sentTechnicalService;
  }, [sentTechnicalService]);

  const disabledNoWarrantyReceivedFromTSButton: boolean = useMemo(() => {
    return !receivedFromTechnicalService;
  }, [receivedFromTechnicalService]);

  const disabledBudgetPriceButton: boolean = useMemo(() => {
    return costPrice === null || repairPrice === null;
  }, [costPrice, repairPrice]);

  const disabledAcceptRejectButton: boolean = useMemo(() => {
    return acceptBudget === null;
  }, [acceptBudget]);

  const onClosePinModalSentST = async (pin: string | null) => {
    setShowPinModalSentTS(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    await dispatch(
      updateRepairSentTechnicalService({
        organizationId: organization.id,
        pin,
        sentTechnicalService,
      }),
    );
  };

  const onClosePinModalSentST2 = async (pin: string | null) => {
    setShowPinModalSentTS2(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    await dispatch(updateRepairWithWarranty({ organizationId: organization.id, pin, sentTechnicalService }));
  };

  const onClosePinModalReceivedFromST = async (pin: string | null) => {
    setShowPinModalReceivedFromTS(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    await dispatch(updateRepairReceivedFromTechnicalService({ organizationId: organization.id, pin, receivedFromTechnicalService }));
  };

  const onClosePinModalReceivedFromSTWithWarranty = async (pin: string | null) => {
    setShowPinModalReceivedFromTSWithWarranty(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    await dispatch(updateRepairWithWarranty({ organizationId: organization.id, pin, receivedByTechnicalService: receivedFromTechnicalService }));
  };

  const onClosePinModalBudgetPrice = async (pin: string | null) => {
    setShowPinModalBudgetPrice(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    await dispatch(updateRepairPrices({ organizationId: organization.id, pin, costPrice: costPrice!, repairPrice: repairPrice! }));
  };

  const onClosePinModalAcceptBudget = async (pin: string | null) => {
    setShowPinModalAcceptBudget(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    await dispatch(updateRepairAcceptBudget({ organizationId: organization.id, pin, accept: acceptBudget! }));
  };

  const onClosePinModalBudgetInStore = async (pin: string | null) => {
    setShowPinModalBudgetInStore(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    await dispatch(updateRepairBudgetInStore({ organizationId: organization.id, pin }));
    window.location.reload();
  };

  const onCloseWarrantyFinished = async (pin: string | null) => {
    setShowPinModalWarrantyFinished(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    await dispatch(deliverNewRepair(pin));
  };

  return (
    <div className="update-repair-buttons">
      {!repair?.warranty && repair?.repairStatus === RepairStatus.Budget && repair?.sentTechnicalService === null && (
        <React.Fragment>
          <UpdateRepairButton />
          <button
            onClick={() => {
              if (disabledNoWarrantySentTS) {
                myToastr.error('Producto no enviado al servicio técnico');
                return;
              }
              setShowPinModalSentTS(true);
            }}
            disabled={requesting}
            className={clsx('container-button pick-up-repair', { disabled: disabledNoWarrantySentTS })}
          >
            Siguiente
          </button>
        </React.Fragment>
      )}
      {repair?.warranty === false && repair?.repairStatus === RepairStatus.InProcess && repair?.sentTechnicalService && repair?.costPrice !== null && repair?.repairPrice !== null && (
        <React.Fragment>
          <UpdateRepairButton />
          <button
            onClick={() => {
              if (disabledNoWarrantyReceivedFromTSButton) {
                myToastr.error('El producto no se ha recibido del servicio técnico');
                return;
              }
              setShowPinModalReceivedFromTS(true);
            }}
            disabled={requesting}
            className={clsx('container-button pick-up-repair', { disabled: disabledNoWarrantyReceivedFromTSButton })}
          >
            Pasar a recogida
          </button>
        </React.Fragment>
      )}
      {repair?.warranty === false && repair?.repairStatus === RepairStatus.Budget && repair.sentTechnicalService && repair.costPrice == null && repair.repairPrice === null && (
        <React.Fragment>
          <UpdateRepairButton />
          <button
            onClick={() => {
              if (costPrice === null) {
                myToastr.error('Introduce el precio de coste de la reparación');
                dispatch(setErrorCostPrice(true));
              }
              if (repairPrice === null) {
                myToastr.error('Introduce el precio de la reparación');
                dispatch(setErrorRepairPrice(true));
              }
              if (disabledBudgetPriceButton) {
                return;
              }
              setShowPinModalBudgetPrice(true);
            }}
            disabled={requesting}
            className={clsx('container-button next', { disabled: disabledBudgetPriceButton })}
          >
            Guardar presupuesto
          </button>
        </React.Fragment>
      )}
      {repair?.warranty === false && repair?.repairStatus === RepairStatus.Budget && repair.costPrice !== null && repair.repairPrice !== null && (
        <React.Fragment>
          <UpdateRepairButton />
          <button
            onClick={() => {
              if (acceptBudget === null) {
                myToastr.error('Acepta o rechaza el presupuesto');
                return;
              }
              if (disabledAcceptRejectButton) {
                return;
              }
              setShowPinModalAcceptBudget(true);
            }}
            disabled={requesting}
            className={clsx('container-button next', { disabled: disabledAcceptRejectButton })}
          >
            Siguiente
          </button>
        </React.Fragment>
      )}
      {repair?.warranty && repair?.repairStatus === RepairStatus.InProcess && repair?.sentTechnicalService === null && (
        <React.Fragment>
          <UpdateRepairButton />
          <button
            onClick={() => {
              if (sentTechnicalService !== true) {
                myToastr.error('Envía el producto al servicio técnico');
                return;
              }
              setShowPinModalSentTS2(true);
            }}
            disabled={requesting}
            className={clsx('container-button next', { disabled: sentTechnicalService !== true })}
          >
            Siguiente
          </button>
        </React.Fragment>
      )}
      {repair?.warranty && repair?.sentTechnicalService === true && repair?.receivedFromTechnicalService !== true && (
        <React.Fragment>
          <UpdateRepairButton />
          <button
            onClick={() => {
              if (receivedFromTechnicalService !== true) {
                myToastr.error('El producto no se ha recibido del servicio técnico');
                return;
              }
              setShowPinModalReceivedFromTSWithWarranty(true);
            }}
            disabled={requesting}
            className={clsx('container-button next', { disabled: receivedFromTechnicalService !== true })}
          >
            Siguiente
          </button>
        </React.Fragment>
      )}
      {repair?.warranty === false && repair?.repairStatus === RepairStatus.InProcess && repair.budgetInStore && (
        <React.Fragment>
          <UpdateRepairButton />
          <button onClick={() => setShowPinModalBudgetInStore(true)} disabled={requesting} className={clsx('container-button pick-up-repair')}>
            Pasar a recogida
          </button>
        </React.Fragment>
      )}
      {repair?.repairStatus === RepairStatus.Canceled && (
        <React.Fragment>
          <UpdateRepairButton />
          <button onClick={() => navigate('/reparaciones')} disabled={requesting} className={clsx('container-button back')}>
            Volver
          </button>
        </React.Fragment>
      )}
      {repair?.repairStatus === RepairStatus.Finished && (
        <React.Fragment>
          <UpdateRepairButton />
          <button
            onClick={() => {
              if (pending > 0 && repair.acceptBudget) {
                myToastr.error(`Faltan por abonar ${formatNumber(pending)}€`);
                return;
              }
              setShowPinModalWarrantyFinished(true);
            }}
            disabled={requesting}
            className={clsx('container-button pick-up-repair', { disabled: requesting || (pending > 0 && repair.acceptBudget) })}
          >
            Retirado por el cliente
          </button>
        </React.Fragment>
      )}
      {repair?.repairStatus === RepairStatus.Delivered && (
        <React.Fragment>
          <UpdateRepairButton />
          <button onClick={() => navigate('/reparaciones')} disabled={requesting} className={clsx('container-button back')}>
            Volver
          </button>
        </React.Fragment>
      )}
      <PinModal show={showPinModalSentTS} onCloseModal={onClosePinModalSentST} />
      <PinModal show={showPinModalSentTS2} onCloseModal={onClosePinModalSentST2} />
      <PinModal show={showPinModalReceivedFromTS} onCloseModal={onClosePinModalReceivedFromST} />
      <PinModal show={showPinModalReceivedFromTSWithWarranty} onCloseModal={onClosePinModalReceivedFromSTWithWarranty} />
      <PinModal show={showPinModalBudgetPrice} onCloseModal={onClosePinModalBudgetPrice} />
      <PinModal show={showPinModalAcceptBudget} onCloseModal={onClosePinModalAcceptBudget} />
      <PinModal show={showPinModalBudgetInStore} onCloseModal={onClosePinModalBudgetInStore} />
      <PinModal show={showPinModalWarrantyFinished} onCloseModal={onCloseWarrantyFinished} />
    </div>
  );
};

export default UpdateRepairButtons;
