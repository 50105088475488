import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Edit, Plus, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { Row } from 'react-table';
import ConfirmModal from '../components/confirm-modal';
import OrganizationModal from '../components/organization-modal';
import Table from '../components/table';
import { Role } from '../enums/role';
import { Organization } from '../interfaces/organization';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { organizationTypeToString } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

const OrganizationsView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [showOrganizationModal, setShowOrganizationModal] = useState<boolean>(false);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);

  const getOrganizations = async () => {
    setRequesting(true);
    const organizations: Organization[] = await api.getOrganizations();
    setOrganizations(organizations);
    setRequesting(false);
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const columns = React.useMemo(() => {
    const columnsData: any[] = [
      { Header: 'Nombre', accessor: 'name', filter: 'fuzzyText' },
      {
        Header: 'Tipo',
        accessor: (organization: Organization) => organizationTypeToString(organization.type),
        filter: 'fuzzyText',
      },
      { Header: 'CIF', accessor: 'cif', filter: 'fuzzyText' },
      { Header: 'Razón social', accessor: 'businessName', filter: 'fuzzyText' },
      { Header: 'Dirección', accessor: 'address', filter: 'fuzzyText' },
      { Header: 'Teléfono', accessor: 'phone', filter: 'fuzzyText' },
      { Header: 'Email', accessor: 'email', filter: 'fuzzyText' },
      {
        Header: 'Acciones',
        Cell: ({ row }: any) => {
          return (
            <React.Fragment>
              <Edit
                size={16}
                color="#222E3D"
                onClick={() => {
                  setSelectedOrganization(row.original);
                  setShowOrganizationModal(true);
                }}
                type="button"
                className="mx-2"
              />
              {user.role === Role.SuperAdmin && (
                <Trash2
                  type="button"
                  className="mx-2"
                  onClick={() => {
                    setSelectedOrganization(row.original);
                    setShowConfirmModal(true);
                  }}
                  size={14}
                />
              )}
            </React.Fragment>
          );
        },
      },
    ];
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]) as any;

  const newOrganization = () => {
    setSelectedOrganization(null);
    setShowOrganizationModal(true);
  };

  const onOrganizationModalClose = (organization: Organization | null) => {
    if (organization) {
      getOrganizations();
    }
    setShowOrganizationModal(false);
    setSelectedOrganization(null);
  };

  const deleteOrganization = async (o: Organization) => {
    try {
      await api.deleteOrganization(o.id);
      const orgs: Organization[] = [...organizations];
      const index: number = orgs.findIndex((org: Organization) => org.id === o.id);
      orgs.splice(index, 1);
      setOrganizations(orgs);
      myToastr.success('Organización eliminada correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const organization: Organization = fr.original;
      data.push({
        Nombre: organization.name,
        Tipo: organizationTypeToString(organization.type),
        CIF: organization.cif,
        'Razón social': organization.businessName,
        Dirección: organization.address,
        Teléfono: organization.phone,
        Email: organization.email,
      });
    });
    setCsvData(data);
  };

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="d-flex flex-row align-items-center">
        <h1 className="flex-grow-1">{user.role === Role.SuperAdmin ? 'Organizaciones' : 'Organización'}</h1>
        {filteredRows.length > 0 && (
          <CSVLink filename="organizaciones.csv" className="d-flex align-items-center export-csv me-2" data={csvData} onClick={generateCsv}>
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        {user.role === Role.SuperAdmin && (
          <button className="d-flex align-items-center create-button" onClick={newOrganization}>
            <Plus className="me-1" size={14} /> Nueva organización
          </button>
        )}
      </div>
      <Table
        data={organizations}
        columns={columns}
        noDataMessage="No hay organizaciones"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      <OrganizationModal show={showOrganizationModal} closeModal={onOrganizationModalClose} organization={selectedOrganization}></OrganizationModal>
      {selectedOrganization && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Organización"
          content={`¿Estás seguro que quieres eliminar la organización ${selectedOrganization.name}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteOrganization(selectedOrganization);
            }
            setSelectedOrganization(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default OrganizationsView;
