import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight, RefreshCw, User as IconUser } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CashRegisterType } from '../enums/cash-register-type';
import { OnlineStoreType } from '../enums/online-store-type';
import { PaymentMethodType } from '../enums/payment-method-type';
import { PosStatus } from '../enums/pos-status';
import { Role } from '../enums/role';
import { SaleStatus } from '../enums/sale-status';
import { SaleStep } from '../enums/sale-step';
import { WooCommerceOrderStatus } from '../enums/woocommerce-order-status';
import { CashRegisterCheck } from '../interfaces/cash-register-check';
import { Organization } from '../interfaces/organization';
import { PaymentMethod } from '../interfaces/payment-method';
import { Sale } from '../interfaces/sale';
import { SalePaymentMethod } from '../interfaces/sale-payment-method';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { downloadPdf, formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { saleSelector, setSale, setStep, updateOnlineStatusSale, updateSalePaymentMethod } from '../store/sale-slice';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';
import PinModal from './pin-modal';
import SelectPaymentMethodModal from './select-payment-method-modal';
import TableBookingPayments from './table-booking-payments';
import { TicketOptions } from './ticket-options';

const SalePaymentMethods = () => {
  const user: User | null = useAppSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { store } = useSelector(storeSelector);
  const { sale, availableUnits, manualAvailableUnits, selectedProductIds, selectedManualProductIds, previosNextSale, customer, requesting } = useSelector(saleSelector);
  const numAvailableProductsToReturn: number = useMemo(() => {
    let total = 0;
    for (const productId in availableUnits) {
      if (availableUnits.hasOwnProperty(productId)) {
        total += availableUnits[productId];
      }
    }
    for (const id in manualAvailableUnits) {
      if (manualAvailableUnits.hasOwnProperty(id)) {
        total += manualAvailableUnits[id];
      }
    }
    return total;
  }, [availableUnits, manualAvailableUnits]);
  const [requestingTicket, setRequestingTicket] = useState<boolean>(false);
  const [requestingGiftTicket, setRequestingGiftTicket] = useState<boolean>(false);
  const [sendingTicketByEmail, setSendingTicketByEmail] = useState<boolean>(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState<string>('');
  const [requestingShopify, setRequestingShopify] = useState<boolean>(false);
  const [selectedSalePaymentMethod, setSelectedSalePaymentMethod] = useState<SalePaymentMethod | null>(null);
  const [currentCashRegisterCheck, setCurrentCashRegisterCheck] = useState<CashRegisterCheck | null>(null);

  const onPrintTicket = async () => {
    if (requestingTicket) {
      return;
    }
    try {
      myToastr.info('Obteniendo el ticket de la venta. Espere por favor.');
      setRequestingTicket(true);
      const result: ArrayBuffer = await api.getSaleTicket(sale!.id);
      downloadPdf(result, `ticket-${sale!.internalId}.pdf`);
    } catch (e) {
      myToastr.error('Hubo un error obteniendo el ticket de la venta');
    } finally {
      setRequestingTicket(false);
    }
  };

  const onSendGift = async () => {
    if (requestingGiftTicket) {
      return;
    }
    try {
      myToastr.info('Obteniendo el ticket regalo de la venta. Espere por favor.');
      setRequestingGiftTicket(true);
      const productIds: number[] = Object.keys(selectedProductIds).map((idStr: string) => parseInt(idStr, 10));
      const manualProductIds: number[] = Object.keys(selectedManualProductIds).map((idStr: string) => parseInt(idStr, 10));
      const result: ArrayBuffer = await api.getSaleGiftTicket(sale!.id, productIds, manualProductIds);
      downloadPdf(result, `ticket-regalo-${sale!.internalId}.pdf`);
    } catch (e) {
      myToastr.error('Hubo un error obteniendo el ticket regalo de la venta');
    } finally {
      setRequestingGiftTicket(false);
    }
  };

  const onSendEmail = async () => {
    if (sendingTicketByEmail) {
      return;
    }
    if (!customer?.email) {
      myToastr.error('El cliente no tiene un email asociado');
      return;
    }
    myToastr.info('Enviando ticket por correo electrónico. Espere por favor.');
    try {
      setSendingTicketByEmail(true);
      const result: boolean = await api.sendSaleTicketByEmail(sale!.id);
      if (result) {
        myToastr.success('Se ha enviado el ticket por correo electrónico');
      } else {
        myToastr.error('Hubo un error enviando el ticket por correo electrónico');
      }
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          myToastr.error(Array.isArray(axiosError.response.data.message) ? axiosError.response.data.message.join('<br>') : axiosError.response.data.message);
        }
      } else {
        myToastr.error('Hubo un error enviando el ticket por correo electrónico');
      }
    } finally {
      setSendingTicketByEmail(false);
    }
  };

  const onClosePinModal = (pin: string | null) => {
    setShowPinModal(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    dispatch(
      updateOnlineStatusSale({
        organizationId: organization.id,
        pin,
        onlineStatus,
      }),
    );
  };

  const fulfillShopifyOrder = async () => {
    if (requestingShopify) {
      return;
    }
    setRequestingShopify(true);
    try {
      myToastr.info('Finalizando venta en Shopify. Espera por favor...');
      const updatedSale: Sale = await api.fulfillShopifyOrder(sale!.id);
      myToastr.clear();
      myToastr.success('Pedido finalizado en Shopify');
      dispatch(setSale(updatedSale));
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          myToastr.error(Array.isArray(axiosError.response.data.message) ? axiosError.response.data.message.join('<br>') : axiosError.response.data.message);
        }
      } else {
        myToastr.error('Hubo un error finalizando la venta en Shopify');
      }
    }
    setRequestingShopify(false);
  };

  const onCloseSelectPaymentMethod = (newPaymentMethodId: number) => {
    dispatch(
      updateSalePaymentMethod({
        salePaymentMethodId: selectedSalePaymentMethod!.id,
        newPaymentMethodId,
      }),
    );
    setSelectedSalePaymentMethod(null);
  };

  const getCurrentCashRegisterCheck = useCallback(async () => {
    try {
      const crc: CashRegisterCheck = await api.getCurrentCashRegisterCheck(sale!.storeId);
      setCurrentCashRegisterCheck(crc);
    } catch (e) {}
  }, [sale]);

  useEffect(() => {
    if (!sale || !user) {
      return;
    }
    if (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) {
      getCurrentCashRegisterCheck();
    }
  }, [getCurrentCashRegisterCheck, sale, user]);

  if (!sale) {
    return null;
  }

  const title: string[] = [`${sale.store.name}`];
  let seller = '';
  if (sale.user && sale.user.name && sale.user.name.length > 0) {
    seller = sale.user.name;
    if (sale.user.surnames && sale.user.surnames.length > 0) {
      seller += ` ${sale.user.surnames}`;
    }
    title.push(seller);
  }
  if (sale.onlineStoreId && sale.onlineStoreId > 0) {
    title.push(`Venta online`);
  }
  return (
    <React.Fragment>
      <div className="make-payment d-flex flex-column position-relative">
        <div className="d-flex flex-column p-4 mb-auto">
          <div className="d-flex justify-content-between">
            <div className="come-back">
              <ChevronLeft className="me-1" size={14} />
              <span
                className={clsx('prev-next', { disabled: !previosNextSale?.previousSaleId })}
                onClick={() => {
                  if (previosNextSale?.previousSaleId) {
                    navigate(`/venta/${previosNextSale.previousSaleId}`);
                  }
                }}
              >
                Anterior
              </span>
            </div>
            <div className="d-flex flex-column text-center mb-3">
              <h1 className="mb-0">{sale.internalId || sale.internalReservationId}</h1>
              <span className="subtitle mt-2">{title.join(' - ')}</span>
            </div>
            <div className="come-back">
              <span
                className={clsx('prev-next', { disabled: !previosNextSale?.nextSaleId })}
                onClick={() => {
                  if (previosNextSale?.nextSaleId) {
                    navigate(`/venta/${previosNextSale.nextSaleId}`);
                  }
                }}
              >
                Siguiente
              </span>
              <ChevronRight className="me-1" size={14} />
            </div>
          </div>
          {sale.status === SaleStatus.Cancelled && (
            <div className="sale-status-view" style={{ backgroundColor: '#E02760' }}>
              Cancelada
            </div>
          )}
          <div className="d-flex flex-row justify-content-between mb-3">
            <div className="d-flex flex-column payment-columns me-4">
              {sale.salePaymentMethods.map((salePaymentMethod: SalePaymentMethod) => {
                const paymentMethod: PaymentMethod = salePaymentMethod.paymentMethod;
                return (
                  <div
                    key={salePaymentMethod.id}
                    className={clsx('mb-3 payment-method', {
                      paytpv: paymentMethod.isTpv,
                      paybalance: paymentMethod.type === PaymentMethodType.Balance,
                      paycash: paymentMethod.type === PaymentMethodType.Cash,
                      paycustom: paymentMethod.type === PaymentMethodType.Custom,
                      payimports: paymentMethod.type === PaymentMethodType.Imports,
                    })}
                  >
                    <div className="name d-flex">
                      {paymentMethod.type === PaymentMethodType.Balance && <IconUser className="me-2" size={14} />}
                      <div className="d-flex justify-content-between w-100 position-relative">{paymentMethod.name}</div>
                      {currentCashRegisterCheck !== null &&
                        (sale.status === SaleStatus.Finalized || sale.status === SaleStatus.Return) &&
                        currentCashRegisterCheck.type === CashRegisterType.Open &&
                        currentCashRegisterCheck.createdAt <= sale.createdAt && (
                          <div onClick={() => setSelectedSalePaymentMethod(salePaymentMethod)} className="cursor-pointer me-2" title="Cambiar método de pago">
                            <RefreshCw size={14} />
                          </div>
                        )}
                    </div>
                    <div className="container-amount">
                      <input className="cursor-default" type="text" value={`${formatNumber(salePaymentMethod!.amount)}€`} readOnly />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex flex-column payment-columns ms-4">
              <div className="d-flex flex-column container-money mb-3">
                <span className="title">Total</span>
                <span className="value">{formatNumber(sale.total)}€</span>
              </div>
              {sale.status === SaleStatus.Finalized && (
                <div className="d-flex flex-column container-money mb-3">
                  <span className="title">Cambio en {sale.changeInBalance ? 'saldo cliente' : 'efectivo'}</span>
                  <span className="value">{formatNumber(sale.changeToClient)}€</span>
                </div>
              )}
            </div>
          </div>
          {sale.onlineStore?.type === OnlineStoreType.WooCommerce && sale.status !== SaleStatus.Return && (
            <div className="d-flex flex-column mb-4 w-50">
              <label className="change-status mb-2">Cambiar el estado en {sale.onlineStore.name}:</label>
              <select
                disabled={
                  requesting ||
                  sale.onlineStatus === WooCommerceOrderStatus.Cancelled ||
                  sale.onlineStatus === WooCommerceOrderStatus.Refunded ||
                  sale.onlineStatus === WooCommerceOrderStatus.Failed ||
                  sale.onlineStatus === WooCommerceOrderStatus.Trash
                }
                name="onlineStatus"
                value={sale.onlineStatus}
                onChange={(e: any) => {
                  setOnlineStatus(e.target.value);
                  setShowPinModal(true);
                }}
              >
                <option value={WooCommerceOrderStatus.Pending}>Pendiente</option>
                <option value={WooCommerceOrderStatus.Processing}>En proceso</option>
                <option value={WooCommerceOrderStatus.OnHold}>En espera</option>
                <option value={WooCommerceOrderStatus.Completed}>Completado</option>
                <option value={WooCommerceOrderStatus.Cancelled}>Cancelado</option>
                <option value={WooCommerceOrderStatus.Refunded}>Reembolsado</option>
                <option value={WooCommerceOrderStatus.Failed}>Fallido</option>
                <option value={WooCommerceOrderStatus.Trash}>Trash</option>
              </select>
            </div>
          )}
          {sale.onlineStore?.type === OnlineStoreType.Shopify && sale.status === SaleStatus.Pending && (
            <div className="make-payment">
              <div
                className="changes my-5 my-md-2"
                onClick={fulfillShopifyOrder}
                style={{
                  cursor: requestingShopify ? 'not-allowed' : 'pointer',
                  backgroundColor: requestingShopify ? '#ccc' : '#0092fd',
                }}
              >
                Finalizar
              </div>
            </div>
          )}
          {!sale.onlineSaleId && sale.status === SaleStatus.Finalized && numAvailableProductsToReturn > 0 && (
            <div
              onClick={() => {
                if (!store) {
                  myToastr.error('Selecciona una tienda');
                  return;
                }
                if (store.posStatus === PosStatus.Closed) {
                  myToastr.error('Abre la caja para poder devolver productos');
                  return;
                }
                dispatch(setStep(SaleStep.ReturnsAndExchanges));
              }}
              className="changes my-5 my-md-2"
            >
              Cambios y devoluciones
            </div>
          )}
          {sale.onlineSaleId && sale.status === SaleStatus.Finalized && numAvailableProductsToReturn > 0 && (
            <div
              onClick={() => {
                if (!store) {
                  myToastr.error('Selecciona una tienda');
                  return;
                }
                if (store.posStatus === PosStatus.Closed) {
                  myToastr.error('Abre la caja para poder devolver productos');
                  return;
                }
                dispatch(setStep(SaleStep.ReturnsAndExchanges));
              }}
              className="changes my-5 my-md-2"
            >
              Devoluciones
            </div>
          )}
          {(sale.status === SaleStatus.Finalized || sale.status === SaleStatus.Return) && (
            <TicketOptions showGift={sale.status === SaleStatus.Finalized} onPrint={onPrintTicket} onSendGift={onSendGift} onSendEmail={onSendEmail} />
          )}
          {sale.status === SaleStatus.Cancelled && !sale.onlineSaleId && <TableBookingPayments />}
        </div>
        <div className="container-buttons">
          {store?.posStatus === PosStatus.Opened && (
            <button className={clsx('finalize-button')} onClick={() => navigate('/pos')}>
              Nueva venta
            </button>
          )}
        </div>
      </div>
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
      <SelectPaymentMethodModal
        amount={selectedSalePaymentMethod?.amount || 0}
        customerId={sale.customerId}
        onCancel={() => setSelectedSalePaymentMethod(null)}
        onSave={onCloseSelectPaymentMethod}
        show={selectedSalePaymentMethod !== null}
        storeId={sale.storeId}
        paymentMethodId={selectedSalePaymentMethod?.paymentMethod.id || -1}
      />
    </React.Fragment>
  );
};

export default SalePaymentMethods;
