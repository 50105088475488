import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Check, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { CommunicationPreferences } from '../enums/communication-preferences';
import { Role } from '../enums/role';
import { Customer } from '../interfaces/customer';
import { CustomerPrivacyMarketingDto } from '../interfaces/customer-privacy-marketing.dto';
import { CustomerSignDto } from '../interfaces/customer-sign.dto';
import { CustomerDto } from '../interfaces/customer.dto';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { isEmail } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';
import SignModal from './sign-modal';

interface Props {
  customer: Customer | null;
  show: boolean;
  billingDataRequired: boolean;
  closeModal: (customer: Customer | null) => void;
}

interface MyModel extends CustomerDto {
  acceptPrivacy?: boolean;
  marketing?: boolean;
}

const initialValues: MyModel = {
  organizationId: -1,
  storeId: -1,
  name: '',
  surnames: '',
  email: '',
  phone: '',
  secondaryPhone: '',
  address: '',
  postalCode: null,
  city: '',
  province: '',
  cif: '',
  notes: '',
  money: 0,
  communicationPreferences: CommunicationPreferences.Sign,
  billingBusinessName: '',
  billingAddress1: '',
  billingAddress2: '',
  billingPostalCode: '',
  billingCity: '',
  billingProvince: '',
  billingCountry: '',
  billingEmail: '',
  billingPhone: '',
  marketing: false,
  acceptPrivacy: false,
};

const CustomerModal = (props: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { store } = useSelector(storeSelector);
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [showSignModal, setShowSignModal] = useState<boolean>(false);
  const [sign, setSign] = useState<string | null>(null);
  const [requestingPrivacy, setRequestingPrivacy] = useState<boolean>(false);
  const [requestingMarketing, setRequestingMarketing] = useState<boolean>(false);
  const {
    register,
    reset,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<MyModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!props.show) {
      reset(initialValues);
      setCustomer(null);
      setRequesting(false);
      setSign(null);
      setRequestingPrivacy(false);
      setRequestingMarketing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    if (!props.show || !props.customer) {
      return;
    }
    const getCustomer = async () => {
      const customer: Customer = await api.getCustomer(props.customer!.id);
      setCustomer(customer);
      reset({
        organizationId: customer.organizationId,
        storeId: customer.storeId ? customer.storeId : -1,
        name: customer.name,
        surnames: customer.surnames,
        email: customer.email,
        phone: customer.phone && customer.phone.length > 0 ? customer.phone.replace(/ +/g, '') : '',
        secondaryPhone: customer.secondaryPhone && customer.secondaryPhone.length > 0 ? customer.secondaryPhone.replace(/ +/g, '') : '',
        address: customer.address,
        postalCode: customer.postalCode,
        city: customer.city,
        province: customer.province,
        cif: customer.cif,
        notes: customer.notes,
        money: customer.balance?.money || 0,
        communicationPreferences: customer.communicationPreferences,
        billingBusinessName: customer.billingBusinessName,
        billingAddress1: customer.billingAddress1,
        billingAddress2: customer.billingAddress2,
        billingPostalCode: customer.billingPostalCode,
        billingCity: customer.billingCity,
        billingProvince: customer.billingProvince,
        billingCountry: customer.billingCountry,
        billingEmail: customer.billingEmail,
        billingPhone: customer.billingPhone,
        acceptPrivacy: customer.acceptPrivacy,
        marketing: customer.marketing,
      });
    };
    getCustomer();
  }, [props.show, props.customer, reset]);

  const close = () => {
    props.closeModal(null);
  };

  const onSubmit = async () => {
    const data = getValues() as any;
    setRequesting(true);
    try {
      const customerDto: CustomerDto = {
        organizationId: organization.id,
        storeId: -1,
        name: data.name ? data.name.trim() : null,
        surnames: data.surnames ? data.surnames.trim() : null,
        email: data.email ? data.email.trim() : null,
        phone: data.phone ? data.phone.trim().toString() : '',
        secondaryPhone: data.secondaryPhone ? data.secondaryPhone.trim().toString() : '',
        address: data.address ? data.address.trim() : null,
        postalCode: data?.postalCode ? parseInt(data.postalCode.trim()).toString() : null,
        city: data.city ? data.city.trim() : null,
        province: data.province ? data.province.trim() : null,
        cif: data.cif ? data.cif.trim() : null,
        notes: data.notes ? data.notes.trim() : null,
        money: data.money,
        communicationPreferences: data.communicationPreferences,
        billingBusinessName: data.billingBusinessName,
        billingAddress1: data.billingAddress1,
        billingAddress2: data.billingAddress2,
        billingPostalCode: data.billingPostalCode,
        billingCity: data.billingCity,
        billingProvince: data.billingProvince,
        billingCountry: data.billingCountry,
        billingEmail: data.billingEmail,
        billingPhone: data.billingPhone,
      };
      if (customer) {
        customerDto.storeId = data.storeId > 0 ? data.storeId : null;
        const updatedCustomer: Customer = await api.updateCustomer(customer.id, customerDto);
        myToastr.success('Cliente actualizado correctamente');
        props.closeModal(updatedCustomer);
      } else {
        customerDto.storeId = store!.id;
        let c: Customer = await api.createCustomer(customerDto);
        if (data.acceptPrivacy) {
          if (c.communicationPreferences === CommunicationPreferences.Email) {
            if (!c.email) {
              myToastr.error('El cliente no tiene un email para enviarle la comunicación');
            } else if (!isEmail(c.email)) {
              myToastr.error('El cliente no tiene un email válido para enviarle la comunicación');
            } else {
              if (data.marketing) {
                c = await api.sendEmailAdvertising(c.id);
              } else {
                c = await api.sendEmailPrivacy(c.id);
              }
            }
          } else if (c.communicationPreferences === CommunicationPreferences.Phone) {
            myToastr.warning('El servicio de comunicación con el cliente mediante teléfono no está disponible todavía');
          } else if (c.communicationPreferences === CommunicationPreferences.Sign) {
            if (!sign) {
              myToastr.warning('El cliente no ha firmado');
            } else {
              const customerSignDto: CustomerSignDto = {
                customerId: c.id,
                sign,
                acceptPrivacy: true,
                marketing: data.marketing,
              };
              c = await api.sign(customerSignDto);
            }
          }
        }
        myToastr.success('Cliente creado correctamente');
        props.closeModal(c);
      }
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
    setRequesting(false);
  };

  const requestPrivacy = async () => {
    if (watch().communicationPreferences === CommunicationPreferences.Email) {
      if (!customer!.email) {
        myToastr.error('El cliente no tiene un email');
        return;
      } else if (!isEmail(customer!.email)) {
        myToastr.error('El cliente no tiene un email válido');
        return;
      }
      setRequesting(true);
      try {
        const c: Customer = await api.sendEmailPrivacy(customer!.id);
        setCustomer(c);
        myToastr.success('Email enviado correctamente');
      } catch (e: any) {
        myToastr.error(e.response.data.message);
      }
      setRequesting(false);
    } else if (watch().communicationPreferences === CommunicationPreferences.Phone) {
      myToastr.warning('El servicio de comunicación con el cliente mediante teléfono no está disponible todavía');
      return;
    } else if (watch().communicationPreferences === CommunicationPreferences.Sign) {
      setRequestingPrivacy(true);
      setRequestingMarketing(false);
      setShowSignModal(true);
    }
  };

  const requestPrivacyNewCustomer = () => {
    if (watch().communicationPreferences === CommunicationPreferences.Email) {
      if (!watch().email) {
        myToastr.error('El cliente no tiene un email');
        return;
      } else if (!isEmail(watch().email)) {
        myToastr.error('El cliente no tiene un email válido');
        return;
      }
      setValue('acceptPrivacy', true);
    } else if (watch().communicationPreferences === CommunicationPreferences.Phone) {
      myToastr.warning('El servicio de comunicación con el cliente mediante teléfono no está disponible todavía');
      return;
    } else if (watch().communicationPreferences === CommunicationPreferences.Sign) {
      setRequestingPrivacy(true);
      setRequestingMarketing(false);
      setShowSignModal(true);
    }
  };

  const requestAdvertising = async () => {
    if (watch().communicationPreferences === CommunicationPreferences.Email) {
      if (!customer!.email) {
        myToastr.error('El cliente no tiene un email');
        return;
      } else if (!isEmail(customer!.email)) {
        myToastr.error('El cliente no tiene un email válido');
        return;
      }
      setRequesting(true);
      try {
        const c: Customer = await api.sendEmailAdvertising(customer!.id);
        setCustomer(c);
        myToastr.success('Email enviado correctamente');
      } catch (e: any) {
        myToastr.error(e.response.data.message);
      }
      setRequesting(false);
    } else if (watch().communicationPreferences === CommunicationPreferences.Phone) {
      myToastr.warning('El servicio de comunicación con el cliente mediante teléfono no está disponible todavía');
      return;
    } else if (watch().communicationPreferences === CommunicationPreferences.Sign) {
      setRequestingPrivacy(true);
      setRequestingMarketing(true);
      setShowSignModal(true);
    }
  };

  const requestAdvertisingNewCustomer = () => {
    if (watch().communicationPreferences === CommunicationPreferences.Email) {
      if (!watch().email) {
        myToastr.error('El cliente no tiene un email');
        return;
      } else if (!isEmail(watch().email)) {
        myToastr.error('El cliente no tiene un email válido');
        return;
      }
      setValue('acceptPrivacy', true);
      setValue('marketing', true);
    } else if (watch().communicationPreferences === CommunicationPreferences.Phone) {
      myToastr.warning('El servicio de comunicación con el cliente mediante teléfono no está disponible todavía');
      return;
    } else if (watch().communicationPreferences === CommunicationPreferences.Sign) {
      setRequestingPrivacy(true);
      setRequestingMarketing(true);
      setShowSignModal(true);
    }
  };

  const updatePrivacyAndMarketing = async (privacy: boolean, marketing: boolean) => {
    try {
      const customerPrivacyMarketingDto: CustomerPrivacyMarketingDto = {
        marketing,
        privacy,
      };
      const c: Customer = await api.updateCustomerPrivacyAndMarketing(customer!.id, customerPrivacyMarketingDto);
      setCustomer(c);
      myToastr.success('Los datos del cliente han sido actualizados');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const onSignModalClose = async (customerSign: string | null) => {
    if (customerSign) {
      if (customer) {
        try {
          const customerSignDto: CustomerSignDto = {
            customerId: customer!.id,
            sign: customerSign,
            acceptPrivacy: requestingPrivacy,
            marketing: requestingMarketing,
          };
          const updatedCustomer: Customer = await api.sign(customerSignDto);
          myToastr.success('Firma guardada correctamente');
          setCustomer(updatedCustomer);
        } catch (e) {}
      } else {
        if (requestingMarketing) {
          setValue('marketing', true);
        } else {
          setValue('marketing', false);
        }
        setValue('acceptPrivacy', true);
      }
    }
    setSign(customerSign);
    setRequestingPrivacy(false);
    setRequestingMarketing(false);
    setShowSignModal(false);
  };

  return (
    <Modal className="vercomi-modal my-form customer-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Cliente</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="contact-info">Información de contacto</div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.phone })}>Teléfono *</div>
              <input type="number" {...register('phone', { required: true })} placeholder="Teléfono" className={clsx({ error: errors?.phone })} />
              {errors.phone && <div className="error-message">{errors.phone.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.email })}>Email</div>
              <input
                type="email"
                {...register('email', {
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email inválido',
                  },
                })}
                placeholder="Email"
                className={clsx({ error: errors?.email })}
              />
              {errors.email && <div className="error-message">{errors.email.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.name ? 'error' : '')}>Nombre</div>
              <input type="text" {...register('name')} className={clsx({ error: errors?.name })} placeholder="Nombre" />
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.surnames })}>Apellidos</div>
              <input type="text" {...register('surnames')} className={clsx({ error: errors?.surnames })} placeholder="Apellidos" />
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.address })}>Dirección</div>
              <input type="text" {...register('address')} className={clsx({ error: errors?.address })} placeholder="Dirección" />
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.postalCode })}>Código postal</div>
              <input type="number" {...register('postalCode')} className={clsx({ error: errors?.postalCode })} placeholder="Código postal" />
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.city })}>Ciudad</div>
              <input type="text" {...register('city')} className={clsx({ error: errors?.city })} placeholder="Ciudad" />
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.province })}>Provincia</div>
              <input type="text" {...register('province')} className={clsx({ error: errors?.province })} placeholder="Provincia" />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.secondaryPhone })}>Teléfono secundario</div>
              <input type="number" {...register('secondaryPhone')} placeholder="Teléfono" />
            </div>
            {user && (
              <div className="col-6">
                <div className="input-name">Tienda</div>
                <select {...register('storeId', { valueAsNumber: true })}>
                  <option value={-1}>Selecciona una tienda</option>
                  {user.stores.map((s: Store) => (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className="row my-3">
            <div className="col-6">
              {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                <React.Fragment>
                  <div className={clsx('input-name', { error: errors?.money })}>Saldo</div>
                  <input type="number" step="0.01" pattern="[0-9]+([\.,][0-9]+)?" {...register('money', { valueAsNumber: true })} className={clsx({ error: errors?.money })} placeholder="Saldo" />
                </React.Fragment>
              )}
              <div>
                <div className={clsx('input-name', { error: errors?.notes })}>Notas</div>
                <textarea {...register('notes')} className={clsx({ error: errors?.notes })} placeholder="Notas" />
              </div>
            </div>
            <div className="col-6 firma-cliente py-2">
              <div className={clsx('input-name', { error: errors?.money })}>Preferencias de comunicación</div>
              <select {...register('communicationPreferences')}>
                {/* <option value={CommunicationPreferences.Phone}>Teléfono</option> */}
                <option value={CommunicationPreferences.Email}>Email</option>
                <option value={CommunicationPreferences.Sign}>Firma</option>
              </select>
              <div>
                {customer !== null ? (
                  <div className="my-3">
                    {!customer.acceptPrivacy && (
                      <div className="row my-3">
                        <div className="col">
                          <div className="mb-2">
                            <button type="button" className="customer-button" disabled={requesting} onClick={requestPrivacy}>
                              {customer.personalDataAcceptanceToken !== null && watch().communicationPreferences === CommunicationPreferences.Email
                                ? 'Reenviar solicitud uso datos personales'
                                : 'Solicitar uso datos personales'}
                            </button>
                          </div>
                          <div>
                            <button type="button" className="customer-button" disabled={requesting} onClick={requestAdvertising}>
                              {customer.advertisingToken !== null && watch().communicationPreferences === CommunicationPreferences.Email
                                ? 'Reenviar solicitud uso datos personales y marketing'
                                : 'Solicitar uso datos personales y marketing'}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {customer.acceptPrivacy && (
                      <div className="col-12 mb-2">
                        <div className="d-flex flex-row align-items-center">
                          <button type="button" className="customer-button danger me-2" disabled={requesting} onClick={() => updatePrivacyAndMarketing(false, false)}>
                            Desactivar uso datos personales
                          </button>
                          <label className="flex-grow-1" style={{ fontSize: 12 }}>
                            <Check size={16} color="#26C44B" />
                          </label>
                        </div>
                      </div>
                    )}
                    {customer.marketing && (
                      <div className="d-flex flex-row align-items-center">
                        <button type="button" className="customer-button danger me-2" disabled={requesting} onClick={() => updatePrivacyAndMarketing(true, false)}>
                          Desactivar envío publicidad
                        </button>
                        <label className="flex-grow-1" style={{ fontSize: 12 }}>
                          <Check size={16} color="#26C44B" />
                        </label>
                      </div>
                    )}
                    {customer.acceptPrivacy && !customer.marketing && (
                      <div className="col">
                        <button type="button" className="customer-button" disabled={requesting} onClick={requestAdvertising}>
                          {customer.advertisingToken !== null ? 'Reenviar solicitud marketing' : 'Solicitar marketing'}
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="row my-3">
                      <div className="col">
                        <div className="mb-2">
                          {watch().acceptPrivacy ? (
                            <label>Datos personales solicitados</label>
                          ) : (
                            <button type="button" className="customer-button" disabled={requesting} onClick={requestPrivacyNewCustomer}>
                              Solicitar uso datos personales
                            </button>
                          )}
                        </div>
                        <div>
                          {watch().marketing ? (
                            <label>Datos personales y marketing solicitados</label>
                          ) : (
                            <button type="button" className="customer-button" disabled={requesting} onClick={requestAdvertisingNewCustomer}>
                              Solicitar uso datos personales y marketing
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div className="contact-info">Datos de facturación</div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.billingBusinessName })}>Razón social {props.billingDataRequired ? '*' : ''}</div>
              <input type="text" {...register('billingBusinessName', { required: props.billingDataRequired })} placeholder="Razón social" className={clsx({ error: errors?.billingBusinessName })} />
              {errors.billingBusinessName && <div className="error-message">{errors.billingBusinessName.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.cif })}>CIF / NIF {props.billingDataRequired ? '*' : ''}</div>
              <input type="text" {...register('cif', { required: props.billingDataRequired })} className={clsx({ error: errors?.cif })} placeholder="CIF / NIF" />
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.billingAddress1 })}>Dirección 1 {props.billingDataRequired ? '*' : ''}</div>
              <input type="text" {...register('billingAddress1', { required: props.billingDataRequired })} placeholder="Dirección (línea 1)" className={clsx({ error: errors?.billingAddress1 })} />
              {errors.billingAddress1 && <div className="error-message">{errors.billingAddress1.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.billingAddress2 })}>Dirección 2</div>
              <input type="text" {...register('billingAddress2')} placeholder="Dirección (línea 2)" className={clsx({ error: errors?.billingAddress2 })} />
              {errors.billingAddress2 && <div className="error-message">{errors.billingAddress2.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.billingPostalCode })}>Código postal {props.billingDataRequired ? '*' : ''}</div>
              <input type="text" {...register('billingPostalCode', { required: props.billingDataRequired })} placeholder="Código postal" className={clsx({ error: errors?.billingPostalCode })} />
              {errors.billingPostalCode && <div className="error-message">{errors.billingPostalCode.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.billingCity })}>Ciudad {props.billingDataRequired ? '*' : ''}</div>
              <input type="text" {...register('billingCity', { required: props.billingDataRequired })} placeholder="Ciudada" className={clsx({ error: errors?.billingCity })} />
              {errors.billingCity && <div className="error-message">{errors.billingCity.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.billingProvince })}>Provincia {props.billingDataRequired ? '*' : ''}</div>
              <input type="text" {...register('billingProvince', { required: props.billingDataRequired })} placeholder="Provincia" className={clsx({ error: errors?.billingProvince })} />
              {errors.billingProvince && <div className="error-message">{errors.billingProvince.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.billingCountry })}>País {props.billingDataRequired ? '*' : ''}</div>
              <input type="text" {...register('billingCountry', { required: props.billingDataRequired })} placeholder="Provincia" className={clsx({ error: errors?.billingCountry })} />
              {errors.billingCountry && <div className="error-message">{errors.billingCountry.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.billingEmail })}>Email</div>
              <input
                type="email"
                {...register('billingEmail', {
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email inválido',
                  },
                })}
                placeholder="Email"
                className={clsx({ error: errors?.billingEmail })}
              />
              {errors.billingEmail && <div className="error-message">{errors.billingEmail.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.billingPhone })}>Teléfono</div>
              <input type="text" {...register('billingPhone')} placeholder="Teléfono" />
              {errors.billingPhone && <div className="error-message">{errors.billingPhone.message}</div>}
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={async () => {
            const validForm: boolean = await trigger();
            if (!validForm) {
              return;
            }
            onSubmit();
          }}
          disabled={requesting}
          className="button save-button"
        >
          Guardar
        </button>
      </form>
      <SignModal show={showSignModal} closeModal={onSignModalClose} requestingPrivacy={requestingPrivacy} requestingMarketing={requestingMarketing} />
    </Modal>
  );
};

export default CustomerModal;
