import { Image } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { truncateText } from '../services/helpers';

const ProductOption = (props: any) => {
  const { innerProps, innerRef, onCreate } = props;
  if (props?.data?.product) {
    const { product } = props.data;
    let image = null;
    if (product?.images && product.images.length > 0) {
      image = product.images[0].path;
    }
    return (
      <div ref={innerRef} {...innerProps} className="d-flex flex-row align-items-center custom-product-option">
        <div className="me-2">{image ? <LazyLoadImage src={process.env.REACT_APP_PUBLIC_URL + image} alt="" width={40} height={40} className="my-2 me-2" /> : <Image size={50} color="white" />}</div>
        <div className="d-flex flex-column">
          <div className="name">{truncateText(product.name, 30)}</div>
          <div className="sku">{product.sku}</div>
        </div>
      </div>
    );
  } else if (props.data.hasOwnProperty('custom')) {
    return (
      <div
        ref={innerRef}
        className="d-flex custom-product-option"
        style={{
          lineHeight: '50px',
          fontSize: '12px',
        }}
        onClick={() => {
          onCreate(props.data.value);
          innerProps.onClick();
        }}
      >
        Nueva referencia: <strong className="ms-2">{props.data.value}</strong>
      </div>
    );
  } else {
    return null;
  }
};

export default ProductOption;
