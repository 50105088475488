export enum WooCommerceOrderStatus {
  Pending = 'pending',
  Processing = 'processing',
  OnHold = 'on-hold',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Refunded = 'refunded',
  Failed = 'failed',
  Trash = 'trash',
}
